import { PropsWithChildren, useContext, useCallback, ReactNode } from "react";
import cx from "classnames";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import styles from "./DropdownMenu.module.scss";
import { radixThemeDataAttributes } from "@/components/Theme";

function Trigger({ children }: PropsWithChildren) {
  return (
    <DropdownMenuPrimitive.Trigger asChild>
      {children}
    </DropdownMenuPrimitive.Trigger>
  );
}

export interface DropdownMenuItem {
  label: ReactNode;
  onClick: () => void;
  icon?: ReactNode;
}

type MenuItemProps = DropdownMenuItem;

function MenuItem({ label, onClick, icon }: MenuItemProps) {
  return (
    <DropdownMenuPrimitive.Item
      onSelect={onClick}
      className={cx(styles.DropdownMenuItem, {
        [styles.hasIcon]: icon != null,
      })}
    >
      {icon}
      {label}
    </DropdownMenuPrimitive.Item>
  );
}

interface DropdownMenuProps {
  items: DropdownMenuItem[];
}

function DropdownMenu({
  children,
  items,
}: PropsWithChildren<DropdownMenuProps>) {
  return (
    <DropdownMenuPrimitive.Root>
      {children}

      <DropdownMenuPrimitive.Portal>
        <DropdownMenuPrimitive.Content
          align={"start"}
          sideOffset={5}
          className={cx(styles.DropdownMenuContent, "radix-themes")}
          {...radixThemeDataAttributes}
        >
          {items.map((item) => (
            <MenuItem {...item} />
          ))}
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  );
}

DropdownMenu.Trigger = Trigger;

export default DropdownMenu;
