import dayjs from "dayjs";
import cx from "classnames";
import styles from "./styles.module.scss";
import { categoriesMapper } from "../lib";
import { useEffect, useMemo, useState } from "react";
import { useFilter } from "../context";
import { Chart } from "../../customer/trend";
import { Avatar, Badge, Em, Flex, Separator, Text } from "@radix-ui/themes";
import {
  ChatBubbleIcon,
  DotFilledIcon,
  DoubleArrowRightIcon,
  EnvelopeOpenIcon,
  GlobeIcon,
  LoopIcon,
  TimerIcon,
  ValueNoneIcon,
  Pencil2Icon,
} from "@radix-ui/react-icons";
import { DateRangePreset } from "@/components/shared/date-range-picker/DateRangePicker";
import { TicketOverview as TicketOverviewType } from "../../../api/useExplore/Ticket.model";
import {
  TicketExploreCollaborator,
  TrendValue,
} from "../../../api/useExplore/Explore.model";
import { useAuth0 } from "@auth0/auth0-react";
import { isYotpo } from "../../../api/user";
import { buildColorMap, RadixColor } from "@/components/radixColors";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
} from "@/components/ui/hover-card";

const fixedNumberValue = (value: number | string) => {
  const numValue = typeof value === "string" ? parseFloat(value) : value;
  return numValue.toFixed(2).replace(/[.,]00$/, "");
};

export function getInitials(name: string): string {
  const words = name
    .replaceAll("Unknown ", "")
    .split(" ")
    .map((x) => x[0]);

  if (words.length === 1) {
    return words[0];
  }

  return [words[0], words[words.length - 1]].join("");
}

export const QAScore = (props: { value: number }) => {
  const { user } = useAuth0();

  const value = useMemo(() => {
    if (isYotpo(user)) {
      return props.value * 4 + 1;
    }

    return props.value;
  }, [props.value, user]);

  const color = useMemo(() => {
    if (value < 2) {
      return "red";
    } else if (value <= 4) {
      return "yellow";
    } else {
      return "green";
    }
  }, [value]);

  return (
    <div className={styles.qaScore}>
      {[...Array(5)].map((_, index) => (
        <span
          key={index}
          className={cx(styles.qaScoreDot, {
            [styles[`${color}`]]: index + 1 <= value,
            [styles[`half_${color}`]]: index < value && value < index + 1,
          })}
        />
      ))}

      <div className={styles.qaScoreValue}>{fixedNumberValue(props.value)}</div>
    </div>
  );
};

export const TTR = ({ value }: { value: number }) => {
  const convert = (time: number) => {
    return time > 60 * 24
      ? dayjs.duration(time, "minutes").format("D[D] H[H]")
      : dayjs.duration(time, "minutes").format("H[H] m[M]");
  };

  return (
    <div className={styles.ttr}>
      <TimerIcon />
      {value ? convert(value) : "-"}
    </div>
  );
};

export const CategoryName = ({ value }: { value: string }) => {
  return (
    <div className={styles.categoryName}>
      <span className={styles.iconContainer}>
        {categoriesMapper[value as keyof typeof categoriesMapper]?.icon}
      </span>
      {categoriesMapper[value as keyof typeof categoriesMapper]?.label ??
        "Uncategorized"}
    </div>
  );
};

export const JiraTicket = () => {
  return (
    <div className={styles.categoryName}>
      <span className={styles.iconContainer}>
        <DoubleArrowRightIcon />
      </span>
      Jira
    </div>
  );
};

export const KbCoverage = ({ value }: { value: string }) => {
  if (value === "NO_COVERAGE") {
    return (
      <div className={styles.notCovered}>
        <ValueNoneIcon />
        Not Covered
      </div>
    );
  }

  if (value === "PARTIAL") {
    return <div className={styles.partialCovered}>Partly Covered</div>;
  }

  return <div>Covered</div>;
};

export const Trend = ({ value: qtyByDates }: { value: TrendValue }) => {
  const { dateRange } = useFilter();
  const [ticketCount, setTicketCount] = useState<number[]>([]);

  useEffect(() => {
    const ranges = [];
    const dateRangeMapping: Record<
      DateRangePreset,
      { value: number; unit: "days" | "months" }
    > = {
      today: { value: 0, unit: "days" },
      "1d": { value: 1, unit: "days" },
      "7d": { value: 7, unit: "days" },
      "14d": { value: 14, unit: "days" },
      "1m": { value: 1, unit: "months" },
      "2m": { value: 2, unit: "months" },
    };

    const { value, unit } = { value: 7, unit: "days" };

    for (let i = 0; i < 4; i++) {
      const endDate = dayjs()
        .subtract(i * value, unit as "days" | "months")
        .startOf("day");
      const startDate = endDate
        .subtract(value, unit as "days" | "months")
        .startOf("day");
      ranges.push({
        start: startDate.format("YYYY-MM-DD"),
        end:
          i === 0
            ? endDate.add(1, "day").format("YYYY-MM-DD")
            : endDate.format("YYYY-MM-DD"),
      });
    }

    const ticketCounts = ranges.map(({ start, end }) => {
      return qtyByDates.reduce((sum, { date, count }) => {
        const currentDate = dayjs(date);
        if (currentDate.isBefore(end) && currentDate.isAfter(start)) {
          return sum + count;
        }
        return sum;
      }, 0);
    });

    setTicketCount(ticketCounts);
  }, [dateRange, qtyByDates]);

  return (
    <div>
      <Chart
        data={ticketCount
          .map((t, i) => ({
            month: 4 - i,
            count: t,
          }))
          .sort((a, b) => a.month - b.month)}
        width={60}
        height={30}
      />
    </div>
  );
};

export const TicketOverview = ({ value }: { value: TicketOverviewType }) => {
  const subject =
    value?.subject == null || value.subject.length === 0
      ? `Ticket #${value?.id}`
      : value.subject;

  return (
    <div className={styles.ticketOverviewContainer}>
      <Text wrap="wrap" size="2">
        {subject}
      </Text>
      <Text color="gray" wrap="wrap" size="2" weight="light">
        #{value?.id}
      </Text>
    </div>
  );
};

export const Status = ({ value }: { value: string }) => {
  return (
    <div
      className={cx(styles.statusContainer, {
        [styles.red]: ["deleted"].includes(value),
        [styles.yellow]: ["pending", "hold"].includes(value),
        [styles.green]: ["solved", "closed"].includes(value),
        [styles.blue]: ["open"].includes(value),
      })}
    >
      <DotFilledIcon />
      {value}
    </div>
  );
};

export const Agent = ({ value }: { value: string }) => {
  const initials = getInitials(value);

  return (
    <Flex gap={"2"} align={"center"}>
      <Avatar size={"1"} fallback={initials} color={"gray"} radius={"full"} />

      <Text truncate>{value}</Text>
    </Flex>
  );
};

export const Channel = ({ value }: { value: string }) => {
  return (
    <div className={styles.channel}>
      {value === "chat" ||
      value === "native_messaging" ||
      value === "autonomous_quackchat" ? (
        <ChatBubbleIcon />
      ) : value === "web" ? (
        <GlobeIcon />
      ) : (
        <EnvelopeOpenIcon />
      )}

      {value.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
    </div>
  );
};

export const BackAndForth = ({ value }: { value: number }) => {
  return (
    <div className={styles.messagesCount}>
      <LoopIcon />
      {fixedNumberValue(value)}
    </div>
  );
};

export const InternalNotes = ({ value }: { value: number }) => {
  return (
    <div className={styles.messagesCount}>
      <Pencil2Icon />
      {fixedNumberValue(value)}
    </div>
  );
};

export function Release({ value }: { value: boolean }) {
  return (
    <div
      className={cx(styles.statusContainer, {
        [styles.red]: !value,
        [styles.green]: value,
      })}
    >
      <DotFilledIcon />
      {value ? "released" : "Not released yet"}
    </div>
  );
}

export function TicketValidation({ value = false }: { value?: boolean }) {
  return (
    <div
      className={cx(styles.TicketValidation, {
        [styles.validated]: value,
      })}
    >
      {value ? "Validated" : "Pending"}
    </div>
  );
}

export function FirstMessage({ value }: { value: string }) {
  return <div className={styles.firstMessage}>{value}</div>;
}

export function FormattedDate({ value }: { value: string }) {
  const formattedDate = dayjs(value).format("MMM DD YYYY, HH:mm");
  return <div className={styles.createdAt}>{formattedDate}</div>;
}

function SentimentBadge({ value }: { value: number }) {
  if (value === 0) {
    <Badge color={"gray"}>
      <div className={`h-2 w-2 shrink-0 rounded-[2px] bg-current`} />
      {0}
    </Badge>;
  }

  return (
    <Badge color={value > 0 ? "green" : "red"}>
      <div className={`h-2 w-2 shrink-0 rounded-[2px] bg-current`} />
      {Math.abs(value * 100).toFixed(0)}
    </Badge>
  );
}

export function Sentiment({ value }: { value: number }) {
  const content = useMemo(() => {
    if (value === 0) {
      return ["Neutral Sentiment"];
    }

    const absValue = Math.abs(value * 100);
    const level =
      absValue <= 40 ? "Low" : absValue <= 70 ? "Moderate" : "Strong";

    return [
      `${level} ${value > 0 ? "Positive" : "Negative"} Sentiment`,
      `Avg. sentiment is on the the ${
        value > 0 ? "Positive" : "Negative"
      } scale.`,
    ];
  }, [value]);

  return (
    <HoverCard>
      <HoverCardTrigger>
        <SentimentBadge value={value} />
      </HoverCardTrigger>

      <HoverCardContent side="bottom" sideOffset={8} align="start">
        <Flex direction={"column"} gap={"1"}>
          <div>
            <SentimentBadge value={value} />
          </div>

          {content.map((text, index) => (
            <Text
              size={"2"}
              {...(index === 0
                ? {
                    color: value === 0 ? "gray" : value > 0 ? "green" : "red",
                    weight: "medium",
                    highContrast: value === 0,
                  }
                : {})}
            >
              {text}
            </Text>
          ))}
        </Flex>
      </HoverCardContent>
    </HoverCard>
  );
}

export function MessagesCount({ value }: { value: number }) {
  return <div className={styles.messagesCount}>{fixedNumberValue(value)}</div>;
}

function CollaboratorInfo({
  name,
  color,
  publicCommentsCount = 0,
  internalCommentsCount = 0,
  avgQaScore,
}: TicketExploreCollaborator & { color: RadixColor }) {
  const collaboratorInitials = getInitials(name);

  return (
    <Flex direction={"column"} gap={"1"}>
      <Flex gap={"2"}>
        <Avatar
          size={"1"}
          fallback={collaboratorInitials}
          color={color}
          radius={"full"}
        />

        <Text size={"2"} weight={"bold"}>
          {name}
        </Text>
      </Flex>

      <Text size={"2"}>
        {publicCommentsCount > 0
          ? `${publicCommentsCount} Public Messages`
          : "No Public Messages"}
      </Text>

      {internalCommentsCount > 0 && (
        <Text size={"2"}>{`${internalCommentsCount} Internal Messages`}</Text>
      )}

      {avgQaScore != null && (
        <Text size={"2"}>
          <QAScore value={avgQaScore ?? 0} />
        </Text>
      )}
    </Flex>
  );
}

export function Collaborators({
  value,
}: {
  value?: TicketExploreCollaborator[];
}) {
  const sortedCollaborators = useMemo(() => {
    return value?.sort((a, b) => a.name.localeCompare(b.name));
  }, [value]);

  const collaboratorsToColor = useMemo(() => {
    return buildColorMap(value?.map(({ name }) => name) ?? []);
  }, [value]);

  const isEmpty =
    sortedCollaborators == null || sortedCollaborators.length === 0;

  if (isEmpty) {
    return (
      <Text color={"gray"}>
        <Em>No collaborators</Em>
      </Text>
    );
  }

  return (
    <Flex gap={"1"}>
      {sortedCollaborators.slice(0, 3).map((collaborator) => {
        const collaboratorInitials = getInitials(collaborator.name);
        const color = collaboratorsToColor[collaborator.name];

        return (
          <HoverCard key={collaborator.id}>
            <HoverCardTrigger>
              <Avatar
                size={"1"}
                fallback={collaboratorInitials}
                color={color}
                radius={"full"}
              />
            </HoverCardTrigger>

            <HoverCardContent side="top" align="start">
              <CollaboratorInfo {...collaborator} color={color} />
            </HoverCardContent>
          </HoverCard>
        );
      })}

      {sortedCollaborators.length > 3 && (
        <HoverCard>
          <HoverCardTrigger>
            <Avatar
              size={"1"}
              fallback={`+${sortedCollaborators.length - 3}`}
              color={"gray"}
              radius={"full"}
            />
          </HoverCardTrigger>

          <HoverCardContent side="bottom" sideOffset={8} align="start">
            <Flex direction={"column"} gap={"1"}>
              {sortedCollaborators.slice(3).map((collaborator, index) => {
                const color = collaboratorsToColor[collaborator.name];

                return (
                  <>
                    {index !== 0 && <Separator my="3" size="4" />}
                    <CollaboratorInfo {...collaborator} color={color} />
                  </>
                );
              })}
            </Flex>
          </HoverCardContent>
        </HoverCard>
      )}
    </Flex>
  );
}
