import { useParams } from "react-router-dom";
import { useSimulatorConfiguration } from "./useSimulatorConfiguration";
import { ConfigurationViewGuard } from "./parts/ConfigurationViewGuard";
import { TicketChannelEscalationConfiguration } from "./ticket-channel/TicketChannelEscalationConfiguration";

export function EscalationConfiguration() {
  const { simulatorId } = useParams();
  const { simulator } = useSimulatorConfiguration(simulatorId);

  const channel = simulator?.channel;

  return (
    <ConfigurationViewGuard view={"escalations"}>
      {channel === "autonomous_ticket_event" && (
        <TicketChannelEscalationConfiguration />
      )}
    </ConfigurationViewGuard>
  );
}
