import { RouteTreeValue } from "@/lib/renderRouteTree";
import { automationConfigurationUris } from "@/features/automation-configuration/AutomationConfiguration.config";
import { GeneralConfiguration } from "@/features/automation-configuration/GeneralConfiguration";
import { EscalationConfiguration } from "@/features/automation-configuration/EscalationConfiguration";
import { InstallSimulator } from "@/features/automation-configuration/InstallSimulator";
import { AppearanceConfiguration } from "@/features/automation-configuration/AppearanceConfiguration";
import { AutomationConfiguration } from "./AutomationConfiguration";
import { EndOfConversationConfiguration } from "@/features/automation-configuration/EndOfConversationConfiguration";

export const automationConfigurationRouteTree: RouteTreeValue = {
  root: automationConfigurationUris.root,
  rootElement: <AutomationConfiguration />,
  tree: [
    {
      path: automationConfigurationUris.general,
      elem: <GeneralConfiguration />,
      index: true,
    },
    {
      path: automationConfigurationUris.end_of_conversation,
      elem: <EndOfConversationConfiguration />,
    },
    {
      path: automationConfigurationUris.escalations,
      elem: <EscalationConfiguration />,
    },
    {
      path: automationConfigurationUris.appearance,
      elem: <AppearanceConfiguration />,
    },
    { path: automationConfigurationUris.install, elem: <InstallSimulator /> },
  ],
};
