import { useCreateIntegrationV2, useIntegrations } from "@/api/integration";
import {
  ConnectIntegrationRequest,
  Integration,
  IntegrationName,
  IntegrationType,
  isChatIntegrationName,
  isKnowledgeBaseIntegrationName,
  isNangoSupportedIntegrationName,
  isTaskManagementIntegrationName,
  isTicketSystemIntegrationName,
  supportedChat,
  supportedKnowledgeBase,
  supportedTaskManagement,
  supportedTicketSystems,
  existingTicketSystems,
} from "@/api/integration/Integration.model";
import SelectIntegration from "./add-integration/select-integration/SelectIntegration";
import { useWorkspace } from "@/routes/simulator/context/wrokspace";
import Nango from "@nangohq/frontend";
import AddIntegrationDialog from "./add-integration/AddIntegration";
import { useCallback, useMemo, useState } from "react";
import { CTA } from "@/routes/onboarding/getting-started/GettingStarted";
import { Callout, Container, Link, Text } from "@radix-ui/themes";
import { IntegrationEvents, mixTrackEvent } from "@/assets/mixpanel";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { integrationNameToGalleryItem } from "@/api/integration/integrationNameToGalleryItem";
import { createToast } from "vercel-toast";

const nango = new Nango({
  publicKey: "7d3dd047-cdc9-4f26-ae76-a1a75565600a",
});

function NotAvailableIntegrationsHeader({ names }: { names: string[] }) {
  return (
    <div style={{ padding: "10px 45px 0" }}>
      <Callout.Root color="red">
        <Callout.Icon>
          <ExclamationTriangleIcon />
        </Callout.Icon>
        <Callout.Text>
          {names.join(", ")} connection failed,{" "}
          <Link href="mailto:support@quack.ai">contact support</Link> for
          further assistance.{" "}
        </Callout.Text>
      </Callout.Root>
    </div>
  );
}

export function Integrations() {
  const {
    data: integrations,
    isLoading: loadingIntegrations,
    refetch: reloadIntegrations,
  } = useIntegrations();
  const [integrationType, setIntegrationType] = useState<IntegrationType>();
  const [integrationName, setIntegrationName] = useState<IntegrationName>();

  const createNangoIntegration = useCreateNangoIntegration({
    reloadIntegrations,
  });

  const handleKnowledgeBaseClick = useCallback((name: IntegrationName) => {
    setIntegrationType("KNOWLEDGE_BASE");
    setIntegrationName(name);
  }, []);

  const handleTaskManagementClick = useCallback((name: IntegrationName) => {
    setIntegrationType("TASK_MANAGEMENT");
    setIntegrationName(name);
  }, []);

  const handleTicketSystemClick = useCallback((name: IntegrationName) => {
    setIntegrationType("TICKET_SYSTEM");
    setIntegrationName(name);
  }, []);

  const handleCreateIntegration = useCallback(
    (name: IntegrationName, type: IntegrationType) => {
      mixTrackEvent({
        event: IntegrationEvents.CLICK_INTEGRATION,
        properties: {
          name,
          type,
        },
      });

      if (type === "TICKET_SYSTEM" && isTicketSystemIntegrationName(name)) {
        if (supportedTicketSystems.includes(name)) {
          isNangoSupportedIntegrationName(name)
            ? createNangoIntegration({ name, type })
            : handleTicketSystemClick(name);
        }
      } else if (
        type === "KNOWLEDGE_BASE" &&
        isKnowledgeBaseIntegrationName(name)
      ) {
        if (supportedKnowledgeBase.includes(name)) {
          isNangoSupportedIntegrationName(name)
            ? createNangoIntegration({ name, type })
            : handleKnowledgeBaseClick(name);
        }
      } else if (type === "CHAT" && isChatIntegrationName(name)) {
        if (supportedChat.includes(name)) {
          isNangoSupportedIntegrationName(name)
            ? createNangoIntegration({ name, type })
            : null;
        }
      } else if (
        type === "TASK_MANAGEMENT" &&
        isTaskManagementIntegrationName(name)
      ) {
        if (supportedTaskManagement.includes(name)) {
          isNangoSupportedIntegrationName(name)
            ? createNangoIntegration({ name, type })
            : null;
        }
      }
    },
    [
      createNangoIntegration,
      handleKnowledgeBaseClick,
      handleTicketSystemClick,
      handleTaskManagementClick,
    ]
  );

  const slackIntegrationExists = useMemo(
    () => integrations?.some((i) => i.type === "CHAT"),
    [integrations]
  );

  const notAvailableIntegrationNames = useMemo(() => {
    return integrations
      ?.filter((i) => i.status === "NOT_AVAILABLE")
      .map((i) => integrationNameToGalleryItem[i.name]?.title);
  }, [integrations]);

  return (
    <Container size="4" mx="auto">
      <div style={{ padding: "20px 45px 0" }}>
        <Text size={"5"} weight={"medium"} as={"div"}>
          Data Sources
        </Text>

        <Text size={"3"} color={"gray"} as={"div"}>
          Connect data sources to enable Quack to assist, analyze, and act.
        </Text>
      </div>

      {notAvailableIntegrationNames.length ? (
        <NotAvailableIntegrationsHeader names={notAvailableIntegrationNames} />
      ) : (
        <></>
      )}
      <SelectIntegration
        type="TICKET_SYSTEM"
        onClick={handleCreateIntegration}
        existingIntegrations={integrations}
        options={existingTicketSystems}
      />

      <SelectIntegration
        type="KNOWLEDGE_BASE"
        onClick={handleCreateIntegration}
        existingIntegrations={integrations}
        options={supportedKnowledgeBase}
        isMultipleAllowed
      />

      <SelectIntegration
        type="TASK_MANAGEMENT"
        onClick={handleCreateIntegration}
        existingIntegrations={integrations}
        options={supportedTaskManagement}
      />

      <CTA
        disabled={slackIntegrationExists}
        reloadIntegrations={reloadIntegrations}
      />

      {integrationType && (
        <AddIntegrationDialog
          type={integrationType}
          preSelectedName={integrationName}
          loading={loadingIntegrations}
          existingIntegrations={integrations as Integration[]}
          onClose={() => setIntegrationType(undefined)}
          reloadIntegrations={reloadIntegrations}
        />
      )}
    </Container>
  );
}

export function useCreateNangoIntegration({
  reloadIntegrations,
}: {
  reloadIntegrations: () => Promise<unknown>;
}) {
  const { workspace } = useWorkspace();
  const { mutateAsync: createIntegration } = useCreateIntegrationV2();

  return useCallback(
    async (req: ConnectIntegrationRequest) => {
      try {
        const result = await nango.auth(req.name, workspace);

        console.log(
          `Integration to ${
            req.name
          } created Successfully, result: ${JSON.stringify(result)}`,
          {
            type: "success",
          }
        );

        const createIntegrationVariables = {
          ...req,
          isNangoAuth: true,
        };

        if (req.name === "confluence") {
          createIntegrationVariables.type = "WIKI";
        }

        await createIntegration(createIntegrationVariables);

        await reloadIntegrations();
        createToast(
          `Integration to ${
            integrationNameToGalleryItem[req.name].title
          } created successfuly`,
          {
            type: "success",
            timeout: 5000,
          }
        );
      } catch (error) {
        console.log(`Something went wrong ${error}`, {
          type: "error",
        });
      }
    },
    [workspace]
  );
}
