export interface Instruction {
  content: string;
  subTopic: string | null;
  topic: string | null;
  type: "general" | "manual" | "brief";
}

export interface ManualInstruction {
  content: string;
  subTopic: string | null;
  topic: string;
  type: "manual";
}

export interface Release {
  id: number;
  releasePolicy: any; // JSON type
  tenantId: string;
  status: ReleaseStatus;
  releaseConditions: ReleaseCondition[];
  conversations: ReleaseConversation[];
  createdAt: Date;
  updatedAt: Date;
  publishedAt?: Date;
}

export interface ReleaseCondition {
  id: number;
  releaseId: number;
  tenantId: string;
  conditionType: ConditionType;
  conditionValue: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ReleaseConversation {
  id: number;
  releaseComments: ReleaseComment[];
  releaseId?: number;
  topicId?: number;
  tenantId: string;
  feedback: ReleaseConversationFeedback;
  feedbackComment?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ReleaseComment {
  id: number;
  isAgent: boolean;
  comment: string;
  releaseConversationId?: number;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum ReleaseConversationFeedback {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  NONE = "NONE",
}

export enum ReleaseStatus {
  draft = "draft",
  published = "published",
}

export enum ConditionType {
  TYPE_A = "TYPE_A",
  TYPE_B = "TYPE_B",
}

export enum Topic {
  TOPIC_A = "TOPIC_A",
  TOPIC_B = "TOPIC_B",
}

export type TTopic = {
  id: number;
  name?: string;
  topic: string;
  owner: string;
  tenant: string;
  enabledPolicies: string[];
};
