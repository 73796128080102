import { useParams } from "react-router-dom";
import { useSimulatorConfiguration } from "./useSimulatorConfiguration";
import { ConfigurationViewGuard } from "./parts/ConfigurationViewGuard";
import { QuackchatEndOfConversationConfiguration } from "./quackchat-channel/QuackchatEndOfConversationConfiguration";

export function EndOfConversationConfiguration() {
  const { simulatorId } = useParams();
  const { simulator } = useSimulatorConfiguration(simulatorId);

  const channel = simulator?.channel;

  return (
    <ConfigurationViewGuard view={"end_of_conversation"}>
      {channel === "autonomous_quackchat" && (
        <QuackchatEndOfConversationConfiguration />
      )}
    </ConfigurationViewGuard>
  );
}
