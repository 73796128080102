import { useCallback, useMemo, useState } from "react";
import { Flex } from "@radix-ui/themes";
import { DateRange } from "@/components/shared/date-range-picker/DateRangePicker";
import { MetricsScope, metricsScopes } from "@/api/reports/reports.model";
import { useQaEvaluationMetrics } from "@/api/reports/reports.api";
import ExploreTable, { ColumnType } from "@/routes/explore/ExploreTable";
import WidgetCard from "../../parts/WidgetCard";
import { QaScoresChart, ScoresValue } from "../../parts/ScoresChart";
import { MetricsScopeToggle } from "../../parts/MetricsScopeToggle";
import styles from "../../Reports.module.scss";

type MetricRow = {
  [key: string]: string | number | ScoresValue | undefined;
};

function cellGenerator(
  values: MetricRow,
  { key }: ColumnType<keyof MetricRow>
) {
  const value = values[key];

  if (
    value == null ||
    (typeof value === "number" && (isNaN(value) || value === 0))
  ) {
    return <>-</>;
  }

  if (value instanceof Array) {
    return <QaScoresChart data={value} />;
  }

  return <>{value}</>;
}

function cellValueGetter(values: MetricRow, key: keyof MetricRow) {
  const value = values[key];

  if (value instanceof Array) {
    const total = value.reduce((acc, { score }) => acc + score, 0);
    return total / value.length;
  }

  return value;
}

interface QaEvaluationMetricsWidgetProps {
  dateRange: DateRange;
}

export function QaEvaluationMetricsWidget({
  dateRange,
}: QaEvaluationMetricsWidgetProps) {
  const [scope, setScope] = useState<MetricsScope>(metricsScopes[0]);

  const { data, isLoading, isError, refetch } = useQaEvaluationMetrics({
    ...dateRange,
    scope,
  });

  const [columns, rows] = useMemo(() => {
    const columns: ColumnType<keyof MetricRow>[] = [
      {
        key: "name",
        label: scope === "agents" ? "Agent" : "Team",
        minWidth: "400px",
      },
      { key: "evaluationVolume", label: "Evaluations Vol", width: "160px" },
    ];

    if (data == null) {
      return [columns];
    }

    const rows = data.map((item) => {
      const { id, name, dailyScores } = item;

      const all: Record<string, ScoresValue> = {};

      Object.entries(dailyScores).forEach(([date, scores]) => {
        Object.entries(scores).forEach(([metric, score]) => {
          if (!columns.some((column) => column.key === metric)) {
            columns.push({ key: metric, label: metric, width: "160px" });
          }

          if (!all[metric]) {
            all[metric] = [];
          }

          if (score != null) {
            all[metric].push({ date, score });
          }
        });
      });

      const evaluationVolume = Object.values(all).reduce((acc, scores) => {
        return acc + scores.length;
      }, 0);

      return {
        id,
        name,
        evaluationVolume,
        ...all,
      };
    });

    return [columns, rows];
  }, [scope, data]);

  const handleReload = useCallback(() => {
    void refetch();
  }, []);

  return (
    <WidgetCard fullWidth>
      <Flex justify={"between"} align={"start"}>
        <WidgetCard.Header>
          <WidgetCard.Title value={"QA Evaluation Metrics Snapshot"} />
          <WidgetCard.Description
            value={"QA scores across evaluation criteria"}
          />
        </WidgetCard.Header>

        <Flex p={"5"}>
          <MetricsScopeToggle value={scope} onChange={setScope} />
        </Flex>
      </Flex>

      <WidgetCard.Content
        noPadding
        isError={isError}
        isEmpty={!isLoading && !data}
        onReload={handleReload}
        className={styles.CardTableContent}
      >
        <ExploreTable<MetricRow>
          columns={columns}
          rows={rows}
          isLoading={isLoading}
          cellGenerator={cellGenerator}
          cellValueGetter={cellValueGetter}
          defaultSortState={{ key: "evaluationVolume", direction: "asc" }}
        />
      </WidgetCard.Content>
    </WidgetCard>
  );
}
