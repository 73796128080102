import { Dict } from "mixpanel-browser";
import { ActivationEvents } from "./activation";
import { KnowledgeEvents } from "./knowledge";
import { GeneralEvents } from "./general";
import { ReportEvents } from "./report";
import { OpportunitiesEvents } from "./opportunities";
import { SimulatorEvents } from "./simulator";
import { IntegrationEvents } from "./integration";
import { QualityEvents } from "./quality";
import { ExploreEvents } from "./explore";
import { InteractionInformationEvents } from "./interactionInformation";

type MixpanelEvents =
  | ActivationEvents
  | KnowledgeEvents
  | GeneralEvents
  | ReportEvents
  | OpportunitiesEvents
  | SimulatorEvents
  | IntegrationEvents
  | QualityEvents
  | ExploreEvents
  | InteractionInformationEvents;

declare global {
  interface Window {
    analytics: any;
    track_properties: any;
  }
}

export const mixSetUserProps = (setOnce: boolean, properties: Dict) => {
  window.analytics.identify(properties.id, properties);
};

export const mixRegisterSuperProps = (properties: Dict) =>
  (window.track_properties = {
    ...(window.track_properties || {}),
    ...properties,
  });
export const mixUnregisterSuperProps = (properties: string[]) => {
  properties.forEach((property) => {
    window.track_properties = {
      ...(window.track_properties || {}),
      [property]: null,
    };
  });
};

export const mixTrackEvent = ({
  event,
  properties,
}: {
  event: MixpanelEvents;
  properties?: Dict;
}) =>
  window.analytics.track(`OS_${event}`, {
    ...(window.track_properties || {}),
    ...(properties || {}),
  });

export {
  ActivationEvents,
  KnowledgeEvents,
  GeneralEvents,
  ReportEvents,
  OpportunitiesEvents,
  SimulatorEvents,
  IntegrationEvents,
  QualityEvents,
  ExploreEvents,
  InteractionInformationEvents,
};
