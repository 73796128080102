import { useCallback } from "react";
import { Flex } from "@radix-ui/themes";
import Select, { SelectOption } from "../../components/shared/select";
import { useFilter } from "./context";
import {
  groupByTypes,
  isGroupByType,
  groupByToLabel,
} from "./context/FilterContext.model";
import { PaddingIcon } from "@radix-ui/react-icons";
import styles from "./styles.module.scss";

const groupByOptions: SelectOption[] = groupByTypes.map((value) => ({
  label: groupByToLabel[value],
  value,
}));

const groupByOptionsForConversation: SelectOption[] = [
  "topics",
  // "categories",
].map((value) => ({
  label: "Topics",
  value,
}));

interface GroupByProps {
  type?: "ticket" | "conversation";
}

export function GroupBy({ type = "ticket" }: GroupByProps) {
  const { groupBy, setGroupBy } = useFilter();

  const handleOnChange = useCallback(
    (newGroupBy: string) => {
      if (isGroupByType(newGroupBy)) {
        setGroupBy(newGroupBy);
      }
    },
    [setGroupBy]
  );

  return (
    <Flex>
      <Select
        size={"small"}
        value={groupBy}
        onChange={handleOnChange}
        options={
          type === "ticket" ? groupByOptions : groupByOptionsForConversation
        }
        classes={{
          trigger: styles.GroupBy,
        }}
        startIcon={<PaddingIcon />}
      />
    </Flex>
  );
}
