import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  CSSProperties,
} from "react";
import { Avatar, Flex } from "@radix-ui/themes";
import { Popover } from "../popover";
import { InlineTextField } from "../inputs/inilie-text-field/InlineTextField";
import styles from "./ColorPicker.module.scss";

interface HSVColor {
  h: number;
  s: number;
  v: number;
  a: number;
}

interface RGBColor {
  r: number;
  g: number;
  b: number;
  a: number;
}

// Hex to RGB
function hexToRgb(hex: string): RGBColor {
  // Remove # if present
  hex = hex.replace(/^#/, "");

  let r,
    g,
    b,
    a = 1;

  if (hex.length === 3) {
    r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
    g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
    b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
  } else if (hex.length === 6) {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else if (hex.length === 8) {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
    a = parseInt(hex.substring(6, 8), 16) / 255;
  } else {
    // Default to black if invalid
    r = 0;
    g = 0;
    b = 0;
  }

  return { r, g, b, a };
}

// RGB to HSV
function rgbToHsv(r: number, g: number, b: number, a: number = 1): HSVColor {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;

  let h = 0;
  const s = max === 0 ? 0 : delta / max;
  const v = max;

  if (delta === 0) {
    h = 0;
  } else if (max === r) {
    h = ((g - b) / delta) % 6;
  } else if (max === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);
  if (h < 0) h += 360;

  return {
    h,
    s: Math.round(s * 100),
    v: Math.round(v * 100),
    a,
  };
}

// Hex to HSV
function hexToHsv(hex: string): HSVColor {
  const rgb = hexToRgb(hex);
  return rgbToHsv(rgb.r, rgb.g, rgb.b, rgb.a);
}

interface ColorPickerProps {
  value?: string;
  onChange: (color: string) => void;
  showAlpha?: boolean;
}

export function ColorPicker({
  value = "#000000",
  onChange,
  showAlpha = false,
}: ColorPickerProps) {
  // Parse initial color
  const [color, setColor] = useState<HSVColor>(() => hexToHsv(value));
  const [isDraggingHue, setIsDraggingHue] = useState(false);
  const [isDraggingSV, setIsDraggingSV] = useState(false);
  const [isDraggingAlpha, setIsDraggingAlpha] = useState(false);

  const hueSliderRef = useRef<HTMLDivElement>(null);
  const svPanelRef = useRef<HTMLDivElement>(null);
  const alphaSliderRef = useRef<HTMLDivElement>(null);

  // Convert HSV to RGB
  const hsvToRgb = useCallback(
    (h: number, s: number, v: number, a: number = 1): RGBColor => {
      h = h % 360;
      s = s / 100;
      v = v / 100;

      let c = v * s;
      let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
      let m = v - c;
      let r = 0,
        g = 0,
        b = 0;

      if (h >= 0 && h < 60) {
        r = c;
        g = x;
        b = 0;
      } else if (h >= 60 && h < 120) {
        r = x;
        g = c;
        b = 0;
      } else if (h >= 120 && h < 180) {
        r = 0;
        g = c;
        b = x;
      } else if (h >= 180 && h < 240) {
        r = 0;
        g = x;
        b = c;
      } else if (h >= 240 && h < 300) {
        r = x;
        g = 0;
        b = c;
      } else if (h >= 300 && h < 360) {
        r = c;
        g = 0;
        b = x;
      }

      return {
        r: Math.round((r + m) * 255),
        g: Math.round((g + m) * 255),
        b: Math.round((b + m) * 255),
        a,
      };
    },
    []
  );

  // RGB to Hex
  const rgbToHex = useCallback(
    (r: number, g: number, b: number, a: number = 1): string => {
      const toHex = (c: number): string => {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };

      const hex = `#${toHex(r)}${toHex(g)}${toHex(b)}`;

      if (showAlpha && a < 1) {
        const alpha = Math.round(a * 255);
        return `${hex}${toHex(alpha)}`;
      }

      return hex;
    },
    [showAlpha]
  );

  // HSV to Hex
  const hsvToHex = useCallback(
    (h: number, s: number, v: number, a: number = 1): string => {
      const rgb = hsvToRgb(h, s, v, a);
      return rgbToHex(rgb.r, rgb.g, rgb.b, rgb.a);
    },
    [hsvToRgb, rgbToHex]
  );

  const handleColorChange = useCallback(
    (newColor: HSVColor) => {
      setColor(newColor);
      const hexColor = hsvToHex(newColor.h, newColor.s, newColor.v, newColor.a);
      onChange(hexColor);
    },
    [onChange]
  );

  // Update internal state when prop value changes
  useEffect(() => {
    try {
      setColor(hexToHsv(value));
    } catch (e) {
      console.error("Invalid color value:", value);
    }
  }, [value]);

  // Handle hue slider
  const handleHueMouseDown = useCallback(
    (e: React.MouseEvent) => {
      if (!hueSliderRef.current) return;

      setIsDraggingHue(true);

      const rect = hueSliderRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
      const hue = Math.round((x / rect.width) * 360);

      handleColorChange({ ...color, h: hue });

      document.addEventListener("mousemove", handleHueMouseMove);
      document.addEventListener("mouseup", handleHueMouseUp);
    },
    [color, handleColorChange]
  );

  const handleHueMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDraggingHue || !hueSliderRef.current) return;

      const rect = hueSliderRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
      const hue = Math.round((x / rect.width) * 360);

      handleColorChange({ ...color, h: hue });
    },
    [isDraggingHue, color, handleColorChange]
  );

  const handleHueMouseUp = useCallback(() => {
    setIsDraggingHue(false);
    document.removeEventListener("mousemove", handleHueMouseMove);
    document.removeEventListener("mouseup", handleHueMouseUp);
  }, [handleHueMouseMove]);

  // Handle SV panel
  const handleSVMouseDown = useCallback(
    (e: React.MouseEvent) => {
      if (!svPanelRef.current) return;

      setIsDraggingSV(true);

      const rect = svPanelRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
      const y = Math.max(0, Math.min(e.clientY - rect.top, rect.height));

      const saturation = Math.round((x / rect.width) * 100);
      const value = Math.round(100 - (y / rect.height) * 100);

      handleColorChange({ ...color, s: saturation, v: value });

      document.addEventListener("mousemove", handleSVMouseMove);
      document.addEventListener("mouseup", handleSVMouseUp);
    },
    [color, handleColorChange]
  );

  const handleSVMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDraggingSV || !svPanelRef.current) return;

      const rect = svPanelRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
      const y = Math.max(0, Math.min(e.clientY - rect.top, rect.height));

      const saturation = Math.round((x / rect.width) * 100);
      const value = Math.round(100 - (y / rect.height) * 100);

      handleColorChange({ ...color, s: saturation, v: value });
    },
    [isDraggingSV, color, handleColorChange]
  );

  const handleSVMouseUp = useCallback(() => {
    setIsDraggingSV(false);
    document.removeEventListener("mousemove", handleSVMouseMove);
    document.removeEventListener("mouseup", handleSVMouseUp);
  }, [handleSVMouseMove]);

  // Handle alpha slider
  const handleAlphaMouseDown = useCallback(
    (e: React.MouseEvent) => {
      if (!alphaSliderRef.current || !showAlpha) return;

      setIsDraggingAlpha(true);

      const rect = alphaSliderRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
      const alpha = parseFloat((x / rect.width).toFixed(2));

      handleColorChange({ ...color, a: alpha });

      document.addEventListener("mousemove", handleAlphaMouseMove);
      document.addEventListener("mouseup", handleAlphaMouseUp);
    },
    [showAlpha, color, handleColorChange]
  );

  const handleAlphaMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDraggingAlpha || !alphaSliderRef.current) return;

      const rect = alphaSliderRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
      const alpha = parseFloat((x / rect.width).toFixed(2));

      handleColorChange({ ...color, a: alpha });
    },
    [isDraggingAlpha, color, handleColorChange]
  );

  const handleAlphaMouseUp = useCallback(() => {
    setIsDraggingAlpha(false);
    document.removeEventListener("mousemove", handleAlphaMouseMove);
    document.removeEventListener("mouseup", handleAlphaMouseUp);
  }, [handleAlphaMouseMove]);

  // Handle hex input
  const handleHexChange = useCallback(
    (hexValue: string) => {
      // let hexValue = e.target.value;

      // Make sure the input starts with #
      if (!hexValue.startsWith("#")) {
        hexValue = "#" + hexValue;
      }

      // Only update if it's a valid hex
      const hexRegex = showAlpha
        ? /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/
        : /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/;

      if (hexRegex.test(hexValue)) {
        try {
          const newColor = hexToHsv(hexValue);
          handleColorChange(newColor);
        } catch (e) {
          console.error("Invalid hex color", e);
        }
      }
    },
    [showAlpha, handleColorChange]
  );

  // Calculate hex value
  const hexValue = hsvToHex(color.h, color.s, color.v, color.a);

  // Position of the hue slider thumb
  const huePosition = `${(color.h / 360) * 100}%`;

  // Position of the SV panel cursor
  const svCursorStyle = {
    left: `${color.s}%`,
    top: `${100 - color.v}%`,
  };

  // Background color of the SV panel - pure hue
  const svBackgroundColor = hsvToHex(color.h, 100, 100);

  return (
    <Flex gap={"4"} align={"center"}>
      <Popover>
        <Popover.Trigger>
          <span>
            <Avatar
              size={"2"}
              fallback={<></>}
              className={styles.Avatar}
              style={
                {
                  "--selected-color": hexValue,
                } as CSSProperties
              }
            />
          </span>
        </Popover.Trigger>

        <Popover.Content align="start" side="bottom">
          <Flex
            direction={"column"}
            width={"240px"}
            style={{ padding: "4px", gap: "8px" }}
          >
            <div
              className={styles.SaturationValue}
              ref={svPanelRef}
              onMouseDown={handleSVMouseDown}
            >
              <div
                className={styles.SvBackground}
                style={{ backgroundColor: svBackgroundColor }}
              ></div>
              <div className={styles.Saturation}></div>
              <div className={styles.Value}></div>
              <div className={styles.SvCursor} style={svCursorStyle}></div>
            </div>

            <div className={styles.HueSlider}>
              <div
                className={styles.HueSliderTrack}
                ref={hueSliderRef}
                onMouseDown={handleHueMouseDown}
              >
                <div
                  className={styles.HueSliderThumb}
                  style={{ left: huePosition }}
                ></div>
              </div>
            </div>
          </Flex>
        </Popover.Content>
      </Popover>

      <InlineTextField value={hexValue} onChange={handleHexChange} />
    </Flex>
  );
}
