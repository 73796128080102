import React, { useMemo, useState } from "react";
import cx from "classnames";
import { Button, Text, Badge } from "@radix-ui/themes";
import Dialog from "@/components/shared/dialog/Dialog";

import { ExploreRow } from "@/api/useExplore/Explore.model";
import { useAuthorizedQuery } from "@/api";
import { toTitleCase } from "@/assets/functions";
import { useAuth0 } from "@auth0/auth0-react";

import styles from "./styles.module.scss";
import {
  SeeAllButton,
  useExploreHelper,
  BarChartHelper,
  TicketsVolumeByCategories,
} from "./ExploreDashboard";
import { exploreCellGenerator } from "./CellGenerator";
import { FilterValue } from "./context/FilterContext.model";
import ExploreTable, { ColumnType } from "./ExploreTable";
import { useFilter } from "./context";
import { useNavigate } from "react-router-dom";
import { params } from "@/params";

const jiraTicketsByTopicsValue: Omit<FilterValue, "dateRange"> = {
  groupBy: "topics",
  stringSliceBy: {
    and: [
      {
        type: "tags",
        operation: "or",
        values: [
          {
            type: "tags",
            operation: "eq",
            value: "jira_escalated",
          },
        ],
      },
    ],
  },
};

function JiraTicketsByTopics() {
  const { data: exploreData, isLoading } = useExploreHelper(
    jiraTicketsByTopicsValue
  );

  const data = useMemo(() => {
    const sorted = exploreData.sort(
      (a, b) => (b.avgVolume ?? 0) - (a.avgVolume ?? 0)
    );

    return sorted.slice(0, 5).map((row) => ({
      name: row.name,
      volume: row.avgVolume,
    }));
  }, [exploreData]);

  return (
    <div className={cx(styles.Widget, styles.medium)}>
      <div className={styles.Header}>
        <div className={styles.Title}>Topics Requiring Escalations</div>

        <SeeAllButton filterValue={jiraTicketsByTopicsValue} />
      </div>

      <BarChartHelper data={data} isLoading={isLoading} />
    </div>
  );
}

const ageTimeToResolutionJiraTicketsValue: Omit<FilterValue, "dateRange"> = {
  groupBy: "topics",
  stringSliceBy: {
    and: [
      {
        type: "tags",
        operation: "or",
        values: [
          {
            type: "tags",
            operation: "eq",
            value: "jira_escalated",
          },
        ],
      },
    ],
  },
};

function AvgTimeToResolutionJiraTickets() {
  const { data: exploreData, isLoading } = useExploreHelper(
    ageTimeToResolutionJiraTicketsValue
  );

  const data = useMemo(() => {
    const sorted = exploreData.sort(
      (a, b) => (b.avgTimeToResolve ?? 0) - (a.avgTimeToResolve ?? 0)
    );

    return sorted.slice(0, 8).map((row) => ({
      name: row.name,
      volume: row.avgTimeToResolve
        ? row.avgTimeToResolve / (60 * 24)
        : undefined,
    }));
  }, [exploreData]);

  return (
    <div className={cx(styles.Widget, styles.large)}>
      <div className={styles.Header}>
        <div className={styles.Title}>
          Escalation Effectiveness - Escalated Tickets Resolution Time in Days
        </div>

        <SeeAllButton filterValue={ageTimeToResolutionJiraTicketsValue} />
      </div>

      <BarChartHelper data={data} isLoading={isLoading} />
    </div>
  );
}

export function useGetMerchantInsights() {
  const { user } = useAuth0();
  const { dateRange } = useFilter();

  const searchParams = useMemo(() => {
    const { dateFrom, dateTo } = dateRange;

    return new URLSearchParams([
      ["from", dateFrom],
      ["to", dateTo],
    ]);
  }, [dateRange]);

  return useAuthorizedQuery<{
    avgSentimentMerchants: {
      merchant: string;
      avgSentiment: number | undefined;
      volume: number;
      resolutionRate: number | undefined;
      avgTimeToResolve: number | undefined;
    }[];
    merchantVolume: {
      merchant: string;
      volume: number;
    }[];
  }>({
    queryKey: `useGetMerchantInsights_${searchParams.toString()}`,
    url: `${
      params.API_URL
    }/platform/insight/merchant-insights?${searchParams.toString()}`,
    method: "GET",
    enabled: !!user,
  });
}

function MerchantInsights() {
  const { data: merchentData, isLoading } = useGetMerchantInsights();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sentimentDialogOpen, setSentimentDialogOpen] = useState(false);

  const data = useMemo(() => {
    if (!merchentData) return [];

    const formattedData = merchentData.merchantVolume.map((item) => ({
      name: toTitleCase(item.merchant ?? "") || "Unknown",
      avgVolume: item.volume,
    }));

    const sorted = formattedData.sort(
      (a, b) => (b.avgVolume ?? 0) - (a.avgVolume ?? 0)
    );

    return sorted.slice(0, 10).map((row) => ({
      name: row.name,
      volume: row.avgVolume,
    }));
  }, [merchentData]);

  const rows = useMemo<ExploreRow[]>(() => {
    return (
      merchentData?.avgSentimentMerchants
        ?.sort((a, b) => (a.avgSentiment ?? 0) - (b.avgSentiment ?? 0))
        .slice(0, 8)
        .map((value, index) => {
          return {
            id: index,
            name: toTitleCase(value.merchant ?? "") || "Unknown",
            avgVolume: value.volume,
            avgSentiment: value.avgSentiment,
            resolutionRate: value.resolutionRate,
            avgTimeToResolve: value.avgTimeToResolve,
          };
        }) ?? []
    );
  }, [merchentData]);

  return (
    <>
      <div className={cx(styles.Widget, styles.medium)}>
        <div className={styles.Header}>
          <div className={styles.Title}>Top Merchants</div>
          <Button
            color="gray"
            variant="ghost"
            highContrast
            size="2"
            onClick={() => setDialogOpen(true)}
          >
            See All
          </Button>
        </div>

        <BarChartHelper isVertical data={data} isLoading={isLoading} />
      </div>
      <div className={cx(styles.Widget, styles.largerTableSize)}>
        <div className={styles.Header}>
          <div className={styles.Title}>Lowest sentiment by Merchant</div>
          <Button
            color="gray"
            variant="ghost"
            highContrast
            size="2"
            onClick={() => setSentimentDialogOpen(true)}
          >
            See All
          </Button>
        </div>

        <div className={styles.TableWrapper}>
          {!isLoading && rows.length === 0 ? (
            <div className={styles.EmptyState}>
              <div
                className={styles.Title}
              >{`It seems there's no data matching your criteria.`}</div>
              <div
                className={styles.Description}
              >{`Try different date range.`}</div>
            </div>
          ) : (
            <ExploreTable<ExploreRow>
              columns={[
                {
                  label: "Customers",
                  key: "name",
                },
                {
                  label: "Volume",
                  key: "avgVolume",
                },
                {
                  label: "Avg. Sentiment",
                  key: "avgSentiment",
                },
              ]}
              rows={rows}
              cellGenerator={exploreCellGenerator}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <Dialog.Content size="large">
          <Dialog.Title>All Merchants</Dialog.Title>
          <div className={styles.DialogBody}>
            <ExploreTable<ExploreRow>
              columns={[
                {
                  label: "Merchants",
                  key: "name",
                },
                {
                  label: "Volume",
                  key: "avgVolume",
                  width: "80px",
                },
              ]}
              rows={
                merchentData?.merchantVolume
                  .map((item, index) => ({
                    id: index,
                    name: toTitleCase(item.merchant ?? "") || "Unknown",
                    avgVolume: item.volume,
                  }))
                  .filter((row) => row.name !== "Unknown")
                  .sort((a, b) => (b.avgVolume ?? 0) - (a.avgVolume ?? 0)) ?? []
              }
              cellGenerator={exploreCellGenerator}
              isLoading={isLoading}
            />
          </div>
          <Dialog.Close>
            <Button color="gray" variant="soft">
              Close
            </Button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog>

      <Dialog open={sentimentDialogOpen} onOpenChange={setSentimentDialogOpen}>
        <Dialog.Content size="large">
          <Dialog.Title>All Merchants by Sentiment</Dialog.Title>
          <div className={styles.DialogBody}>
            <ExploreTable<ExploreRow>
              columns={[
                {
                  label: "Merchants",
                  key: "name",
                },
                {
                  label: "Volume",
                  key: "avgVolume",
                },
                {
                  label: "Avg. Sentiment",
                  key: "avgSentiment",
                },
                {
                  label: "Resolution Rate",
                  key: "resolutionRate",
                },
                {
                  label: "Avg. Resolution Time",
                  key: "avgTimeToResolve",
                },
              ]}
              rows={
                merchentData?.avgSentimentMerchants
                  ?.map((value, index) => ({
                    id: index,
                    name: toTitleCase(value.merchant ?? "") || "Unknown",
                    avgVolume: value.volume,
                    avgSentiment: value.avgSentiment,
                    resolutionRate: value.resolutionRate,
                    avgTimeToResolve: value.avgTimeToResolve,
                  }))
                  .filter((row) => row.name !== "Unknown")
                  .sort(
                    (a, b) => (a.avgSentiment ?? 0) - (b.avgSentiment ?? 0)
                  ) ?? []
              }
              cellGenerator={exploreCellGenerator}
              isLoading={isLoading}
            />
          </div>
          <Dialog.Close>
            <Button color="gray" variant="soft">
              Close
            </Button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog>
    </>
  );
}

const columns: ColumnType<keyof ExploreRow>[] = [
  {
    label: "Agents",
    key: "name",
    width: "80%",
  },
  {
    label: "Avg. QA Score",
    key: "avgQaScore",
    width: "126px",
  },
];

const performingAgentsValue: Omit<FilterValue, "dateRange"> = {
  groupBy: "agents",
  stringSliceBy: {
    and: [
      {
        type: "teams",
        operation: "or",
        values: [
          { type: "teams", operation: "eq", value: "Tech Support Tier 1" },
          { type: "teams", operation: "eq", value: "Tech Support Tier 2" },
        ],
      },
      {
        type: "agent",
        operation: "or",
        values: [{ type: "agent", operation: "neq", value: "Ruti Roshi" }],
      },
    ],
  },
};

function PerformingAgents() {
  const { data: exploreData, isLoading } = useExploreHelper(
    performingAgentsValue
  );

  const under = useMemo(() => {
    return exploreData
      .filter((row) => row.avgQaScore != null && (row.avgQaScore ?? 0))
      .sort((a, b) => (a.avgQaScore ?? 0) - (b.avgQaScore ?? 0))
      .slice(0, 5);
  }, [exploreData]);

  const top = useMemo(() => {
    return exploreData
      .filter((row) => row.avgQaScore ?? 0)
      .sort((a, b) => (b.avgQaScore ?? 0) - (a.avgQaScore ?? 0))
      .slice(0, 5);
  }, [exploreData]);

  return (
    <div className={styles.Row}>
      <div className={cx(styles.Widget, styles.tableSize)}>
        <div className={styles.Header}>
          <div className={styles.Title}>Underperforming agents</div>
        </div>

        <div className={styles.TableWrapper}>
          {!isLoading && under.length === 0 ? (
            <div className={styles.EmptyState}>
              <div
                className={styles.Title}
              >{`It seems there's no data matching your criteria.`}</div>
              <div
                className={styles.Description}
              >{`Try different date range.`}</div>
            </div>
          ) : (
            <ExploreTable<ExploreRow>
              columns={columns}
              rows={under}
              cellGenerator={exploreCellGenerator}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>

      <div className={cx(styles.Widget, styles.tableSize)}>
        <div className={styles.Header}>
          <div className={styles.Title}>Top performing agents</div>
        </div>

        <div className={styles.TableWrapper}>
          {!isLoading && top.length === 0 ? (
            <div className={styles.EmptyState}>
              <div
                className={styles.Title}
              >{`It seems there's no data matching your criteria.`}</div>
              <div
                className={styles.Description}
              >{`Try different date range.`}</div>
            </div>
          ) : (
            <ExploreTable<ExploreRow>
              columns={columns}
              rows={top}
              cellGenerator={exploreCellGenerator}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const highestBackAndForthAgentsValue: Omit<FilterValue, "dateRange"> = {
  groupBy: "agents",
  stringSliceBy: {
    and: [
      {
        type: "teams",
        operation: "or",
        values: [
          { type: "teams", operation: "eq", value: "Tech Support Tier 1" },
          { type: "teams", operation: "eq", value: "Tech Support Tier 2" },
        ],
      },
    ],
  },
};

function HighestBackAndForthAgents() {
  const { data: exploreData, isLoading } = useExploreHelper(
    highestBackAndForthAgentsValue
  );

  const top = useMemo(() => {
    return exploreData
      .filter((row) => (row.avgQaScore ?? 0) > 3)
      .sort((a, b) => (b.avgBackAndForth ?? 0) - (a.avgBackAndForth ?? 0))
      .slice(0, 5);
  }, [exploreData]);

  return (
    <div className={cx(styles.Widget, styles.tableSize)}>
      <div className={styles.Header}>
        <div className={styles.Title}>Highest B&F agents</div>
      </div>

      <div className={styles.TableWrapper}>
        {!isLoading && top.length === 0 ? (
          <div className={styles.EmptyState}>
            <div
              className={styles.Title}
            >{`It seems there's no data matching your criteria.`}</div>
            <div
              className={styles.Description}
            >{`Try different date range.`}</div>
          </div>
        ) : (
          <ExploreTable<ExploreRow>
            columns={[
              {
                label: "Agents",
                key: "name",
                width: "80%",
              },
              {
                label: "Avg. B&F",
                key: "avgBackAndForth",
              },
            ]}
            rows={top}
            cellGenerator={exploreCellGenerator}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
}

export function useGetAgentInsights() {
  const { user } = useAuth0();

  return useAuthorizedQuery<any>({
    queryKey: `useGetAgentInsights`,
    url: `${params.API_URL}/platform/insight/agents-insights`,
    method: "GET",
    enabled: !!user,
  });
}

function AgentInsights() {
  const { data: agentData, isLoading } = useGetAgentInsights();
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  const topAgents = useMemo(() => {
    if (!agentData) return [];
    return agentData
      .sort(
        (a: { volume: any }, b: { volume: any }) =>
          (b.volume ?? 0) - (a.volume ?? 0)
      )
      .slice(0, 5)
      .map((agent: any) => ({
        id: agent.agentId,
        name: agent.name,
        volume: agent.volume,
        topic: agent.topicsVolumes?.[0]?.topic || "N/A",
      }));
  }, [agentData]);

  const allAgents = useMemo(() => {
    if (!agentData) return [];
    return agentData.map((agent: any) => ({
      id: agent.agentId,
      name: agent.name,
      volume: agent.volume,
      topTopics: agent.topicsVolumes?.map(
        (topic: { topic: string; volume: number }) => (
          <Badge key={topic.topic} color="gray" variant="soft" mr="1">
            {topic.topic} ({topic.volume})
          </Badge>
        )
      ),
      lastTicket: agent.lastTicketAssigned,
      lastTicketDate: new Date(agent.lastTicketCreatedAt).toLocaleDateString(),
    }));
  }, [agentData]);

  const onRowClick = (row: ExploreRow) => {
    navigate(
      `/explore/tickets?by=agents&view=tickets&agent=${row.name?.replaceAll(
        " ",
        "%20"
      )}`
    );
  };

  return (
    <div className={cx(styles.Widget, styles.tableSize)}>
      <div className={styles.Header}>
        <div className={styles.Title}>Agent Activity</div>
        <Button
          color="gray"
          variant="ghost"
          highContrast
          size="2"
          onClick={() => setDialogOpen(true)}
        >
          See All
        </Button>
      </div>

      <div className={styles.TableWrapper}>
        {!isLoading && topAgents.length === 0 ? (
          <div className={styles.EmptyState}>
            <div className={styles.Title}>
              {`It seems there's no data matching your criteria.`}
            </div>
            <div
              className={styles.Description}
            >{`Try different date range.`}</div>
          </div>
        ) : (
          <ExploreTable
            onRowClick={onRowClick}
            columns={[
              { label: "Agent", key: "name" },
              { label: "Volume", key: "volume" },
              { label: "Topic", key: "topic" },
            ]}
            rows={topAgents}
            cellGenerator={exploreCellGenerator}
            isLoading={isLoading}
          />
        )}
      </div>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <Dialog.Content size="large">
          <Dialog.Title>All Agents Activity</Dialog.Title>
          <div className={styles.DialogBody}>
            <ExploreTable
              onRowClick={onRowClick}
              columns={[
                { label: "Agent", key: "name" },
                { label: "Volume", key: "volume" },
                {
                  label: "Top Topics",
                  key: "topTopics",
                },
                {
                  label: "Last Ticket",
                  key: "lastTicket",
                },
                { label: "Last Ticket Created At", key: "lastTicketDate" },
              ]}
              rows={allAgents}
              cellGenerator={exploreCellGenerator}
              isLoading={isLoading}
            />
          </div>
          <Dialog.Close>
            <Button color="gray" variant="soft">
              Close
            </Button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog>
    </div>
  );
}

export function ExploreGlobaleTicketDashboard() {
  return (
    <div className={styles.ExploreDashboardScrollArea}>
      <div className={styles.Row}>
        <Text size="5">Issue Trends</Text>
      </div>
      <div className={styles.Row}>
        <JiraTicketsByTopics />
        <TicketsVolumeByCategories />
      </div>
      <div className={styles.Row}>
        <AvgTimeToResolutionJiraTickets />
      </div>

      <div className={styles.Row}>
        <Text size="5">Merchants</Text>
      </div>
      <div className={styles.Row}>
        <MerchantInsights />
      </div>

      <div className={styles.Row}>
        <Text size="5">Agent Performance</Text>
      </div>
      <div className={styles.Row}>
        <PerformingAgents />
      </div>
      <div className={styles.Row}>
        <HighestBackAndForthAgents />
        <AgentInsights />
      </div>
    </div>
  );
}
