import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, DropdownMenu } from "@radix-ui/themes";
import {
  CheckIcon,
  ExitIcon,
  GearIcon,
  MoonIcon,
  SunIcon,
} from "@radix-ui/react-icons";
import styles from "./styles.module.scss";
import { ThemeContext } from "@/components/Theme";
import { useGetAccess } from "@/api/useAccess";
import { FeatureAccessContext } from "@/context/featureAccess";

export function AppMenu() {
  const navigate = useNavigate();
  const { logout, user } = useAuth0();
  const { data: getAccessData } = useGetAccess(!!user);
  const { selectedWorkspace, setSelectedWorkspace } =
    useContext(FeatureAccessContext);
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (getAccessData && getAccessData.tenants?.[0] && !selectedWorkspace) {
      setSelectedWorkspace(getAccessData.tenants[0]);
    }
  }, [JSON.stringify(getAccessData)]);

  return (
    <div className={styles.account}>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <div>
            <Button color="gray" variant="outline" size="2">
              {selectedWorkspace ? selectedWorkspace : user?.owner}
              <DropdownMenu.TriggerIcon />
            </Button>
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content variant="soft" color="gray">
          <DropdownMenu.Group>
            <DropdownMenu.Label>Choose your workspace</DropdownMenu.Label>
            {getAccessData?.tenants?.map((t: string) => {
              return (
                <DropdownMenu.Item
                  shortcut=""
                  onClick={() => setSelectedWorkspace(t)}
                >
                  {t}
                  {selectedWorkspace === t && <CheckIcon />}
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Group>
          <DropdownMenu.Separator />
          <DropdownMenu.Item shortcut="" onClick={() => navigate("/settings")}>
            <GearIcon />
            Settings
          </DropdownMenu.Item>
          <DropdownMenu.Item shortcut="" onClick={() => toggleTheme()}>
            {isDarkMode ? <SunIcon /> : <MoonIcon />}
            Switch mode
          </DropdownMenu.Item>
          <DropdownMenu.Separator />

          <DropdownMenu.Item
            shortcut=""
            onClick={() =>
              logout({
                logoutParams: { returnTo: window.location.origin },
              })
            }
          >
            <ExitIcon />
            Logout
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
}
