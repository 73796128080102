import { useState, useEffect, useCallback } from "react";
import { Release, ReleasePolicyItem } from "../../api/simulator";
import { useSimulatorConfiguration } from "./useSimulatorConfiguration";
import {
  isKnowledgeBaseIntegrationName,
  isTicketSystemIntegrationName,
} from "../../api/integration/Integration.model";

export type GeneralPayload = {
  sources?: string[];
  includeLink?: boolean;
  styleAndTone?: string;
  toneOfVoice?: string;
  openingLine?: string;
  closingLine?: string;
  maxBackAndForth?: number | "unlimited";
  others?: ReleasePolicyItem[];
};

export function buildGeneralPayload(release?: Release): GeneralPayload {
  if (release == null) {
    return {
      styleAndTone: "context",
    };
  }

  const payload: GeneralPayload = {};
  const sources: string[] = [];

  release.releasePolicy.forEach((policyItem) => {
    if (policyItem.type === "general") {
      switch (true) {
        case policyItem.topic === "includeTicketSystem":
          sources.push(`TICKET_SYSTEM@${policyItem.content}`);
          break;
        case policyItem.topic === "includeKnowledgeBase":
          sources.push(`KNOWLEDGE_BASE@${policyItem.content}`);
          break;
        case policyItem.topic === "includeLink":
          payload.includeLink = (policyItem.content as string) === "true";
          break;
        case policyItem.topic === "toneOfVoice":
          payload.toneOfVoice = policyItem.content as string;
          break;
        case policyItem.topic === "openingLine":
          payload.openingLine = policyItem.content as string;
          break;
        case policyItem.topic === "closingLine":
          payload.closingLine = policyItem.content as string;
          break;
        case policyItem.topic === "maxBackAndForth":
          payload.maxBackAndForth =
            (policyItem.content as number) > 6
              ? "unlimited"
              : (policyItem.content as number);
          break;
        default:
          payload.others = payload.others ?? [];
          payload.others.push(policyItem);
          break;
      }
    } else {
      payload.others = payload.others ?? [];
      payload.others.push(policyItem);
    }
  });

  if (sources.length > 0) {
    payload.sources = sources;
  }

  if (
    payload.toneOfVoice != null ||
    payload.openingLine != null ||
    payload.closingLine != null
  ) {
    payload.styleAndTone = "custom";
  } else {
    payload.styleAndTone = "context";
  }

  return payload;
}

export function buildReleasePolicy(
  payload: GeneralPayload
): ReleasePolicyItem[] {
  const releasePolicyItems: Release["releasePolicy"] = [];

  if (payload.sources != null) {
    payload.sources.forEach((source) => {
      const [type, name] = source.split("@");

      if (type === "TICKET_SYSTEM" && isTicketSystemIntegrationName(name)) {
        releasePolicyItems.push({
          type: "general",
          topic: "includeTicketSystem",
          subTopic: "",
          content: name,
        });
      }

      if (type === "KNOWLEDGE_BASE" && isKnowledgeBaseIntegrationName(name)) {
        releasePolicyItems.push({
          type: "general",
          topic: "includeKnowledgeBase",
          subTopic: "",
          content: name,
        });
      }
    });
  }

  if (payload.includeLink != null) {
    releasePolicyItems.push({
      type: "general",
      topic: "includeLink",
      subTopic: "",
      content: payload.includeLink.toString(),
    });
  }

  if (payload.styleAndTone === "custom") {
    if (payload.toneOfVoice != null) {
      releasePolicyItems.push({
        type: "general",
        topic: "toneOfVoice",
        subTopic: "",
        content: payload.toneOfVoice,
      });
    }

    if (payload.openingLine != null && payload.openingLine !== "") {
      releasePolicyItems.push({
        type: "general",
        topic: "openingLine",
        subTopic: "",
        content: payload.openingLine,
      });
    }

    if (payload.closingLine != null && payload.closingLine !== "") {
      releasePolicyItems.push({
        type: "general",
        topic: "closingLine",
        subTopic: "",
        content: payload.closingLine,
      });
    }
  }

  if (payload.maxBackAndForth != null) {
    releasePolicyItems.push({
      type: "general",
      topic: "maxBackAndForth",
      subTopic: "",
      content:
        payload.maxBackAndForth === "unlimited" ? 400 : payload.maxBackAndForth,
    });
  }

  if (payload.others != null) {
    payload.others.forEach((item) => {
      releasePolicyItems.push(item);
    });
  }

  return releasePolicyItems;
}

export function useUpdateReleasePolicyGeneral(simulatorId?: string) {
  const { release, updateReleasePolicy, status } =
    useSimulatorConfiguration(simulatorId);

  const [localPayload, setLocalPayload] = useState<GeneralPayload>(
    buildGeneralPayload(release)
  );

  useEffect(() => {
    setLocalPayload(buildGeneralPayload(release));
  }, [release]);

  const handlePayloadChange = useCallback(
    (partialPayload: Partial<GeneralPayload>) => {
      const updatedPayload = {
        ...localPayload,
        ...partialPayload,
      };
      setLocalPayload(updatedPayload);

      const newReleasePolicy = buildReleasePolicy(updatedPayload);

      // Update release policy in the background
      updateReleasePolicy(newReleasePolicy);
    },
    [localPayload, updateReleasePolicy]
  );

  return { localPayload, handlePayloadChange, status };
}
