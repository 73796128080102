import { ButtonProps } from "@radix-ui/themes";
import { PropsWithChildren } from "react";

// Workaround to create link which look like radix button
export function Link({
  to,
  openInNewTab = false,
  color = "gray",
  size = "2",
  variant = "solid",
  highContrast = true,
  children,
}: PropsWithChildren<ButtonProps & { to: string; openInNewTab?: boolean }>) {
  return (
    <a
      href={to}
      {...(openInNewTab && { target: "_blank", rel: "noreferrer" })}
      data-accent-color={color}
      className={`rt-reset rt-BaseButton rt-r-size-${size} rt-variant-${variant} ${
        highContrast ? "rt-high-contrast" : ""
      } rt-Button`}
    >
      {children}
    </a>
  );
}
