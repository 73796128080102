import { Navigate, Route } from "react-router-dom";

export type RouteTreeItem = {
  path: string;
  elem: React.ReactNode;
  index?: boolean;
};

export type RouteTreeValue = {
  root: string;
  rootElement: React.ReactNode;
  tree: RouteTreeItem[];
};

export function renderRouteTree({ root, rootElement, tree }: RouteTreeValue) {
  const indexRoute = tree.find((route) => route.index);

  return (
    <Route path={root} element={rootElement}>
      {indexRoute && (
        <Route index element={<Navigate to={indexRoute.path} replace />} />
      )}

      {tree.map(({ path, elem, index }) => (
        <Route key={path} path={path} element={elem} index={index} />
      ))}

      {indexRoute && (
        <Route
          path="*"
          element={<Navigate to={indexRoute.path} replace={true} />}
        />
      )}
    </Route>
  );
}
