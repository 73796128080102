import { useState, useCallback, useEffect } from "react";
import { Flex, Button, Text, TextArea, Em } from "@radix-ui/themes";
import { PlusIcon, ArrowRightIcon } from "@radix-ui/react-icons";
import styles from "../AutomationConfiguration.module.scss";

interface OptionalTextareaProps {
  value: string | undefined;
  onChange: (value: string) => void;
  buttonText?: string;
  label: string;
  description?: string;
}

export function OptionalTextarea({
  value,
  onChange,
  buttonText = "Add",
  label,
  description,
}: OptionalTextareaProps) {
  const [innerValue, setInnerValue] = useState(value ?? "");
  const [open, setOpen] = useState(false);

  const isEmpty = value == null || value === "";

  const handleChange = useCallback((newValue: string) => {
    setInnerValue(newValue);
  }, []);

  const handleCancel = useCallback(() => {
    setOpen(false);
    setInnerValue(value ?? "");
  }, [value]);

  const handleDelete = useCallback(() => {
    setOpen(false);
    onChange("");
  }, [onChange]);

  const hadleSave = useCallback(() => {
    setOpen(false);
    onChange(innerValue);
  }, [onChange, innerValue]);

  useEffect(() => {
    setInnerValue(value ?? "");
  }, [value]);

  if (isEmpty && !open) {
    return (
      <Button
        color="gray"
        variant="soft"
        size="1"
        onClick={() => setOpen(true)}
      >
        <PlusIcon height={"15px"} width={"15px"} />
        <Text size={"2"} weight={"regular"} highContrast>
          {buttonText}
        </Text>

        <Text size={"2"} weight={"regular"}>
          <Em>(optional)</Em>
        </Text>
      </Button>
    );
  }

  if (!isEmpty && !open) {
    return (
      <Button
        color="gray"
        variant="soft"
        size="1"
        onClick={() => setOpen(true)}
        className={styles.OptionalTextareaPreview}
      >
        <Flex
          gap={"2"}
          align={"center"}
          className={styles.OptionalTextareaPreviewInner}
        >
          <ArrowRightIcon />

          <Text size={"2"} highContrast truncate>
            {value}
          </Text>
        </Flex>
      </Button>
    );
  }

  return (
    <Flex
      width={"100%"}
      p={"3"}
      direction={"column"}
      gap={"2"}
      className={styles.OptionalTextarea}
    >
      <TextArea
        variant={"soft"}
        color={"gray"}
        value={innerValue ?? ""}
        onChange={(e) => handleChange(e.target.value)}
        className={styles.TextArea}
        placeholder={"Add"}
      />

      {description != null && (
        <Text size={"2"} color={"gray"}>
          <Em>{description}</Em>
        </Text>
      )}

      <Flex justify={"between"}>
        <Button
          color="gray"
          variant="outline"
          highContrast
          size="1"
          onClick={handleDelete}
        >
          Delete
        </Button>

        <Flex gap={"2"}>
          <Button
            color="gray"
            variant="outline"
            highContrast
            size="1"
            onClick={handleCancel}
          >
            Cancel
          </Button>

          <Button
            color="gray"
            variant="solid"
            highContrast
            size="1"
            onClick={hadleSave}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
