import { useCallback, useEffect, useMemo, useState } from "react";
import { SelectOption } from "../../components/shared/select";
import {
  useGetAgents,
  useRefetchAgentsFromTicketSystem,
} from "../../api/agent-group";
import {
  AutoResponseAgent,
  useGetAutoResponseAgent,
  useUpdateAutoResponseConfig,
} from "../../api/auto-config";
import { UpdateStatus } from "./utils/automation-configuration.utils";

type Value = {
  agent?: string;
};

function buildValue(autoResponseAgent?: AutoResponseAgent): Value {
  if (autoResponseAgent?.type === "agent") {
    return {
      agent: autoResponseAgent.id.toString(),
    };
  } else {
    return {
      agent: undefined,
    };
  }
}

export function useUpdateAutoResponseAgent() {
  const {
    data: autoResponseAgent,
    isLoading: isAutoResponseAgentLoading,
    isError: isAutoResponseAgentError,
    refetch: refetchAutoResponseAgent,
  } = useGetAutoResponseAgent();
  const { mutateAsync: updateAutoResponseConfig } =
    useUpdateAutoResponseConfig();
  const { mutateAsync: refetchAgentsFromTicketSystem } =
    useRefetchAgentsFromTicketSystem();

  const [localValue, setLocalValue] = useState<Value>();
  const [status, setStatus] = useState<UpdateStatus>("idle");

  const {
    data: agents,
    isLoading: loadingAgents,
    refetch: refetchAgents,
  } = useGetAgents();

  const agentOptions = useMemo<SelectOption[] | undefined>(() => {
    return agents?.map(({ id, name }) => ({
      value: id.toString(),
      label: name ?? id.toString(),
    }));
  }, [agents]);

  const handleReloadAgents = useCallback(async () => {
    await refetchAgentsFromTicketSystem({});
    await refetchAgents();
  }, [refetchAgentsFromTicketSystem, refetchAgents]);

  useEffect(() => {
    setLocalValue(buildValue(autoResponseAgent));
  }, [autoResponseAgent]);

  const onChange = useCallback(
    async (autoResponseAgentId: string | null) => {
      setStatus("saving");
      const res = await updateAutoResponseConfig({
        autoResponseAgentId: autoResponseAgentId
          ? parseInt(autoResponseAgentId)
          : null,
      });

      if (res != null) {
        await refetchAutoResponseAgent();
      }

      setStatus("saved");
    },
    [refetchAutoResponseAgent, updateAutoResponseConfig]
  );

  useEffect(() => {
    if (status === "saved") {
      const timer = setTimeout(() => setStatus("idle"), 2000);
      return () => clearTimeout(timer);
    }
  }, [status]);

  return useMemo(
    () => ({
      agentOptions,
      loadingAgents,
      value: localValue,
      onAgentChange: (agent: string) => {
        setLocalValue({ agent });
        void onChange(agent);
      },
      isAutoResponseAgentError,
      isAutoResponseAgentLoading,
      refetchAutoResponseAgent,
      reloadAgents: handleReloadAgents,
      status,
    }),
    [
      agentOptions,
      loadingAgents,
      localValue,
      onChange,
      isAutoResponseAgentError,
      isAutoResponseAgentLoading,
      refetchAutoResponseAgent,
      handleReloadAgents,
      status,
    ]
  );
}
