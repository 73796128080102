import { ReactNode } from "react";
import { IntegrationName } from "./Integration.model";
import ZendeskLogoSVG from "../../components/icons/connections/ZendeskLogoSVG";
import Document360LogoSVG from "../../components/icons/connections/Document360LogoSVG";
import GitbookLogoSVG from "../../components/icons/connections/GitbookLogoSVG";
import FrontLogoSVG from "../../components/icons/connections/FrontLogoSVG";
import FreshdeskLogoSVG from "../../components/icons/connections/FreshdeskLogoSVG";
import SalesforceLogoSVG from "../../components/icons/connections/SalesforceLogoSVG";
import HubSpotLogoSVG from "../../components/icons/connections/HubSpotLogoSVG";
import IntercomLogoSVG from "../../components/icons/connections/IntercomLogoSVG";
import SlackLogoSVG from "@/components/icons/connections/SlackLogoSVG";
import ConfluenceLogoSVG from "@/components/icons/connections/ConfluenceLogoSVG";
import NotionLogoSVG from "@/components/icons/connections/NotionLogoSVG";
import JiraLogoSVG from "@/components/icons/connections/JiraLogoSVG";
import KustomerLogoSVG from "@/components/icons/connections/KustomerLogoSVG";
// import IntercomLogoSVG from "../assets/IntercomLogoSVG";
// import GladlyLogoSVG from "../assets/GladlyLogoSVG";

type GalleryItem = {
  title: string;
  svg: ReactNode;
};

export const integrationNameToGalleryItem: Record<
  IntegrationName,
  GalleryItem
> = {
  zendesk: {
    title: "Zendesk",
    svg: <ZendeskLogoSVG />,
  },
  front: {
    title: "Front",
    svg: <FrontLogoSVG />,
  },
  freshdesk: {
    title: "Freshdesk",
    svg: <FreshdeskLogoSVG />,
  },
  hubspot: {
    title: "HubSpot",
    svg: <HubSpotLogoSVG />,
  },
  salesforce: {
    title: "Salesforce",
    svg: <SalesforceLogoSVG />,
  },
  intercom: {
    title: "Intercom",
    svg: <IntercomLogoSVG />,
  },
  kustomer: {
    title: "Kustomer",
    svg: <KustomerLogoSVG />,
  },
  // gladly: {
  //   title: "Gladly",
  //   svg: <GladlyLogoSVG />,
  // },
  document360: {
    title: "Doc360",
    svg: <Document360LogoSVG />,
  },
  notion: {
    title: "Notion",
    svg: <NotionLogoSVG />,
  },
  confluence: {
    title: "Confluence",
    svg: <ConfluenceLogoSVG />,
  },
  gitbook: {
    title: "Gitbook",
    svg: <GitbookLogoSVG />,
  },
  slack: {
    title: "Slack",
    svg: <SlackLogoSVG />,
  },
  jira: {
    title: "Jira",
    svg: <JiraLogoSVG />,
  },
};
