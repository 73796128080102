import { PropsWithChildren, ReactNode } from "react";
import { Flex, Text } from "@radix-ui/themes";
import { EmptyStateSvgName, EmptyStateSvg } from "./EmptyStateSVG";

interface PageEmptyStateProps {
  title: string;
  description: string;
  svg?: EmptyStateSvgName;
}

export function PageEmptyState({
  title,
  description,
  svg,
  children,
}: PropsWithChildren<PageEmptyStateProps>) {
  return (
    <Flex
      pl={"200px"}
      pt={"120px"}
      flexGrow={"1"}
      direction={"column"}
      gap={"4"}
    >
      <Flex direction={"column"} gap={"4"}>
        {svg != null && <EmptyStateSvg name={svg} />}

        <Flex direction={"column"} gap={"2"} maxWidth={"540px"}>
          <Text color={"gray"} size={"5"} weight={"medium"} highContrast>
            {title}
          </Text>

          <Text color={"gray"} size={"3"}>
            {description}
          </Text>
        </Flex>
      </Flex>

      {children}
    </Flex>
  );
}
