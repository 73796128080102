import { useCallback, useState } from "react";
import { Button, Text } from "@radix-ui/themes";
import { ChatBubbleIcon } from "@radix-ui/react-icons";
import { Dialog } from "@/components/shared/dialog";
import { QuackChatConfigResponse } from "@/components/quack-chat/QuackChatConfig.model";
import { QuackChatSystemMessageType } from "@/api/quackchat/configuration/useQuackChatSystemMessageOverride";
import { QuackChatSystemMessageOverride } from "./QuackChatSystemMessageOverride";

interface QuackchatSystemMessageConfigurationProps {
  config: QuackChatConfigResponse;
  defaultMessageType?: QuackChatSystemMessageType;
}

export function QuackchatSystemMessageConfiguration({
  config,
  defaultMessageType,
}: QuackchatSystemMessageConfigurationProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = useCallback((open: boolean) => {
    setIsOpen(open);
  }, []);

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>
        <Button
          size={"1"}
          color={"gray"}
          variant={"outline"}
          onClick={() => setIsOpen(true)}
        >
          <ChatBubbleIcon />
          Customize Messages
        </Button>
      </Dialog.Trigger>

      {isOpen && (
        <Dialog.Content size={"large"}>
          <Dialog.Title>
            <Text size={"2"} color={"gray"}>
              Customize Messages
            </Text>

            <Dialog.Close />
          </Dialog.Title>

          <QuackChatSystemMessageOverride
            config={config}
            defaultMessageType={defaultMessageType}
          />
        </Dialog.Content>
      )}
    </Dialog>
  );
}
