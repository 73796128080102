import { useMemo, useState } from "react";
import cx from "classnames";
import { Button, Em, Flex, ScrollArea, Text } from "@radix-ui/themes";
import { FileTextIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import {
  mixTrackEvent,
  InteractionInformationEvents,
} from "../../../assets/mixpanel";
import {
  printTicketId,
  TicketExploreRow,
} from "../../../api/useExplore/Explore.model";
import { TicketScore } from "./TicketQAScore.model";
import TicketQuestionsScore from "./TicketQuestionsScore";
import styles from "./TicketQAScore.module.scss";
import { useGetInteractionInTraining } from "../../../api/training";
import { TicketInTraining } from "../../simulator/pendingReview/types";
import { InteractionInTrainingStatus } from "../../simulator-live-mode/types";
import { SendInteractionToTraining } from "../../simulator-live-mode/sendTicketToTraining";
import { useFeatureFlag } from "configcat-react";
import { useAuth0 } from "@auth0/auth0-react";

interface TicketSummaryProps {
  ticket: TicketExploreRow;
  viewOnly?: boolean;
}

function TicketSummary({ ticket, viewOnly = false }: TicketSummaryProps) {
  const { user } = useAuth0();
  const { value: reviewTicketsFromExplorerValue } = useFeatureFlag(
    "reviewTicketsFromExplorer",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const { data, refetch } = useGetInteractionInTraining(
    ticket?.topic ?? "",
    !!ticket?.topic && reviewTicketsFromExplorerValue
  );

  const showTicketInTrainingButton = useMemo(() => {
    const ticketsInTraining = data?.tickets as TicketInTraining[];
    if (!ticketsInTraining || !ticket?.id) return false;

    const ticketInTraining = ticketsInTraining.find(
      (t: TicketInTraining) =>
        t.ticketId === ticket.id &&
        t.status === InteractionInTrainingStatus.PENDING
    );

    return !ticketInTraining;
  }, [data?.tickets, ticket?.id]);

  return (
    <ScrollArea scrollbars={"vertical"}>
      <div className={styles.Summary}>
        <Flex justify={"between"} align={"center"}>
          <Flex direction={"column"}>
            <Text size={"4"} color={"gray"} weight={"medium"} highContrast>
              {ticket?.overview?.subject}
            </Text>

            <Text size={"2"} color={"gray"}>
              {printTicketId(ticket?.overview?.id)}
            </Text>
          </Flex>

          {!viewOnly &&
            reviewTicketsFromExplorerValue &&
            showTicketInTrainingButton && (
              <SendInteractionToTraining
                interactionId={ticket.id}
                refetch={refetch}
              />
            )}
        </Flex>

        <div className={styles.TLDR}>
          <div className={styles.Label}>Summary</div>
          <div className={styles.Content}>
            {ticket.tldr != null ? (
              ticket.tldr
            ) : (
              <Flex py={"40px"} direction={"column"} gap={"2"} align={"center"}>
                <Text size={"2"} weight={"medium"} color={"gray"} highContrast>
                  No summary available yet.
                </Text>
                <Text size={"2"} color={"gray"}>
                  <Em>
                    A detailed summary will be generated once the ticket is
                    resolved. Stay tuned—it's on its way!
                  </Em>
                </Text>
              </Flex>
            )}
          </div>
        </div>
      </div>
    </ScrollArea>
  );
}

const mainTabs = ["summary", "evaluation"] as const;
export type MainTab = (typeof mainTabs)[number];

interface MainProps {
  ticket: TicketExploreRow;
  ticketScore?: TicketScore[];
  onUpdateTicketScore: () => Promise<unknown>;
  allowValidateTicket?: boolean;
  viewOnly?: boolean;
  avgQAScore?: number;
  defaultTab?: MainTab;
}

function Main({
  ticket,
  ticketScore,
  onUpdateTicketScore,
  allowValidateTicket,
  viewOnly = false,
  avgQAScore,
  defaultTab = "summary",
}: MainProps) {
  const [activeTab, setActiveTab] = useState<MainTab>(defaultTab);

  return (
    <div className={styles.Main}>
      <div className={styles.Tabs}>
        <Button
          variant={"outline"}
          color={"gray"}
          size={"1"}
          onClick={() => setActiveTab("summary")}
          className={cx(styles.TabButton, {
            [styles.active]: activeTab === "summary",
          })}
        >
          <FileTextIcon /> Ticket Summary
        </Button>

        <Button
          variant={"outline"}
          color={"gray"}
          size={"1"}
          onClick={() => {
            setActiveTab("evaluation");
            mixTrackEvent({
              event: InteractionInformationEvents.VIEWED_EVALUATIONS_TAB,
              properties: {
                ticketId: ticket.id,
              },
            });
          }}
          className={cx(styles.TabButton, {
            [styles.active]: activeTab === "evaluation",
          })}
        >
          <EyeOpenIcon /> Evaluation
        </Button>
      </div>

      <div className={styles.TabContent}>
        {activeTab === "summary" && (
          <TicketSummary ticket={ticket} viewOnly={viewOnly} />
        )}

        {activeTab === "evaluation" && (
          <TicketQuestionsScore
            ticket={ticket}
            ticketScore={ticketScore}
            onUpdateTicketScore={onUpdateTicketScore}
            allowValidateTicket={allowValidateTicket}
            viewOnly={viewOnly}
            avgQAScore={avgQAScore}
          />
        )}
      </div>
    </div>
  );
}

export default Main;
