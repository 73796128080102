import { PropsWithChildren } from "react";
import cx from "classnames";
import { Flex, FlexProps, Text } from "@radix-ui/themes";
import styles from "../AddIntegration.module.scss";

interface StepHeaderProps {
  title: string;
  description?: string;
}

export function StepHeader({ title, description }: StepHeaderProps) {
  return (
    <div className={styles.StepHeader}>
      <Text size={"3"} weight={"medium"} as={"div"}>
        {title}
      </Text>

      {description != null && (
        <Text size={"2"} color={"gray"} as={"div"}>
          {description}
        </Text>
      )}
    </div>
  );
}

export function StepContent({
  children,
  className,
  ...flexProps
}: PropsWithChildren<FlexProps>) {
  return (
    <Flex
      flexGrow={"1"}
      pt={"35px"}
      px={"45px"}
      {...flexProps}
      className={cx(styles.Content, className)}
    >
      {children}
    </Flex>
  );
}

export function StepMain({ children }: PropsWithChildren) {
  return (
    <Flex direction={"column"} className={styles.Main}>
      {children}
    </Flex>
  );
}

export function StepFooter({ children }: PropsWithChildren) {
  return <Flex className={styles.Footer}>{children}</Flex>;
}
