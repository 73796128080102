import { useCallback, useEffect, useMemo, useState } from "react";
import { useChat, Message } from "ai/react";
import { useWorkspace } from "../context/wrokspace";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { useInterval } from "@/hooks/useInterval";
import { Instruction } from "../types";
import { params } from "@/params";
import { useAuthorizedMutation } from "@/api";

export const useSimulatorChat = ({
  id,
  isTraining,
  instructions,
  topic,
  subTopic,
  handleResponse,
  briefs = "",
}: {
  id: string;
  isTraining: boolean;
  instructions: Instruction[];
  topic: string;
  subTopic: string;
  handleResponse: (message: Message) => void;
  briefs?: string;
}) => {
  const { workspace } = useWorkspace();
  const [tokenId, setToken] = useState<IdToken | undefined>(undefined);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    (async () => {
      const token = await getIdTokenClaims();
      setToken(token);
    })();
  }, [isAuthenticated, getIdTokenClaims]);

  useInterval(() => {
    (async () => {
      if (isAuthenticated) {
        const token = await getIdTokenClaims();
        setToken(token);
      }
    })();
  }, 1000 * 60 * 10); // every 10 minutes

  const authToken = tokenId?.__raw ? tokenId.__raw : "";
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  const requestBody = useMemo(
    () => ({
      tenant: workspace ?? "",
      instructions,
      topic,
      subTopic,
      brief: briefs,
    }),
    [workspace, instructions, topic, subTopic, briefs]
  );

  const {
    messages,
    input,
    handleInputChange,
    handleSubmit,
    append,
    reload,
    setMessages,
    isLoading,
  } = useChat({
    id: String(id),
    api: `${params.API_URL}/platform/vercel-proxy/api/reply?stream=true&playground=${isTraining}&simulator=true`,
    headers,
    body: requestBody,
    onFinish: handleResponse,
  });

  return {
    messages,
    input,
    handleInputChange,
    handleSubmit,
    append,
    reload,
    setMessages,
    isLoading,
  };
};

export const useBadFeedback = ({ isTraining }: { isTraining: boolean }) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/vercel-proxy/api/reply?stream=false&playground=${isTraining}&simulator=true`,
    method: "POST",
  });
};

export const usePositiveFeedback = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/vercel-proxy/api/rag`,
    method: "PUT",
  });
};

export const useFetchTopics = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/vercel-proxy/api/topics`,
    method: "POST",
  });
};

export const useHandleInstructify = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/vercel-proxy/api/instructify`,
    method: "POST",
  });
};
