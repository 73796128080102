import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import axios, { AxiosInstance } from "axios";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { params } from "@/params";
import { FeatureAccessContext } from "@/context/featureAccess";

const ApiContext = createContext<AxiosInstance | null>(null);

export function ApiProvider({ children }: PropsWithChildren) {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const { selectedWorkspace } = useContext(FeatureAccessContext);

  const [tokenId, setTokenId] = useState<IdToken>();

  const api = useMemo(() => {
    const authToken = tokenId?.__raw ? tokenId.__raw : "";

    const instance = axios.create({
      baseURL: params.API_URL,
      timeout: 10000, // optional timeout
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
        ...(selectedWorkspace && { "x-tenant-id": selectedWorkspace }),
      },
    });

    // Optional: Add request interceptor
    instance.interceptors.request.use(
      (config) => {
        // Further customization if needed (e.g., dynamically attach tokens)
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Optional: Add response interceptor
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        // Handle errors globally
        return Promise.reject(error);
      }
    );

    return instance;
  }, [tokenId, selectedWorkspace]);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const tokenId = await getIdTokenClaims();
        setTokenId(tokenId);
      })();
    }
  }, [isAuthenticated, getIdTokenClaims]);

  return (
    <ApiContext.Provider value={api}>
      {api != null && children}
    </ApiContext.Provider>
  );
}

export function useApi(): { api: AxiosInstance } {
  const api = useContext(ApiContext);

  if (api == null) {
    throw new Error("useApi must be used within an ApiProvider");
  }

  return { api };
}
