import { useState } from "react";
import { Box } from "@radix-ui/themes";
import { Dialog } from "@/components/shared/dialog";
import styles from "./TicketTranscript.module.scss";

interface ImageViewerProps {
  url: string;
}

export function ImageViewer({ url }: ImageViewerProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleImageClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <img
        src={url}
        alt="Thumbnail"
        className="cursor-pointer"
        onClick={handleImageClick}
      />

      <Dialog open={isOpen} onOpenChange={handleClose}>
        <Dialog.Content size="large" noRadius>
          <Box position={"relative"}>
            <Dialog.Close
              className={styles.CloseImageViewer}
              variant={"surface"}
              color={"gray"}
              highContrast={false}
              size={"1"}
            />
            <img src={url} alt="Full Size" className="max-w-full max-h-full" />
          </Box>
        </Dialog.Content>
      </Dialog>
    </div>
  );
}
