import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { DateRange } from "@/components/shared/date-range-picker/DateRangePicker";

export const useArticlesSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialSearchPhrase = searchParams.get("searchPhrase") || "";
  const initialDateFrom =
    searchParams.get("dateFrom") ||
    dayjs().subtract(2, "months").format("YYYY-MM-DD");
  const initialDateTo =
    searchParams.get("dateTo") || dayjs().format("YYYY-MM-DD");

  const [searchPhrase, setSearchPhrase] = useState<string>(initialSearchPhrase);
  const [dateRange, setDateRange] = useState<DateRange>({
    dateFrom: initialDateFrom,
    dateTo: initialDateTo,
  });

  useEffect(() => {
    setSearchParams({
      searchPhrase,
      dateFrom: dateRange.dateFrom,
      dateTo: dateRange.dateTo,
    });
  }, [searchPhrase, dateRange, setSearchParams]);

  return {
    searchPhrase,
    setSearchPhrase,
    dateRange,
    setDateRange,
  };
};
