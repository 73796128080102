import { Button, TextField } from "@radix-ui/themes";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";

import styles from "./styles.module.scss";
import { useGetTicketBySource } from "../../../api/useGetTicket";
import { useState } from "react";
import { useWorkspace } from "../context/wrokspace";

export const SearchTicket = ({
  onTicketFound,
  handleMessage,
}: {
  randomTicketId: string;
  onTicketFound?: ({
    ticketId,
    topic,
    comments,
  }: {
    ticketId: string;
    topic: string;
    comments: any;
  }) => void;
  handleMessage?: (message: string) => void;
}) => {
  const { workspace } = useWorkspace();
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const { data } = useGetTicketBySource(value, Boolean(value !== ""));

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        setValue(newValue);
      }, 500)
    );
  };

  return (
    <div className={styles.searchTicketContainer}>
      <TextField.Root
        placeholder="Search ticket"
        value={inputValue}
        onChange={handleInputChange}
      >
        <TextField.Slot>
          <MagnifyingGlassIcon height="16" width="16" />
        </TextField.Slot>
      </TextField.Root>
      {data &&
        (data?.ticket !== null && !data.error ? (
          <Button
            variant="ghost"
            color="indigo"
            onClick={() => {
              onTicketFound &&
                onTicketFound({
                  ticketId: data?.ticket?.id,
                  topic:
                    data?.ticket?.topic?.name || data?.ticket?.topic?.topic,
                  comments: [{ body: data?.ticket?.description }],
                });

              let description = "";
              let subject = data.ticket.subject
                .toLowerCase()
                .includes("conversation")
                ? ""
                : data.ticket.subject;

              const userComments = data.ticket.comments.filter(
                (c: { agent: boolean; isInternal: boolean; body: String }) => {
                  if (!c.agent && !c.isInternal) {
                    return true;
                  }
                  return false;
                }
              );

              console.log({ userComments, d: userComments });
              description = userComments[0].body;
              let metadata = "";
              if (data.ticket.channel === "native_messaging") {
                console.log({ description });

                const userMessage = data.ticket.comments.findIndex(
                  (x: {
                    agent: boolean;
                    isInternal: boolean;
                    body: string;
                    authorId: string;
                  }) => {
                    if (x.body.includes("connect you to an agent now")) {
                      return true;
                    }
                    return false;
                  }
                );

                let transcript = `This is the transcript of the chatbot:
`;
                data.ticket.comments
                  .slice(0, userMessage + 2)
                  .forEach(
                    (x: {
                      agent: boolean;
                      isInternal: boolean;
                      body: string;
                      authorId: string;
                    }) => {
                      if (!x.isInternal && !x.agent) {
                        transcript += `${x.agent ? "bot" : "user"}: ${
                          x.body
                        }\n`;
                      }
                    }
                  );

                description = transcript;
              }
              if (
                workspace === "moovit" &&
                (data?.tickt?.topic?.name === "Schedule Accuracy" ||
                  data?.tickt?.topic?.topic === "Schedule Accuracy")
              ) {
                const metadataValue = data?.ticket?.metadata?.find(
                  (x: { id: number; value: string }) => x.id === 360018202319
                )?.value;
                metadata = `the ticket is: ${metadataValue.replaceAll(
                  "_",
                  " "
                )}`;
              }
              if (workspace === "brightdata") {
                const ids = [1900005234433, 31629287017873, 27337835770129];
                const titleMap: { [key: number]: string } = {
                  1900005234433: "Ticket category",
                  31629287017873: "Account Tier (Ticket)",
                  27337835770129: "Cust. Monthly revenue",
                };

                ids.forEach((id) => {
                  const metadataValue = data?.ticket?.metadata?.find(
                    (x: { id: number; value: string }) => x.id === id
                  );

                  if (metadataValue) {
                    const title = titleMap[metadataValue.id];
                    metadata += metadataValue.value
                      ? `${title}: ${(metadataValue.value ?? "").replaceAll(
                          "_",
                          " "
                        )}\n`
                      : "";
                  }
                });
              }
              const message = ["moovit", "brightdata"].includes(workspace)
                ? `${metadata}\n--\n\n${subject}\n\n${description}`
                : `${subject}\n\n${description}`;
              handleMessage && handleMessage(message);
            }}
          >
            Simulate #{value}
          </Button>
        ) : (
          <Button color="crimson" disabled>
            Not found
          </Button>
        ))}
    </div>
  );
};
