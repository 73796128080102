import { useMemo, useState } from "react";
import { Flex, ScrollArea, Spinner, Text, Em } from "@radix-ui/themes";
import {
  buildTranscript,
  CommentValue,
  TicketTranscriptFilterValue,
} from "@/api/ticket-transcript/TicketTranscript.model";
import { TicketTranscriptHeader } from "./TicketTranscriptHeader";
import { Comment } from "./Comment";
import styles from "./TicketTranscript.module.scss";
import { TicketExploreCollaborator } from "@/api/useExplore/Explore.model";
import { buildColorMap } from "@/components/radixColors";

interface TicketTranscriptProps {
  data?: CommentValue[];
  isLoading: boolean;
  collaborators?: TicketExploreCollaborator[];
}

export function TicketTranscript({
  data,
  isLoading = false,
  collaborators = [],
}: TicketTranscriptProps) {
  const [search, setSearch] = useState("");
  const [filterValue, setFilterValue] = useState<TicketTranscriptFilterValue>(
    {}
  );

  const comments = useMemo(() => {
    if (!data) {
      return [];
    }

    const knownCollaboratorsToColor = buildColorMap(
      collaborators.map((c) => c.name)
    );

    return buildTranscript(data, knownCollaboratorsToColor);
  }, [data, collaborators]);

  const visibleComments = useMemo(() => {
    const testSearch = search.length > 0;
    const testFilter = Object.keys(filterValue).length > 0;

    if (!testSearch && !testFilter) {
      return comments;
    }

    const searchString = search.toLowerCase();

    return comments.filter((comment) => {
      if (testSearch && !comment.body.toLowerCase().includes(searchString)) {
        return false;
      }

      if (testFilter && filterValue.messageType != null) {
        const showInternal = filterValue.messageType.includes("internal");
        const showPublic = filterValue.messageType.includes("public");

        if (comment.isInternal && !showInternal) {
          return false;
        }

        if (!comment.isInternal && !showPublic) {
          return false;
        }
      }

      return true;
    });
  }, [comments, search, filterValue]);

  return (
    <Flex className={styles.TicketTranscript}>
      <TicketTranscriptHeader
        search={search}
        filterValue={filterValue}
        onSearchChange={setSearch}
        onFilterChange={setFilterValue}
      />

      <Flex className={styles.CommentList}>
        <ScrollArea
          type={"auto"}
          scrollbars={"vertical"}
          className={styles.ScrollArea}
          style={{ width: "100%" }}
        >
          {isLoading ? (
            <Flex pt={"9"} justify={"center"} align={"center"}>
              <Spinner size={"3"} />
            </Flex>
          ) : (
            <>
              {visibleComments.length === 0 && (
                <Flex
                  pt={"9"}
                  direction={"column"}
                  gap={"1"}
                  justify={"center"}
                  align={"center"}
                >
                  <Text
                    size={"2"}
                    color={"gray"}
                    weight={"medium"}
                    highContrast
                  >
                    No Comments Found
                  </Text>

                  {(search.length > 0 ||
                    Object.keys(filterValue).length > 0) && (
                    <Text size={"2"} color={"gray"}>
                      <Em>Try to change filter or search</Em>
                    </Text>
                  )}
                </Flex>
              )}

              {visibleComments.length > 0 &&
                visibleComments.map((comment, index) => {
                  const previousComment = visibleComments[index - 1];
                  const hideHeader =
                    previousComment?.authorId === comment.authorId;

                  const nextComment = visibleComments[index + 1];
                  const hasConscutiveComment =
                    nextComment?.authorId === comment.authorId;

                  return (
                    <Comment
                      key={comment.externalId}
                      isFirst={index === 0}
                      hideHeader={hideHeader}
                      hasConscutiveComment={hasConscutiveComment}
                      {...comment}
                    />
                  );
                })}
            </>
          )}
        </ScrollArea>
      </Flex>
    </Flex>
  );
}
