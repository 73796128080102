import { useMemo } from "react";
import { params } from "@/params";
import { useAuthorizedQuery } from "..";
import {
  QuackInteractionsRequest,
  QuackInteractionsResponse,
  AvgTimeMetricRequest,
  AvgTimeMetricResponse,
  QaEvaluationMetricsRequest,
  QaEvaluationMetricsResponse,
  QaKeyMetricsResponse,
  CountQuackInteractionsResponse,
  CountUsersInteractedWithQuackResponse,
  CountQuackMessagesResponse,
  FirstReleaseResponse,
  AvgQaScoreResponse,
  TicketResolutionReponse,
} from "./reports.model";
import { DateRange } from "@/components/shared/date-range-picker/DateRangePicker";

const REPORT_URI = "/platform/report";
const REPORT_HIRING_URI = "/platform/report-hiring";
const REPORT_AGENT_PERFORMANCE_URI = "/platform/report-agent-performance";
const REPORT_RESOLUTION = "/platform/report/v2";

function buildSearchParams(params: {
  [paramName: string]: string | string[];
}): URLSearchParams {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (typeof value === "string" && key === "dateFrom") {
      searchParams.append("startDate", value);
    } else if (typeof value === "string" && key === "dateTo") {
      searchParams.append("endDate", value);
    } else if (typeof value === "string") {
      searchParams.append(key, value);
    } else {
      value.forEach((item) => {
        searchParams.append(`${key}[]=`, item);
      });
    }
  });

  return searchParams;
}

export function useCountQuackInteractions(
  request: QuackInteractionsRequest,
  enabled = false
) {
  const paramsString = useMemo(
    () => buildSearchParams(request).toString(),
    [request]
  );

  return useAuthorizedQuery<CountQuackInteractionsResponse>({
    queryKey: `count-quack-interactions-${paramsString}`,
    url: `${params.API_URL}${REPORT_URI}/count-quack-interactions?${paramsString}`,
    method: "GET",
    enabled,
  });
}

export function useCountUsersInteractedWithQuack(
  request: QuackInteractionsRequest,
  enabled = false
) {
  const paramsString = useMemo(
    () => buildSearchParams(request).toString(),
    [request]
  );

  return useAuthorizedQuery<CountUsersInteractedWithQuackResponse>({
    queryKey: `count-users-interacted-with-quack-${paramsString}`,
    url: `${params.API_URL}${REPORT_URI}/count-users-interacted-with-quack?${paramsString}`,
    method: "GET",
    enabled,
  });
}

export function useCountQuackMessages(
  request: QuackInteractionsRequest,
  enabled = false
) {
  const paramsString = useMemo(
    () => buildSearchParams(request).toString(),
    [request]
  );

  return useAuthorizedQuery<CountQuackMessagesResponse>({
    queryKey: `count-quack-messages-${paramsString}`,
    url: `${params.API_URL}${REPORT_URI}/count-quack-messages?${paramsString}`,
    method: "GET",
    enabled,
  });
}

export function useFirstRelease(
  request: Pick<QuackInteractionsRequest, "channels">
) {
  const paramsString = useMemo(
    () => buildSearchParams(request).toString(),
    [request]
  );

  return useAuthorizedQuery<FirstReleaseResponse>({
    queryKey: `first-release-${paramsString}`,
    url: `${params.API_URL}${REPORT_URI}/first-release?${paramsString}`,
    method: "GET",
    enabled: true,
  });
}

export function useQuackShareOfWork(request: QuackInteractionsRequest) {
  const parmasString = useMemo(
    () => buildSearchParams(request).toString(),
    [request]
  );

  return useAuthorizedQuery<QuackInteractionsResponse>({
    queryKey: `quack-share-of-work-${parmasString}`,
    url: `${params.API_URL}${REPORT_HIRING_URI}/quack-share-of-work?${parmasString}`,
    method: "GET",
    enabled: true,
  });
}

export function useAvgTimeMetric(request: AvgTimeMetricRequest) {
  const paramsString = useMemo(
    () => buildSearchParams(request).toString(),
    [request]
  );

  return useAuthorizedQuery<AvgTimeMetricResponse>({
    queryKey: `avg-time-metric-${paramsString}`,
    url: `${params.API_URL}${REPORT_HIRING_URI}/avg-time-metric?${paramsString}`,
    method: "GET",
    enabled: true,
  });
}

export function useQaEvaluationMetrics(request: QaEvaluationMetricsRequest) {
  const paramsString = useMemo(
    () => buildSearchParams(request).toString(),
    [request]
  );

  return useAuthorizedQuery<QaEvaluationMetricsResponse>({
    queryKey: `qa-evaluation-metrics-${paramsString}`,
    url: `${params.API_URL}${REPORT_AGENT_PERFORMANCE_URI}/qa-evaluation-metrics?${paramsString}`,
    method: "GET",
    enabled: true,
  });
}

export function useQaKeyMetrics(request: QaEvaluationMetricsRequest) {
  const paramsString = useMemo(
    () => buildSearchParams(request).toString(),
    [request]
  );

  return useAuthorizedQuery<QaKeyMetricsResponse>({
    queryKey: `qa-key-metric-${paramsString}`,
    url: `${params.API_URL}${REPORT_AGENT_PERFORMANCE_URI}/qa-key-metric?${paramsString}`,
    method: "GET",
    enabled: true,
  });
}

export function useAvgQaScore(request: QaEvaluationMetricsRequest) {
  const paramsString = useMemo(
    () => buildSearchParams(request).toString(),
    [request]
  );

  return useAuthorizedQuery<AvgQaScoreResponse>({
    queryKey: `avg-qa-score-${paramsString}`,
    url: `${params.API_URL}${REPORT_AGENT_PERFORMANCE_URI}/avg-qa-score?${paramsString}`,
    method: "GET",
    enabled: true,
  });
}
export function useQuackResolution(request: DateRange & { channel: string }) {
  const paramsString = useMemo(
    () => buildSearchParams(request).toString(),
    [request]
  );

  return useAuthorizedQuery<TicketResolutionReponse>({
    queryKey: `count-quack-interactions-${paramsString}`,
    url: `${params.API_URL}${REPORT_RESOLUTION}/count-quack-interactions?${paramsString}`,
    method: "GET",
    enabled: true,
  });
}
