import { PropsWithChildren } from "react";
import cx from "classnames";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import styles from "./Popover.module.scss";
import { radixThemeDataAttributes } from "@/components/Theme";

function Close({ children }: PropsWithChildren) {
  return <PopoverPrimitive.Close asChild>{children}</PopoverPrimitive.Close>;
}

function Trigger({ children }: PropsWithChildren) {
  return (
    <PopoverPrimitive.Trigger asChild>{children}</PopoverPrimitive.Trigger>
  );
}

function Content({
  children,
  align = "start",
  side = "bottom",
  sideOffset = 4,
  ...contentProps
}: PropsWithChildren<PopoverPrimitive.PopoverContentProps>) {
  return (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        forceMount
        align={align}
        side={side}
        sideOffset={sideOffset}
        {...contentProps}
        className={cx(styles.PopoverContent, "radix-themes")}
        {...radixThemeDataAttributes}
      >
        {children}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  );
}

function Popover({ children }: PropsWithChildren) {
  return <PopoverPrimitive.Root modal>{children}</PopoverPrimitive.Root>;
}

Popover.Close = Close;
Popover.Trigger = Trigger;
Popover.Content = Content;

export default Popover;
