import { useCallback } from "react";
import { User, Users } from "lucide-react";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { isMetricsScope, MetricsScope } from "@/api/reports/reports.model";

interface MetricsScopeToggleProps {
  value: MetricsScope;
  onChange: (value: MetricsScope) => void;
}

export function MetricsScopeToggle({
  value,
  onChange,
}: MetricsScopeToggleProps) {
  const handleOnValueChange = useCallback(
    (newTab: string) => {
      isMetricsScope(newTab) && onChange(newTab as MetricsScope);
    },
    [onChange]
  );

  return (
    <Tabs value={value} onValueChange={handleOnValueChange}>
      <TabsList>
        <TabsTrigger value={"agents"}>
          <User size={"12"} />
          Agents
        </TabsTrigger>

        <TabsTrigger value={"teams"}>
          <Users size={"12"} />
          Teams
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
}
