import { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Flex, Container } from "@radix-ui/themes";
import { RouteTreeValue } from "@/lib/renderRouteTree";
import { Navbar, NavbarItem } from "@/components/shared/navbar/Navbar";
import { QuackHiringReport } from "./QuackHiringReport";
import { AgentPerformanceReport } from "./AgentPerformanceReport";
import styles from "./Reports.module.scss";
import { TicketsReport } from "./Tickets";
import { ConversationsReport } from "./Conversations";

export const reportsUris = {
  root: "/reports",
  quackHiring: "quack-hiring",
  agentPerformance: "agent-performance",
  tickets: "tickets",
  conversations: "conversations",
  // playground: "playground",
};

const reportsHeader: Record<string, NavbarItem> = {
  [`${reportsUris.root}/${reportsUris.quackHiring}`]: {
    title: "🐣 Quack Hiring",
  },
  [`${reportsUris.root}/${reportsUris.agentPerformance}`]: {
    title: "👨‍💻 Agent Performance",
  },
  [`${reportsUris.root}/${reportsUris.tickets}`]: {
    title: "🎟️ Tickets",
  },
  // [`${reportsUris.root}/${reportsUris.playground}`]: { title: "🛝 Playground" },
};

function ReportNavbar() {
  const location = useLocation();

  const items = useMemo(() => {
    const pageHeader = reportsHeader[location.pathname];
    return [{ title: "Reports" }, ...(pageHeader ? [pageHeader] : [])];
  }, [location.pathname]);

  return (
    <Flex
      height={"45px"}
      px={"15px"}
      justify={"between"}
      align={"center"}
      className={styles.Header}
    >
      <Navbar items={items} />
    </Flex>
  );
}

function Reports() {
  return (
    <Flex flexGrow={"1"} direction={"column"} className={styles.Reports}>
      <ReportNavbar />

      <Flex flexGrow={"1"} overflow={"auto"}>
        <Container size="4" className={styles.Container}>
          <Outlet />
        </Container>
      </Flex>
    </Flex>
  );
}

// function ReportPlayground() {
//   const [dateRange] = useState<DateRange>({
//     startDate: dayjs().subtract(3, "month").toISOString(),
//     endDate: dayjs().toISOString(),
//   });
//   const [channels] = useState<ChannelInteraction[]>(["tickets"]);

//   return (
//     <Flex pt={"9"} pb={"5"} gap={"4"} wrap={"wrap"}>
//       <GeneralWidget dateRange={dateRange} channels={channels} />

//       <ShareOfWorkWidget dateRange={dateRange} channels={channels} />

//       <AvgTTRWidget dateRange={dateRange} channels={channels} />

//       <AvgFRTWidget dateRange={dateRange} channels={channels} />

//       <QaEvaluationMetricsWidget dateRange={dateRange} />

//       <QaKeyMetricsWidget dateRange={dateRange} />

//       <AvgQaScoreWidget dateRange={dateRange} />
//     </Flex>
//   );
// }

export const reportsRouteTree: RouteTreeValue = {
  root: reportsUris.root,
  rootElement: <Reports />,
  tree: [
    { path: reportsUris.quackHiring, elem: <QuackHiringReport />, index: true },
    { path: reportsUris.agentPerformance, elem: <AgentPerformanceReport /> },
    { path: reportsUris.tickets, elem: <TicketsReport /> },
    { path: reportsUris.conversations, elem: <ConversationsReport /> },
    // { path: reportsUris.playground, elem: <ReportPlayground /> },
  ],
};
