import { useParams } from "react-router-dom";
import { useSimulatorConfiguration } from "./useSimulatorConfiguration";
import { ConfigurationViewGuard } from "./parts/ConfigurationViewGuard";
import { QuackchatChannelAppearanceConfiguration } from "./quackchat-channel/QuackchatChannelAppearanceConfiguration";

export function AppearanceConfiguration() {
  const { simulatorId } = useParams();
  const { simulator } = useSimulatorConfiguration(simulatorId);

  const channel = simulator?.channel;

  return (
    <ConfigurationViewGuard view={"appearance"}>
      {channel === "autonomous_quackchat" && (
        <QuackchatChannelAppearanceConfiguration />
      )}
    </ConfigurationViewGuard>
  );
}
