import { useEffect, useState } from "react";
import { InstructionsProvider } from "./context/instructions";
import styles from "./styles.module.scss";
import { Chat } from "./chat";
import { RigthPanel } from "./rightPanel";
import { ReleaseProvider, useReleases } from "./context/release";
import { Navbar } from "@/components/shared/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { SimulatorChannelIcon } from "../simulators/SimulatorChannelIcon";

function SimulatorNavbar() {
  const navigate = useNavigate();
  const { simulator } = useReleases();

  return (
    <Navbar
      items={[
        { title: "Automation" },
        {
          title: "Auto-pilot",
          onClick: () => navigate("/automation?f=autopilot"),
        },
        ...(simulator != null
          ? [
              {
                title: simulator.name,
                svg: <SimulatorChannelIcon channel={simulator.channel} />,
              },
            ]
          : []),
      ]}
    />
  );
}

export const Simulator = () => {
  const [topic, setTopic] = useState<string | null>(null);
  const [isTraining, setTraining] = useState(true);
  const [briefs, setBriefs] = useState<{ [key: string]: string }>({});
  const [selectedValue, setSelectedValue] = useState("");
  const [channel, setChannel] = useState("");
  const [tenantGroup, setTenantGroup] = useState<string[]>([]);
  const queryParams = new URLSearchParams(window.location.search);
  const topicId = queryParams.get("topicId");

  useEffect(() => {
    if (topicId) {
      setSelectedValue(topicId);
    }
  }, [topicId]);

  return (
    <ReleaseProvider>
      <InstructionsProvider>
        <div className={styles.container}>
          <div className={styles.controlRow}>
            <SimulatorNavbar />
          </div>

          <div className={styles.mainContainer}>
            <div className={styles.chatContainer}>
              <Chat
                {...{
                  topic,
                  setTopic,
                  isTraining,
                  setTraining,
                  briefs,
                  setBriefs,
                  selectedValue,
                  setSelectedValue,
                  channel,
                  setChannel,
                  tenantGroup,
                  setTenantGroup,
                }}
              />
            </div>

            <div className={styles.configurationPanelContainer}>
              <RigthPanel
                {...{ topic, isTraining, selectedValue, channel, tenantGroup }}
              />
            </div>
          </div>
        </div>
      </InstructionsProvider>
    </ReleaseProvider>
  );
};
