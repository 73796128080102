import { QAScore } from "../../explore/cells";
import { SharedReportValues } from "../model";
import styles from "../styles.module.scss";

type SharedReportTopMetricsProps = Pick<
  SharedReportValues,
  | "avgScore"
  | "numberOfTickets"
  | "numOfCreatedTickets"
  | "avgTicketsPerDay"
  | "internalCommentedTicketsCount"
> & {
  showCreatedTicketsStats: boolean;
};

const SharedReportTopMetrics = ({
  avgScore,
  numberOfTickets,
  avgTicketsPerDay,
  numOfCreatedTickets,
  internalCommentedTicketsCount,
  showCreatedTicketsStats,
}: SharedReportTopMetricsProps) => {
  return (
    <>
      <div className={styles.topMetrics}>
        <div className={styles.item}>
          <div className={styles.label}>Evaluated Tickets</div>
          <div className={styles.value}>{numberOfTickets}</div>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>Avg. QA Score</div>
          <div className={styles.value}>
            <QAScore value={parseFloat(avgScore)} />
          </div>
        </div>
      </div>

      {
        // use feature flag to have the following only for autoDS
        showCreatedTicketsStats && (
          <div className={styles.topMetrics}>
            <div className={styles.item}>
              <div className={styles.label}>Avg. Tickets per Working Day</div>
              <div className={styles.value}>{parseFloat(avgTicketsPerDay)}</div>
            </div>

            <div className={styles.item}>
              <div className={styles.label}>Tickets with internal Comments</div>
              <div className={styles.value}>
                {(
                  (internalCommentedTicketsCount / numOfCreatedTickets) *
                  100
                ).toFixed(0)}
                %{" "}
                <span className={styles.metadata}>
                  {internalCommentedTicketsCount} of {numOfCreatedTickets}
                </span>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.label}>Number of Bugs Created</div>
              <div className={styles.value}> </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default SharedReportTopMetrics;
