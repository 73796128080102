import React, { useMemo, useState } from "react";

import { Flex, Avatar, Text } from "@radix-ui/themes";
import { RadixColor } from "@/components/radixColors";
import { getInitials } from "../explore/cells";

import { Collaborator } from "./index.types";
import styles from "./styles.module.scss";
import TicketQAScore from "../quality/ticket-qa-score/TicketQAScore";
import { useTicketInfo } from "@/api/useTicketInfo";

export const AgentActivity = ({
  agent,
  color,
}: {
  agent: Collaborator;
  color: RadixColor;
}) => {
  const collaboratorInitials = useMemo(
    () => getInitials(agent.name),
    [agent.name]
  );
  const [open, setOpen] = useState(false);

  const ticket = useMemo(() => agent.tickets[0], [agent.tickets]);
  const { ticketData } = useTicketInfo({
    displayId: ticket.displayId,
    sourceID: ticket.sourceID,
    id: ticket.id,
    isOpen: open,
  });

  return (
    <Flex className={styles.agentActivity} gap={"2"} direction={"column"}>
      <Flex style={{ width: "100%" }} align={"center"} gap={"2"}>
        <Avatar
          size={"1"}
          fallback={collaboratorInitials}
          color={color}
          radius={"full"}
        />
        <Text size={"2"} weight={"medium"}>
          {agent.name}
        </Text>
      </Flex>
      <Text size={"2"} color="gray">
        {agent.tickets.length} Related Tickets
      </Text>
      {ticketData?.status && open && (
        <TicketQAScore
          ticket={ticketData}
          navbarItems={[{ title: "Knowledge gaps" }]}
          onClose={() => setOpen(false)}
          reloadTickets={async () => {}}
          allowValidateTicket={false}
          viewOnly
        />
      )}
    </Flex>
  );
};
