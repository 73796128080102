import { ChangeEventHandler, useCallback, useState } from "react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Box, Flex, TextField, Button, Text, Em } from "@radix-ui/themes";
import { useDebouncedCallback } from "@/lib/useDebouncedCallback";
import { TicketTranscriptFilterValue } from "@/api/ticket-transcript/TicketTranscript.model";
import { TicketTranscriptFilterMenu } from "./TicketTranscriptFilterMenu";
import styles from "./TicketTranscript.module.scss";

interface TicketTranscriptHeaderProps {
  search: string;
  filterValue: TicketTranscriptFilterValue;
  onSearchChange: (newValue: string) => void;
  onFilterChange: (newValue: TicketTranscriptFilterValue) => void;
}

export function TicketTranscriptHeader({
  search,
  filterValue,
  onSearchChange,
  onFilterChange,
}: TicketTranscriptHeaderProps) {
  const debouncedOnSearchChange = useDebouncedCallback<void, [string]>(
    onSearchChange,
    700
  );

  const [innerSearch, setInnerSearch] = useState(search);

  const handleSearchChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setInnerSearch(event.currentTarget.value);
      debouncedOnSearchChange(event.currentTarget.value);
    },
    [debouncedOnSearchChange]
  );

  return (
    <Flex px={"3"} align={"center"} className={styles.Search}>
      <Box width={"100%"}>
        <TextField.Root
          placeholder={"Search in transcript…"}
          color={"gray"}
          size={"2"}
          value={innerSearch}
          onChange={handleSearchChange}
          className={styles.TextField}
        >
          <TextField.Slot>
            <MagnifyingGlassIcon height={"16"} width={"16"} />
          </TextField.Slot>

          <TextField.Slot pr="3">
            <TicketTranscriptFilterMenu
              filterValue={filterValue}
              onFilterChange={onFilterChange}
            />
          </TextField.Slot>
        </TextField.Root>
      </Box>
    </Flex>
  );
}
