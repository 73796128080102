import { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import {
  Container,
  Flex,
  Text,
  Card,
  Progress,
  Spinner,
  Box,
  Button,
  Inset,
} from "@radix-ui/themes";
import { useAuth0 } from "@auth0/auth0-react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { RadioItem } from "../../../components/shared/inputs/radio-group/RadioGroup";
import { IntegrationType } from "../../../api/integration/Integration.model";
import { useIntegrations } from "@/api/integration";
import { useBackfillStatus, useOnboarding } from "../../../api/onboarding";
import HeadingSVG from "./assets/HeadingSVG";
import styles from "./GettingStarted.module.scss";
import SlackCTASVG from "./assets/SlackCTASVG";
import { useFeatureFlag } from "configcat-react";

import { Navigate } from "react-router-dom";
import AddIntegrationDialog from "@/features/integrations/add-integration/AddIntegration";
import { useCreateNangoIntegration } from "@/features/integrations";

const phrases = [
  "Syncing Data",
  "Creating Topics",
  "Enriching Data",
  "Creating Insights",
];

export function BackfillStatusAnimation() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 10_000); // Change text every 10 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={index}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
      >
        {phrases[index]}
      </motion.div>
    </AnimatePresence>
  );
}

function Header() {
  const { user } = useAuth0();
  return (
    <Flex direction={"column"} gap={"5px"}>
      <Text size={"8"} weight={"medium"}>
        {`Hello ${user?.name},`}
      </Text>

      <Text size="4" weight="regular" color="gray">
        Let's set things up and get me trained to work perfectly for your team
      </Text>
    </Flex>
  );
}

// function CopilotCTA() {
//   return (
//     <Box maxWidth="350px">
//       <Card size="2">
//         <Inset clip="padding-box" side="top" pb="current">
//           <CopilotCTASVG />
//         </Inset>

//         <Flex direction={"column"} gap={"5px"}>
//           <Text size="3" weight={"medium"}>
//             Download Quack Co-pilot
//           </Text>

//           <Text size="1" color="gray">
//             Add Quack to boost ticketing workflows and assist with actionable
//             insights.
//           </Text>

//           <Flex flexGrow={"1"} align={"end"} pt={"20px"}>
//             <Link
//               to={
//                 "https://chromewebstore.google.com/detail/quack-support-co-pilot/ipdeeefkkfmpcpamomgfmkjkidpkncme/related"
//               }
//             >
//               Install Chrome extension
//             </Link>
//           </Flex>
//         </Flex>
//       </Card>
//     </Box>
//   );
// }

function SlackCTA({
  disabled,
  reloadIntegrations,
}: {
  disabled: boolean;
  reloadIntegrations: () => Promise<unknown>;
}) {
  const createNangoIntegration = useCreateNangoIntegration({
    reloadIntegrations,
  });

  return (
    <Box maxWidth="350px">
      <Card size="2">
        <Inset clip="padding-box" side="top" pb="current">
          <SlackCTASVG />
        </Inset>

        <Flex direction={"column"} gap={"5px"}>
          <Text size="3" weight={"medium"}>
            Integrate with Slack
          </Text>

          <Text size="1" color="gray">
            Enable your team to ‘Ask Quack Anything’—get instant answers,
            insights, and support in Slack.
          </Text>

          <Flex flexGrow={"1"} align={"end"} pt={"20px"}>
            <Button
              color="gray"
              variant="outline"
              size="2"
              highContrast
              onClick={() =>
                createNangoIntegration({
                  name: "slack",
                  type: "CHAT",
                })
              }
              disabled={disabled}
            >
              {disabled ? `Integrated` : `Integrate with Slack`}
            </Button>
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
}
export function CTA({
  disabled,
  reloadIntegrations,
}: {
  disabled: boolean;
  reloadIntegrations: () => Promise<unknown>;
}) {
  return (
    <Flex px={"45px"} pt={"35px"} direction={"column"} gap={"16px"}>
      <Text size={"4"} weight={"medium"}>
        Take Quack with you everywhere
      </Text>

      <Flex gap={"16px"}>
        <SlackCTA disabled={disabled} reloadIntegrations={reloadIntegrations} />
      </Flex>
    </Flex>
  );
}

interface StepProps {
  name: string;
  title: string;
  isDone: boolean;
  onClick: () => void;
}

function Step({ name, title, isDone, onClick }: StepProps) {
  const { backfillStatus } = useOnboarding();

  return (
    <Card
      size="2"
      className={cx(styles.Step, {
        [styles.done]: isDone,
      })}
      onClick={onClick}
    >
      <Flex px={"9px"} py={"4px"} gap={"20px"} align={"center"}>
        <RadioGroupPrimitive.Root disabled value={isDone ? name : undefined}>
          <RadioItem id={name} value={name} />
        </RadioGroupPrimitive.Root>

        <Flex direction={"column"} gap={"5px"} flexGrow={"1"}>
          <Text size={"2"} color="gray">
            {name}
          </Text>

          <Text size={"3"} weight={"medium"} className={styles.Title}>
            {title}
          </Text>
        </Flex>

        {name === "Step 2" && backfillStatus === "started" && (
          <Flex gap={"2"} align={"center"}>
            <Spinner />
            <Text size={"2"} color="gray" className="min-w-[120px]">
              <BackfillStatusAnimation />
            </Text>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}

function Steps() {
  const {
    data: integrations,
    isLoading: loadingIntegrations,
    refetch: reloadIntegrations,
  } = useIntegrations();

  const {
    hasKnowledgeBaseIntegration,
    hasTicketSystemIntegration,
    hasChatIntegration,
  } = useOnboarding();

  const { user } = useAuth0();
  const { value: slackSelfSetup } = useFeatureFlag("slackSelfSetup", false, {
    identifier: user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });

  const [integrationType, setIntegrationType] = useState<IntegrationType>();

  const steps = useMemo<StepProps[]>(
    () => [
      {
        name: "Step 1",
        title: "Connect knowledge sources",
        isDone: hasKnowledgeBaseIntegration,
        onClick: () => setIntegrationType("KNOWLEDGE_BASE"),
      },
      {
        name: "Step 2",
        title: "Connect ticketing system",
        isDone: hasTicketSystemIntegration,
        onClick: () => setIntegrationType("TICKET_SYSTEM"),
      },
    ],
    [hasKnowledgeBaseIntegration, hasTicketSystemIntegration]
  );

  return (
    <>
      <Flex direction={"column"} gap={"15px"}>
        {steps.map((step) => (
          <Step {...step} />
        ))}
      </Flex>

      {slackSelfSetup && !hasChatIntegration && (
        <CTA
          disabled={hasChatIntegration}
          reloadIntegrations={reloadIntegrations}
        />
      )}

      {integrationType != null && (
        <AddIntegrationDialog
          type={integrationType}
          loading={loadingIntegrations}
          existingIntegrations={integrations}
          reloadIntegrations={reloadIntegrations}
          onClose={() => setIntegrationType(undefined)}
          allowMultiple={integrationType === "KNOWLEDGE_BASE"}
        />
      )}
    </>
  );
}

function BackfillLoadingPage() {
  const { totalTickets, completedTickets } = useBackfillStatus();

  const backfillProgress = useMemo(() => {
    if (totalTickets == null || completedTickets == null) {
      return 5;
    }

    return (completedTickets / totalTickets) * 100;
  }, [totalTickets, completedTickets]);

  return (
    <Flex flexGrow={"1"} pt={"120px"} justify={"center"}>
      <Flex direction={"column"} gap={"4"}>
        <HeadingSVG />

        <Flex direction={"column"} gap={"4"} maxWidth={"540px"}>
          <Progress value={backfillProgress} color="gray" highContrast />

          <Text
            color={"gray"}
            size={"3"}
            weight={"medium"}
            highContrast
            align={"center"}
          >
            <BackfillStatusAnimation />
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

function GettingStarted() {
  const {
    isDone,
    hasKnowledgeBaseIntegration,
    hasTicketSystemIntegration,
    backfillStatus,
  } = useOnboarding();

  if (
    hasKnowledgeBaseIntegration &&
    hasTicketSystemIntegration &&
    backfillStatus == "started"
  ) {
    return <BackfillLoadingPage />;
  }

  if (isDone) {
    return <Navigate to={"/"} />;
  }

  return (
    <Flex flexGrow={"1"} className={styles.GettingStarted}>
      <Container size="3">
        <Flex px={"25px"} pt={"80px"} direction={"column"} gap={"45px"}>
          <Header />
          <Steps />
        </Flex>
      </Container>
    </Flex>
  );
}

export default GettingStarted;
