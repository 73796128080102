import { useState, useCallback, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { isAxiosError } from "axios";
import { useDebouncedCallback } from "@/lib/useDebouncedCallback";
import { useApi } from "@/api/useApi";
import { UpdateStatus } from "@/features/automation-configuration/utils/automation-configuration.utils";

interface QuackchatSessionConfiguration {
  showCsat: boolean;
  checkAiResolvedAfterSeconds: number;
  checkInactivityTimeoutAfterSeconds: number;
  followUpReminderAfterSeconds: number;
}

type QuackchatSessionConfigurationResponse = QuackchatSessionConfiguration;

function useGetQuackchatSessionConfiguration() {
  const { api } = useApi();

  return useQuery<QuackchatSessionConfigurationResponse>({
    queryKey: "useGetQuackchatSessionConfiguration",
    queryFn: async () => {
      const res = await api.get(`/sessions-api/quackchat-config`);

      return res.data;
    },
  });
}

export type UpdateQuackchatSessionConfigurationRequest =
  Partial<QuackchatSessionConfiguration>;

type ValidationError = {
  message: string;
  errors: Array<{
    field: string;
    message: string;
  }>;
};

function isValidationError(error: unknown): error is ValidationError {
  return (
    typeof error === "object" &&
    error != null &&
    "message" in error &&
    "errors" in error
  );
}

function useUpdateQuackchatSessionConfiguration() {
  const { api } = useApi();

  return useMutation<
    QuackchatSessionConfigurationResponse,
    unknown,
    UpdateQuackchatSessionConfigurationRequest
  >(async (request) => {
    const res = await api.put(`/sessions-api/quackchat-config`, request);

    return res.data;
  });
}

export function useQuackchatSessionConfiguration() {
  const { data, isLoading, refetch } = useGetQuackchatSessionConfiguration();

  const { mutateAsync } = useUpdateQuackchatSessionConfiguration();

  const [updateStatus, setUpdateStatus] = useState<UpdateStatus>("idle");
  const [localConfiguration, setLocalConfiguration] =
    useState<QuackchatSessionConfiguration>(null!);
  const [errors, setErrors] = useState<Record<string, string> | undefined>();

  const updateConfiguration = useCallback(
    async (request: UpdateQuackchatSessionConfigurationRequest) => {
      try {
        setErrors(undefined);
        setUpdateStatus("saving");
        const res = await mutateAsync(request);
        await refetch();

        setUpdateStatus("saved");
        return res;
      } catch (error) {
        setUpdateStatus("error");

        if (isAxiosError(error) && isValidationError(error.response?.data)) {
          setErrors(
            error.response?.data.errors.reduce(
              (acc, { field, message }) => ({
                ...acc,
                [field]: message,
              }),
              {}
            )
          );
        }
      }
    },
    [mutateAsync, refetch]
  );

  const debouncedUpdateConfiguration = useDebouncedCallback<
    Promise<QuackchatSessionConfiguration | undefined>,
    [UpdateQuackchatSessionConfigurationRequest]
  >(updateConfiguration, 1000);

  const handleUpdateConfiguration = useCallback(
    (request: UpdateQuackchatSessionConfigurationRequest) => {
      setLocalConfiguration((prevState) => ({
        ...prevState,
        ...request,
      }));
      debouncedUpdateConfiguration(request);
    },
    [debouncedUpdateConfiguration]
  );

  useEffect(() => {
    if (data != null) {
      setLocalConfiguration(data);
    }
  }, [data]);

  useEffect(() => {
    if (updateStatus === "saved") {
      const timer = setTimeout(() => setUpdateStatus("idle"), 2000);
      return () => clearTimeout(timer);
    }
  }, [updateStatus]);

  return {
    configuration: localConfiguration,
    isLoading,
    updateConfiguration: handleUpdateConfiguration,
    updateStatus,
    errors,
  };
}
