import React, { useState, useEffect } from "react";
import { Flex, Text } from "@radix-ui/themes";
import styles from "./TicketQAScore.module.scss";
import { TicketListItem as TicketListItemType } from "@/features/tickets-info";
import cx from "classnames";
import { Search } from "@/components/shared/inputs/search";

export const TicketsList = ({
  activeTicketId,
  tickets,
  setActiveTicketId,
}: {
  activeTicketId: string;
  tickets: TicketListItemType[];
  setActiveTicketId: (id: string) => void;
}) => {
  const [searchPhrase, setSearchPhrase] = useState("");
  const [filteredTickets, setFilteredTickets] =
    useState<TicketListItemType[]>(tickets);

  useEffect(() => {
    setFilteredTickets(
      tickets.filter(
        (ticket) =>
          ticket.subject.toLowerCase().includes(searchPhrase.toLowerCase()) ||
          ticket.id.toLowerCase().includes(searchPhrase.toLowerCase()) ||
          ticket.displayId.toLowerCase().includes(searchPhrase.toLowerCase()) ||
          ticket.sourceId.toLowerCase().includes(searchPhrase.toLowerCase())
      )
    );
  }, [searchPhrase]);

  return (
    <Flex direction={"column"} gap={"3"} className={styles.TicketsList}>
      <Flex direction={"column"} gap={"2"} align={"start"}>
        <Text size={"5"} weight={"bold"}>
          Related tickets
        </Text>
        <Search
          placeholder="Search ticket"
          setSearchPhrase={setSearchPhrase}
          debounce={1000}
        />
      </Flex>
      <Flex direction={"column"} gap={"2"}>
        {filteredTickets.map((ticket) => (
          <TicketListItem
            key={ticket.id}
            ticket={ticket}
            active={activeTicketId === ticket.id}
            onClick={() => setActiveTicketId(ticket.id)}
          />
        ))}
      </Flex>
    </Flex>
  );
};

const TicketListItem = ({
  ticket,
  active,
  onClick,
}: {
  ticket: TicketListItemType;
  active: boolean;
  onClick: () => void;
}) => {
  return (
    <Flex
      direction={"column"}
      className={cx(styles.TicketListItem, {
        [styles.active]: active,
      })}
      onClick={onClick}
    >
      <Text size={"2"} weight={"medium"}>
        {ticket.subject}
      </Text>
      <Text size={"2"} weight={"medium"} color="gray">
        #{ticket.displayId || ticket.sourceId}
      </Text>
    </Flex>
  );
};
