import { forwardRef, PropsWithChildren, useContext, useMemo } from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import cx from "classnames";
import styles from "./Select.module.scss";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { radixThemeDataAttributes } from "@/components/Theme";

export type SelectOption = {
  label: string;
  value: string;
};

interface SelectItemProps {
  value: string;
  selected?: boolean;
  className?: string;
}

const SelectItem = forwardRef(function SelectItem(
  {
    children,
    value,
    selected = false,
    className,
  }: PropsWithChildren<SelectItemProps>,
  forwardedRef: React.Ref<HTMLDivElement>
) {
  return (
    <SelectPrimitive.Item
      ref={forwardedRef}
      value={value}
      className={cx(styles.Item, className)}
      aria-selected={selected}
    >
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});

interface SelectProps {
  options?: SelectOption[];
  value?: string[];
  onChange: (value: string) => void;
  size?: "small" | "medium";
  placeholder?: string;
  classes?: {
    trigger?: string;
  };
}

function MultiSelect({
  options = [],
  value,
  onChange,
  size = "medium",
  placeholder = "Select …",
  classes = {},
}: SelectProps) {
  const selectedValue = useMemo(() => {
    return options
      .filter((option) => value?.includes(option.value))
      .map((option) => option.label)
      .join(", ");
  }, [options, value]);

  return (
    <SelectPrimitive.Root value={selectedValue} onValueChange={onChange}>
      <SelectPrimitive.Trigger
        className={cx(styles.Trigger, styles[size], classes.trigger)}
      >
        <SelectPrimitive.Value
          placeholder={placeholder}
          aria-label={selectedValue}
        >
          {selectedValue}
        </SelectPrimitive.Value>

        <SelectPrimitive.Icon className={styles.Icon}>
          <ChevronDownIcon />
        </SelectPrimitive.Icon>
      </SelectPrimitive.Trigger>

      <SelectPrimitive.Portal>
        <SelectPrimitive.Content
          position={"popper"}
          sideOffset={5}
          className={cx(styles.Content, "radix-themes")}
          {...radixThemeDataAttributes}
        >
          <SelectPrimitive.Viewport className={styles.Viewport}>
            {options.map((option) => (
              <SelectItem
                key={option.value}
                value={option.value}
                selected={value?.includes(option.value)}
              >
                {option.label}
              </SelectItem>
            ))}
          </SelectPrimitive.Viewport>
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  );
}

export default MultiSelect;
