import { Text } from "@radix-ui/themes";
import { CheckIcon } from "@radix-ui/react-icons";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import styles from "./Checkbox.module.scss";

interface CheckboxProps {
  label: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
}

function Checkbox({ label, value, onChange }: CheckboxProps) {
  return (
    <Text size={"2"} as="label" className={styles.Label}>
      <CheckboxPrimitive.Root
        checked={value}
        onCheckedChange={onChange}
        className={styles.Root}
      >
        <CheckboxPrimitive.Indicator className={styles.Indicator}>
          <CheckIcon />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>

      {label}
    </Text>
  );
}

export default Checkbox;
