import { ReactNode } from "react";
import { AutopilotSVG, DarkAutopilotSVG } from "./assests/AutopilotSVG";
import { CopilotSVG, DarkCopilotSVG } from "./assests/CopilotSVG";
import {
  KnowledgeViewSVG,
  DarkKnowledgeViewSVG,
} from "./assests/KnowledgeViewSVG";
import {
  KnowledgeGapsSVG,
  DarkKnowledgeGapsSVG,
} from "./assests/KnowledgeGapsSVG";
import { EvaluationsSVG, DarkEvaluationsSVG } from "./assests/EvaluationsSVG";
import { ValidationsSVG, DarkValidationsSVG } from "./assests/ValidationsSVG";
import {
  SessionsReportSVG,
  DarkSessionsReportSVG,
} from "./assests/SessionsReportSVG";
import {
  ExploreInsightsSVG,
  DarkExploreInsightsSVG,
} from "./assests/ExploreInsightsSVG";
import {
  ExploreTicketsSVG,
  DarkExploreTicketsSVG,
} from "./assests/ExploreTicketsSVG";
import {
  ExploreConversationsSVG,
  DarkExploreConversationsSVG,
} from "./assests/ExploreConversationsSVG";
import { useTheme } from "../Theme";

const emptyStateSvgNames = [
  "auto-pilot",
  "co-pilot",
  "knowledge-view",
  "knowledge-gaps",
  "evaluations",
  "validations",
  "sessions-report",
  "explore-insights",
  "explore-tickets",
  "explore-conversations",
] as const;

export type EmptyStateSvgName = (typeof emptyStateSvgNames)[number];

const svgs: Record<EmptyStateSvgName, { light: ReactNode; dark: ReactNode }> = {
  "auto-pilot": { light: <AutopilotSVG />, dark: <DarkAutopilotSVG /> },
  "co-pilot": { light: <CopilotSVG />, dark: <DarkCopilotSVG /> },
  "knowledge-view": {
    light: <KnowledgeViewSVG />,
    dark: <DarkKnowledgeViewSVG />,
  },
  "knowledge-gaps": {
    light: <KnowledgeGapsSVG />,
    dark: <DarkKnowledgeGapsSVG />,
  },
  evaluations: { light: <EvaluationsSVG />, dark: <DarkEvaluationsSVG /> },
  validations: { light: <ValidationsSVG />, dark: <DarkValidationsSVG /> },
  "sessions-report": {
    light: <SessionsReportSVG />,
    dark: <DarkSessionsReportSVG />,
  },
  "explore-insights": {
    light: <ExploreInsightsSVG />,
    dark: <DarkExploreInsightsSVG />,
  },
  "explore-tickets": {
    light: <ExploreTicketsSVG />,
    dark: <DarkExploreTicketsSVG />,
  },
  "explore-conversations": {
    light: <ExploreConversationsSVG />,
    dark: <DarkExploreConversationsSVG />,
  },
};

export function EmptyStateSvg({ name }: { name: EmptyStateSvgName }) {
  const { isDarkMode } = useTheme();

  return <>{svgs[name][isDarkMode ? "dark" : "light"]}</>;
}
