import React, { useMemo, useState } from "react";

import cx from "classnames";
import styles from "./styles.module.scss";
import { useSearchParams } from "react-router-dom";
import { useFeatureFlag } from "configcat-react";
import { Button, Input, Modal } from "@geist-ui/core";
import { createToast } from "vercel-toast";

import Nango from "@nangohq/frontend";

const nango = new Nango({ publicKey: "7d3dd047-cdc9-4f26-ae76-a1a75565600a" });

const integrationDescriptions: { [key: string]: string } = {
  zendesk: "/zendesk.png",
  document360:
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrNGqfsZAIfNDkuUBexVfhCsKluYexGJg8HV7RwrdRuJxOgxod2SVY2-tawvIhjKpps50&usqp=CAU",
  jira: "https://seeklogo.com/images/J/jira-logo-FD39F795A7-seeklogo.com.png",
  salesforce:
    "https://static-00.iconduck.com/assets.00/salesforce-icon-2048x1434-jxt80iiu.png",
  gitbook:
    "https://www.google.com/url?sa=i&url=https%3A%2F%2Ficonduck.com%2Ficons%2F108465%2Fgitbook&psig=AOvVaw2uRUO-4h2dEEX-LIqwow3T&ust=1725486013734000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCKiA-bjep4gDFQAAAAAdAAAAABAR",
  kustomer: "https://app.nango.dev/images/template-logos/kustomer.svg",
  front: "https://app.nango.dev/images/template-logos/front.svg",
};

const instructions: { [key: string]: string } = {
  zendesk:
    "https://support.zendesk.com/hc/en-us/articles/4408889192858-Managing-access-to-the-Zendesk-API#topic_tcb_fk1_2yb",
  document360: "https://apidocs.document360.com/apidocs/api-token",
  freshdesk:
    "https://support.freshdesk.com/support/solutions/articles/215517-how-to-find-your-api-key",
  gitbook: "https://developer.gitbook.com/gitbook-api/authentication",
  mixpanel: "https://developer.mixpanel.com/reference/service-accounts",
  kustomer:
    "https://developer.kustomer.com/kustomer-api-docs/reference/authentication",
  front: "https://dev.frontapp.com/docs/create-and-revoke-api-tokens",
  intercom:
    "https://developers.intercom.com/docs/build-an-integration/learn-more/authentication",
  "zendesk chat":
    "https://integrations.quack.ai/BKUZmzGN2Mmipf4UROTF/chat/connecting-quack-ai-to-zendesk-conversations-api",
  datadog:
    "https://thequack.notion.site/Integrate-to-Datadog-686b4561b2504243a013cafa2584f277",
};

const tokenTypes = [
  "zendesk",
  "document360",
  "freshdesk",
  "gitbook",
  "mixpanel",
  "kustomer",
  "front",
  "intercom",
  "zendesk chat",
  "datadog",
];

type ZendeskChatConfigValue = {
  appId: string;
  keyId: string;
  secretKey: string;
  webhookId: string;
  sharedSecret: string;
};

const isValidZendeskChatConfig = (
  config: Partial<ZendeskChatConfigValue>
): boolean => {
  const requiredFields: (keyof ZendeskChatConfigValue)[] = [
    "appId",
    "keyId",
    "secretKey",
    "webhookId",
    "sharedSecret",
  ];

  return requiredFields.every((field) => !!config[field]);
};

export const IntegrationsInvite = ({
  source,
}: {
  source: "feature_flag" | "searchparam";
}) => {
  const [isOpen, setOpen] = useState(false);
  const [apiToken, setAPIToken] = useState("");
  const [searchParams] = useSearchParams();

  const [integrationConfig, setIntegrationConfig] = useState("");
  const [email, setEmail] = useState("");
  const [subdomain, setSubdomain] = useState("");

  const [zendeskChatConfig, setZendeskChatConfig] = useState<
    Partial<ZendeskChatConfigValue>
  >({});

  const handleZendeskChatConfig = (config: Partial<ZendeskChatConfigValue>) => {
    setZendeskChatConfig((prevConfig) => ({
      ...prevConfig,
      ...config,
    }));
  };

  const token = searchParams.get("t") ?? "";
  const integrationTicketingSystem = searchParams.get("t") ?? "";
  const integrationKB = searchParams.get("kb") ?? "";

  const { value } = useFeatureFlag("integrations", "nothing:nothing", {
    identifier: token ?? "",
    email: "test@quack.ai",
    custom: {
      t: token,
    },
  });

  const tenant =
    source === "feature_flag"
      ? value.split(":")?.[0]
      : searchParams.get("tenant") ?? "";
  const integrations =
    source === "feature_flag"
      ? value.split(":")?.[1]?.split(",") ?? []
      : Array.from(
          new Set([
            integrationTicketingSystem.toLowerCase(),
            integrationKB.toLowerCase(),
          ])
        ).filter((x) => x).length === 0
      ? [
          "zendesk",
          "salesforce",
          "intercom",
          "front",
          "kustomer",
          "freshdesk",
          "document360",
          "gitbook",
          "confluence",
        ]
      : Array.from(
          new Set([
            integrationTicketingSystem.toLowerCase(),
            integrationKB.toLowerCase(),
          ])
        ).filter((x) => x);

  const isValid = useMemo(() => {
    if (integrationConfig === "zendesk chat") {
      return isValidZendeskChatConfig(zendeskChatConfig);
    }

    return apiToken != null;
  }, [integrationConfig, zendeskChatConfig, apiToken]);

  if ((source === "feature_flag" && !token) || tenant === "none") {
    return <div>nothing here</div>;
  }

  return (
    <div className={styles.container}>
      <div className="space-y-6 text-center">
        <h1 className={styles.title}>Connect Your Integrations</h1>
        <p className="max-w-[700px] mx-auto text-muted-foreground md:text-xl/relaxed text-left">
          To get started, connect the relevant integrations to your account.
          <br />
          And let's Quack it!
        </p>
      </div>
      <div className={styles.grid}>
        {integrations.map((integration: string) => {
          return (
            <Button
              style={{
                height: "200px",
                border: localStorage.getItem(
                  `quack-integrations-${integration}`
                )
                  ? "1px solid green"
                  : "",
              }}
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              placeholder=""
              onClick={() => {
                if (tokenTypes.includes(integration)) {
                  setIntegrationConfig(integration);
                  setOpen(true);
                } else {
                  nango
                    .auth(integration, tenant)
                    .then(
                      async (result: {
                        providerConfigKey: string;
                        connectionId: string;
                      }) => {
                        createToast(
                          `Integration to ${integration} created Successfully`,
                          { type: "success" }
                        );
                        localStorage.setItem(
                          `quack-integrations-${result.providerConfigKey}`,
                          JSON.stringify({
                            integrationType: result.providerConfigKey,
                            integrationId: result.connectionId,
                          })
                        );

                        await fetch(
                          `https://hooks.slack.com/services/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`,
                          {
                            method: "POST",
                            headers: {},
                            body: JSON.stringify({
                              blocks: [
                                {
                                  type: "section",
                                  text: {
                                    type: "mrkdwn",
                                    text: `New Integration 🎉 \n *Tenant*: ${tenant} \n *Integration*: ${integration} \n *Method*:Nango`,
                                  },
                                },
                              ],
                            }),
                          }
                        );
                      }
                    )
                    .catch((err: { message: string; type: string }) => {
                      // handle error
                      createToast(`Something went wrong ${err}`, {
                        type: "error",
                      });
                    });
                }
              }}
            >
              <div className={cx(styles.card)} key={integration}>
                <div className="flex flex-col items-center">
                  <img
                    src={
                      integrationDescriptions[integration] ??
                      `https://app.nango.dev//images/template-logos/${integration}.svg`
                    }
                    alt=""
                  />
                  <h3>{integration}</h3>
                </div>
              </div>
            </Button>
          );
        })}
      </div>
      <Modal onClose={() => setOpen(false)} visible={isOpen}>
        <Modal.Title>{integrationConfig}</Modal.Title>
        <Modal.Content>
          {integrationConfig === "zendesk chat" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Input
                style={{ width: "280px" }}
                placeholder="Your App ID"
                crossOrigin={""}
                value={zendeskChatConfig.appId}
                onChange={(e) => {
                  handleZendeskChatConfig({ appId: e.target.value });
                }}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
              <br />
              <Input
                style={{ width: "280px" }}
                placeholder="Your Key ID"
                crossOrigin={""}
                value={zendeskChatConfig.keyId}
                onChange={(e) => {
                  handleZendeskChatConfig({ keyId: e.target.value });
                }}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
              <br />
              <Input
                style={{ width: "280px" }}
                placeholder="Your Secret Key"
                crossOrigin={""}
                value={zendeskChatConfig.secretKey}
                onChange={(e) => {
                  handleZendeskChatConfig({ secretKey: e.target.value });
                }}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
              <br />
              <Input
                style={{ width: "280px" }}
                placeholder="Your Webhook ID"
                crossOrigin={""}
                value={zendeskChatConfig.webhookId}
                onChange={(e) => {
                  handleZendeskChatConfig({ webhookId: e.target.value });
                }}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
              <br />
              <Input
                style={{ width: "280px" }}
                placeholder="Your Shared Secret"
                crossOrigin={""}
                value={zendeskChatConfig.sharedSecret}
                onChange={(e) => {
                  handleZendeskChatConfig({ sharedSecret: e.target.value });
                }}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
              <p style={{ color: "#949494" }}>
                Need help ?
                <a
                  style={{
                    color: "#949494",
                    textDecoration: "underline",
                    marginLeft: "6px",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={instructions[integrationConfig]}
                >
                  see docs
                </a>
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {integrationConfig === "zendesk" && (
                <>
                  <Input
                    style={{ width: "280px" }}
                    placeholder="Your Subdomain"
                    crossOrigin={""}
                    value={subdomain}
                    onChange={(e) => {
                      setSubdomain(e.target.value);
                    }}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                  <br />
                  <Input
                    style={{ width: "280px" }}
                    placeholder="Your Email"
                    crossOrigin={""}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                  />
                </>
              )}
              {integrationConfig === "freshdesk" && (
                <>
                  <Input
                    style={{ width: "280px" }}
                    placeholder="Your Subdomain"
                    crossOrigin={""}
                    value={subdomain}
                    onChange={(e) => {
                      setSubdomain(e.target.value);
                    }}
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                  />
                </>
              )}
              {integrationConfig === "kustomer" && (
                <>
                  <Input
                    style={{ width: "280px" }}
                    placeholder="Your Subdomain"
                    crossOrigin={""}
                    value={subdomain}
                    onChange={(e) => {
                      setSubdomain(e.target.value);
                    }}
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                  />
                </>
              )}
              {integrationConfig === "mixpanel" && (
                <>
                  <Input
                    style={{ width: "280px" }}
                    placeholder="Your Project Id"
                    crossOrigin={""}
                    value={subdomain}
                    onChange={(e) => {
                      setSubdomain(e.target.value);
                    }}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                  <br />
                  <Input
                    style={{ width: "280px" }}
                    placeholder="Service account username"
                    crossOrigin={""}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                </>
              )}
              {integrationConfig === "datadog" && (
                <>
                  <Input
                    style={{ width: "280px" }}
                    placeholder="Your Application Key"
                    crossOrigin={""}
                    value={subdomain}
                    onChange={(e) => {
                      setSubdomain(e.target.value);
                    }}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                </>
              )}

              <p>Paste here your API token:</p>
              <Input
                style={{ width: "280px" }}
                placeholder="Your API Token"
                crossOrigin={""}
                value={apiToken}
                onChange={(e) => {
                  setAPIToken(e.target.value);
                }}
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />

              <p style={{ color: "#949494" }}>
                Can't find your token:
                <a
                  style={{
                    color: "#949494",
                    textDecoration: "underline",
                    marginLeft: "6px",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={instructions[integrationConfig]}
                >
                  generate token here
                </a>
              </p>
            </div>
          )}
        </Modal.Content>
        <Modal.Action
          passive
          onClick={() => setOpen(false)}
          placeholder=""
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        >
          Cancel
        </Modal.Action>
        <Modal.Action
          disabled={!isValid}
          placeholder=""
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          onClick={async () => {
            localStorage.setItem(
              `quack-integrations-${integrationConfig}`,
              JSON.stringify({
                set: true,
              })
            );

            if (integrationConfig === "zendesk chat") {
              const { appId, keyId, secretKey, webhookId, sharedSecret } =
                zendeskChatConfig;

              await fetch(
                `https://hooks.slack.com/services/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`,
                {
                  method: "POST",
                  headers: {},
                  body: JSON.stringify({
                    blocks: [
                      {
                        type: "section",
                        text: {
                          type: "mrkdwn",
                          text: `New Integration 🎉 \n *Tenant*: ${tenant} \n *Integration*: ${integrationConfig} \n${
                            appId ? `\n*AppId*:${appId}` : ""
                          } ${keyId ? `\n*KeyId*:${keyId}` : ""} ${
                            secretKey ? `\n*SecretKey*:${secretKey}` : ""
                          } ${webhookId ? `\n*WebhookId*:${webhookId}` : ""} ${
                            sharedSecret
                              ? `\n*SharedSecret*:${sharedSecret}`
                              : ""
                          } `,
                        },
                      },
                    ],
                  }),
                }
              );

              setZendeskChatConfig({});
              setOpen(false);
              createToast(
                `Integration to ${integrationConfig} created Successfully`,
                { type: "success" }
              );
              return;
            }

            await fetch(
              `https://hooks.slack.com/services/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`,
              {
                method: "POST",
                headers: {},
                body: JSON.stringify({
                  blocks: [
                    {
                      type: "section",
                      text: {
                        type: "mrkdwn",
                        text: `New Integration 🎉 \n *Tenant*: ${tenant} \n *Integration*: ${integrationConfig} \n*Token*: ${apiToken} ${
                          email ? `\n*Email*:${email}` : ""
                        } ${subdomain ? `\n*Subdomain*:${subdomain}` : ""} `,
                      },
                    },
                  ],
                }),
              }
            );
            setAPIToken("");
            setOpen(false);
            createToast(
              `Integration to ${integrationConfig} created Successfully`,
              { type: "success" }
            );
          }}
        >
          Submit
        </Modal.Action>
      </Modal>
    </div>
  );
};
