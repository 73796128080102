import { PropsWithChildren, useState } from "react";
import { Collapsible } from "radix-ui";
import { Flex, Text, IconButton, Box } from "@radix-ui/themes";
import { CaretRightIcon, CaretDownIcon } from "@radix-ui/react-icons";

interface CollapsibleFieldListProps {
  title: string;
  defaultOpen?: boolean;
}

export function CollapsibleFieldList({
  title,
  defaultOpen = false,
  children,
}: PropsWithChildren<CollapsibleFieldListProps>) {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <Collapsible.Root open={open} onOpenChange={setOpen}>
      <Collapsible.Trigger asChild>
        <Flex
          pt={"40px"}
          pb={"16px"}
          justify={"start"}
          align={"center"}
          gap={"3"}
        >
          <Text size={"2"} color={"gray"} weight={"medium"} highContrast>
            {title}
          </Text>

          <IconButton variant={"ghost"} size={"1"} color={"gray"}>
            {open ? <CaretDownIcon /> : <CaretRightIcon />}
          </IconButton>
        </Flex>
      </Collapsible.Trigger>

      <Collapsible.Content>
        <Box pt={"24"}>{children}</Box>
      </Collapsible.Content>
    </Collapsible.Root>
  );
}
