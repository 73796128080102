import { useCallback, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Flex, ScrollArea, Text } from "@radix-ui/themes";
import { EyeClosedIcon } from "@radix-ui/react-icons";
import {
  printTicketId,
  TicketExploreRow,
} from "@/api/useExplore/Explore.model";
import { isYotpo } from "@/api/user";
import { QAScore } from "@/routes/explore/cells";
import {
  questionsOrder,
  TicketQuestionScoreType,
  TicketScore,
} from "./TicketQAScore.model";
import { QuestionListTabItem, QuestionListTabs } from "./QuestionListTabs";
import { Question } from "./Question";
import styles from "./TicketQAScore.module.scss";
import { useAgentScoreFeatFlag } from "@/api/agent-score/useAgentScoreFeatFlag";

type TicketScoreByEntity = {
  entityValue: string;
  entityName: string;
  scores: TicketScore[];
  publicCommentsCount?: number;
  avgQaScore?: number;
  totalAdjusted?: number;
};

function buildTicketScoreKey({
  scoreEntity,
  title,
  content,
  agentScore,
}: TicketScore): string {
  if (scoreEntity === "ticket" || agentScore == null) {
    return `${title}-${content}-${scoreEntity}`;
  }

  return `${title}-${content}-${scoreEntity}-${agentScore.agentId}`;
}

function getTicketScoreByEntity(
  ticketScore: TicketScore[],
  ticket: TicketExploreRow,
  scoreType: TicketQuestionScoreType
): Record<string, TicketScoreByEntity> {
  const { collaborators, overview, id: ticketId } = ticket;

  const collaboratorsMaps = new Map(
    collaborators?.map((collaborator) => [collaborator.id, collaborator]) ?? []
  );
  const uniqueScores = new Set<string>();

  const allScores: Record<string, TicketScoreByEntity> = {};

  ticketScore
    .sort((a, b) => b.id - a.id)
    .forEach((score) => {
      const key = buildTicketScoreKey(score);

      if (uniqueScores.has(key)) {
        return;
      }

      uniqueScores.add(key);

      if (score.scoreEntity === "ticket") {
        if (allScores.ticket == null) {
          allScores.ticket = {
            entityValue: "ticket",
            entityName: `Ticket ${printTicketId(
              overview?.id ?? ticketId.split("::")[1]
            )}`,
            scores: [],
            publicCommentsCount: ticket?.messagesCount,
          };
        }

        allScores.ticket.scores.push(score);
      } else if (score.agentScore?.agentId != null) {
        if (allScores[score.agentScore.agentId] == null) {
          const collaborator = collaboratorsMaps.get(score.agentScore.agentId);

          allScores[score.agentScore.agentId] = {
            entityValue: score.agentScore.agentId.toString(),
            entityName:
              collaborator?.name ??
              `Agent #${score.agentScore.agentId.toString()}`,
            scores: [],
            publicCommentsCount: collaborator?.publicCommentsCount,
          };
        }

        allScores[score.agentScore.agentId].scores.push(score);
      }
    });

  Object.keys(allScores).forEach((key) => {
    const sumQaScores = allScores[key].scores.reduce(
      (sum, { type, adjustedScore }) => {
        if (type === "binary") {
          // Convert 0-1 rating to 1-5 scale
          return sum + (adjustedScore * 4 + 1 || 1);
        }

        // Rating scores stay as-is
        return sum + (adjustedScore || 1);
      },
      0
    );

    if (allScores[key].scores.length > 0) {
      let avgQaScore = sumQaScores / allScores[key].scores.length;

      if (scoreType === "binary") {
        avgQaScore = avgQaScore / 4 - 0.25;
      }

      allScores[key].avgQaScore = avgQaScore;
    }

    const totalAdjusted = allScores[key].scores.reduce(
      (sum, { adjustedScore, autoScore }) =>
        sum + (autoScore !== adjustedScore ? 1 : 0),
      0
    );

    allScores[key].totalAdjusted = totalAdjusted;
  });

  return allScores;
}

function TicketQuestionsScoreEmptyState() {
  return (
    <div className={styles.EmptyState}>
      <EyeClosedIcon />
      <div className={styles.Header}>Evaluation not yet available</div>
      <div className={styles.Description}>
        An evaluation will be generated once the ticket reaches its final
        resolution. Check back later to review insights and results.
      </div>
    </div>
  );
}

function QuestionsTabHeader({
  title,
  publicCommentsCount,
  totalAdjusted = 0,
  avgQaScore,
}: QuestionListTabItem) {
  return (
    <Flex justify={"between"}>
      <Flex direction={"column"}>
        <Text size={"4"} color={"gray"} weight={"medium"} highContrast>
          {title}
        </Text>

        {publicCommentsCount != null && (
          <Text size={"2"} color={"gray"}>
            {`${publicCommentsCount} Public Messages`}
          </Text>
        )}
      </Flex>

      <Flex gap={"2"} align={"center"}>
        {totalAdjusted > 0 && (
          <Text size={"2"} color={"gray"}>
            {`${totalAdjusted} ${
              totalAdjusted > 1 ? "adjustments" : "adjustment"
            }`}
          </Text>
        )}

        {avgQaScore != null && (
          <Text size={"2"} color={"gray"} highContrast>
            <QAScore value={avgQaScore} />
          </Text>
        )}
      </Flex>
    </Flex>
  );
}

interface QuestionListProps {
  ticket: TicketExploreRow;
  ticketScore: TicketScore[];
  onUpdateTicketScore: () => Promise<unknown>;
  avgQAScore?: number;
}

export function QuestionList({
  ticket,
  onUpdateTicketScore,
  viewOnly = false,
  avgQAScore,
  ...props
}: QuestionListProps & { viewOnly?: boolean }) {
  const { user } = useAuth0();
  const showAgentScores = useAgentScoreFeatFlag();

  const ticketScoreByEntities = useMemo(() => {
    return getTicketScoreByEntity(
      props.ticketScore,
      ticket,
      isYotpo(user) ? "binary" : "rating"
    );
  }, [props.ticketScore, ticket, user]);

  const tabs = useMemo<QuestionListTabItem[]>(() => {
    const tabItems = Object.values(ticketScoreByEntities).map(
      ({
        entityValue,
        entityName,
        publicCommentsCount,
        avgQaScore,
        totalAdjusted,
      }) => ({
        value: entityValue,
        title: entityName,
        publicCommentsCount,
        avgQaScore,
        totalAdjusted,
      })
    );

    return tabItems.sort((a, b) => {
      if (a.value === "ticket") return -1;
      if (b.value === "ticket") return 1;
      return a.title.localeCompare(b.title);
    });
  }, [ticketScoreByEntities]);

  const [selectedTab, setSelectedTab] = useState("ticket");

  const tabContent = useMemo(() => {
    return tabs.find((tab) => tab.value === selectedTab);
  }, [tabs, selectedTab]);

  const ticketScores = useMemo(
    () => ticketScoreByEntities[selectedTab]?.scores ?? [],
    [ticketScoreByEntities, selectedTab]
  );

  const compareFn = useCallback(
    (a: TicketScore, b: TicketScore) => {
      return isYotpo(user)
        ? (questionsOrder[b.content] ?? -1) - (questionsOrder[a.content] ?? -1)
        : a.id - b.id;
    },
    [user]
  );

  const showTabs = showAgentScores && tabs.length > 1;

  if (ticketScores.length === 0) {
    return (
      <div className={styles.QuestionList}>
        <TicketQuestionsScoreEmptyState />
      </div>
    );
  }

  return (
    <>
      {showTabs && (
        <div className={styles.Header}>
          <QuestionListTabs
            tabs={tabs}
            value={selectedTab}
            onChange={setSelectedTab}
          />
        </div>
      )}

      <ScrollArea scrollbars={"vertical"}>
        <div className={styles.QuestionList}>
          {tabContent != null && <QuestionsTabHeader {...tabContent} />}

          {ticketScores.sort(compareFn).map((question) => (
            <Question
              key={question.id}
              {...question}
              onUpdateTicketScore={onUpdateTicketScore}
              viewOnly={viewOnly}
            />
          ))}
        </div>
      </ScrollArea>
    </>
  );
}
