import { params } from "../params";

interface TranslationResponse {
  data: {
    translations: Array<{
      translatedText: string;
      detectedSourceLanguage?: string;
    }>;
  };
}

interface TranslationCache {
  cache: Map<string, string>;
  get(text: string): string | undefined;
  set(text: string, translation: string): void;
}

const translationCache: TranslationCache = {
  cache: new Map<string, string>(),
  get: function (text: string) {
    return this.cache.get(text);
  },
  set: function (text: string, translation: string) {
    this.cache.set(text, translation);
  },
};

export const translateText = async (originalText: string): Promise<string> => {
  const text = originalText.replaceAll(
    `
`,
    '<span translate="no">_</span>'
  );
  // Check cache first
  const cachedTranslation = translationCache.get(text);
  if (cachedTranslation) {
    return cachedTranslation;
  }

  try {
    const response = await fetch(
      `${params.GOOGLE_CLOUD_TRANSLATION.ENDPOINT}?key=${params.GOOGLE_CLOUD_TRANSLATION.API_KEY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          q: text,
          target: "en",
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Translation failed");
    }

    const data = await response.json();
    const unFormattedTranslation = data.data.translations[0].translatedText;
    const translation = unFormattedTranslation.replaceAll(
      '<span translate="no">_</span>',
      `
  `
    );
    // Store in cache
    translationCache.set(text, translation);

    return translation;
  } catch (error) {
    throw new Error("Failed to translate text");
  }
};
