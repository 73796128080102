import { PropsWithChildren, useContext } from "react";
import cx from "classnames";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import styles from "./Tooltip.module.scss";
import { radixThemeDataAttributes } from "@/components/Theme";

function Trigger({ children }: PropsWithChildren) {
  return (
    <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
  );
}

function Content({ children }: PropsWithChildren) {
  return (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content
        className={cx(styles.TooltipContent, "radix-themes")}
        sideOffset={5}
        side={"bottom"}
        align={"start"}
        {...radixThemeDataAttributes}
      >
        {children}
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  );
}

function Tooltip({ children }: PropsWithChildren) {
  return <TooltipPrimitive.Root>{children}</TooltipPrimitive.Root>;
}

Tooltip.Trigger = Trigger;
Tooltip.Content = Content;

export default Tooltip;
