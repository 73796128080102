import { useCallback, useState } from "react";
import { FilterProvider } from "../explore/context";
import { ExploreComponent } from "../explore";
import TicketQAScore from "./ticket-qa-score/TicketQAScore";
import { ColumnType } from "../explore/ExploreTable";
import {
  ExploreRow,
  TicketExploreRow,
} from "../../api/useExplore/Explore.model";
import { useExploreNavbar } from "../explore/ExploreNavbar";
import {
  mixTrackEvent,
  InteractionInformationEvents,
} from "../../assets/mixpanel";
import dayjs from "dayjs";
import { PageEmptyState } from "@/components/page-empty-state/PageEmptyState";
import { useOnboarding } from "@/api/onboarding";

const exploreLevel1Table: ColumnType<keyof ExploreRow>[] = [
  {
    label: "Topics",
    key: "name",
    width: "45%",
  },
  {
    label: "Volume",
    key: "avgVolume",
  },
  {
    label: "Trend Pattern",
    key: "trendVolume",
  },
  {
    label: "Agents Evaluated",
    key: "numberOfAgentsEvaluated",
  },
  {
    label: "Avg. QA Score",
    key: "avgQaScore",
  },
];

function EvaluationsEmptyState() {
  return (
    <PageEmptyState
      title={"No evaluations yet"}
      description={
        "Evaluations will appear here once interactions are completed. Check back soon to review and improve performance!"
      }
      svg={"evaluations"}
    />
  );
}

function EvaluationsComp() {
  const { backfillStatus } = useOnboarding();
  const [ticket, setTicket] = useState<TicketExploreRow>();

  const exploreNavbarItems = useExploreNavbar({
    titleOverrides: { teams: "evaluations" },
    staticPrefix: ["quality"],
  });

  const toggleTicket = useCallback((ticket: TicketExploreRow) => {
    setTicket(ticket);
    mixTrackEvent({
      event: InteractionInformationEvents.OPENED_TICKET_SCORE,
      properties: {
        ticketId: ticket.id,
        source: "evaluations",
      },
    });
  }, []);

  const handleClose = useCallback(() => {
    setTicket(undefined);
  }, []);

  return (
    <>
      <ExploreComponent
        finiteState
        navbarOptions={{
          titleOverrides: { teams: "evaluations" },
          staticPrefix: ["quality"],
        }}
        exploreLevel1Table={exploreLevel1Table}
        onTicketClick={toggleTicket}
        hideGroupBy
        emptyStateOverlay={
          backfillStatus !== "completed" && <EvaluationsEmptyState />
        }
      />

      <TicketQAScore
        ticket={ticket}
        navbarItems={exploreNavbarItems}
        onClose={handleClose}
        defaultTabs={{
          main: "evaluation",
          sidePanel: "transcript",
        }}
      />
    </>
  );
}

function Evaluations() {
  return (
    <FilterProvider
      source={"evaluations"}
      defaultValue={{
        dateRange: {
          dateFrom: dayjs().subtract(14, "days").format("YYYY-MM-DD"),
          dateTo: dayjs().format("YYYY-MM-DD"),
        },
        groupBy: "teams",
      }}
    >
      <EvaluationsComp />
    </FilterProvider>
  );
}

export default Evaluations;
