import { params } from "@/params";
import { useAuthorizedMutation, useAuthorizedQuery } from "@/api";
import { FeatureAccessContext } from "@/context/featureAccess";
import { useContext } from "react";

export const quackChatSystemMessageTypes = [
  "WELCOME",
  "CLOSED_CHAT",
  "ESCALATING_TO_LIVE_AGENT",
  "NO_LIVE_AGENTS_AVAILABLE",
  "ESCALATING_TO_TICKET",
  "CONNECTED_TO_LIVE_AGENT",
  "DONE_WAITING_TO_LIVE_AGENT",
  "THANK_YOU_FOR_EMAIL",
  "THANK_YOU_FOR_PATIENCE",
  "UNABLE_TO_ESCALATE",
  "CREATED_TICKET",
  "FILE_UPLOADED",
  "FOLLOW_UP_REMINDER",
] as const;

export type QuackChatSystemMessageType =
  (typeof quackChatSystemMessageTypes)[number];

export const quackChatSystemMessageTypesMap: Record<
  QuackChatSystemMessageType,
  { title: string; description: string }
> = {
  WELCOME: {
    title: "👋 Thread Opening",
    description: "A new conversation starts",
  },
  CLOSED_CHAT: {
    title: "Thread Closing",
    description: "The conversation is ending",
  },
  ESCALATING_TO_LIVE_AGENT: {
    title: "Escalate to Live Agent",
    description: "The issue requires a live agent for resolution",
  },
  NO_LIVE_AGENTS_AVAILABLE: {
    title: "No Live Agents Available",
    description: "No live agents are currently online",
  },
  ESCALATING_TO_TICKET: {
    title: "Escalate to Ticket",
    description:
      "The issue needs to be forwarded to the support team via a ticket",
  },
  CONNECTED_TO_LIVE_AGENT: {
    title: "Connected to Live Agent",
    description: "The user is successfully connected to a live agent",
  },
  DONE_WAITING_TO_LIVE_AGENT: {
    title: "Live Agent Busy",
    description:
      "All agents are currently busy, and the user's request is in queue",
  },
  THANK_YOU_FOR_EMAIL: {
    title: "Email Received",
    description: "The user provided their email address",
  },
  THANK_YOU_FOR_PATIENCE: {
    title: "Patience Acknowledged",
    description: "The system acknowledges the user's patience",
  },
  UNABLE_TO_ESCALATE: {
    title: "Escalation Failed",
    description: "The system is unable to escalate the request",
  },
  CREATED_TICKET: {
    title: "Ticket Created",
    description: "A support ticket has been created for the issue",
  },
  FILE_UPLOADED: {
    title: "File Uploaded",
    description: "The user has uploaded a file",
  },
  FOLLOW_UP_REMINDER: {
    title: "Follow-up Reminder",
    description: "A reminder to follow up with the user",
  },
};

type GetSystemMessagesReuqest = {
  type: QuackChatSystemMessageType;
  tenant: string;
};

type GetSystemMessagesResponse = {
  message: string;
};

export function useGetQuackChatSystemMessage(
  type: QuackChatSystemMessageType = "WELCOME"
) {
  const { selectedWorkspace } = useContext(FeatureAccessContext);

  return useAuthorizedQuery<
    GetSystemMessagesResponse,
    GetSystemMessagesReuqest
  >({
    queryKey: `get-quack-chat-system-message_${type}_${selectedWorkspace}`,
    url: `${params.API_URL}/sessions-api/quackchat/system-messages`,
    method: "POST",
    enabled: type != null,
    body: { type, tenant: selectedWorkspace },
  });
}

type OverrideSystemMessagesReuqest = {
  tenant: string;
  type: QuackChatSystemMessageType;
  message: string;
};

type OverrideSystemMessagesResponse = {
  type: QuackChatSystemMessageType;
  message: string;
};

export function useOverrideQuackChatSystemMessage() {
  return useAuthorizedMutation<
    OverrideSystemMessagesResponse,
    OverrideSystemMessagesReuqest
  >({
    url: `${params.API_URL}/sessions-api/quackchat/override-system-message`,
    method: "PUT",
  });
}
