import { useCallback, useState } from "react";
import { MixerHorizontalIcon } from "@radix-ui/react-icons";
import { Flex, Button, Text, Em } from "@radix-ui/themes";
import { Popover } from "@/components/shared/popover";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import {
  isMessageType,
  TicketTranscriptFilterValue,
} from "@/api/ticket-transcript/TicketTranscript.model";
import styles from "./TicketTranscript.module.scss";

interface TicketTranscriptFilterMenuProps {
  filterValue: TicketTranscriptFilterValue;
  onFilterChange: (newValue: TicketTranscriptFilterValue) => void;
}

export function TicketTranscriptFilterMenu({
  filterValue,
  onFilterChange,
}: TicketTranscriptFilterMenuProps) {
  const [localFilterValue, setLocalFilterValue] =
    useState<TicketTranscriptFilterValue>(filterValue);

  const handleMessageTypeChange = useCallback((newValue: string[]) => {
    if (newValue.length === 0) {
      setLocalFilterValue({});
    } else if (newValue.every(isMessageType)) {
      setLocalFilterValue({ messageType: newValue });
    }
  }, []);

  const handleClearAll = useCallback(() => {
    setLocalFilterValue({});
    onFilterChange({});
  }, [onFilterChange]);

  const handleApply = useCallback(() => {
    onFilterChange(localFilterValue);
  }, [localFilterValue, onFilterChange]);

  return (
    <Popover>
      <Popover.Trigger>
        <Button
          size={"1"}
          variant={"outline"}
          color={"gray"}
          highContrast
          className={styles.FilterButton}
        >
          <MixerHorizontalIcon height={"16"} width={"16"} />
          Filter
        </Button>
      </Popover.Trigger>

      <Popover.Content align="end" side="bottom">
        <Flex
          direction={"column"}
          className={styles.TicketTranscriptFilterMenu}
        >
          <Flex direction={"column"} gap={"1"} className={styles.Section}>
            <Text size={"2"} color={"gray"}>
              <Em>Message Type</Em>
            </Text>

            <Flex>
              <ToggleGroup
                value={localFilterValue.messageType}
                onValueChange={handleMessageTypeChange}
                type="multiple"
                size="sm"
              >
                <ToggleGroupItem value="public" aria-label="Toggle public">
                  Public Messages
                </ToggleGroupItem>

                <ToggleGroupItem value="internal" aria-label="Toggle internal">
                  Internal Messages
                </ToggleGroupItem>
              </ToggleGroup>
            </Flex>
          </Flex>

          <Flex justify={"between"} className={styles.Section}>
            <Popover.Close>
              <Button
                size={"1"}
                variant={"soft"}
                color={"gray"}
                highContrast
                onClick={handleClearAll}
              >
                Clear All
              </Button>
            </Popover.Close>

            <Popover.Close>
              <Button
                size={"1"}
                variant={"solid"}
                color={"gray"}
                highContrast
                onClick={handleApply}
              >
                Apply
              </Button>
            </Popover.Close>
          </Flex>
        </Flex>
      </Popover.Content>
    </Popover>
  );
}
