import { useAuthorizedQuery } from "..";
import { ETimesFilter, getFilteredDate } from "../../assets/types";
import { params } from "../../params";

export const useGetArticleDetails = ({
  articleId,
  enabled,
}: {
  articleId: string;
  enabled: boolean;
}) => {
  return useAuthorizedQuery({
    queryKey: `useGetArticleDetails-${articleId}`,
    url: `${params.API_URL}/knowledge-base-handler/articles/${articleId}`,
    method: "GET",
    enabled,
  });
};

export const useGetArticleOpportunities = ({
  articleId,
  enabled,
  timeFilter,
}: {
  articleId: string;
  enabled: boolean;
  timeFilter?: ETimesFilter;
}) => {
  const statuses = "statuses[]=todo";
  const articleIds = `filterRelatedArticleIds[]=${articleId}`;
  const date = timeFilter ? getFilteredDate(timeFilter) : null;

  return useAuthorizedQuery({
    queryKey: `useGetArticleOpportunities-${articleId}-${statuses}-${timeFilter}`,
    url: `${params.API_URL}/platform/opportunity?${statuses}&${articleIds}${
      date ? `&timeFilter=${date}` : ""
    }`,
    method: "GET",
    enabled,
  });
};

export const useGetArticleOpportunitiesByArticlesIds = ({
  articleIds,
  enabled,
  timeFilter,
}: {
  articleIds: string[];
  enabled: boolean;
  timeFilter?: ETimesFilter;
}) => {
  const statuses = "statuses[]=todo&statuses[]=verify";
  let articleIdsFilter = "";

  articleIds.forEach((id) => {
    articleIdsFilter += `&filterRelatedArticleIds[]=${id}`;
  });

  const date = timeFilter ? getFilteredDate(timeFilter) : null;

  return useAuthorizedQuery({
    queryKey: `useGetArticleOpportunities-${articleIds.join(
      ","
    )}-${statuses}-${timeFilter}`,
    url: `${
      params.API_URL
    }/platform/opportunity?${statuses}${articleIdsFilter}${
      date ? `&timeFilter=${date}` : ""
    }`,
    method: "GET",
    enabled,
  });
};

export const useGetArticleTemplates = ({ enabled }: { enabled: boolean }) => {
  return useAuthorizedQuery({
    queryKey: `useGetArticleTemplates`,
    url: `${params.API_URL}/knowledge-base-handler/article-generation-templates`,
    method: "GET",
    enabled,
  });
};

export const useGetExtendedArticleDetails = ({
  articleId,
  enabled,
}: {
  articleId: string;
  enabled: boolean;
}) => {
  return useAuthorizedQuery({
    queryKey: `useGetArticleExtendedDetails-${articleId}`,
    url: `${params.API_URL}/knowledge-base-handler/knowledge/articles/${articleId}`,
    method: "GET",
    enabled,
  });
};
