import { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import cx from "classnames";
import { Flex, Card, Text, Box, Spinner } from "@radix-ui/themes";
import { SimulatorChannel } from "@/api/simulator/simulator.model";
import { useReleases } from "../simulator/context/release";
import { SimulatorChannelIcon } from "./SimulatorChannelIcon";
import styles from "./SimulatorsEmptyState.module.scss";

interface ChannelCardContent {
  title: string;
  description: string;
}

export const simulatorChannelContentMap: Record<
  SimulatorChannel,
  ChannelCardContent | null
> = {
  autonomous_ticket_event: {
    title: "Tickets",
    description: "Empower your ticket handling",
  },
  helpcenter: {
    title: "Help Center",
    description: "Enhance your help center with QuackChat for seamless support",
  },
  autonomous_quackchat: {
    title: "Live Chat",
    description:
      "Enable real-time communication with your users for instant assistance",
  },
  copilot: {
    title: "Ticketing system AI Assistant",
    description:
      "Seamlessly integrated into your workflows to provide real-time support and insights.",
  },
  slackbot: {
    title: "Slackbot",
    description:
      "Bring AI to your team's conversations—get instant answers and assistance directly in Slack",
  },
};

interface ChannelCardProps {
  channel: SimulatorChannel;
}

function ChannelCard({ channel }: ChannelCardProps) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { handleCreateSimulator } = useReleases();

  const [isLoading, setIsLoading] = useState(false);

  const fParam = searchParams.get("f");
  const path = fParam === "autopilot" ? "/automation" : "/automation-copilot";
  const content = simulatorChannelContentMap[channel];

  const handleOnClick = useCallback(async () => {
    setIsLoading(true);
    const simulator = await handleCreateSimulator(channel);

    setIsLoading(false);
    navigate(`${path}/${simulator.id}`);
  }, [channel, handleCreateSimulator, path, navigate]);

  if (content === null) {
    return <> </>;
  }

  return (
    <Card
      variant={"ghost"}
      onClick={handleOnClick}
      className={cx(styles.ChannelCard, { [styles.loading]: isLoading })}
    >
      <Flex direction={"column"} gap={"3"}>
        {isLoading && (
          <Flex
            justify={"center"}
            align={"center"}
            className={styles.LoadingState}
          >
            <Spinner />
          </Flex>
        )}

        <SimulatorChannelIcon channel={channel} size={"4"} />

        <Flex direction={"column"} gap={"1"}>
          <Text size={"3"} color={"gray"} weight={"medium"} highContrast>
            {content.title}
          </Text>

          <Text size={"3"} color={"gray"}>
            {content.description}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}

export function SimulatorsEmptyState() {
  const [searchParams] = useSearchParams();
  const fParam = searchParams.get("f");

  if (fParam === "autopilot") {
    return (
      <Flex pl={"200px"} pt={"120px"} direction={"column"} gap={"5"}>
        <Flex direction={"column"} gap={"1"}>
          <Text size={"5"} weight={"medium"} highContrast>
            Set up your first AI-Powered response channel
          </Text>
          <Text size={"3"}>Select a channel to start with</Text>
        </Flex>

        <Flex direction={"column"} gap={"4"}>
          <ChannelCard channel={"autonomous_ticket_event"} />
          <ChannelCard channel={"helpcenter"} />
          <ChannelCard channel={"autonomous_quackchat"} />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex pl={"200px"} pt={"120px"} direction={"column"} gap={"5"}>
      <Flex direction={"column"} gap={"1"}>
        <Text size={"5"} weight={"medium"} highContrast>
          Set up Co-Pilot Agent
        </Text>
        <Text size={"3"}>Select a tool to start with</Text>
      </Flex>

      <Flex direction={"column"} gap={"4"}>
        <ChannelCard channel={"copilot"} />
        <ChannelCard channel={"slackbot"} />
      </Flex>
    </Flex>
  );
}
