import { CSSProperties, useContext, useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  matchPath,
} from "react-router-dom";
import cx from "classnames";
import { useFeatureFlag } from "configcat-react";
import { Redirect } from "../redirect";
import logoImg from "../../assets/logo.png";
import {
  ESessionStorage,
  fetchFromSessionStorage,
} from "../../assets/storage/sessionStorage";
import { GeneralEvents, mixTrackEvent } from "../../assets/mixpanel";
import { ENavigate, PagesIcons } from "./sidebar.types";
import * as Collapsible from "@radix-ui/react-collapsible";
import { ThemeContext } from "../Theme";
import { CaretRightIcon } from "@radix-ui/react-icons";
import { sharedReportRouteUri } from "../../routes/sharedReport";
import { useOnboarding } from "../../api/onboarding";
import { automationConfigurationUris } from "@/features/automation-configuration/AutomationConfiguration.config";
import { AppMenu } from "./AppMenu";
import styles from "./styles.module.scss";
import dayjs from "dayjs";
import { Spinner } from "@radix-ui/themes";
import { BackfillStatusAnimation } from "@/routes/onboarding/getting-started/GettingStarted";
import { useWorkspace } from "@/routes/simulator/context/wrokspace";

declare global {
  interface Window {
    quack: any;
  }
}

const unprotected = [
  "/installation-successfull",
  "/welcome",
  "/upskill",
  "/chatbot-sandbox",
  "/playground",
  "/demo",
];

type Page = ENavigate | { root: ENavigate; pages: ENavigate[] };

function isMultiPage(
  page: Page
): page is { root: ENavigate; pages: ENavigate[] } {
  return (
    page != null && page.hasOwnProperty("root") && page.hasOwnProperty("pages")
  );
}

const opportunities: Page[] = [
  // ENavigate.ASSESSMENT,
  {
    root: ENavigate.SIMULATOR,
    pages: [ENavigate.AUTOPILOT, ENavigate.COPILOT],
  },
  {
    root: ENavigate.KNOWLEDGE,
    pages: [ENavigate.KNOWLEDGE_VIEW, ENavigate.KNOWLEDGE_GAPS],
  },
  {
    root: ENavigate.QUALITY,
    pages: [ENavigate.EVALUATIONS, ENavigate.VALIDATIONS],
  },

  ENavigate.SESSIONS_REPORTS,
];

const reports: ENavigate[] = [
  ENavigate.REPORTS_QUACK_HIRING,
  ENavigate.REPORTS_AGENT_PERFORMANCE,
  // ENavigate.REPORTS_PLAYGROUND,
  ENavigate.COPILOT_FEEDBACK,
  ENavigate.REPORTS_TICKETS,
  ENavigate.REPORTS_CONVERSATIONS,
];

const tooltipMapper: { [x: string]: string } = {
  [ENavigate.HOME]: "Dashboard",
  [ENavigate.ASSESSMENT]: "Topics",
  [ENavigate.GETTING_STARTED]: "Getting Started",
  [ENavigate.SIMULATOR]: "Automation",
  [ENavigate.KNOWLEDGE]: "Knowledge management",
  [ENavigate.PERFORMANCE]: "Teams",
  [ENavigate.VOC]: "Categories",
  [ENavigate.CUSTOMERS]: "Customers",
  [ENavigate.SESSIONS_REPORTS]: "Sessions",
  [ENavigate.QUALITY]: "Quality",
  [ENavigate.EVALUATIONS]: "Evaluations",
  [ENavigate.VALIDATIONS]: "Validations",
  [ENavigate.SAMPLING]: "Sampling",
  [ENavigate.COPILOT]: "Co-pilot",
  [ENavigate.AUTOPILOT]: "Auto-pilot",
  [ENavigate.EXPLORE_INSIGHTS]: "Insights",
  [ENavigate.EXPLORE_TICKETS]: "Tickets",
  [ENavigate.EXPLORE_CONVERSATIONS]: "Conversations",
  [ENavigate.KNOWLEDGE_VIEW]: "Articles",
  [ENavigate.KNOWLEDGE_GAPS]: "Knowledge gaps",
  [ENavigate.REPORTS_QUACK_HIRING]: "Quack Hiring",
  [ENavigate.REPORTS_TICKETS]: "Tickets",
  [ENavigate.REPORTS_CONVERSATIONS]: "Conversations",
  [ENavigate.REPORTS_AGENT_PERFORMANCE]: "Agent Performance",
  [ENavigate.COPILOT_FEEDBACK]: "Copilot Feedback",
  // [ENavigate.REPORTS_PLAYGROUND]: "Playground",
};

const hideSidebarPaths = [
  `${automationConfigurationUris.root}/:page`,
  "/copilot/:tenantId/ticket/:ticketId",
];

export const Sidebar = () => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [firstTopic, setFirstTopic] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { workspace } = useWorkspace();

  const onboarding = useOnboarding();

  const { value: supportChat } = useFeatureFlag("supportChat", false, {
    identifier: user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });

  useEffect(() => {
    if (user) {
      if (supportChat) {
        window.quack?.identify(user);
        window.quack?.showChat();
      }
    }
  }, [user, supportChat]);

  const handleExploreClick = (page: ENavigate) => {
    // if its in explore now, just change the by
    // if its not explore that move it to explore
    if (
      location.pathname.startsWith("/explore") &&
      !location.pathname.startsWith("/explore/dashboard")
    ) {
      setSearchParams((prevParams) => {
        const from = dayjs().subtract(14, "days");
        const to = dayjs();

        return new URLSearchParams([
          ["by", page],
          ["from", prevParams.get("from") ?? from.format("YYYY-MM-DD")],
          ["to", prevParams.get("to") ?? to.format("YYYY-MM-DD")],
        ]);
      });
    } else {
      const from = dayjs().subtract(14, "days");
      const to = dayjs();
      navigate(`/explore?by=${page}&from=${from}&to=${to}`);
    }
  };

  const handleClick = (page: ENavigate = ENavigate.HOME) => {
    mixTrackEvent({
      event: GeneralEvents.MOVE_PAGE_FROM_SIDEBAR,
      properties: {
        oldPage: location.pathname.split("/")[1],
        newPage: page,
      },
    });
    switch (page) {
      case ENavigate.ACTIVATION:
        navigate(`/${page}/${firstTopic}`);
        break;
      default:
        navigate(`/${page}`);
        break;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const _firstTopic = fetchFromSessionStorage(ESessionStorage.FIRST_TOPIC);

      if (_firstTopic) {
        setFirstTopic(_firstTopic);
        clearInterval(interval);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const { value: exploreDashboardEnabled } = useFeatureFlag(
    "exploreDashboard",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const { value: exploreConversationEnabled } = useFeatureFlag(
    "exploreConversation",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const { value: isKnowledgeGaps } = useFeatureFlag("isKnowledgeGaps", false, {
    identifier: user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });

  const { value: reportsEnabled } = useFeatureFlag("reports", false, {
    identifier: user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });

  const hide = useMemo(() => {
    return hideSidebarPaths.some((path) => matchPath(path, location.pathname));
  }, [location.pathname]);

  if (
    unprotected.includes(window.location.pathname) ||
    location.pathname.includes(sharedReportRouteUri) ||
    hide
  )
    return null;

  if (isLoading)
    return (
      <div className={styles.container}>
        <div className={styles.loading} />
      </div>
    );

  return (
    <div className={cx(styles.container)}>
      {isAuthenticated ? (
        <>
          <div className={styles.topContainer}>
            <AppMenu />

            <div className={styles.pagesContainer}>
              {!onboarding.isDone && (
                <GettinStartedSidebarPageItem
                  onClick={() => handleClick(ENavigate.GETTING_STARTED)}
                  active={location.pathname.includes(ENavigate.GETTING_STARTED)}
                />
              )}

              <label>Opportunities</label>

              {opportunities.map((page) => {
                if (isMultiPage(page)) {
                  return (
                    <>
                      <Collapsible.Root className="CollapsibleRoot">
                        <Collapsible.Trigger
                          asChild
                          className={styles.collapseTrigger}
                        >
                          <SidebarPageItem
                            key={page.root}
                            page={page.root}
                            onClick={() => {}}
                            active={false}
                            isParent
                          />
                        </Collapsible.Trigger>
                        <Collapsible.Content>
                          {page.pages
                            .filter((p) => {
                              if (isKnowledgeGaps) {
                                return true;
                              } else {
                                if (p === ENavigate.KNOWLEDGE_GAPS) {
                                  return false;
                                }
                                return true;
                              }
                            })
                            .map((subPage) => {
                              const active = subPage.startsWith(
                                ENavigate.SIMULATOR
                              )
                                ? `${location.pathname}${location.search}`.includes(
                                    subPage
                                  )
                                : location.pathname.includes(subPage);

                              return (
                                <>
                                  <div
                                    style={{ height: "2px", width: "40px" }}
                                  ></div>
                                  <SidebarPageItem
                                    key={subPage}
                                    page={subPage}
                                    onClick={() => handleClick(subPage)}
                                    active={active}
                                    indent={1}
                                  />
                                </>
                              );
                            })}
                        </Collapsible.Content>
                      </Collapsible.Root>
                    </>
                  );
                }

                return (
                  <SidebarPageItem
                    key={page}
                    page={page}
                    onClick={() => handleClick(page)}
                    active={location.pathname.includes(page)}
                  />
                );
              })}

              <hr />

              <label>Explore</label>
              {exploreDashboardEnabled && (
                <SidebarPageItem
                  page={ENavigate.EXPLORE_INSIGHTS}
                  active={location.pathname.includes(
                    ENavigate.EXPLORE_INSIGHTS
                  )}
                  onClick={() => handleClick(ENavigate.EXPLORE_INSIGHTS)}
                />
              )}
              <SidebarPageItem
                page={ENavigate.EXPLORE_TICKETS}
                active={location.pathname.includes(ENavigate.EXPLORE_TICKETS)}
                onClick={() => handleClick(ENavigate.EXPLORE_TICKETS)}
              />
              {exploreConversationEnabled && (
                <SidebarPageItem
                  page={ENavigate.EXPLORE_CONVERSATIONS}
                  active={location.pathname.includes(
                    ENavigate.EXPLORE_CONVERSATIONS
                  )}
                  onClick={() => handleClick(ENavigate.EXPLORE_CONVERSATIONS)}
                />
              )}

              {reportsEnabled && onboarding.backfillStatus === "completed" && (
                <>
                  <hr />

                  <label>Reports</label>
                  {reports
                    .filter((x) => {
                      if (x === ENavigate.COPILOT_FEEDBACK) {
                        if (workspace === "fiverr") {
                          return true;
                        }
                        return false;
                      }
                      return true;
                    })
                    .map((page) => (
                      <SidebarPageItem
                        key={page}
                        page={page}
                        onClick={() => handleClick(page)}
                        active={location.pathname.includes(page)}
                      />
                    ))}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.topContainer}>
            <div className={styles.imageContainer}>
              <img
                src={logoImg}
                alt="Quack Logo"
                onClick={() => handleClick()}
              />
            </div>
          </div>
          <Redirect to="/login" />
        </>
      )}
    </div>
  );
};

interface SidebarPageItemProps {
  page: ENavigate;
  active: boolean;
  onClick: () => void;
  indent?: number;
  isParent?: boolean;
}

const SidebarPageItem = ({
  page,
  active,
  onClick,
  indent = 0,
  isParent = false,
}: SidebarPageItemProps) => {
  return (
    <div
      key={page}
      className={cx(styles.pageContainer, {
        [styles.active]: active,
      })}
      onClick={onClick}
      style={
        {
          "--indent": indent,
        } as CSSProperties
      }
    >
      {PagesIcons[page]}
      <div className={styles.label}>
        {tooltipMapper[page]}
        {isParent && <CaretRightIcon className={styles.collapseBtn} />}
      </div>
    </div>
  );
};

function GettinStartedSidebarPageItem({
  onClick,
  active,
}: Pick<SidebarPageItemProps, "onClick" | "active">) {
  const { backfillStatus } = useOnboarding();

  if (backfillStatus === "started") {
    return (
      <div
        key={ENavigate.GETTING_STARTED}
        className={cx(styles.pageContainer, {
          [styles.active]: active,
        })}
        onClick={onClick}
        style={
          {
            "--indent": 0,
            overflow: "hidden",
          } as CSSProperties
        }
      >
        <Spinner />
        <div className={styles.label}>
          <BackfillStatusAnimation />
        </div>
      </div>
    );
  }

  return (
    <SidebarPageItem
      page={ENavigate.GETTING_STARTED}
      onClick={onClick}
      active={active}
    />
  );
}
