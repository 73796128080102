import { DropdownMenu } from "@radix-ui/themes";
import { useCallback, useMemo } from "react";
import { useFilter } from "../context";
import { CheckIcon } from "@radix-ui/react-icons";
import { StringSlicerType } from "../context/FilterContext.model";
import { ExploreValuesResponse } from "../../../api/useExplore/Explore.model";

export const SliceStringDropDown = ({
  k,
  title,
  sliceKey,
  options,
}: {
  k: StringSlicerType;
  title: string;
  sliceKey: string;
  options: ExploreValuesResponse["channels"];
}) => {
  const { stringSliceBy, setStringSlicer, removeStringSlicer } = useFilter();

  const fields = useMemo(() => {
    const slicer = stringSliceBy?.and.find(({ type }) => type === k);

    return slicer?.values.map(({ value }) => value);
  }, [k, stringSliceBy]);

  const handleSlice = useCallback(
    (option: string) => {
      let newFields = [];

      if (fields?.includes(option)) {
        newFields = fields.filter((o) => o !== option);
      } else {
        newFields = [...(fields ?? []), option];
      }

      if (newFields.length > 0) {
        setStringSlicer(k, {
          type: k,
          operation: "or",
          values: newFields.map((field) => ({
            type: k,
            operation: "eq",
            value: field,
          })),
        });
      } else {
        removeStringSlicer(k);
      }
    },
    [fields, removeStringSlicer, setStringSlicer, k]
  );

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
        {title}
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent
        className="DropdownMenuSubContent"
        sideOffset={8}
        alignOffset={-5}
      >
        {options
          .filter((o) => o.label !== "Other")
          .map((option) => {
            return (
              <DropdownMenu.Item
                className="DropdownMenuItem"
                onClick={() => handleSlice(option.value)}
              >
                {option.label}
                {fields?.includes(option.value) && <CheckIcon />}
              </DropdownMenu.Item>
            );
          })}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
