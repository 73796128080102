import { useMemo } from "react";
import cx from "classnames";
import { Flex, Spinner, Text } from "@radix-ui/themes";
import Select, { SelectOption } from "@/components/shared/select";
import { QuackChat } from "@/components/quack-chat/QuackChat";
import { Message } from "@/components/quack-chat/core/entities/Message";
import { useGetQuackchatConfiguration } from "@/api/quackchat/configuration/useQuackchatConfiguration";
import { useQuackchatSessionConfiguration } from "@/api/quackchat/configuration/useQuackchatSessionConfiguration";
import { useGetQuackChatSystemMessage } from "@/api/quackchat/configuration/useQuackChatSystemMessageOverride";
import { AutomationConfigurationContentHeader } from "../parts/AutomationConfigurationContentHeader";
import { FieldGroup, OptionalFieldGroup } from "../parts/FieldGroup";
import styles from "../AutomationConfiguration.module.scss";

const timeOptions: SelectOption[] = [...Array(10)].map((_, i) => {
  return {
    label: `${i + 1} minute${i + 1 > 1 ? "s" : ""}`,
    value: `${(i + 1) * 60}`,
  };
});

export function QuackchatEndOfConversationConfiguration() {
  const quackchatConfigurationQuery = useGetQuackchatConfiguration();

  const {
    configuration,
    isLoading,
    updateStatus,
    updateConfiguration,
    errors,
  } = useQuackchatSessionConfiguration();

  const { data: followupMessage } =
    useGetQuackChatSystemMessage("FOLLOW_UP_REMINDER");

  const { data: closeChatMessage } =
    useGetQuackChatSystemMessage("CLOSED_CHAT");

  const messages = useMemo<Message[]>(() => {
    const messages: Message[] = [];

    if (
      followupMessage != null &&
      configuration?.followUpReminderAfterSeconds > 0
    ) {
      messages.push({
        action: "standard_message",
        author: "AUTO_AGENT",
        text: followupMessage?.message ?? "",
        deduplicationKey: "FOLLOW_UP_REMINDER",
        createdAt: "2021-09-01T12:00:00Z",
        data: {},
      });
    }

    if (closeChatMessage != null) {
      messages.push({
        action: "standard_message",
        author: "AUTO_AGENT",
        text: closeChatMessage?.message ?? "",
        deduplicationKey: "CLOSED_CHAT",
        createdAt: "2021-09-01T12:10:00Z",
        data: {},
      });
    }

    return messages;
  }, [
    followupMessage,
    closeChatMessage,
    configuration?.followUpReminderAfterSeconds,
  ]);

  const foramttedErrors = useMemo(() => {
    if (errors == null) {
      return;
    }

    return Object.fromEntries(
      Object.entries(errors).map(([key, value]) => {
        return [
          key,
          value
            .replaceAll(
              "checkInactivityTimeoutAfterSeconds",
              "Inactivity Timeout"
            )
            .replaceAll("followUpReminderAfterSeconds", "Follow-up Reminder")
            .toLowerCase(),
        ];
      })
    );
  }, [errors]);

  if (
    isLoading ||
    configuration == null ||
    quackchatConfigurationQuery.isLoading ||
    quackchatConfigurationQuery.data == null
  ) {
    return <Spinner />;
  }

  return (
    <Flex gap={"24px"}>
      <Flex flexGrow={"1"} direction={"column"} gap={"24px"}>
        <AutomationConfigurationContentHeader
          title={"End of Conversation"}
          status={updateStatus}
        />

        <Flex direction={"column"}>
          <OptionalFieldGroup
            label={"Collect Customer Feedback"}
            description={"Send a CSAT survey when the conversation ends."}
            enabled={configuration.showCsat}
            toggleEnabled={(nextValue) =>
              updateConfiguration({ showCsat: nextValue })
            }
            error={foramttedErrors?.showCsat}
          />

          <FieldGroup
            label={"Inactivity Timeout"}
            description={
              "If a user doesn't respond within the set time, they will be marked inactive, and the chat will close. Users can always restart a new conversation."
            }
            error={foramttedErrors?.checkInactivityTimeoutAfterSeconds}
          >
            <Flex width={"100%"} align={"center"} gap={"2"}>
              <Text
                size={"2"}
                color={"gray"}
                style={{ flexGrow: 1, maxWidth: 260 }}
              >
                End conversation after
              </Text>

              <Select
                value={configuration.checkInactivityTimeoutAfterSeconds?.toString()}
                onChange={(value) => {
                  updateConfiguration({
                    checkInactivityTimeoutAfterSeconds: parseInt(value),
                  });
                }}
                options={timeOptions}
                classes={{
                  trigger: cx(styles.Select, styles.small),
                  value: styles.SelectValue,
                }}
              />
            </Flex>
          </FieldGroup>

          <OptionalFieldGroup
            label={"Follow-up Reminder"}
            description={
              "Send a message if the user becomes inactive to check if they still need assistance."
            }
            enabled={configuration.followUpReminderAfterSeconds > 0}
            toggleEnabled={(nextValue) =>
              updateConfiguration({
                followUpReminderAfterSeconds: nextValue ? 60 : 0,
              })
            }
            error={foramttedErrors?.followUpReminderAfterSeconds}
          >
            <Flex width={"100%"} align={"center"} gap={"2"}>
              <Text
                size={"2"}
                color={"gray"}
                style={{ flexGrow: 1, maxWidth: 260 }}
              >
                Send after
              </Text>
              <Select
                value={configuration.followUpReminderAfterSeconds?.toString()}
                onChange={(value) => {
                  updateConfiguration({
                    followUpReminderAfterSeconds: parseInt(value),
                  });
                }}
                options={timeOptions}
                classes={{
                  trigger: cx(styles.Select, styles.small),
                  value: styles.SelectValue,
                }}
              />
            </Flex>
          </OptionalFieldGroup>
        </Flex>
      </Flex>

      <Flex flexShrink={"0"}>
        <QuackChat
          config={quackchatConfigurationQuery.data.quackchatConfiguration}
          messages={messages}
          showCSAT={configuration.showCsat}
        />
      </Flex>
    </Flex>
  );
}
