import { useEffect, useState } from "react";
import { Box, TextField, Text, Em } from "@radix-ui/themes";
import styles from "./InlineTextField.module.scss";

interface InlineTextFieldProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

export function InlineTextField({
  value,
  onChange,
  placeholder,
}: InlineTextFieldProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  const handleBlur = () => {
    setIsEditing(false);
    if (localValue !== value) {
      onChange(localValue || "");
    }
  };

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setIsEditing(false);
      if (localValue !== value) {
        onChange(localValue || "");
      }
    }
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const isEmpty = localValue == null || localValue === "";

  return (
    <Box onClick={handleFocus} className={styles.InlineTextFieldContainer}>
      {isEditing ? (
        <TextField.Root
          size="2"
          placeholder={placeholder}
          type="text"
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
          className={styles.InlineTextField}
        />
      ) : (
        <Text
          size={"2"}
          color={"gray"}
          truncate
          highContrast={!isEmpty}
          className={styles.InlineTextFieldValue}
        >
          {!isEmpty ? localValue : <Em>{placeholder}</Em>}
        </Text>
      )}
    </Box>
  );
}
