import { useAuthorizedQuery } from "..";
import { params } from "../../params";

export const useGetAnsweredByAi = (enabled: boolean) => {
  return useAuthorizedQuery({
    url: `${params.API_URL}/platform/tickets/got-answered-by-ai/alltime`,
    queryKey: "answered-by-ai",
    method: "GET",
    enabled,
  });
};
