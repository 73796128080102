import { Badge } from "@radix-ui/themes";
import { Users } from "lucide-react";
import {
  Agent,
  BackAndForth,
  CategoryName,
  Channel,
  FormattedDate,
  FirstMessage,
  JiraTicket,
  KbCoverage,
  QAScore,
  Status,
  TicketOverview,
  TicketValidation,
  Trend,
  TTR,
  Sentiment,
  InternalNotes,
  MessagesCount,
  Collaborators,
} from "./cells";
import styles from "./styles.module.scss";
import {
  ExploreRow,
  TicketExploreRow,
} from "../../api/useExplore/Explore.model";
import {
  ConversationRow,
  ExploreConversationRow,
} from "../../api/useExplore/ExploreConversation.model";
import { ColumnType } from "./ExploreTable";
import { GroupByType } from "./context/FilterContext.model";

export type Views = "topics" | "categories" | "teams" | "customers";

export function getValue<T, K extends keyof T>(obj: T, key: K): T[K] {
  return obj[key];
}

export const EmptyCell = () => <>-</>;

export function exploreCellGenerator(
  values: ExploreRow,
  { key }: ColumnType<keyof ExploreRow>,
  groupBy?: GroupByType
) {
  if (groupBy === "categories" && key === "name") {
    return <CategoryName value={getValue(values, key)} />;
  }

  if (key === "avgBackAndForth") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <BackAndForth value={value} />;
  }

  if (key === "trendVolume" || key === "trendQa") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <Trend value={value} />;
  }

  if (key === "avgTimeToResolve") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <TTR value={value} />;
  }

  if (key === "resolutionRate" || key === "taggedRate") {
    const value = getValue(values, key);
    return value == null ? (
      <EmptyCell />
    ) : (
      <> {value ? `${(value * 100).toFixed(0)}%` : "-"} </>
    );
  }

  if (key === "avgQaScore") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <QAScore value={value} />;
  }

  if (key === "avgSentiment") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <Sentiment value={value} />;
  }

  if (key === "avgMessagesCount") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <BackAndForth value={value} />;
  }

  if (key === "avgInternalNotesCount") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <InternalNotes value={value} />;
  }

  const value = getValue(values, key);
  return <>{value != null ? value : <EmptyCell />}</>;
}

export function ticketExploreCellGenerator(
  values: TicketExploreRow,
  { key }: ColumnType<keyof TicketExploreRow>
) {
  if (key === "overview") {
    return <TicketOverview value={getValue(values, key)} />;
  }

  if (key === "kbCoverage") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <KbCoverage value={value} />;
  }

  if (key === "category") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <CategoryName value={value} />;
  }

  if (key === "status") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <Status value={value} />;
  }

  if (key === "agent") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <Agent value={value} />;
  }

  if (key === "ttr") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <TTR value={value} />;
  }

  if (key === "avgQaScore" || key === "ticketCsat") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <QAScore value={value} />;
  }

  if (key === "collaborators") {
    const value = getValue(values, key);
    return <Collaborators value={value} />;
  }

  if (key === "topic") {
    return (
      <Badge color="gray" size="2">
        {getValue(values, key)}
      </Badge>
    );
  }

  if (key === "channel") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <Channel value={value} />;
  }

  if (key === "team") {
    const value = getValue(values, key);
    return value == null ? (
      <EmptyCell />
    ) : (
      <div className={styles.team}>
        <Users />
        {value}
      </div>
    );
  }

  if (key === "createdAt" || key === "updatedAt") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <FormattedDate value={value} />;
  }

  if (key === "isValidated") {
    const value = getValue(values, key);

    return <TicketValidation value={value} />;
  }

  if (key === "tags") {
    const value = getValue(values, key) as unknown as string[];
    return value.includes("jira_escalated") ? <JiraTicket /> : <EmptyCell />;
  }

  if (key === "avgSentiment") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <Sentiment value={value} />;
  }

  const value = getValue(values, key);
  return <>{value != null ? value : <EmptyCell />}</>;
}

export function ticketExploreCellValueGetter(
  values: TicketExploreRow,
  key: keyof TicketExploreRow
) {
  if (key === "overview") {
    return getValue(values, key).subject?.toLowerCase();
  }

  if (key === "sentimentScores") {
    return;
  }

  if (key === "collaborators") {
    const value = getValue(values, key);
    return value?.length;
  }

  return getValue(values, key);
}

export function exploreConversationCellGenerator(
  values: ExploreConversationRow,
  { key }: ColumnType<keyof ExploreConversationRow>,
  groupBy?: GroupByType
) {
  if (groupBy === "categories" && key === "name") {
    return <CategoryName value={getValue(values, key)} />;
  }

  if (key === "avgMessagesCount") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <MessagesCount value={value} />;
  }

  if (key === "avgCsatScore") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <QAScore value={value} />;
  }

  if (key === "escalationRate") {
    const value = getValue(values, key);
    return value == null ? (
      <EmptyCell />
    ) : (
      <> {value ? `${value.toFixed(0)}%` : "-"} </>
    );
  }

  const value = getValue(values, key);
  return <>{value != null ? value : <EmptyCell />}</>;
}

export function conversationCellGenerator(
  values: ConversationRow,
  { key }: ColumnType<keyof ConversationRow>
) {
  if (key === "topic") {
    return (
      <Badge color="gray" size="2">
        {getValue(values, key)}
      </Badge>
    );
  }

  if (key === "category") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <CategoryName value={value} />;
  }

  if (key === "escalation") {
    return <>-</>;
  }

  if (key === "ttr") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <TTR value={value} />;
  }

  if (key === "backAndForth") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <BackAndForth value={value} />;
  }

  if (key === "messagesCount") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <MessagesCount value={value} />;
  }

  if (key === "channel") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <Channel value={value} />;
  }

  if (key === "firstMessage") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <FirstMessage value={value} />;
  }

  if (key === "createdAt" || key === "updatedAt") {
    const value = getValue(values, key);
    return value == null ? <EmptyCell /> : <FormattedDate value={value} />;
  }

  return <></>;
}

export function conversationCellValueGetter(
  values: ConversationRow,
  key: keyof ConversationRow
) {
  if (key === "escalation" || key === "messages") {
    return;
  }

  return getValue(values, key);
}
