import { channel } from "diagnostics_channel";
import {
  FilterValueParsed,
  NumericSlicer,
  StringSlicer,
} from "../../routes/explore/context/FilterContext.model";
import {
  Conversation,
  ConversationMessage,
  ConversationStatus,
} from "./Conversation.model";
import { ColumnResponse } from "./Explore.model";

export interface ExploreConversationValue {
  avgVolume: number;
  escalationRate: number;
  avgCsatScore: number;
  avgMessagesCount: number;
}

export type ExploreConversationRow = {
  id: number;
  name: string;
} & Partial<ExploreConversationValue>;

export type ExploreConversationRequest = FilterValueParsed;

interface ExploreConversationResponseMetadata
  extends Omit<ExploreConversationRequest, "stringSliceBy" | "numericSliceBy"> {
  tenantId: string;
  numericSliceBy: NumericSlicer[];
  stringSliceBy: StringSlicer[];
  conversationsCount: number;
}

export interface ExploreConversationResponse {
  metadata: ExploreConversationResponseMetadata;
  res: ColumnResponse<{ id: number; name: string }, ExploreConversationValue>[];
}

export function translateColumnsToExploreConversationRows({
  res,
}: ExploreConversationResponse): ExploreConversationRow[] {
  if (!res) return [];

  const rows: Record<
    number,
    {
      id: number;
      name: string;
    } & Partial<ExploreConversationValue>
  > = {};

  const fulfilledColumns = res.filter(({ status }) => status === "fulfilled");

  fulfilledColumns.forEach((column) => {
    const key = column.value.name;

    column.value.data.forEach((data) => {
      const { id, name } = data;

      if (id == null || data[key] == null) {
        return;
      }

      if (rows[id] == null) {
        rows[id] = { id, name, [key]: data[key] };
      } else {
        rows[id] = { ...rows[id], [key]: data[key] };
      }
    });
  });

  return Object.values(rows);
}

export interface ConversationValue {
  ttr: number;
  backAndForth: number;
}

export type ConversationRow = {
  id: string;
  name?: string;
  firstMessage?: string;
  topic?: string;
  category?: string;
  channel?: string;
  createdAt?: string;
  updatedAt?: string;
  escalation?: {
    status?: ConversationStatus;
    ticketExternalId?: string;
  };
  messages?: ConversationMessage[];
  messagesCount?: number;
} & Partial<ConversationValue>;

export type ConversationRequest = FilterValueParsed & {
  includeComments: boolean;
};

interface ConversationResponseMetadata
  extends ExploreConversationResponseMetadata {
  isThereNextPage: boolean;
}

export interface ConversationResponse {
  metadata: ConversationResponseMetadata;
  res: ColumnResponse<{ id?: string }, ConversationValue>[];
  conversationsData?: {
    isThereNextPage: boolean;
    totalCount: number;
    conversations: Conversation[];
  };
}

export function translateColumnsToConversationRows({
  res,
  conversationsData,
}: ConversationResponse): ConversationRow[] {
  if (!res) return [];

  const rowsObj: Record<
    string,
    {
      id: string;
    } & Partial<ConversationValue>
  > = {};

  const fulfilledColumns = res.filter(({ status }) => status === "fulfilled");

  fulfilledColumns.forEach((column) => {
    const key = column.value.name;

    column.value.data.forEach((data) => {
      const id = data.id;

      if (id == null || data[key] == null) {
        return;
      }

      if (rowsObj[id] == null) {
        rowsObj[id] = { id, [key]: data[key] };
      } else {
        rowsObj[id] = { ...rowsObj[id], [key]: data[key] };
      }
    });
  });

  const rows = Object.values(rowsObj);

  const conversationMap = new Map(
    conversationsData?.conversations.map((conversation) => [
      conversation.id,
      conversation,
    ])
  );

  return rows
    .filter(({ id }) => id)
    .map((row) => {
      const conversation = conversationMap.get(row.id);

      const firstMessage =
        conversation?.messages?.reduce<ConversationMessage | null>(
          (earliest, message) => {
            return !earliest ||
              new Date(message.createdAt) < new Date(earliest.createdAt)
              ? message
              : earliest;
          },
          null
        );

      const messagesCount = conversation?.messages?.length;

      return {
        ...row,
        name: undefined,
        agent: undefined,
        topic: conversation?.topic?.topic,
        category: conversation?.category?.name,
        channel: conversation?.channel,
        createdAt: firstMessage?.createdAt,
        updatedAt: undefined,
        escalation: {
          status: conversation?.status,
          ticketExternalId: conversation?.ticketExternalId,
        },
        messages: conversation?.messages,
        firstMessage: firstMessage?.message,
        messagesCount,
      };
    });
}
