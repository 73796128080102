import { useMemo, useState } from "react";
import dayjs from "dayjs";
import { Flex, Avatar, Badge, Text, TextProps, Link } from "@radix-ui/themes";
import { radixColors } from "@/components/radixColors";
import { CommentValue } from "@/api/ticket-transcript/TicketTranscript.model";
import { ImageViewer } from "./ImageViewer";
import { CommentBodyWithBoundary as ExperimentalBody } from "./CommentBody";

function isHtmlString(text: string): boolean {
  const htmlPattern = /<\/?[a-z][\s\S]*>/i;
  return htmlPattern.test(text);
}

function extractUrls(text: string): string | undefined {
  const urlPattern = /https?:\/\/[^\s]+/;
  const match = text.match(urlPattern);

  if (match) {
    return match[0].replace(/[^\w\/]+$/, ""); // Remove trailing non-word characters
  }
}

function getCommentBodyStyles(color: string): TextProps {
  return {
    size: "2",
    color: "gray",
    highContrast: true,
    style: {
      background: `var(--${color}-a3)`,
      padding: "8px",
      borderRadius: "8px",
      wordBreak: "break-word",
      whiteSpace: "break-spaces",
    },
  };
}

interface CommentBodyProps {
  body: string;
  color: (typeof radixColors)[number] | "amber";
}

function CommentBody({ body, color }: CommentBodyProps) {
  if (isHtmlString(body)) {
    console.log("Html body");
    return (
      <Text
        {...getCommentBodyStyles(color)}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    );
  }

  return (
    <Text {...getCommentBodyStyles(color)}>
      {body
        .trim()
        .split(/(https?:\/\/[^\s]+)/g)
        .map((part, index) => {
          const cleanedUrls = extractUrls(part) ?? "";

          if (part.match(/https?:\/\/[^\s]+/)) {
            if (cleanedUrls.match(/\.(jpeg|jpg|gif|png)$/)) {
              return <ImageViewer key={index} url={cleanedUrls} />;
            }

            return (
              <Link
                key={index}
                color="indigo"
                underline={"hover"}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
              >
                {part}
              </Link>
            );
          }

          return (
            <span key={index} dangerouslySetInnerHTML={{ __html: part }} />
          );
        })}
    </Text>
  );
}

interface CommentProps extends CommentValue {
  authorName: string;
  color: (typeof radixColors)[number];
  isFirst?: boolean;
  hideHeader?: boolean;
  hasConscutiveComment?: boolean;
}

export function Comment({
  authorName,
  updateAt,
  body,
  isInternal,
  isFirst = false,
  hideHeader = false,
  hasConscutiveComment = false,
  ...props
}: CommentProps) {
  const color = isInternal ? "amber" : props.color;

  const agentInitials = useMemo(() => {
    const words = authorName
      .replaceAll("Unknown ", "")
      .split(" ")
      .map((x) => x[0]);

    if (words.length === 1) {
      return words[0];
    }

    return [words[0], words[words.length - 1]].join("");
  }, [authorName]);

  return (
    <Flex
      pt={isFirst ? "3" : undefined}
      pb={hasConscutiveComment ? undefined : "6"}
      direction={"column"}
      gap={"1"}
    >
      {!hideHeader && (
        <Flex p={"2"} justify={"between"} gap={"2"} align={"center"}>
          <Flex gap={"2"} align={"center"}>
            <Avatar
              size={"1"}
              fallback={agentInitials}
              color={color}
              radius={"full"}
            />

            <Text
              size={"2"}
              color={"gray"}
              weight={"medium"}
              highContrast
              truncate
            >
              {authorName ?? "Unknown Author"}
            </Text>

            <Text size={"1"} color={"gray"} style={{ flexShrink: 0 }}>
              {dayjs(updateAt).format("MMM D HH:mm")}
            </Text>
          </Flex>

          {isInternal && (
            <Badge size={"2"} variant={"surface"} color={color} highContrast>
              Internal
            </Badge>
          )}
        </Flex>
      )}

      <Flex pt={hideHeader ? "1" : undefined} pl={"6"} pr={"4"}>
        {/* <CommentBody color={color} body={body} /> */}
        <ExperimentalBody color={color} body={body} />

        {/* <Text
          size={"2"}
          color={"gray"}
          highContrast
          style={{
            background: `var(--${color}-a3)`,
            padding: "8px",
            borderRadius: "8px",
            wordBreak: "break-word",
          }}
        >
          {body}
        </Text> */}
      </Flex>
    </Flex>
  );
}
