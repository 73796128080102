import React, { useRef, useState } from "react";
import { Dialog, Button as RadixButton, Switch, Tooltip } from "@radix-ui/themes";
import { Message } from "ai";
import confetti from "canvas-confetti";
import { translateText } from "../../services/translation";
import { GlobeIcon } from "@radix-ui/react-icons";
import toast from "react-hot-toast";

import { Converter } from "showdown";

import { X } from "@geist-ui/icons";
import {
  Timeline,
  TimelineContent,
  TimelineDot,
  TimelineHeading,
  TimelineItem,
  TimelineLine,
} from "@/components/ui/timeline";
import styles from "./styles.module.scss";

const processMapper = {
  reading_message: "Going over your message",
  looking_for_sources: "Looking for relevant sources in the help center",
  reviewing_relevant_sources: "Reviewing relevant sources that I found",
  looking_for_the_answer: "Searching the correct answer",
  drafting_response: "Drafting initial Response",
  typing: "Typing...",
  none: "",
};

export const MessageComponent = ({
  m,
  clearChat,
  handleGoodFeedback,
}: {
  m: Message;
  clearChat: () => void;
  handleGoodFeedback: () => void;
}) => {
  const [isHover, setHover] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [translatedContent, setTranslatedContent] = useState<string | null>(null);

  const convertor = new Converter({
    simplifiedAutoLink: true,
    excludeTrailingPunctuationFromURLs: true,
    openLinksInNewWindow: true,
    smoothLivePreview: true,
    disableForced4SpacesIndentedSublists: true,
  });

  const convertMarkdownToHTML = (markdown: string) => {
    if (markdown) {
      // return markdown;
      return convertor?.makeHtml(markdown);
    } else {
      return "";
    }
  };

  const annotations = m.annotations?.[0] ?? m.annotations;

  const isRtl =
    ((annotations as any[])?.[(annotations as any[])?.length - 1] as any)
      ?.isRtl ?? false;

  const chosenTool =
    m.toolInvocations && m.toolInvocations?.length > 0
      ? m.toolInvocations[0].state === "result"
        ? m.toolInvocations[0].result.action === "talk_to_an_agent"
          ? "It's just a playground, but in real life I'd pass this conversation to an agent based on the escalation policy that you will set."
          : m.toolInvocations[0].result.action === "skip_it"
          ? "-- Im skipping this conversation due to low confidence -- you'd be able to train me and adjust the confidence level once we start working together."
          : null
        : null
      : null;

  return (
    <>
      {(m.content || chosenTool) && (
        <div
          className={`w-full flex ${m.role === "user" ? " justify-end" : " "}`}
        >
          <div
            className={`
         p-2 rounded-sm relative min-w-12 w-fit text-sm
        ${
          m.role === "user" ? ` bg-black text-white` : " bg-gray-200 text-black"
        }`}

            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {m.toolInvocations && m.toolInvocations?.length > 0 ? (
              <>{chosenTool}</>
            ) : (
              <div
                className={styles.message}
                dir={isRtl ? "rtl" : "ltr"}
                dangerouslySetInnerHTML={{
                  __html: convertMarkdownToHTML(m.content),
                }}
              />

            )}

            {m.role !== "user" &&
              isHover &&
              m.id !== "intro" &&
              (annotations as any[])?.length > 2 && (
                <div className=" absolute left-2 h-16 -bottom-6 items-end flex gap-4">
                  <RadixButton
                    variant="ghost"
                    size="1"
                    color="gray"
                    highContrast
                    onClick={() => setOpen(true)}
                  >
                    How I got to that ☝️
                  </RadixButton>
                  <RadixButton
                    variant="ghost"
                    size="1"
                    color="gray"
                    highContrast
                    onClick={() => {
                      confetti({
                        particleCount: 100,
                        spread: 100,
                        origin: { y: 0.8, x: 0.6 },
                      });
                      handleGoodFeedback();
                      setTimeout(() => {
                        clearChat();
                      }, 300);
                    }}
                  >
                    Great response 👍
                  </RadixButton>
                </div>
              )}
          </div>
        </div>
      )}

      <Dialog.Root open={isOpen}>
        <Dialog.Content>
          <RadixButton
            className=" absolute top-2 right-4 p-1 w-5 h-5"
            onClick={() => setOpen(false)}
            color="gray"
            variant="ghost"
            highContrast
          >
            <X />
          </RadixButton>
          <Timeline>
            {(annotations as any[])
              ?.filter((l) => l.step !== "typing")
              ?.map((annotation) => {
                return (
                  <TimelineItem
                    status="done"
                    className="before:content-[''] list-none"
                  >
                    <TimelineHeading>
                      {
                        processMapper[
                          annotation.step as keyof typeof processMapper
                        ]
                      }
                    </TimelineHeading>
                    <TimelineDot status="done" />
                    <TimelineLine done />
                    <TimelineContent className=" whitespace-break-spaces">
                      {annotation.description}
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
          </Timeline>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
