import { params } from "../../params";
import { useAuthorizedMutation, useAuthorizedQuery } from "..";
import { Ticket } from "../useExplore/Ticket.model";

interface Question {
  id: number;
  tenantId: string;
  createdAt: string;
  updatedAt: string;
  key: string;
  title: string;
  content: string;
  type: string;
  sampleFormId: number;
  sampleConfigurationId: number;
}

interface SampleForm {
  questions: Question[];
}

interface SampleConfiguration {
  id: number;
  tenantId: string;
  createdAt: string;
  updatedAt: string;
  dateFrom: string;
  dateTo: string;
  nextRun: string;
  isEnabled: boolean;
  stringSliceCommand: object;
  numericSliceCommand: object;
  daysUntilNextRun: number;
  numberOfTickets: number;
  sampleFormId: number;
  agentGroupId: number | null;
  sampleForm: SampleForm;
}

export interface TicketScore {
  id: number;
  title: string;
  key: string;
  content: string;
  tenantId: string;
  ticketId: string;
  createdAt: string;
  updateAt: string;
  autoScore: number;
  adjustedScore: number;
  autoScoreExplanation: string;
  reasonForNegativeScore: string | null;
  reasonsForNegativeScore: any[];
  comment: string | null;
}

interface TicketEntry {
  id: number;
  tenantId: string;
  sampleId: number;
  ticketId: string;
  ticket: Ticket;
}

export interface Sample {
  id: number;
  name: string;
  tenantId: string;
  createdAt: string;
  dateFrom: string;
  dateTo: string;
  sampleConfigurationId: number;
  sampleConfiguration: SampleConfiguration;
  tickets: TicketEntry[];
}

export type SamplesResponse = Sample[];

export function useValidationSetSample(sampleId: string) {
  return useAuthorizedQuery<Sample>({
    queryKey: `useValidationSetSample-${sampleId}`,
    url: `${params.API_URL}/platform/sampling/samples/${sampleId}`,
    method: "GET",
    enabled: sampleId != null,
  });
}

export function useValidationSets() {
  return useAuthorizedQuery<SamplesResponse>({
    queryKey: `useValidationSets`,
    url: `${params.API_URL}/platform/sampling/samples`,
    method: "GET",
    enabled: true,
  });
}

const validationSetsOptions = [
  "random",
  "time-to-resolution",
  "sentiment",
  "back-and-forth",
] as const;
export type ValidationSetsOption = (typeof validationSetsOptions)[number];

export const validationSetsOptionContent: Record<
  ValidationSetsOption,
  { title: string; description: string }
> = {
  random: {
    title: "Random",
    description: "All Tickets",
  },
  "time-to-resolution": {
    title: "High TTR",
    description: "TTR is higher than 4 days",
  },
  sentiment: {
    title: "Negative sentiment",
    description: "Negative sentiment detected",
  },
  "back-and-forth": {
    title: "High B&F",
    description: "B&F is higher than 5",
  },
};

export function useValidationSetsOptions() {
  return useAuthorizedQuery<ValidationSetsOption[]>({
    queryKey: `useValidationSetsOptions`,
    url: `${params.API_URL}/platform/sampling/validation-sets-options`,
    method: "GET",
    enabled: true,
  });
}

export function useAddValidationSet() {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/sampling/sample-configuration`,
    method: "POST",
  });
}

export interface ValidateTicketRequest {
  ticketId: string;
  isValidated: boolean;
}

export function useValidateTicket() {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/ticket-validation`,
    method: "PUT",
  });
}
