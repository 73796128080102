import { useState } from "react";
import { FaArrowUp } from "react-icons/fa";

export const Question = ({
  question,
  index,
  onSubmit,
}: {
  question: string;
  index: number;
  onSubmit: (e: string) => void;
}) => {
  const [isHover, setHover] = useState(false);

  return (
    <div
      className="  bg-black/5 rounded-xl  px-4 py-3 flex items-center justify-between flex-grow h-full cursor-pointer hover:bg-gray-100"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className=" flex flex-col gap-1 text-gray-900">
        <span className=" text-xs text-black"> Example question #{index}</span>
        <span className=" text-sm">{question}</span>
      </div>

      {isHover ? (
        <button
          className=" p-2 bg-gray-300 w-fit h-fit rounded-md text-white hover:bg-gray-400"
          onClick={() => {
            onSubmit(question);
          }}
        >
          <FaArrowUp />
        </button>
      ) : (
        <div className=" p-2 text-transparent">__</div>
      )}
    </div>
  );
};
