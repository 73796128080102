import { PropsWithChildren, useContext } from "react";
import cx from "classnames";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { IconButton, IconButtonProps, Spinner } from "@radix-ui/themes";
import { Cross2Icon } from "@radix-ui/react-icons";
import styles from "./Dialog.module.scss";
import { radixThemeDataAttributes } from "@/components/Theme";

function DialogTitle({ children }: PropsWithChildren) {
  return (
    <DialogPrimitive.Title className={styles.Title}>
      {children}
    </DialogPrimitive.Title>
  );
}

function DialogClose({
  className,
  ...iconButtonProps
}: { className?: string } & IconButtonProps) {
  return (
    <DialogPrimitive.Close aria-label="Close" asChild className={className}>
      <IconButton
        color="gray"
        variant="ghost"
        highContrast
        size="1"
        {...iconButtonProps}
      >
        <Cross2Icon />
      </IconButton>
    </DialogPrimitive.Close>
  );
}

function DialogTrigger({ children }: PropsWithChildren) {
  return <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>;
}

interface DialogContentWrapperProps {
  isPortal?: boolean;
}

function DialogContentWrapper({
  children,
  isPortal = true,
}: PropsWithChildren<DialogContentWrapperProps>) {
  if (isPortal) {
    return <DialogPrimitive.Portal>{children}</DialogPrimitive.Portal>;
  }

  return <>{children}</>;
}

interface DialogContentProps extends DialogContentWrapperProps {
  size?: "small" | "medium" | "large";
  noRadius?: boolean;
}

function DialogContent({
  children,
  isPortal,
  size = "medium",
  noRadius = false,
}: PropsWithChildren<DialogContentProps>) {
  return (
    <DialogContentWrapper isPortal={isPortal}>
      <DialogPrimitive.Overlay
        className={cx(styles.DialogOverlay, "radix-themes")}
        {...radixThemeDataAttributes}
      />

      <DialogPrimitive.Content
        className={cx(styles.DialogContent, "radix-themes", styles[size], {
          [styles.noRadius]: noRadius,
        })}
        {...radixThemeDataAttributes}
      >
        {children}
      </DialogPrimitive.Content>
    </DialogContentWrapper>
  );
}

function DialogLoader() {
  return (
    <DialogContentWrapper>
      <DialogPrimitive.Overlay
        className={cx(styles.DialogOverlay, "radix-themes")}
        {...radixThemeDataAttributes}
      />

      <DialogPrimitive.Content
        className={cx(styles.DialogLoader, "radix-themes")}
        {...radixThemeDataAttributes}
      >
        <Spinner size="3" className={styles.DialogLoaderSpinner} />
      </DialogPrimitive.Content>
    </DialogContentWrapper>
  );
}

interface DialogProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  loading?: boolean;
}

function Dialog({
  children,
  loading,
  ...props
}: PropsWithChildren<DialogProps>) {
  return (
    <DialogPrimitive.Root {...props}>
      {loading ? <DialogLoader /> : children}
    </DialogPrimitive.Root>
  );
}

Dialog.Title = DialogTitle;
Dialog.Close = DialogClose;
Dialog.Trigger = DialogTrigger;
Dialog.Content = DialogContent;

export default Dialog;
