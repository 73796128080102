const releaseStatuses = ["draft", "published"] as const;
export type ReleaseStatus = (typeof releaseStatuses)[number];

const releasePolicyItemTypes = ["general"] as const;
type ReleasePolicyItemType = (typeof releasePolicyItemTypes)[number];

interface BaseReleasePolicyItem {
  type: ReleasePolicyItemType;
  topic: string;
  subTopic: string;
  content: string | number | boolean;
}

const generalTopics = [
  "includeTicketSystem",
  "includeKnowledgeBase",
  "includeLink",
  "toneOfVoice",
  "openingLine",
  "closingLine",
  "maxBackAndForth",
] as const;
type GeneralTopic = (typeof generalTopics)[number];

interface GeneralReleasePolicyItem extends BaseReleasePolicyItem {
  type: "general";
  topic: GeneralTopic;
}

export type ReleasePolicyItem = GeneralReleasePolicyItem;

export function isReleasePolicyItem(obj: any): obj is ReleasePolicyItem {
  return (
    obj != null &&
    obj.hasOwnProperty("type") &&
    obj.hasOwnProperty("topic") &&
    obj.hasOwnProperty("subTopic")
  );
}

export interface Release {
  id: number;
  releasePolicy: ReleasePolicyItem[];
  tenantId: string;
  status: ReleaseStatus;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date | null;
  simulatorId: number;
}

const simulatorChannels = [
  "helpcenter",
  "autonomous_ticket_event",
  "autonomous_quackchat",
  "slackbot",
  "copilot",
] as const;

export type SimulatorChannel = (typeof simulatorChannels)[number];

export const simulatorChannelToLabel: Record<SimulatorChannel, string> = {
  helpcenter: "Help Center",
  autonomous_ticket_event: "Tickets",
  autonomous_quackchat: "Live Chat",
  slackbot: "Slackbot",
  copilot: "Ticketing system AI Assistant",
};

export interface Simulator {
  id: number;
  tenantId: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  publishedReleaseId: number | null;
  channel: SimulatorChannel;
  shouldCloseTicket: boolean;
  releases: Release[];
}
