import React, { createContext, useContext, useEffect, useState } from "react";
import { Instruction } from "../types";
import { useReleases } from "./release";
import { useHandleInstructify } from "../chat/api";

interface InstructionsContextType {
  instructions: Instruction[];
  setInstructions: React.Dispatch<React.SetStateAction<Instruction[]>>;
  simulatorInstructions: Instruction[];
  setSimulatorInstructions: React.Dispatch<React.SetStateAction<Instruction[]>>;
}

const InstructionsContext = createContext<InstructionsContextType | undefined>(
  undefined
);

export const useInstructions = (): {
  instructions: Instruction[];
  simulatorInstructions: Instruction[];
  generalInstructions: Instruction[];
  briefs: Instruction[];
  manualInstructions: Instruction[];
  deleteInstruction: (instruction: Instruction, index: number) => void;
  handleInstruction: (instruction: Instruction) => void;
  handleInstructionsOptions: (instruction: string) => Promise<string[]>;
  handleGeneralInstruction: (instruction: Instruction[]) => void;
  handleSimulatorInstructions: (instruction: Instruction[]) => void;
  handleBrief: (
    brief: string,
    topic: string,
    knowledgeChecked: boolean,
    historicalTicketsChecked: boolean
  ) => void;
} => {
  const { handleUpdateReleaseInstructions } = useReleases();
  const context = useContext(InstructionsContext);
  if (!context) {
    throw new Error(
      "useInstructions must be used within an InstructionsProvider"
    );
  }

  const handleGeneralInstruction = async (i: any) => {
    const newInstructions = [
      ...context.instructions.filter((x) => x.type === "manual"),
      ...i,
    ];

    handleUpdateReleaseInstructions(newInstructions);
    context.setInstructions(newInstructions);
  };

  const { mutateAsync: handleInstructify } = useHandleInstructify();

  const handleInstructionsOptions = async (instruction: string) => {
    const data = await handleInstructify({
      instruction: {
        content: instruction,
      },
    });

    return data.options;
  };

  const handleBrief = (
    brief: string,
    topicName: string,
    includeKnowledge: boolean,
    includeTickets: boolean
  ) => {
    const newInstructions = context.instructions.filter(
      (x) => x.type !== "brief" || x.topic !== topicName
    );

    const newBriefInstructions: Instruction[] = [
      {
        type: "brief",
        content: brief,
        subTopic: null,
        topic: topicName,
      },
      {
        type: "brief",
        content: String(includeKnowledge),
        subTopic: "knowledge",
        topic: topicName,
      },
      {
        type: "brief",
        content: String(includeTickets),
        subTopic: "tickets",
        topic: topicName,
      },
    ];

    const updatedInstructions = [...newInstructions, ...newBriefInstructions];

    handleUpdateReleaseInstructions(updatedInstructions);
    context.setInstructions(updatedInstructions);
  };

  const handleInstruction = async (instruction: Instruction) => {
    const newInstructions = [...context.instructions, { ...instruction }];

    handleUpdateReleaseInstructions(newInstructions);
    context.setInstructions(newInstructions);
  };

  const deleteInstruction = (instruction: Instruction, index: number) => {
    const newInstructions = context.instructions.filter(
      (inst) => inst.content !== instruction.content
    );
    handleUpdateReleaseInstructions(newInstructions);
    context.setInstructions(newInstructions);
  };

  return {
    instructions: context.instructions,
    generalInstructions: context.instructions.filter(
      (i) => i.type === "general"
    ),
    briefs: context.instructions.filter((i) => i.type === "brief"),
    manualInstructions: context.instructions.filter((i) => i.type === "manual"),
    deleteInstruction,
    handleInstruction,
    handleGeneralInstruction,
    handleInstructionsOptions,
    simulatorInstructions: context.simulatorInstructions,
    handleSimulatorInstructions: (instruct) =>
      context.setSimulatorInstructions(instruct),
    handleBrief,
  };
};

export const InstructionsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { relevantRelease } = useReleases();

  const [instructions, setInstructions] = useState<Instruction[]>([]);
  const [simulatorInstructions, setSimulatorInstructions] = useState<
    Instruction[]
  >([]);

  useEffect(() => {
    if (relevantRelease && relevantRelease?.releasePolicy) {
      const policy =
        typeof relevantRelease.releasePolicy === "string"
          ? JSON.parse(relevantRelease.releasePolicy)
          : relevantRelease.releasePolicy;
      if (JSON.stringify(policy) !== JSON.stringify(instructions)) {
        setInstructions([...policy]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relevantRelease]);

  return (
    <InstructionsContext.Provider
      value={{
        instructions: instructions,
        setInstructions,
        simulatorInstructions,
        setSimulatorInstructions,
      }}
    >
      {children}
    </InstructionsContext.Provider>
  );
};
