import { Button as RadixButton } from "@radix-ui/themes";
import { AIInputWithLoading } from "@/components/ui/ai-input-with-loading";
import { RainbowButton } from "@/components/ui/rainbow-button";
import { Tag, TagList } from "@/components/ui/tag-group";
import { TagGroup } from "@/components/ui/tag-group";
import { Typewriter } from "@/components/ui/typewriter";
import { useChat } from "ai/react";

const capitalizeString = (str: string): string => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

import React, { useEffect, useRef, useState } from "react";
import { MessageComponent } from "./Message";
import { params } from "@/params";
import { MessageLoading } from "@/components/ui/loading";
import { Question } from "./Question";
import { TimelineContent, TimelineItem } from "@/components/ui/timeline";
import {
  TimelineDot,
  TimelineHeading,
  TimelineLine,
} from "@/components/ui/timeline";

const processMapper = {
  reading_message: "Going over your message",
  looking_for_sources: "Looking for relevant sources in the help center",
  potential_questions: "Searching",
  reviewing_relevant_sources: "Reviewing relevant sources that I found",
  looking_for_the_answer: "Searching the correct answer",
  drafting_response: "Drafting initial Response",
  typing: "Typing...",
  done: "Finished and sent response",
  none: "",
};

export const Playground = () => {
  const [step, setStep] = useState<{ step: string; metadata: any } | null>(
    null
  );
  const [steps, setSteps] = useState<any[]>([]);
  const [isTyping, setTyping] = useState(false);
  const [chatId, setChatId] = useState(String(new Date().valueOf()));
  const searchParams = new URLSearchParams(window.location.search);
  const tenant = searchParams.get("t");
  const ticketingSystem = searchParams.get("ts");
  const helpCenter = searchParams.get("hc");
  const feedbackKey = `quack-${tenant}-positiveFeedback`;
  const feedbackCount = Number(localStorage.getItem(feedbackKey) || 0);
  const [questions, setQuestions] = useState([]);

  const [tone, setTone] = useState("casual");
  const [length, setLength] = useState("chat");

  const bottomRef = useRef<HTMLDivElement>(null);
  const bottomStepsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(`${params.AI_URL}/api/questions`, {
          method: "POST",
          body: JSON.stringify({ tenant: tenant }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch questions");
        }
        const questions = await response.json();

        setQuestions(questions);
        // You can set the questions to a state if needed
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, []);

  const { messages, isLoading, setMessages, append, data, reload } = useChat({
    id: chatId,
    api: `${params.AI_URL}/api/playground`,
    body: {
      tenantId: tenant,
      options: {
        channel: length,
        tone: tone,
      },
      isDemo: window.location.href.includes("demo"),
    },
    onFinish: () => {
      setStep(null);
    },
  });

  useEffect(() => {
    bottomStepsRef.current?.scrollIntoView();
  }, [steps.length]);

  useEffect(() => {
    if (messages.length > 1) {
      reload();
    }
  }, [tone, length]);

  useEffect(() => {
    if (data?.length && data[data.length - 1]) {
      setStep((data as any)[data.length - 1]);
      setSteps((prev) => [...prev, (data as any)[data.length - 1]]);

      if ((data as any)[data.length - 1].step === "typing") {
        setTyping(false);
      } else {
        setTyping(true);
      }
    }
  }, [data]);

  useEffect(() => {
    setSteps([]);
  }, [chatId]);

  useEffect(() => {
    setTimeout(() => {
      setMessages((prev) => {
        return [
          {
            id: "intro",
            content:
              feedbackCount < 6
                ? `Hey, I'm Quack. I'm really excited about the opportunity to work together! I'm kind of new to ${capitalizeString(
                    tenant ?? ""
                  )} ${
                    feedbackCount > 0
                      ? `although I already got ${feedbackCount} good ${
                          feedbackCount === 1 ? "answer" : "answers"
                        } `
                      : ""
                  }, so it might take me a bit longer than usual to find the best answer, but let's give it a try. `
                : `Hey again, seems that we on track, can't wait to start working together officaly, until than keep testing me`,
            role: "assistant",
          },
        ];
      });
    }, 300);
  }, [chatId]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView();
  }, [messages]);

  useEffect(() => {
    if (messages.length % 2 === 0) {
      setSteps([]);
    }
  }, [messages.length]);

  const handleGoodFeedback = () => {
    const feedbackKey = `quack-${tenant}-positiveFeedback`;
    const feedbackCount = Number(localStorage.getItem(feedbackKey) || 0);
    localStorage.setItem(feedbackKey, (feedbackCount + 1).toString());
  };

  return (
    <div className="flex flex-col grow">
      <header className="flex justify-between p-4 border-b items-center">
        <label>
          <b>Hey {capitalizeString(tenant ?? "")} team 👋</b>, give me a try and
          let me amaze you.
        </label>

        <RainbowButton
          onClick={(e) => {
            window.location.href = `/upskill?tenant=${tenant}${
              ticketingSystem ? `&t=${ticketingSystem}` : ""
            }${helpCenter ? `&kb=${helpCenter}` : ""}`;
          }}
        >
          Upskill Quack 10x
        </RainbowButton>
      </header>
      <section className=" flex flex-grow h-2">
        <div className=" border-r p-2 gap-6 flex flex-col ">
          <div className="flex justify-between items-center">
            <h4 className=" text-lg">Adjustments panel</h4>
            {messages.length > 1 && (
              <RadixButton
                className="  mr-1"
                variant="ghost"
                size="2"
                highContrast
                color="gray"
                onClick={() => setChatId(String(new Date().valueOf()))}
              >
                Reset session
              </RadixButton>
            )}
          </div>

          <TagGroup
            className="space-y-1"
            selectionMode="single"
            selectedKeys={[tone]}
            onSelectionChange={(v) => setTone((v as any).currentKey)}
          >
            <label className=" text-sm mb-2">Tone of voice</label>
            <TagList>
              <Tag id="formal">Formal</Tag>
              <Tag id="casual">Casual</Tag>
              <Tag id="enthusiastic">Enthusiastic</Tag>
              <Tag id="professional">Professional </Tag>
            </TagList>
          </TagGroup>

          <TagGroup
            className="space-y-1"
            selectionMode="single"
            selectedKeys={[length]}
            onSelectionChange={(v) => setLength((v as any).currentKey)}
          >
            <label className="text-sm mb-2">Channel</label>
            <TagList>
              <Tag id="chat">Chat</Tag>
              <Tag id="email">Email</Tag>
            </TagList>
          </TagGroup>
        </div>
        <div className="flex grow">
          <div className=" flex flex-grow flex-col pt-3 border-r justify-center items-center max-w-2xl">
            <div className=" max-w-lg flex-grow flex flex-col w-full">
              <div
                id="messages"
                className="flex flex-col flex-grow gap-2 overflow-y-scroll h-3"
              >
                {messages.map((m) => {
                  return (
                    <MessageComponent
                      m={m}
                      key={m.content}
                      clearChat={() => setChatId(String(new Date().valueOf()))}
                      handleGoodFeedback={handleGoodFeedback}
                    />
                  );
                })}
                {isTyping && <MessageLoading />}
                <div className="h-10 min-h-4 mt-4" ref={bottomRef}></div>
              </div>
              {messages.length == 1 && (
                <div className="  text-gray-300 flex  gap-3 justify-around">
                  <div className="w-1/2  flex gap-3 flex-col">
                    {questions.slice(0, 2).map((question, index) => {
                      return (
                        <Question
                          key={question}
                          question={question}
                          index={index + 1}
                          onSubmit={(q) => append({ content: q, role: "user" })}
                        />
                      );
                    })}
                  </div>
                  <div className=" w-1/2  flex gap-3 flex-col">
                    {questions.slice(2, 4).map((question, index) => {
                      return (
                        <Question
                          key={question}
                          question={question}
                          index={index + 3}
                          onSubmit={(q) => append({ content: q, role: "user" })}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            <AIInputWithLoading
              onSubmit={(value) => {
                append({ content: value, role: "user" });
              }}
              isLoading={isLoading}
              loadingDuration={3000}
              thinkingDuration={2000}
              placeholder="Ask me a question"
              step={
                processMapper?.[
                  (step?.step as keyof typeof processMapper) ?? "none"
                ] ?? ""
              }
              stepMetadata={step?.metadata}
              messages={messages.length}
            />
          </div>
          <div className="flex flex-col items-baseline overflow-y-scroll grow h-full max-w-96 p-3">
            {(steps as any[])
              ?.filter((l) => l.step !== "typing")
              ?.map((annotation, i) => {
                return (
                  <TimelineItem
                    status="default"
                    className="before:content-[''] list-none"
                  >
                    <TimelineHeading>
                      {
                        processMapper[
                          annotation.step as keyof typeof processMapper
                        ]
                      }
                    </TimelineHeading>
                    <TimelineDot
                      status={
                        i ==
                        (steps as any[])?.filter((l) => l.step !== "typing")
                          .length -
                          1
                          ? "current"
                          : "done"
                      }
                    />
                    <TimelineLine done />
                    <TimelineContent className=" whitespace-break-spaces text-xs">
                      {annotation.description}
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            <div ref={bottomStepsRef} />
          </div>
        </div>
      </section>
      <footer className=" flex  justify-between w-full p-4 text-sm border-t">
        <div className=" md:text-4xl lg:text-5xl sm:text-3xl text-2xl flex flex-row items-start justify-start bg-background font-normal overflow-hidden ">
          <p className="whitespace-pre-wrap">
            <span>{"Quack AI revolutinizng the way support teams "}</span>
            <Typewriter
              text={[
                "manage their knowledge",
                "delight their customers",
                "assure quality",
                "automate resolution",
                "doing real impact",
              ]}
              speed={70}
              className="text-yellow-500"
              waitTime={1500}
              deleteSpeed={40}
              cursorChar={"_"}
            />
          </p>
        </div>
        <div>This playground build with 💛 & 🧠 by Quack AI team.</div>
      </footer>
    </div>
  );
};
