import { params } from "@/params";
import { useAuthorizedMutation, useAuthorizedQuery } from "..";
import { useMemo } from "react";
import {
  OpportunitiesGroupType,
  OpportunityGroupPriority,
  OpportunityStatus,
} from "@/routes/knowledge-gaps/index.types";

export type OpportunityType = {
  resolution: "resolution";
  knowledge: "knowledge";
  quality: "quality";
};

export type OpportunityGroup = {
  id: string;
  externalId: string;
  tenantId: string;
  opportunities: Opportunity[];
};

export type ExpandedOpportunityGroup = OpportunityGroup & {
  faqs: FAQ[];
};

export type Opportunity = {
  id: number;
  label: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  type: string;
  status: string;
  transactionId: number;
  groupId: string;
  transaction: Transaction;
};

export type Transaction = {
  id: number;
  tenantId: string;
  userContent: string;
  agentContent: string;
  createdAt: Date;
  updatedAt: Date;
  tickets: {
    id: string;
    displayId: string;
    sourceID: string;
  }[];
  commonTransactionEval: { relatedArticlesIds: string[] };
};

export type FAQ = {
  id: string;
  answer: string;
  opportunityGroupId: string;
  question: string;
  tenantId: string;
};

export const useOpportunityGroups = ({
  enabled,
  findBy,
  startDate,
  endDate,
  priority,
  status,
  page,
  type,
}: {
  enabled: boolean;
  findBy?: string;
  startDate?: string;
  endDate?: string;
  priority?: OpportunityGroupPriority[];
  status?: OpportunityStatus[];
  page?: number;
  type?: OpportunitiesGroupType[];
}) => {
  const queryParams = useMemo(() => {
    const params = new URLSearchParams();

    const paramMappings = [
      { value: startDate, key: "startDate" },
      { value: endDate, key: "endDate" },
      { value: findBy, key: "search" },
      { value: page?.toString(), key: "page" },
    ];

    paramMappings.forEach(({ value, key }) => {
      if (value) params.append(key, value);
    });

    if (priority?.length) {
      priority.forEach((p) => params.append("priority[]", p as string));
    }

    if (status?.length) {
      status.forEach((s) => params.append("statuses[]", s as string));
    }
    
    if (type?.length) {
      type.forEach((t) => params.append("type[]", t as string));
    }

    return params.toString();
  }, [findBy, startDate, endDate, priority, status, page, type]);

  return useAuthorizedQuery({
    queryKey: `useGetOpportunityGroups-${queryParams}`,
    url: `${params.API_URL}/platform/opportunity-groups${
      queryParams ? `?${queryParams}` : ""
    }`,
    method: "GET",
    enabled,
  });
};

export const useGetOpportunityGroup = ({
  id,
  startDate,
  endDate,
  enabled,
}: {
  id: string;
  startDate?: string;
  endDate?: string;
  enabled: boolean;
}) => {
  const queryParams = useMemo(() => {
    const params = new URLSearchParams();

    const paramMappings = [
      { value: startDate, key: "startDate" },
      { value: endDate, key: "endDate" },
    ];

    paramMappings.forEach(({ value, key }) => {
      if (value) params.append(key, value);
    });

    return params.toString();
  }, [startDate, endDate]);

  return useAuthorizedQuery({
    queryKey: `useGetOpportunityGroup-${id}-${queryParams}`,
    url: `${params.API_URL}/platform/opportunity-groups/${id}${
      queryParams ? `?${queryParams}` : ""
    }`,
    method: "GET",
    enabled,
  });
};

export const useUpdateOpportunityGroup = ({
  opportunityGroupId,
}: {
  opportunityGroupId: string;
}) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/opportunity-groups/${opportunityGroupId}`,
    method: "PUT",
  });
};

export const useCreateOpportunityGroupFaqFeedback = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/opportunity-groups/faq-feedback`,
    method: "POST",
  });
};
