import { useCallback, useEffect, useRef, useState } from "react";
import { Flex } from "@radix-ui/themes";
import { Dialog } from "../../../components/shared/dialog";
import {
  ConnectIntegrationFormPayload,
  GroupsPolicy,
  Integration,
  IntegrationName,
  IntegrationType,
  isKnowledgeBaseIntegrationName,
  isNangoSupportedIntegrationName,
  isTicketSystemIntegrationName,
  supportedKnowledgeBase,
  supportedTicketSystems,
} from "../../../api/integration/Integration.model";
import {
  useCompleteTicketSystemConfiguration,
  useCreateBrandsConfiguration,
  useCreateIntegrationV2,
  useGetAllBrandsFromIntegration,
  useGetTenantGroupsInTicketSystem,
} from "../../../api/integration";
import { addIntegrationConfigs } from "./addIntegrationConfigs";
import AddIntegrationHeader from "./AddIntegrationHeader";
import SelectIntegration from "./select-integration/SelectIntegration";
import ConnectIntegration from "./connect-integration/ConnectIntegration";
import CreateIntegration from "./create-integration/CreateIntegration";
import styles from "./AddIntegration.module.scss";
import CommingSoonDialog from "./CommingSoonDialog";
import { createToast } from "vercel-toast";
import { integrationNameToGalleryItem } from "../../../api/integration/integrationNameToGalleryItem";
import { mixTrackEvent, IntegrationEvents } from "@/assets/mixpanel";
import HeadingSVG from "@/routes/onboarding/getting-started/assets/HeadingSVG";
import { useCreateNangoIntegration } from "..";
import ConfigureBrands from "./configure-brands/ConfigureBrands";

const addIntegrationSteps = ["select", "connect", "create", "brands", "done"];
type AddIntegrationStep = (typeof addIntegrationSteps)[number];

interface AddIntegrationProps {
  preSelectedName?: IntegrationName;
  type: IntegrationType;
  existingIntegrations: Integration[];
  reloadIntegrations: () => Promise<unknown>;
  onClose: () => void;
  allowMultiple: boolean;
}

function AddIntegration({
  preSelectedName,
  type,
  existingIntegrations,
  reloadIntegrations,
  onClose,
  allowMultiple,
}: AddIntegrationProps) {
  const { mutateAsync: createIntegrationV2 } = useCreateIntegrationV2();
  const { mutateAsync: completeTicketSystemConfiguration } =
    useCompleteTicketSystemConfiguration();
  const { mutateAsync: createBrandsConfiguration } =
    useCreateBrandsConfiguration();

  const [step, setStep] = useState<AddIntegrationStep>("select");
  const [name, setName] = useState<IntegrationName>();

  const [commingSoon, setCommingSoon] = useState<IntegrationName>();

  const { data: tenantGroups } = useGetTenantGroupsInTicketSystem(
    type === "TICKET_SYSTEM" && step === "create"
  );

  const { data: integrationBrands } = useGetAllBrandsFromIntegration(
    type === "KNOWLEDGE_BASE" && step === "brands"
  );

  const config = useRef(addIntegrationConfigs[type]);

  const createNangoIntegration = useCreateNangoIntegration({
    reloadIntegrations,
  });

  const handleIntegrationCreated = useCallback(
    async (name: IntegrationName) => {
      await reloadIntegrations();
      createToast(
        `Integration to ${integrationNameToGalleryItem[name].title} created successfuly`,
        {
          type: "success",
          timeout: 5000,
        }
      );
      mixTrackEvent({
        event:
          type === "TICKET_SYSTEM"
            ? IntegrationEvents.TICKETING_SYSTEM_INTEGRATION
            : IntegrationEvents.KNOWLEDGE_INTEGRATION,
        properties: {
          name: name,
        },
      });

      if (type === "KNOWLEDGE_BASE" && name === "zendesk") {
        setStep("brands");
      } else if (allowMultiple) {
        setStep("done");
        setName(undefined);
      } else {
        onClose();
      }
    },
    [type, reloadIntegrations, onClose, allowMultiple]
  );

  const handleSelectIntegrationName = useCallback(
    (name: IntegrationName) => {
      mixTrackEvent({
        event: IntegrationEvents.CLICK_INTEGRATION,
        properties: {
          name,
          type,
        },
      });
      if (type === "TICKET_SYSTEM" && isTicketSystemIntegrationName(name)) {
        if (supportedTicketSystems.includes(name)) {
          isNangoSupportedIntegrationName(name)
            ? createNangoIntegration({ name, type })
            : (setName(name), setStep("connect"));
        } else {
          setCommingSoon(name);
        }
      } else if (
        type === "KNOWLEDGE_BASE" &&
        isKnowledgeBaseIntegrationName(name)
      ) {
        if (supportedKnowledgeBase.includes(name)) {
          isNangoSupportedIntegrationName(name)
            ? createNangoIntegration({ name, type })
            : (setName(name), setStep("connect"));
        } else {
          setCommingSoon(name);
        }
      }
    },
    [createNangoIntegration]
  );

  const handleConnectIntegration = useCallback(
    async (payload: ConnectIntegrationFormPayload) => {
      const { subdomain, ...secretValue } = payload;

      if (name == null) {
        return;
      }

      if (type === "TICKET_SYSTEM" && isTicketSystemIntegrationName(name)) {
        await createIntegrationV2({
          type,
          name,
          secretValue,
          subdomain,
        });

        setStep("create");
      } else if (
        type === "KNOWLEDGE_BASE" &&
        isKnowledgeBaseIntegrationName(name)
      ) {
        await createIntegrationV2({
          type,
          name,
          secretValue,
          subdomain,
          isConfigurationRequired: name === "zendesk",
        });

        await handleIntegrationCreated(name);
      }
    },
    [type, name, createIntegrationV2, handleIntegrationCreated]
  );

  const handleCreateIntegration = useCallback(
    async (groupsPolicy: GroupsPolicy) => {
      if (name == null) {
        return;
      }

      if (type === "TICKET_SYSTEM" && isTicketSystemIntegrationName(name)) {
        await completeTicketSystemConfiguration({
          type,
          name,
          groupsPolicy,
        });

        await handleIntegrationCreated(name);
      }
    },
    [type, name, handleIntegrationCreated]
  );

  const handleIntegrationBrandConfiguration = useCallback(
    async (brandIds: string[]) => {
      if (name == null) {
        return;
      }

      if (type === "KNOWLEDGE_BASE" && isKnowledgeBaseIntegrationName(name)) {
        await createBrandsConfiguration({
          type,
          name,
          brandIds,
        });
      }

      if (allowMultiple) {
        setStep("done");
        setName(undefined);
      } else {
        onClose();
      }
    },
    [type, name]
  );

  const handleBack = useCallback(() => {
    if (step === "connect") {
      setStep("select");
      setName(undefined);
    } else if (step === "create" || step === "brands") {
      setStep("connect");
    }
  }, [step]);

  useEffect(() => {
    if (preSelectedName) {
      handleSelectIntegrationName(preSelectedName);
    }
  }, [preSelectedName, handleSelectIntegrationName]);

  if (config.current == null) {
    return <></>;
  }

  return (
    <>
      <Flex
        height={"100%"}
        direction={"column"}
        className={styles.AddIntegrationContainer}
      >
        <AddIntegrationHeader
          {...config.current.header}
          isGettingStarted={preSelectedName === undefined}
        />

        <Flex flexGrow={"1"} className={styles.AddIntegration}>
          {(step === "select" || step === "done") && (
            <SelectIntegration
              {...config.current.select}
              type={type}
              onClick={handleSelectIntegrationName}
              existingIntegrations={existingIntegrations}
              isMultipleAllowed
            />
          )}

          {step === "connect" && name != null && (
            <ConnectIntegration
              type={type}
              name={name}
              onSubmit={handleConnectIntegration}
              onBack={handleBack}
            />
          )}

          {step === "create" &&
            name != null &&
            tenantGroups?.groups != null && (
              <CreateIntegration
                name={name}
                groups={tenantGroups.groups}
                onSubmit={handleCreateIntegration}
                onBack={handleBack}
              />
            )}

          {step === "brands" && name != null && integrationBrands != null && (
            <ConfigureBrands
              name={name}
              brands={integrationBrands.map((b) => ({
                ...b,
                id: b.id.toString(),
              }))}
              onSubmit={handleIntegrationBrandConfiguration}
              onBack={handleBack}
            />
          )}

          <Flex flexGrow={"1"} className={styles.Illustration}>
            <HeadingSVG />
          </Flex>
        </Flex>
      </Flex>

      {commingSoon != null && (
        <CommingSoonDialog
          name={commingSoon}
          onClose={() => setCommingSoon(undefined)}
        />
      )}
    </>
  );
}

interface AddIntegrationDialogProps {
  type: IntegrationType;
  preSelectedName?: IntegrationName;
  loading?: boolean;
  existingIntegrations: Integration[];
  onClose: () => void;
  reloadIntegrations: () => Promise<unknown>;
  allowMultiple?: boolean;
}

function AddIntegrationDialog({
  type,
  preSelectedName,
  loading = false,
  existingIntegrations,
  onClose,
  reloadIntegrations,
  allowMultiple = false,
}: AddIntegrationDialogProps) {
  return (
    <Dialog
      open
      onOpenChange={(open) => (open ? undefined : onClose())}
      loading={loading}
    >
      <Dialog.Content size={"large"}>
        <AddIntegration
          type={type}
          preSelectedName={preSelectedName}
          onClose={onClose}
          reloadIntegrations={reloadIntegrations}
          existingIntegrations={existingIntegrations}
          allowMultiple={allowMultiple}
        />
      </Dialog.Content>
    </Dialog>
  );
}

export default AddIntegrationDialog;
