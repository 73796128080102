import { useMemo } from "react";
import dayjs from "dayjs";
import { scaleTime } from "d3-scale";
import { LineChart, Line, XAxis, CartesianGrid, YAxis } from "recharts";
import { radixColors } from "@/components/radixColors";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { AxisDomain } from "recharts/types/util/types";

export type DataPoint = {
  date: string; // ISO date string
  values: Record<string, number>;
};

type ChartDataPoint = {
  timestamp: number;
} & Record<string, number>;

export interface LineConfig {
  key: string;
  label: string;
}

interface TimeSeriesChartProps {
  data: DataPoint[];
  lines: LineConfig[];
  yDomain?: AxisDomain;
}

export function TimeSeriesChart({
  data,
  lines,
  yDomain,
}: TimeSeriesChartProps) {
  const { config, chartData, dataKeys, xDomain, ticks, formatDate } =
    useMemo(() => {
      const config: ChartConfig = {};

      // Convert each ISO date string into a timestamp for numerical scaling.
      const chartData: ChartDataPoint[] = data
        .map(({ date, values }) => ({
          timestamp: new Date(date).getTime(),
          ...values,
        }))
        .sort((a, b) => a.timestamp - b.timestamp);

      lines.forEach(({ key, label }, index) => {
        config[key] = {
          label,
          color: `var(--${radixColors[index]}-10)`,
        };
      });
      const dataKeys = lines.map((line) => line.key);

      // Determine the minimum and maximum timestamps from the data.
      const timestamps: number[] = chartData.map((item) => item.timestamp);
      const minTimestamp: number = Math.min(...timestamps);
      const maxTimestamp: number = Math.max(...timestamps);
      const xDomain = [minTimestamp, maxTimestamp];

      // Create a D3 time scale using the computed min and max timestamps.
      // This scale is used here to generate tick values.
      const timeScale = scaleTime()
        .domain([new Date(minTimestamp), new Date(maxTimestamp)])
        .range([minTimestamp, maxTimestamp]);

      // Generate tick values (displaying 5 ticks on the x-axis).
      const ticks: number[] = timeScale.ticks(12).map((date) => date.getTime());

      // Format tick labels from timestamp to a readable date.
      const dateFormat =
        dayjs.duration(maxTimestamp - minTimestamp).asYears() > 1
          ? "MMM DD"
          : "MMM DD";
      const formatDate = (timestamp: number): string =>
        dayjs(timestamp).format(dateFormat);

      return {
        config,
        chartData,
        dataKeys,
        xDomain,
        ticks,
        formatDate,
      };
    }, [data, lines]);

  return (
    <ChartContainer config={config} className="w-full max-h-[332px]">
      <LineChart
        accessibilityLayer
        data={chartData}
        margin={{
          left: 12,
          right: 12,
          top: 4,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="timestamp"
          type="number"
          domain={xDomain}
          ticks={ticks}
          tickFormatter={formatDate}
          tickLine={false}
          axisLine={false}
          tickMargin={8}
        />
        <YAxis hide domain={yDomain} />
        <ChartTooltip
          cursor={false}
          content={
            <ChartTooltipContent
              labelGetter={(payload) => formatDate(payload.timestamp)}
            />
          }
        />
        <ChartLegend content={<ChartLegendContent />} />
        {dataKeys.map((key) => (
          <Line
            type="monotone"
            dataKey={key}
            stroke={`var(--color-${key})`}
            strokeWidth={2}
            dot={false}
            connectNulls
          />
        ))}
      </LineChart>
    </ChartContainer>
  );
}
