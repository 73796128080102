import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Text } from "@geist-ui/core";

import {
  getOpenTicketsFormExplorerObject,
  useExploreTickets,
} from "../../../api/useExplore";
import { useGetTopics } from "../../../api/useGetTopics";
import styles from "./styles.module.scss";
import { ArrowLeft, ArrowRight, Loader } from "@geist-ui/icons";
import { cleanTickets } from "./lib";
import { useReleases } from "../../simulator/context/release";
import { VerifiedIcon } from "lucide-react";
import SharedSelect from "../../../components/shared/select";
import { SearchTicket } from "../../simulator/searchTicket";
import { useFeatureFlag } from "configcat-react";

export const CoPilotSimulatorSuggestions = ({
  handleSelectTicketId,
  selectedValue,
  setSelectedValue,
}: {
  handleSelectTicketId: ({
    ticketId,
    topic,
    comments,
  }: {
    ticketId: string;
    topic: string;
    comments: any;
  }) => void;
  selectedValue: string;
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { user, isAuthenticated } = useAuth0();
  const [currentIndex, setCurrentIndex] = useState(0);

  const { releaseConversations } = useReleases();

  const { data: topicList, isLoading } = useGetTopics(!!user);

  const [data, setData] = useState<any[]>([]);

  const selectedTopic = useMemo(() => {
    const selectedTopicId = +selectedValue;
    return topicList?.find(
      (topic: { id: number }) => topic.id === selectedTopicId
    )?.topic;
  }, [selectedValue, topicList]);

  const { mutateAsync: fetchTickets, isLoading: similarTicketsLoading } =
    useExploreTickets();

  useEffect(() => {
    if (user && isAuthenticated) {
      setTimeout(() => {
        const finiteStateObject = getOpenTicketsFormExplorerObject({
          topicName: selectedTopic,
        });
        fetchTickets(finiteStateObject).then((res) => {
          setData(
            res?.ticketsData?.tickets.map((x: any) => {
              return {
                ...x,
                id: x.id.split("::")[1],
                quackId: x.id,
              };
            }) ?? []
          );
        });
      }, 100);
    }
  }, [user, selectedTopic, isAuthenticated, fetchTickets]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 3 : prevIndex - 3
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 3 >= data.length ? 0 : prevIndex + 3
    );
  };

  useEffect(() => {
    if (topicList && Array.isArray(topicList) && !selectedValue) {
      setSelectedValue((prev) => {
        return String(
          topicList?.filter(({ topic }: { topic: string }) => {
            return topic.toLowerCase() !== "other";
          })?.[0]?.id
        );
      });
    }
  }, [topicList, setSelectedValue, selectedValue]);

  const { value: searchTicketInSimulator } = useFeatureFlag(
    "searchTicketInSimulator",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  return (
    <div className={styles.suggestionsContainer}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.selectContainer}>
          <SharedSelect
            options={topicList
              ?.filter(({ topic }: { topic: string }) => {
                return topic.toLowerCase() !== "other";
              })
              .map((topic: { topic: string; id: number }) => ({
                label: topic.topic,
                value: String(topic.id),
              }))}
            value={selectedValue}
            onChange={(newValue) => {
              setSelectedValue(newValue);
            }}
          />
          {searchTicketInSimulator && (
            <SearchTicket
              randomTicketId={data?.[0]?.quackId || ""}
              onTicketFound={handleSelectTicketId}
            />
          )}
        </div>
      )}

      {data?.length !== 0 && !similarTicketsLoading ? (
        <>
          <div className={styles.title}>
            <Text small my={0}>
              Tickets currently open
            </Text>
          </div>
          <div className={cx(styles.cardContainer)}>
            {data
              ?.filter((t: any) => t?.id)
              .slice(currentIndex, currentIndex + 3)
              .map(
                (ticket: {
                  id: string;
                  quackId: string;
                  description: string;
                  similarTicketsCount: number;
                  topicId: number;
                  comments: any;
                }) => {
                  const cleanedDescription = cleanTickets(
                    ticket.description ?? ""
                  );

                  const isExist = releaseConversations.some(
                    (conversation) =>
                      conversation.releaseComments.sort(
                        (a, b) => a.id - b.id
                      )[0]?.comment === cleanedDescription
                  );
                  return (
                    <Card
                      key={ticket.id}
                      className={styles.card}
                      hoverable
                      onClick={() =>
                        handleSelectTicketId({
                          ticketId: ticket.quackId,
                          topic: selectedTopic,
                          comments: ticket.comments,
                        })
                      }
                    >
                      <div>
                        <Text small>#{ticket.id}</Text>

                        {isExist ? (
                          <span className={styles.verified}>
                            <VerifiedIcon />
                            Already reviewed
                          </span>
                        ) : (
                          <></>
                        )}
                        <Text h6 my={0}>
                          {cleanedDescription}
                        </Text>
                      </div>
                      <div>
                        <Text small>
                          similar tickets - {ticket.similarTicketsCount}
                        </Text>
                      </div>
                    </Card>
                  );
                }
              )}
          </div>

          {data?.length > 3 && (
            <div className={styles.btnsContainer}>
              <button onClick={handlePrev} className={styles.btn}>
                <ArrowLeft />
              </button>
              <button onClick={handleNext} className={styles.btn}>
                <ArrowRight />
              </button>
            </div>
          )}
        </>
      ) : (
        <div className={styles.title}>
          <Text small my={0}>
            Oops, we didn't find any similar ticket, but feel free to ask
            anything 👇
          </Text>
        </div>
      )}
      {similarTicketsLoading && (
        <div className={cx(styles.cardContainer)}>
          <>
            <SuggestionsSkeleton />
          </>
        </div>
      )}
    </div>
  );
};

const SuggestionsSkeleton = () => {
  return (
    <>
      <div className={styles.skeletonCard}></div>
      <div className={styles.skeletonCard}></div>
      <div className={styles.skeletonCard}></div>
    </>
  );
};
