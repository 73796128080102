import React, { useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { Message } from "ai/react";
import { useSimulatorChat } from "@/routes/simulator/chat/api";
import { RocketIcon } from "@radix-ui/react-icons";
import { Converter } from "showdown";
import { Switch, Tooltip } from "@radix-ui/themes";
import { translateText } from "../../../services/translation";
import toast from "react-hot-toast";
import { PiTranslate } from "react-icons/pi";

import dayjs from "dayjs";
import { Button, Spinner } from "@radix-ui/themes";
import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { TSimulatorLiveModeTicket } from "../types";
import styles from "../styles.module.scss";
import { useInstructions } from "../../simulator/context/instructions";
import { SendInteractionToTraining } from "../sendTicketToTraining";

export const SimulateTicket = ({
  ticket,
  activeTicketIndex,
  ticketsLength,
  setActiveTicketIndex,
  topic,
  topicId,
  ticketReview,
  setTicketReview,
  reviewedCount,
}: {
  ticket: TSimulatorLiveModeTicket;
  activeTicketIndex: number;
  ticketsLength: number;
  setActiveTicketIndex: (index: number) => void;
  topic: string | null;
  topicId: string | null;
  ticketReview: {
    ticketId: string;
    good?: boolean;
    comment?: string;
  };
  setTicketReview: (ticketReview: {
    ticketId: string;
    good?: boolean;
    comment?: string;
  }) => void;
  reviewedCount: number;
}) => {
  const { instructions } = useInstructions();
  const [customerTranslation, setCustomerTranslation] = useState<string | null>(
    null
  );
  const [botTranslation, setBotTranslation] = useState<string | null>(null);
  const [isTranslatingCustomer, setIsTranslatingCustomer] = useState(false);
  const [isTranslatingBot, setIsTranslatingBot] = useState(false);

  const chatId = useMemo(
    () => String(dayjs().valueOf()) + ticket.id,
    [ticket.id]
  );
  const navigate = useNavigate();
  const { simulatorId } = useParams();

  const { messages, append, isLoading } = useSimulatorChat({
    id: chatId,
    isTraining: true,
    instructions: instructions,
    topic: topic ?? "",
    subTopic: "",
    handleResponse: () => {},
  });

  const ticketReviewRef = useRef<HTMLDivElement | null>(null);

  const [hasAppended, setHasAppended] = useState(false);

  useEffect(() => {
    setHasAppended(false);
    setBotTranslation("");
  }, [ticket.id]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!hasAppended && ticket.comments && ticket.comments.length) {
      timeout = setTimeout(() => {
        append({ content: ticket.comments[0].rawBody, role: "user" });
        setHasAppended(true);
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [ticket.id, append, ticket.comments, hasAppended]);

  useEffect(() => {
    if (ticketReviewRef.current) {
      ticketReviewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const botMessage = useMemo(() => {
    return (
      messages.find((message: Message) => message.role === "assistant")
        ?.content || null
    );
  }, [messages]);

  const submitTicketReview = ({
    good,
    comment,
  }: {
    good: boolean;
    comment?: string;
  }) => {
    setTicketReview({
      ticketId: ticket.id,
      good,
      comment,
    });
  };

  const returnToSimulator = () => {
    navigate(`/automation/${simulatorId}?topicId=${topicId}`);
  };

  const convertor = new Converter({
    simplifiedAutoLink: true,
    excludeTrailingPunctuationFromURLs: true,
    openLinksInNewWindow: true,
    smoothLivePreview: true,
    disableForced4SpacesIndentedSublists: true,
  });

  const convertMarkdownToHTML = (markdown: string) => {
    if (markdown) {
      // return markdown;
      return convertor?.makeHtml(markdown);
    } else {
      return "";
    }
  };

  const firstComment = useMemo(() => {
    return ticket.comments
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      .find((comment) => !comment.isInternal && !comment.agent)?.rawBody;
  }, [ticket.comments]);

  return (
    <div className={styles.simulateTicketContainer}>
      <div className={styles.topPageContainer}>
        <ChevronLeftIcon onClick={returnToSimulator} cursor="pointer" />
        <div>
          <h3>{ticket.subject}</h3>
          <h5>#{ticket.displayId || ticket.id?.split("::")[1]}</h5>
        </div>
      </div>
      <div className={styles.ticketDetailsContainer}>
        <div className={styles.messagesContainer}>
          <div className={styles.messageContainer}>
            <p>Customer inquiry</p>
            <div className={cx(styles.message, styles.client)}>
              <div style={{ width: "100%" }}>
                {customerTranslation || firstComment}
              </div>
              <div className="flex items-center gap-2">
                <Tooltip
                  content={
                    customerTranslation
                      ? "Show original"
                      : "Translate to English"
                  }
                >
                  <PiTranslate />
                </Tooltip>
                <Switch
                  size="1"
                  disabled={isTranslatingCustomer}
                  checked={!!customerTranslation}
                  onCheckedChange={async (checked) => {
                    if (!checked) {
                      setCustomerTranslation(null);
                      return;
                    }
                    setIsTranslatingCustomer(true);
                    try {
                      const translated = await translateText(
                        firstComment || ""
                      );
                      setCustomerTranslation(translated);
                    } catch (error) {
                      console.error("Translation failed:", error);
                      toast.error("Failed to translate message");
                    } finally {
                      setIsTranslatingCustomer(false);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {(botMessage || isLoading) && (
            <div className={styles.messageContainer}>
              <p>
                <RocketIcon /> Generated response
              </p>
              <div className={cx(styles.message, styles.bot)}>
                {botMessage ? (
                  <>
                    <div
                      className={styles.messageFormat}
                      dangerouslySetInnerHTML={{
                        __html: convertMarkdownToHTML(
                          botTranslation || botMessage
                        ),
                      }}
                    />
                    <div className="flex items-center gap-2">
                      <Tooltip
                        content={
                          botTranslation
                            ? "Show original"
                            : "Translate to English"
                        }
                      >
                        <PiTranslate />
                      </Tooltip>
                      <Switch
                        size="1"
                        disabled={isTranslatingBot}
                        checked={!!botTranslation}
                        onCheckedChange={async (checked) => {
                          if (!checked) {
                            setBotTranslation(null);
                            return;
                          }
                          setIsTranslatingBot(true);
                          try {
                            const translated = await translateText(botMessage);
                            setBotTranslation(translated);
                          } catch (error) {
                            console.error("Translation failed:", error);
                            toast.error("Failed to translate message");
                          } finally {
                            setIsTranslatingBot(false);
                          }
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <Spinner size="2" />
                )}
              </div>
            </div>
          )}
          {ticketReview && (
            <div className={styles.ticketReview} ref={ticketReviewRef}>
              {ticketReview.good === undefined || ticketReview.good === null ? (
                <>
                  <p>Rate the response</p>
                  <div className={styles.ticketReviewButtons}>
                    <SendInteractionToTraining
                      onSubmit={submitTicketReview}
                      interactionId={ticket.id}
                      disableToast
                    />
                    <Button
                      color="gray"
                      variant="solid"
                      highContrast
                      onClick={() => submitTicketReview({ good: true })}
                    >
                      Good
                    </Button>
                  </div>
                </>
              ) : (
                <div className={styles.submitedFeedback}>
                  {ticketReview.good ? (
                    <p>Good Feedback Submitted!</p>
                  ) : (
                    <p>
                      Feedback submitted! 🎯 This ticket has been added to the
                      training bucket
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.left}>
          Ticket {activeTicketIndex + 1} out of {ticketsLength}
          <br />
          {reviewedCount} reviewed
        </div>
        <div className={styles.right}>
          <Button
            color="gray"
            variant="outline"
            highContrast
            className={styles.nextTicket}
            disabled={activeTicketIndex === 0}
            onClick={() => setActiveTicketIndex(activeTicketIndex - 1)}
          >
            <ArrowLeftIcon />
            Previous Ticket
          </Button>
          <Button
            color="gray"
            variant="outline"
            highContrast
            className={styles.nextTicket}
            onClick={() =>
              activeTicketIndex !== ticketsLength - 1
                ? setActiveTicketIndex(activeTicketIndex + 1)
                : returnToSimulator()
            }
          >
            {activeTicketIndex === ticketsLength - 1 ? "Done" : "Next Ticket"}
            <ArrowRightIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};
