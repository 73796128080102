import { params } from "@/params";
import { useAuthorizedQuery } from "..";
import { CommentValue } from "./TicketTranscript.model";

type TicketTranscriptResponse = {
  comments: CommentValue[];
};

export function useTicketTranscript(ticketId?: string) {
  return useAuthorizedQuery<TicketTranscriptResponse>({
    queryKey: `useTicketTranscript-${ticketId}`,
    url: `${params.API_URL}/platform/tickets/${ticketId}/comments`,
    method: "GET",
    enabled: ticketId != null,
  });
}
