import { ReactNode } from "react";
import cx from "classnames";
import { useTheme } from "@/components/Theme";
import { SimulatorChannel } from "@/api/simulator/simulator.model";
import styles from "./SimulatorChannelIcon.module.scss";

function TicketsSVG() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.66667C0 2.98477 2.98477 0 6.66667 0H33.3333C37.0152 0 40 2.98477 40 6.66667V33.3333C40 37.0152 37.0152 40 33.3333 40H6.66667C2.98477 40 0 37.0152 0 33.3333V6.66667Z"
        fill="#EDF6FF"
      />
      <path
        d="M9.16675 18.3333C9.16675 12.8105 13.6439 8.33334 19.1667 8.33334H25.8334C28.5948 8.33334 30.8334 10.5719 30.8334 13.3333C30.8334 16.0948 28.5948 18.3333 25.8334 18.3333H9.16675Z"
        fill="#0082E6"
        fill-opacity="0.632"
      />
      <path
        d="M9.16675 31.6667C9.16675 26.1438 13.6439 21.6667 19.1667 21.6667H25.8334C28.5948 21.6667 30.8334 23.9052 30.8334 26.6667C30.8334 29.4281 28.5948 31.6667 25.8334 31.6667H9.16675Z"
        fill="#0082E6"
        fill-opacity="0.632"
      />
    </svg>
  );
}

function DarkTicketsSVG() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.66667C0 2.98477 2.98477 0 6.66667 0H33.3333C37.0152 0 40 2.98477 40 6.66667V33.3333C40 37.0152 37.0152 40 33.3333 40H6.66667C2.98477 40 0 37.0152 0 33.3333V6.66667Z"
        fill="#11253F"
      />
      <path
        d="M9.16797 18.3333C9.16797 12.8105 13.6451 8.33334 19.168 8.33334H25.8346C28.5961 8.33334 30.8346 10.5719 30.8346 13.3333C30.8346 16.0948 28.5961 18.3333 25.8346 18.3333H9.16797Z"
        fill="#1183FF"
        fill-opacity="0.875"
      />
      <path
        d="M9.16797 31.6667C9.16797 26.1438 13.6451 21.6667 19.168 21.6667H25.8346C28.5961 21.6667 30.8346 23.9052 30.8346 26.6667C30.8346 29.4281 28.5961 31.6667 25.8346 31.6667H9.16797Z"
        fill="#1183FF"
        fill-opacity="0.875"
      />
    </svg>
  );
}

function HelpCenterSVG() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z"
        fill="#EDFADA"
      />
      <path
        d="M9 14C9 10.6863 11.6863 8 15 8H25C28.3137 8 31 10.6863 31 14V26C31 29.3137 28.3137 32 25 32H9V14Z"
        fill="#699200"
        fill-opacity="0.671"
      />
      <path
        d="M13.3333 15.8333C13.3333 15.3731 13.7063 15 14.1666 15H25.8333C26.2935 15 26.6666 15.3731 26.6666 15.8333C26.6666 16.2936 26.2935 16.6667 25.8333 16.6667H14.1666C13.7063 16.6667 13.3333 16.2936 13.3333 15.8333Z"
        fill="#EDFADA"
      />
      <path
        d="M13.3333 19.1666C13.3333 18.7064 13.7063 18.3333 14.1666 18.3333H25.8333C26.2935 18.3333 26.6666 18.7064 26.6666 19.1666C26.6666 19.6269 26.2935 20 25.8333 20H14.1666C13.7063 20 13.3333 19.6269 13.3333 19.1666Z"
        fill="#EDFADA"
      />
      <path
        d="M13.3333 22.5C13.3333 22.0398 13.7063 21.6667 14.1666 21.6667H25.8333C26.2935 21.6667 26.6666 22.0398 26.6666 22.5C26.6666 22.9603 26.2935 23.3334 25.8333 23.3334H14.1666C13.7063 23.3334 13.3333 22.9603 13.3333 22.5Z"
        fill="#EDFADA"
      />
    </svg>
  );
}

function DarkHelpCenterSVG() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z"
        fill="#1F2711"
      />
      <path
        d="M9 14C9 10.6863 11.6863 8 15 8H25C28.3137 8 31 10.6863 31 14V26C31 29.3137 28.3137 32 25 32H9V14Z"
        fill="#D1FF44"
        fill-opacity="0.477"
      />
      <path
        d="M13.332 15.8333C13.332 15.3731 13.7051 15 14.1654 15H25.832C26.2923 15 26.6654 15.3731 26.6654 15.8333C26.6654 16.2936 26.2923 16.6667 25.832 16.6667H14.1654C13.7051 16.6667 13.332 16.2936 13.332 15.8333Z"
        fill="#1F2711"
      />
      <path
        d="M13.332 19.1666C13.332 18.7064 13.7051 18.3333 14.1654 18.3333H25.832C26.2923 18.3333 26.6654 18.7064 26.6654 19.1666C26.6654 19.6269 26.2923 20 25.832 20H14.1654C13.7051 20 13.332 19.6269 13.332 19.1666Z"
        fill="#1F2711"
      />
      <path
        d="M13.332 22.5C13.332 22.0398 13.7051 21.6667 14.1654 21.6667H25.832C26.2923 21.6667 26.6654 22.0398 26.6654 22.5C26.6654 22.9603 26.2923 23.3334 25.832 23.3334H14.1654C13.7051 23.3334 13.332 22.9603 13.332 22.5Z"
        fill="#1F2711"
      />
    </svg>
  );
}

function LiveChatSVG() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.66667C0 2.98477 2.98477 0 6.66667 0H33.3333C37.0152 0 40 2.98477 40 6.66667V33.3333C40 37.0152 37.0152 40 33.3333 40H6.66667C2.98477 40 0 37.0152 0 33.3333V6.66667Z"
        fill="#FF9101"
        fill-opacity="0.165"
      />
      <path
        d="M9.16675 20C9.16675 14.4772 13.6439 10 19.1667 10H20.8334C26.3563 10 30.8334 14.4772 30.8334 20C30.8334 25.5228 26.3563 30 20.8334 30H9.16675V20Z"
        fill="#E34800"
        fill-opacity="0.64"
      />
    </svg>
  );
}

function DarkLiveChatSVG() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.66667C0 2.98477 2.98477 0 6.66667 0H33.3333C37.0152 0 40 2.98477 40 6.66667V33.3333C40 37.0152 37.0152 40 33.3333 40H6.66667C2.98477 40 0 37.0152 0 33.3333V6.66667Z"
        fill="#FD3700"
        fill-opacity="0.122"
      />
      <path
        d="M9.16797 20C9.16797 14.4772 13.6451 10 19.168 10H20.8346C26.3575 10 30.8346 14.4772 30.8346 20C30.8346 25.5228 26.3575 30 20.8346 30H9.16797V20Z"
        fill="#FF7525"
        fill-opacity="0.741"
      />
    </svg>
  );
}

function CopilotSVG() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.66667C0 2.98477 2.98477 0 6.66667 0H33.3333C37.0152 0 40 2.98477 40 6.66667V33.3333C40 37.0152 37.0152 40 33.3333 40H6.66667C2.98477 40 0 37.0152 0 33.3333V6.66667Z"
        fill="#02C096"
        fill-opacity="0.095"
      />
      <path
        d="M9.99997 31C15.6078 31 20.1538 26.4539 20.1538 20.8461L20.1538 14.0769C20.1538 11.273 17.8808 8.99999 15.0769 8.99999C12.273 8.99999 9.99997 11.273 9.99997 14.0769L9.99997 31Z"
        fill="#009783"
        fill-opacity="0.675"
      />
      <path
        d="M10 31C10 25.4771 14.4772 21 20 21H26.6667C29.4281 21 31.6667 23.2385 31.6667 26C31.6667 28.7614 29.4281 31 26.6667 31H10Z"
        fill="#009783"
        fill-opacity="0.675"
      />
    </svg>
  );
}

function DarkCopilotSVG() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.66667C0 2.98477 2.98477 0 6.66667 0H33.3333C37.0152 0 40 2.98477 40 6.66667V33.3333C40 37.0152 37.0152 40 33.3333 40H6.66667C2.98477 40 0 37.0152 0 33.3333V6.66667Z"
        fill="#00FEAE"
        fill-opacity="0.074"
      />
      <path
        d="M9.99851 31C15.6063 31 20.1523 26.4539 20.1523 20.8461L20.1523 14.0769C20.1523 11.273 17.8793 8.99999 15.0754 8.99999C12.2715 8.99999 9.99851 11.273 9.99851 14.0769L9.99851 31Z"
        fill="#2EFFE7"
        fill-opacity="0.498"
      />
      <path
        d="M10 31C10 25.4771 14.4772 21 20 21H26.6667C29.4281 21 31.6667 23.2385 31.6667 26C31.6667 28.7614 29.4281 31 26.6667 31H10Z"
        fill="#2EFFE7"
        fill-opacity="0.498"
      />
    </svg>
  );
}

function SlackbotSVG() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.66667C0 2.98477 2.98477 0 6.66667 0H33.3333C37.0152 0 40 2.98477 40 6.66667V33.3333C40 37.0152 37.0152 40 33.3333 40H6.66667C2.98477 40 0 37.0152 0 33.3333V6.66667Z"
        fill="#FCEFFC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.1579 16.2105C20.1579 17.6639 21.3361 18.8421 22.7895 18.8421C24.2429 18.8421 25.4211 17.6639 25.4211 16.2105V9.63158C25.4211 8.1782 24.2429 7 22.7895 7C21.3361 7 20.1579 8.1782 20.1579 9.63158V16.2105ZM9.63158 13.5789C8.1782 13.5789 7 14.7571 7 16.2105C7 17.6639 8.1782 18.8421 9.63158 18.8421H16.2105C17.6639 18.8421 18.8421 17.6639 18.8421 16.2105C18.8421 14.7571 17.6639 13.5789 16.2105 13.5789H9.63158ZM22.7895 20.1579C21.3361 20.1579 20.1579 21.3361 20.1579 22.7895C20.1579 24.2429 21.3361 25.4211 22.7895 25.4211H29.3684C30.8218 25.4211 32 24.2429 32 22.7895C32 21.3361 30.8218 20.1579 29.3684 20.1579H22.7895ZM16.2105 32C14.7571 32 13.5789 30.8218 13.5789 29.3684V22.7895C13.5789 21.3361 14.7571 20.1579 16.2105 20.1579C17.6639 20.1579 18.8421 21.3361 18.8421 22.7895V29.3684C18.8421 30.8218 17.6639 32 16.2105 32ZM13.5789 9.63158C13.5789 8.1782 14.7571 7 16.2105 7C17.6639 7 18.8421 8.1782 18.8421 9.63158V12.2632H16.2105C14.7571 12.2632 13.5789 11.085 13.5789 9.63158ZM7 22.7895C7 24.2429 8.1782 25.4211 9.63158 25.4211C11.085 25.4211 12.2632 24.2429 12.2632 22.7895V20.1579H9.63158C8.1782 20.1579 7 21.3361 7 22.7895ZM25.4211 29.3684C25.4211 30.8218 24.2429 32 22.7895 32C21.3361 32 20.1579 30.8218 20.1579 29.3684V26.7368H22.7895C24.2429 26.7368 25.4211 27.915 25.4211 29.3684ZM32 16.2105C32 14.7571 30.8218 13.5789 29.3684 13.5789C27.915 13.5789 26.7368 14.7571 26.7368 16.2105V18.8421H29.3684C30.8218 18.8421 32 17.6639 32 16.2105Z"
        fill="#CF91D8"
      />
    </svg>
  );
}

function DarkSlackbotSVG() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.66667C0 2.98477 2.98477 0 6.66667 0H33.3333C37.0152 0 40 2.98477 40 6.66667V33.3333C40 37.0152 37.0152 40 33.3333 40H6.66667C2.98477 40 0 37.0152 0 33.3333V6.66667Z"
        fill="#341B34"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.1579 16.2105C20.1579 17.6639 21.3361 18.8421 22.7895 18.8421C24.2429 18.8421 25.4211 17.6639 25.4211 16.2105V9.63158C25.4211 8.1782 24.2429 7 22.7895 7C21.3361 7 20.1579 8.1782 20.1579 9.63158V16.2105ZM9.63158 13.5789C8.1782 13.5789 7 14.7571 7 16.2105C7 17.6639 8.1782 18.8421 9.63158 18.8421H16.2105C17.6639 18.8421 18.8421 17.6639 18.8421 16.2105C18.8421 14.7571 17.6639 13.5789 16.2105 13.5789H9.63158ZM22.7895 20.1579C21.3361 20.1579 20.1579 21.3361 20.1579 22.7895C20.1579 24.2429 21.3361 25.4211 22.7895 25.4211H29.3684C30.8218 25.4211 32 24.2429 32 22.7895C32 21.3361 30.8218 20.1579 29.3684 20.1579H22.7895ZM16.2105 32C14.7571 32 13.5789 30.8218 13.5789 29.3684V22.7895C13.5789 21.3361 14.7571 20.1579 16.2105 20.1579C17.6639 20.1579 18.8421 21.3361 18.8421 22.7895V29.3684C18.8421 30.8218 17.6639 32 16.2105 32ZM13.5789 9.63158C13.5789 8.1782 14.7571 7 16.2105 7C17.6639 7 18.8421 8.1782 18.8421 9.63158V12.2632H16.2105C14.7571 12.2632 13.5789 11.085 13.5789 9.63158ZM7 22.7895C7 24.2429 8.1782 25.4211 9.63158 25.4211C11.085 25.4211 12.2632 24.2429 12.2632 22.7895V20.1579H9.63158C8.1782 20.1579 7 21.3361 7 22.7895ZM25.4211 29.3684C25.4211 30.8218 24.2429 32 22.7895 32C21.3361 32 20.1579 30.8218 20.1579 29.3684V26.7368H22.7895C24.2429 26.7368 25.4211 27.915 25.4211 29.3684ZM32 16.2105C32 14.7571 30.8218 13.5789 29.3684 13.5789C27.915 13.5789 26.7368 14.7571 26.7368 16.2105V18.8421H29.3684C30.8218 18.8421 32 17.6639 32 16.2105Z"
        fill="#9E49AB"
      />
    </svg>
  );
}

const simulatorChannelToIcon: Record<
  SimulatorChannel,
  { light: ReactNode; dark: ReactNode }
> = {
  helpcenter: { light: <HelpCenterSVG />, dark: <DarkHelpCenterSVG /> },
  autonomous_ticket_event: { light: <TicketsSVG />, dark: <DarkTicketsSVG /> },
  autonomous_quackchat: { light: <LiveChatSVG />, dark: <DarkLiveChatSVG /> },
  slackbot: { light: <SlackbotSVG />, dark: <DarkSlackbotSVG /> },
  copilot: { light: <CopilotSVG />, dark: <DarkCopilotSVG /> },
};

interface SimulatorChannelIconProps {
  channel: SimulatorChannel;
  size?: "1" | "2" | "3" | "4" | "5";
}

export function SimulatorChannelIcon({
  channel,
  size = "2",
}: SimulatorChannelIconProps) {
  const { isDarkMode } = useTheme();
  const svg = simulatorChannelToIcon[channel][isDarkMode ? "dark" : "light"];

  return (
    <span className={cx(styles.SimulatorChannelIcon, styles[`size-${size}`])}>
      {svg}
    </span>
  );
}
