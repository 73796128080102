import cx from "classnames";
import { Button, Table } from "@radix-ui/themes";
import { EmptyCell } from "../../explore/CellGenerator";
import { TicketScore } from "../model";
import exploreStyles from "../../explore/styles.module.scss";
import styles from "../styles.module.scss";
import { BackAndForth, QAScore, TTR } from "../../explore/cells";
import { TicketExploreRow } from "@/api/useExplore/Explore.model";

const SharedReportTicketScoreTable = ({
  data,
  onTicketClick,
}: {
  data: TicketScore[];
  onTicketClick: (ticket: TicketScore) => void;
}) => {
  return (
    <Table.Root className={styles.ticketScoreTable}>
      <Table.Header className={exploreStyles.tableHeader}>
        <Table.Row className={exploreStyles.theader}>
          <Table.ColumnHeaderCell
            className={cx(exploreStyles.thcell, styles.ticketScoreTableHeader)}
          >
            Ticket
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell
            className={cx(exploreStyles.thcell, styles.ticketScoreTableHeader)}
            width={"120px"}
          >
            TTR
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell
            className={cx(exploreStyles.thcell, styles.ticketScoreTableHeader)}
            width={"90px"}
          >
            B&F
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell
            className={cx(exploreStyles.thcell, styles.ticketScoreTableHeader)}
            width={"120px"}
          >
            Avg. QA Score
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell
            className={cx(exploreStyles.thcell, styles.ticketScoreTableHeader)}
            width={"120px"}
          >
            CSAT
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell
            className={cx(exploreStyles.thcell, styles.ticketScoreTableHeader)}
            width={"120px"}
          >
            Show More
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body className={exploreStyles.tbody}>
        {data.map((ticket) => {
          return (
            <Table.Row>
              <Table.Cell>
                <div>{ticket.subject}</div>

                <div className={styles.ticketNo}>
                  {`#${ticket.id?.split("::")[1]}`}
                </div>
              </Table.Cell>

              <Table.Cell>
                {ticket.ttrMinutes == null ? (
                  <EmptyCell />
                ) : (
                  <TTR value={ticket.ttrMinutes} />
                )}
              </Table.Cell>

              <Table.Cell>
                {ticket.backAndForth == null ? (
                  <EmptyCell />
                ) : (
                  <BackAndForth value={ticket.backAndForth} />
                )}
              </Table.Cell>

              <Table.Cell>
                {ticket.avgQaScore == null ? (
                  <EmptyCell />
                ) : (
                  <QAScore value={ticket.avgQaScore} />
                )}
              </Table.Cell>

              <Table.Cell>
                {ticket.csat?.score == null ? (
                  <EmptyCell />
                ) : (
                  <QAScore value={ticket.csat?.score} />
                )}
              </Table.Cell>
              <Table.Cell>
                <Button
                  color="gray"
                  size="1"
                  className={styles.Btn}
                  onClick={() => {
                    onTicketClick(ticket);
                  }}
                >
                  See more
                </Button>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table.Root>
  );
};

export default SharedReportTicketScoreTable;
