"use client";

import * as React from "react";
import { addDays, format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";
import { DateRange as QuackDateRange } from "./DateRangePicker";
import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@radix-ui/themes";
import { useEffect } from "react";
import { useTheme } from "@/components/Theme";

export function DatePickerWithRange({
  className,
  dateFrom,
  dateTo,
  setDateRange,
}: React.HTMLAttributes<HTMLDivElement> & {
  dateFrom: string;
  dateTo: string;
  setDateRange: (newDateRange: QuackDateRange) => void;
}) {
  const { isDarkMode } = useTheme();

  const [date, setDate] = React.useState<DateRange | undefined>({
    from: new Date(2022, 0, 20),
    to: addDays(new Date(2022, 0, 20), 20),
  });

  useEffect(() => {
    setDate({
      from: new Date(dateFrom),
      to: new Date(dateTo),
    });
  }, [JSON.stringify({ dateFrom, dateTo })]);

  const handleSetDates = (v: DateRange | undefined) => {
    if (v?.from && v?.to) {
      setDateRange({
        dateFrom: v.from.toISOString(),
        dateTo: v.to!.toISOString(),
      });
    }
    // im here
    setDate(v);
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            color="gray"
            highContrast
            variant={"ghost"}
            className={cn(
              " justify-start text-left font-medium gap-2 py-1 px-2 text-xs ",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon size={"14px"} />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd")} - {format(date.to, "LLL dd")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className={`w-auto p-0 ${isDarkMode ? "dark" : "light"}`}
          align="start"
        >
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={handleSetDates}
            numberOfMonths={1}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
