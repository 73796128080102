import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SimulatorChannel } from "@/api/simulator/simulator.model";
import { useSimulatorConfiguration } from "./useSimulatorConfiguration";

const configurationViews = [
  "general",
  "end_of_conversation",
  "escalations",
  "install",
  "appearance",
] as const;
export type ConfigurationView = (typeof configurationViews)[number];

export const configurationViewToLabel: Record<ConfigurationView, string> = {
  general: "General",
  end_of_conversation: "End of Conversation",
  escalations: "Escalations",
  appearance: "Appearance",
  install: "Install",
};

const supportedChannels: SimulatorChannel[] = [
  "autonomous_ticket_event",
  "autonomous_quackchat",
];

export function isChannelSupported(channel?: SimulatorChannel) {
  return channel != null && supportedChannels.includes(channel);
}

const channelConfigurationViews: Record<
  SimulatorChannel,
  ConfigurationView[] | null
> = {
  autonomous_ticket_event: ["general", "escalations", "install"],
  autonomous_quackchat: [
    "general",
    "end_of_conversation",
    "appearance",
    "install",
  ],
  helpcenter: null,
  slackbot: null,
  copilot: null,
};

export const automationConfigurationUris = {
  root: "/automation/:simulatorId/configurations",
  general: "general",
  end_of_conversation: "end-of-conversation",
  escalations: "escalations",
  install: "install",
  appearance: "appearance",
  getRootUri: (simulatorId: number) =>
    `/automation/${simulatorId}/configurations`,
};

export const configurationViewToUri: Record<ConfigurationView, string> = {
  general: automationConfigurationUris.general,
  end_of_conversation: automationConfigurationUris.end_of_conversation,
  escalations: automationConfigurationUris.escalations,
  appearance: automationConfigurationUris.appearance,
  install: automationConfigurationUris.install,
};

export function useGetChannelConfigurationViews(): {
  channel?: SimulatorChannel;
  views: ConfigurationView[];
} {
  const navigate = useNavigate();
  const { simulatorId } = useParams();
  const { simulator, loadingSimulator } =
    useSimulatorConfiguration(simulatorId);

  return useMemo(() => {
    if (loadingSimulator || simulator == null) {
      return { views: [] };
    }

    if (simulator.channel == null || !isChannelSupported(simulator.channel)) {
      navigate(`/`);
      return { views: [] };
    }

    const views = channelConfigurationViews[simulator.channel] ?? [];
    return { channel: simulator.channel, views };
  }, [navigate, loadingSimulator, simulator?.channel]);
}
