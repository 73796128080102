export function ExploreInsightsSVG() {
  return (
    <svg
      width="380"
      height="170"
      viewBox="0 0 380 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4590_59651)">
        <g filter="url(#filter0_f_4590_59651)">
          <g clip-path="url(#clip1_4590_59651)">
            <path
              d="M0.0947266 155.465C27.5778 155.465 34.9067 100.007 62.0233 100.007C89.14 100.007 82.1776 57.347 109.661 57.347C137.144 57.347 111.127 103.617 161.329 103.617C211.532 103.617 192.477 42.9082 229.121 42.9082C249.512 42.9082 256.935 111.299 288.789 135.768C314.18 155.271 364.715 131.987 379.831 131.987"
              stroke="#F6F0EC"
              stroke-width="1.2725"
              stroke-linecap="round"
            />
            <path
              d="M62.0233 100.007C37.2252 100.007 28.9757 146.387 6.72713 154.318C3.21088 155.572 0.0947266 158.491 0.0947266 162.224C0.0947266 165.957 3.1209 168.984 6.85388 168.984H371.831C376.249 168.984 379.831 165.402 379.831 160.984V139.987C379.831 135.569 376.258 132.163 371.939 133.094C351.552 137.485 310.758 152.643 288.789 135.768C256.935 111.299 249.512 42.9082 229.121 42.9082C192.477 42.9082 211.532 103.617 161.329 103.617C111.127 103.617 137.144 57.347 109.661 57.347C82.1776 57.347 89.14 100.007 62.0233 100.007Z"
              fill="url(#paint0_linear_4590_59651)"
              fill-opacity="0.1"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_4590_59651"
          x="-0.905273"
          y="-0.796204"
          width="382"
          height="169.97"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.5"
            result="effect1_foregroundBlur_4590_59651"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4590_59651"
          x1="0.0947258"
          y1="168.984"
          x2="385.633"
          y2="88.8254"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3EDDDD" />
          <stop offset="1" stop-color="#F9D40F" />
        </linearGradient>
        <clipPath id="clip0_4590_59651">
          <rect width="380" height="170" fill="white" />
        </clipPath>
        <clipPath id="clip1_4590_59651">
          <rect
            x="0.0947266"
            y="0.203796"
            width="380"
            height="167.97"
            rx="1.2725"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function DarkExploreInsightsSVG() {
  return (
    <svg
      width="380"
      height="170"
      viewBox="0 0 380 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5032_84724)">
        <g filter="url(#filter0_f_5032_84724)">
          <g clip-path="url(#clip1_5032_84724)">
            <path
              d="M0.09375 155.465C27.5769 155.465 34.9057 100.007 62.0224 100.007C89.139 100.007 82.1767 57.347 109.66 57.347C137.143 57.347 111.126 103.617 161.328 103.617C211.531 103.617 192.476 42.9082 229.12 42.9082C249.511 42.9082 256.934 111.299 288.788 135.768C314.179 155.271 364.714 131.987 379.83 131.987"
              stroke="#F6F0EC"
              stroke-width="1.2725"
              stroke-linecap="round"
            />
            <path
              d="M62.0224 100.007C37.2242 100.007 28.9748 146.387 6.72615 154.318C3.20991 155.572 0.09375 158.491 0.09375 162.224C0.09375 165.957 3.11993 168.984 6.8529 168.984H371.83C376.248 168.984 379.83 165.402 379.83 160.984V139.987C379.83 135.569 376.257 132.163 371.938 133.094C351.551 137.485 310.757 152.643 288.788 135.768C256.934 111.299 249.511 42.9082 229.12 42.9082C192.476 42.9082 211.531 103.617 161.328 103.617C111.126 103.617 137.143 57.347 109.66 57.347C82.1767 57.347 89.139 100.007 62.0224 100.007Z"
              fill="url(#paint0_linear_5032_84724)"
              fill-opacity="0.1"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_5032_84724"
          x="-0.90625"
          y="-0.796204"
          width="382"
          height="169.97"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.5"
            result="effect1_foregroundBlur_5032_84724"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5032_84724"
          x1="0.0937492"
          y1="168.984"
          x2="385.632"
          y2="88.8254"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3EDDDD" />
          <stop offset="1" stop-color="#F9D40F" />
        </linearGradient>
        <clipPath id="clip0_5032_84724">
          <rect width="380" height="170" fill="white" />
        </clipPath>
        <clipPath id="clip1_5032_84724">
          <rect
            x="0.09375"
            y="0.203796"
            width="380"
            height="167.97"
            rx="1.2725"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
