import { useCallback, useContext } from "react";
import dayjs from "dayjs";
import { Button, DropdownMenu, Separator } from "@radix-ui/themes";
import { ThemeContext } from "@/components/Theme";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { DatePickerWithRange } from "./picker";
import styles from "./DateRangePicker.module.scss";

export type DateRange = { dateFrom: string; dateTo: string };

export const dateRangePresets = [
  "today",
  "1d",
  "7d",
  "14d",
  "1m",
  "2m",
] as const;
export type DateRangePreset = (typeof dateRangePresets)[number];

export function isDateRangePreset(obj: any): obj is DateRangePreset {
  return dateRangePresets.includes(obj);
}

const dateRangePresetToLabel: Record<DateRangePreset, string> = {
  today: "Today",
  "1d": "Yesterday",
  "7d": "Last 7 days",
  "14d": "Last 14 days",
  "1m": "Last 1 month",
  "2m": "Last 2 months",
};

export const buildRange: Record<DateRangePreset, () => DateRange> = {
  today: () => ({
    dateFrom: dayjs().startOf("day").toISOString(),
    dateTo: dayjs().toISOString(),
  }),
  "1d": () => ({
    dateFrom: dayjs().startOf("day").subtract(1, "day").toISOString(),
    dateTo: dayjs().endOf("day").subtract(1, "day").toISOString(),
  }),
  "7d": () => ({
    dateFrom: dayjs().subtract(7, "day").toISOString(),
    dateTo: dayjs().toISOString(),
  }),
  "14d": () => ({
    dateFrom: dayjs().subtract(14, "day").toISOString(),
    dateTo: dayjs().toISOString(),
  }),
  "1m": () => ({
    dateFrom: dayjs().subtract(1, "month").toISOString(),
    dateTo: dayjs().toISOString(),
  }),
  "2m": () => ({
    dateFrom: dayjs().subtract(2, "month").toISOString(),
    dateTo: dayjs().toISOString(),
  }),
};

interface DateRangePickerProps {
  value: DateRange;
  onChange: (newDateRange: DateRange) => void;
  hardBackground?: boolean;
}

export function DateRangePicker({
  value,
  onChange,
  hardBackground = false,
}: DateRangePickerProps) {
  const { dateFrom, dateTo } = value;
  const { isDarkMode } = useContext(ThemeContext);

  const getDateRangeLabel = useCallback(
    (dateFrom: string, dateTo: string): string => {
      const preset = dateRangePresets.find((preset) => {
        const range = buildRange[preset]();

        return (
          dayjs(range.dateFrom).format("YYYY-MM-DD") ===
            dayjs(dateFrom).format("YYYY-MM-DD") &&
          dayjs(range.dateTo).format("YYYY-MM-DD") ===
            dayjs(dateTo).format("YYYY-MM-DD")
        );
      });

      return preset ? dateRangePresetToLabel[preset] : "Custom";
    },
    []
  );

  return (
    <>
      <DropdownMenu.Root>
        <div
          className={
            (styles.btnWithBorder,
            ` flex items-center gap-0 border rounded-md pr-2 ${
              hardBackground ? (isDarkMode ? "bg-black" : "bg-white") : ""
            } `)
          }
        >
          <DropdownMenu.Trigger>
            <Button
              color={"gray"}
              variant="soft"
              size="1"
              highContrast
              className=" border-0 border-r-2"
            >
              {getDateRangeLabel(dateFrom, dateTo)}

              <ChevronDownIcon />
            </Button>
          </DropdownMenu.Trigger>
          <Separator orientation="vertical" className={styles.seperator} />
          <DatePickerWithRange
            className=" border-0"
            {...{ dateFrom, dateTo, setDateRange: onChange }}
          />
        </div>

        <DropdownMenu.Content variant="soft" color="gray">
          {dateRangePresets.map((item) => (
            <DropdownMenu.Item
              shortcut=""
              onClick={() => onChange(buildRange[item]())}
            >
              {dateRangePresetToLabel[item]}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  );
}
