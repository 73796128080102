import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import cx from "classnames";
//@ts-ignore
import confetti from "canvas-confetti";
import { Message } from "ai/react";
import { Text } from "@geist-ui/core";
import { Tabs, Badge, Kbd, Button, Spinner, Tooltip } from "@radix-ui/themes";
import {
  ArrowUp,
  CornerDownRight,
  Plus,
  ThumbsDown,
  ThumbsUp,
  X,
} from "@geist-ui/icons";
import { IoSparkles } from "react-icons/io5";
import { InputType, SimulatorInput } from "../input";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";

import { Suggestions } from "../suggestions";
import styles from "./styles.module.scss";
import { Messages } from "../messages";
import { useInstructions } from "../context/instructions";
import { useGetTopics } from "../../../api/useGetTopics";
import { useReleases } from "../context/release";
import { ReleaseConversationFeedback } from "../types";
import { PendingReview } from "../pendingReview";
import { useWorkspace } from "../context/wrokspace";
import { useExploreTickets } from "../../../api/useExplore";
import { useChangeStatusInteractionInTraining } from "../../../api/training";
import { useGetInteractionInTraining } from "../../../api/training";
import {
  InteractionInTrainingStatus,
  InteractionType,
} from "../../simulator-live-mode/types";
import {
  ConversationInTraining,
  InteractionInTrainingTopic,
  TicketInTraining,
} from "../pendingReview/types";
import { mixTrackEvent, SimulatorEvents } from "@/assets/mixpanel";
import {
  useBadFeedback,
  useFetchTopics,
  usePositiveFeedback,
  useSimulatorChat,
} from "./api";
import { Loader } from "@/components/loader";

const useDelayedEffect = (callback: () => void, delay: number, deps: any[]) => {
  useEffect(() => {
    const handler = setTimeout(() => callback(), delay);

    return () => clearTimeout(handler);
  }, [...deps, delay]);
};

export const Chat = ({
  topic,
  setTopic,
  isTraining,
  setTraining,
  selectedValue,
  setSelectedValue,
  channel,
  setChannel,
  tenantGroup,
  setTenantGroup,
}: {
  topic: string | null;
  setTopic: Dispatch<SetStateAction<string | null>>;
  isTraining: boolean;
  setTraining: Dispatch<SetStateAction<boolean>>;
  selectedValue: string;
  setSelectedValue: Dispatch<SetStateAction<string>>;
  channel: string;
  setChannel: Dispatch<SetStateAction<string>>;
  tenantGroup: string[];
  setTenantGroup: Dispatch<SetStateAction<string[]>>;
}) => {
  const [messagesSources, setMessagesSources] = useState<{
    [key: string]: unknown;
  }>({});
  const [id, setId] = useState(0);
  const [isOptionsOpen, setOptionsOpen] = useState(false);
  const isInstructionChanged = useRef<boolean>(false);
  const { workspace } = useWorkspace();

  const [isRedrafting, setRedrafting] = useState(false);

  const [isShadow, setShadow] = useState(false);
  const [openTickets, setOpenTickets] = useState<{ description: string }[]>([]);
  const [index, setIndex] = useState(0);
  const [isBriefOpen, setBriefOpen] = useState(false);
  const [isGenerationInProcess, setGenerationInProcess] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<string | null>(null);
  const searchParams = new URLSearchParams(window.location.search);
  const fParam = searchParams.get("f");

  const { user } = useAuth0();

  const conversationIdRef = useRef<number>(null);
  const topicIdRef = useRef<number>(null);

  const { handleUpdateConversation, conversationId, releaseId, simulator } =
    useReleases();
  const { mutateAsync: fetchExploreTickets } = useExploreTickets();

  useEffect(() => {
    (conversationIdRef as React.MutableRefObject<number | null>).current =
      conversationId;
  }, [conversationId]);

  // should create conversation
  // should create new release

  const [subTopic] = useState(null);
  const [options, setOptions] = useState<string[]>([]);

  const [textareaValue, setTextareaValue] = useState("");
  const { data: topics } = useGetTopics(!!user);
  const [isLoading, setLoading] = useState(false);
  const [type, __setType] = useState<InputType>("none");

  const setType = (newType: InputType) => {
    __setType(newType);
  };

  const {
    instructions,
    handleInstruction,
    handleInstructionsOptions,
    simulatorInstructions,
    briefs,
  } = useInstructions();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const trackingData = {
    source: "simulator-autopilot",
    channel: simulator?.channel,
    topic,
    subTopic,
  };

  const [interactionInTraining, setInteractionInTraining] = useState<{
    interactionInTrainingId: number;
    interactionType: InteractionType;
  } | null>(null);
  const { data: interactionsInTrainingData } = useGetInteractionInTraining(
    topic ?? "",
    !!topic
  );
  const interactionsInTraining =
    interactionsInTrainingData as InteractionInTrainingTopic;
  const { mutateAsync: changeStatusInteractionInTraining } =
    useChangeStatusInteractionInTraining();

  // use tickets filter by status slice by topic

  useDelayedEffect(
    () => {
      if (
        textareaValue &&
        isInstructionChanged.current &&
        type !== "feedback"
      ) {
        handleInstructionsOptions(textareaValue).then((res) => {
          setOptions(res);
          setOptionsOpen(true);
          isInstructionChanged.current = false;
        });
      }
      // You can add any additional logic here that should run after the delay
    },
    900,
    [textareaValue]
  );

  const handleSuccessFeedback = async () => {
    handleResetConversation(ReleaseConversationFeedback.POSITIVE);
    confetti({
      particleCount: 100,
      spread: 100,
      origin: { y: 0.5, x: 0.4 },
    });
    handleUpdateInteractionInTrainingStatus();
  };

  const handleUpdateInteractionInTrainingStatus = async () => {
    if (!interactionInTraining) return;

    const isInteractionInTraining =
      interactionInTraining?.interactionType === InteractionType.CONVERSATION
        ? interactionsInTraining?.conversations.find(
            (interaction: ConversationInTraining) =>
              interaction.conversationInTrainingId ===
                Number(interactionInTraining.interactionInTrainingId) &&
              interaction.status === InteractionInTrainingStatus.PENDING
          )?.conversationInTrainingId
        : interactionsInTraining?.tickets.find(
            (interaction: TicketInTraining) =>
              interaction.ticketInTrainingId ===
                Number(interactionInTraining.interactionInTrainingId) &&
              interaction.status === InteractionInTrainingStatus.PENDING
          )?.ticketInTrainingId;

    if (isInteractionInTraining) {
      await changeStatusInteractionInTraining({
        interactionInTrainingId: isInteractionInTraining,
        interactionType: interactionInTraining.interactionType,
        status: InteractionInTrainingStatus.COMPLETED,
      });
    }
  };

  const { mutateAsync: fetchTopics } = useFetchTopics();

  const handleTopicAssignment = async (question: string) => {
    const data = await fetchTopics({
      question: question,
      topics: topics
        ?.map((topic: { topic: string }) => topic.topic)
        .filter((topic: string) => topic.toLowerCase() !== "other"),
    });

    const topicId = topics?.filter(
      (topic: { topic: string; id: number }) =>
        topic.topic.replace(/['"`*]/g, "").trim() ===
        data.text.replace(/['"`*]/g, "").trim()
    )?.[0]?.id;

    setTopic(data.text.replace(/['"`*]/g, "").trim());

    (topicIdRef as React.MutableRefObject<number | null>).current = topicId;

    handleUpdateConversation({
      message: { role: "user", content: question },
      feedback: ReleaseConversationFeedback.NONE,
      topicId: topicId ?? 0,
      localConversationId: conversationIdRef.current ?? 0,
    });

    return data.text.replace(/['"`*]/g, "").trim();
  };

  const handleFormSubmission = async (e: React.FormEvent<HTMLFormElement>) => {
    switch (type) {
      case "none":
        setGenerationInProcess(true);
        e.preventDefault();
        if (messages.length === 0) {
          await handleTopicAssignment(textareaRef?.current?.value ?? "");
        } else {
          // should update
          handleUpdateConversation({
            message: {
              role: "user",
              content: textareaRef?.current?.value ?? "",
            },
            feedback: ReleaseConversationFeedback.NONE,
            topicId: topicIdRef.current ?? 0,
            localConversationId: conversationId ?? 0,
          });
        }

        setLoading(true);
        const messageContent = textareaRef?.current?.value ?? "";
        mixTrackEvent({
          event: SimulatorEvents.TRAINING_INQUIRY_SELF_CREATED,
          properties: {
            ...trackingData,
            messageLength: messageContent.length,
            isFirstMessage: messages.length === 0,
          },
        });
        handleSubmit(e);
        break;
      case "instruction":
        const inputValue = textareaRef?.current?.value ?? "";

        mixTrackEvent({
          event: SimulatorEvents.SUBMIT_INSTRUCTION,
          properties: {
            ...trackingData,
            instructionLength: inputValue.length,
          },
        });

        handleInstruction({
          topic,
          subTopic,
          content: inputValue,
          type: "manual",
        });
        e.preventDefault();

        setTimeout(() => {
          setTextareaValue("");
          setType("none");
          textareaRef!.current!.style.height = "24px";
        }, 300);

        break;
      case "feedback":
        e.preventDefault();
        handleResetConversation(
          ReleaseConversationFeedback.NEGATIVE,
          textareaRef?.current?.value ?? ""
        );

        setTimeout(() => {
          setTextareaValue("");
          setType("none");
          textareaRef!.current!.style.height = "24px";
        }, 300);

        break;
    }
  };

  const handleResponse = async (response: Message) => {
    setMessagesSources((prev) => {
      return {
        ...prev,
        [response.id]: response?.annotations?.[0],
      };
    });

    setLoading(false);
    setGenerationInProcess(false);
    const firstTool = response.toolInvocations?.[0];

    handleUpdateConversation({
      localConversationId: conversationIdRef.current ?? 0,
      message: {
        role: "assistant",
        content:
          response.content ||
          (firstTool?.state === "result" ? firstTool.result : "__"),
      },
      feedback: ReleaseConversationFeedback.NONE,
      topicId: topicIdRef.current ?? 0,
    });
  };

  const {
    messages,
    input,
    handleInputChange,
    handleSubmit,
    append,
    reload,
    setMessages,
  } = useSimulatorChat({
    id: String(id),
    isTraining,
    instructions: isTraining ? instructions : simulatorInstructions,
    topic: topic ?? "",
    subTopic: subTopic ?? "",
    handleResponse,
  });

  useEffect(() => {
    reload();
  }, [JSON.stringify(briefs)]);

  const { mutateAsync: mutateBadFeedback } = useBadFeedback({
    isTraining,
  });

  const handleBadFeedback = async ({
    badFeedback,
    instructions,
    topic,
    subTopic,
    messages,
    setLoading,
  }: {
    messages: Message[];
  } & any): Promise<string> => {
    setLoading(true);
    setGenerationInProcess(true);

    const res = await mutateBadFeedback({
      tenant: workspace ?? "",
      instructions: [
        ...instructions,
        {
          topic,
          subTopic,
          type: "manual",
          content: `
        The last response was:
        ${messages[messages.length - 1].content}
  
        this response isn't good enough
        fix by following feedback:
        ${badFeedback}
  
      `,
        },
      ],
      topic,
      subTopic,
      messages: messages.slice(0, -1),
    });
    setLoading(false);
    setGenerationInProcess(false);
    return res.text;
  };

  const { mutateAsync: handlePositiveFeedback } = usePositiveFeedback();

  const handleResetConversation = async (
    feedback?:
      | ReleaseConversationFeedback.NEGATIVE
      | ReleaseConversationFeedback.POSITIVE,
    feedbackComment?: string
  ) => {
    // here should fire a create conversation
    if (feedback === ReleaseConversationFeedback.NEGATIVE && feedbackComment) {
      // we block the reset conversation
      handleBadFeedback({
        tenant: workspace ?? "",
        instructions: isTraining ? instructions : simulatorInstructions,
        topic,
        subTopic,
        messages,
        badFeedback: feedbackComment ?? "",
        setLoading: setRedrafting,
      }).then((res) => {
        mixTrackEvent({
          event: SimulatorEvents.BAD_FEEDBACK,
          properties: {
            ...trackingData,
            conversationLength: messages.length,
            hasComment: !!feedbackComment,
          },
        });

        handleUpdateConversation({
          localConversationId: conversationIdRef.current ?? 0,
          message: {
            role: "assistant",
            content: res,
          },
          topicId: topicIdRef.current ?? 0,
          feedback: ReleaseConversationFeedback.NONE,
          feedbackComment: "",
        });

        setMessages([
          ...messages.slice(0, -1),
          { id: "1241241", content: res, role: "assistant" },
        ]);
        handleUpdateInteractionInTrainingStatus();
      });
    } else {
      if (feedback === ReleaseConversationFeedback.POSITIVE) {
        await handlePositiveFeedback({
          messages,
          id: conversationId,
          tenant: workspace ?? "",
        });
        mixTrackEvent({
          event: SimulatorEvents.GOOD_FEEDBACK,
          properties: {
            ...trackingData,
            conversationLength: messages.length,
            hasComment: !!feedbackComment,
          },
        });
        handleUpdateConversation({
          localConversationId: conversationIdRef.current ?? 0,
          message: {
            role: "user",
            content: "--reset converesation--",
          },
          topicId: topicIdRef.current ?? 0,
          feedback: feedback ?? ReleaseConversationFeedback.NONE,
          feedbackComment: feedbackComment ?? "",
        });
        // should update the conversation
        (topicIdRef as { current: number | null }).current = null;
        (conversationIdRef as { current: number | null }).current = null;
        setTopic(null);
        setId(dayjs().valueOf());
      } else {
        handleUpdateConversation({
          localConversationId: conversationIdRef.current ?? 0,
          message: {
            role: "user",
            content: "--reset converesation--",
          },
          topicId: topicIdRef.current ?? 0,
          feedback: feedback ?? ReleaseConversationFeedback.NONE,
          feedbackComment: feedbackComment ?? "",
        });
        // should update the conversation
        (topicIdRef as { current: number | null }).current = null;
        (conversationIdRef as { current: number | null }).current = null;
        setTopic(null);
        setId(dayjs().valueOf());
      }
    }
  };

  const handleNegativeFeedback = () => {
    setType("feedback");
  };

  const [inputValue, handleInputValueChange] =
    type === "none"
      ? [input, handleInputChange]
      : [
          textareaValue,
          (
            e:
              | React.ChangeEvent<HTMLInputElement>
              | React.ChangeEvent<HTMLTextAreaElement>
          ) => {
            isInstructionChanged.current = true;
            setTextareaValue(e.target.value);
          },
        ];

  const handleAddMessage = async (message: string) => {
    setLoading(true);
    await handleTopicAssignment(message);
    append({ content: message, role: "user" });

    mixTrackEvent({
      event: SimulatorEvents.TRAINING_INQUIRY_OPENED,
      properties: {
        ...trackingData,
        messageLength: message.length,
        isFirstMessage: messages.length === 0,
      },
    });
  };

  const handleEditMessage = (message: string) => {
    handleUpdateConversation({
      localConversationId: conversationIdRef.current ?? 0,
      message: {
        role: "assistant",
        content: message,
      },
      topicId: topicIdRef.current ?? 0,
      feedback: ReleaseConversationFeedback.NONE,
      feedbackComment: "",
    });

    setMessages((prev: Message[]) => {
      return [
        ...prev.slice(0, -1),
        { content: message, role: "assistant", id: prev[prev.length - 1].id },
      ];
    });
  };

  useEffect(() => {
    handleResetConversation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTraining]);

  const stringify = JSON.stringify(instructions);

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringify]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.topContainer}>
          {messages.length > 0 && (
            <div className={styles.resetButton}>
              <Button
                onClick={() => handleResetConversation()}
                size={"2"}
                color="gray"
                highContrast
                variant="outline"
              >
                <Plus />
                New Session
              </Button>
            </div>
          )}
          <Tabs.Root
            value={isTraining ? "training" : "simulate"}
            onValueChange={(val) => {
              setTraining(val === "training");
            }}
          >
            <Tabs.List>
              <Tabs.Trigger value="training">Training</Tabs.Trigger>
              <Tabs.Trigger value="simulate">Simulate</Tabs.Trigger>
            </Tabs.List>
          </Tabs.Root>
        </div>
        <div className={styles.messagesContainer}>
          {topic && (
            <div className={styles.badgesContainer}>
              <Badge className={styles.primary}>{topic}</Badge>
              {subTopic && <Badge variant="outline">{subTopic}</Badge>}
            </div>
          )}
          {messages.length === 0 && !isLoading ? (
            <>
              <Suggestions
                handleMessage={handleAddMessage}
                {...{
                  selectedValue,
                  setSelectedValue,
                  setSelectedTicket,
                  channel,
                  setChannel,
                  tenantGroup,
                  setTenantGroup,
                }}
              />
              <PendingReview
                topicId={selectedValue}
                handleMessage={handleAddMessage}
                setSelectedTicket={setSelectedTicket}
                setInteractionInTraining={setInteractionInTraining}
              />
            </>
          ) : (
            <Messages
              messages={messages}
              isLoading={isLoading}
              handleEditMessage={handleEditMessage}
              messagesSources={messagesSources}
              trackingData={trackingData}
            />
          )}
        </div>

        {(fParam !== "copilot" ||
          (fParam === "copilot" && selectedTicket !== null)) && (
          <form onSubmit={handleFormSubmission}>
            <div className={styles.inputContainer}>
              <div className={styles.feedbackContainer}>
                {messages.length > 1 && (
                  <>
                    <Text small>Rate the conversation</Text>
                    <div>
                      <Tooltip
                        content={`Teach Quack that this answer is "verified"`}
                      >
                        <button
                          type="button"
                          className={cx(styles.btn, styles.positive)}
                          onClick={handleSuccessFeedback}
                        >
                          <ThumbsUp />
                        </button>
                      </Tooltip>
                      {/* <Tooltip contentx={`Answer the user straight from here`}>
                    <button
                      type="button"
                      className={cx(styles.btn, styles.super)}
                      onClick={handleSuccessFeedback}
                    >
                      <RocketIcon /> Super like
                    </button>
                  </Tooltip> */}
                      <Tooltip
                        content={`Let Quack know what was wrong, or just count its as a bad answer`}
                      >
                        <button
                          type="button"
                          className={cx(styles.btn, styles.negative)}
                          onClick={handleNegativeFeedback}
                          disabled={isRedrafting}
                        >
                          {isRedrafting ? <Spinner /> : <ThumbsDown />}
                        </button>
                      </Tooltip>
                    </div>
                  </>
                )}
              </div>
              <div className={styles.inputFieldContainer}>
                {isOptionsOpen && options.length > 0 && (
                  <div className={styles.optionsContainer}>
                    <div className={styles.optionsHeader}>
                      <div className={styles.iconContainer}>
                        <IoSparkles />
                      </div>
                      <label>
                        Suggested optimized instructions that might perform
                        better
                      </label>
                      <div
                        className={styles.close}
                        onClick={() => setOptionsOpen(false)}
                      >
                        <X />
                      </div>
                    </div>

                    <div className={styles.optionsBody}>
                      {options.map((o, index) => {
                        return (
                          <div
                            className={styles.option}
                            key={o}
                            onClick={() => {
                              setOptionsOpen(false);
                              setTextareaValue(o);
                            }}
                          >
                            {o}

                            <CornerDownRight />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className={styles.input}>
                  <div className={styles.iconContainer}>
                    <IoSparkles />
                  </div>

                  <SimulatorInput
                    ref={textareaRef}
                    type={type}
                    setType={setType}
                    value={inputValue}
                    handleInputChange={handleInputValueChange}
                    messagesLength={messages.length}
                    handleSubmit={() => buttonRef.current?.click()}
                    handleResetConversation={handleResetConversation}
                  />
                </div>
                <div className={styles.submitContainer}>
                  <Button
                    type="submit"
                    ref={buttonRef}
                    loading={isGenerationInProcess}
                  >
                    <ArrowUp />
                  </Button>
                </div>
              </div>
              <div className={styles.bottomBar}>
                <Button
                  type={"button"} // important - otherwise form will submit on click
                  color={"gray"}
                  variant={"ghost"}
                  highContrast
                  size={"1"}
                  className={styles.shortcut}
                  onClick={() => {
                    mixTrackEvent({
                      event: SimulatorEvents.START_INSTRUCTION,
                      properties: trackingData,
                    });
                    setType("instruction");
                  }}
                >
                  <Kbd size={"3"}>/</Kbd>
                  <Text small>to add instructions</Text>
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};
