import { useMemo } from "react";
import { CartesianGrid, Line, LineChart, ReferenceLine, XAxis } from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from "@/components/ui/chart";

export type LineChartItem<T extends string | number | symbol> = {
  month: string;
} & Record<T, number | undefined>;

interface LineChartCompProps<T extends string | number | symbol> {
  config: ChartConfig;
  data: LineChartItem<T>[];
  referenceLine?: { month: string; label: string };
}

export function LineChartComp<T extends string | number | symbol>({
  config,
  data,
  referenceLine,
}: LineChartCompProps<T>) {
  const dataKeys = useMemo(
    () =>
      data.length > 0
        ? Object.keys(data?.[0]).filter((key) => key !== "month")
        : [],
    [data]
  );

  return (
    <ChartContainer config={config} className="w-full">
      <LineChart
        accessibilityLayer
        data={data}
        margin={{
          left: 12,
          right: 12,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="month"
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          tickFormatter={(value) => value.slice(0, 3)}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent hideLabel />}
        />

        {dataKeys.map((key) => {
          const stroke = `var(--color-${key})`;

          return (
            <Line
              dataKey={key}
              type="linear"
              stroke={stroke}
              strokeWidth={2}
              dot={false}
              unit={"minutes"}
            />
          );
        })}

        {referenceLine && (
          <>
            <ReferenceLine
              x={referenceLine.month}
              stroke={`var(--color-referenceLine)`}
              strokeDasharray={"3 3"}
            />

            <Line
              dataKey={"referenceLine"}
              stroke={`var(--color-referenceLine)`}
            />
          </>
        )}

        <ChartLegend content={<ChartLegendContent />} />
      </LineChart>
    </ChartContainer>
  );
}
