import { useRef, useCallback } from "react";

export function useDebouncedCallback<
  ReturnType = void,
  Parameters extends unknown[] = unknown[]
>(callback: (...parameters: Parameters) => ReturnType, wait = 500) {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  return useCallback(
    (...parameters: Parameters) => {
      const later = () => {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
        callback(...parameters);
      };

      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(later, wait);
    },
    [callback, wait]
  );
}
