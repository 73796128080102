import { useCallback, useMemo, useState } from "react";
import { Badge, Flex } from "@radix-ui/themes";
import { DateRange } from "@/components/shared/date-range-picker/DateRangePicker";
import { MetricsScope, metricsScopes } from "@/api/reports/reports.model";
import { useQaKeyMetrics } from "@/api/reports/reports.api";
import ExploreTable, { ColumnType } from "@/routes/explore/ExploreTable";
import WidgetCard from "../../parts/WidgetCard";
import { MetricsScopeToggle } from "../../parts/MetricsScopeToggle";
import {
  QaScoresChart,
  SentimentScoresChart,
  ScoresValue,
} from "../../parts/ScoresChart";
import { getValue } from "@/routes/explore/CellGenerator";
import styles from "../../Reports.module.scss";

type MetricRow = {
  id: string;
  name?: string;
  ticketEvaluatedCount: number;
  scores: ScoresValue;
  sentiments: ScoresValue;
};

function cellGenerator(
  values: MetricRow,
  { key }: ColumnType<keyof MetricRow>
) {
  if (key === "scores") {
    const value = getValue(values, key);
    return <QaScoresChart data={value} />;
  }

  if (key === "sentiments") {
    const value = getValue(values, key);
    return <SentimentScoresChart data={value} />;
  }

  const value = getValue(values, key);
  return <>{value == null ? "-" : value}</>;
}

function cellValueGetter(values: MetricRow, key: keyof MetricRow) {
  if (key === "scores" || key === "sentiments") {
    const value = getValue(values, key);

    const total = value.reduce((acc, { score }) => acc + score, 0);
    return total / value.length;
  }

  const value = getValue(values, key);
  return value;
}

interface QaKeyMetricsWidgetProps {
  dateRange: DateRange;
}

export function QaKeyMetricsWidget({ dateRange }: QaKeyMetricsWidgetProps) {
  const [scope, setScope] = useState<MetricsScope>(metricsScopes[0]);

  const { data, isLoading, isError, refetch } = useQaKeyMetrics({
    ...dateRange,
    scope,
  });

  const [columns, rows] = useMemo(() => {
    const columns: ColumnType<keyof MetricRow>[] = [
      {
        key: "name",
        label: scope === "agents" ? "Agent" : "Team",
        minWidth: "400px",
      },
      { key: "ticketEvaluatedCount", label: "Tickets Vol", width: "160px" },
      { key: "scores", label: "QA Score", width: "160px" },
      { key: "sentiments", label: "Sentiment", width: "160px" },
    ];

    if (data == null) {
      return [columns];
    }

    const rows = Object.entries(data).map(([id, leanDataValue]) => {
      const { name, ticketEvaluatedCount, values } = leanDataValue;

      const scores: ScoresValue = [];
      const sentiments: ScoresValue = [];

      values.forEach(
        ({ date, avgScores, avgSentiment }) => (
          scores.push({ date, score: avgScores }),
          sentiments.push({ date, score: avgSentiment })
        )
      );

      return {
        id,
        name,
        ticketEvaluatedCount,
        scores,
        sentiments,
      };
    });

    return [columns, rows];
  }, [scope, data]);

  const handleReload = useCallback(() => {
    void refetch();
  }, []);

  return (
    <WidgetCard fullWidth>
      <Flex justify={"between"} align={"start"}>
        <WidgetCard.Header>
          <WidgetCard.Title value={"Key Metrics Snapshot"} />
          <WidgetCard.Description
            value={"Evaluation across key support metrics"}
          />
        </WidgetCard.Header>

        <Flex p={"5"}>
          <MetricsScopeToggle value={scope} onChange={setScope} />
        </Flex>
      </Flex>

      <WidgetCard.Content
        noPadding
        isError={isError}
        isEmpty={!isLoading && !data}
        onReload={handleReload}
        className={styles.CardTableContent}
      >
        <ExploreTable<MetricRow>
          columns={columns}
          rows={rows}
          isLoading={isLoading}
          cellGenerator={cellGenerator}
          cellValueGetter={cellValueGetter}
          defaultSortState={{ key: "ticketEvaluatedCount", direction: "asc" }}
        />
      </WidgetCard.Content>
    </WidgetCard>
  );
}
