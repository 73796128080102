import React, { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { Spinner } from "@radix-ui/themes";

import {
  getOpenTicketsFormExplorerObject,
  useExploreTickets,
} from "../../api/useExplore";
import { TTopic } from "../simulator/types";
import { useGetTopics } from "../../api/useGetTopics";
import { InstructionsProvider } from "../simulator/context/instructions";
import { ReleaseProvider } from "../simulator/context/release";
import { RigthPanel } from "../simulator/rightPanel";
import {
  ELocalStorage,
  fetchFromLocalStorage,
} from "../../assets/storage/localStorage";
import { saveToLocalStorage } from "../../assets/storage/localStorage";

import styles from "./styles.module.scss";
import {
  TSimulatorLiveModeReviewTicket,
  TSimulatorLiveModeTicket,
} from "./types";
import { SimulateTicket } from "./simulateTicket";

export const SimulatorLiveMode = () => {
  const { topicId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const channel = queryParams.get("channel") || "All";
  const tenantGroup = queryParams.get("tenantGroup")?.split(",") || [];

  const { mutateAsync: fetchTickets, isLoading } = useExploreTickets();
  const { user, isAuthenticated } = useAuth0();
  const { data: topics }: { data: TTopic[] | undefined } = useGetTopics();

  const [topic, setTopic] = useState<string | null>(null);
  const [tickets, setTickets] = useState<TSimulatorLiveModeTicket[]>([]);
  const [activeTicketIndex, setActiveTicketIndex] = useState<number | null>(
    null
  );
  const [reviewTickets, setReviewTickets] = useState<
    TSimulatorLiveModeReviewTicket[]
  >([]);

  useEffect(() => {
    if (
      user &&
      isAuthenticated &&
      topicId &&
      topics?.length &&
      activeTicketIndex === null
    ) {
      const topicObj = topics.find((t) => t.id.toString() === topicId);
      if (!topicObj) return;
      const topicName = topicObj?.name || topicObj?.topic;
      setTopic(topicName);

      setTimeout(() => {
        const openTicketsObject = getOpenTicketsFormExplorerObject({
          topicName,
          channel,
          tenantGroup,
        });
        fetchTickets(openTicketsObject).then((res) => {
          setTickets(res?.ticketsData?.tickets?.slice(0, 30));
          setActiveTicketIndex(0);
          const lsReviewTickets = fetchFromLocalStorage(
            `${ELocalStorage.LIVE_TEST_MODE}-${topicId}`
          );
          const parsedReviewTickets = lsReviewTickets
            ? JSON.parse(lsReviewTickets)
            : [];
          const updatedReviewTickets = res?.ticketsData?.tickets.map(
            (t: TSimulatorLiveModeTicket) => {
              const existingTicket = parsedReviewTickets.find(
                (rt: { ticketId: string }) => rt.ticketId === t.id
              );
              return existingTicket ? existingTicket : { ticketId: t.id };
            }
          );
          setReviewTickets(updatedReviewTickets);
        });
      }, 100);
    }
  }, [
    user,
    topicId,
    isAuthenticated,
    fetchTickets,
    topics,
    activeTicketIndex,
    channel,
  ]);

  useEffect(() => {
    if (tickets && tickets.length && reviewTickets.length) {
      saveToLocalStorage(
        `${ELocalStorage.LIVE_TEST_MODE}-${topicId}`,
        JSON.stringify(reviewTickets)
      );
    }
  }, [tickets, reviewTickets, topicId]);

  const reviewedCount = useMemo(() => {
    return reviewTickets
      ? reviewTickets.filter((t) => t.good !== undefined && t.good !== null)
          .length
      : 0;
  }, [reviewTickets]);

  return (
    <ReleaseProvider>
      <InstructionsProvider>
        <div className={styles.simulatorLiveModeContainer}>
          {!isLoading &&
          tickets &&
          tickets.length > 0 &&
          tickets[activeTicketIndex!] ? (
            <SimulateTicket
              ticket={tickets[activeTicketIndex!]}
              activeTicketIndex={activeTicketIndex!}
              ticketsLength={tickets.length}
              setActiveTicketIndex={setActiveTicketIndex}
              topic={topic}
              topicId={topicId ?? null}
              ticketReview={
                reviewTickets.find(
                  (t) => t.ticketId === tickets[activeTicketIndex!].id
                ) ?? { ticketId: tickets[activeTicketIndex!].id }
              }
              setTicketReview={(ticketReview: {
                ticketId: string;
                good?: boolean;
                comment?: string;
              }) =>
                setReviewTickets((prev) => [
                  ...prev.filter((t) => t.ticketId !== ticketReview.ticketId),
                  ticketReview,
                ])
              }
              reviewedCount={reviewedCount}
            />
          ) : (
            <div className={styles.loadingContainer}>
              <Spinner size="3" />
            </div>
          )}
        </div>
        <div style={{ visibility: "hidden", position: "absolute" }}>
          <RigthPanel
            topic={topicId ?? null}
            isTraining={false}
            selectedValue={topicId ?? ""}
          />
        </div>
      </InstructionsProvider>
    </ReleaseProvider>
  );
};
