import { useState } from "react";

import styles from "./styles.module.scss";
import { CopilotSimulatorChat } from "./chat";
import { ReleaseProvider, useReleases } from "../simulator/context/release";
import { InstructionsProvider } from "../simulator/context/instructions";
import { CopilotRightPanel } from "./rightPanel";
import { Navbar } from "@/components/shared/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { SimulatorChannelIcon } from "../simulators/SimulatorChannelIcon";

function SimulatorNavbar() {
  const navigate = useNavigate();
  const { simulator } = useReleases();

  return (
    <Navbar
      items={[
        { title: "Automation" },
        {
          title: "Co-pilot",
          onClick: () => navigate("/automation?f=copilot"),
        },
        ...(simulator != null
          ? [
              {
                title: simulator.name,
                svg: <SimulatorChannelIcon channel={simulator.channel} />,
              },
            ]
          : []),
      ]}
    />
  );
}

export const SimulatorCoPilot = () => {
  const [topic, setTopic] = useState<string | null>(null);
  const [isTraining, setTraining] = useState(true);
  const [ticketId, setTicketId] = useState<string | null>(null);
  const [comments, setComments] = useState<
    | {
        rawBody: string;
        agent: boolean;
        createdAt: string;
      }[]
    | null
  >(null);

  return (
    <ReleaseProvider>
      <InstructionsProvider>
        <div className={styles.container}>
          <div className={styles.controlRow}>
            <SimulatorNavbar />
          </div>

          <div className={styles.mainContainer}>
            <div className={styles.chatContainer}>
              <CopilotSimulatorChat
                {...{
                  topic,
                  setTopic,
                  isTraining,
                  setTraining,
                  ticketId,
                  setTicketId,
                  setComments,
                }}
              />
            </div>
            <div className={styles.configurationPanelContainer}>
              <CopilotRightPanel
                {...{ topic, isTraining, ticketId, comments }}
              />
            </div>
          </div>
        </div>
      </InstructionsProvider>
    </ReleaseProvider>
  );
};
