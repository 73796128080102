import React, {
  Fragment,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import Markdown from "react-markdown";
//@ts-ignore
import { Converter } from "showdown";
import { Message, ToolInvocation } from "ai";
import { translateText } from "../../../services/translation";
import toast from "react-hot-toast";
import { PiTranslate } from "react-icons/pi";

import styles from "./styles.module.scss";
import { IoSparkles } from "react-icons/io5";
import { Loading, Textarea } from "@geist-ui/core";
import { Info, ThumbsDown } from "@geist-ui/icons";
import {
  ArchiveIcon,
  EnvelopeClosedIcon,
  FileTextIcon,
  GlobeIcon,
  Pencil1Icon,
} from "@radix-ui/react-icons";
import {
  Badge,
  Button,
  Card,
  Separator,
  Text,
  Tooltip,
  Switch,
} from "@radix-ui/themes";
import { Dialog } from "../../../components/shared/dialog";
import { useFeatureFlag } from "configcat-react";
import { useAuth0 } from "@auth0/auth0-react";
import { mixTrackEvent } from "../../../assets/mixpanel";
import { SimulatorEvents } from "../../../assets/mixpanel/simulator";
import { track } from "mixpanel-browser";

interface IMessage {
  role: "user" | "assistant";
  content: string;
}

enum Tools {
  SKIP_IT = "skip_it",
  TALK_TO_AN_AGENT = "talk_to_an_agent",
}

export const Messages = ({
  messages,
  isLoading,
  handleEditMessage,
  messagesSources,
  trackingData,
}: {
  messages: Message[];
  handleEditMessage: (message: string) => void;
  isLoading: boolean;
  messagesSources: { [key: string]: any };
  trackingData: { [key: string]: any };
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // handle scrolling down on messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView();
  }, [messages]);

  return (
    <>
      {messages.length === 0 && <div>Analyzing...</div>}
      {messages.map((m, i) => {
        return (
          <MessageComponent
            role={m.role === "user" ? "user" : "assistant"}
            content={m.content}
            tools={m.toolInvocations}
            isLast={i + 1 === messages.length}
            handleEditMessage={handleEditMessage}
            sources={m.role === "user" ? null : messagesSources[m.id]}
            trackingData={trackingData}
          />
        );
      })}

      {isLoading && messages.length % 2 !== 0 && (
        <MessageComponent
          role={"assistant"}
          content={""}
          isLoading
          handleEditMessage={handleEditMessage}
          sources={[]}
          trackingData={trackingData}
        />
      )}

      <div ref={messagesEndRef} />
    </>
  );
};

const AgentWrapper = ({
  children,
  hideIcon = false,
}: {
  children: ReactElement;
  hideIcon?: boolean;
}) => {
  return (
    <div className={styles.assistantWrapper}>
      {!hideIcon && (
        <div className={styles.sparklesIcon}>
          <IoSparkles />
        </div>
      )}
      {children}
    </div>
  );
};
const convertor = new Converter({
  simplifiedAutoLink: true,
  excludeTrailingPunctuationFromURLs: true,
  openLinksInNewWindow: true,
  smoothLivePreview: true,
  disableForced4SpacesIndentedSublists: true,
});

const convertMarkdownToHTML = (markdown: string) => {
  if (markdown) {
    // return markdown;
    return convertor?.makeHtml(markdown);
  } else {
    return "";
  }
};

const MessageComponent = ({
  role,
  content,
  tools,
  handleEditMessage,
  isLoading = false,
  isLast = false,
  sources,
  trackingData,
}: IMessage & {
  isLoading?: boolean;
  handleEditMessage: (m: string) => void;
  tools?: ToolInvocation[];
  isLast?: boolean;
  sources: null | any[];
  trackingData: { [key: string]: any };
}) => {
  const { user } = useAuth0();
  const [isHover, setHover] = useState(false);
  const [value, setValue] = useState(content);
  const [isEdit, setEdit] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [translatedContent, setTranslatedContent] = useState<string | null>(
    null
  );
  const Wrapper = role === "assistant" ? AgentWrapper : Fragment;

  const { value: auditCenterEnabled } = useFeatureFlag(
    "simulatorAuditCenter",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );
  useEffect(() => {
    setValue(content);
  }, [content]);

  const firstTool = tools?.[0];

  const isSkip =
    firstTool?.state === "result" && firstTool?.result === Tools.SKIP_IT;

  const isEscalated =
    firstTool?.state === "result" &&
    firstTool.result === Tools.TALK_TO_AN_AGENT;

  const props = role === "assistant" ? { hideIcon: isSkip || isEscalated } : {};

  return (
    <Wrapper {...props}>
      <>
        <div
          className={cx(styles.message, styles[role], {
            [styles.loading]: isLoading,
            [styles.systemMessage]: isSkip || isEscalated,
            [styles.editMode]: isEdit,
            [styles.translated]: !!translatedContent,
          })}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onBlur={() => {}}
        >
          {isHover && auditCenterEnabled ? (
            <div className={styles.actionBar}>
              <div className="flex items-center gap-2">
                <Tooltip
                  delayDuration={100}
                  content={
                    translatedContent ? "Show original" : "Translate to English"
                  }
                >
                  <PiTranslate />
                </Tooltip>
                <Switch
                  size="1"
                  disabled={isTranslating}
                  checked={!!translatedContent}
                  onCheckedChange={async (checked) => {
                    if (!checked) {
                      setTranslatedContent(null);
                      return;
                    }
                    setIsTranslating(true);
                    try {
                      const translated = await translateText(content);
                      setTranslatedContent(translated);
                      mixTrackEvent({
                        event: SimulatorEvents.MESSAGE_TRANSLATED,
                        properties: {
                          ...trackingData,
                          messageRole: role,
                          hasContent: !!content,
                        },
                      });
                    } catch (error) {
                      console.error("Translation failed:", error);
                      toast.error("Failed to translate message");
                    } finally {
                      setIsTranslating(false);
                    }
                  }}
                />
              </div>
              <Separator orientation={"vertical"} />
              {sources && sources.length > 0 && (
                <Button
                  color="gray"
                  variant="ghost"
                  onClick={() => {
                    setOpen(true);
                    mixTrackEvent({
                      event: SimulatorEvents.CHECK_SOURCES,
                      properties: {
                        ...trackingData,
                        messageRole: role,
                        hasContent: !!content,
                        sourcesCount: sources?.length ?? 0,
                      },
                    });
                  }}
                >
                  Sources <ArchiveIcon />
                </Button>
              )}
            </div>
          ) : (
            <></>
          )}
          {(isHover || isEdit) && role === "assistant" && isLast && (
            <div
              className={cx(styles.btnContainer, {
                [styles.editablt]: isEdit,
              })}
            >
              <button
                type="button"
                className={cx(styles.btn)}
                onClick={() => {
                  const newEditState = !isEdit;
                  setEdit(newEditState);
                  if (newEditState) {
                    mixTrackEvent({
                      event: SimulatorEvents.ENTER_EDIT_MODE,
                      properties: {
                        ...trackingData,
                        messageRole: role,
                        messageLength: content.length,
                      },
                    });
                  }
                }}
              >
                {isEdit ? "Cancel" : <Pencil1Icon />}
              </button>
              {isEdit && (
                <button
                  className={cx(styles.btn)}
                  onClick={() => {
                    handleEditMessage(value);
                    setEdit(false);
                    mixTrackEvent({
                      event: SimulatorEvents.SAVE_EDIT_MODE,
                      properties: {
                        ...trackingData,
                        messageRole: role,
                        originalLength: content.length,
                        editedLength: value.length,
                        hasChanges: content !== value,
                      },
                    });
                  }}
                >
                  Save
                </button>
              )}
            </div>
          )}
          {isLoading ? (
            <Loading width={2.5} />
          ) : (
            <>
              {isSkip && (
                <div className={styles.system}>
                  <Info />
                  Out of the policy, Quack Agent will skip this question
                </div>
              )}
              {isEscalated && (
                <div className={styles.system}>
                  <Info />
                  Quack will escalate it to an agent
                </div>
              )}
              {isEdit ? (
                <TextField
                  value={content}
                  index={0}
                  handleBlur={() => {
                    setTimeout(() => {
                      setEdit(false);
                    }, 300);
                  }}
                  handleFocus={() => {}}
                  setExternalValue={setValue}
                  preventSelectAll
                />
              ) : (
                <div
                  className={styles.messageFormat}
                  dangerouslySetInnerHTML={{
                    __html: convertMarkdownToHTML(translatedContent || content),
                  }}
                ></div>
              )}
              {/* <Markdown disallowedElements={["ul"]} unwrapDisallowed>
              {content}
            </Markdown> */}
            </>
          )}
        </div>
        <Dialog open={isOpen} onOpenChange={(v) => setOpen(v)}>
          <Dialog.Content size={"large"}>
            <Dialog.Close className={styles.Close} />
            <Dialog.Title>
              List of sources reviewed before answering
            </Dialog.Title>
            <div className={styles.SourceDialogList}>
              {sources?.map((s) => {
                if (!s) {
                  return null;
                }
                const isTicket = !s?.metadata?.indexName?.includes("kb");
                const ticketId = isTicket
                  ? String(s.metadata.id).split("-")[0]
                  : "";
                return (
                  <div>
                    <Card>
                      <div className={styles.SourceTopbar}>
                        {s?.metadata?.indexName?.includes("kb") ? (
                          <FileTextIcon />
                        ) : (
                          <EnvelopeClosedIcon />
                        )}
                        <Text as="label" size={"5"}>
                          {isTicket
                            ? s.metadata.isGenerated
                              ? "Training Session"
                              : `Ticket #${ticketId}`
                            : s.metadata.title}
                        </Text>
                        {s.metadata.positiveFeedback === 1 ? (
                          <Badge color="green">Good Feedback</Badge>
                        ) : (
                          <Badge color="blue">Imported</Badge>
                        )}
                      </div>
                      <Separator size={"4"} />
                      <Text as="label" size={"2"}>
                        {isTicket ? s?.metadata?.answer : s?.metadata?.text}
                      </Text>
                    </Card>
                  </div>
                );
              })}
            </div>
            {/* <div className={styles.SourceModalFooter}>
              <Button variant="ghost" color="gray">
                Suggest new source
              </Button>
              <Button variant="ghost" color="gray">
                Create snippet
              </Button>
            </div> */}
          </Dialog.Content>
        </Dialog>
      </>
    </Wrapper>
  );
};

// ol/ul - margin top and bottom 0
// li - margin 0 and line height like the font size

const TextField = ({
  index,
  value,
  handleBlur,
  handleFocus,
  setExternalValue,
  disabled = false,
  preventSelectAll = false,
}: {
  index: number;
  value: string;
  setExternalValue?: (v: string) => void;
  handleBlur: (index: number, value: string) => void;
  handleFocus: (index: number) => void;
  disabled?: boolean;
  preventSelectAll?: boolean;
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [v, setValue] = useState(value);
  const [height, setHeight] = useState("unset");

  useEffect(() => {
    setValue(value);

    setTimeout(() => {
      if (textAreaRef.current) {
        setHeight(`${textAreaRef.current.scrollHeight}px`);
      }
    }, 300);
  }, [value]);

  return (
    <Textarea
      ref={textAreaRef}
      onChange={(e) => {
        const inputValue = e.target.value;
        setValue(inputValue);

        if (setExternalValue) {
          setExternalValue(inputValue);
        }
        e.target.style.height = "auto";
        e.target.style.height = `${e.target.scrollHeight}px`;
      }}
      onBlur={(e) => {
        handleBlur(index, e.target.value);
      }}
      onFocus={(e) => {
        handleFocus(index);
        if (!preventSelectAll) {
          e.target.select();
        }
      }}
      value={v}
      disabled={disabled}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      style={{
        resize: "none",
        width: "100%",
        overflow: "hidden",
        height: height,
        border: "0",
      }}
    />
  );
};
