function NotionLogoSVG() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3409_41767)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.288 42.6237L6.70219 36.9108C5.59884 35.5364 5 33.8461 5 32.1065V11.42C5 8.81419 7.08479 6.65196 9.77428 6.46834L31.0428 5.01624C32.588 4.91075 34.1237 5.32146 35.3931 6.1796L42.8656 11.2319C44.2033 12.1363 45 13.6147 45 15.1923V38.7109C45 41.2611 42.9501 43.3724 40.3168 43.5345L16.7111 44.9876C14.6098 45.1169 12.5787 44.2316 11.288 42.6237Z"
          fill="white"
        />
        <path
          d="M18.6648 21.7715V21.5007C18.6648 20.8142 19.2146 20.2449 19.9233 20.1975L25.085 19.8529L32.2233 30.3646V21.1387L30.386 20.8938V20.7653C30.386 20.0706 30.9484 19.4975 31.6661 19.4607L36.3628 19.2202V19.8961C36.3628 20.2133 36.1268 20.4847 35.8034 20.5394L34.6732 20.7306V35.6715L33.2548 36.1593C32.0697 36.5669 30.7505 36.1306 30.0721 35.107L23.1421 24.6495V34.6306L25.275 35.0387L25.2454 35.2366C25.1522 35.8563 24.6172 36.3251 23.97 36.3539L18.6648 36.5901C18.5946 35.9235 19.0941 35.3274 19.7832 35.255L20.481 35.1817V21.8735L18.6648 21.7715Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.2332 7.62205L9.96463 9.07414C8.69066 9.16113 7.70312 10.1853 7.70312 11.4197V32.1062C7.70312 33.2659 8.10235 34.3927 8.83791 35.3091L13.4237 41.0219C14.1652 41.9455 15.332 42.4542 16.5391 42.3799L40.1448 40.9269C41.3547 40.8523 42.2965 39.8822 42.2965 38.7106V15.192C42.2965 14.4671 41.9305 13.7879 41.3159 13.3724L33.8433 8.32006C33.0817 7.80518 32.1603 7.55876 31.2332 7.62205ZM11.0193 11.7423C10.7235 11.5233 10.8643 11.0671 11.2359 11.0404L31.3763 9.59582C32.018 9.5498 32.6556 9.7265 33.1745 10.0942L37.2156 12.9572C37.3689 13.0659 37.2971 13.3005 37.1071 13.3109L15.7785 14.4708C15.133 14.5059 14.4959 14.3163 13.9827 13.9363L11.0193 11.7423ZM14.7781 18.1073C14.7781 17.4143 15.3379 16.842 16.0537 16.8031L38.604 15.5749C39.3017 15.537 39.8889 16.0735 39.8889 16.7488V37.1134C39.8889 37.805 39.3312 38.3767 38.6168 38.4174L16.2092 39.6923C15.4328 39.7366 14.7781 39.1399 14.7781 38.3883V18.1073Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_3409_41767">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(5 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NotionLogoSVG;
