import { Navigate, useParams } from "react-router-dom";
import { Button, Em, Flex, Spinner, Text } from "@radix-ui/themes";
import { ReloadIcon } from "@radix-ui/react-icons";
import Select, { SelectOption } from "@/components/shared/select";
import RadioGroup from "@/components/shared/inputs/radio-group/RadioGroup";
import { FieldGroup } from "../parts/FieldGroup";
import { InteractionLimitField } from "../fields/InteractionLimitField";
import { useSimulatorConfiguration } from "../useSimulatorConfiguration";
import { useUpdateReleasePolicyGeneral } from "../useUpdateReleasePolicyGeneral";
import { useUpdateAutoResponseFallbackAgent } from "../useUpdateAutoResponseFallbackAgent";
import styles from "../AutomationConfiguration.module.scss";
import { AutomationConfigurationContentHeader } from "../parts/AutomationConfigurationContentHeader";
import { mergeStatus } from "../utils/automation-configuration.utils";

type SelectDefaultAssigneeProps = ReturnType<
  typeof useUpdateAutoResponseFallbackAgent
>;

function SelectDefaultAssignee({
  value,
  onMethodChange,
  onGroupChange,
  onAgentChange,
  groupsOptions,
  loadinGroups,
  agentOptions,
  loadingAgents,
  isAutoResponseFallbackAgentLoading,
  isAutoResponseFallbackAgentError,
  refetchAutoResponseFallbackAgent,
}: SelectDefaultAssigneeProps) {
  if (isAutoResponseFallbackAgentLoading) {
    return <Spinner size="2" />;
  }

  if (isAutoResponseFallbackAgentError) {
    return (
      <Flex direction={"column"} gap={"2"}>
        <Text size={"2"}>
          An error occurred while loading data. Please try again. If the error
          persists, contact Quack support.
        </Text>

        <Flex>
          <Button
            color="gray"
            variant="solid"
            highContrast
            size="1"
            onClick={() => refetchAutoResponseFallbackAgent()}
          >
            <ReloadIcon />
            Retry
          </Button>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex direction={"column"} gap={"3"}>
      <RadioGroup
        value={value.method}
        onChange={onMethodChange}
        options={[
          { label: "Specific agent", value: "specific" },
          { label: "Unassigned", value: "unassigned" },
        ]}
      />

      {value.method === "specific" && (
        <Flex direction={"column"} gap={"4"}>
          <Flex direction={"column"} gap={"1"}>
            <Text size={"2"} color={"gray"}>
              <Em>Select a group</Em>
            </Text>

            <Select
              options={groupsOptions}
              value={value.group}
              onChange={onGroupChange}
              classes={{ trigger: styles.Select, value: styles.SelectValue }}
              loading={loadinGroups}
            />
          </Flex>

          <Flex direction={"column"} gap={"1"}>
            <Text size={"2"} color={"gray"}>
              <Em>Select an agent (optional)</Em>
            </Text>

            <Select
              options={agentOptions}
              value={value.agent}
              onChange={onAgentChange}
              classes={{ trigger: styles.Select, value: styles.SelectValue }}
              loading={loadingAgents}
              allowClear
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

const interactionLimitOptions: SelectOption[] = [
  ...[...Array(6)].map((_, i) => ({
    label: `${i + 1} user intreaction${i > 0 ? "s" : ""}`,
    value: (i + 1).toString(),
  })),
  { label: "Unlimited", value: "unlimited" },
];

export type EscalationPayload = {
  defaultAssignee: string;
  interactionLimit: number | "unlimited";
};

export function TicketChannelEscalationConfiguration() {
  const { simulatorId } = useParams();
  const { release } = useSimulatorConfiguration(simulatorId);
  const {
    localPayload,
    handlePayloadChange,
    status: updateReleasePolicyStatus,
  } = useUpdateReleasePolicyGeneral(simulatorId);

  const selectDefaultAssigneeProps = useUpdateAutoResponseFallbackAgent();

  const status = mergeStatus(
    updateReleasePolicyStatus,
    selectDefaultAssigneeProps.status
  );

  if (release == null) {
    return <Navigate to={`/automation/${simulatorId}`} />;
  }

  return (
    <Flex direction={"column"} gap={"24px"}>
      <AutomationConfigurationContentHeader
        title={"Escalations"}
        status={status}
      />

      <Flex direction={"column"}>
        <FieldGroup
          label={"Default assignee"}
          description={"Set to whom escalated tickets are assigned."}
        >
          <SelectDefaultAssignee {...selectDefaultAssigneeProps} />
        </FieldGroup>

        <InteractionLimitField
          value={localPayload.maxBackAndForth}
          onChange={(newValue) =>
            handlePayloadChange({ maxBackAndForth: newValue })
          }
        />
      </Flex>
    </Flex>
  );
}
