import { useContext, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import cx from "classnames";
import { Copy } from "@geist-ui/icons";
import { Tabs, Tooltip } from "@geist-ui/core";
import { Badge, Button } from "@radix-ui/themes";
import {
  useGetArticleDetails,
  useGetArticleOpportunities,
  useGetArticleTemplates,
  useImproveArticle,
} from "../../api/useWizard";
import { BigSelect } from "../../components/geistSelect";
import { useFetchingToasts } from "../../hooks/useFetchingToasts";
import styles from "./styles.module.scss";
import {
  ArticleDetails,
  ArticleGenerationTemplate,
  Opportunity,
} from "./index.types";
import { ThemeContext } from "../../components/Theme";
import {
  OpportunityStatus,
  useChangeStatusToHidden,
} from "../../api/useOpportunities";
import { useAuth0 } from "@auth0/auth0-react";
import { DiffViewer } from "../../components/shared/diff-viewer/DiffViewer";
import { KnowledgeEvents, mixTrackEvent } from "@/assets/mixpanel";

export const ArticleWizard = () => {
  const { articleId } = useParams<{ articleId: string }>();
  const [tab, setTab] = useState<"generated" | "compare">("generated");
  const [selectedOpportunityIds, setSelectedOpportunityIds] = useState<
    string[]
  >([]);
  const [isCopyPressed, setIsCopyPressed] = useState(false);
  const [instructions, setInstructions] = useState<string>("");
  const [selectedTemplateId, setSelectedTemplateId] = useState<string[]>([]);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { isDarkMode } = useContext(ThemeContext);

  const {
    data: articleDetailsData,
    isLoading: isArticleDetailsLoading,
    refetch: refetchArticleDetails,
  } = useGetArticleDetails({
    articleId: articleId || "",
    enabled: !!articleId,
  });
  const articleDetails = articleDetailsData as ArticleDetails;

  const {
    data: opportunitiesData,
    isLoading: isOpportunitiesLoading,
    refetch: refetchOpportunities,
  } = useGetArticleOpportunities({
    articleId: articleId || "",
    enabled: !!articleId,
  });
  const opportunities = opportunitiesData as Opportunity[];

  const { data: templatesData, isLoading: isTemplatesLoading } =
    useGetArticleTemplates({ enabled: true });
  const templates = templatesData as ArticleGenerationTemplate[];

  const { currentArticleCoverage, opportunityCoverage } = useMemo(() => {
    if (!opportunities || !articleDetails) {
      return { currentArticleCoverage: 0, opportunityCoverage: 0 };
    }

    const suggestedArticleOpportunityIds = new Set(
      articleDetails?.suggestedArticles?.flatMap(
        (article) => article.opportunityIds
      ) || []
    );

    const totalTicketsCovered = opportunities
      .filter(
        (opportunity) =>
          suggestedArticleOpportunityIds.has(opportunity.id) &&
          opportunity.transaction.commonTransactionEval.isCovered
      )
      .reduce(
        (acc, opportunity) => acc + opportunity.transaction.tickets.length,
        0
      );

    const totalTicketsNotCovered = opportunities
      .filter(
        (opportunity) =>
          !opportunity.transaction.commonTransactionEval.isCovered
      )
      .reduce(
        (acc, opportunity) => acc + opportunity.transaction.tickets.length,
        0
      );

    return {
      currentArticleCoverage: totalTicketsCovered,
      opportunityCoverage: totalTicketsNotCovered,
    };
  }, [opportunities, articleDetails]);

  const {
    mutateAsync: improveArticle,
    isLoading: isImprovingArticle,
    data: improvingArticleData,
  } = useImproveArticle();

  useFetchingToasts({
    error: improvingArticleData?.statusCode > 400,
    emptyState: false,
    errorMessage: "Error while improving article, please try again",
  });

  const suggestedArticle = useMemo(() => {
    if (!articleDetails) return null;
    if (articleDetails?.suggestedArticles?.length === 0) return null;
    const sugg = articleDetails?.suggestedArticles?.[0];
    setSelectedOpportunityIds(sugg?.opportunityIds || []);
    setInstructions(sugg?.instructions || "");
    if (templates && templates.length > 0)
      setSelectedTemplateId([sugg.generationTemplateIds[0]?.toString()]);
    return sugg;
  }, [articleDetails, templates]);

  const loading = useMemo(() => {
    return (
      isArticleDetailsLoading ||
      isOpportunitiesLoading ||
      isImprovingArticle ||
      isTemplatesLoading ||
      !articleDetails ||
      !opportunities ||
      !templates
    );
  }, [
    isArticleDetailsLoading,
    isOpportunitiesLoading,
    isImprovingArticle,
    articleDetails,
    opportunities,
    isTemplatesLoading,
    templates,
  ]);

  const handleOpportunityChange = (opportunityId: string) => {
    setSelectedOpportunityIds((prevSelected) => {
      if (prevSelected.includes(opportunityId)) {
        return prevSelected.filter((id) => id !== opportunityId);
      } else {
        return [...prevSelected, opportunityId];
      }
    });
  };

  const handleImproveArticle = async () => {
    await improveArticle({
      articleId: articleId || "",
      instruction: instructions,
      opportunityIds: selectedOpportunityIds,
      templateId: selectedTemplateId.length
        ? Number(selectedTemplateId[0])
        : undefined,
    });
    refetchArticleDetails();

    mixTrackEvent({
      event: KnowledgeEvents.ARTICLE_GENERATED,
      properties: {
        articleId: articleId || "",
        instruction: instructions,
        opportunityIds: selectedOpportunityIds,
        templateId: selectedTemplateId.length
          ? Number(selectedTemplateId[0])
          : undefined,
      },
    });
  };

  const activeButton = useMemo(() => {
    if (suggestedArticle) {
      const instructionsMatch = suggestedArticle.instructions || "";
      return (
        instructionsMatch !== instructions ||
        !selectedOpportunityIds.every((id) =>
          suggestedArticle.opportunityIds.includes(id)
        ) ||
        suggestedArticle.opportunityIds.length !== selectedOpportunityIds.length
      );
    }
    if (suggestedArticle === null) {
      return !!instructions || selectedOpportunityIds.length > 0;
    }
    return false;
  }, [selectedOpportunityIds, instructions, suggestedArticle]);

  const isHTMLString = (str: string) => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  };

  const isHtml = isHTMLString(suggestedArticle?.content ?? "");

  const sortedOpportunities = useMemo(() => {
    return opportunities
      ?.filter((opportunity) => opportunity.transaction.tickets.length > 0)
      .sort((a, b) => {
        if (b.transaction.tickets.length === a.transaction.tickets.length) {
          return +b.id - +a.id;
        }
        return b.transaction.tickets.length - a.transaction.tickets.length;
      });
  }, [opportunities]);

  return (
    <div className={styles.mainContainer}>
      <div
        className={cx(styles.articleContainer, {
          [styles.compare]: tab === "compare",
        })}
      >
        <Tabs
          width="fit-content"
          value={tab}
          onChange={(value) => {
            setTab(value as "generated" | "compare");
          }}
        >
          <Tabs.Item label="Generated" value="generated">
            <></>
          </Tabs.Item>
          {!loading && suggestedArticle && (
            <Tabs.Item label="Compare" value="compare">
              <></>
            </Tabs.Item>
          )}
        </Tabs>
        <div
          className={cx(
            styles.article,
            { [styles.loading]: loading },
            { [styles.empty]: !suggestedArticle }
          )}
        >
          {loading ? (
            <div>Loading...</div>
          ) : tab === "generated" ? (
            <>
              <div className={cx(styles.content)}>
                {suggestedArticle ? (
                  <>
                    <div className={styles.copyBadgeContainer}>
                      <Badge
                        color={isCopyPressed ? "green" : "gray"}
                        onClick={() => {
                          setIsCopyPressed(true);
                          navigator.clipboard.writeText(
                            `
                              ${suggestedArticle?.title}
                              \n
                              ${suggestedArticle?.content || ""}
                            `
                          );
                        }}
                      >
                        <Copy />
                        {isCopyPressed ? "Copied" : "Copy"}
                      </Badge>
                    </div>
                    <h4>{suggestedArticle?.title}</h4>
                    <p
                      className={
                        isHtml
                          ? styles.articleContentHtml
                          : styles.articleContent
                      }
                    >
                      {isHtml ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: articleDetails.content,
                          }}
                        />
                      ) : (
                        articleDetails.content
                      )}
                    </p>
                  </>
                ) : (
                  <div className={styles.emptyState}>
                    No suggested article for article
                    <br />
                    <b>{articleDetails.title}</b>
                    <br />
                    <span>yet - you can generate one now!</span>
                  </div>
                )}
              </div>
            </>
          ) : (
            <DiffViewer
              oldValue={articleDetails?.content
                ?.replace(/\t/g, "")
                ?.replace(/\n+/g, "")}
              newValue={suggestedArticle?.content
                ?.replace(/\t/g, "")
                ?.replace(/\n+/g, "")}
            />
          )}
        </div>
      </div>
      <div className={cx(styles.brainContainer, { [styles.loading]: loading })}>
        {!loading ? (
          <>
            <div className={styles.header}>
              <h2 className={styles.brainContainerHeader}>
                {articleDetails.title}
              </h2>
              <div className={styles.coverageContainer}>
                <div className={styles.currentCoverage}>
                  {currentArticleCoverage}
                </div>
                <div className={styles.opportunityCoverage}>
                  +{opportunityCoverage}
                </div>
                <div className={styles.coverageExplanation}>
                  Est. Tickets Coverage Score
                </div>
              </div>
            </div>
            <div className={styles.editorsContainer}>
              {templates &&
                Array.isArray(templates) &&
                templates.length > 0 && (
                  <div className={styles.editContainer}>
                    <h5>Select Template</h5>
                    <BigSelect
                      filter={selectedTemplateId?.[0]}
                      setFilter={(value: any) => setSelectedTemplateId(value)}
                      options={templates.map((template) => ({
                        id: template.id,
                        name: template.name,
                      }))}
                      isLoading={false}
                      maxWidth="100%"
                    />
                  </div>
                )}
              <div
                className={cx(styles.editContainer, {
                  [styles.light_mode]: !isDarkMode,
                })}
              >
                <h5>Instructions</h5>
                <textarea
                  ref={textAreaRef}
                  value={instructions}
                  onChange={(e) => {
                    setInstructions(e.target.value);
                  }}
                  placeholder="Add instruction"
                  className="h-auto"
                  style={{
                    height: `${textAreaRef.current?.scrollHeight}px`,
                    minHeight: "100px",
                    maxHeight: "400px",
                  }}
                />
              </div>
              {opportunities?.length > 0 && (
                <div className={styles.editContainer}>
                  <h5>Insert</h5>
                  <div className={styles.opportunityList}>
                    {sortedOpportunities.map((opportunity) => (
                      <OpportunityComponent
                        opportunity={opportunity}
                        selectedOpportunityIds={selectedOpportunityIds}
                        handleOpportunityChange={handleOpportunityChange}
                        refetchOpportunities={refetchOpportunities}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className={styles.improveContainer}>
              <Button
                color="gray"
                highContrast
                style={{ width: "100%" }}
                disabled={!activeButton}
                onClick={handleImproveArticle}
              >
                Improve
              </Button>
            </div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};

const OpportunityComponent = ({
  opportunity,
  selectedOpportunityIds,
  handleOpportunityChange,
  refetchOpportunities,
}: {
  opportunity: Opportunity;
  selectedOpportunityIds: string[];
  handleOpportunityChange: (opportunityId: string) => void;
  refetchOpportunities: () => void;
}) => {
  const { mutateAsync: changeStatusToHidden } = useChangeStatusToHidden({
    opportunityId: +opportunity.id,
  });
  const { user } = useAuth0();

  return (
    <div
      key={opportunity.id}
      className={`${styles.opportunityItem} ${
        selectedOpportunityIds.includes(opportunity.id) ? styles.selected : ""
      }`}
      onClick={() => handleOpportunityChange(opportunity.id)}
    >
      <input
        type="checkbox"
        disabled={
          selectedOpportunityIds.length > 9 &&
          !selectedOpportunityIds.includes(opportunity.id)
        }
        checked={selectedOpportunityIds.includes(opportunity.id)}
        onClick={(e) => {
          e.stopPropagation();
          handleOpportunityChange(opportunity.id);
        }}
        className={styles.checkbox}
      />
      <Tooltip
        text={
          <div>
            <div>
              {opportunity.transaction.commonTransactionEval.explanation}
            </div>
          </div>
        }
      >
        <div className={styles.opportunityItemContent}>
          <label>{opportunity.label}</label>
          <p>Related tickets: {opportunity.transaction.tickets.length}</p>
          <Button
            color="gray"
            variant="ghost"
            onClick={() =>
              changeStatusToHidden({
                status: OpportunityStatus.HIDDEN,
                userEmail: user?.email,
              }).then(() => {
                refetchOpportunities();
              })
            }
          >
            Ignore
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};
