import { Outlet, useParams, Navigate } from "react-router-dom";
import { Flex, Container, ScrollArea, Box } from "@radix-ui/themes";
import { AutomationConfigurationSidebar } from "@/features/automation-configuration/parts/AutomationConfigurationSidebar";
import { AutomationConfigurationHeader } from "@/features/automation-configuration/parts/AutomationConfigurationHeader";
import { useSimulatorConfiguration } from "@/features/automation-configuration/useSimulatorConfiguration";
import { useGetChannelConfigurationViews } from "@/features/automation-configuration/AutomationConfiguration.config";
import styles from "@/features/automation-configuration/AutomationConfiguration.module.scss";

export function AutomationConfiguration() {
  const { simulatorId } = useParams();
  const { loadingSimulator } = useSimulatorConfiguration(simulatorId);
  const { channel, views } = useGetChannelConfigurationViews();

  if (loadingSimulator) {
    return <></>;
  }

  if (views == null || channel == null || views.length === 0) {
    return <Navigate to={`/`} />;
  }

  return (
    <Flex
      flexGrow={"1"}
      direction={"column"}
      className={styles.AutomationConfiguration}
    >
      <AutomationConfigurationHeader />

      <Flex flexGrow={"1"} overflow={"hidden"}>
        <AutomationConfigurationSidebar channel={channel} views={views} />

        <ScrollArea>
          <Container
            size={channel === "autonomous_quackchat" ? "4" : "2"}
            pt={"12px"}
            minWidth={"600px"}
          >
            <Box className={styles.Content}>
              <Outlet />
            </Box>
          </Container>
        </ScrollArea>
      </Flex>
    </Flex>
  );
}
