import { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useFeatureFlag } from "configcat-react";
import {
  Integration,
  isIntegrationCompleted,
} from "../integration/Integration.model";
import { useIntegrations } from "../integration";
import { useAuthorizedQuery } from "..";
import { params } from "@/params";
import { useWorkspace } from "@/routes/simulator/context/wrokspace";

const backfillStatusTypes = ["not_started", "started", "completed"] as const;
type BackfillStatusType = (typeof backfillStatusTypes)[number];

type BackfillStatusResponse = {
  totalTickets?: number;
  loadedTickets?: number;
  completedTickets?: number;
};

function computeBackfillStatus(
  data: BackfillStatusResponse | undefined
): BackfillStatusType {
  if (data == null) {
    return "not_started";
  }

  const { completedTickets, totalTickets } = data;
  if (completedTickets != null) {
    return completedTickets > 100 || completedTickets === totalTickets
      ? "completed"
      : "started";
  }

  return "not_started";
}

export function useBackfillStatus(defaultEnabled: boolean = true) {
  const [enabled, setEnabled] = useState(true);

  const { data, isLoading, isError } =
    useAuthorizedQuery<BackfillStatusResponse>({
      queryKey: "useBackfillStatus",
      url: `${params.API_URL}/platform/backfill-status`,
      method: "GET",
      enabled: defaultEnabled || enabled,
      refetchInterval: 30_000,
    });

  const status = useMemo(() => computeBackfillStatus(data), [data, isLoading]);

  useEffect(() => {
    if (status === "completed" || isError) {
      setEnabled(false);
    }
  }, [status, isError]);

  return { status, isLoading, ...data };
}

interface OnboardingValue {
  hasTicketSystemIntegration: boolean;
  hasKnowledgeBaseIntegration: boolean;
  hasChatIntegration: boolean;
}

function extractFirstIntegrations(
  integrations: Integration[]
): OnboardingValue {
  const firstTicketSystemIntegration = integrations.find(
    (integration) =>
      integration.type === "TICKET_SYSTEM" &&
      isIntegrationCompleted(integration)
  )?.id;

  const firstKnowledgeBaseIntegration = integrations.find(
    (integration) =>
      integration.type === "KNOWLEDGE_BASE" &&
      isIntegrationCompleted(integration)
  )?.id;

  const firstChatIntegration = integrations.find(
    (integration) =>
      integration.type === "CHAT"
  );

  return {
    hasTicketSystemIntegration: firstTicketSystemIntegration != null,
    hasKnowledgeBaseIntegration: firstKnowledgeBaseIntegration != null,
    hasChatIntegration: firstChatIntegration != null,
  };
}

function isOnboardingDone(value: OnboardingValue, shouldCheckChat = false): boolean {
  if (!value.hasTicketSystemIntegration) {
    return false;
  }

  if (!value.hasKnowledgeBaseIntegration) {
    return false;
  }

  if (shouldCheckChat && !value.hasChatIntegration) {
    return false;
  }

  return true;
}

export function useOnboarding(): OnboardingValue & {
  isLoading: boolean;
  isDone: boolean;
  backfillStatus?: BackfillStatusType;
} {
  const { user } = useAuth0();
  const { workspace } = useWorkspace();
  const { data: integrations, isLoading: loadingIntegrations } =
    useIntegrations();

  const { value: onboardingEnabled } = useFeatureFlag("onboarding", true, {
    identifier: workspace ?? user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });

  const { value: slackSelfSetup } = useFeatureFlag("slackSelfSetup", false, {
    identifier: user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });

  const [state, setState] = useState<OnboardingValue>(
    extractFirstIntegrations(integrations)
  );

  const shouldCheckBackfill =
    onboardingEnabled && state.hasTicketSystemIntegration;
  const { status: backfillStatus, isLoading: loadingBackfillStatus } =
    useBackfillStatus(shouldCheckBackfill);

  const isLoading =
    loadingIntegrations || loadingBackfillStatus;
  const isDone = useMemo(
    () => isOnboardingDone(state, slackSelfSetup) && backfillStatus === "completed",
    [state, backfillStatus]
  );

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ...extractFirstIntegrations(integrations),
    }));
  }, [integrations]);

  if (!onboardingEnabled) {
    // Has integs + backfill completed
    return {
      isLoading: false,
      isDone: true,
      backfillStatus: "completed",
      hasTicketSystemIntegration: true,
      hasKnowledgeBaseIntegration: true,
      hasChatIntegration: true,
    };
  }

  return {
    ...state,
    isLoading,
    isDone,
    backfillStatus,
  };
}
