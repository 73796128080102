import React, {
  useState,
  useEffect,
  Component,
  ErrorInfo,
  ReactNode,
} from "react";
import * as runtime from "react/jsx-runtime";
import { evaluate } from "@mdx-js/mdx";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Text, Link, Box, Spinner, Em, Heading } from "@radix-ui/themes";
import { radixColors } from "@/components/radixColors";
import { ImageViewer } from "./ImageViewer";

const components: Record<string, React.ComponentType<any>> = {
  h1: (props: any) => (
    <Heading
      size={"8"}
      color={"gray"}
      highContrast
      style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
      {...props}
    />
  ),
  h2: (props: any) => (
    <Heading
      size={"7"}
      color={"gray"}
      highContrast
      style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
      {...props}
    />
  ),
  h3: (props: any) => (
    <Heading
      size={"6"}
      color={"gray"}
      highContrast
      style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
      {...props}
    />
  ),
  h4: (props: any) => (
    <Heading
      size={"5"}
      color={"gray"}
      highContrast
      style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
      {...props}
    />
  ),
  h5: (props: any) => (
    <Heading
      size={"4"}
      color={"gray"}
      highContrast
      style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
      {...props}
    />
  ),
  h6: (props: any) => (
    <Heading
      size={"3"}
      color={"gray"}
      highContrast
      style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
      {...props}
    />
  ),
  p: (props: any) => (
    <Text
      as={"p"}
      size={"2"}
      color={"gray"}
      highContrast
      style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
      {...props}
    />
  ),
  a: (props: any) => (
    <>
      {props.href.match(/\.(jpeg|jpg|gif|png)$/) ? (
        <ImageViewer url={props.href} />
      ) : (
        <Link
          color="indigo"
          underline={"hover"}
          {...props}
          target="_blank"
          rel="noopener noreferrer"
        />
      )}
    </>
  ),
};

interface CommentBodyProps {
  body: string;
  color: (typeof radixColors)[number] | "amber";
}

export function CommentBody({ body, color }: CommentBodyProps) {
  const [MDXComponent, setMDXComponent] =
    useState<React.ComponentType<any> | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const content = body;
        const compiled = await evaluate(content, {
          ...runtime,
          format: "md",
          remarkPlugins: [remarkGfm],
          rehypePlugins: [rehypeRaw],
        });

        // Set the compiled MDX component.
        setMDXComponent(() => compiled.default);
      } catch (error) {
        console.error("Error evaluating MDX:", error);
      }
    })();
  }, [body]);

  return (
    <>
      {!MDXComponent ? (
        <Spinner size={"2"} />
      ) : (
        <MDXComponent components={components} />
      )}
    </>
  );
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Error caught in ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Text
          size={"2"}
          color={"gray"}
          highContrast
          style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
        >
          <Em>Something went wrong while parsing comment.</Em>
        </Text>
      );
    }

    return this.props.children;
  }
}

export function CommentBodyWithBoundary({ body, color }: CommentBodyProps) {
  return (
    <Box
      p={"2"}
      style={{ background: `var(--${color}-a3)`, borderRadius: "8px" }}
    >
      <ErrorBoundary>
        <CommentBody body={body} color={color} />
      </ErrorBoundary>
    </Box>
  );
}
