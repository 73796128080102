import { useMemo } from "react";
import { PolarAngleAxis, PolarGrid, Radar, RadarChart } from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from "@/components/ui/chart";

export type RadarChartItem<T extends string> = {
  month: string;
} & Record<T, number>;

interface RadarChartCompProps<T extends string> {
  config: ChartConfig;
  data: RadarChartItem<T>[];
}

export function RadarChartComp<T extends string>({
  config,
  data,
}: RadarChartCompProps<T>) {
  const dataKeys = useMemo(
    () =>
      data.length > 0
        ? Object.keys(data?.[0]).filter((key) => key !== "month")
        : [],
    [data]
  );

  return (
    <ChartContainer config={config}>
      <RadarChart
        data={data}
        margin={{
          left: 32,
          right: 32,
        }}
      >
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent indicator="line" />}
        />
        <PolarAngleAxis dataKey="month" />
        <PolarGrid />

        {dataKeys.map((key) => {
          const fill = `var(--color-${key})`;

          return <Radar dataKey={key} fill={fill} />;
        })}

        <ChartLegend content={<ChartLegendContent />} />
      </RadarChart>
    </ChartContainer>
  );
}
