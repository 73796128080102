import { Em, Text } from "@radix-ui/themes";
import cx from "classnames";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import styles from "./RadioGroup.module.scss";
import classNames from "classnames";

export type RadioItemValue = {
  value: string;
  label: string;
  description?: string;
};

export function RadioItem({
  id,
  value,
}: {
  id: string;
  value: string;
  className?: string;
}) {
  return (
    <RadioGroupPrimitive.Item
      id={id}
      value={value}
      className={cx(styles.Item, classNames)}
    >
      <RadioGroupPrimitive.Indicator className={styles.Indicator} />
    </RadioGroupPrimitive.Item>
  );
}

interface RadioGroupProps {
  value: string;
  onChange: (newValue: string) => void;
  options: RadioItemValue[];
  layout?: "horizontal" | "vertical";
  classes?: {
    root?: string;
    optionItem?: string;
    item?: string;
    label?: string;
    description?: string;
  };
}

function RadioGroup({
  value,
  onChange,
  options,
  layout = "vertical",
  classes = {},
}: RadioGroupProps) {
  return (
    <RadioGroupPrimitive.Root
      className={cx(styles.Root, styles[layout], classes.root)}
      value={value}
      onValueChange={onChange}
    >
      {options.map((option, index) => {
        const id = `r${index}`;

        return (
          <label
            htmlFor={id}
            className={cx(styles.OptionItem, classes.optionItem)}
            aria-checked={option.value === value}
          >
            <RadioItem id={id} value={option.value} className={classes.item} />

            <Text size="2" className={cx(styles.Label, classes.label)}>
              {option.label}
            </Text>

            {option.description != null && (
              <Text
                size="2"
                color="gray"
                className={cx(styles.Description, classes.description)}
              >
                <Em>{option.description}</Em>
              </Text>
            )}
          </label>
        );
      })}
    </RadioGroupPrimitive.Root>
  );
}

export default RadioGroup;
