import React from "react";
import { useReleases } from "../../context/release";
import { Badge, Card, Separator, Text } from "@radix-ui/themes";
import { ReleaseConversation } from "../../types";

export const Feedback = ({
  conversations,
}: {
  conversations: ReleaseConversation[];
}) => {
  const { simulators, chosenSimulatorId } = useReleases();

  const simulator = (simulators ?? []).find(
    (sim) => sim.id === chosenSimulatorId
  );
  const versions = simulator?.releases?.sort(
    (a, b) =>
      new Date(a.publishedAt ?? new Date().getDate()).getTime() -
      new Date(b.publishedAt ?? new Date().getDate()).getTime()
  );

  return (
    <div
      style={{
        display: "flex",
        height: "90%",
        padding: " 10px 20px 20px 0 ",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0, 5000px))",
          overflowY: "scroll",
          height: "200px",
          gap: "20px",
          flexGrow: 1,
        }}
      >
        {conversations
          .sort((a, b) => b.id - a.id)
          .filter((r) => r.feedback !== "NONE")
          .map((r) => {
            const notRealIndex = r.releaseComments
              .sort((a, b) => a.id - b.id)
              .slice(1, r.releaseComments.length)
              .findIndex((comment) => !comment.isAgent);
            const index = notRealIndex + 1;

            const answer =
              r.releaseComments
                .slice(0, index)
                .reverse()
                .find((comment) => comment.isAgent)?.comment ||
              "No agent comment found";
            return (
              <div>
                <Card>
                  <div style={{ display: "flex", gap: "4px" }}>
                    <Badge color={r.feedback === "POSITIVE" ? "green" : "red"}>
                      {r.feedback}
                    </Badge>
                    <Badge color={"gray"}>
                      {versions?.findIndex((v) => v.id === r.releaseId) === 0
                        ? "DRAFT"
                        : `version ${versions?.findIndex(
                            (v) => v.id === r.releaseId
                          )}`}
                    </Badge>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      margin: "8px 0",
                    }}
                  >
                    <Text
                      as="label"
                      size="2"
                      style={{ whiteSpace: "break-spaces" }}
                    >
                      {r.releaseComments[0].comment}
                    </Text>
                    <Separator my="3" size="4" />

                    <Text
                      as="label"
                      size="1"
                      style={{ whiteSpace: "break-spaces" }}
                    >
                      {answer}
                    </Text>
                  </div>
                  <Separator my="3" size="4" />
                  {/* <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Tooltip content="Remove it from the saved answers">
                      <Button color="red" variant="outline">
                        <InfoCircledIcon />
                        Revoke
                      </Button>
                    </Tooltip>
                    <Tooltip content="Start this conversation in the simulator">
                      <Button color="gray" highContrast>
                        <InfoCircledIcon />
                        Train
                      </Button>
                    </Tooltip>
                  </div> */}
                </Card>
              </div>
            );
          })}
      </div>
    </div>
  );
};
