import React, { useState } from "react";
import cx from "classnames";
import { Copy, Check } from "lucide-react";
import { Button } from "@radix-ui/themes";
import styles from "./styles.module.scss";

interface CopyButtonProps {
  textToCopy: string;
  label?: string;
  onClick?: (value: string) => void;
}

const CopyButton: React.FC<CopyButtonProps> = ({
  textToCopy,
  label = "Copy",
  onClick = (value: string) => {},
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
    onClick(textToCopy);
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };

  return (
    <Button
      variant="outline"
      color={isCopied ? "green" : "gray"}
      size="1"
      onClick={handleCopy}
      className={cx(styles.copyButton, { [styles.copied]: isCopied })}
    >
      <span className={styles.iconWrapper}>
        <Copy className={cx("h-4 w-4", { [styles.active]: !isCopied })} />
        <Check className={cx("h-4 w-4", { [styles.active]: isCopied })} />
      </span>
      <span className={styles.label}>{isCopied ? "Copied!" : label}</span>
    </Button>
  );
};

export default CopyButton;
