import { useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Em, Flex, Text } from "@radix-ui/themes";
import Select from "@/components/shared/select";
import Checkbox from "@/components/shared/inputs/checkbox";
import { useIntegrations } from "@/api/integration";
import { integrationNameToGalleryItem } from "@/api/integration/integrationNameToGalleryItem";
import { FieldGroup } from "../parts/FieldGroup";
import { SelectOption, MultiSelect } from "../parts/MultiSelect";
import { OptionalTextarea } from "../parts/OptionalTextarea";
import { SubFieldRadioGroup } from "../parts/SubFieldRadioGroup";
import { useSimulatorConfiguration } from "../useSimulatorConfiguration";
import { useUpdateReleasePolicyGeneral } from "../useUpdateReleasePolicyGeneral";
import styles from "../AutomationConfiguration.module.scss";
import { AutomationConfigurationContentHeader } from "../parts/AutomationConfigurationContentHeader";

export function TicketChannelGeneralConfiguration() {
  const { simulatorId } = useParams();
  const { release } = useSimulatorConfiguration(simulatorId);
  const { localPayload, handlePayloadChange, status } =
    useUpdateReleasePolicyGeneral(simulatorId);

  const { data: integrations, isLoading: loadingIntegrations } =
    useIntegrations();

  const sourceOptons = useMemo<SelectOption[]>(() => {
    if (integrations == null) {
      return [];
    }

    return integrations.map((integration) => {
      const { title: label, svg: icon } =
        integrationNameToGalleryItem[integration.name];

      const integrationTypeLable =
        integration.type === "TICKET_SYSTEM" ? "Ticketing" : "Knowledge";

      return {
        value: `${integration.type}@${integration.name}`,
        label: (
          <>
            {label}{" "}
            <Text color="gray">
              <Em>{integrationTypeLable}</Em>
            </Text>
          </>
        ),
        icon,
      };
    });
  }, [integrations]);

  if (release == null) {
    return <Navigate to={`/automation/${simulatorId}`} />;
  }

  return (
    <Flex direction={"column"} gap={"24px"}>
      <AutomationConfigurationContentHeader title={"General"} status={status} />

      <Flex direction={"column"}>
        <FieldGroup
          label={"Sources 📚"}
          description={
            "Choose the data sources that will feed information into the tickets channel."
          }
        >
          <MultiSelect
            options={sourceOptons}
            value={localPayload.sources ?? []}
            onChange={(newValue) => handlePayloadChange({ sources: newValue })}
            loading={loadingIntegrations}
          />
        </FieldGroup>

        <FieldGroup
          label={"Helpful Links"}
          description={
            "Choose whether the AI response should include helpful links."
          }
        >
          <Checkbox
            label="Include helpful links"
            value={localPayload.includeLink ?? false}
            onChange={(checked) =>
              handlePayloadChange({ includeLink: checked })
            }
          />
        </FieldGroup>

        <FieldGroup
          label={"Style & Tone"}
          description={"Set the tone your AI uses to match your brand."}
        >
          <SubFieldRadioGroup
            value={localPayload.styleAndTone ?? "context"}
            onChange={(newValue) => {
              if (newValue === "context") {
                const {
                  toneOfVoice,
                  openingLine,
                  closingLine,
                  ...restOfPayload
                } = localPayload;
                handlePayloadChange({
                  ...restOfPayload,
                  styleAndTone: "context",
                });
              } else {
                handlePayloadChange({
                  styleAndTone: "custom",
                  toneOfVoice: "formal",
                });
              }
            }}
            options={[
              {
                value: "context",
                label: "Context-aware interactions",
                description:
                  "Quack AI's smart defaults, ensuring smooth and context-aware interactions.",
              },
              {
                value: "custom",
                label: "Custom",
                description: "Define a specific tone and style for your AI.",
              },
            ]}
          >
            {localPayload.styleAndTone === "custom" && (
              <>
                <FieldGroup
                  label={"Tone of Voice 📣"}
                  description={"Set the tone your AI uses to match your brand."}
                >
                  <Select
                    options={[
                      { label: "Formal", value: "formal" },
                      { label: "Casual", value: "casual" },
                    ]}
                    value={localPayload.toneOfVoice ?? "formal"}
                    onChange={(newValue) =>
                      handlePayloadChange({ toneOfVoice: newValue })
                    }
                    classes={{ trigger: styles.Select }}
                  />
                </FieldGroup>
                <FieldGroup
                  label={"Always Open Chat With 👋🏻"}
                  description={"Set a standard opening line for responses."}
                >
                  <OptionalTextarea
                    value={localPayload.openingLine}
                    onChange={(newValue) =>
                      handlePayloadChange({ openingLine: newValue })
                    }
                    buttonText={"Add opening style"}
                    label={"Always Open Chat With 👋🏻"}
                    description={
                      "Use quotation marks (“ ”) for exact phrasing to ensure precise wording."
                    }
                  />
                </FieldGroup>

                <FieldGroup
                  label={"Always End Chat With 👋🏻"}
                  description={"Define a consistent closing line."}
                >
                  <OptionalTextarea
                    value={localPayload.closingLine}
                    onChange={(newValue) =>
                      handlePayloadChange({ closingLine: newValue })
                    }
                    buttonText={"Add closing style"}
                    label={"Always End Chat With 👋🏻"}
                    description={
                      "Use quotation marks (“ ”) for exact phrasing to ensure precise wording."
                    }
                  />
                </FieldGroup>
              </>
            )}
          </SubFieldRadioGroup>
        </FieldGroup>
      </Flex>
    </Flex>
  );
}
