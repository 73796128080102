import { LoopIcon, TimerIcon } from "@radix-ui/react-icons";
import styles from "./styles.module.scss";

export const tooltipSupportedTypes = [
  "status",
  "topic",
  "agent",
  "category",
  "team",
  "ttr",
  "channel",
  "kbCoverage",
  "avgTimeToResolve",
  "avgVolume",
  "avgQaScore",
  "avgBackAndForth",
  "resolutionRate",
  "trend",
  "avgSentiment",
  "priority",
  "currentTimeSpanTickets",
] as const;

export type TooltipSupportedType = (typeof tooltipSupportedTypes)[number];

export function isTooltipSupportedType(obj: any): obj is TooltipSupportedType {
  return tooltipSupportedTypes.includes(obj);
}

export const TooltipGenerator = <
  TooltipSupportedTypes extends TooltipSupportedType
>({
  type,
  page = "explore",
}: {
  type: TooltipSupportedTypes;
  page?: "explore" | "knowledge-gaps" | "knowledge-articles";
}) => {
  if (type === "status" && page === "explore") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Status</div>
        <div className={styles.description}>
          Imported from the ticketing system
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }
  if (type === "status" && page === "knowledge-gaps") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Status</div>
        <div className={styles.description}>
          Indicates the current review state of the knowledge gap.
        </div>
      </div>
    );
  }
  if (type === "priority") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Priority</div>
        <div className={styles.description}>
          Automatically assigned by Quack based on the total number of related
          tickets. Higher ticket volume means more urgent priority.
        </div>
      </div>
    );
  }
  if (type === "currentTimeSpanTickets") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Ticket Trend</div>
        <div className={styles.description}>
          Represents the change in ticket volume for the selected time period.
          Tracks how frequently this issue is occurring.
        </div>
      </div>
    );
  }
  if (type === "topic") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Topic</div>
        <div className={styles.description}>
          Auto Generated topic by Quack AI and Auto assigned to the ticket
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }
  if (type === "agent") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Agent</div>
        <div className={styles.description}>
          The agent assigned to the ticket - Imported from the ticketing system
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }
  if (type === "category") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Category</div>
        <div className={styles.description}>
          Represent the type of the ticket - Auto assigned by Quack AI
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }
  if (type === "team") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Team</div>
        <div className={styles.description}>
          The team of the agent that assigned to the ticket - Imported from the
          ticketing system
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }
  if (type === "ttr") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>
          <TimerIcon />
          Time To Resolution
        </div>
        <div className={styles.description}>
          The time from ticket creation to resolution, reflecting support
          efficiency
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }
  if (type === "channel") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Channel</div>
        <div className={styles.description}>
          The Channel that created the ticket - imported from the ticketing
          system
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }
  if (type === "kbCoverage") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Knowledge base Coverage</div>
        <div className={styles.description}>
          Measures is the knowledge the agent provided in the ticket covered in
          the help center articles - Generated by Quack AI
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }
  if (type === "avgTimeToResolve") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>
          <TimerIcon />
          Average Time to Resolution
        </div>
        <div className={styles.description}>
          Measures the time from ticket creation to resolution, reflecting
          support efficiency
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }
  if (type === "avgVolume") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Volume</div>
        <div className={styles.description}>
          The number of tickets in the selected period of time
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }
  if (type === "avgQaScore") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Average QA Score</div>
        <div className={styles.description}>
          {" "}
          Reflects the quality of support provided based on evaluated tickets.
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }
  if (type === "avgBackAndForth") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>
          <LoopIcon />
          Average Back and Forth
        </div>
        <div className={styles.description}>
          Average number of user-agent interactions. If only the agent sent
          messages, the value is under 1.
        </div>
        {/* <Button variant="outline" color="gray" size="1">
          <ExternalLinkIcon />
          Learn more
        </Button> */}
      </div>
    );
  }
  if (type === "resolutionRate") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Resolution</div>
        <div className={styles.description}>
          What percentage of tickets are resolved (closed or solved status)
        </div>
        {/* <Button variant="outline" color="gray" size="1">
      <ExternalLinkIcon />
      Learn more
    </Button> */}
      </div>
    );
  }
  if (type === "avgSentiment") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Sentiment</div>
        <div className={styles.description}>
          The average sentiment of the user's comments in the ticket
        </div>
      </div>
    );
  }
  if (type === "trend") {
    return (
      <div className={styles.avgTTR}>
        <div className={styles.title}>Trend</div>
        <div className={styles.description}>
          Ticket trend indicates changes in volume over time, showing if it's
          rising, falling, or steady, and highlights seasonal patterns or
          emerging issues.
        </div>
        {/* <Button variant="outline" color="gray" size="1">
      <ExternalLinkIcon />
      Learn more
    </Button> */}
      </div>
    );
  }

  return <></>;
};
