import { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Button, TextField } from "@radix-ui/themes";
import { useDebouncedCallback } from "@/lib/useDebouncedCallback";

export const Search = ({
  setSearchPhrase,
  placeholder,
  initialValue,
  debounce = 0,
}: {
  setSearchPhrase: (value: string) => void;
  placeholder: string;
  initialValue?: string;
  debounce?: number;
}) => {
  const [isSearchActive, setSearchActive] = useState(
    initialValue ? true : false
  );
  const [value, setValue] = useState(initialValue || "");
  const debouncedSetSearchPhrase = useDebouncedCallback(
    setSearchPhrase,
    debounce
  );

  useEffect(() => {
    debouncedSetSearchPhrase(value);
  }, [value]);

  return (
    <>
      {isSearchActive ? (
        <TextField.Root
          placeholder={placeholder}
          size="1"
          autoFocus
          variant="soft"
          color="gray"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          <TextField.Slot>
            <MagnifyingGlassIcon height="16" width="16" />
          </TextField.Slot>
        </TextField.Root>
      ) : (
        <Button
          color="gray"
          variant="ghost"
          highContrast
          size="1"
          onClick={() => setSearchActive(true)}
        >
          <MagnifyingGlassIcon /> Find by
        </Button>
      )}
    </>
  );
};
