import React, { ReactNode } from "react";
import { ResizableBox as ReactResizableBox, ResizeCallbackData, ResizableBoxProps } from "react-resizable";
const ResizableBoxComponent = ReactResizableBox as React.ComponentType<ResizableBoxProps>;

import "react-resizable/css/styles.css";

export default function ResizableBox({
  children,
  width = 450,
  height = 250,
  resizable = true,
  style = {},
  className = "",
}: {
  children: ReactNode;
  width?: number;
  height?: number;
  resizable?: boolean;
  style?: any;
  className?: string;
}) {
  return (
    <div style={{ marginLeft: 0 }}>
      <div
        style={{
          display: "inline-block",
          width: "auto",
          background: "white",
          padding: ".5rem",
          borderRadius: "0.5rem",
          boxShadow: "0 10px 20px rgba(0,0,0,.05)",
          ...style,
        }}
      >
        {resizable ? (
          <ResizableBoxComponent 
            width={width} 
            height={height} 
            onResize={(_e: React.SyntheticEvent, data: ResizeCallbackData) => {}}
            resizeHandles={["se"]}
            draggableOpts={{}}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
              className={className}
            >
              {children}
            </div>
          </ResizableBoxComponent>
        ) : (
          <div
            style={{
              width: `${width}px`,
              height: `${height}px`,
            }}
            className={className}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
}
