import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { params } from "../params";

interface FeatureAccessContextType {
  features: Features;
  updateFeatures: (newFeatures: Features) => void;
  selectedWorkspace: string;
  setSelectedWorkspace: (workspace: string) => void;
}

export const FeatureAccessContext =
  React.createContext<FeatureAccessContextType>({
    features: {
      resolution: true,
      quality: true,
      sessions: false,
      knowledge: true,
    },
    updateFeatures: () => {},
    selectedWorkspace: "",
    setSelectedWorkspace: () => {},
  });

interface FeatureAccessProviderProps {
  children: React.ReactNode;
}

interface Features {
  resolution: boolean;
  quality: boolean;
  sessions: boolean;
  knowledge: boolean;
  [key: string]: any;
}

export const FeatureAccessProvider: React.FC<FeatureAccessProviderProps> = ({
  children,
}) => {
  const { user } = useAuth0();

  const [selectedWorkspace, setSelectedWorkspace] = useState(() => {
    return "";
  });
  const [features, setFeatures] = React.useState<Features>({
    resolution: true,
    quality: true,
    sessions: false,
    knowledge: true,
  });

  useEffect(() => {
    const fetchTenantConfig = async () => {
      if (user && user.owner) {
        try {
          const response = await fetch(`${params.S3}/${user.owner}.json`, {
            headers: {
              "Access-Control-Allow-Origin": "*", // Allow all origins
            },
          });
          if (response.ok) {
            const data = await response.json();
            const { modules } = data;
            updateFeatures({
              resolution: modules.resolution,
              quality: modules.quality,
              sessions: modules.sessions,
              knowledge: modules.knowledge,
            });
          } else {
            console.error("Failed to fetch tenant configuration");
          }
        } catch (error) {
          console.error("Error fetching tenant configuration:", error);
        }
      }
    };

    fetchTenantConfig();
  }, [user]);

  const updateFeatures = (newFeatures: Features) => {
    setFeatures((prevFeatures) => ({ ...prevFeatures, ...newFeatures }));
  };

  useEffect(() => {
    if (user?.owner && !selectedWorkspace) {
      setSelectedWorkspace(
        localStorage.getItem(`selectedWorkspace-${user.owner}`) || ""
      );
    }
  }, [user]);

  const handleUpdateWorkspace = (value: string) => {
    if (user?.owner) {
      localStorage.setItem(`selectedWorkspace-${user.owner}`, value);
      setSelectedWorkspace(value);
    }
  };

  return (
    <FeatureAccessContext.Provider
      value={{
        features,
        updateFeatures,
        selectedWorkspace,
        setSelectedWorkspace: handleUpdateWorkspace,
      }}
    >
      {children}
    </FeatureAccessContext.Provider>
  );
};
