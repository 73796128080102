export const radixColors = [
  "blue",
  "bronze",
  "pink",
  "orange",
  "cyan",
  "yellow",
  "grass",
  "ruby",
  "gold",
  "teal",
  "plum",
  "iris",
  "green",
  "indigo",
  "crimson",
  "jade",
  "lime",
  "mint",
  "purple",
  "gray",
  "red",
  "sky",
  "tomato",
  "violet",
  "brown",
  // "amber",
  // "mauve",
  // "olive",
  // "sage",
  // "sand",
  // "slate",
] as const;

export type RadixColor = (typeof radixColors)[number];

export type RadixColorMap = Record<string, RadixColor>;

export function buildColorMap(values: string[], offset = 0): RadixColorMap {
  const uniqueValuesMap = new Map(values.map((value) => [value, value]));

  const valuesToColor: Record<string, RadixColor> = {};

  [...uniqueValuesMap]
    .sort((a, b) => a[1].localeCompare(b[1]))
    .forEach(([value], index) => {
      valuesToColor[value] = radixColors[(index + offset) % radixColors.length];
    });

  return valuesToColor;
}
