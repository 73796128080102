import { useAuth0 } from "@auth0/auth0-react";
import { useFeatureFlag } from "configcat-react";
import { useWorkspace } from "@/routes/simulator/context/wrokspace";

export function useAgentScoreFeatFlag() {
  const { user } = useAuth0();
  const { workspace } = useWorkspace();
  const { value } = useFeatureFlag("agent-score", false, {
    identifier: workspace ?? user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });

  return value;
}
