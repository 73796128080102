import * as Dialog from "@radix-ui/react-dialog";
import cx from "classnames";
import { RxReader, RxCircleBackslash, RxCross2 } from "react-icons/rx";
import { BadTicketQaScore } from "../model";
import styles from "../styles.module.scss";
import { Button, IconButton } from "@radix-ui/themes";
import { radixThemeDataAttributes } from "@/components/Theme";

const Title = () => {
  return (
    <div className={styles.metric}>
      <div className={styles.metricIcon} />
      <div>Underperforming metric</div>
    </div>
  );
};

const Description = ({
  content,
  ticketCount,
}: {
  content: string;
  ticketCount: number;
}) => {
  return (
    <div className={styles.description}>
      <div className={styles.content}>{content}</div>

      <div className={styles.ticketCount}>
        {`${ticketCount} ${ticketCount > 1 ? "ticket" : "tickets"} found`}
      </div>
    </div>
  );
};

const TicketListHeader = () => {
  return (
    <div className={styles.ticketListHeader}>
      <div>Tickets</div>
      <div>Result</div>
    </div>
  );
};

const TicketListItem = ({ ticketId }: { ticketId: string }) => {
  return (
    <div key={ticketId} className={styles.ticketListItem}>
      <div className={styles.ticketNo}>
        <RxReader />
        {ticketId.split("::")[1]}
      </div>

      <div className={styles.ticketResult}>
        <RxCircleBackslash />
        Not achieved
      </div>
    </div>
  );
};

const TicketListViewport = ({ ticketIds }: { ticketIds: string[] }) => {
  return (
    <>
      {ticketIds.map((ticketId) => (
        <TicketListItem ticketId={ticketId} />
      ))}
    </>
  );
};

const TicketListSeeMore = ({
  content,
  ticketIds,
}: {
  content: string;
  ticketIds: string[];
}) => {
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button variant="outline" color="gray" size="1" className={styles.Btn}>
          See more
        </Button>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className={styles.DialogOverlay} />
        <Dialog.Content
          className={cx(styles.DialogContent, "radix-themes")}
          {...radixThemeDataAttributes}
        >
          <Dialog.Title className={styles.Title}>
            <Title />

            <Dialog.Close asChild>
              <IconButton
                variant="outline"
                color="gray"
                size="1"
                className={styles.Btn}
              >
                <RxCross2 />
              </IconButton>
            </Dialog.Close>
          </Dialog.Title>

          <div className={styles.Main}>
            <div className={cx(styles.BadTicketQaScore)}>
              <Description content={content} ticketCount={ticketIds.length} />

              <div className={styles.ticketList}>
                <TicketListHeader />

                <TicketListViewport ticketIds={ticketIds} />
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const TicketList = ({
  content,
  ticketIds,
}: {
  content: string;
  ticketIds: string[];
}) => {
  return (
    <div className={styles.ticketList}>
      <TicketListHeader />

      <TicketListViewport ticketIds={ticketIds.slice(0, 3)} />

      {ticketIds.length > 3 && (
        <div>
          <TicketListSeeMore content={content} ticketIds={ticketIds} />
        </div>
      )}
    </div>
  );
};

const AreaForImprovement = ({ content, ticketIds }: BadTicketQaScore) => {
  const flatTicketIds = ticketIds.flat();

  return (
    <div className={cx(styles.item, styles.BadTicketQaScore)}>
      <Title />

      <Description content={content} ticketCount={flatTicketIds.length} />

      <TicketList content={content} ticketIds={flatTicketIds} />
    </div>
  );
};

const SharedReportAreasForImprovement = ({
  data,
}: {
  data: BadTicketQaScore[];
}) => {
  return (
    <div className={styles.areasForImprovement}>
      {data.map((item) => (
        <AreaForImprovement {...item} />
      ))}
    </div>
  );
};

export default SharedReportAreasForImprovement;
