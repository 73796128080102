import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Flex, IconButton, Badge } from "@radix-ui/themes";
import { ChevronLeftIcon, GearIcon } from "@radix-ui/react-icons";
import styles from "../AutomationConfiguration.module.scss";

export function AutomationConfigurationHeader() {
  const navigate = useNavigate();
  const { simulatorId } = useParams();

  const handleBack = useCallback(() => {
    navigate(`/automation/${simulatorId}`);
  }, [navigate, simulatorId]);

  return (
    <Flex
      flexShrink={"0"}
      py={"8px"}
      px={"12px"}
      gap={"2"}
      className={styles.Header}
    >
      <IconButton
        color="gray"
        variant="soft"
        highContrast
        size="1"
        onClick={handleBack}
      >
        <ChevronLeftIcon />
      </IconButton>

      <Badge color={"gray"} size={"2"} highContrast>
        <GearIcon />
        Configurations
      </Badge>
    </Flex>
  );
}
