import cx from "classnames";
import { Flex, Grid, Card, Button, Badge, Text } from "@radix-ui/themes";
import { CheckIcon } from "@radix-ui/react-icons";
import {
  IntegrationName,
  IntegrationType,
  Integration,
  isIntegrationCompleted,
  isIntegrationNotAvailable,
  supportedTicketSystems,
  TicketSystemIntegrationName,
} from "../../../../api/integration/Integration.model";
import { AddIntegrationConfig } from "../addIntegrationConfigs";
import { integrationNameToGalleryItem } from "../../../../api/integration/integrationNameToGalleryItem";
import { StepContent, StepFooter, StepHeader, StepMain } from "../parts";
import styles from "../AddIntegration.module.scss";

export interface SelectIntegrationProps {
  type: IntegrationType;
  existingIntegrations: Integration[];
  isMultipleAllowed?: boolean;
  onClick: (name: IntegrationName, type: IntegrationType) => void;
  onClose?: () => void;
}

const integrationTypeToContent: Record<
  IntegrationType,
  { title: string; description: string }
> = {
  CHAT: {
    title: "Communication",
    description:
      "Enable your team to ‘Ask Quack Anything’—get instant answers, insights, and support",
  },
  KNOWLEDGE_BASE: {
    title: "Knowledge & Docs",
    description:
      "Connect to give Quack access to information for analysis and smarter responses.",
  },
  TICKET_SYSTEM: {
    title: "Ticketing system",
    description:
      "Connect to manage, track, and escalate tickets through Quack.",
  },
  TASK_MANAGEMENT: {
    title: "Task Management system",
    description: "Connect to help Quack track issues and understand workflows.",
  },
  WIKI: {
    title: "",
    description: "",
  },
};

function SelectIntegration({
  type,
  existingIntegrations,
  title,
  description,
  options,
  isMultipleAllowed = false,
  onClick,
  onClose,
}: SelectIntegrationProps & AddIntegrationConfig["select"]) {
  return (
    <StepMain>
      <StepContent
        direction={"column"}
        gap={"16px"}
        className={styles.SelectIntegration}
      >
        <StepHeader
          title={title ?? integrationTypeToContent[type].title}
          description={
            description ?? integrationTypeToContent[type].description
          }
        />

        <Grid
          columns="2"
          gap="3"
          display="inline-grid"
          width={"100%"}
          height={"fit-content"}
        >
          {options?.map((item) => {
            const { title, svg } = integrationNameToGalleryItem[item];
            const isExists = existingIntegrations.some(
              (integ) =>
                (integ.type === type ||
                  (type === "KNOWLEDGE_BASE" && integ.type === "WIKI")) && // TODO: delete when internal KB gets design
                integ.name === item &&
                isIntegrationCompleted(integ)
            );

            const isNotAvaiable = existingIntegrations.some(
              (integ) =>
                (integ.type === type ||
                  (type === "KNOWLEDGE_BASE" && integ.type === "WIKI")) && // TODO: delete when internal KB gets design
                integ.name === item &&
                isIntegrationNotAvailable(integ)
            );

            // for existing TICKET_SYSTEM that we can't setup yet
            const isSetupNotSupported =
              type === "TICKET_SYSTEM" &&
              !isExists &&
              !isNotAvaiable &&
              !supportedTicketSystems.includes(
                item as TicketSystemIntegrationName
              );

            const isSingleIntegrationExists =
              !isMultipleAllowed &&
              existingIntegrations.some((integ) => integ.type === type);

            return (isSingleIntegrationExists && isExists) ||
              !isSingleIntegrationExists ? (
              <Card
                size="2"
                className={cx(styles.Card, {
                  [styles.exists]:
                    isExists ||
                    isNotAvaiable ||
                    isSingleIntegrationExists ||
                    isSetupNotSupported,
                })}
                onClick={
                  isExists ||
                  isNotAvaiable ||
                  isSingleIntegrationExists ||
                  isSetupNotSupported
                    ? undefined
                    : () => onClick(item, type)
                }
              >
                <Flex direction={"column"} gap={"15px"}>
                  <Flex gap={"15px"} justify={"between"}>
                    <div>{svg}</div>

                    {isExists && (
                      <Badge color="green" size={"2"}>
                        <CheckIcon />
                        Connected
                      </Badge>
                    )}

                    {isNotAvaiable && (
                      <Badge color="red" size={"2"}>
                        Connection Failed
                      </Badge>
                    )}

                    {isSetupNotSupported && (
                      <Badge color="gray" size={"2"}>
                        Coming Soon
                      </Badge>
                    )}
                  </Flex>

                  <Text size={"3"} weight={"medium"} as={"div"}>
                    {title}
                  </Text>
                </Flex>
              </Card>
            ) : (
              <></>
            );
          })}
        </Grid>
      </StepContent>

      <StepFooter>
        {onClose != null && (
          <Button color="gray" variant="solid" highContrast onClick={onClose}>
            Done
          </Button>
        )}
      </StepFooter>
    </StepMain>
  );
}

export default SelectIntegration;
