import { useMemo } from "react";
import { Box } from "@radix-ui/themes";
import { params } from "@/params";
import { ChatSettings, QuackChatConfigResponse } from "./QuackChatConfig.model";
import { Message } from "./core/entities/Message";
import { QuackChatComp } from "./core/parts/QuackChatComp";
import { QuackChatWidgetTheme } from "./core/parts/QuackChatWidgetTheme";
import { QuackChatCompContainer } from "./core/parts/QuackChatCompContainer";
import "./core/styles/index.scss";

export const defaultTheme: ChatSettings["theme"] = {
  iconPrimary: "#1A1A1A",
  widgetPrimary: "#1A1A1A",
  background: "#FFFFFF",
  lightBorder: "#656665",
  textPlaceholder: "#8F8F8F",
  borderDefault: "#F1F1F1",
  bgCard: "#E6E6E6",
  bgInverted: "#1D1D1D",
  textPrimary: "#1A1A1A",
  textInverted: "#FFFFFF",
  borderLight: "#CBCBCB",
  bgPrimaryQuack: "#FFFFFF",
  bgSendButton: "#6F5BE2",
  bgChatWidget: "#ffffff00",
  bgInput: "#F0F0F0",
  agentBackground: "#6F5BE2",
  agentText: "#FAFBFC",
  userBackground: "#FFFFFF",
  userText: "#000000",
};

const defaultChatSettings: ChatSettings = {
  name: "Quack demo's AI Agent",
  quackAgentName: "AI Assistant",
  customSVG:
    '<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" shape-rendering="geometricPrecision" viewBox="0 0 24 24" class="jsx-3060654314" height="24" width="24" style="color: currentcolor;"><path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path></svg>',
  borderRadius: 8,
  iconSize: 48,
  iconPadding: 4,
  poweredByLine: false,
  escalationMessageToLiveAgent:
    "We understand that this inquiry requires the help of one of our Support Agents. Please hold while we connect you to one of our Agents.",
  escalationMessageToNotLiveAgent:
    "We understand that this inquiry requires the help of one of our Support Agents. At this time all of our agents are offline, so we will open a ticket to the team on your behalf. They will get back to you as soon as they can.",
  theme: defaultTheme,
  disappearAfterClose: 10,
  fontSize: 14,
  waitTimeBeforeRefreshAfterEscalating: 300,
  openFullyOnOpenChat: false,
  csatDisabled: false,
  placeholder: "Ask anything...",
};

interface QuackChatProps {
  config: QuackChatConfigResponse;
  agents?: string[];
  messages?: Message[];
  thinking?: boolean;
  showCSAT?: boolean;
}

export function QuackChat({ config, ...props }: QuackChatProps) {
  const chatSettings = useMemo<ChatSettings>(() => {
    const { customSVG, closeSVG, avatarSVG } = config.chatSettings;

    return {
      ...defaultChatSettings,
      ...config.chatSettings,
      customSVG: `${params.SDK_CONFIG}${customSVG}`,
      ...(avatarSVG != null
        ? { avatarSVG: `${params.SDK_CONFIG}${avatarSVG}` }
        : {}),
      ...(closeSVG != null
        ? { closeSVG: `${params.SDK_CONFIG}${closeSVG}` }
        : {}),
    };
  }, [config.chatSettings]);

  return (
    <Box width={"476px"} height={"720px"} position={"relative"}>
      <QuackChatWidgetTheme themeVars={chatSettings.theme}>
        <QuackChatCompContainer
          open
          settings={chatSettings}
          onOpenChange={() => {}}
        >
          <QuackChatComp settings={chatSettings} {...props} />
        </QuackChatCompContainer>
      </QuackChatWidgetTheme>
    </Box>
  );
}
