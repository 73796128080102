import React, { useEffect, useMemo, useRef, useState } from "react";
import { Flex, Heading, Text } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import { useFeatureFlag } from "configcat-react";
import { useAuth0 } from "@auth0/auth0-react";

import { Navbar } from "@/components/shared/navbar/Navbar";
import { useOpportunityGroups } from "@/api/useOpportunityGroups";
import { Search } from "@/components/shared/inputs/search";
import { MultiSelect } from "@/components/shared/inputs/multi-select";
import { toCamelCaseWithFirstLetterCaps } from "@/assets/functions";
import { DateRangePicker } from "@/components/shared/date-range-picker/DateRangePicker";
import ExploreTable from "../explore/ExploreTable";
import { PageEmptyState } from "@/components/page-empty-state/PageEmptyState";
import { useOnboarding } from "@/api/onboarding";
import { Link } from "@/components/shared/link/Link";
import { KnowledgeEvents } from "@/assets/mixpanel/knowledge";
import { mixTrackEvent } from "@/assets/mixpanel";

import {
  OpportunityGroupPriority,
  OpportunityStatus,
  opportunityStatusesToLabels,
  opportunityGroupPriorities,
} from "./index.types";
import styles from "./styles.module.scss";
import {
  knowledgeGapsCellGenerator,
  knowledgeGapsCellValueGetter,
  KnowledgeGapsColumns,
  OpportunityGroupRow,
} from "./knowledge-gaps-table.types";
import { useKnowledgeGapsSearchParams } from "./useKnowledgeGapsSearchParams";

export const KnowledgeGapsPage = () => {
  const navigate = useNavigate();
  const { hasKnowledgeBaseIntegration } = useOnboarding();

  const {
    searchPhrase,
    setSearchPhrase,
    priority,
    setPriority,
    status,
    setStatus,
    dateRange,
    setDateRange,
  } = useKnowledgeGapsSearchParams();

  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [knowledgeGaps, setKnowledgeGaps] = useState<OpportunityGroupRow[]>([]);
  const [knowledgeGapsExists, setKnowledgeGapsExists] =
    useState<boolean>(false);
  const isFirstRender = useRef(true);

  const { data: opportunityGroups, isLoading } = useOpportunityGroups({
    findBy: searchPhrase,
    priority: priority,
    status: status,
    startDate: dateRange.dateFrom,
    page: page,
    endDate: dateRange.dateTo,
    type: ["knowledge"],
    enabled: true,
  });

  useEffect(() => {
    setPage(1);
    setKnowledgeGaps([]);
    if (!isFirstRender.current) {
      mixTrackEvent({
        event: KnowledgeEvents.GAPS_FILTER_CHANGED,
        properties: {
          searchPhrase,
          priority,
          status,
          dateRange,
        },
      });
    } else {
      isFirstRender.current = false;
    }
  }, [searchPhrase, priority, status, dateRange]);

  useEffect(() => {
    setHasNextPage(opportunityGroups?.length === 30);
    if (opportunityGroups && Array.isArray(opportunityGroups)) {
      setKnowledgeGaps((prev) => [
        ...prev,
        ...(opportunityGroups as OpportunityGroupRow[]),
      ]);
    }
  }, [opportunityGroups]);

  const {
    data: opportunityGroupsExists,
    isLoading: isLoadingOpportunityGroupsExists,
  } = useOpportunityGroups({
    enabled: !knowledgeGapsExists,
    type: ["knowledge"],
  });

  useEffect(() => {
    if (
      opportunityGroupsExists &&
      Array.isArray(opportunityGroupsExists) &&
      opportunityGroupsExists.length > 0
    ) {
      setKnowledgeGapsExists(true);
    }
  }, [opportunityGroupsExists]);

  const isEmpty = useMemo(
    () =>
      !isLoadingOpportunityGroupsExists &&
      !opportunityGroupsExists &&
      page === 1 &&
      opportunityGroups &&
      opportunityGroups?.length === 0,
    [knowledgeGaps, page, opportunityGroupsExists]
  );

  if (opportunityGroups?.type === "Error") {
    return (
      <PageEmptyState
        title={"Oops! Something went wrong"}
        description={
          "We're sorry, but we couldn't load the knowledge gap details right now. Please try again later."
        }
        svg={"knowledge-view"}
      ></PageEmptyState>
    );
  }

  if (isEmpty) {
    return (
      <PageEmptyState
        title={"No knowledge gaps available yet"}
        description={
          "There are no knowledge gaps to display right now. Once knowledge gaps are identified, they'll appear here for easy access and management."
        }
        svg={"knowledge-view"}
      >
        {!hasKnowledgeBaseIntegration && (
          <div>
            <Link to={"/getting-started"}>Connect knowledge sources</Link>
          </div>
        )}
      </PageEmptyState>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.controlRow}>
        <Navbar
          items={[
            { title: "Knowledge Management" },
            { title: "Knowledge Gaps" },
          ]}
        />
      </div>
      <div className={styles.main}>
        <div className={styles.content}>
          <Heading size={"4"} weight={"medium"}>
            Knowledge Gaps
          </Heading>
          <Flex direction={"column"} gap={"8"}>
            <Flex
              style={{
                padding: "0 4px",
                width: "100%",
              }}
              justify={"between"}
              align={"center"}
            >
              <Flex gap={"3"} align={"center"}>
                <Text weight={"medium"} size={"3"}>
                  Identified Knowledge Gaps
                </Text>
                <Text size={"2"} weight={"bold"} color="gray">
                  {knowledgeGaps && knowledgeGaps?.length}
                </Text>
                <Search
                  setSearchPhrase={setSearchPhrase}
                  placeholder="Find"
                  debounce={500}
                  initialValue={searchPhrase}
                />
              </Flex>
              <Flex gap={"1"} align={"center"}>
                <MultiSelect
                  options={opportunityGroupPriorities.map((priority) => ({
                    value: priority,
                    label: toCamelCaseWithFirstLetterCaps(priority),
                  }))}
                  initialValue={priority as string[]}
                  placeholder="Priorities"
                  onChange={(values) => {
                    setPriority(values as OpportunityGroupPriority[]);
                  }}
                />
                <MultiSelect
                  options={Object.entries(opportunityStatusesToLabels).map(
                    ([key, label]) => ({
                      value: key,
                      label: label,
                    })
                  )}
                  initialValue={status as string[]}
                  placeholder="Statuses"
                  onChange={(values) => {
                    setStatus(values as OpportunityStatus[]);
                  }}
                />
                <DateRangePicker
                  value={dateRange}
                  onChange={setDateRange}
                  hardBackground
                />
              </Flex>
            </Flex>
          </Flex>
          <ExploreTable<OpportunityGroupRow>
            rows={knowledgeGaps}
            columns={KnowledgeGapsColumns}
            isLoading={isLoading && page === 1}
            cellGenerator={knowledgeGapsCellGenerator}
            cellValueGetter={knowledgeGapsCellValueGetter}
            shadow
            onRowClick={(row) => {
              mixTrackEvent({
                event: KnowledgeEvents.GAP_REVIEWED,
                properties: {
                  opportunityGroupId: row.id,
                  label: row.label,
                },
              });
              const x = new URLSearchParams([
                ["searchPhrase", searchPhrase],
                ["priority", priority.join(",")],
                ["status", status.join(",")],
                ["dateFrom", dateRange.dateFrom],
                ["dateTo", dateRange.dateTo],
              ]);
              navigate(`/knowledge-management/gaps/${row.id}?${x.toString()}`);
            }}
            defaultSortState={{
              key: "label",
              direction: "asc",
            }}
            fetchNextPage={() => {
              setPage(page + 1);
            }}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isLoading}
            page="knowledge-gaps"
          />
        </div>
      </div>
    </div>
  );
};
