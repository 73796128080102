import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Loading } from "@geist-ui/core";
import { Message } from "ai/react";
import styled from "styled-components";
import { ArrowUpRight, Check, Copy, User } from "@geist-ui/icons";

import { EMessagesContainerType, TypeContext } from "./typeContext";
import { fadeAndMoveUp } from "./utils";
import { MessageFeedback } from "./message-feedback";

export const MessageComponent = ({
  message,
  isLoading = false,
  icon = <></>,
  isLastMessage = false,
  sendFeedback,
  isResponse,
}: {
  message: Message;
  isLoading?: boolean;
  icon?: React.ReactNode;
  isLastMessage?: boolean;
  isResponse?: boolean;
  sendFeedback?: (messageId: string, feedback: boolean) => Promise<void>;
}) => {
  const { type } = useContext(TypeContext);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messageRef = useRef<HTMLDivElement>(null);

  const [isCopied, setCopied] = useState(false);

  const handleCopy = () => {
    if (messageRef.current) {
      const textToCopy = messageRef.current.innerText;
      navigator.clipboard.writeText(textToCopy).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copy state after 2 seconds
      });
    }
  };

  const messageContainers = useMemo(() => {
    const formatContent = (content: string, disableSplitting = false) => {
      if (!content?.trim()) {
        return [];
      }

      const splitMessages = disableSplitting
        ? [
            content.split("\\n").join(`
          `),
          ]
        : content.split(/\n\s*\n/); // double line breaks - new message

      return splitMessages.map((msg) => {
        const parts = msg.split(/(\*\*.*?\*\*|\[.*?\]\(.*?\))/g);
        return parts.map((part, index) => {
          if (part.startsWith("**") && part.endsWith("**")) {
            return <strong key={index}>{part.slice(2, -2)}</strong>;
          } else if (part.match(/\[.*?\]\(.*?\)/)) {
            const [text, url] = part.slice(1, -1).split("](");
            return (
              <StyledLink
                key={index}
                href={url.startsWith("https://") ? url : `https://${url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {text}
              </StyledLink>
            );
          } else {
            return part
              .split("\n")
              .filter((line) => line.trim() !== "" && line.trim() !== ".")
              .map((line, lineIndex) => (
                <>
                  <div key={`${index}-${lineIndex}`}>
                    {line}
                    {lineIndex < part.split("\n").length - 1 && <br />}
                  </div>
                </>
              ));
          }
        });
      });
    };

    return formatContent(message.content, isResponse);
  }, [message.content, message.role, isResponse]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (isLastMessage) {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    });

    if (messageRef.current) {
      observer.observe(messageRef.current);
    }

    return () => {
      if (messageRef.current) {
        observer.unobserve(messageRef.current);
      }
    };
  }, [isLastMessage, messageContainers.length]);

  const isAssistant = message.role === "assistant";
  const feedback = isAssistant
    ? (message as { feedback?: boolean }).feedback
    : undefined;

  return (
    <MessageSection isAssistant={message.role === "assistant"}>
      {message.role === "assistant" ? (
        <AgentBubble>
          <svg
            width="15"
            height="17"
            viewBox="0 0 15 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.30237 0.520508C5.01261 0.520508 3.86158 1.11557 3.10945 2.04587C2.62371 2.64578 2.30438 3.38546 2.21997 4.19467L2.21997 4.19468H0.434118H0.434104H0.428223L0.433758 4.20091C0.429952 4.25315 0.428223 4.30574 0.428223 4.35901C0.428223 5.64774 1.47061 6.6929 2.7583 6.6974C2.92263 6.97798 3.11948 7.23745 3.34367 7.47063C4.09061 8.24698 5.13992 8.73029 6.30237 8.73029C7.04481 8.73029 7.74125 8.53309 8.34219 8.18816C9.57591 7.48031 10.4069 6.14973 10.4069 4.6254C10.4069 2.35828 8.56914 0.520508 6.30237 0.520508ZM4.5632 2.8627C4.64519 2.83745 4.73238 2.82395 4.82267 2.82395C5.31256 2.82395 5.70938 3.22078 5.70938 3.71032C5.70938 4.10472 5.45233 4.43858 5.09668 4.55378C5.01053 4.58181 4.91816 4.59703 4.82267 4.59703C4.33313 4.59703 3.93631 4.20021 3.93631 3.71032C3.93631 3.31107 4.20028 2.97341 4.5632 2.8627ZM13.8403 12.7587C12.3997 15.6265 9.69734 16.4796 7.47831 16.4796C5.25928 16.4796 1.22289 14.5246 1.22289 11.2624C1.22289 8.2515 3.31114 7.48241 3.34366 7.47065C4.09061 8.247 5.13992 8.73031 6.30237 8.73031C7.04481 8.73031 7.74124 8.53311 8.34219 8.18818C9.14933 8.70056 10.3242 9.21986 11.5016 8.93859C13.6075 8.4359 14.1209 7.02885 14.1209 7.02885C14.8228 9.22574 14.6865 11.0746 13.8403 12.7587ZM14.196 8.79498C13.9192 12.1675 11.1258 14.8169 7.72085 14.8169C5.39422 14.8169 3.35337 13.5797 2.20545 11.7205C3.48241 13.1881 5.3534 14.1142 7.43819 14.1142C10.6972 14.1142 13.4334 11.8519 14.196 8.79498Z"
              fill="#25262B"
            />
          </svg>
        </AgentBubble>
      ) : (
        <UserBubble>
          <User />
        </UserBubble>
      )}
      <MessageWrapper>
        {messageContainers.map((formattedMessage, index) => (
          <>
            <MessageContainer
              ref={messageRef}
              className={`message ${
                message.role === "user" ? "user" : "agent"
              } ${isLoading ? "loading" : ""} ${
                type === EMessagesContainerType.PROACTIVE ? "proactive" : ""
              }`}
              key={message.id + index}
            >
              {!isLoading ? (
                <div className="messageContent">
                  {formattedMessage}

                  {isResponse && (
                    <button
                      className={` absolute right-2 bottom-2    ${
                        isCopied ? " text-green-600" : ""
                      }`}
                      onClick={handleCopy}
                    >
                      {isCopied ? (
                        <Check
                          className={` h-4 w-4 ${
                            isCopied ? " text-green-600" : ""
                          }`}
                        />
                      ) : (
                        <Copy
                          className={` h-4 w-4 ${
                            isCopied ? " text-green-600" : ""
                          }`}
                        />
                      )}
                    </button>
                  )}
                </div>
              ) : (
                <Loading width={2.5} />
              )}
            </MessageContainer>
          </>
        ))}

        {isAssistant && (
          <MessageRateButtonSet>
            <MessageFeedback
              feedback={feedback}
              sendFeedback={async (feedback) => {
                if (sendFeedback) await sendFeedback(message.id, feedback);
              }}
            />
          </MessageRateButtonSet>
        )}

        <div ref={messagesEndRef} />
      </MessageWrapper>
    </MessageSection>
  );
};

const MessageRateButtonSet = styled.div`
  /* display: none; */
  display: flex;
  justify-content: flex-start;
  gap: 8px;
`;

const MessageSection = styled.div<{ isAssistant: boolean }>`
  display: flex;
  gap: 8px;

  &:last-child ${MessageRateButtonSet} {
    display: flex;
  }

  ${({ isAssistant }) =>
    isAssistant && `&:last-child ${MessageRateButtonSet}`} {
    display: flex;
  }
`;

const AgentBubble = styled.div`
  background-color: #eff0f3;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
`;

const UserBubble = styled.div`
  background-color: #edf6ff;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  svg {
    height: 14px;
    width: 14px;
  }
`;

const MessageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &:hover ${MessageRateButtonSet} {
    display: flex;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  max-width: 85%;
  align-self: flex-start;
  padding: 5px 10px;
  border-radius: 8px;
  background: #edf6ff;
  color: ${(props) => props.theme.light.textPrimary};
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  animation: ${fadeAndMoveUp} 0.2s ease-in-out;

  &.agent {
    align-self: flex-start;
    background: ${(props) => props.theme.light.bgCard};
    position: relative;
  }

  &.loading {
    justify-content: flex-start;

    > * {
      width: 45px;
    }
  }

  &.proactive {
    max-width: 100%;
    width: fit-content;
    .messageContent {
      display: flex;
      gap: 10px;
      align-items: center;
      position: relative;
    }
  }
`;

const StyledLink = styled.a`
  font-weight: bold;
  text-decoration: underline;
  color: inherit;
`;
