function HeadingSVG() {
  return (
    <svg
      width="313"
      height="202"
      viewBox="0 0 313 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1784_12724)">
        <path
          d="M97.0001 240.798L97.0001 113.599L207.158 49.9999"
          stroke="url(#paint0_linear_1784_12724)"
        />
        <path
          d="M109.472 247.999L109.472 120.8L219.629 57.2004"
          stroke="url(#paint1_linear_1784_12724)"
        />
        <path
          d="M121.942 255.199L121.942 128L232.1 64.4006"
          stroke="url(#paint2_linear_1784_12724)"
        />
        <rect
          width="72.0024"
          height="72.0024"
          transform="matrix(0.866025 0.5 -0.866025 0.5 109.356 71.5007)"
          fill="white"
        />
        <rect
          width="72.0024"
          height="36.0012"
          transform="matrix(0.866025 0.5 0 1 47 107.502)"
          fill="#D9D9D9"
        />
        <rect
          width="72.0024"
          height="36.0012"
          transform="matrix(0.866025 -0.5 0 1 109.356 143.503)"
          fill="#B3B3B3"
        />
        <rect
          width="72.0024"
          height="72.0024"
          transform="matrix(0.866025 0.5 -0.866025 0.5 204.356 0)"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M179.342 32.0911C179.322 32.4438 179.344 32.7972 179.408 33.1485C179.666 34.5651 180.616 35.947 182.255 37.1131V39.2799C180.616 38.1138 179.666 36.7319 179.408 35.3152C179.344 34.964 179.322 34.6106 179.342 34.2578V32.0911Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M182.254 37.1128L178.218 39.4432V41.61L182.254 39.2796V37.1128Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M178.218 39.4436C178.222 39.4468 178.226 39.4495 178.231 39.4522C178.341 39.5253 178.456 39.5962 178.576 39.6657C181.489 41.3474 186.207 41.351 189.127 39.6766C190.133 39.8283 191.164 39.91 192.198 39.9217V42.0885C191.164 42.0767 190.133 41.995 189.127 41.8433C186.207 43.5178 181.489 43.5142 178.576 41.8325C178.456 41.763 178.341 41.6921 178.231 41.619C178.226 41.6163 178.222 41.6136 178.218 41.6104V39.4436Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M192.198 39.9219C192.178 39.9473 191.589 40.6834 191.516 41.8345V44.0013C191.589 42.8502 192.178 42.1141 192.198 42.0887V39.9219Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M191.516 41.834C191.423 43.2991 192.165 45.4365 195.976 47.6367C203.349 51.8935 216.89 49.1775 221.906 46.2819C225.517 44.197 228.695 41.2081 228.987 37.5994V39.7661C228.695 43.3749 225.517 46.3638 221.906 48.4487C216.89 51.3443 203.349 54.0603 195.976 49.8035C192.165 47.6032 191.423 45.4658 191.516 44.0008V41.834Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M228.988 37.6C229.102 36.1966 228.779 34.6995 227.876 33.1254C225.982 29.8234 222.112 27.233 215.56 25.2822V27.449C222.112 29.3998 225.982 31.9902 227.876 35.2921C228.779 36.8663 229.102 38.3634 228.988 39.7668V37.6Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M215.56 25.2822C215.56 25.2822 216.106 25.9599 216.121 27.089V29.2558C216.106 28.1266 215.56 27.449 215.56 27.449V25.2822Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M216.12 27.0894C216.134 28.1316 215.695 29.5587 213.955 31.1926C211.93 33.0959 208.101 33.9514 205.119 34.336C204.976 34.64 204.8 34.9397 204.591 35.2334V37.4002C204.8 37.1065 204.976 36.8067 205.119 36.5028C208.101 36.1182 211.93 35.2627 213.955 33.3593C215.695 31.7254 216.134 30.2984 216.12 29.2561V27.0894Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M204.591 35.233C204.8 34.9393 204.976 34.6395 205.119 34.3356C205.404 33.7276 205.556 33.103 205.574 32.4773V34.6441C205.556 35.2697 205.404 35.8943 205.119 36.5024C204.976 36.8063 204.8 37.1061 204.591 37.3998V35.233Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M205.573 32.4771C205.631 30.4958 204.351 28.5038 201.732 26.992C196.608 24.0337 188.301 24.0337 183.178 26.9916C180.729 28.4053 179.45 30.2392 179.343 32.0911V34.2579C179.45 32.406 180.729 30.5721 183.178 29.1584C188.301 26.2004 196.608 26.2004 201.732 29.1588C204.351 30.6705 205.631 32.6626 205.573 34.6439V32.4771Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M189.876 33.0415C189.888 33.311 189.785 33.5818 189.568 33.8276C189.437 33.9766 189.262 34.117 189.046 34.2416C187.94 34.8804 186.146 34.8804 185.039 34.2411C184.497 33.9285 184.221 33.5209 184.209 33.1112V35.278C184.221 35.6876 184.497 36.0953 185.039 36.4079C186.146 37.0471 187.94 37.0472 189.046 36.4084C189.262 36.2837 189.437 36.1433 189.568 35.9943C189.785 35.7486 189.888 35.4777 189.876 35.2083V33.0415Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M184.21 33.1116C184.202 32.8385 184.312 32.5646 184.54 32.3174C184.669 32.1774 184.835 32.0461 185.039 31.9282C186.147 31.289 187.94 31.289 189.047 31.9278C189.582 32.2366 189.858 32.6377 189.876 33.0419V35.2087C189.858 34.8045 189.582 34.4034 189.047 34.0946C187.94 33.4558 186.147 33.4558 185.039 34.095C184.835 34.2128 184.669 34.3442 184.54 34.4842C184.312 34.7314 184.202 35.0053 184.21 35.2784V33.1116Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M178.231 39.4519C178.227 39.4493 178.222 39.4467 178.218 39.4436L178.206 39.451V41.6178L178.218 41.6104C178.222 41.6134 178.227 41.616 178.231 41.6186V39.4519Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M178.206 39.4514L178.231 39.4523V41.6191L178.206 41.6182V39.4514Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M219.721 27.4888C222.799 29.584 224.46 32.1324 224.7 34.7079V36.8747C224.46 34.2991 222.799 31.7508 219.721 29.6555V27.4888Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M224.699 34.7087C225.004 37.9867 223.006 41.3088 218.696 43.797C213.437 46.8331 206.029 47.8818 199.232 46.9536V49.1204C206.029 50.0486 213.437 48.9999 218.696 45.9638C223.006 43.4756 225.004 40.1535 224.699 36.8755V34.7087Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M199.232 46.9534C205.435 47.2021 211.757 45.9692 216.469 43.2487C220.594 40.8672 222.745 37.7738 222.928 34.6375V36.8042C222.745 39.9406 220.594 43.034 216.469 45.4155C211.757 48.136 205.435 49.3689 199.232 49.1201V46.9534Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M222.929 34.6369C223.073 32.1726 222.002 29.6818 219.721 27.4888V29.6555C222.002 31.8485 223.073 34.3394 222.929 36.8037V34.6369Z"
          fill="#A09F9D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M183.177 26.9911C180.262 28.6741 179.006 30.9526 179.408 33.148C179.666 34.5647 180.616 35.9466 182.255 37.1127C182.541 37.3167 182.849 37.5145 183.177 37.7041C184.441 38.4336 185.898 38.9831 187.454 39.3523C188.001 39.4833 188.56 39.5912 189.128 39.676C190.133 39.8277 191.165 39.9094 192.199 39.9212C193.059 39.9308 193.921 39.8918 194.771 39.8043C193.921 39.8918 193.059 39.9308 192.198 39.9212C192.152 39.979 189.17 43.7076 195.975 47.6366C203.348 51.8934 216.89 49.1774 221.905 46.2818C226.921 43.3861 231.1 38.7465 227.875 33.1246C225.981 29.8226 222.11 27.2322 215.558 25.2814C215.558 25.2814 217.578 27.7874 213.954 31.1914C211.929 33.0947 208.1 33.9502 205.118 34.3349C206.306 31.8013 205.177 28.9806 201.732 26.9915C196.608 24.0331 188.301 24.0331 183.177 26.9911ZM184.54 32.3169C184.668 32.1769 184.835 32.0455 185.039 31.9277C186.146 31.2885 187.94 31.2885 189.047 31.9273C189.938 32.4419 190.112 33.213 189.568 33.8274C189.437 33.9764 189.262 34.1168 189.047 34.2414C187.94 34.8802 186.146 34.8802 185.039 34.241C184.137 33.72 183.97 32.9349 184.54 32.3169ZM178.218 39.4433L182.255 37.113C182.541 37.317 182.849 37.5148 183.177 37.7044C184.441 38.4339 185.898 38.9834 187.454 39.3526C188.001 39.4836 188.56 39.5915 189.128 39.6763C186.207 41.3508 181.489 41.3472 178.576 39.6655C178.456 39.596 178.341 39.5251 178.231 39.452L178.205 39.4511L178.218 39.4434L178.218 39.4433ZM219.719 27.4884C226.716 32.2504 226.391 39.3527 218.695 43.7959C213.436 46.8319 206.027 47.8807 199.231 46.9525C205.434 47.2012 211.756 45.9683 216.468 43.2478C223.834 38.9951 224.905 32.4725 219.719 27.4884Z"
          fill="#EBEBEB"
        />
        <rect
          width="72.0024"
          height="36.0012"
          transform="matrix(0.866025 0.5 0 1 142 36.0012)"
          fill="#D9D9D9"
        />
        <rect
          width="72.0024"
          height="36.0012"
          transform="matrix(0.866025 -0.5 0 1 204.356 72.0024)"
          fill="#B3B3B3"
        />
        <rect
          width="20.7747"
          height="20.7747"
          transform="matrix(0.866025 0.5 -0.866025 0.5 109.093 94.7471)"
          fill="#ECECEC"
        />
        <path
          d="M91.1016 105.134L109.093 115.522V123.909L91.1016 113.522V105.134Z"
          fill="#D9D9D9"
        />
        <path
          d="M109.093 115.522L127.084 105.135V113.522L109.093 123.909V115.522Z"
          fill="#B3B3B3"
        />
        <rect
          width="20.7747"
          height="20.7747"
          transform="matrix(0.866025 0.5 -0.866025 0.5 109.094 86.801)"
          fill="#EBEBEB"
        />
        <path
          d="M91.1021 97.1882L109.093 107.576V112.963L91.1021 102.576V97.1882Z"
          fill="#D9D9D9"
        />
        <path
          d="M109.093 107.576L127.085 97.1886V102.576L109.093 112.963V107.576Z"
          fill="#B3B3B3"
        />
        <circle
          cx="144.25"
          cy="86.2501"
          r="1.75008"
          fill="#F9F9FB"
          stroke="#D8D9E0"
        />
        <circle
          cx="175.25"
          cy="97.2501"
          r="1.75008"
          fill="#F9F9FB"
          stroke="#D8D9E0"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1784_12724"
          x1="171.512"
          y1="70.5801"
          x2="175.51"
          y2="219.064"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D8D9E0" />
          <stop offset="1" stop-color="#F9F9FB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1784_12724"
          x1="164.551"
          y1="89.0001"
          x2="176.983"
          y2="199.822"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D8D9E0" />
          <stop offset="1" stop-color="#F9F9FB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1784_12724"
          x1="177.021"
          y1="96.2003"
          x2="182.001"
          y2="200.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D8D9E0" />
          <stop offset="1" stop-color="#F9F9FB" />
        </linearGradient>
        <clipPath id="clip0_1784_12724">
          <rect width="313" height="202" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HeadingSVG;
