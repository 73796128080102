import React from "react";
import ReactDOM from "react-dom";
import "vercel-toast/css";
import { ConfigCatProvider } from "configcat-react";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "react-query";
import "@radix-ui/themes/styles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { params } from "./params";
import { GeistContainer } from "./components/geistContainer";
import { QuackTheme } from "./components/Theme";
import { FeatureAccessProvider } from "./context/featureAccess";
import * as Sentry from "@sentry/react";
import "./index.css";
import "./theme.scss";
import "./styles/overrides.css";
import { ApiProvider } from "./api/useApi";

Sentry.init({
  dsn: "https://d429c60c36a2781d37bdd1aec6efe583@o4506401607909376.ingest.us.sentry.io/4508552236957696",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  tracePropagationTargets: [],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: params.SENTRY_ENABLED,
  environment: params.SENTRY_ENVIRONMENT,
});

const queryClient = new QueryClient();

const getSubdomain = () => {
  const host = window.location.host;
  const parts = host.split(".");
  if (parts.length > 2) {
    return parts[0];
  }
  return null;
};

const subdomain = getSubdomain();

const organizationId = subdomain === "global-e" ? "org_qOK3qqQpXsvFOBtF" : "";

console.log({ organizationId, subdomain });
(async () => {
  ReactDOM.render(
    <React.StrictMode>
      <ConfigCatProvider sdkKey={params.FEATURE_FLAG_ID}>
        <Auth0Provider
          domain={params.AUTH0_DOMAIN}
          clientId={params.CLIENT_ID}
          authorizationParams={
            organizationId
              ? {
                  redirect_uri: window.location.origin,
                  organization: organizationId,
                }
              : {
                  redirect_uri: window.location.origin,
                }
          }
        >
          <FeatureAccessProvider>
            <ApiProvider>
              <QueryClientProvider client={queryClient}>
                <QuackTheme>
                  <GeistContainer>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </GeistContainer>
                </QuackTheme>
              </QueryClientProvider>
            </ApiProvider>
          </FeatureAccessProvider>
        </Auth0Provider>
      </ConfigCatProvider>
    </React.StrictMode>,
    document.getElementById("root") as HTMLElement
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
