import { PropsWithChildren } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  ConfigurationView,
  useGetChannelConfigurationViews,
} from "../AutomationConfiguration.config";
import { useSimulatorConfiguration } from "../useSimulatorConfiguration";

interface ConfigurationViewGuardProps {
  view: ConfigurationView;
}

export function ConfigurationViewGuard({
  view,
  children,
}: PropsWithChildren<ConfigurationViewGuardProps>) {
  const { simulatorId } = useParams();
  const { loadingSimulator } = useSimulatorConfiguration(simulatorId);
  const { views } = useGetChannelConfigurationViews();

  if (loadingSimulator) {
    return <></>;
  }

  if (views == null || !views.includes(view)) {
    return <Navigate to={`/`} />;
  }

  return <>{children}</>;
}
