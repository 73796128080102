import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  useExploreConversations,
  useInfiniteConversations,
  useExploreValues,
} from "../../api/useExplore";
import { useAuth0 } from "@auth0/auth0-react";
import { Navbar } from "@/components/shared/navbar/Navbar";
import { FilterProvider, useFilter } from "./context";
import { Search } from "./actions/search";
import { pasrseFilterValue } from "./context/FilterContext.model";
import SlicedBy from "./SlicedBy";
import { DateRangePicker } from "@/components/shared/date-range-picker/DateRangePicker";
import { useExploreNavbar, UseExploreNavbarOptions } from "./ExploreNavbar";
import styles from "./styles.module.scss";
import { conversation, exploreConversation } from "./stractures";
import ExploreTable, { TableSum } from "./ExploreTable";
import {
  conversationCellGenerator,
  conversationCellValueGetter,
  exploreConversationCellGenerator,
} from "./CellGenerator";
import { GroupBy } from "./GroupBy";
import {
  ConversationRow,
  ExploreConversationRow,
  translateColumnsToConversationRows,
  translateColumnsToExploreConversationRows,
} from "../../api/useExplore/ExploreConversation.model";
import { ConversationMessages } from "./ConversationMessages";
import dayjs from "dayjs";
import { PageEmptyState } from "@/components/page-empty-state/PageEmptyState";
import { ExploreConversationsSVG } from "@/components/page-empty-state/assests/ExploreConversationsSVG";
import { Link } from "@/components/shared/link/Link";
import { useOnboarding } from "@/api/onboarding";

interface ExploreComponentProps {
  navbarOptions?: UseExploreNavbarOptions;
  onConversationClick?: (ticket: ConversationRow) => void;
  emptyStateOverlay?: ReactNode;
}

export const ExploreComponent = ({
  navbarOptions = {
    staticPrefix: ["Conversations"],
  },
  onConversationClick,
  emptyStateOverlay,
}: ExploreComponentProps) => {
  const { user, getIdTokenClaims } = useAuth0();
  const {
    mutate: loadExplore,
    isLoading: exploreLoading,
    data: exploreData,
  } = useExploreConversations();
  const { data: exploreValues } = useExploreValues(!!user);

  const {
    groupBy,
    stringSliceBy,
    numericSliceBy,
    dateRange,
    setDateRange,
    view,
    mainSliceBy,
    diveIntoConversations,
  } = useFilter();

  const exploreNavbarItems = useExploreNavbar(navbarOptions);

  const [token, setToken] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState("");

  const conversationsQuery = useInfiniteConversations(
    {
      groupBy: groupBy,
      ...(numericSliceBy ? { numericSliceBy } : {}),
      ...(stringSliceBy ? { stringSliceBy } : {}),
      dateRange,
      mainSliceBy,
      includeMessages: true,
    },
    !!user && view === "conversations"
  );

  const data =
    view === "conversations" ? conversationsQuery.data?.pages[0] : exploreData;
  const isLoading =
    view === "conversations" ? conversationsQuery.isLoading : exploreLoading;

  const exploreTableProps = useMemo(() => {
    const columns = exploreConversation.map((column) => {
      if (column.key === "name") {
        return { ...column, label: groupBy ?? "topics" };
      }

      return column;
    });

    if (exploreData == null) {
      return { columns };
    }

    const rows = translateColumnsToExploreConversationRows(exploreData).filter(
      ({ avgVolume, name }) =>
        (avgVolume ?? 0) >= 1 && name.toLowerCase().includes(searchPhrase)
    );

    return { columns, rows };
  }, [exploreData, groupBy, searchPhrase]);

  const conversationTableProps = useMemo(() => {
    const rows = conversationsQuery.data?.pages
      .map((page) => {
        return translateColumnsToConversationRows(page);
      })
      .flat();

    return { columns: conversation, rows };
  }, [conversationsQuery]);

  const isEmpty = useMemo(() => {
    return data?.metadata?.conversationsCount === 0;
  }, [data]);

  const handleExloreRowClick = useCallback(
    (row: ExploreConversationRow) => {
      diveIntoConversations(row.name);
    },
    [diveIntoConversations]
  );

  useEffect(() => {
    getIdTokenClaims().then((claims) => {
      setToken(true);
    });
  }, [getIdTokenClaims]);

  useEffect(() => {
    if (user && token) {
      if (view == null) {
        loadExplore(
          pasrseFilterValue({
            groupBy: groupBy,
            ...(numericSliceBy ? { numericSliceBy } : {}),
            ...(stringSliceBy ? { stringSliceBy } : {}),
            dateRange,
            mainSliceBy,
          })
        );
      }
    }
  }, [
    groupBy,
    view,
    user,
    token,
    loadExplore,
    dateRange,
    numericSliceBy,
    stringSliceBy,
    mainSliceBy,
  ]);

  if (isEmpty && emptyStateOverlay) {
    return (
      <div className={styles.exploreContainer}>
        <div className={styles.controlRow}>
          <Navbar items={[{ title: "Conversations" }]} />
        </div>

        {emptyStateOverlay}
      </div>
    );
  }

  return (
    <div className={styles.exploreContainer}>
      <div className={styles.controlRow}>
        <Navbar items={exploreNavbarItems} />

        <div className={styles.buttonGroup}>
          {/* <LLM
            data={
              view === "conversations"
                ? conversationTableProps.rows
                : exploreTableProps.rows
            }
          /> */}
        </div>
      </div>

      <div className={styles.controlRow}>
        <div className={styles.buttonGroup}>
          <SlicedBy
            isLoading={isLoading}
            count={data?.metadata?.conversationsCount}
            groupBy={data?.metadata?.groupBy}
            exploreValues={exploreValues}
            type={"conversation"}
          />
        </div>

        <div className={styles.buttonGroup}>
          <GroupBy type="conversation" />
          <DateRangePicker value={dateRange} onChange={setDateRange} />
        </div>
      </div>

      <div className={styles.controlRow}>
        <div className={styles.buttonGroup}>
          <Search {...{ searchPhrase, setSearchPhrase }} />
        </div>
      </div>

      <TableSum
        isLoading={isLoading}
        {...(view === "conversations"
          ? {
              label: "Conversations",
              count: conversationTableProps.rows?.length,
              totalCount: data?.metadata?.conversationsCount,
            }
          : {
              label: groupBy ?? "topics",
              count: exploreTableProps.rows?.length,
            })}
      />

      {view === "conversations" ? (
        <ExploreTable<ConversationRow>
          {...conversationTableProps}
          isLoading={isLoading}
          groupBy={groupBy}
          hasNextPage={conversationsQuery.hasNextPage}
          fetchNextPage={conversationsQuery.fetchNextPage}
          isFetchingNextPage={conversationsQuery.isFetchingNextPage}
          onRowClick={onConversationClick}
          cellGenerator={conversationCellGenerator}
          cellValueGetter={conversationCellValueGetter}
        />
      ) : (
        <ExploreTable<ExploreConversationRow>
          {...exploreTableProps}
          isLoading={isLoading}
          groupBy={groupBy}
          onRowClick={handleExloreRowClick}
          cellGenerator={exploreConversationCellGenerator}
        />
      )}
    </div>
  );
};

function ExploreConversationsEmptyState() {
  return (
    <PageEmptyState
      title={"No conversations yet"}
      description={
        "Once your AI chat is live, all its conversations will appear here. Release your AI to start seeing interactions in action!"
      }
      svg={"explore-conversations"}
    />
  );
}

function ExploreInner() {
  const { backfillStatus } = useOnboarding();

  const [conversation, setConversation] = useState<ConversationRow>();

  const exploreNavbarItems = useExploreNavbar({});

  const toggleConversation = useCallback((ticket: ConversationRow) => {
    setConversation(ticket);
  }, []);

  const handleClose = useCallback(() => {
    setConversation(undefined);
  }, []);

  return (
    <>
      <ExploreComponent
        onConversationClick={toggleConversation}
        emptyStateOverlay={
          backfillStatus !== "completed" && <ExploreConversationsEmptyState />
        }
      />

      <ConversationMessages
        conversation={conversation}
        navbarItems={exploreNavbarItems}
        onClose={handleClose}
      />
    </>
  );
}

export const ExploreConversation = () => {
  return (
    <FilterProvider
      source="explore-conversation"
      defaultValue={{
        dateRange: {
          dateFrom: dayjs().subtract(14, "days").format("YYYY-MM-DD"),
          dateTo: dayjs().format("YYYY-MM-DD"),
        },
        groupBy: "topics",
      }}
    >
      <ExploreInner />
    </FilterProvider>
  );
};
