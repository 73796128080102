import { useMemo } from "react";
import { NavbarItem } from "@/components/shared/navbar/Navbar";
import { useFilter } from "./context";
import {
  FilterValue,
  findDiveHierarchy,
  groupByToLabel,
} from "./context/FilterContext.model";

export interface UseExploreNavbarOptions {
  titleOverrides?: Record<string, string>;
  staticPrefix?: string[];
  staticSuffix?: string;
}

export function useExploreNavbar({
  titleOverrides = {},
  staticPrefix,
  staticSuffix,
}: UseExploreNavbarOptions) {
  const {
    groupBy,
    stringSliceBy,
    numericSliceBy,
    dateRange,
    view,
    mainSliceBy,
    resetFilter,
  } = useFilter();

  return useMemo<NavbarItem[]>(() => {
    function overrideTitle(title = ""): string {
      return titleOverrides[title] ?? title;
    }

    const currFilterValue: FilterValue = {
      groupBy,
      stringSliceBy,
      numericSliceBy,
      dateRange,
      view,
      mainSliceBy,
    };

    const currGroupByLabel =
      currFilterValue.groupBy != null
        ? groupByToLabel[currFilterValue.groupBy]
        : currFilterValue.groupBy;

    return [
      ...(staticPrefix?.map((title) => ({ title })) ?? []),
      ...findDiveHierarchy(currFilterValue).map((value) => {
        const groupByLabel =
          value.groupBy != null ? groupByToLabel[value.groupBy] : value.groupBy;

        return {
          title: overrideTitle(value.mainSliceBy?.value ?? groupByLabel),
          onClick: () => resetFilter(value),
        };
      }),
      {
        title: overrideTitle(
          currFilterValue.mainSliceBy?.value ?? currGroupByLabel
        ),
      },
      ...(staticSuffix != null ? [{ title: staticSuffix }] : []),
    ];
  }, [
    groupBy,
    stringSliceBy,
    numericSliceBy,
    dateRange,
    view,
    mainSliceBy,
    staticPrefix,
    staticSuffix,
    titleOverrides,
    resetFilter,
  ]);
}
