import { useMemo, useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import cx from "classnames";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
} from "recharts";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Navbar } from "@/components/shared/navbar/Navbar";
import { useExplore, useExploreInfiniteTickets } from "../../api/useExplore";
import {
  ExploreRow,
  TicketExploreRow,
  translateColumnsToExploreRows,
} from "../../api/useExplore/Explore.model";
import { buildFilterSearchParams, FilterProvider, useFilter } from "./context";
import { FilterValue, pasrseFilterValue } from "./context/FilterContext.model";
import { DateRangePicker } from "@/components/shared/date-range-picker/DateRangePicker";
import styles from "./styles.module.scss";
import { Badge, Button, Flex, Skeleton, Text } from "@radix-ui/themes";
import ExploreTable, { ColumnType } from "./ExploreTable";
import {
  exploreCellGenerator,
  ticketExploreCellGenerator,
} from "./CellGenerator";
import { categoriesMapper } from "./lib";
import { useNavigate } from "react-router-dom";
import { useAuthorizedQuery } from "../../api";
import { Ticket } from "../../api/useExplore/Ticket.model";
import { params } from "../../params";
import { isYotpo } from "../../api/user";
import { ChatBubbleIcon, IdCardIcon } from "@radix-ui/react-icons";
import {
  ExploreConversationsComingSoonSVG,
  DarkExploreConversationsComingSoonSVG,
} from "./assets/ExploreConversationsComingSoonSVG";
import { useOnboarding } from "@/api/onboarding";
import { PageEmptyState } from "@/components/page-empty-state/PageEmptyState";
import { useTheme } from "@/components/Theme";
import { ExploreGlobaleTicketDashboard } from "./ExploreGlobaleDashboard";
import { useFeatureFlag } from "configcat-react";

export function useExploreHelper(filterValue: Omit<FilterValue, "dateRange">) {
  const { user, getIdTokenClaims } = useAuth0();
  const { mutate, isLoading, data } = useExplore();

  const { dateRange } = useFilter();

  const [token, setToken] = useState(false);

  useEffect(() => {
    getIdTokenClaims().then((claims) => {
      setToken(true);
    });
  }, [getIdTokenClaims]);

  useEffect(() => {
    if (user && token) {
      mutate(
        pasrseFilterValue({
          ...filterValue,
          dateRange,
        })
      );
    }
  }, [user, token, dateRange, filterValue, mutate]);

  return useMemo(
    () => ({
      isLoading,
      data:
        data == null
          ? []
          : translateColumnsToExploreRows(data).filter(
              (row) =>
                !["uncategorized", "other"].includes(
                  row.name.toLocaleLowerCase()
                )
            ),
    }),
    [data, isLoading]
  );
}

interface BarChartHelperProps {
  data: {
    name: string;
    volume: number | undefined;
  }[];
  isLoading: boolean;
  isVertical?: boolean;
}

export function BarChartHelper({
  data,
  isLoading,
  isVertical = false,
}: BarChartHelperProps) {
  return (
    <div className={styles.ChartWrapper}>
      {isLoading && <Skeleton width="100%" height="100%" />}

      {!isLoading && data.length === 0 && (
        <div className={styles.EmptyState}>
          <div
            className={styles.Title}
          >{`It seems there's no data matching your criteria.`}</div>
          <div
            className={styles.Description}
          >{`Try a different date range.`}</div>
        </div>
      )}

      {!isLoading && data.length > 0 && (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            {...(isVertical
              ? {
                  layout: "vertical",
                  margin: {
                    left: 25,
                  },
                }
              : {})}
          >
            <CartesianGrid />
            {isVertical ? (
              <>
                <XAxis
                  type={"number"}
                  domain={[
                    0,
                    (dataMax: number) => Math.ceil(dataMax / 10) * 10,
                  ]}
                  tickLine={false}
                />
                <YAxis
                  dataKey="name"
                  type={"category"}
                  tickLine={false}
                  width={100}
                />
              </>
            ) : (
              <>
                <XAxis
                  dataKey="name"
                  type={"category"}
                  tickLine={false}
                  width={100}
                />
                <YAxis
                  type={"number"}
                  domain={[
                    0,
                    (dataMax: number) => Math.ceil(dataMax / 10) * 10,
                  ]}
                  tickLine={false}
                />
              </>
            )}

            <Tooltip cursor={{ fill: "var(--gray_4_a)" }} />
            <Bar dataKey="volume" fill="#8884d8" barSize={17} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

export function SeeAllButton({
  filterValue,
}: {
  filterValue: Omit<FilterValue, "dateRange">;
}) {
  const navigate = useNavigate();
  const { dateRange } = useFilter();

  const handleOnClick = useCallback(() => {
    const searchParams = buildFilterSearchParams({ ...filterValue, dateRange });
    navigate(`/explore/tickets?${searchParams.toString()}`);
  }, [filterValue, dateRange, navigate]);

  return (
    <Button
      color="gray"
      variant="ghost"
      highContrast
      size="2"
      onClick={handleOnClick}
    >
      See All
    </Button>
  );
}

const highVolumeTopicsValue: Omit<FilterValue, "dateRange"> = {
  groupBy: "topics",
};

function HighVolumeTopics() {
  const { data: exploreData, isLoading } = useExploreHelper(
    highVolumeTopicsValue
  );

  const data = useMemo(() => {
    const sorted = exploreData.sort(
      (a, b) => (b.avgVolume ?? 0) - (a.avgVolume ?? 0)
    );

    return sorted.slice(0, 5).map((row) => ({
      name: row.name,
      volume: row.avgVolume,
    }));
  }, [exploreData]);

  return (
    <div className={cx(styles.Widget, styles.medium)}>
      <div className={styles.Header}>
        <div className={styles.Title}>High Volume Topics</div>

        <SeeAllButton filterValue={highVolumeTopicsValue} />
      </div>

      <BarChartHelper isVertical data={data} isLoading={isLoading} />
    </div>
  );
}

const ticketsVolumeByCategoriesValue: Omit<FilterValue, "dateRange"> = {
  groupBy: "categories",
};

export function TicketsVolumeByCategories() {
  const { data: exploreData, isLoading } = useExploreHelper(
    ticketsVolumeByCategoriesValue
  );

  const data = useMemo(() => {
    const sorted = exploreData.sort(
      (a, b) => (b.avgVolume ?? 0) - (a.avgVolume ?? 0)
    );

    return sorted.slice(0, 5).map((row) => ({
      name:
        categoriesMapper[row.name as keyof typeof categoriesMapper]?.label ??
        "Uncategorized",
      volume: row.avgVolume,
    }));
  }, [exploreData]);

  return (
    <div className={cx(styles.Widget, styles.medium)}>
      <div className={styles.Header}>
        <div className={styles.Title}>Tickets Volume by Categories</div>

        <SeeAllButton filterValue={ticketsVolumeByCategoriesValue} />
      </div>

      <BarChartHelper data={data} isLoading={isLoading} />
    </div>
  );
}

const bugsByTopicsValue: Omit<FilterValue, "dateRange"> = {
  groupBy: "topics",
  stringSliceBy: {
    and: [
      {
        type: "category",
        operation: "and",
        values: [
          {
            type: "category",
            operation: "eq",
            value: "bug",
          },
        ],
      },
    ],
  },
};

function BugsByTopics() {
  const { data: exploreData, isLoading } = useExploreHelper(bugsByTopicsValue);

  const data = useMemo(() => {
    const sorted = exploreData.sort(
      (a, b) => (b.avgVolume ?? 0) - (a.avgVolume ?? 0)
    );

    return sorted.slice(0, 5).map((row) => ({
      name: row.name,
      volume: row.avgVolume,
    }));
  }, [exploreData]);

  return (
    <div className={cx(styles.Widget, styles.medium)}>
      <div className={styles.Header}>
        <div className={styles.Title}>Bugs by Topics</div>

        <SeeAllButton filterValue={bugsByTopicsValue} />
      </div>

      <BarChartHelper data={data} isLoading={isLoading} />
    </div>
  );
}

const bugsValue: Omit<FilterValue, "dateRange"> = {
  view: "tickets",
  groupBy: "categories",
  stringSliceBy: {
    and: [
      {
        type: "category",
        operation: "and",
        values: [
          {
            type: "category",
            operation: "eq",
            value: "bug",
          },
        ],
      },
    ],
  },
};

function Bugs() {
  const { user } = useAuth0();
  const { dateRange } = useFilter();

  const { data: ticketData, isLoading } = useExploreInfiniteTickets(
    {
      ...bugsValue,
      includeComments: false,
      dateRange,
    },
    !!user
  );

  const data = useMemo(() => {
    return ticketData?.pages?.[0].metadata.ticketCount;
  }, [ticketData]);

  return (
    <div className={cx(styles.Widget, styles.medium)}>
      <div className={styles.Header}>
        <div className={styles.Title}>Bugs</div>

        <SeeAllButton filterValue={bugsValue} />
      </div>

      <div className={styles.ChartWrapper}>
        {isLoading && (
          <div className={styles.Metric}>
            <Skeleton width="80px" height="54px" />
            <div className={styles.Label}>Ticktes</div>
          </div>
        )}

        {!isLoading && (data == null || data === 0) && (
          <div className={styles.EmptyState}>
            <div className={styles.Title}>{`Bug-Free Zone 🎉`}</div>
            <div
              className={styles.Description}
            >{`Great news! No bugs were detected.`}</div>
          </div>
        )}

        {!isLoading && data != null && data > 0 && (
          <div className={styles.Metric}>
            <div className={styles.Value}>{data}</div>
            <div className={styles.Label}>Ticktes</div>
          </div>
        )}
      </div>
    </div>
  );
}

const highTicketVolumeCustomersValue: Omit<FilterValue, "dateRange"> = {
  groupBy: "customers",
};

function HighTicketVolumeCustomers() {
  const { data: exploreData, isLoading } = useExploreHelper(
    highTicketVolumeCustomersValue
  );

  const data = useMemo(() => {
    const sorted = exploreData.sort(
      (a, b) => (b.avgVolume ?? 0) - (a.avgVolume ?? 0)
    );

    return sorted.slice(0, 10).map((row) => ({
      name: row.name,
      volume: row.avgVolume,
    }));
  }, [exploreData]);

  return (
    <div className={cx(styles.Widget, styles.large)}>
      <div className={styles.Header}>
        <div className={styles.Title}>High Ticket Volume Customers</div>

        <SeeAllButton filterValue={highTicketVolumeCustomersValue} />
      </div>

      <BarChartHelper isVertical data={data} isLoading={isLoading} />
    </div>
  );
}

const columns: ColumnType<keyof ExploreRow>[] = [
  {
    label: "Agents",
    key: "name",
    width: "80%",
  },
  {
    label: "Avg. QA Score",
    key: "avgQaScore",
    width: "126px",
  },
];

const performingAgentsValue: Omit<FilterValue, "dateRange"> = {
  groupBy: "agents",
};

function PerformingAgents() {
  const { user } = useAuth0();

  const { data: exploreData, isLoading } = useExploreHelper(
    performingAgentsValue
  );

  const scoreBaseline = useMemo(() => (isYotpo(user) ? 0.5 : 3), [user]);

  const under = useMemo(() => {
    return exploreData
      .filter(
        (row) =>
          row.avgQaScore != null && (row.avgQaScore ?? 0) <= scoreBaseline
      )
      .sort((a, b) => (a.avgQaScore ?? 0) - (b.avgQaScore ?? 0))
      .slice(0, 5);
  }, [scoreBaseline, exploreData]);

  const top = useMemo(() => {
    return exploreData
      .filter((row) => (row.avgQaScore ?? 0) > scoreBaseline)
      .sort((a, b) => (b.avgQaScore ?? 0) - (a.avgQaScore ?? 0))
      .slice(0, 5);
  }, [scoreBaseline, exploreData]);

  return (
    <div className={styles.Row}>
      <div className={cx(styles.Widget, styles.tableSize)}>
        <div className={styles.Header}>
          <div className={styles.Title}>Underperforming agents</div>
        </div>

        <div className={styles.TableWrapper}>
          {!isLoading && under.length === 0 ? (
            <div className={styles.EmptyState}>
              <div
                className={styles.Title}
              >{`It seems there's no data matching your criteria.`}</div>
              <div
                className={styles.Description}
              >{`Try different date range.`}</div>
            </div>
          ) : (
            <ExploreTable<ExploreRow>
              columns={columns}
              rows={under}
              cellGenerator={exploreCellGenerator}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>

      <div className={cx(styles.Widget, styles.tableSize)}>
        <div className={styles.Header}>
          <div className={styles.Title}>Top performing agents</div>
        </div>

        <div className={styles.TableWrapper}>
          {!isLoading && top.length === 0 ? (
            <div className={styles.EmptyState}>
              <div
                className={styles.Title}
              >{`It seems there's no data matching your criteria.`}</div>
              <div
                className={styles.Description}
              >{`Try different date range.`}</div>
            </div>
          ) : (
            <ExploreTable<ExploreRow>
              columns={columns}
              rows={top}
              cellGenerator={exploreCellGenerator}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const highestBackAndForthAgentsValue: Omit<FilterValue, "dateRange"> = {
  groupBy: "agents",
};

function HighestBackAndForthAgents() {
  const { data: exploreData, isLoading } = useExploreHelper(
    highestBackAndForthAgentsValue
  );

  const top = useMemo(() => {
    return exploreData
      .filter((row) => (row.avgQaScore ?? 0) > 3)
      .sort((a, b) => (b.avgBackAndForth ?? 0) - (a.avgBackAndForth ?? 0))
      .slice(0, 5);
  }, [exploreData]);

  return (
    <div className={cx(styles.Widget, styles.tableSize)}>
      <div className={styles.Header}>
        <div className={styles.Title}>Highest B&F agents</div>
      </div>

      <div className={styles.TableWrapper}>
        {!isLoading && top.length === 0 ? (
          <div className={styles.EmptyState}>
            <div
              className={styles.Title}
            >{`It seems there's no data matching your criteria.`}</div>
            <div
              className={styles.Description}
            >{`Try different date range.`}</div>
          </div>
        ) : (
          <ExploreTable<ExploreRow>
            columns={[
              {
                label: "Agents",
                key: "name",
                width: "80%",
              },
              {
                label: "Avg. B&F",
                key: "avgBackAndForth",
              },
            ]}
            rows={top}
            cellGenerator={exploreCellGenerator}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
}

function useGetTicketsWithMostComments() {
  const { user } = useAuth0();
  const { dateRange } = useFilter();

  const searchParams = useMemo(() => {
    const { dateFrom, dateTo } = dateRange;

    return new URLSearchParams([
      ["startDate", dateFrom],
      ["endDate", dateTo],
    ]);
  }, [dateRange]);

  return useAuthorizedQuery<Pick<Ticket, "id" | "subject" | "comments">[]>({
    queryKey: `useGetTicketsWithMostComments_${searchParams.toString()}`,
    url: `${
      params.API_URL
    }/platform/tickets/high-comments-volume?${searchParams.toString()}`,
    method: "GET",
    enabled: !!user,
  });
}

function HighCommentVolumeTickets() {
  const { data, isLoading } = useGetTicketsWithMostComments();

  const rows = useMemo<TicketExploreRow[]>(() => {
    return (
      data?.slice(0, 5).map((ticket) => {
        const commentsCount = ticket?.comments?.reduce(
          (acc, comment) => {
            if (comment.isInternal) {
              acc.internal += 1;
            } else {
              acc.external += 1;
            }

            return acc;
          },
          { internal: 0, external: 0 }
        );

        return {
          ...ticket,
          overview: {
            id: ticket.id.split("::")[1],
            subject: ticket.subject ?? "",
          },
          messagesCount: commentsCount?.external,
          internalNotesCount: commentsCount?.internal,
        };
      }) ?? []
    );
  }, [data]);

  return (
    <div className={cx(styles.Widget, styles.largeTableSize)}>
      <div className={styles.Header}>
        <div className={styles.Title}>High comment volume tickets</div>
      </div>

      <div className={cx(styles.TableWrapper, styles.showHeader)}>
        {!isLoading && rows.length === 0 ? (
          <div className={styles.EmptyState}>
            <div
              className={styles.Title}
            >{`It seems there's no data matching your criteria.`}</div>
            <div
              className={styles.Description}
            >{`Try different date range.`}</div>
          </div>
        ) : (
          <ExploreTable<TicketExploreRow>
            columns={[
              {
                label: "Tickets",
                key: "overview",
              },
              {
                label: "# Messages",
                key: "messagesCount",
                width: "160px",
              },
              {
                label: "# Internal Notes",
                key: "internalNotesCount",
                width: "160px",
              },
            ]}
            rows={rows}
            cellGenerator={ticketExploreCellGenerator}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
}

function useGetOldestOpenTickets() {
  const { user } = useAuth0();

  return useAuthorizedQuery<
    Pick<Ticket, "id" | "subject" | "createdAt" | "status">[]
  >({
    queryKey: `useGetOldestOpenTickets`,
    url: `${params.API_URL}/platform/tickets/oldest-open-tickets`,
    method: "GET",
    enabled: !!user,
  });
}

function OldestOpenTickets() {
  const { data, isLoading } = useGetOldestOpenTickets();

  const rows = useMemo<TicketExploreRow[]>(() => {
    return (
      data?.slice(0, 5).map((ticket) => {
        return {
          ...ticket,
          overview: {
            id: ticket.id.split("::")[1],
            subject: ticket.subject ?? "",
          },
          status: ticket.status,
          createdAt: ticket.createdAt,
        };
      }) ?? []
    );
  }, [data]);

  return (
    <div className={cx(styles.Widget, styles.largeTableSize)}>
      <div className={styles.Header}>
        <div className={styles.Title}>Oldest open tickets</div>
      </div>

      <div className={cx(styles.TableWrapper, styles.showHeader)}>
        {!isLoading && rows.length === 0 ? (
          <div className={styles.EmptyState}>
            <div
              className={styles.Title}
            >{`It seems there's no data matching your criteria.`}</div>
            <div
              className={styles.Description}
            >{`Try different date range.`}</div>
          </div>
        ) : (
          <ExploreTable<TicketExploreRow>
            columns={[
              {
                label: "Tickets",
                key: "overview",
              },
              {
                label: "Status",
                key: "status",
                width: "80px",
              },
              {
                label: "Created At",
                key: "createdAt",
                width: "150px",
              },
            ]}
            rows={rows}
            cellGenerator={ticketExploreCellGenerator}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
}

type LowestSentimentOrganization = {
  organizationId: string;
  organizationName: string;
  sentiment: number;
  ticketCount: number;
};

type GetLowestSentimentOrganizationsResponse = {
  from: Date;
  to: Date;
  values: LowestSentimentOrganization[];
};

export function useGetLowestSentimentOrganizations() {
  const { user } = useAuth0();
  const { dateRange } = useFilter();

  const searchParams = useMemo(() => {
    const { dateFrom, dateTo } = dateRange;

    return new URLSearchParams([
      ["from", dateFrom],
      ["to", dateTo],
    ]);
  }, [dateRange]);

  return useAuthorizedQuery<GetLowestSentimentOrganizationsResponse>({
    queryKey: `useGetLowestSentimentOrganizations_${searchParams.toString()}`,
    url: `${
      params.API_URL
    }/platform/insight/lowest-sentiment/tenant-organizations?${searchParams.toString()}`,
    method: "GET",
    enabled: !!user,
  });
}

function LowestSentimentOrganizations() {
  const { data, isLoading } = useGetLowestSentimentOrganizations();

  const rows = useMemo<ExploreRow[]>(() => {
    return (
      data?.values
        ?.sort((a, b) => a.sentiment - b.sentiment)
        .slice(0, 5)
        .map((value, index) => {
          return {
            id: index,
            name: value.organizationName,
            avgSentiment: value.sentiment,
            avgVolume: value.ticketCount,
          };
        }) ?? []
    );
  }, [data]);

  return (
    <div className={cx(styles.Widget, styles.tableSize)}>
      <div className={styles.Header}>
        <div className={styles.Title}>Lowest sentiment organizations</div>
      </div>

      <div className={styles.TableWrapper}>
        {!isLoading && rows.length === 0 ? (
          <div className={styles.EmptyState}>
            <div
              className={styles.Title}
            >{`It seems there's no data matching your criteria.`}</div>
            <div
              className={styles.Description}
            >{`Try different date range.`}</div>
          </div>
        ) : (
          <ExploreTable<ExploreRow>
            columns={[
              {
                label: "Customers",
                key: "name",
              },
              {
                label: "Volume",
                key: "avgVolume",
                width: "80px",
              },
              {
                label: "Avg. Sentiment",
                key: "avgSentiment",
                width: "132px",
              },
            ]}
            rows={rows}
            cellGenerator={exploreCellGenerator}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
}

function ExploreTicketDashboard() {
  return (
    <div className={styles.ExploreDashboardScrollArea}>
      <div className={styles.Row}>
        <HighVolumeTopics />
        <TicketsVolumeByCategories />
      </div>

      <div className={styles.Row}>
        <BugsByTopics />
        <Bugs />
      </div>

      <div className={styles.Row}>
        <HighTicketVolumeCustomers />
      </div>

      <PerformingAgents />

      <div className={styles.Row}>
        <HighestBackAndForthAgents />
        <LowestSentimentOrganizations />
      </div>

      <div className={styles.Row}>
        <HighCommentVolumeTickets />
        <OldestOpenTickets />
      </div>
    </div>
  );
}

function ExploreConversationsDashboard() {
  const { isDarkMode } = useTheme();

  return (
    <Flex
      width={"100%"}
      direction={"column"}
      justify={"center"}
      align={"center"}
      gap={"3"}
    >
      <Flex
        direction={"column"}
        justify={"center"}
        align={"center"}
        gap={"5"}
        className={styles.CommingSoon}
      >
        <div className={styles.BadgeWrapper}>
          <Badge
            color={"gray"}
            size={"2"}
            highContrast
            className={styles.Badge}
          >
            Coming Soon
          </Badge>
        </div>

        <Flex
          direction={"column"}
          justify={"center"}
          align={"center"}
          gap={"2"}
        >
          <Text size={"7"} color={"gray"} weight={"medium"} highContrast>
            Conversations Insights
          </Text>

          <Text size={"4"} color={"gray"} highContrast align={"center"}>
            Gain powerful insights into your AI-driven conversations. Track
            trends, analyze response quality, and identify areas for improvement
            - all in one place.
          </Text>
        </Flex>
      </Flex>

      {isDarkMode ? (
        <DarkExploreConversationsComingSoonSVG />
      ) : (
        <ExploreConversationsComingSoonSVG />
      )}
    </Flex>
  );
}

function ExploreDashboardComp() {
  const { backfillStatus } = useOnboarding();
  const { user } = useAuth0();
  const { value: exploreGlobaleDashboardEnabled } = useFeatureFlag(
    "exploreGlobaleDashboard",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const { dateRange, setDateRange } = useFilter();

  const [selectedTab, setSelectedTab] = useState<string>("tickets");

  if (backfillStatus !== "completed") {
    return (
      <div className={cx(styles.exploreContainer, styles.ExploreDashboardPage)}>
        <div className={styles.controlRow}>
          <Navbar items={[{ title: "Explore" }]} />
        </div>

        <PageEmptyState
          title={"No insights yet"}
          description={
            "Trends, patterns, and opportunities will appear here to help you optimize your support operations."
          }
          svg={"explore-insights"}
        />
      </div>
    );
  }

  return (
    <div className={cx(styles.exploreContainer, styles.ExploreDashboardPage)}>
      <div className={styles.controlRow}>
        <Navbar items={[{ title: "Explore" }]} />
      </div>

      <div className={styles.controlRow}>
        <Tabs value={selectedTab} onValueChange={setSelectedTab}>
          <TabsList>
            <TabsTrigger value={"tickets"}>
              <IdCardIcon />
              Tickets
            </TabsTrigger>

            <TabsTrigger value={"conversations"}>
              <ChatBubbleIcon />
              Conversations
            </TabsTrigger>
          </TabsList>
        </Tabs>

        <DateRangePicker value={dateRange} onChange={setDateRange} />
      </div>

      <div className={styles.ExploreDashboard}>
        {selectedTab === "tickets" &&
          (exploreGlobaleDashboardEnabled ? (
            <ExploreGlobaleTicketDashboard />
          ) : (
            <ExploreTicketDashboard />
          ))}
        {selectedTab === "conversations" && <ExploreConversationsDashboard />}
      </div>
    </div>
  );
}

function ExploreDashboard() {
  return (
    <FilterProvider source="insights">
      <ExploreDashboardComp />
    </FilterProvider>
  );
}

export default ExploreDashboard;
