import {
  FormEventHandler,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Flex, Text, TextField, Button, Spinner, Link } from "@radix-ui/themes";
import * as Form from "@radix-ui/react-form";
import {
  ConnectIntegrationFormPayload,
  IntegrationName,
  IntegrationType,
  isIntegrationCompleted,
} from "../../../../api/integration/Integration.model";
import { StepHeader, StepMain } from "../parts";
import { integrationNameToGalleryItem } from "../../../../api/integration/integrationNameToGalleryItem";
import styles from "../AddIntegration.module.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useIntegrations } from "../../../../api/integration";
import Checkbox from "../../../../components/shared/inputs/checkbox";
import { IntegrationEvents, mixTrackEvent } from "@/assets/mixpanel";

interface FieldControlProps {
  label: string;
  name: string;
  type?: TextField.RootProps["type"];
  required?: boolean;
  placeholder?: string;
  error?: string;
}

function FieldControl({
  label,
  name,
  type = "text",
  required,
  placeholder = label,
  error,
  children,
}: PropsWithChildren<FieldControlProps>) {
  return (
    <Form.Field name={name} className={styles.FieldControl}>
      <Form.Label>
        <Text size={"1"} weight={"medium"}>
          {label}
        </Text>
      </Form.Label>

      <Form.Control asChild>
        <TextField.Root
          type={type}
          size="2"
          placeholder={placeholder}
          required={required}
        />
      </Form.Control>

      <Form.Message className={styles.Message} match="valueMissing">
        <Text size={"1"} weight={"medium"} color={"red"}>
          {`Please enter a ${name}.`}
        </Text>
      </Form.Message>

      {error != null && (
        <Form.Message className={styles.Message}>
          <Text size={"1"} weight={"medium"} color={"red"}>
            {error}
          </Text>
        </Form.Message>
      )}

      {children}
    </Form.Field>
  );
}

const docsLinks: Record<IntegrationName, string | undefined> = {
  zendesk:
    "https://support.zendesk.com/hc/en-us/articles/4408889192858-Managing-access-to-the-Zendesk-API#topic_tcb_fk1_2yb",
  document360: "https://apidocs.document360.com/apidocs/api-token",
  gitbook: "https://developer.gitbook.com/gitbook-api/authentication",
  front: "https://app.frontapp.com/settings/home",
  freshdesk: undefined,
  salesforce: undefined,
  hubspot: undefined,
  intercom:
    "https://developers.intercom.com/docs/build-an-integration/learn-more/authentication",
  kustomer: undefined,
  // gladly: undefined,
  slack: undefined,
  jira: undefined,
  notion: undefined,
  confluence: undefined,
};

type ServerErrors = {
  token?: string;
  general?: string;
};

type NewZendeskFormPayload = {
  email: string;
  subdomain: string;
  token: string;
};

function NewZendeskConnectForm({ onSubmit, onBack }: ConnectFormProps) {
  const [loading, setLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState<ServerErrors>();

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      // prevent default form submission
      event.preventDefault();

      try {
        setLoading(true);
        const data = Object.fromEntries(new FormData(event.currentTarget));
        const { email, subdomain, token } = data as NewZendeskFormPayload;

        await onSubmit({
          subdomain,
          email,
          token,
          tokenType: "API",
        });
      } catch (error) {
        setServerErrors({
          general: "An unknown error occurred. Please contact support.",
        });
      } finally {
        setLoading(false);
      }
    },
    [onSubmit]
  );

  const handleClearServerErrors = useCallback(() => {
    setServerErrors(undefined);
  }, []);

  return (
    <Form.Root
      onSubmit={handleSubmit}
      onClearServerErrors={handleClearServerErrors}
      className={styles.Form}
    >
      <div className={styles.FieldList}>
        <FieldControl
          label={"Zendesk Admin's Email"}
          name={"email"}
          type={"email"}
          required
        />

        <FieldControl
          label={"Zendesk subdomain"}
          name={"subdomain"}
          type={"text"}
          required
        />

        <FieldControl
          label={"Zendesk token"}
          name={"token"}
          type={"text"}
          required
        >
          {docsLinks["zendesk"] != null && (
            <Link
              href={docsLinks["zendesk"]}
              target="_blank"
              color="gray"
              highContrast
              size={"2"}
              onClick={() => {
                mixTrackEvent({
                  event: IntegrationEvents.CANT_FIND_TOKEN,
                  properties: {
                    name: "zendesk",
                  },
                });
              }}
            >
              I can't find my token
            </Link>
          )}
        </FieldControl>

        {serverErrors?.general != null && (
          <Text size={"1"} weight={"medium"} color={"red"}>
            {serverErrors.general}
          </Text>
        )}
      </div>

      <Form.Submit asChild>
        <div className={styles.Footer}>
          <Button color="gray" variant="solid" highContrast type="submit">
            <Spinner loading={loading} />
            Continue
          </Button>

          <Button
            color="gray"
            variant="soft"
            highContrast
            type="button"
            onClick={onBack}
          >
            Back
          </Button>
        </div>
      </Form.Submit>
    </Form.Root>
  );
}

type FrontFormPayload = {
  email: string;
  subdomain: string;
  token: string;
};

function FrontConnectForm({ onSubmit, onBack }: ConnectFormProps) {
  const [loading, setLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState<ServerErrors>();

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      // prevent default form submission
      event.preventDefault();

      try {
        setLoading(true);
        const data = Object.fromEntries(new FormData(event.currentTarget));
        const { email, subdomain, token } = data as FrontFormPayload;

        await onSubmit({
          subdomain,
          email,
          token,
          tokenType: "API",
        });
      } catch (error) {
        setServerErrors({
          general: "An unknown error occurred. Please contact support.",
        });
      } finally {
        setLoading(false);
      }
    },
    [onSubmit]
  );

  const handleClearServerErrors = useCallback(() => {
    setServerErrors(undefined);
  }, []);

  return (
    <Form.Root
      onSubmit={handleSubmit}
      onClearServerErrors={handleClearServerErrors}
      className={styles.Form}
    >
      <div className={styles.FieldList}>
        <FieldControl
          label={"Front Admin's Email"}
          name={"email"}
          type={"email"}
          required
        />

        <FieldControl
          label={"Front subdomain"}
          name={"subdomain"}
          type={"text"}
          required
        />

        <FieldControl
          label={"Front token"}
          name={"token"}
          type={"text"}
          required
        >
          {docsLinks["front"] != null && (
            <Link
              href={docsLinks["front"]}
              target="_blank"
              color="gray"
              highContrast
              size={"2"}
              onClick={() => {
                mixTrackEvent({
                  event: IntegrationEvents.CANT_FIND_TOKEN,
                  properties: {
                    name: "front",
                  },
                });
              }}
            >
              I can't find my token
            </Link>
          )}
        </FieldControl>

        {serverErrors?.general != null && (
          <Text size={"1"} weight={"medium"} color={"red"}>
            {serverErrors.general}
          </Text>
        )}
      </div>

      <Form.Submit asChild>
        <div className={styles.Footer}>
          <Button color="gray" variant="solid" highContrast type="submit">
            <Spinner loading={loading} />
            Continue
          </Button>

          <Button
            color="gray"
            variant="soft"
            highContrast
            type="button"
            onClick={onBack}
          >
            Back
          </Button>
        </div>
      </Form.Submit>
    </Form.Root>
  );
}

type IntercomFormPayload = {
  email: string;
  subdomain: string;
  token: string;
};

function IntercomConnectForm({ onSubmit, onBack }: ConnectFormProps) {
  const [loading, setLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState<ServerErrors>();

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      // prevent default form submission
      event.preventDefault();

      try {
        setLoading(true);
        const data = Object.fromEntries(new FormData(event.currentTarget));
        const { email, subdomain, token } = data as IntercomFormPayload;

        await onSubmit({
          subdomain,
          email,
          token,
          tokenType: "API",
        });
      } catch (error) {
        setServerErrors({
          general: "An unknown error occurred. Please contact support.",
        });
      } finally {
        setLoading(false);
      }
    },
    [onSubmit]
  );

  const handleClearServerErrors = useCallback(() => {
    setServerErrors(undefined);
  }, []);

  return (
    <Form.Root
      onSubmit={handleSubmit}
      onClearServerErrors={handleClearServerErrors}
      className={styles.Form}
    >
      <div className={styles.FieldList}>
        <FieldControl
          label={"Intercom Admin's Email"}
          name={"email"}
          type={"email"}
          required
        />

        <FieldControl
          label={"Intercom subdomain"}
          name={"subdomain"}
          type={"text"}
          required
        />

        <FieldControl
          label={"Intercom token"}
          name={"token"}
          type={"text"}
          required
        >
          {docsLinks["intercom"] != null && (
            <Link
              href={docsLinks["intercom"]}
              target="_blank"
              color="gray"
              highContrast
              size={"2"}
              onClick={() => {
                mixTrackEvent({
                  event: IntegrationEvents.CANT_FIND_TOKEN,
                  properties: {
                    name: "intercom",
                  },
                });
              }}
            >
              I can't find my token
            </Link>
          )}
        </FieldControl>

        {serverErrors?.general != null && (
          <Text size={"1"} weight={"medium"} color={"red"}>
            {serverErrors.general}
          </Text>
        )}
      </div>

      <Form.Submit asChild>
        <div className={styles.Footer}>
          <Button color="gray" variant="solid" highContrast type="submit">
            <Spinner loading={loading} />
            Continue
          </Button>

          <Button
            color="gray"
            variant="soft"
            highContrast
            type="button"
            onClick={onBack}
          >
            Back
          </Button>
        </div>
      </Form.Submit>
    </Form.Root>
  );
}

type ExistingZendeskFormPayload = {
  userConfimation: boolean;
};

function ExistingZendeskConnectForm({ onSubmit, onBack }: ConnectFormProps) {
  const [payload, setPayload] = useState<ExistingZendeskFormPayload>({
    userConfimation: true,
  });

  const [loading, setLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState<ServerErrors>();

  const handleSubmit = useCallback(async () => {
    try {
      setServerErrors(undefined);
      setLoading(true);

      await onSubmit({
        email: "email",
        token: "token",
        tokenType: "API",
      });
    } catch (error) {
      setServerErrors({
        general: "An unknown error occurred. Please contact support.",
      });
    } finally {
      setLoading(false);
    }
  }, [onSubmit]);

  return (
    <Flex height={"100%"} direction={"column"}>
      <Flex px={"45px"}>
        <Text color="gray" size={"2"}>
          Your Zendesk account is already connected. We will reuse the same
          token to connect your knowledge source.
        </Text>
      </Flex>

      <div className={styles.Form}>
        <div className={styles.FieldList}>
          <div className={styles.FieldControl}>
            <Flex direction={"column"}>
              <Flex justify={"between"} align={"center"}>
                <Checkbox
                  label={
                    "I confirm to reuse the existing Zendesk token for this integration."
                  }
                  value={payload.userConfimation}
                  onChange={(checked) =>
                    setPayload({ userConfimation: checked })
                  }
                />
              </Flex>
            </Flex>
          </div>

          {serverErrors?.general != null && (
            <Text size={"1"} weight={"medium"} color={"red"}>
              {serverErrors.general}
            </Text>
          )}
        </div>

        <div className={styles.Footer}>
          <Button
            color="gray"
            variant="solid"
            highContrast
            onClick={handleSubmit}
            disabled={payload.userConfimation !== true}
          >
            <Spinner loading={loading} />
            Continue
          </Button>

          <Button
            color="gray"
            variant="soft"
            highContrast
            type="button"
            onClick={onBack}
          >
            Back
          </Button>
        </div>
      </div>
    </Flex>
  );
}

interface ConnectFormProps {
  onSubmit: (value: ConnectIntegrationFormPayload) => Promise<void>;
  onBack: () => void;
}

function ZendeskConnectForm(props: ConnectFormProps) {
  const { data, isLoading } = useIntegrations();

  const existingZendeskIntegration = useMemo(
    () =>
      data.find(
        (integration) =>
          integration.name === "zendesk" && isIntegrationCompleted(integration)
      ),
    [data]
  );

  if (isLoading) {
    return (
      <Flex pt={"100px"} width={"100%"} justify={"center"}>
        <Spinner size={"3"} />
      </Flex>
    );
  }

  if (existingZendeskIntegration != null) {
    return <ExistingZendeskConnectForm {...props} />;
  }

  return <NewZendeskConnectForm {...props} />;
}

type KnowleBaseFormPayload = {
  token: string;
};

function KnowleBaseConnectForm({
  name,
  onSubmit,
  onBack,
}: { name: IntegrationName } & ConnectFormProps) {
  const { user } = useAuth0();

  const [serverErrors, setServerErrors] = useState<ServerErrors>();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      // prevent default form submission
      event.preventDefault();

      try {
        setLoading(true);
        const data = Object.fromEntries(new FormData(event.currentTarget));
        const { token } = data as KnowleBaseFormPayload;

        await onSubmit({
          email: user?.email ?? "email",
          token,
          tokenType: "API",
        });
      } catch (error) {
        setServerErrors({
          general: "An unknown error occurred. Please contact support.",
        });
      } finally {
        setLoading(false);
      }
    },
    [user, onSubmit]
  );

  const handleClearServerErrors = useCallback(() => {
    setServerErrors(undefined);
  }, []);

  const { title } = integrationNameToGalleryItem[name];

  return (
    <Form.Root
      onSubmit={handleSubmit}
      onClearServerErrors={handleClearServerErrors}
      className={styles.Form}
    >
      <div className={styles.FieldList}>
        <FieldControl
          label={`${title} token`}
          name={"token"}
          type={"text"}
          required
        >
          {docsLinks[name] != null && (
            <Link
              href={docsLinks[name]}
              target="_blank"
              color="gray"
              highContrast
              size={"2"}
              onClick={() => {
                mixTrackEvent({
                  event: IntegrationEvents.CANT_FIND_TOKEN,
                  properties: {
                    name,
                  },
                });
              }}
            >
              I can't find my token
            </Link>
          )}
        </FieldControl>

        {serverErrors?.general != null && (
          <Text size={"1"} weight={"medium"} color={"red"}>
            {serverErrors.general}
          </Text>
        )}
      </div>

      <Form.Submit asChild>
        <div className={styles.Footer}>
          <Button color="gray" variant="solid" highContrast type="submit">
            <Spinner loading={loading} />
            Continue
          </Button>

          <Button
            color="gray"
            variant="soft"
            highContrast
            type="button"
            onClick={onBack}
          >
            Back
          </Button>
        </div>
      </Form.Submit>
    </Form.Root>
  );
}

interface ConnectIntegrationProps {
  type: IntegrationType;
  name: IntegrationName;
  onSubmit: (value: ConnectIntegrationFormPayload) => Promise<void>;
  onBack: () => void;
}

function ConnectIntegration({
  type,
  name,
  onSubmit,
  onBack,
}: ConnectIntegrationProps) {
  const title = `Connect to ${integrationNameToGalleryItem[name].title}`;

  return (
    <StepMain>
      <Flex pt={"35px"} px={"45px"}>
        <StepHeader title={title} />
      </Flex>

      <Flex flexGrow={"1"} overflow={"hidden"} pt={"16px"}>
        {name === "zendesk" && (
          <ZendeskConnectForm onSubmit={onSubmit} onBack={onBack} />
        )}
        {name === "front" && (
          <FrontConnectForm onSubmit={onSubmit} onBack={onBack} />
        )}
        {name === "intercom" && (
          <IntercomConnectForm onSubmit={onSubmit} onBack={onBack} />
        )}

        {type === "KNOWLEDGE_BASE" && name !== "zendesk" && (
          <KnowleBaseConnectForm
            name={name}
            onSubmit={onSubmit}
            onBack={onBack}
          />
        )}
      </Flex>
    </StepMain>
  );
}

export default ConnectIntegration;
