import React, { useEffect, useMemo, useRef, useState } from "react";
import { Flex, Heading, Text } from "@radix-ui/themes";

import { Navbar } from "@/components/shared/navbar/Navbar";
import { useOpportunityGroups } from "@/api/useOpportunityGroups";
import { Search } from "@/components/shared/inputs/search";
import { DateRangePicker } from "@/components/shared/date-range-picker/DateRangePicker";
import ExploreTable from "../explore/ExploreTable";
import { PageEmptyState } from "@/components/page-empty-state/PageEmptyState";
import { useOnboarding } from "@/api/onboarding";
import { Link } from "@/components/shared/link/Link";
import { KnowledgeEvents } from "@/assets/mixpanel/knowledge";
import { mixTrackEvent } from "@/assets/mixpanel";
import { useArticles, useArticlesUsage } from "@/api/useArticles";

import styles from "./styles.module.scss";
import {
  articlesCellGenerator,
  KnowledgeGapsColumns,
  ArticleRow,
  articlesCellValueGetter,
} from "./articles-table.types";
import { useArticlesSearchParams } from "./useArticlessSearchParams";
import { useAuth0 } from "@auth0/auth0-react";

export const KnowledgeArticles = () => {
  const { hasKnowledgeBaseIntegration } = useOnboarding();

  const { searchPhrase, setSearchPhrase, dateRange, setDateRange } =
    useArticlesSearchParams();

  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [articles, setArticles] = useState<ArticleRow[]>([]);
  const [knowledgeGapsExists, setKnowledgeGapsExists] =
    useState<boolean>(false);
  const isFirstRender = useRef(true);

  const { data: articlesData, isLoading } = useArticles({
    findBy: searchPhrase,
    startDate: dateRange.dateFrom,
    page: page,
    endDate: dateRange.dateTo,
    enabled: true,
  });

  const articleIds = useMemo(
    () => articles.map((article) => article.articleId),
    [articles]
  );

  const { data: articlesUsageData } = useArticlesUsage({
    articleIds,
    startDate: dateRange.dateFrom,
    endDate: dateRange.dateTo,
    enabled: articleIds.length > 0,
  });
  const articlesUsage = articlesUsageData?.ticketsCount as {
    [key: string]: { relatedTickets: number; ticketTrend: number };
  };

  useEffect(() => {
    setPage(1);
    setArticles([]);
    if (!isFirstRender.current) {
      mixTrackEvent({
        event: KnowledgeEvents.ARTICLES_FILTER_CHANGED,
        properties: {
          searchPhrase,
          dateRange,
        },
      });
    } else {
      isFirstRender.current = false;
    }
  }, [searchPhrase, dateRange]);

  useEffect(() => {
    setHasNextPage(articlesData?.length === 40);
    if (articlesData && Array.isArray(articlesData)) {
      setArticles((prev) => [...prev, ...(articlesData as ArticleRow[])]);
    }
  }, [articlesData]);

  useEffect(() => {
    if (articlesUsage) {
      setArticles((prevArticles) =>
        prevArticles.map((article) => {
          const relatedTickets = articlesUsage[article.articleId];
          return {
            ...article,
            relatedTickets: relatedTickets?.relatedTickets || 0,
            ticketTrend: relatedTickets?.ticketTrend || 0,
          };
        })
      );
    }
  }, [articlesUsage]);

  const {
    data: opportunityGroupsExists,
    isLoading: isLoadingOpportunityGroupsExists,
  } = useOpportunityGroups({
    enabled: !knowledgeGapsExists,
    type: ["knowledge"],
  });

  useEffect(() => {
    if (
      opportunityGroupsExists &&
      Array.isArray(opportunityGroupsExists) &&
      opportunityGroupsExists.length > 0
    ) {
      setKnowledgeGapsExists(true);
    }
  }, [opportunityGroupsExists]);

  const isEmpty = useMemo(
    () =>
      !isLoadingOpportunityGroupsExists &&
      !opportunityGroupsExists &&
      page === 1 &&
      articlesData?.length === 0,
    [articlesData, page, opportunityGroupsExists]
  );

  if (isEmpty) {
    return (
      <PageEmptyState
        title={"No articles available yet"}
        description={
          "There are no articles to display right now. Once articles are identified, they'll appear here for easy access and management."
        }
        svg={"knowledge-view"}
      >
        {!hasKnowledgeBaseIntegration && (
          <div>
            <Link to={"/getting-started"}>Connect knowledge sources</Link>
          </div>
        )}
      </PageEmptyState>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.controlRow}>
        <Navbar
          items={[{ title: "Knowledge Management" }, { title: "Articles" }]}
        />
      </div>
      <div className={styles.main}>
        <div className={styles.content}>
          <Heading size={"4"} weight={"medium"}>
            Articles
          </Heading>
          <Flex direction={"column"} gap={"8"}>
            <Flex
              style={{
                padding: "0 4px",
                width: "100%",
              }}
              justify={"between"}
              align={"center"}
            >
              <Flex gap={"3"} align={"center"}>
                <Text weight={"medium"} size={"3"}>
                  Articles
                </Text>
                <Text size={"2"} weight={"bold"} color="gray">
                  {articles && articles?.length}
                </Text>
                <Search
                  setSearchPhrase={setSearchPhrase}
                  placeholder="Find"
                  debounce={500}
                  initialValue={searchPhrase}
                />
              </Flex>
              <Flex gap={"1"} align={"center"}>
                <DateRangePicker
                  value={dateRange}
                  onChange={setDateRange}
                  hardBackground
                />
              </Flex>
            </Flex>
          </Flex>
          <ExploreTable<ArticleRow>
            rows={articles}
            columns={KnowledgeGapsColumns}
            isLoading={isLoading && page === 1}
            cellGenerator={articlesCellGenerator}
            cellValueGetter={articlesCellValueGetter}
            shadow
            onRowClick={(row) => {
              mixTrackEvent({
                event: KnowledgeEvents.ARTICLE_VIEWED,
                properties: {
                  articleId: row.articleId,
                  title: row.title,
                },
              });
              const x = new URLSearchParams([
                ["searchPhrase", searchPhrase],
                ["dateFrom", dateRange.dateFrom],
                ["dateTo", dateRange.dateTo],
              ]);
              window.open(row.url, "_blank");
            }}
            defaultSortState={{
              key: "title",
              direction: "asc",
            }}
            fetchNextPage={() => {
              setPage(page + 1);
            }}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isLoading}
            page="knowledge-articles"
          />
        </div>
      </div>
    </div>
  );
};
