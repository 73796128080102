import {
  useAuthorizedInfiniteQuery,
  useAuthorizedMutation,
  useAuthorizedQuery,
} from "..";
import { params } from "../../params";
import { buildFilterSearchParams } from "../../routes/explore/context";
import {
  FilterValue,
  pasrseFilterValue,
} from "../../routes/explore/context/FilterContext.model";
import {
  ExploreRequest,
  ExploreResponse,
  ExploreValuesResponse,
  TicketExploreResponse,
} from "./Explore.model";
import {
  ConversationResponse,
  ExploreConversationRequest,
  ExploreConversationResponse,
} from "./ExploreConversation.model";

export const useExplore = () => {
  return useAuthorizedMutation<ExploreResponse, ExploreRequest>({
    url: `${params.API_URL}/platform/explorer/explore`,
    method: "POST",
  });
};

export const useExploreTickets = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/explorer/tickets`,
    method: "POST",
  });
};

export const useExploreValues = (enabled: boolean) => {
  return useAuthorizedQuery<ExploreValuesResponse>({
    queryKey: `useExploreValues`,
    url: `${params.API_URL}/platform/explorer/values`,
    method: "GET",
    enabled,
  });
};

export const useExploreInfiniteTickets = (
  {
    includeComments,
    ...filterValue
  }: FilterValue & { includeComments: boolean },
  enabled: boolean
) => {
  const searchParams = buildFilterSearchParams(filterValue);

  return useAuthorizedInfiniteQuery<TicketExploreResponse>({
    url: `${params.API_URL}/platform/explorer/tickets/`,
    method: "POST",
    queryKey: `useExploreInfiniteTickets_${searchParams.toString()}`,
    enabled,
    body: { ...pasrseFilterValue(filterValue), includeComments },
    pageKey: "page",
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.metadata?.isThereNextPage && allPages.length + 1;
    },
  });
};

export const getOpenTicketsFormExplorerObject = ({
  dateFrom,
  dateTo,
  page = 1,
  topicName,
  channel,
  tenantGroup,
}: {
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  topicName: string;
  channel?: string;
  tenantGroup?: string[];
}) => {
  const now = new Date();
  const lastMonth = new Date(
    now.getFullYear(),
    now.getMonth() - 1,
    now.getDate()
  );
  return {
    page,
    groupBy: "topics",
    dateFrom: dateFrom || lastMonth.toISOString(),
    dateTo: dateTo || now.toISOString(),
    stringSliceBy: {
      and: [
        {
          type: "status",
          operation: "or",
          values: [
            {
              type: "status",
              operation: "eq",
              value: "Escalated to T2",
            },
            {
              type: "status",
              operation: "eq",
              value: "Open",
            },
            {
              type: "status",
              operation: "eq",
              value: "T1",
            },
            {
              type: "status",
              operation: "eq",
              value: "open",
            },
            {
              type: "status",
              operation: "eq",
              value: "new",
            },
            {
              type: "status",
              operation: "eq",
              value: "New",
            },
            {
              type: "status",
              operation: "eq",
              value: "Pending agent",
            },
            {
              type: "status",
              operation: "eq",
              value: "Pending Yotpo",
            },
          ],
        },
        {
          type: "topic",
          operation: "and",
          values: [
            {
              type: "topic",
              operation: "eq",
              value: topicName,
            },
          ],
        },
        ...(channel && channel !== "All"
          ? [
              {
                type: "channel",
                operation: "and",
                values: [
                  {
                    type: "channel",
                    operation: "eq",
                    value: channel?.toLowerCase().replaceAll(" ", "_"),
                  },
                ],
              },
            ]
          : []),
        ...(tenantGroup && tenantGroup.length > 0
          ? [
              {
                type: "tenantGroup",
                operation: "or",
                values: tenantGroup.map((group) => ({
                  type: "tenantGroup",
                  operation: "eq",
                  value: group,
                })),
              },
            ]
          : []),
      ],
    },
    includeComments: true,
  };
};

export const useExploreConversations = () => {
  return useAuthorizedMutation<
    ExploreConversationResponse,
    ExploreConversationRequest
  >({
    url: `${params.API_URL}/platform/explorer/explore-conversations`,
    method: "POST",
  });
};

export const useInfiniteConversations = (
  {
    includeMessages,
    ...filterValue
  }: FilterValue & { includeMessages: boolean },
  enabled: boolean
) => {
  const searchParams = buildFilterSearchParams(filterValue);

  return useAuthorizedInfiniteQuery<ConversationResponse>({
    url: `${params.API_URL}/platform/explorer/conversations/`,
    method: "POST",
    queryKey: `useInfiniteConversations_${searchParams.toString()}`,
    enabled,
    body: { ...pasrseFilterValue(filterValue), includeMessages },
    pageKey: "page",
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.metadata?.isThereNextPage && allPages.length + 1;
    },
  });
};
