import React, { useContext, useEffect, useState } from "react";
import { Button, Tabs } from "@radix-ui/themes";
import { Dialog } from "../../../components/shared/dialog";
import styles from "./styles.module.scss";
import { SimulatorChannel } from "@/api/simulator/simulator.model";
import { Conversations } from "./conversations/Conversations";
import { Feedback } from "./feedbacks/Feedbacks";
import { Info } from "./info";
import { fetchConversations } from "../../../api/useConversations";
import { FeatureAccessContext } from "../../../context/featureAccess";
import { ChatSession } from "./types";
import { useReleases } from "../context/release";
import { useGetSimulatorById } from "../../../api/useRelease";
import { useGetAnsweredByAi } from "../../../api/useTickets";

export const AuditHub = ({
  simualtorName,
  channel,
}: {
  simualtorName: string;
  channel?: SimulatorChannel;
}) => {
  const [isOpen, setOpen] = useState(false);
  const { selectedWorkspace } = useContext(FeatureAccessContext);
  const { chosenSimulatorId } = useReleases();
  const { data: ticketAnsweredByAIData } = useGetAnsweredByAi(
    channel === "autonomous_ticket_event"
  );

  const { data } = useGetSimulatorById(
    !!chosenSimulatorId,
    chosenSimulatorId ?? 0
  );

  const [chatSessions, setChatSessions] = useState([]);

  useEffect(() => {
    fetchConversations(selectedWorkspace)
      .then((d) => {
        setChatSessions(
          d.chatSessions.sort(
            (a: ChatSession, b: ChatSession) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        );
      })
      .catch((error) => {});
  }, [selectedWorkspace]);

  return (
    <>
      <Button
        color="gray"
        variant="outline"
        highContrast
        onClick={() => setOpen((prev) => !prev)}
      >
        Audit Center
      </Button>
      <Dialog
        open={isOpen}
        onOpenChange={(value) => {
          setOpen(value);
        }}
      >
        <Dialog.Content size={"large"}>
          <Dialog.Title>
            Audit Center - {simualtorName}
            <Dialog.Close />
          </Dialog.Title>
          <div>
            <Tabs.Root defaultValue="general_stats">
              <Tabs.List>
                <Tabs.Trigger value="general_stats">Info</Tabs.Trigger>
                {channel === "autonomous_quackchat" && (
                  <Tabs.Trigger value="all_conversations">
                    Conversations
                  </Tabs.Trigger>
                )}
                <Tabs.Trigger value="all_feedback">Feedbacks</Tabs.Trigger>
              </Tabs.List>
              <div className={styles.bodyContainer}>
                <Tabs.Content value="general_stats">
                  <Info
                    trainingConversations={data?.conversations}
                    type={
                      channel === "autonomous_quackchat"
                        ? "conversations"
                        : "tickets"
                    }
                    name={simualtorName}
                    chatSessions={chatSessions}
                    ticketAnswered={ticketAnsweredByAIData?.length ?? 0}
                  />
                </Tabs.Content>
                <Tabs.Content value="all_conversations">
                  <Conversations chatSessions={chatSessions} />
                </Tabs.Content>
                <Tabs.Content value="all_feedback">
                  <Feedback conversations={data?.conversations ?? []} />
                </Tabs.Content>
              </div>
            </Tabs.Root>
          </div>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
