import { useInsights, useTicket } from "@/api/copilot";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import { getConversationalInsight } from "./lib";
import { Loader } from "./loader";
import { ChatComponent } from "./chat";
import { useGetTicketBySource } from "@/api/useGetTicket";
import { Sentiment } from "../explore/cells";

const subdomainMapper: { [x: string]: string } = {
  "global-e": "global-e",
  "minute-media": "",
};

export function Copilot() {
  const { tenantId, ticketId } = useParams();
  const { user } = useAuth0();
  const [subdomain, setSubdomain] = useState<string | null>(null);

  const { data: ticketData } = useGetTicketBySource(
    ticketId ?? "",
    Boolean(ticketId !== "")
  );

  useEffect(() => {
    if (ticketData?.ticket?.id) {
      setSubdomain(ticketData.ticket.id.split("::")[0]);
    }
  }, [ticketData]);

  const { data, error, refetch, isLoading } = useTicket(
    `${subdomain?.replace(".atlassian.net", "")}::${ticketId}`,
    ticketId !== null && user !== null && !!subdomain
  );

  const {
    data: insightsResponse,
    error: insightsError,
    refetch: refetchInsight,
    isLoading: insightLoading,
  } = useInsights(
    `${subdomain?.replace(".atlassian.net", "")}::${ticketId}`,
    ticketId !== null && user !== null
  );

  const ticket = (data as any)?.ticket;

  const insightsData = insightsResponse?.data;

  const fetchedTicket = insightsData || [];
  const insights = fetchedTicket.insights;

  const insightsId = useMemo(
    () => insights?.map((insight: any) => insight.id.toString()) || [],
    [insights]
  );

  const sortedInsightsItems = useMemo(
    () =>
      fetchedTicket && fetchedTicket?.insights
        ? [...fetchedTicket.insights].sort((a, b) => a.id - b.id)
        : [],
    [fetchedTicket]
  );

  const conversationalInsight = useMemo(
    () => getConversationalInsight(user!, sortedInsightsItems),
    [user, sortedInsightsItems]
  );

  const lastInsight = sortedInsightsItems?.[sortedInsightsItems?.length - 1];
  console.log({ lastInsight });
  return (
    <>
      {ticket && !isLoading && conversationalInsight ? (
        <>
          <div className={styles.ticketContainer}>
            {/* should be the messages */}
            {/* <div className={styles.conversationalContainer}>
              <div className={styles.conversationalBubble}>
                {messageContainers.map((sentence, index) => sentence)}
              </div>
            </div> */}
            <div className={styles.sentiment}>
              <Sentiment value={lastInsight.sentiment} />
            </div>
            <ChatComponent
              conversationalInsight={
                {
                  ...conversationalInsight,
                  content: `TLDR: ${lastInsight.tldr}\n\n${conversationalInsight.content}`,
                } as any
              }
              ticketId={ticketId ?? ""}
              refetchInsight={refetchInsight}
            />
          </div>
        </>
      ) : (
        <div className={styles.errorMessage}>
          <Loader loading />
          We're collecting the data ( up to 2 minutes )
        </div>
      )}
    </>
  );
}
