import { useState, useEffect } from "react";
import { TicketExploreRow } from "@/api/useExplore/Explore.model";
import { TicketScore } from "@/routes/quality/ticket-qa-score/TicketQAScore.model";
import { useGetTicket } from "../useGetTicket";

export const useTicketInfo = ({
  displayId,
  sourceID,
  id,
  isOpen = true,
}: {
  displayId: string;
  sourceID: string;
  id: string;
  isOpen?: boolean;
}) => {
  const [ticketData, setTicketData] = useState<
    (TicketExploreRow & { ticketScore?: TicketScore[] }) | null
  >(null);
  const [isLoading, setIsLoading] = useState(true);

  const { data: response } = useGetTicket(id, isOpen);
  useEffect(() => {
    const fetchTicket = async () => {
      const ticket = {
        ...response?.ticket,
        overview: {
          id: displayId || response?.ticket?.sourceID,
          subject: response?.ticket?.subject,
        },
        tldr: response?.ticket?.tldr,
        agent: response?.ticket?.agentAssigned?.name,
        topic: response?.ticket?.topic?.topic,
      } as TicketExploreRow & {
        ticketScore?: TicketScore[];
      };
      setTicketData(ticket || null);
      setIsLoading(false);
    };

    fetchTicket();
  }, [displayId, response, sourceID, isOpen]);

  return { ticketData, isLoading };
};
