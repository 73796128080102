import React, { useEffect, useState, useCallback, useContext } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import cx from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import Lottie from "react-lottie";
import type { Options as LottieOptions } from "react-lottie";
const LottieDisplay = Lottie as any; // Type assertion needed due to react-lottie types
import animationDataDark from "./lottie/dark_mode_loader.json";
import animationDataLight from "./lottie/light_mode_loader.json";
import "./App.css";
import Login from "./routes/login";
import AccountSetup from "./routes/accountSetup";
import { Sidebar } from "./components/sidebar";
import { InstallSuccess } from "./routes/successfullInstall";
import { Supervision } from "./routes/supervision";
import { Report } from "./routes/report";
import { AssessmentByTopicId } from "./routes/assessmentByTopicId";
import { AssessmentByTicketId } from "./routes/assessmentByTicketId";
import { Topbar, TopbarContext } from "./components/topbar";
import styles from "./App.module.scss";
import { Activation } from "./routes/activation";
import { ActivationTopic } from "./routes/activationTopic";
import { Analysis } from "./routes/analysis";
import { Performance } from "./routes/performance";
import { mixRegisterSuperProps, mixSetUserProps } from "./assets/mixpanel";
import { createToast } from "vercel-toast";
import { VoiceOfCustomer } from "./routes/vocOfCustomer";
import { IntegrationsInvite } from "./routes/integrations-invite";
import { Simulator } from "./routes/simulator";
import { SimulatorCoPilot } from "./routes/simulator-copilot";
import { Reports } from "./routes/reports";
import { ArticleWizard } from "./routes/articleWizard";
import { Customers } from "./routes/customers";
import { Customer } from "./routes/customer";
import { Simulators } from "./routes/simulators";
import { SimulatorWrapper } from "./routes/simulators/wrapper";
import ArticlePage from "./routes/articlePage";
import { ThemeContext } from "./components/Theme";
import { Explore } from "./routes/explore";
import { SharedReport, sharedReportRouteUri } from "./routes/sharedReport";
import { Button } from "@radix-ui/themes";
import { Evaluations, Validations } from "./routes/quality";
import { FeatureAccessContext } from "./context/featureAccess";
import { getMemberRole, Role } from "./api/members/members.model";
import { ChatbotSandbox } from "./routes/chatbot-sandbox";
import { useGetAccess } from "./api/useAccess";
import { SimulatorLiveMode } from "./routes/simulator-live-mode";
import ExploreDashboard from "./routes/explore/ExploreDashboard";
import { useFeatureFlag } from "configcat-react";
import GettingStarted from "./routes/onboarding/getting-started/GettingStarted";
import { ExploreConversation } from "./routes/explore/ExploreConversation";
import { automationConfigurationRouteTree } from "@/routes/automation-configuration/automationConfigurationRouteTree";
import { Playground } from "./routes/playground";
import { renderRouteTree } from "@/lib/renderRouteTree";
import { reportsRouteTree } from "@/features/reports/Reports";
import { HelpCenter } from "./routes/help-center";
import { PlaygroundMyHeritage } from "./routes/playground-myheritage";
import { useOnboarding } from "@/api/onboarding";
import { CopilotFeedback } from "./routes/copilot-feedback";
import { Copilot } from "./routes/copilot";
import { KnowledgeGapsPage } from "./routes/knowledge-gaps";
import { KnowledgeGap } from "./routes/knowledge-gap";
import { useAutomationConfigurationV2FeatFlag } from "@/features/automation-configuration/useAutomationConfigurationV2FeatFlag";
import { KnowledgeArticles } from "./routes/knowledge-articles";
import OGBoard from "./features/board";

const onboardingRoutes = new Map([
  ["/supervision", <Supervision />],
  ["/settings", <AccountSetup />],
  ["/activation", <Activation />],
  ["/assessment", <Analysis />],
  ["/performance", <Performance />],
  ["/voice-of-customer", <VoiceOfCustomer />],
  ["/customers", <Customers />],

  ["/getting-started", <GettingStarted />],
  [
    "/automation",
    <SimulatorWrapper>
      <Simulators />
    </SimulatorWrapper>,
  ],
  ["/knowledge-management/gaps", <KnowledgeGapsPage />],
  ["/knowledge-management/gaps/:groupId", <KnowledgeGap />],
  ["/knowledge-management/articles", <KnowledgeArticles />],
  ["/quality/evaluations", <Evaluations />],
  ["/quality/validations", <Validations />],
  ["/sessions-reports", <Reports />],
  ["/explore/insights", <ExploreDashboard />],
  ["/explore/tickets", <Explore />],
  ["/explore/conversations", <ExploreConversation />],
]);

const onlyAdminsRoutes = new Map([
  ["/supervision", <Supervision />],
  ["/settings", <AccountSetup />],
  ["/assessment/:topicId", <AssessmentByTopicId />],
  ["/assessment/:topicId/:ticketId", <AssessmentByTicketId />],
  ["/activation", <Activation />],
  ["/activation/:topicId", <ActivationTopic />],
  ["/knowledge-management/view/:topicId/wizard/:articleId", <ArticleWizard />],
  ["/knowledge-management/view/:topicId/article/:articleId", <ArticlePage />],
  ["/knowledge-management/gaps", <KnowledgeGapsPage />],
  ["/knowledge-management/articles", <KnowledgeArticles />],
  ["/knowledge-management/gaps/:groupId", <KnowledgeGap />],
  ["/assessment", <Analysis />],
  ["/performance", <Performance />],
  ["/voice-of-customer", <VoiceOfCustomer />],
  [
    "/automation/:simulatorId",
    <SimulatorWrapper>
      <Simulator />
    </SimulatorWrapper>,
  ],
  [
    "/automation/:simulatorId/:topicId/live-test",
    <SimulatorWrapper>
      <SimulatorLiveMode />
    </SimulatorWrapper>,
  ],
  [
    "/automation-copilot/:simulatorId",
    <SimulatorWrapper>
      <SimulatorCoPilot />
    </SimulatorWrapper>,
  ],
  [
    "/automation",
    <SimulatorWrapper>
      <Simulators />
    </SimulatorWrapper>,
  ],
  ["/sessions-reports", <Reports />],
  ["/customers", <Customers />],
  ["/customers/:organizationId", <Customer />],
  ["/explore/insights", <ExploreDashboard />],
  ["/explore/tickets", <Explore />],
  ["/explore/conversations", <ExploreConversation />],
  ["/quality", <Navigate to="/quality/evaluations" />],
  ["/quality/evaluations", <Evaluations />],
  ["/quality/validations", <Validations />],
  ["/getting-started", <GettingStarted />],
  ["/help-center", <HelpCenter />],
  ["/copilot-feedback", <CopilotFeedback />],
  ["/board", <OGBoard />],
]);

const onlyUsersRoutes = new Map([
  ["/report/:id", <Report />],
  ["/copilot/:tenantId/ticket/:ticketId", <Copilot />],
]);

// for it to work, make sure to update 'unprocted' in the sidebar
const openRoutes = new Map([
  ["/installation-successfull", <InstallSuccess />],
  ["/login", <Login />],
  ["/welcome", <IntegrationsInvite source="feature_flag" />],
  ["/upskill", <IntegrationsInvite source="searchparam" />],
  ["/chatbot-sandbox", <ChatbotSandbox />],
  ["/playground", <Playground />],
  ["/demo", <Playground />],
  [`${sharedReportRouteUri}/:reportId`, <SharedReport />],
]);

const renderUnauthorized = (logout: () => void) => (
  <div id="main" className={styles.container}>
    <div className={styles.secondContainer}>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          paddingTop: "300px",
          gap: "16px",
        }}
      >
        Sorry, you can try a different account{" "}
        <Button
          variant={"solid"}
          color={"gray"}
          highContrast
          onClick={() => logout()}
        >
          logout
        </Button>
      </div>
    </div>
  </div>
);

function App() {
  const [path, setPath] = useState("");
  const { user, logout, isLoading } = useAuth0();
  const location = useLocation();
  const [isAnimationLoading, setAnimationLoading] = useState(true);
  const [isWorkspaceChange, setWorkspaceChange] = useState(false);
  const { selectedWorkspace } = useContext(FeatureAccessContext);
  const { isDarkMode } = useContext(ThemeContext);
  const onboardingValue = useOnboarding();

  useEffect(() => {
    setTimeout(() => {
      setAnimationLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (user) {
      mixSetUserProps(true, {
        name: user?.name,
        email: user?.email,
        id: user?.sub,
        tenant: selectedWorkspace,
        webapp_user: true,
      });
      mixRegisterSuperProps({
        webapp_event: true,
      });
      Sentry.setUser({
        id: user?.email,
        email: user?.email,
      });
      Sentry.setTag("tenant", selectedWorkspace);
    }
  }, [user, selectedWorkspace]);

  const { data: membersData, isLoading: membersLoading } = useGetAccess(!!user);

  const isAdmin = membersData && getMemberRole(membersData) !== Role.AGENT;

  useEffect(() => {
    const currentPath = location.pathname;
    const isAdminRoute = Array.from(onlyAdminsRoutes.keys()).some(
      (path) =>
        path === currentPath ||
        (path.includes(":") && currentPath.startsWith(path.split(":")[0]))
    );

    if (
      !membersLoading &&
      !isLoading &&
      membersData !== undefined &&
      isAdminRoute &&
      !isAdmin
    ) {
      createToast(`Not Authorized user, Contact your manager`, {
        type: "dark",
        timeout: 3000,
      });
    }
  }, [isAdmin, membersData, location, membersLoading, isLoading]);

  const renderRoute = useCallback(
    (path: string, element: React.ReactNode) => {
      if (openRoutes.has(path)) {
        return <Route key={path} path={path} element={element} />;
      }
      if (onlyAdminsRoutes.has(path)) {
        return (
          <Route
            key={path}
            path={path}
            element={isAdmin ? element : renderUnauthorized(logout)}
          />
        );
      }
      if (onlyUsersRoutes.has(path)) {
        return <Route key={path} path={path} element={element} />;
      }
      return null;
    },
    [isAdmin, logout]
  );

  useEffect(() => {
    setWorkspaceChange(true);
    setTimeout(() => {
      setWorkspaceChange(false);
    }, 3);
  }, [selectedWorkspace]);

  const { value: exploreDashboardEnabled } = useFeatureFlag(
    "exploreDashboard",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const newAutomationConfigurationEnabled =
    useAutomationConfigurationV2FeatFlag();

  const { value: reportsEnabled } = useFeatureFlag("reports", false, {
    identifier: user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });

  // const appRoutes = onboardingValue.isDone
  //   ? onlyAdminsRoutes
  //   : onboardingRoutes;

  const defaultRouteUrl = exploreDashboardEnabled
    ? "/explore/insights"
    : "/explore/tickets";

  return (
    <div
      id="main"
      className={cx(
        {
          [styles.dark_mode]: isDarkMode,
          [styles.light_mode]: !isDarkMode,
        },
        styles.container
      )}
    >
      {!isLoading &&
      !membersLoading &&
      !onboardingValue.isLoading &&
      !isAnimationLoading ? (
        <>
          <Sidebar />
          {isWorkspaceChange ? (
            <></>
          ) : (
            <div className={styles.secondContainer}>
              <Topbar />
              <TopbarContext.Provider value={{ path: path, setPath: setPath }}>
                <Routes>
                  {[
                    ...Array.from(onlyAdminsRoutes),
                    ...Array.from(onlyUsersRoutes),
                    ...Array.from(openRoutes),
                  ].map(([path, element]) => renderRoute(path, element))}

                  {isAdmin &&
                    reportsEnabled &&
                    renderRouteTree(reportsRouteTree)}

                  {isAdmin &&
                    newAutomationConfigurationEnabled &&
                    renderRouteTree(automationConfigurationRouteTree)}

                  <Route path="*" element={<Navigate to={defaultRouteUrl} />} />
                </Routes>
              </TopbarContext.Provider>
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LottieDisplay
            options={
              {
                loop: true,
                autoplay: true,
                animationData: isDarkMode
                  ? animationDataDark
                  : animationDataLight,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              } as LottieOptions
            }
            height={400}
            width={400}
            isClickToPauseDisabled={true}
          />
        </div>
      )}
    </div>
  );
}

export default App;
