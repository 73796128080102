import React, { useState } from "react";
import { Button, Flex, TextArea } from "@radix-ui/themes";
import * as Popover from "@radix-ui/react-popover";
import { FeedbackType } from "./index.types";
import styles from "./styles.module.scss";

interface FeedbackProps {
  value: { comment: string; type: FeedbackType | null };
  onChange: ({
    comment,
    type,
  }: {
    comment: string;
    type: FeedbackType;
  }) => void;
}

export const Feedback = ({ value, onChange }: FeedbackProps) => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState(value.comment);

  const handleGoodFeedback = () => {
    onChange({ comment, type: FeedbackType.POSITIVE });
    setOpen(false);
  };

  const handleBadFeedback = () => {
    setOpen(true);
  };

  const handleSubmitNegativeFeedback = () => {
    onChange({ comment, type: FeedbackType.NEGATIVE });
    setOpen(false);
  };

  return (
    <Flex gap="2">
      <Button
        variant={value.type === FeedbackType.POSITIVE ? "solid" : "outline"}
        size="1"
        onClick={handleGoodFeedback}
        color="gray"
      >
        👍
      </Button>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>
          <Button
            variant={value.type === FeedbackType.NEGATIVE ? "solid" : "outline"}
            size="1"
            className="thumbs-down"
            onClick={handleBadFeedback}
            color="gray"
          >
            👎
          </Button>
        </Popover.Trigger>
        <Popover.Content
          className={styles.popoverContent}
          align="start"
          sideOffset={10}
        >
          <Flex direction="column" gap="3">
            <TextArea
              placeholder="Please provide feedback"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Flex justify="end" gap="3">
              <Button
                style={{ cursor: "pointer" }}
                color="gray"
                variant="solid"
                highContrast
                onClick={handleSubmitNegativeFeedback}
              >
                Submit
              </Button>
            </Flex>
          </Flex>
        </Popover.Content>
      </Popover.Root>
    </Flex>
  );
};
