import {
  useAuthorizedMutation,
  useAuthorizedQuery,
  useGraphQLAuthorizedQuery,
} from "..";
import { params } from "../../params";
import { graphql } from "../../gql/gql";
import {
  ConnectIntegrationRequest,
  CreateIntegrationRequest,
  ConnectIntegrationResponse,
  Integration,
  TenantGroup,
  isIntegrationName,
  BrandResponse,
  IntegrationType,
  IntegrationName,
} from "./Integration.model";
import { useCallback, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useWorkspace } from "../../routes/simulator/context/wrokspace";
import { IntegrationEvents, mixTrackEvent } from "@/assets/mixpanel";

const getIntegrationsByOwnerQueryDocument = graphql(/* GraphQL */ `
  query listIntegrationsByOwner {
    listIntegrations {
      nextToken
      items {
        id
        name
        metadata
        status
        type
        tenantID
      }
    }
  }
`);

type GraphQLIntegrationsResponse = {
  listIntegrations: { items: (Integration & { tenantID: string })[] };
};

function useDymamoIntegrations() {
  const { user } = useAuth0();
  const { workspace } = useWorkspace();

  const query = useGraphQLAuthorizedQuery<GraphQLIntegrationsResponse>({
    queryKey: ["getIntegrations", workspace],
    url: `${params.GRAPHQL_URL}/graphql/`,
    document: getIntegrationsByOwnerQueryDocument,
    variables: { owner: user?.owner },
    enabled: user != null,
  });

  const data = useMemo<Integration[] | undefined>(() => {
    if (query.data == null) {
      return;
    }

    return query.data.listIntegrations.items
      .filter(({ tenantID }) => tenantID === workspace)
      .map(({ tenantID, ...rest }) => rest);
  }, [query.data]);

  return { ...query, data };
}

function useGetTenantIntegrations() {
  return useAuthorizedQuery<Integration[]>({
    url: `${params.API_URL}/platform/integration`,
    queryKey: "getTenantIntegrations",
    enabled: true,
    method: "GET",
  });
}

export const useIntegrations = () => {
  const dynamoIntegrationsQuery = useDymamoIntegrations();
  const platformIntegrationsQuery = useGetTenantIntegrations();

  const data = useMemo<Integration[]>(() => {
    const dynamoIntegrations = dynamoIntegrationsQuery.data ?? [];
    const platformIntegrations = platformIntegrationsQuery.data ?? [];

    const mergedIntegrations = [
      ...dynamoIntegrations.filter(
        (dynamoIntegration) =>
          !platformIntegrations.some(
            (platformIntegration) =>
              platformIntegration.type === dynamoIntegration.type &&
              platformIntegration.name === dynamoIntegration.name
          )
      ),
      ...platformIntegrations,
    ].filter((integration) => isIntegrationName(integration.name));

    return mergedIntegrations;
  }, [dynamoIntegrationsQuery.data, platformIntegrationsQuery.data]);

  const refetch = useCallback(async () => {
    await Promise.all([
      dynamoIntegrationsQuery.refetch(),
      platformIntegrationsQuery.refetch(),
    ]);
  }, [dynamoIntegrationsQuery.refetch, platformIntegrationsQuery.refetch]);

  return {
    data,
    refetch,
    isLoading:
      dynamoIntegrationsQuery.isLoading || platformIntegrationsQuery.isLoading,
    isFetched:
      dynamoIntegrationsQuery.isFetched && platformIntegrationsQuery.isFetched,
  };
};

export function useCreateIntegrationV2() {
  return useAuthorizedMutation<void, ConnectIntegrationRequest>({
    url: `${params.API_URL}/platform/integration`,
    method: "POST",
    onSuccess: (data, variables, context) => {
      mixTrackEvent({
        event: IntegrationEvents.CREATE_INTEGRATION,
        properties: {
          ...variables
        },
      });
    }
  });
}

export function useCompleteTicketSystemConfiguration() {
  return useAuthorizedMutation<void, CreateIntegrationRequest>({
    url: `${params.API_URL}/platform/integration/ticket-system/complete`,
    method: "POST",
    onSuccess: (data, variables, context) => {
      mixTrackEvent({
        event: IntegrationEvents.COMPLETE_TICKET_SYSTEM_INTEGRATION,
        properties: {
          ...variables
        },
      });
    }
  });
}

export function useGetTenantGroupsInTicketSystem(enabled: boolean) {
  return useAuthorizedQuery<{groups: TenantGroup[]}>({
    url: `${params.API_URL}/platform/integration/groups`,
    queryKey: "useGetTenantGroups",
    enabled,
    method: "GET",
  });
}

export function useGetAllBrandsFromIntegration(enabled: boolean) {
  return useAuthorizedQuery<BrandResponse[]>({
    url: `${params.API_URL}/platform/tenant-brand/all`,
    queryKey: "getAllBrandsFromIntegration",
    enabled,
    method: "GET",
  });
}

export function useCreateBrandsConfiguration() {
  return useAuthorizedMutation<void, {type: IntegrationType,
    name: IntegrationName,
    brandIds: string[]}>({
    url: `${params.API_URL}/platform/integration/configuration/brands`,
    method: "POST",
    onSuccess: (data, variables, context) => {
      mixTrackEvent({
        event: IntegrationEvents.CREATE_BRAND_CONFIGURATION,
        properties: {
          ...variables
        },
      });
    }
  });
}
