export type TSimulatorLiveModeTicket = {
  id: string;
  createdAt: string;
  updatedAt: string;
  description: string;
  displayId: string | null;
  status: string;
  channel: string | null;
  subject: string;
  priority: string;
  topic: {
    topic: string;
  };
  category: string | null;
  agentAssigned: {
    id: number;
    name: string;
    agentGroups: {
      name: string;
    }[];
  };
  kbEvaluation: {
    coverageLevel: string;
  };
  ticketCalculations: any | null;
  ticketCsat: any | null;
  comments: {
    rawBody: string;
    agent: boolean;
    createdAt: string;
    isInternal: boolean;
  }[];
};

export type TSimulatorLiveModeReviewTicket = {
  ticketId: string;
  good?: boolean;
  comment?: string;
};

export enum InteractionType {
  TICKET = "ticket",
  CONVERSATION = "conversation",
}

export enum InteractionInTrainingStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  IGNORED = "IGNORED",
}
