import { useAuthorizedQuery, useAuthorizedMutation } from "..";
import { params } from "../../params";

export const useGetInteractionInTraining = (
  topicName: string,
  enabled: boolean
) => {
  return useAuthorizedQuery({
    queryKey: `useGetInteractionInTraining-${topicName}`,
    url: `${params.API_URL}/platform/interaction-in-training/topics/${topicName}`,
    enabled,
  });
};

export const useCreateInteractionInTraining = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/interaction-in-training/create`,
    method: "POST",
  });
};

export const useChangeStatusInteractionInTraining = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/interaction-in-training/status`,
    method: "PUT",
  });
};
