import { DateRange } from "@/components/shared/date-range-picker/DateRangePicker";

const channelInteractions = ["tickets", "conversations"] as const;
export type ChannelInteraction = (typeof channelInteractions)[number];

export function isChannelInteraction(
  channel: string
): channel is ChannelInteraction {
  return channelInteractions.includes(channel as ChannelInteraction);
}

export type QuackInteractionsRequest = {
  channels: ChannelInteraction[];
} & Partial<DateRange>;

export type CountQuackInteractionsResponse = {
  name: string;
  value: number;
};

export type CountUsersInteractedWithQuackResponse = {
  name: string;
  value: number;
};

export type CountQuackMessagesResponse = {
  name: string;
  value: number;
};

export type FirstReleaseResponse = {
  firstRelease: string | null;
};

export type ShareOfWorkValue = {
  date: string;
  quackInteractionsAmount: number;
  nonQuackInteractionsAmount: number;
};

export type QuackInteractionsResponse = {
  shareOfWork: ShareOfWorkValue[];
};

const quackHiringMetricTypes = ["FRT", "TTR"] as const;
export type QuackHiringMetricType = (typeof quackHiringMetricTypes)[number];

export type AvgTimeMetricRequest = QuackInteractionsRequest & {
  metricType: QuackHiringMetricType;
};

export type AvgTimeMetricValue = {
  date: string;
  quackInteractionsAmount: number | undefined;
  allInteractionsAmount: number | undefined;
};

export type AvgTimeMetricResponse = AvgTimeMetricValue[];

export const metricsScopes = ["agents", "teams"] as const;
export type MetricsScope = (typeof metricsScopes)[number];

export function isMetricsScope(scope: string): scope is MetricsScope {
  return metricsScopes.includes(scope as MetricsScope);
}

export type QaEvaluationMetricsRequest = DateRange & {
  scope?: MetricsScope;
};

export type QaEvaluationMetricsResponse = {
  id: string;
  name?: string;
  dailyScores: Record<string, Record<string, number | null>>;
}[];

export type LeanDataValue = {
  name?: string;
  ticketEvaluatedCount: number;
  values: {
    date: string;
    avgScores: number;
    avgSentiment: number;
  }[];
};

export type QaKeyMetricsResponse = {
  [id: string]: LeanDataValue;
};

export type AvgQaScoreByDates = {
  name: string;
  scores: { date: string; count: number }[];
};

export type AvgQaScoreResponse = {
  [id: string]: AvgQaScoreByDates;
};

export type TicketResolutionReponse = {
  quackInvolved: number;
  quackSkippedOrEscalated: number;
  quackSolvedAlone: number;
  totalInteraction: number;
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function sortAvgTimeMetric<DataType extends { date: string }>(
  data: DataType[],
  startMonth: string = "January"
): DataType[] {
  const startIndex = months.indexOf(startMonth);
  if (startIndex === -1) {
    throw new Error(`Invalid start month: ${startMonth}`);
  }

  // Make a copy so we don't change the original array.
  return data.slice().sort((a, b) => {
    // Find the calendar index of each month.
    const aIndex = months.indexOf(a.date);
    const bIndex = months.indexOf(b.date);

    // Adjust the indices so that startMonth becomes 0.
    // (Using modulo arithmetic makes the order circular.)
    const aAdjusted = (aIndex - startIndex + 12) % 12;
    const bAdjusted = (bIndex - startIndex + 12) % 12;

    return aAdjusted - bAdjusted;
  });
}

export function padMonths(startMonth: string, numMonths: number) {
  const monthIndex = months.indexOf(startMonth);
  const prevMonths = [...Array(numMonths)].map(
    (_, i) => months[(monthIndex - numMonths + i + 12) % 12]
  );
  return prevMonths;
}
