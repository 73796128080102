import { Message, useChat } from "ai/react";
import { useContext, useEffect, useRef, useState } from "react";

import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { params } from "@/params";

export const CONVERSATIONAL_INSIGHT_MESSAGE_ID_PREFIX =
  "conversational-insight";

function getConversationalInsightMessage({
  id,
  content,
  feedback,
}: {
  id: number;
  content: string;
  commentExternalId: string;
  feedback?: number;
}) {
  return {
    role: "assistant",
    id: `${CONVERSATIONAL_INSIGHT_MESSAGE_ID_PREFIX}#${id}`,
    content: content,
    feedback: feedback === undefined ? undefined : feedback === 1,
  } as Message;
}

export const useAiChat = ({
  chatId,
  ticketId,
  data,
  conversationalInsight,
}: {
  chatId: string;
  ticketId: string;
  data: Message[];
  conversationalInsight?: {
    id: number;
    content: string;
    commentExternalId: string;
    feedback?: number;
  };
}) => {
  const { API_URL } = params;

  const lastIdRef = useRef<null | string>(null);
  const [throttledMessages, setThrottledMessages] = useState<Message[]>([]);
  const [lastMessageId, setLastMessageId] = useState<string | null>(null);
  const [isDelayed, setIsDelayed] = useState(false);
  const [typingMessage, setTypingMessage] = useState<Message | null>(null);
  const [typingProgress, setTypingProgress] = useState<number>(0); // Track typing progress

  const [tokenId, setToken] = useState<IdToken | undefined>(undefined);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    (async () => {
      const token = await getIdTokenClaims();
      setToken(token);
    })();
  }, [isAuthenticated, getIdTokenClaims]);

  const authToken = tokenId?.__raw ? tokenId.__raw : "";

  useEffect(() => {
    setThrottledMessages([]);
  }, [chatId]);

  const {
    messages,
    input,
    handleInputChange,
    handleSubmit,
    isLoading,
    setMessages,
    append,
  } = useChat({
    id: chatId,
    api: `${API_URL}/sessions-api/copilot-chat/message`,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    body: {
      copilotChatSessionId: chatId,
    },
    onFinish: () => {},
    initialMessages: [],
  });

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    let charIndex = typingProgress;

    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (
        messages.length - 1 > throttledMessages.length ||
        lastIdRef.current === lastMessage.id
      ) {
        setThrottledMessages(messages);
      } else {
        const latestMessage = messages[messages.length - 1];

        if (latestMessage.id === lastMessageId) {
          setThrottledMessages((prev) => {
            const updatedMessages = [...prev];
            updatedMessages[updatedMessages.length - 1] = latestMessage;
            return updatedMessages;
          });
        } else {
          if (latestMessage.role === "user") {
            setThrottledMessages((prev) => [...prev, latestMessage]);
            setLastMessageId(latestMessage.id);
            setTypingProgress(0);
          } else {
            setIsDelayed(true);
            setTypingMessage(
              (prev) => prev || { ...latestMessage, content: "" }
            );

            timerId = setInterval(() => {
              if (charIndex < latestMessage.content.length) {
                setTypingMessage((prev) => {
                  if (prev) {
                    return {
                      ...prev,
                      content: latestMessage.content.slice(0, charIndex + 1),
                    };
                  }
                  return prev;
                });
                charIndex++;
                setTypingProgress(charIndex);
              } else {
                clearInterval(timerId);
                setThrottledMessages((prev) => [...prev, latestMessage]);
                setLastMessageId(latestMessage.id);
                setTypingProgress(0);
                setIsDelayed(false);
                setTypingMessage(null);
              }
            }, 12);
          }
        }
        // lastIdRef.current = latestMessage.id;
      }
    }

    return () => clearInterval(timerId);
  }, [messages, lastMessageId, typingProgress]);

  const initialMessages = [
    ...(conversationalInsight
      ? [getConversationalInsightMessage(conversationalInsight)]
      : []),
    ...data,
  ];

  return {
    messages: typingMessage
      ? [...initialMessages, ...throttledMessages, typingMessage]
      : [...initialMessages, ...throttledMessages],
    append,
    input,
    handleInputChange,
    handleSubmit,
    isLoading: isLoading || isDelayed,
    setMessages,
    isTyping: isLoading || isDelayed,
  };
};
