import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDebouncedCallback } from "@/lib/useDebouncedCallback";
import {
  Release,
  ReleasePolicyItem,
  useGetSimulator,
  useUpdateRelease,
} from "../../api/simulator";
import { UpdateStatus } from "./utils/automation-configuration.utils";

export function useSimulatorConfiguration(simulatorId?: string) {
  const { user } = useAuth0();

  const [status, setStatus] = useState<UpdateStatus>("idle");

  const {
    data: simulator,
    isLoading: loadingSimulator,
    isFetched: simulatorFetched,
    refetch: refetchSimulator,
  } = useGetSimulator(user != null ? simulatorId : undefined);

  const release = useMemo(() => {
    return simulator?.releases.find(
      ({ status, publishedAt }) => status === "draft" && publishedAt === null
    );
  }, [simulator?.releases]);

  const { mutateAsync: updateRelease } = useUpdateRelease(release?.id ?? NaN);

  const updateReleasePolicy = useCallback(
    async (releasePolicy: ReleasePolicyItem[]) => {
      if (simulator == null || release == null) {
        return;
      }

      setStatus("saving");
      const res = await updateRelease({
        id: release.id,
        simulatorId: simulator.id,
        status: release.status,
        releasePolicy: JSON.stringify(releasePolicy),
      });

      if (res != null) {
        await refetchSimulator();
      }

      setStatus("saved");
      return res;
    },
    [release, simulator, updateRelease, refetchSimulator]
  );

  const debouncedUpdateReleasePolicy = useDebouncedCallback<
    Promise<Release | undefined>,
    [ReleasePolicyItem[]]
  >(updateReleasePolicy, 1000);

  useEffect(() => {
    if (status === "saved") {
      const timer = setTimeout(() => setStatus("idle"), 2000);
      return () => clearTimeout(timer);
    }
  }, [status]);

  return {
    simulator,
    loadingSimulator: loadingSimulator || !simulatorFetched,
    release,
    updateReleasePolicy: debouncedUpdateReleasePolicy,
    status,
  };
}
