export function toCamelCase(str: string) {
  return str.replace(/([-_][a-z])/gi, (match) => {
    return match.toUpperCase().replace("-", "").replace("_", "");
  });
}

export function toCamelCaseWithFirstLetterCaps(str: string) {
  if (!str) {
    return "";
  }
  if (!str.includes("-") && !str.includes("_")) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const camelCaseStr = str.replace(/([-_][a-z])/gi, (match) => {
    return match.toUpperCase().replace("-", "").replace("_", "");
  });
  return camelCaseStr.charAt(0).toUpperCase() + camelCaseStr.slice(1);
}

export function toTitleCase(str: string) {
  if (!str) {
    return "";
  }

  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
