import { useMemo, useState, useCallback } from "react";
import dayjs from "dayjs";
import { Flex, Text } from "@radix-ui/themes";
import Select from "@/components/shared/select";
import { DateRange } from "@/components/shared/date-range-picker/DateRangePicker";
import {
  ChannelInteraction,
  isChannelInteraction,
} from "@/api/reports/reports.model";
import { GeneralWidget } from "./widgets/GeneralWidget";
import {
  AvgTTRWidget,
  AvgFRTWidget,
} from "./widgets/quack-hiring/AvgTimeMetricWidget";
import { ShareOfWorkWidget } from "./widgets/quack-hiring/ShareOfWorkWidget";

interface HeaderProps {
  channels: ChannelInteraction[];
  onChannelChange: (newChannels: ChannelInteraction[]) => void;
}

function Header({ channels, onChannelChange }: HeaderProps) {
  const value = useMemo(() => {
    if (channels.length === 1) {
      return channels[0];
    } else {
      return "all";
    }
  }, [channels]);

  const handleChannelChange = useCallback(
    (newChannel: string) => {
      if (isChannelInteraction(newChannel)) {
        return onChannelChange([newChannel]);
      } else {
        return onChannelChange(["tickets", "conversations"]);
      }
    },
    [onChannelChange]
  );

  return (
    <Flex justify={"between"}>
      <Flex p={"5"} direction={"column"} gap={"1"}>
        <Text color={"gray"} size={"3"} weight={"medium"} highContrast>
          🐣 Quack Hiring
        </Text>

        <Text color={"gray"} size={"2"}>
          Showing how Quack has impacts main metrics
        </Text>
      </Flex>

      <Flex px={"6"} py={"4"} gap={"2"} align={"center"}>
        <Select
          size="small"
          value={value}
          onChange={handleChannelChange}
          options={[
            { value: "all", label: "All Channels" },
            { value: "tickets", label: "Tickets" },
            { value: "conversations", label: "Conversations" },
          ]}
        />
      </Flex>
    </Flex>
  );
}

export function QuackHiringReport() {
  const [dateRange] = useState<DateRange>({
    dateFrom: dayjs().subtract(6, "month").toISOString(),
    dateTo: dayjs().toISOString(),
  });

  const [channels, setChannels] = useState<ChannelInteraction[]>([
    "tickets",
    "conversations",
  ]);

  return (
    <Flex pt={"9"} pb={"5"} direction={"column"} gap={"4"}>
      <Header channels={channels} onChannelChange={setChannels} />

      <Flex gap={"4"} wrap={"wrap"}>
        <GeneralWidget channels={channels} />

        <ShareOfWorkWidget dateRange={dateRange} channels={channels} />

        <AvgTTRWidget dateRange={dateRange} channels={channels} />

        <AvgFRTWidget dateRange={dateRange} channels={channels} />
      </Flex>
    </Flex>
  );
}
