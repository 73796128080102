import { useMemo, useState } from "react";
import cx from "classnames";
import { Button, Text, Flex, ScrollArea } from "@radix-ui/themes";
import {
  QuackChatSystemMessageType,
  quackChatSystemMessageTypes,
  quackChatSystemMessageTypesMap,
  useGetQuackChatSystemMessage,
} from "@/api/quackchat/configuration/useQuackChatSystemMessageOverride";
import { QuackChatConfigResponse } from "@/components/quack-chat/QuackChatConfig.model";
import { QuackChat } from "@/components/quack-chat/QuackChat";
import { Message } from "@/components/quack-chat/core/entities/Message";
import { EditSystemMessage } from "./EditSystemMessage";
import styles from "./QuackchatSystemMessageConfiguration.module.scss";

interface QuackChatSystemMessageOverrideProps {
  config: QuackChatConfigResponse;
  defaultMessageType?: QuackChatSystemMessageType;
}

export function QuackChatSystemMessageOverride({
  config,
  defaultMessageType,
}: QuackChatSystemMessageOverrideProps) {
  const [selectedMessageType, setSelectedMessageType] = useState<
    QuackChatSystemMessageType | undefined
  >(defaultMessageType);

  const {
    data: systemMessage,
    refetch: refetchSystemMessage,
    isLoading: loadingSystemMessage,
  } = useGetQuackChatSystemMessage(selectedMessageType);

  const messages = useMemo<Message[]>(() => {
    if (systemMessage == null) {
      return [];
    }

    return [
      {
        action: "standard_message",
        author: "AUTO_AGENT",
        text: systemMessage.message,
        deduplicationKey: "edit_message",
        createdAt: "2021-09-01T12:00:00Z",
        data: {},
      },
    ];
  }, [systemMessage]);

  return (
    <Flex className={styles.QuackChatSystemMessageOverride}>
      <ScrollArea
        style={{ width: "400px", height: "100%" }}
        scrollbars={"vertical"}
      >
        <Flex direction={"column"} gap={"24px"} className={styles.Sidebar}>
          <Text size={"7"} color={"gray"} weight={"medium"} highContrast>
            All Messages
          </Text>

          <Flex direction={"column"} gap={"1"}>
            {quackChatSystemMessageTypes.map((type) => (
              <Button
                key={type}
                size={"1"}
                color={"gray"}
                variant={"soft"}
                highContrast
                className={cx(styles.SidebarItem, {
                  [styles.isActive]: selectedMessageType === type,
                })}
                onClick={() => setSelectedMessageType(type)}
              >
                <Text size={"2"} color={"gray"} weight={"medium"} highContrast>
                  {quackChatSystemMessageTypesMap[type].title}
                </Text>

                <Text
                  size={"2"}
                  color={"gray"}
                  truncate
                  className={styles.Description}
                >
                  {quackChatSystemMessageTypesMap[type].description}
                </Text>
              </Button>
            ))}
          </Flex>
        </Flex>
      </ScrollArea>

      <Flex flexGrow={"1"} direction={"column"} className={styles.Preview}>
        {selectedMessageType != null && (
          <>
            <Flex py={"24px"} px={"40px"} direction={"column"} gap={"1"}>
              <Text size={"2"} color={"gray"} weight={"medium"} highContrast>
                {quackChatSystemMessageTypesMap[selectedMessageType].title}
              </Text>

              <Text size={"2"} color={"gray"}>
                {
                  quackChatSystemMessageTypesMap[selectedMessageType]
                    .description
                }
              </Text>
            </Flex>

            <Flex flexGrow={"1"} justify={"center"} align={"center"}>
              <QuackChat
                config={config}
                messages={messages}
                thinking={loadingSystemMessage}
              />

              {selectedMessageType != null && messages?.length > 0 && (
                <EditSystemMessage
                  type={selectedMessageType}
                  message={messages[0]}
                  onSuccess={refetchSystemMessage}
                />
              )}
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
}
