import { createContext } from "react";

export enum EMessagesContainerType {
  REST = "rest",
  PROACTIVE = "proactive",
  CHAT = "chat",
}

export const TypeContext = createContext<{
  type: EMessagesContainerType;
}>({
  type: EMessagesContainerType.CHAT,
});
