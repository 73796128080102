import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { params } from "@/params";
import { useDebouncedCallback } from "@/lib/useDebouncedCallback";
import { useApi } from "@/api/useApi";
import { useAuthorizedMutation, useAuthorizedQuery } from "@/api";
import { QuackChatConfigResponse } from "@/components/quack-chat/QuackChatConfig.model";
import { UpdateStatus } from "@/features/automation-configuration/utils/automation-configuration.utils";

type QuackchatConfigurationResponse = {
  token: string;
  quackchatConfiguration: QuackChatConfigResponse;
};

export function useGetQuackchatConfiguration() {
  return useAuthorizedQuery<QuackchatConfigurationResponse>({
    queryKey: "useGetQuackchatConfiguration",
    url: `${params.API_URL}/platform/quackchat/configuration`,
    enabled: true,
  });
}

type QuackchatConfigurationRequest = {
  configuration: QuackChatConfigResponse;
};

function useUpdateQuackchatConfiguration() {
  return useAuthorizedMutation<void, QuackchatConfigurationRequest>({
    url: `${params.API_URL}/platform/quackchat/configuration`,
    method: "PUT",
  });
}

export type UploadQuackchatAssestResponse = {
  name: string;
  path: string;
};

export function useUploadQuackchatAssest() {
  const { api } = useApi();

  return useMutation<UploadQuackchatAssestResponse, unknown, File>(
    async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      const res = await api.post(
        `/platform/quackchat/upload-assest`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return res.data;
    }
  );
}

export function useQuackchatConfiguration() {
  const { data, isLoading, refetch } = useGetQuackchatConfiguration();

  const updateConfigurationMutation = useUpdateQuackchatConfiguration();

  const [updateStatus, setUpdateStatus] = useState<UpdateStatus>("idle");
  const [localConfiguration, setLocalConfiguration] =
    useState<QuackChatConfigResponse>();

  const updateConfiguration = useCallback(
    async (configuration: QuackChatConfigResponse) => {
      setUpdateStatus("saving");
      const res = await updateConfigurationMutation.mutateAsync({
        configuration,
      });
      await refetch();

      setUpdateStatus("saved");
      return res;
    },
    [updateConfigurationMutation.mutateAsync, refetch]
  );

  const debouncedUpdateConfiguration = useDebouncedCallback<
    Promise<void>,
    [QuackChatConfigResponse]
  >(updateConfiguration, 1000);

  const handleUpdateConfiguration = useCallback(
    (configuration: QuackChatConfigResponse) => {
      setLocalConfiguration(configuration);

      debouncedUpdateConfiguration(configuration);
    },
    [debouncedUpdateConfiguration]
  );

  useEffect(() => {
    if (data != null) {
      setLocalConfiguration(data.quackchatConfiguration);
    }
  }, [data]);

  useEffect(() => {
    if (updateStatus === "saved") {
      const timer = setTimeout(() => setUpdateStatus("idle"), 2000);
      return () => clearTimeout(timer);
    }
  }, [updateStatus]);

  return {
    configuration: localConfiguration,
    isLoading,
    updateConfiguration: handleUpdateConfiguration,
    updateStatus,
  };
}
