import { useCallback, useEffect, useMemo, useRef } from "react";
import { Flex, Spinner } from "@radix-ui/themes";
import { RadioCardGroup } from "@/components/shared/inputs/radio-group/RadioCardGroup";
import { ColorPicker } from "@/components/shared/color-picker/ColorPicker";
import {
  ChatSettings,
  QuackChatConfigResponse,
} from "@/components/quack-chat/QuackChatConfig.model";
import { defaultTheme, QuackChat } from "@/components/quack-chat/QuackChat";
import {
  useQuackchatConfiguration,
  useUploadQuackchatAssest,
} from "@/api/quackchat/configuration/useQuackchatConfiguration";
import { CollapsibleFieldList } from "../parts/CollapsibleFieldList";
import { FieldGroup } from "../parts/FieldGroup";
import { FileUploadComponent } from "../parts/FileUploadComponent";
import { Message } from "@/components/quack-chat/core/entities/Message";
import { AlignEndVertical, AlignStartVertical } from "lucide-react";
import { AutomationConfigurationContentHeader } from "../parts/AutomationConfigurationContentHeader";
import { u } from "framer-motion/dist/types.d-B50aGbjN";

export function QuackchatChannelAppearanceConfiguration() {
  const { configuration, isLoading, updateStatus, updateConfiguration } =
    useQuackchatConfiguration();

  const { mutateAsync: uploadAssest } = useUploadQuackchatAssest();

  const configurationRef = useRef(configuration);

  const messages = useMemo<Message[]>(() => {
    return [
      {
        action: "standard_message",
        author: "USER",
        text: "Why aren't my reviews displaying on the website?",
        deduplicationKey: "example_message_1",
        createdAt: "2021-09-01T12:00:00Z",
        data: {},
      },
      {
        action: "standard_message",
        author: "AUTO_AGENT",
        text: "This issue requires further assistance. I'm forwarding your request to a live agent, who can help resolve it. Please hold on while I transfer you.",
        deduplicationKey: "example_message_2",
        createdAt: "2021-09-01T12:10:00Z",
        data: {},
      },
      {
        action: "standard_message",
        author: "AUTO_AGENT",
        text: "You are now connected to John Doe. They will assist you further.",
        deduplicationKey: "example_message_3",
        createdAt: "2021-09-01T12:20:00Z",
        data: {},
      },
      {
        action: "standard_message",
        author: "LIVE_AGENT",
        text: "Hi, I'm John, I'm here to help you.",
        deduplicationKey: "example_message_4",
        createdAt: "2021-09-01T12:30:00Z",
        data: {
          agentDisplayName: "John Doe",
        },
      },
    ];
  }, []);

  const updateChatSettings = useCallback(
    (chatSettings: Partial<ChatSettings>) => {
      if (configurationRef.current == null) {
        return;
      }

      updateConfiguration({
        ...configurationRef.current,
        chatSettings: {
          ...configurationRef.current.chatSettings,
          ...chatSettings,
        },
      });
    },
    [updateConfiguration]
  );

  const removeFromChatSettings = useCallback(
    (key: keyof ChatSettings) => {
      if (configurationRef.current == null) {
        return;
      }

      const newConfiguration: QuackChatConfigResponse = {
        ...configurationRef.current,
      };

      delete newConfiguration.chatSettings[key];
      updateConfiguration(newConfiguration);
    },
    [updateConfiguration]
  );

  const updateTheme = useCallback(
    (theme: Partial<ChatSettings["theme"]>) => {
      if (configurationRef.current == null) {
        return;
      }

      updateChatSettings({
        theme: {
          ...defaultTheme,
          ...configurationRef.current.chatSettings.theme,
          ...theme,
        },
      });
    },
    [updateChatSettings]
  );

  const handleUploadAssest = useCallback(
    async (file: File) => {
      return uploadAssest(file);
    },
    [uploadAssest]
  );

  useEffect(() => {
    configurationRef.current = configuration;
  }, [configuration]);

  if (isLoading || configuration == null) {
    return <Spinner />;
  }

  const { chatSettings } = configuration;

  return (
    <Flex gap={"24px"}>
      <Flex flexGrow={"1"} direction={"column"} gap={"24px"}>
        <AutomationConfigurationContentHeader
          title={"Appearance"}
          status={updateStatus}
        />

        <Flex direction={"column"}>
          <CollapsibleFieldList title={"Theme"} defaultOpen>
            <FieldGroup horizontal description={"AI Avatar"}>
              <FileUploadComponent
                defaultFileName={chatSettings.avatarSVG?.split("/").pop()}
                onFileUpload={handleUploadAssest}
                onSuccess={(filePath) => {
                  if (filePath === "") {
                    removeFromChatSettings("avatarSVG");
                  } else {
                    updateChatSettings({
                      avatarSVG: filePath === "" ? undefined : filePath,
                    });
                  }
                }}
              />
            </FieldGroup>
          </CollapsibleFieldList>

          <CollapsibleFieldList title={"Widget"} defaultOpen>
            <FieldGroup horizontal description={"Widget Logo"}>
              <FileUploadComponent
                defaultFileName={chatSettings.customSVG?.split("/").pop()}
                onFileUpload={handleUploadAssest}
                onSuccess={(filePath) => {
                  if (filePath === "") {
                    removeFromChatSettings("customSVG");
                  } else {
                    updateChatSettings({
                      customSVG: filePath,
                    });
                  }
                }}
              />
            </FieldGroup>

            <FieldGroup horizontal description={"Widget Logo when opened"}>
              <FileUploadComponent
                defaultFileName={chatSettings.closeSVG?.split("/").pop()}
                onFileUpload={handleUploadAssest}
                onSuccess={(filePath) => {
                  if (filePath === "") {
                    removeFromChatSettings("closeSVG");
                  } else {
                    updateChatSettings({
                      closeSVG: filePath,
                    });
                  }
                }}
              />
            </FieldGroup>

            <FieldGroup horizontal description={"Widget position alignemnt"}>
              <RadioCardGroup
                value={chatSettings.position ?? "bottom-right"}
                onChange={(newValue) => {
                  if (
                    newValue === "bottom-left" ||
                    newValue === "bottom-right"
                  ) {
                    updateChatSettings({
                      position: newValue,
                    });
                  }
                }}
                options={[
                  {
                    value: "bottom-right",
                    label: "Right",
                    icon: <AlignEndVertical size={"16"} />,
                  },
                  {
                    value: "bottom-left",
                    label: "Left",
                    icon: <AlignStartVertical size={"16"} />,
                  },
                ]}
              />
            </FieldGroup>
          </CollapsibleFieldList>

          <CollapsibleFieldList title={"Customize Color Theme"} defaultOpen>
            <FieldGroup horizontal description={"Agent Text"}>
              <ColorPicker
                value={chatSettings.theme?.agentText}
                onChange={(newValue) => {
                  updateTheme({
                    agentText: newValue,
                  });
                }}
              />
            </FieldGroup>

            <FieldGroup horizontal description={"Agent Background"}>
              <ColorPicker
                value={chatSettings.theme?.agentBackground}
                onChange={(newValue) => {
                  updateTheme({
                    agentBackground: newValue,
                  });
                }}
              />
            </FieldGroup>

            <FieldGroup horizontal description={"User Text"}>
              <ColorPicker
                value={chatSettings.theme?.userText}
                onChange={(newValue) => {
                  updateTheme({
                    userText: newValue,
                  });
                }}
              />
            </FieldGroup>

            <FieldGroup horizontal description={"User Background"}>
              <ColorPicker
                value={chatSettings.theme?.userBackground}
                onChange={(newValue) => {
                  updateTheme({
                    userBackground: newValue,
                  });
                }}
              />
            </FieldGroup>

            <FieldGroup horizontal description={"Send Button Text"}>
              <ColorPicker
                value={chatSettings.theme?.textSendButton}
                onChange={(newValue) => {
                  updateTheme({
                    textSendButton: newValue,
                  });
                }}
              />
            </FieldGroup>

            <FieldGroup horizontal description={"Send Button Background"}>
              <ColorPicker
                value={chatSettings.theme?.bgSendButton}
                onChange={(newValue) => {
                  updateTheme({
                    bgSendButton: newValue,
                  });
                }}
              />
            </FieldGroup>
          </CollapsibleFieldList>
        </Flex>
      </Flex>

      <Flex flexShrink={"0"}>
        <QuackChat
          config={configuration}
          agents={["John Doe"]}
          messages={messages}
        />
      </Flex>
    </Flex>
  );
}
