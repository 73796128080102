import { PropsWithChildren } from "react";
import cx from "classnames";
import { Card, Flex, Spinner, Text, Button } from "@radix-ui/themes";
import { ExclamationTriangleIcon, ReloadIcon } from "@radix-ui/react-icons";
import styles from "../Reports.module.scss";

function Header({ children }: PropsWithChildren) {
  return (
    <Flex p={"5"} direction={"column"} gap={"2"}>
      {children}
    </Flex>
  );
}

function Title({ value }: { value: string }) {
  return (
    <Text size={"3"} color={"gray"} weight={"medium"} highContrast>
      {value}
    </Text>
  );
}

function Description({ value }: { value: string }) {
  return (
    <Text size={"2"} color={"gray"} weight={"regular"}>
      {value}
    </Text>
  );
}

function LoadingState() {
  return (
    <Flex gap={"2"} align={"center"}>
      <Spinner size={"2"} />
      <Text color={"gray"} size={"2"} weight={"regular"}>
        Loading...
      </Text>
    </Flex>
  );
}

function ErrorState({ onReload }: { onReload?: () => void }) {
  return (
    <Flex direction={"column"} gap={"4"} justify={"center"}>
      <Flex gap={"2"} align={"center"}>
        <ExclamationTriangleIcon color={"red"} />
        <Text color={"red"} size={"2"} weight={"regular"}>
          Oops something went wrong
        </Text>
      </Flex>

      {onReload && (
        <Flex justify={"center"}>
          <Button
            color="gray"
            variant="ghost"
            highContrast
            size="2"
            onClick={onReload}
          >
            <ReloadIcon />
            Try again
          </Button>
        </Flex>
      )}
    </Flex>
  );
}

function EmptyState() {
  return (
    <Flex gap={"2"} align={"center"}>
      <Text color={"gray"} size={"2"} weight={"regular"}>
        No Data Available Yet
      </Text>
    </Flex>
  );
}

function Content({
  children,
  isLoading = false,
  isError = false,
  isEmpty = false,
  noPadding = false,
  onReload,
  className,
}: PropsWithChildren<{
  isLoading?: boolean;
  isError?: boolean;
  isEmpty?: boolean;
  noPadding?: boolean;
  onReload?: () => void;
  className?: string;
}>) {
  return (
    <Flex
      p={noPadding ? "" : "5"}
      flexGrow={"1"}
      justify={"center"}
      className={className}
    >
      {isLoading && <LoadingState />}
      {!isLoading && isError && <ErrorState onReload={onReload} />}
      {!isLoading && !isError && isEmpty && <EmptyState />}
      {!isLoading && !isError && !isEmpty && children}
    </Flex>
  );
}

function WidgetCard({
  children,
  fullWidth = false,
  autoHeight = false,
}: PropsWithChildren<{
  fullWidth?: boolean;
  autoHeight?: boolean;
}>) {
  return (
    <Card
      variant={"ghost"}
      className={cx(styles.Card, {
        [styles.fullWidth]: fullWidth,
        [styles.autoHeight]: autoHeight,
      })}
    >
      {children}
    </Card>
  );
}

WidgetCard.Header = Header;
WidgetCard.Title = Title;
WidgetCard.Description = Description;
WidgetCard.Content = Content;

export default WidgetCard;
