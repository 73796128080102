import { useMatch, Link } from "react-router-dom";
import cx from "classnames";
import { Flex, Text } from "@radix-ui/themes";
import {
  ConfigurationView,
  automationConfigurationUris,
  configurationViewToLabel,
  configurationViewToUri,
} from "../AutomationConfiguration.config";
import { SimulatorChannelIcon } from "@/routes/simulators/SimulatorChannelIcon";
import {
  SimulatorChannel,
  simulatorChannelToLabel,
} from "@/api/simulator/simulator.model";
import styles from "../AutomationConfiguration.module.scss";

function SidebarLink({ view }: { view: ConfigurationView }) {
  const to = configurationViewToUri[view];
  const isActive = useMatch(`${automationConfigurationUris.root}/${view}`);

  return (
    <Link
      to={to}
      className={cx(
        styles.SidebarLink,
        "rt-reset rt-BaseButton rt-r-size-2 rt-variant-soft rt-high-contrast rt-Button",
        { [styles.isActive]: isActive }
      )}
      data-accent-color="gray"
    >
      {configurationViewToLabel[view]}
    </Link>
  );
}

interface AutomationConfigurationSidebarProps {
  channel: SimulatorChannel;
  views: ConfigurationView[];
}

export function AutomationConfigurationSidebar({
  channel,
  views,
}: AutomationConfigurationSidebarProps) {
  return (
    <Flex p={"24px"} direction={"column"} gap={"5"} className={styles.Sidebar}>
      <Flex align={"center"} gap={"2"}>
        <SimulatorChannelIcon channel={channel} size={"5"} />

        <Text size={"7"} color={"gray"} weight={"medium"} highContrast>
          {simulatorChannelToLabel[channel]}
        </Text>
      </Flex>

      <Flex direction={"column"} gap={"1"}>
        {views.map((view) => (
          <SidebarLink key={view} view={view} />
        ))}
      </Flex>
    </Flex>
  );
}
