import { useMemo, useState } from "react";
import { Flex, Text } from "@radix-ui/themes";
import { DateRange } from "@/components/shared/date-range-picker/DateRangePicker";
import { MetricsScope, metricsScopes } from "@/api/reports/reports.model";
import { useAvgQaScore } from "@/api/reports/reports.api";
import WidgetCard from "../../parts/WidgetCard";
import { MetricsScopeToggle } from "../../parts/MetricsScopeToggle";
import {
  TimeSeriesChart,
  DataPoint,
  LineConfig,
} from "../../parts/TimeSeriesChart";
import ExploreTable, { ColumnType } from "@/routes/explore/ExploreTable";
import { EmptyCell, getValue } from "@/routes/explore/CellGenerator";
import { QAScore } from "@/routes/explore/cells";
import styles from "./AvgQaScoreWidget.module.scss";
import Select from "@/components/shared/select";
import { useAuth0 } from "@auth0/auth0-react";
import { isYotpo } from "@/api/user";
import { AxisDomain } from "recharts/types/util/types";

const sortDirections = ["asc", "desc"] as const;
type SortDirection = (typeof sortDirections)[number];

function isSortDirection(obj: any): obj is SortDirection {
  return sortDirections.includes(obj);
}

type MetricRow = {
  index: number;
  key: string;
  name: string;
  avgQaScore: number;
};

function cellGenerator(
  values: MetricRow,
  { key }: ColumnType<keyof MetricRow>
) {
  if (key === "avgQaScore") {
    const value = getValue(values, key);
    return value == null || value === 0 ? (
      <EmptyCell />
    ) : (
      <QAScore value={value} />
    );
  }

  if (key === "name") {
    const value = getValue(values, key);
    return (
      <Text size={"2"} truncate as="div">
        {value}
      </Text>
    );
  }

  const value = getValue(values, key);
  return <>{value == null ? "-" : value}</>;
}

interface AvgQaScoreWidgetProps {
  dateRange: DateRange;
}

export function AvgQaScoreWidget({ dateRange }: AvgQaScoreWidgetProps) {
  const { user } = useAuth0();

  const [scope, setScope] = useState<MetricsScope>(metricsScopes[0]);
  const [sortDirection, setSortDirection] = useState<SortDirection>("desc");

  const { data, isLoading, isError, refetch } = useAvgQaScore({
    ...dateRange,
    scope,
  });

  const [chartData] = useMemo(() => {
    const lines: LineConfig[] = [];
    const scoresByDates: Record<string, Record<string, number>> = {};

    if (data != null) {
      Object.entries(data).forEach(([id, { name, scores }]) => {
        lines.push({ key: id, label: name ?? id });

        scores.forEach(({ date, count }) => {
          if (scoresByDates[date] == null) {
            scoresByDates[date] = {};
          }

          scoresByDates[date][id] = count;
        });
      });
    }

    const chartData = Object.entries(scoresByDates).map(([date, scores]) => {
      const x: DataPoint = {
        date: new Date(date).toISOString(),
        values: scores,
      };

      return x;
    });

    return [chartData, lines];
  }, [data]);

  const tableDate = useMemo<MetricRow[]>(() => {
    if (data == null) {
      return [];
    }

    return Object.entries(data).map(([key, { name, scores }], index) => {
      const avgQaScore =
        scores.reduce((acc, { count }) => acc + count, 0) / scores.length;

      return {
        index: index + 1,
        key,
        name,
        avgQaScore,
      };
    });
  }, [data]);

  const [visibleTableDate, lines] = useMemo(() => {
    const sortedTableDate = tableDate.sort((a, b) =>
      sortDirection === "desc"
        ? b.avgQaScore - a.avgQaScore
        : a.avgQaScore - b.avgQaScore
    );

    const visibleTableDate = sortedTableDate.slice(0, 7).map((row, index) => ({
      ...row,
      index: index + 1,
    }));

    const lines: LineConfig[] = [];

    visibleTableDate.forEach(({ key, name }) => {
      lines.push({ key, label: name });
    });

    return [visibleTableDate, lines];
  }, [tableDate, sortDirection]);

  const chartYDomain = useMemo<AxisDomain>(() => {
    if (isYotpo(user)) {
      return [0, 1];
    } else {
      return [1, 5];
    }
  }, [user]);

  return (
    <WidgetCard fullWidth>
      <Flex justify={"between"} align={"start"}>
        <WidgetCard.Header>
          <WidgetCard.Title value={"Avg. QA Scores Ranking"} />
          <WidgetCard.Description
            value={"Shows the highest or lowest QA scores"}
          />
        </WidgetCard.Header>

        <Flex p={"5"} gap={"4"} align={"center"}>
          <Flex gap={"1"} align={"center"}>
            <Text size={"2"}>Sort by:</Text>
            <Select
              size={"small"}
              value={sortDirection}
              onChange={(newValue) => {
                isSortDirection(newValue) && setSortDirection(newValue);
              }}
              options={sortDirections.map((value) => ({
                label: value === "asc" ? "Lowest scores" : "Highest scores",
                value,
              }))}
            />
          </Flex>

          <MetricsScopeToggle value={scope} onChange={setScope} />
        </Flex>
      </Flex>

      <WidgetCard.Content
        isLoading={isLoading}
        isError={isError}
        isEmpty={chartData.length === 0}
        onReload={refetch}
      >
        <Flex width={"100%"} gap={"4"}>
          <Flex flexGrow={"1"}>
            <TimeSeriesChart
              data={chartData}
              lines={lines}
              yDomain={chartYDomain}
            />
          </Flex>

          <Flex flexBasis={"360px"} className={styles.AvgQaScoreWidgetTable}>
            <ExploreTable<MetricRow>
              columns={[
                { key: "index", label: "#", width: "40px" },
                { key: "name", label: "Name" },
                { key: "avgQaScore", label: "Avg. QA Score", width: "120px" },
              ]}
              rows={visibleTableDate}
              cellGenerator={cellGenerator}
            />
          </Flex>
        </Flex>
      </WidgetCard.Content>
    </WidgetCard>
  );
}
