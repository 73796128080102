import { useMemo, useState } from "react";
import { Flex, Text } from "@radix-ui/themes";
import {
  DateRangePicker,
  DateRange,
  buildRange,
} from "@/components/shared/date-range-picker/DateRangePicker";
import { AvgQaScoreWidget } from "./widgets/agent-performance/AvgQaScoreWidget";
import { QaEvaluationMetricsWidget } from "./widgets/agent-performance/QaEvaluationMetricsWidget";
import { QaKeyMetricsWidget } from "./widgets/agent-performance/QaKeyMetricsWidget";

interface HeaderProps {
  dateRange: DateRange;
  onDateRangeChange: (newDateRange: DateRange) => void;
}

function Header({ dateRange, onDateRangeChange }: HeaderProps) {
  return (
    <Flex justify={"between"}>
      <Flex p={"5"} direction={"column"} gap={"1"}>
        <Text color={"gray"} size={"3"} weight={"medium"} highContrast>
          👨‍💻 Agent Performance
        </Text>

        <Text color={"gray"} size={"2"}>
          Showing how your agents perform
        </Text>
      </Flex>

      <Flex px={"6"} py={"4"} gap={"2"} align={"center"}>
        <DateRangePicker value={dateRange} onChange={onDateRangeChange} />
      </Flex>
    </Flex>
  );
}

export function AgentPerformanceReport() {
  const [dateRange, setDateRange] = useState<DateRange>(buildRange["14d"]());

  return (
    <Flex pt={"9"} pb={"5"} direction={"column"} gap={"4"}>
      <Header dateRange={dateRange} onDateRangeChange={setDateRange} />

      <Flex gap={"4"} wrap={"wrap"}>
        <AvgQaScoreWidget dateRange={dateRange} />

        <QaEvaluationMetricsWidget dateRange={dateRange} />

        <QaKeyMetricsWidget dateRange={dateRange} />
      </Flex>
    </Flex>
  );
}
