import { useCallback, useMemo } from "react";
import { DateRange } from "@/components/shared/date-range-picker/DateRangePicker";
import { ChartConfig } from "@/components/ui/chart";
import {
  AvgTimeMetricValue,
  ChannelInteraction,
  QuackHiringMetricType,
  months,
  padMonths,
  sortAvgTimeMetric,
} from "@/api/reports/reports.model";
import { useAvgTimeMetric, useFirstRelease } from "@/api/reports/reports.api";
import { LineChartItem, LineChartComp } from "../../parts/LineChart";
import WidgetCard from "../../parts/WidgetCard";
import dayjs from "dayjs";

const NUMBER_OF_MONTHS = 6;

export type AvgTimeMetricKeys = Exclude<keyof AvgTimeMetricValue, "date">;

function ReferenceLineLegend() {
  return (
    <div className="h-4 w-4 shrink-0 flex items-center">
      <svg
        width="16"
        height="1"
        viewBox="0 0 16 1"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="15.75"
          y1="0.5"
          x2="0.75"
          y2="0.5"
          stroke={"var(--color-referenceLine)"}
          stroke-dasharray="2 2"
        />
      </svg>
    </div>
  );
}

export const chartConfig: ChartConfig = {
  quackInteractionsAmount: {
    label: "Quack-Responded Interactions",
    color: "hsl(var(--cx-chart-1))",
  },
  allInteractionsAmount: {
    label: "All Support Interactions",
    color: "hsl(var(--cx-chart-2-alpha))",
  },
  referenceLine: {
    label: "Quack First Release",
    color: "hsl(var(--cx-chart-1))",
    icon: ReferenceLineLegend,
  },
};

interface AvgTimeMetricWidgetProps {
  dateRange: DateRange;
  channels: ChannelInteraction[];
  metricType: QuackHiringMetricType;
  title: string;
  description: string;
}

function AvgTimeMetricWidget({
  dateRange,
  channels,
  metricType,
  title,
  description,
}: AvgTimeMetricWidgetProps) {
  const firstReleaseQuery = useFirstRelease({
    ...dateRange,
    channels,
  });

  const avgTimeMetricQuery = useAvgTimeMetric({
    ...dateRange,
    channels,
    metricType,
  });

  const chartData = useMemo<LineChartItem<AvgTimeMetricKeys>[]>(() => {
    if (
      avgTimeMetricQuery.data == null ||
      avgTimeMetricQuery.data.length === 0
    ) {
      return [];
    }

    const data = sortAvgTimeMetric(
      avgTimeMetricQuery.data,
      months[new Date(dateRange.dateFrom).getMonth()]
    );

    if (data.length < NUMBER_OF_MONTHS) {
      const firstMonth = data[0].date;
      const prevMonths: AvgTimeMetricValue[] = padMonths(
        firstMonth,
        NUMBER_OF_MONTHS - data.length
      ).map((month) => ({
        date: month,
        quackInteractionsAmount: undefined,
        allInteractionsAmount: undefined,
      }));

      return [...prevMonths, ...data].map((item) => ({
        ...item,
        month: item.date,
      }));
    }

    return data.map((item) => ({
      ...item,
      month: item.date,
    }));
  }, [dateRange.dateFrom, avgTimeMetricQuery.data]);

  const referenceLine = useMemo(() => {
    if (
      firstReleaseQuery.data == null ||
      firstReleaseQuery.data.firstRelease == null
    ) {
      return;
    }

    const month = dayjs(firstReleaseQuery.data.firstRelease).format("MMMM");
    return { month, label: "Quack First Release" };
  }, [firstReleaseQuery.data]);

  const handleReload = useCallback(() => {
    void avgTimeMetricQuery.refetch();
    void firstReleaseQuery.refetch();
  }, []);

  const isLoading = avgTimeMetricQuery.isLoading || firstReleaseQuery.isLoading;
  const isError = avgTimeMetricQuery.isError || firstReleaseQuery.isError;

  return (
    <WidgetCard>
      <WidgetCard.Header>
        <WidgetCard.Title value={title} />
        <WidgetCard.Description value={description} />
      </WidgetCard.Header>

      <WidgetCard.Content
        isLoading={isLoading}
        isError={isError}
        isEmpty={chartData.length === 0}
        onReload={handleReload}
      >
        <LineChartComp<AvgTimeMetricKeys>
          data={chartData}
          config={chartConfig}
          referenceLine={referenceLine}
        />
      </WidgetCard.Content>
    </WidgetCard>
  );
}

export function AvgTTRWidget(
  props: Omit<AvgTimeMetricWidgetProps, "title" | "description" | "metricType">
) {
  return (
    <AvgTimeMetricWidget
      {...props}
      metricType={"TTR"}
      title={"Avg. TTR"}
      description={"Time to resolution trend"}
    />
  );
}

export function AvgFRTWidget(
  props: Omit<AvgTimeMetricWidgetProps, "title" | "description" | "metricType">
) {
  return (
    <AvgTimeMetricWidget
      {...props}
      metricType={"FRT"}
      title={"Avg. FRT"}
      description={"First response time trend"}
    />
  );
}
