import React, { ChangeEvent, useContext, useRef, useEffect } from "react";
import styled from "styled-components";

import { ArrowUp } from "@geist-ui/icons";

import { EMessagesContainerType, TypeContext } from "./typeContext";
import { useKeyboard } from "@geist-ui/core";
import { Loader } from "../loader";

enum EKeyboardNumbers {
  ESCAPE = "escape",
  ENTER = "enter",
}

const keyboardNumbers = {
  [EKeyboardNumbers.ESCAPE]: 27,
  [EKeyboardNumbers.ENTER]: 13,
};

const PREFIX_HEIGHT = 24;
const MAX_HEIGHT = 150;

export const Input = ({
  value,
  handleInputChange,
  handleSubmit,
  messagesLength,
  disabled = false,
  placeholder = "",
  escalation = false,
  isTyping = false,
  loading = false,
}: {
  value: string;
  handleInputChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  messagesLength: number;
  disabled?: boolean;
  placeholder?: string;
  escalation?: boolean;
  isTyping?: boolean;
  loading?: boolean;
}) => {
  const { type } = useContext(TypeContext);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useKeyboard(
    (e) => {
      if (textareaRef.current) {
        smallInput();
      }
    },
    [keyboardNumbers[EKeyboardNumbers.ESCAPE]]
  );

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    smallInput();
    handleSubmit(e);
  };

  const smallInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${PREFIX_HEIGHT}px`;
      textareaRef.current.blur();
    }
  };

  const adjustHeight = (value: string) => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      const scrollHeight = textareaRef.current.scrollHeight - PREFIX_HEIGHT - 8;
      textareaRef.current.style.height = `${Math.min(
        value ? Math.max(scrollHeight, PREFIX_HEIGHT) : PREFIX_HEIGHT,
        MAX_HEIGHT
      )}px`;
    }
  };

  useEffect(() => {
    adjustHeight(value);
  }, [value]);

  useEffect(() => {
    if (!isTyping) {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }
  }, [isTyping, textareaRef.current]);

  return (
    <SimulatorInputWrapper onSubmit={onSubmit}>
      <textarea
        ref={textareaRef}
        value={value}
        autoFocus
        onChange={(e) => {
          handleInputChange(e);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (!e.shiftKey) {
              buttonRef.current?.click();
            } else {
            }
          }
        }}
        rows={1}
        placeholder={placeholder ?? "Ask anything about this ticket"}
        onBlur={() => {
          if (textareaRef.current && !value) {
            smallInput();
          }
        }}
      />
      <button
        className="submit"
        type="submit"
        ref={buttonRef}
        disabled={disabled}
      >
        {loading ? (
          <>
            <Loader loading={false} smaller />
          </>
        ) : (
          <ArrowUp />
        )}
      </button>
    </SimulatorInputWrapper>
  );
};

const SimulatorInputWrapper = styled.form`
  position: relative;
  display: flex;
  width: 100%;
  padding-right: 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.light.borderDefault};
  background: ${(props) => props.theme.light.bgCard};
  max-height: ${MAX_HEIGHT + 32}px; // Adjust max-height to account for padding
  box-sizing: border-box;

  textarea {
    position: relative;
    all: unset;
    padding: 12px;
    padding-right: 58px;
    min-height: ${PREFIX_HEIGHT}px;
    flex-grow: 1;
    overflow-y: auto;
    border: 0;
    width: 100%;
    resize: none;
    background: transparent;
    transition: height 0.2s ease;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: ${(props) => props.theme.light.textPrimary};
    &::placeholder {
      color: ${(props) => props.theme.light.textPlaceholder};
    }
  }

  button.submit {
    all: unset;
    position: absolute;
    right: 12px;
    bottom: 10px;
    cursor: pointer;
    display: flex;
    width: 26px;
    height: 26px;
    justify-content: center;
    align-items: center;
    border-radius: 42.667px;
    border: 0.667px solid ${(props) => props.theme.light.bgInverted};
    background: ${(props) => props.theme.light.bgInverted};

    svg {
      color: ${(props) => props.theme.light.background} !important;
      width: 14px;
      height: 14px;
    }
  }
`;
