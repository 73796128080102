import { useAuthorizedQuery } from "..";
import { params } from "../../params";

export const useGetTenantGroups = (enabled: boolean) => {
  return useAuthorizedQuery({
    url: `${params.API_URL}/platform/groups`,
    queryKey: `get-tenant-groups`,
    method: "GET",
    enabled,
  });
};
