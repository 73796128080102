const config = {
  eu: [
    "lsports",
    "justt",
    "yotpo",
    "yotpo-loyalty",
    "yotpo-ugc",
    "autods",
    "eu",
    "faye",
    "payem",
    "elementor",
    "justt",
  ],
  us: ["datarails", "driivz", "duve", "atera", "oculus", "moovit", "route"],
};

const isDevelopment = window.location.hostname.includes("localhost");

const isStg =
  (window.location.hostname.includes("staging") ||
    window.location.hostname.includes("localhost")) &&
  !config.us.some((domain) => window.location.hostname.includes(domain));

const isEU =
  window.location.hostname.includes(`app-eu.`) ||
  config.eu.some((domain) => window.location.hostname.includes(domain));

enum ENV {
  PRODUCTION_US,
  PRODUCTION_EU,
  STAGING,
}

export const params = isStg
  ? {
      ENV: "STG",
      SDK_URL: "https://sdk-quack.s3.amazonaws.com/s",
      PARAGON_PROJECT_ID: "56315a1f-fa16-496d-8f22-df442e9592e1",
      AUTH0_DOMAIN: "dev-jqkpam3mofa5uwym.us.auth0.com",
      CLIENT_ID: "6KMh4Oaj1JggwAvwSKS3sLhGg0IZCcq2",
      API_URL: "https://stage-api.thequack.ai",
      GRAPHQL_URL: "https://stage-api.thequack.ai",
      TRACK_TOKEN: "e2cdf9bd5d148905b74f4e1f8ea28091",
      FEATURE_FLAG_ID: "NBTcCM86OkWHkbT5R59NKw/4bvaghLHx0OnvNVUN-DoBA",
      AI_URL: "https://ai-stg.quack.cx", // currnetly we have just production
      // AI_URL: "http://localhost:3002",
      AI_TOKEN: "5f4dcc3b5aa765d61d8327deb882cf99",
      SHARED_REPORT_URL:
        "https://quack-stg-shared-sample-report.s3.us-east-1.amazonaws.com",
      S3: "https://sdk-quack.s3.us-east-1.amazonaws.com/config/s/tenants",
      INTERNAL_TOKEN: "KnASFJdASLKFJGM24k1cccAsdJ0BnhDtps",
      SENTRY_ENABLED: !isDevelopment,
      SENTRY_ENVIRONMENT: "staging",
      GOOGLE_CLOUD_TRANSLATION: {
        API_KEY: "AIzaSyCBdKYTnd8fjQ3yv4qb9GJ-2m4Sy0YyywQ",
        ENDPOINT: "https://translation.googleapis.com/language/translate/v2",
      },
      SDK_CONFIG:
        "https://quack-stg-sdk-configurations.s3.us-east-1.amazonaws.com/",
    }
  : isEU
  ? {
      ENV: "EU",
      SDK_URL: "https://sdk-quack.s3.amazonaws.com/eu",
      PARAGON_PROJECT_ID: "---",
      AUTH0_DOMAIN: "quackai.eu.auth0.com",
      CLIENT_ID: "RRzTiMeLTtTFplgBsucD7eVAHQeMgQN6", // auth0
      API_URL: "https://eu.api.thequack.ai",
      GRAPHQL_URL: "https://eu.api.thequack.ai",
      TRACK_TOKEN: "bc2657e23891e11a179adee09eb9e7e1",
      FEATURE_FLAG_ID: "NBTcCM86OkWHkbT5R59NKw/zffXPKX7FkWTZhOtjSYUxA",
      AI_URL: "https://ai-eu.quack.cx",
      // AI_URL: "http://localhost:3002",
      AI_TOKEN: "5f4dcc3b5aa765d61d8327deb882cf99",
      SHARED_REPORT_URL:
        "https://quack-eu-shared-sample-report.s3.eu-central-1.amazonaws.com",
      S3: "https://sdk-quack.s3.us-east-1.amazonaws.com/config/p/tenants",
      INTERNAL_TOKEN: "KnASFJdASLKFJGM24k1cccAsdJ0BnhDtps",
      SENTRY_ENABLED: !isDevelopment,
      SENTRY_ENVIRONMENT: "prod",
      GOOGLE_CLOUD_TRANSLATION: {
        API_KEY: "AIzaSyCBdKYTnd8fjQ3yv4qb9GJ-2m4Sy0YyywQ",
        ENDPOINT: "https://translation.googleapis.com/language/translate/v2",
      },
      SDK_CONFIG:
        "https://quack-eu-sdk-configurations.s3.eu-central-1.amazonaws.com/",
    }
  : {
      ENV: "US",
      SDK_URL: "https://sdk-quack.s3.amazonaws.com/p",
      PARAGON_PROJECT_ID: "47c156e9-7918-4760-8ba5-1cbdbb59af6d",
      AUTH0_DOMAIN: "quackai.us.auth0.com",
      CLIENT_ID: "QG8XLnC9IgGszZGNg22XqPZRah8W00EE",
      API_URL: "https://api.thequack.ai",
      GRAPHQL_URL: "https://api.thequack.ai",
      TRACK_TOKEN: "bc2657e23891e11a179adee09eb9e7e1",
      FEATURE_FLAG_ID: "NBTcCM86OkWHkbT5R59NKw/zffXPKX7FkWTZhOtjSYUxA",
      AI_URL: "https://ai.quack.cx",
      // AI_URL: "http://localhost:3002",
      AI_TOKEN: "5f4dcc3b5aa765d61d8327deb882cf99",
      SHARED_REPORT_URL:
        "https://quack-production-shared-sample-report.s3.us-east-1.amazonaws.com",
      S3: "https://sdk-quack.s3.us-east-1.amazonaws.com/config/p/tenants",
      INTERNAL_TOKEN: "KnASFJdASLKFJGM24k1cccAsdJ0BnhDtps",
      SENTRY_ENABLED: !isDevelopment,
      SENTRY_ENVIRONMENT: "prod",
      GOOGLE_CLOUD_TRANSLATION: {
        API_KEY: "AIzaSyCBdKYTnd8fjQ3yv4qb9GJ-2m4Sy0YyywQ",
        ENDPOINT: "https://translation.googleapis.com/language/translate/v2",
      },
      SDK_CONFIG:
        "https://quack-production-sdk-configurations.s3.us-east-1.amazonaws.com/",
    };
