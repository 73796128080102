import { useMemo, useState } from "react";
import dayjs from "dayjs";
import cx from "classnames";
import { TicketComments } from "./TicketQAScore.model";
import styles from "./TicketTranscript.module.scss";
import { X } from "lucide-react";

const extractUrls = (text: string) => {
  const urlPattern = /https?:\/\/[^\s]+/;
  const match = text.match(urlPattern);
  if (match) {
    return match[0].replace(/[^\w\/]+$/, ""); // Remove trailing non-word characters
  }
  return null;
};

const isHtmlString = (text: string): boolean => {
  const htmlPattern = /<\/?[a-z][\s\S]*>/i;
  return htmlPattern.test(text);
};

const ImageViewer = ({ url }: { url: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleImageClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <img
        src={url}
        alt="Thumbnail"
        className="cursor-pointer"
        onClick={handleImageClick}
      />
      {isOpen && (
        <div className={styles.ImageViewerContainer}>
          <div className="relative">
            <button
              id="close"
              className={styles.ImageViewerBtn}
              onClick={handleClose}
            >
              Close <X />
            </button>
            <img src={url} alt="Full Size" className="max-w-full max-h-full" />
          </div>
        </div>
      )}
    </div>
  );
};

function TicketTranscript({
  comments,
}: {
  comments: Pick<
    TicketComments,
    "body" | "updatedAt" | "agent" | "isInternal"
  >[];
}) {
  const sortedComments = useMemo(() => {
    return comments.sort(
      (a, b) =>
        new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
    );
  }, [comments]);

  return (
    <div className={styles.Transcript}>
      {sortedComments.map((comment) => (
        <div className={styles.Comment} key={comment.updatedAt}>
          <div className={styles.Title}>
            {dayjs(comment.updatedAt).format("MM/DD - HH:mm:ss")}
            {comment.isInternal ? ` ( Internal note )` : ""}
          </div>

          <div
            className={cx(styles.Content, {
              [styles.isAgent]: comment.agent,
              [styles.isInternal]: comment.isInternal,
            })}
          >
            <div className={styles.Body}>
              {isHtmlString(comment.body) ? (
                <div dangerouslySetInnerHTML={{ __html: comment.body }}></div>
              ) : (
                <div>
                  {comment.body
                    ?.trim()
                    .split(/(https?:\/\/[^\s]+)/g)
                    .map((part, index) => {
                      const cleanedUrls = extractUrls(part) ?? "";
                      return part.match(/https?:\/\/[^\s]+/) ? (
                        <>
                          {cleanedUrls.match(/\.(jpeg|jpg|png|gif)$/i) ? (
                            // <img key={index} src={cleanedUrls} alt="Image" />
                            <ImageViewer url={cleanedUrls} />
                          ) : (
                            <a
                              key={index}
                              href={part}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {part}
                            </a>
                          )}
                        </>
                      ) : (
                        <span
                          key={index}
                          dangerouslySetInnerHTML={{ __html: part }}
                        />
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TicketTranscript;
