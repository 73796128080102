import React from "react";

export const HelpCenter = () => {
  return (
    <div>
      <iframe
        style={{ width: "100%", height: "94.5vh" }}
        src="https://lean-knowledge-hub.lovable.app/?tenant=0e73c4bd-b41c-41c0-9a20-c20859b2576e"
      />
    </div>
  );
};
