import { useCallback, useMemo, useState } from "react";
import { Flex, Text, Button, Spinner, Grid } from "@radix-ui/themes";
import RadioGroup from "../../../../components/shared/inputs/radio-group";
import Checkbox from "../../../../components/shared/inputs/checkbox";
import Switch from "../../../../components/shared/inputs/switch";
import {
  GroupsPolicy,
  IntegrationName,
  TenantGroup,
} from "../../../../api/integration/Integration.model";
import { StepHeader, StepMain } from "../parts";
import { integrationNameToGalleryItem } from "../../../../api/integration/integrationNameToGalleryItem";
import styles from "../AddIntegration.module.scss";
import { IntegrationEvents, mixTrackEvent } from "@/assets/mixpanel";

type ServerErrors = {
  autoSync?: string;
  include?: string;
  general?: string;
};

interface CreateFormProps {
  groups: TenantGroup[];
  onSubmit: (value: GroupsPolicy) => Promise<void>;
  onBack: () => void;
}

type SyncMethod = "all" | "custom";

function ZendeskCreateForm({ groups, onSubmit, onBack }: CreateFormProps) {
  const [syncMethod, setSyncMethod] = useState<SyncMethod>("all");
  const [payload, setPayload] = useState<Partial<GroupsPolicy>>({
    include: groups.map(({ id }) => id),
  });
  const [serverErrors, setServerErrors] = useState<ServerErrors>();
  const [loading, setLoading] = useState(false);

  const includeSet = useMemo(() => {
    return new Set(payload.include ?? []);
  }, [payload.include]);

  const handleSyncMethodChanged = useCallback(
    (newValue: SyncMethod) => {
      setPayload((prevState) => ({
        ...prevState,
        include: groups.map(({ id }) => id),
      }));
      setSyncMethod(newValue);

      mixTrackEvent({
        event: IntegrationEvents.TICKET_SYSTEM_SYNC_METHOD,
        properties: {
          name: "zendesk",
        },
      });
    },
    [groups]
  );

  const handleGroupSelectionChanged = useCallback(
    (groupId: number, include: boolean) => {
      if (include) {
        setPayload((prevState) => {
          const include = [...(prevState.include ?? []), groupId];

          return {
            ...prevState,
            include,
          };
        });
      } else {
        setPayload((prevState) => {
          const include = (prevState.include ?? []).filter(
            (id) => id !== groupId
          );

          return {
            ...prevState,
            include,
          };
        });
      }
    },
    []
  );

  const handleSubmit = useCallback(async () => {
    try {
      setServerErrors(undefined);
      setLoading(true);

      await onSubmit(payload as GroupsPolicy);
    } catch (error) {
      setServerErrors({
        general: "An unknown error occurred. Please contact support.",
      });
    } finally {
      setLoading(false);
    }
  }, [onSubmit, payload]);

  return (
    <div className={styles.Form}>
      <div className={styles.FieldList}>
        <div className={styles.FieldControl}>
          <Text size={"1"} weight={"medium"} as={"label"}>
            Sync method
          </Text>

          <RadioGroup
            value={syncMethod}
            onChange={(newValue) =>
              handleSyncMethodChanged(newValue as SyncMethod)
            }
            options={[
              { value: "all", label: "Sync all groups" },
              { value: "custom", label: "Custom" },
            ]}
          />

          {syncMethod === "custom" && (
            <Grid
              pt={"10px"}
              pl={"15px"}
              columns="2"
              gap="2"
              display="inline-grid"
              width={"100%"}
              height={"fit-content"}
            >
              <Text size={"2"} weight={"medium"}>{`${groups.length} Group${
                groups.length > 1 ? "s" : ""
              }`}</Text>

              <Flex justify={"end"} align={"center"}>
                {payload.include?.length === 0 ? (
                  <Button
                    color="gray"
                    variant="ghost"
                    highContrast
                    size="1"
                    onClick={() =>
                      setPayload((prevState) => ({
                        ...prevState,
                        include: groups.map(({ id }) => id),
                      }))
                    }
                  >
                    Select all
                  </Button>
                ) : (
                  <Button
                    color="gray"
                    variant="ghost"
                    highContrast
                    size="1"
                    onClick={() =>
                      setPayload((prevState) => ({ ...prevState, include: [] }))
                    }
                  >
                    Unselect all
                  </Button>
                )}
              </Flex>

              {groups.map((group) => (
                <Checkbox
                  label={group.name}
                  value={includeSet.has(group.id)}
                  onChange={(checked) =>
                    handleGroupSelectionChanged(group.id, checked)
                  }
                />
              ))}
            </Grid>
          )}
        </div>

        <div className={styles.FieldControl}>
          <Flex direction={"column"}>
            <Flex justify={"between"} align={"center"}>
              <Text size={"1"} weight={"medium"} as={"label"}>
                Auto-sync new groups
              </Text>

              <Switch
                value={payload.autoSync ?? false}
                onChange={(checked) => {
                  setPayload((prevState) => ({
                    ...prevState,
                    autoSync: checked,
                  }));
                  mixTrackEvent({
                    event: IntegrationEvents.TICKET_SYSTEM_AUTO_SYNC,
                    properties: {
                      name: "zendesk",
                    },
                  });
                }}
              />
            </Flex>

            <Text size={"1"} color="gray" as={"p"}>
              Automatically synchronize newly created groups with the system to
              ensure they are instantly available for use without manual
              updates.
            </Text>
          </Flex>
        </div>

        {serverErrors?.general != null && (
          <Text size={"1"} weight={"medium"} color={"red"}>
            {serverErrors.general}
          </Text>
        )}
      </div>

      <div className={styles.Footer}>
        <Button
          color="gray"
          variant="solid"
          highContrast
          onClick={handleSubmit}
        >
          <Spinner loading={loading} />
          Continue
        </Button>

        <Button
          color="gray"
          variant="soft"
          highContrast
          type="button"
          onClick={onBack}
        >
          Back
        </Button>
      </div>
    </div>
  );
}

function FrontCreateForm({ groups, onSubmit, onBack }: CreateFormProps) {
  const [syncMethod, setSyncMethod] = useState<SyncMethod>("all");
  const [payload, setPayload] = useState<Partial<GroupsPolicy>>({
    include: groups.map(({ id }) => id),
  });
  const [serverErrors, setServerErrors] = useState<ServerErrors>();
  const [loading, setLoading] = useState(false);

  const includeSet = useMemo(() => {
    return new Set(payload.include ?? []);
  }, [payload.include]);

  const handleSyncMethodChanged = useCallback(
    (newValue: SyncMethod) => {
      setPayload((prevState) => ({
        ...prevState,
        include: groups.map(({ id }) => id),
      }));
      setSyncMethod(newValue);

      mixTrackEvent({
        event: IntegrationEvents.TICKET_SYSTEM_SYNC_METHOD,
        properties: {
          name: "front",
        },
      });
    },
    [groups]
  );

  const handleGroupSelectionChanged = useCallback(
    (groupId: number, include: boolean) => {
      if (include) {
        setPayload((prevState) => {
          const include = [...(prevState.include ?? []), groupId];

          return {
            ...prevState,
            include,
          };
        });
      } else {
        setPayload((prevState) => {
          const include = (prevState.include ?? []).filter(
            (id) => id !== groupId
          );

          return {
            ...prevState,
            include,
          };
        });
      }
    },
    []
  );

  const handleSubmit = useCallback(async () => {
    try {
      setServerErrors(undefined);
      setLoading(true);

      await onSubmit(payload as GroupsPolicy);
    } catch (error) {
      setServerErrors({
        general: "An unknown error occurred. Please contact support.",
      });
    } finally {
      setLoading(false);
    }
  }, [onSubmit, payload]);

  return (
    <div className={styles.Form}>
      <div className={styles.FieldList}>
        <div className={styles.FieldControl}>
          <Text size={"1"} weight={"medium"} as={"label"}>
            Sync method
          </Text>

          <RadioGroup
            value={syncMethod}
            onChange={(newValue) =>
              handleSyncMethodChanged(newValue as SyncMethod)
            }
            options={[
              { value: "all", label: "Sync all groups" },
              { value: "custom", label: "Custom" },
            ]}
          />

          {syncMethod === "custom" && (
            <Grid
              pt={"10px"}
              pl={"15px"}
              columns="2"
              gap="2"
              display="inline-grid"
              width={"100%"}
              height={"fit-content"}
            >
              <Text size={"2"} weight={"medium"}>{`${groups.length} Group${
                groups.length > 1 ? "s" : ""
              }`}</Text>

              <Flex justify={"end"} align={"center"}>
                {payload.include?.length === 0 ? (
                  <Button
                    color="gray"
                    variant="ghost"
                    highContrast
                    size="1"
                    onClick={() =>
                      setPayload((prevState) => ({
                        ...prevState,
                        include: groups.map(({ id }) => id),
                      }))
                    }
                  >
                    Select all
                  </Button>
                ) : (
                  <Button
                    color="gray"
                    variant="ghost"
                    highContrast
                    size="1"
                    onClick={() =>
                      setPayload((prevState) => ({ ...prevState, include: [] }))
                    }
                  >
                    Unselect all
                  </Button>
                )}
              </Flex>

              {groups.map((group) => (
                <Checkbox
                  label={group.name}
                  value={includeSet.has(group.id)}
                  onChange={(checked) =>
                    handleGroupSelectionChanged(group.id, checked)
                  }
                />
              ))}
            </Grid>
          )}
        </div>

        <div className={styles.FieldControl}>
          <Flex direction={"column"}>
            <Flex justify={"between"} align={"center"}>
              <Text size={"1"} weight={"medium"} as={"label"}>
                Auto-sync new groups
              </Text>

              <Switch
                value={payload.autoSync ?? false}
                onChange={(checked) => {
                  setPayload((prevState) => ({
                    ...prevState,
                    autoSync: checked,
                  }));
                  mixTrackEvent({
                    event: IntegrationEvents.TICKET_SYSTEM_AUTO_SYNC,
                    properties: {
                      name: "front",
                    },
                  });
                }}
              />
            </Flex>

            <Text size={"1"} color="gray" as={"p"}>
              Automatically synchronize newly created groups with the system to
              ensure they are instantly available for use without manual
              updates.
            </Text>
          </Flex>
        </div>

        {serverErrors?.general != null && (
          <Text size={"1"} weight={"medium"} color={"red"}>
            {serverErrors.general}
          </Text>
        )}
      </div>

      <div className={styles.Footer}>
        <Button
          color="gray"
          variant="solid"
          highContrast
          onClick={handleSubmit}
        >
          <Spinner loading={loading} />
          Continue
        </Button>

        <Button
          color="gray"
          variant="soft"
          highContrast
          type="button"
          onClick={onBack}
        >
          Back
        </Button>
      </div>
    </div>
  );
}

function IntercomCreateForm({ groups, onSubmit, onBack }: CreateFormProps) {
  const [syncMethod, setSyncMethod] = useState<SyncMethod>("all");
  const [payload, setPayload] = useState<Partial<GroupsPolicy>>({
    include: groups.map(({ id }) => id),
  });
  const [serverErrors, setServerErrors] = useState<ServerErrors>();
  const [loading, setLoading] = useState(false);

  const includeSet = useMemo(() => {
    return new Set(payload.include ?? []);
  }, [payload.include]);

  const handleSyncMethodChanged = useCallback(
    (newValue: SyncMethod) => {
      setPayload((prevState) => ({
        ...prevState,
        include: groups.map(({ id }) => id),
      }));
      setSyncMethod(newValue);

      mixTrackEvent({
        event: IntegrationEvents.TICKET_SYSTEM_SYNC_METHOD,
        properties: {
          name: "intercom",
        },
      });
    },
    [groups]
  );

  const handleGroupSelectionChanged = useCallback(
    (groupId: number, include: boolean) => {
      if (include) {
        setPayload((prevState) => {
          const include = [...(prevState.include ?? []), groupId];

          return {
            ...prevState,
            include,
          };
        });
      } else {
        setPayload((prevState) => {
          const include = (prevState.include ?? []).filter(
            (id) => id !== groupId
          );

          return {
            ...prevState,
            include,
          };
        });
      }
    },
    []
  );

  const handleSubmit = useCallback(async () => {
    try {
      setServerErrors(undefined);
      setLoading(true);

      await onSubmit(payload as GroupsPolicy);
    } catch (error) {
      setServerErrors({
        general: "An unknown error occurred. Please contact support.",
      });
    } finally {
      setLoading(false);
    }
  }, [onSubmit, payload]);
  return (
    <div className={styles.Form}>
      <div className={styles.FieldList}>
        <div className={styles.FieldControl}>
          <Text size={"1"} weight={"medium"} as={"label"}>
            Sync method
          </Text>

          <RadioGroup
            value={syncMethod}
            onChange={(newValue) =>
              handleSyncMethodChanged(newValue as SyncMethod)
            }
            options={[
              { value: "all", label: "Sync all groups" },
              { value: "custom", label: "Custom" },
            ]}
          />

          {syncMethod === "custom" && (
            <Grid
              pt={"10px"}
              pl={"15px"}
              columns="2"
              gap="2"
              display="inline-grid"
              width={"100%"}
              height={"fit-content"}
            >
              <Text size={"2"} weight={"medium"}>{`${groups.length} Group${
                groups.length > 1 ? "s" : ""
              }`}</Text>

              <Flex justify={"end"} align={"center"}>
                {payload.include?.length === 0 ? (
                  <Button
                    color="gray"
                    variant="ghost"
                    highContrast
                    size="1"
                    onClick={() =>
                      setPayload((prevState) => ({
                        ...prevState,
                        include: groups.map(({ id }) => id),
                      }))
                    }
                  >
                    Select all
                  </Button>
                ) : (
                  <Button
                    color="gray"
                    variant="ghost"
                    highContrast
                    size="1"
                    onClick={() =>
                      setPayload((prevState) => ({
                        ...prevState,
                        include: [],
                      }))
                    }
                  >
                    Clear all
                  </Button>
                )}
              </Flex>

              <div className={styles.GroupList}>
                {groups.map((group) => (
                  <Checkbox
                    key={group.id}
                    label={group.name}
                    value={(payload.include ?? []).includes(group.id)}
                    onChange={(checked) =>
                      handleGroupSelectionChanged(group.id, checked)
                    }
                  />
                ))}
              </div>
            </Grid>
          )}
        </div>

        {serverErrors?.general != null && (
          <Text size={"1"} weight={"medium"} color={"red"}>
            {serverErrors.general}
          </Text>
        )}
      </div>

      <div className={styles.Footer}>
        <Button
          color="gray"
          variant="solid"
          highContrast
          onClick={handleSubmit}
          disabled={loading}
        >
          <Spinner loading={loading} />
          Continue
        </Button>

        <Button
          color="gray"
          variant="soft"
          highContrast
          onClick={onBack}
          disabled={loading}
        >
          Back
        </Button>
      </div>
    </div>
  );
}

interface CreateIntegrationProps {
  name: IntegrationName;
  groups: TenantGroup[];
  onSubmit: (value: GroupsPolicy) => Promise<void>;
  onBack: () => void;
}

function CreateIntegration({
  name,
  groups,
  onSubmit,
  onBack,
}: CreateIntegrationProps) {
  const title = `Connect to ${integrationNameToGalleryItem[name].title}`;

  return (
    <StepMain>
      <Flex pt={"35px"} px={"45px"}>
        <StepHeader title={title} />
      </Flex>

      <Flex flexGrow={"1"} overflow={"hidden"}>
        {name === "zendesk" && (
          <ZendeskCreateForm
            groups={groups}
            onSubmit={onSubmit}
            onBack={onBack}
          />
        )}
        {name === "front" && (
          <FrontCreateForm
            groups={groups}
            onSubmit={onSubmit}
            onBack={onBack}
          />
        )}
        {name === "intercom" && (
          <IntercomCreateForm
            groups={groups}
            onSubmit={onSubmit}
            onBack={onBack}
          />
        )}
      </Flex>
    </StepMain>
  );
}

export default CreateIntegration;
