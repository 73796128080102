import { useEffect, useState } from "react";
import { useInstructions } from "../context/instructions";
import { Button, Flex, Switch, Text } from "@radix-ui/themes";
import { Dialog } from "../../../components/shared/dialog";
import { Textarea } from "@geist-ui/core";

export const BriefDialog = ({
  topicName,
  open,
  openChange,
}: {
  topicName: string;
  open: boolean;
  openChange: (value: boolean) => void;
}) => {
  const [text, setText] = useState("");

  const [knowledgeChecked, setKnowledgeChecked] = useState(true);
  const [historicalTicketsChecked, setHistoricalTicketsChecked] =
    useState(true);

  const { handleBrief, briefs } = useInstructions();

  useEffect(() => {
    const brief =
      briefs?.find((b) => b.topic === topicName && !b.subTopic)?.content ?? "";
    const knowledge =
      briefs?.find((b) => b.topic === topicName && b.subTopic === "knowledge")
        ?.content ?? "true";
    const tickets =
      briefs?.find((b) => b.topic === topicName && b.subTopic === "tickets")
        ?.content ?? "true";

    if (knowledge) {
      setKnowledgeChecked(knowledge === "true");
    }

    if (tickets) {
      setHistoricalTicketsChecked(tickets === "true");
    }
    setText(brief);
  }, [JSON.stringify(briefs), topicName]);

  return (
    <Dialog open={open} onOpenChange={openChange}>
      <Dialog.Content size="small">
        <Dialog.Title>
          Add Brief to topic "{topicName}"
          <Dialog.Close />
        </Dialog.Title>
        <div
          style={{
            display: "flex",
            padding: "8px",
            flexDirection: "column",
            gap: "6px",
          }}
        >
          <Textarea
            rows={5}
            placeholder={`${topicName} is about ...
our mission here is ... 
in case of ___ ...`}
            onChange={(e) => setText(e.target.value)}
            value={text}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          <Text as="label" size="3">
            <Flex gap="2">More configurations</Flex>
          </Text>
          <Text as="label" size="2">
            <Flex gap="2">
              <Switch
                color="blue"
                size="1"
                checked={knowledgeChecked}
                onCheckedChange={(value) => setKnowledgeChecked(value)}
              />{" "}
              Review help center articles
            </Flex>
          </Text>
          <Text as="label" size="2">
            <Flex gap="2">
              <Switch
                color="blue"
                size="1"
                checked={historicalTicketsChecked}
                onCheckedChange={(value) => setHistoricalTicketsChecked(value)}
              />{" "}
              Review historical tickets
            </Flex>
          </Text>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "8px",
            }}
          >
            <Button
              highContrast
              color="gray"
              variant="outline"
              onClick={() => openChange(false)}
            >
              Cancel
            </Button>
            <Button
              highContrast
              color="gray"
              onClick={() => {
                handleBrief(
                  text,
                  topicName,
                  knowledgeChecked,
                  historicalTicketsChecked
                );
                openChange(false);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog>
  );
};
