import React, { createContext, useContext, useEffect, useState } from "react";
import { Theme } from "@radix-ui/themes";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

export const radixThemeDataAttributes = {
  "data-accent-color": "yellow",
  "data-gray-color": "slate",
  "data-panel-background": "solid",
  "data-radius": "large",
  "data-scaling": "100%",
};

type ThemeContextType = {
  isDarkMode: boolean;
  toggleTheme: () => void;
};

export const ThemeContext = createContext<ThemeContextType>({
  isDarkMode: false,
  toggleTheme: () => {},
});

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const QuackTheme: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("quack-theme-mode");
    return savedTheme === "dark";
  });

  const toggleTheme = () => {
    setIsDarkMode((prevMode: boolean) => {
      const newMode = !prevMode;
      localStorage.setItem("quack-theme-mode", newMode ? "dark" : "light");
      return newMode;
    });
  };

  useEffect(() => {
    const rootElement = document.documentElement;
    if (isDarkMode) {
      rootElement.classList.add("dark-theme");
      rootElement.classList.remove("light-theme");
    } else {
      rootElement.classList.add("light-theme");
      rootElement.classList.remove("dark-theme");
    }
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <Theme
        accentColor="yellow"
        grayColor="slate"
        panelBackground="solid"
        radius="large"
        appearance={isDarkMode ? "dark" : "light"}
      >
        <TooltipPrimitive.Provider>{children}</TooltipPrimitive.Provider>
      </Theme>
    </ThemeContext.Provider>
  );
};
