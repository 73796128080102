import { PropsWithChildren, useMemo, useCallback, ReactNode } from "react";
import { Flex, IconButton, Button, Badge, Text } from "@radix-ui/themes";
import { Cross2Icon, PlusIcon } from "@radix-ui/react-icons";
import { DropdownMenu } from "../../../components/shared/dropdown-menu";
import styles from "../AutomationConfiguration.module.scss";

function IconWrapper({ children }: PropsWithChildren) {
  return <div className={styles.IconWrapper}>{children}</div>;
}

export type SelectOption = {
  label: ReactNode;
  value: string;
  icon?: ReactNode;
};

interface MultiSelectProps {
  options: SelectOption[];
  value: string[];
  onChange: (value: string[]) => void;
  loading?: boolean;
}

export function MultiSelect({
  options,
  value,
  onChange,
  loading,
}: MultiSelectProps) {
  const optionMap = useMemo(
    () => new Map(options.map((o) => [o.value, o])),
    [options]
  );

  const optionsToAdd = useMemo(
    () => options.filter((o) => !value.includes(o.value)),
    [options, value]
  );

  const handleAdd = useCallback(
    (option: SelectOption) => {
      onChange([...value, option.value]);
    },
    [value, onChange]
  );

  const handleRemove = useCallback(
    (valueToRemove: SelectOption["value"]) => {
      onChange(value.filter((v) => v !== valueToRemove));
    },
    [value, onChange]
  );

  return (
    <Flex direction={"row"} wrap={"wrap"} align={"center"} gap={"12px"}>
      {value.map((v) => {
        const option = optionMap.get(v);

        return (
          <Badge
            key={v}
            color="gray"
            size="1"
            className={styles.MultiSelectItem}
          >
            {option?.icon != null && <IconWrapper>{option.icon}</IconWrapper>}

            <Text size={"2"} highContrast>
              {option?.label ?? v}
            </Text>

            <IconButton
              color="gray"
              variant="soft"
              highContrast
              size="1"
              onClick={() => handleRemove(v)}
              loading={loading}
            >
              <Cross2Icon />
            </IconButton>
          </Badge>
        );
      })}

      {optionsToAdd.length > 0 && (
        <DropdownMenu
          items={optionsToAdd.map((o) => ({
            label: o.label,
            onClick: () => handleAdd(o),
            icon:
              o.icon != null ? <IconWrapper>{o.icon}</IconWrapper> : undefined,
          }))}
        >
          <DropdownMenu.Trigger>
            <Button
              color="gray"
              variant="soft"
              highContrast
              size="1"
              className={styles.MultiSelectMenuTrigger}
            >
              <PlusIcon height={"15px"} width={"15px"} />
              <Text size={"2"} weight={"regular"}>
                Add source
              </Text>
            </Button>
          </DropdownMenu.Trigger>
        </DropdownMenu>
      )}
    </Flex>
  );
}
