import { useAuthorizedQuery } from "..";
import { params } from "../../params";

export const useBrands = (enabled = true) => {
  return useAuthorizedQuery({
    queryKey: "brands",
    url: `${params.API_URL}/platform/tenant-brand`,
    method: "GET",
    enabled,
  });
};
