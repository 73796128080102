import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { ThemeProvider } from "styled-components";

import { ChatSvg } from "./chat-widget";
import { MessagesContainer } from "./messagesContainer";
import { EMessagesContainerType, TypeContext } from "./typeContext";

import { useAiChat } from "../lib/useChat";
import {
  useCreateChatSession,
  useGetMessages,
  useMessageFeedback,
} from "../../../api/copilot-chat";
import { useAuth0 } from "@auth0/auth0-react";
import { Loader } from "../loader";

export const ChatComponent = ({
  conversationalInsight,
  ticketId,
  refetchInsight,
}: {
  conversationalInsight?: {
    id: number;
    content: string;
    commentExternalId: string;
    feedback?: number;
  };
  ticketId: string;
  refetchInsight: () => void;
}) => {
  const [showInput, setShowInput] = useState(true);
  const chatIdRef = useRef<string | null>(null);
  const [chatId, setChatId] = useState<string | null>(null);
  const [showProactive, setShowProactive] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  const { mutateAsync } = useCreateChatSession();
  const { data, refetch } = useGetMessages(chatId ?? "");

  const {
    messages,
    input: question,
    handleInputChange: setQuestion,
    handleSubmit,
    isLoading: aiLoading,
    setMessages,
    append,
    isTyping,
  } = useAiChat({
    chatId: chatId ?? "",
    ticketId,
    conversationalInsight,
    data: data ?? [],
  });

  // useEffect(() => {
  //   setLoading(true);
  // }, [commentExternalId]);

  useEffect(() => {
    if (chatId) {
      setLoading(true);
      refetch();
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  }, [chatId]);

  useEffect(() => {
    (async () => {
      if (!chatIdRef.current) {
        if (
          conversationalInsight?.commentExternalId &&
          ticketId &&
          isAuthenticated
        ) {
          const token = await getIdTokenClaims();

          const response = await mutateAsync({
            commentExternalId: conversationalInsight.commentExternalId,
            ticketSourceId: ticketId,
          });
          const { copilotChatSession } = response;
          setChatId(copilotChatSession);
        }
      } else {
        setChatId(chatIdRef.current);
      }
    })();
  }, [conversationalInsight, ticketId, isAuthenticated]);

  const [type, setType] = useState<EMessagesContainerType>(
    messages.length !== 0
      ? EMessagesContainerType.CHAT
      : EMessagesContainerType.REST
  );

  const onMessage = async () => {
    setType(EMessagesContainerType.CHAT);
  };

  if (isLoading) {
    return <Loader loading />;
  }

  return (
    <ThemeProvider
      theme={{
        light: {
          iconPrimary: "#1A1A1A",
          background: "#EDEDED",
          lightBorder: "#656665",
          textPlaceholder: "#8F8F8F",
          borderDefault: "#C7C7C7",
          bgCard: "#EFF0F3",
          bgInverted: "#1D1D1D",
          bgPrimaryQuack: "#F2F2F2",
          borderLight: "#D9D9D9",
          textPrimary: "#1F1F1F",
          textInverted: "#EDEDED",
        },
        dark: {
          iconPrimary: "#1A1A1A",
          background: "#EDEDED",
          lightBorder: "#656665",
          textPlaceholder: "#8F8F8F",
          borderDefault: "#C7C7C7",
          bgCard: "#EFF0F3",
          bgInverted: "#1D1D1D",
          bgPrimaryQuack: "#F2F2F2",
          borderLight: "#D9D9D9",
          textPrimary: "#1F1F1F",
          textInverted: "#EDEDED",
        },
      }}
    >
      <TypeContext.Provider value={{ type }}>
        {!isLoading ? (
          <MessagesContainer
            onMessage={onMessage}
            closeWidget={() => {
              if (type === EMessagesContainerType.PROACTIVE)
                setType(EMessagesContainerType.REST);
              setShowInput(false);
            }}
            append={append}
            messages={messages}
            question={question}
            setQuestion={setQuestion}
            handleSubmit={handleSubmit}
            aiLoading={aiLoading}
            setMessages={setMessages}
            isTyping={isTyping}
            chatId={chatId}
            showProactive={showProactive}
            setShowProactive={setShowProactive}
            refetch={refetch}
            conversationalInsightId={conversationalInsight?.id}
            refetchInsight={refetchInsight}
          />
        ) : (
          "loading"
        )}
      </TypeContext.Provider>
    </ThemeProvider>
  );
};
