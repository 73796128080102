import { params } from "@/params";
import { useAuthorizedQuery } from "..";
import { useMemo } from "react";

export const useArticles = ({
  enabled,
  findBy,
  startDate,
  endDate,
  page,
}: {
  enabled: boolean;
  findBy?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
}) => {
  const queryParams = useMemo(() => {
    const params = new URLSearchParams();

    const paramMappings = [
      { value: startDate, key: "startDate" },
      { value: endDate, key: "endDate" },
      { value: findBy, key: "search" },
      { value: page?.toString(), key: "page" },
    ];

    paramMappings.forEach(({ value, key }) => {
      if (value) params.append(key, value);
    });

    return params.toString();
  }, [findBy, startDate, endDate, page]);

  return useAuthorizedQuery({
    queryKey: `useGetArticles-${queryParams}`,
    url: `${params.API_URL}/knowledge-base-handler/articles${
      queryParams ? `?${queryParams}` : ""
    }`,
    method: "GET",
    enabled,
  });
};

export const useArticlesUsage = ({
  enabled,
  startDate,
  endDate,
  articleIds,
}: {
  enabled: boolean;
  startDate?: string;
  endDate?: string;
  articleIds?: string[];
}) => {
  const queryParams = useMemo(() => {
    const params = new URLSearchParams();

    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);

    if (articleIds && articleIds.length > 0) {
      articleIds.forEach((id) => {
        params.append("articleIds[]", id);
      });
    }

    return params.toString();
  }, [startDate, endDate, articleIds]);

  return useAuthorizedQuery({
    queryKey: `useGetArticles-${queryParams}`,
    url: `${params.API_URL}/platform/tickets/count/by-article-ids${
      queryParams ? `?${queryParams}` : ""
    }`,
    method: "GET",
    enabled,
  });
};
