import { useCallback } from "react";
import { Button } from "@radix-ui/themes";
import { useFilter } from "../context";
import cx from "classnames";
import styles from "../styles.module.scss";

export const AutoResponse = () => {
  const { stringSliceBy, setStringSlicer, removeStringSlicer } = useFilter();

  const handleQuackAutoResponse = useCallback(() => {
    if (stringSliceBy?.and.find((s) => s.type === "auto-response")) {
      removeStringSlicer("auto-response");
    } else {
      setStringSlicer("auto-response", {
        type: "tags",
        operation: "or",
        values: [
          { type: "tags", operation: "eq", value: "quack-auto-response" },
        ],
      });
    }
  }, [stringSliceBy, removeStringSlicer, setStringSlicer]);

  return (
    <Button
      color="gray"
      variant="soft"
      highContrast
      size="1"
      className={cx(styles.btnWithBorder, styles.sliceBtn)}
      onClick={handleQuackAutoResponse}
    >
      Show Quack Interactions
    </Button>
  );
};
