function KustomerLogoSVG() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3409_41837)">
        <path
          d="M32.6686 44.6146C39.4509 44.6146 44.9615 39.1811 45 32.3988V17.2158C44.9615 10.4335 39.4509 5 32.6686 5H17.3314C10.5491 5 5.03854 10.4335 5 17.2158V32.3988C5.03854 39.1811 10.5491 44.6146 17.3314 44.6146H32.6686Z"
          fill="#FFFFFE"
        />
        <path
          d="M27.1967 19.2964V24.7685C27.1967 25.0768 27.3123 25.3465 27.5435 25.5778C27.7747 25.7704 28.0445 25.9246 28.3528 25.886H32.0522C32.3605 25.886 32.6302 25.7704 32.8614 25.5778C33.0541 25.3851 33.2083 25.0768 33.2083 24.7685V21.1076C33.2083 20.7993 33.0926 20.5296 32.8614 20.2984C32.6688 20.1057 32.3605 19.9516 32.0522 19.9901H29.4703V19.2579C29.4703 17.8321 30.6264 16.7146 32.0522 16.7146C32.6688 16.7146 33.1697 16.2136 33.1697 15.597C33.1697 14.9805 32.6688 14.4795 32.0522 14.4795C30.7805 14.4795 29.5474 14.9805 28.661 15.8668C27.6977 16.7916 27.1967 18.0248 27.1967 19.2964ZM16.8691 19.2964V24.7685C16.8691 25.0768 16.9847 25.3465 17.216 25.5778C17.4086 25.7704 17.7169 25.9246 18.0252 25.886H21.7246C22.0329 25.886 22.3027 25.7704 22.5339 25.5778C22.7651 25.3851 22.8807 25.0768 22.8807 24.7685V21.1076C22.8807 20.7993 22.7651 20.5296 22.5339 20.2984C22.3027 20.1057 22.0329 19.9516 21.7246 19.9901H19.1813V19.2579C19.1813 17.8706 20.3373 16.7146 21.7246 16.7146C22.3412 16.7146 22.8422 16.2136 22.8422 15.597C22.8422 14.9805 22.3412 14.4795 21.7246 14.4795C20.453 14.4795 19.2198 14.9805 18.3335 15.8668C17.4086 16.7916 16.8691 18.0248 16.8691 19.2964ZM18.2564 31.0498C18.0637 31.0498 17.9096 31.1654 17.794 31.3196C17.7169 31.4737 17.7169 31.7049 17.794 31.8591C17.9096 32.0132 20.2988 36.0595 25.0387 36.0595C29.7786 36.0595 32.1678 32.0132 32.2834 31.8591C32.3605 31.7049 32.3605 31.4737 32.2834 31.3196C32.2063 31.1654 32.0136 31.0498 31.821 31.0498H18.2564Z"
          fill="#0A0B09"
        />
        <path
          d="M17.3314 5C10.5491 5 5.03854 10.4335 5 17.2158V32.3988C5.03854 39.1811 10.5491 44.6146 17.3314 44.6146H27.736L20.8767 39.7206H17.3314C13.2466 39.7206 9.93256 36.4451 9.93256 32.3988V17.2158C9.9711 13.1696 13.2852 9.89403 17.3314 9.89403H32.6686C36.7534 9.89403 40.0289 13.1696 40.0674 17.2158V32.3988C40.0289 36.4451 36.7148 39.7206 32.6686 39.7206H29.7399V44.6146H32.6686C39.4509 44.6146 44.9615 39.1811 45 32.3988V17.2158C44.9615 10.4335 39.4509 5 32.6686 5H17.3314Z"
          fill="#FF5551"
        />
      </g>
      <defs>
        <clipPath id="clip0_3409_41837">
          <rect
            width="40"
            height="39.6146"
            fill="white"
            transform="translate(5 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default KustomerLogoSVG;
