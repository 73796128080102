import { useMemo, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Badge, Flex, Text } from "@radix-ui/themes";
import { Area, AreaChart, XAxis, YAxis } from "recharts";
import { AxisDomain } from "recharts/types/util/types";
import { ChartConfig, ChartContainer } from "@/components/ui/chart";
import { useAuth0 } from "@auth0/auth0-react";
import { isYotpo } from "@/api/user";

const chartConfig = {
  score: {
    label: "Score",
    color: "hsl(var(--chart-1))",
  },
} satisfies ChartConfig;

export type ScoresValue = { date: string; score: number }[];

interface ScoresChartProps {
  data: ScoresValue;
}

export function QaScoresChart({ data }: ScoresChartProps) {
  const { user } = useAuth0();

  const avgScore = useMemo(() => {
    if (data.length === 0) {
      return;
    }

    const total = data.reduce((acc, { score }) => acc + score, 0);
    return total / data.length;
  }, [data]);

  const color = useMemo(() => {
    if (data.length === 0) {
      return "var(--gray-7)";
    }

    const startData = data[0].score;
    const endData = data[data.length - 1].score;

    const diff = endData - startData;
    if (diff >= 1) {
      return "var(--green-8)";
    } else if (diff <= -1) {
      return "var(--red-8)";
    } else {
      return "var(--gray-7)";
    }
  }, [data, avgScore]);

  const domain = useMemo<AxisDomain>(() => {
    if (isYotpo(user)) {
      return [0, 1];
    } else {
      return [0, 5];
    }
  }, [user]);

  if (data.length === 0 || avgScore == null) {
    return (
      <Flex align={"end"} gap={"1"}>
        -
      </Flex>
    );
  }

  return (
    <Flex align={"end"} gap={"1"}>
      <Text>{avgScore.toFixed(2).replace(/[.,]00$/, "")}</Text>

      <ChartContainer config={chartConfig} className="w-[95px] h-[28px]">
        <AreaChart
          accessibilityLayer
          data={data}
          margin={{
            left: 12,
            right: 12,
          }}
        >
          <XAxis dataKey="data" hide tickLine={false} axisLine={false} />
          <YAxis type="number" hide domain={domain} />
          <Area
            dataKey="score"
            type="linear"
            fill={color}
            fillOpacity={0.4}
            stroke={color}
          />
        </AreaChart>
      </ChartContainer>
    </Flex>
  );
}

export function SentimentScoresChart({ data }: ScoresChartProps) {
  const chartId = useRef(uuidv4());

  const avgScore = useMemo(() => {
    if (data.length === 0) {
      return;
    }

    const total = data.reduce((acc, { score }) => acc + score, 0);
    return total / data.length;
  }, [data]);

  const offset = useMemo(() => {
    if (data.length === 0) {
      return 0;
    }

    const dataMax = Math.max(...data.map((i) => i.score));
    const dataMin = Math.min(...data.map((i) => i.score));

    if (dataMax <= 0 && dataMin <= 0) {
      return 0;
    }
    if (dataMax >= 0 && dataMin >= 0) {
      return 1;
    }

    const totalSpan = Math.abs(dataMax) + Math.abs(dataMin);
    if (totalSpan === 0) {
      return 0;
    }

    const offset = Math.abs(dataMax) / totalSpan;
    return offset;
  }, [data]);

  if (data.length === 0 || avgScore == null) {
    return (
      <Flex align={"end"} gap={"1"}>
        -
      </Flex>
    );
  }

  return (
    <Flex align={"center"} gap={"1"}>
      <Badge color={avgScore > 0 ? "green" : "red"}>
        <div
          className={`h-2 w-2 shrink-0 rounded-[2px] bg-current opacity-${
            Math.abs(avgScore) > 0.4 ? "100" : "70"
          }`}
        />
        {Math.abs(avgScore * 100).toFixed(0)}
      </Badge>

      <ChartContainer config={chartConfig} className="w-[95px] h-[32px]">
        <AreaChart
          accessibilityLayer
          data={data}
          margin={{
            left: 12,
            right: 12,
          }}
        >
          <XAxis dataKey="data" hide tickLine={false} axisLine={false} />
          <YAxis type="number" hide domain={[-1, 1]} />
          <defs>
            <defs>
              <linearGradient
                id={`${chartId.current}_splitColor`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset={offset}
                  stopColor="var(--green-8)"
                  stopOpacity={1}
                />
                <stop
                  offset={offset}
                  stopColor="var(--red-8)"
                  stopOpacity={1}
                />
              </linearGradient>
            </defs>
          </defs>
          <Area
            dataKey="score"
            type="linear"
            stroke={`url(#${chartId.current}_splitColor)`}
            fillOpacity={0.4}
            fill={`url(#${chartId.current}_splitColor)`}
          />
        </AreaChart>
      </ChartContainer>
    </Flex>
  );
}
