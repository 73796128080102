import { useAuthorizedMutation, useAuthorizedQuery } from "..";
import { params } from "../../params";

export interface Agent {
  id: number;
  externalId: string;
  email?: string;
  name: string;
  tenantId?: string;
  groupId?: string;
}

export interface AgentGroup {
  id: number;
  name?: string;
  externalId: string;
  tenantId: string;
}

export function useGetAgentGroups() {
  return useAuthorizedQuery<AgentGroup[]>({
    queryKey: `useGetAgentGroups`,
    url: `${params.API_URL}/platform/agent-group`,
    method: "GET",
    enabled: true,
  });
}

export function useGetAgentGroupAgents(externalId?: string) {
  return useAuthorizedQuery<Agent[]>({
    queryKey: `useGetAgentGroupAgents${externalId}`,
    url: `${params.API_URL}/platform/agent-group/${externalId}/agents`,
    method: "GET",
    enabled: externalId != null,
  });
}

export function useGetAgents() {
  return useAuthorizedQuery<Agent[]>({
    queryKey: `useGetAgents`,
    url: `${params.API_URL}/platform/agent`,
    method: "GET",
    enabled: true,
  });
}

export function useRefetchAgentsFromTicketSystem() {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/agent/refetch-agents`,
    method: "GET",
  });
}
