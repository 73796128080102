import React, { useMemo } from "react";
import ResizableBox from "../../../../components/ResizableBox/ResizableBox";
import { AxisOptions, Chart } from "react-charts";
import { Text } from "@radix-ui/themes";
import { ChatSession } from "../types";
import { ReleaseConversation } from "../../types";
import { useGetAnsweredByAi } from "../../../../api/useTickets";

export const Info = ({
  name,
  type,
  chatSessions,
  trainingConversations,
  ticketAnswered,
}: {
  name: string;
  type: "tickets" | "conversations";
  chatSessions: ChatSession[];
  trainingConversations: ReleaseConversation[];
  ticketAnswered: number;
}) => {
  const data = useMemo(() => {
    const array = chatSessions.map((c: any) => {
      const twoHoursAgo = new Date(Date.now() - 2 * 60 * 60 * 1000);
      return { ...c, isClosed: new Date(c.createdAt) < twoHoursAgo };
    });

    return [
      {
        label: "AI Resolution",
        data: [
          {
            primary: name,
            secondary: array.length,
          },
        ],
      },
      {
        label: "AI Esclation",
        data: [
          {
            primary: name,
            secondary: chatSessions.filter((c) => c.status === "ESCALATED")
              .length,
          },
        ],
      },
      {
        label: "AI FINISHED",
        data: [
          {
            primary: name,
            secondary: array
              .filter((c) => c.status !== "ESCALATED")
              .filter((c) => c.isClosed).length,
          },
        ],
      },
    ];
  }, [name, chatSessions]);

  const trainingData = useMemo(() => {
    return [
      {
        label: "Total Conversations",
        data: [
          {
            primary: name,
            secondary: trainingConversations?.length,
          },
        ],
      },
      {
        label: "Good Feedback",
        data: [
          {
            primary: name,
            secondary: trainingConversations?.filter(
              (c) => c.feedback === "POSITIVE"
            ).length,
          },
        ],
      },
      {
        label: "Bad Feedback",
        data: [
          {
            primary: name,
            secondary: trainingConversations?.filter(
              (c) => c.feedback === "NEGATIVE"
            ).length,
          },
        ],
      },
    ];
  }, [name, trainingConversations]);

  const primaryAxis = useMemo<
    AxisOptions<(typeof data)[number]["data"][number]>
  >(
    () => ({
      getValue: (datum) => datum.primary,
      min: 0,
    }),

    []
  );

  const secondaryAxes = useMemo<
    AxisOptions<(typeof data)[number]["data"][number]>[]
  >(
    () => [
      {
        getValue: (datum) => datum.secondary,
        min: 0,
      },
    ],
    []
  );
  const primaryTrainingAxis = useMemo<
    AxisOptions<(typeof trainingData)[number]["data"][number]>
  >(
    () => ({
      getValue: (datum) => datum.primary,
      min: 0,
    }),

    []
  );

  const secondaryTrainingAxes = useMemo<
    AxisOptions<(typeof trainingData)[number]["data"][number]>[]
  >(
    () => [
      {
        getValue: (datum) => datum.secondary,
        min: 0,
      },
    ],
    []
  );

  return (
    <div
      style={{
        padding: "16px",
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "16px",
      }}
    >
      {type === "conversations" && (
        <div>
          <Text as="label" size="6">
            Performance
          </Text>
          <ResizableBox resizable>
            <Chart
              options={{
                data,
                primaryAxis,
                secondaryAxes,
                defaultColors: ["#52a8ff", "#59ce8f", "#ef8784"],
              }}
            />
          </ResizableBox>
        </div>
      )}
      <div>
        <Text as="label" size="6">
          Total Count of{" "}
          {type === "conversations" ? "conversations" : `tickets answered `}
        </Text>
        <ResizableBox resizable>
          <div
            style={{
              fontSize: "120px",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {type === "conversations" ? chatSessions.length : ticketAnswered}
          </div>
        </ResizableBox>
      </div>
      <div>
        <Text as="label" size="6">
          Training Summary
        </Text>
        <ResizableBox resizable>
          <Chart
            options={{
              data: trainingData,
              primaryAxis: primaryTrainingAxis,
              secondaryAxes: secondaryTrainingAxes,
              defaultColors: ["#52a8ff", "#59ce8f", "#ef8784"],
            }}
          />
        </ResizableBox>
      </div>
    </div>
  );
};
