import { useMemo, useCallback, useState, useEffect } from "react";
import {
  Cross1Icon,
  MagnifyingGlassIcon,
  PlusIcon,
} from "@radix-ui/react-icons";
import { Badge, Button, Flex, IconButton, TextField } from "@radix-ui/themes";
import * as Popover from "@radix-ui/react-popover";
import { useLocation } from "react-router-dom";

import { useFilter } from "../context";

export const MultiTagSearch = () => {
  const { stringSliceBy, setStringSlicer, removeStringSlicer } = useFilter();
  const location = useLocation();

  const initialTags = useMemo(() => {
    const slicer = stringSliceBy?.and.find(({ type }) => type === "tags");
    return slicer?.values?.map(({ value }) => value) || [];
  }, [stringSliceBy]);

  const [active, setActive] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const [tags, setTags] = useState<string[]>(initialTags);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("tags")) {
      setActive(true);
    }
  }, [location.search]);

  const handleAddTag = useCallback(() => {
    if (searchPhrase.trim() && !tags.includes(searchPhrase.trim())) {
      const newTags = [...tags, searchPhrase.trim()];
      setTags(newTags);
      setStringSlicer("tags", {
        type: "tags",
        operation: "or",
        values: newTags.map((tag) => ({
          type: "tags",
          operation: "eq",
          value: tag,
        })),
      });
      setSearchPhrase("");
    }
  }, [searchPhrase, tags, setStringSlicer]);

  const handleRemoveTag = useCallback(
    (tagToRemove: string) => {
      const newTags = tags.filter((tag) => tag !== tagToRemove);
      setTags(newTags);
      if (newTags.length > 0) {
        setStringSlicer("tags", {
          type: "tags",
          operation: "or",
          values: newTags.map((tag) => ({
            type: "tags",
            operation: "eq",
            value: tag,
          })),
        });
      } else {
        removeStringSlicer("tags");
      }
    },
    [tags, setStringSlicer, removeStringSlicer]
  );

  const handleClearAll = useCallback(() => {
    removeStringSlicer("tags");
    setTags([]);
    setSearchPhrase("");
    setActive(false);
  }, [removeStringSlicer]);

  const lastTwoTags = useMemo(() => tags.slice(-2), [tags]);
  const remainingTags = useMemo(
    () => (tags.length > 2 ? tags.slice(0, -2) : []),
    [tags]
  );

  return (
    <>
      {active ? (
        <Flex gap="2" align="center">
          <Flex gap="2" align="center">
            <TextField.Root
              placeholder="Add a tag"
              size="1"
              autoFocus
              variant="soft"
              color="gray"
              value={searchPhrase}
              onChange={(e) => setSearchPhrase(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleAddTag()}
            >
              <TextField.Slot>
                <MagnifyingGlassIcon height="16" width="16" />
              </TextField.Slot>
              <TextField.Slot>
                <IconButton
                  size="1"
                  variant="ghost"
                  disabled={!searchPhrase.trim()}
                  onClick={() => handleAddTag()}
                >
                  <PlusIcon />
                </IconButton>
              </TextField.Slot>
              <TextField.Slot>
                <IconButton
                  size="1"
                  variant="ghost"
                  onClick={() => handleClearAll()}
                >
                  <Cross1Icon />
                </IconButton>
              </TextField.Slot>
            </TextField.Root>
          </Flex>
          <Flex gap="2" wrap="wrap">
            {remainingTags.length > 0 && (
              <Popover.Root>
                <Popover.Trigger asChild>
                  <Badge color="gray" size="1">
                    +{remainingTags.length}
                  </Badge>
                </Popover.Trigger>
                <Popover.Content>
                  <Flex
                    gap="1"
                    style={{ padding: "8px", backgroundColor: "white" }}
                  >
                    {remainingTags.map((tag) => (
                      <Badge
                        key={tag}
                        color="gray"
                        size="1"
                        style={{ width: "fit-content" }}
                      >
                        {tag}
                        <IconButton
                          size="1"
                          variant="ghost"
                          color="gray"
                          onClick={() => handleRemoveTag(tag)}
                        >
                          <Cross1Icon />
                        </IconButton>
                      </Badge>
                    ))}
                  </Flex>
                </Popover.Content>
              </Popover.Root>
            )}
            {lastTwoTags.map((tag) => (
              <Badge key={tag} color="gray" size="1">
                {tag}
                <IconButton
                  size="1"
                  variant="ghost"
                  color="gray"
                  onClick={() => handleRemoveTag(tag)}
                >
                  <Cross1Icon />
                </IconButton>
              </Badge>
            ))}
          </Flex>
        </Flex>
      ) : (
        <Button
          color="gray"
          variant="ghost"
          highContrast
          size="1"
          onClick={() => setActive(true)}
        >
          <MagnifyingGlassIcon /> Search By Tags
        </Button>
      )}
    </>
  );
};
