import { Member } from "../../../../api/members/members.model";
import { Card } from "../member";

import styles from "./styles.module.scss";

export const MembersList = ({
  members,
  refetch,
  isAdmin,
}: {
  members: Member[];
  refetch: () => void;
  isAdmin: boolean;
}) => {
  if (!members || members.length === 0) {
    return null;
  }
  return (
    <div className={styles.container}>
      {members.map((item, index) => {
        return (
          <div key={index}>
            <Card member={item} refetch={refetch} isAdmin={isAdmin} />
          </div>
        );
      })}
    </div>
  );
};
