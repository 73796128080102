import { PropsWithChildren } from "react";
import { Flex } from "@radix-ui/themes";
import RadioGroup, {
  RadioItemValue,
} from "../../../components/shared/inputs/radio-group/RadioGroup";
import styles from "../AutomationConfiguration.module.scss";

interface SubFieldRadioGroupProps {
  value: string;
  onChange: (value: string) => void;
  options: RadioItemValue[];
}

export function SubFieldRadioGroup({
  value,
  onChange,
  options,
  children,
}: PropsWithChildren<SubFieldRadioGroupProps>) {
  return (
    <Flex
      width={"100%"}
      direction={"column"}
      className={styles.SubFieldRadioGroup}
    >
      <RadioGroup
        value={value}
        onChange={onChange}
        options={options}
        layout="horizontal"
        classes={{
          root: styles.SubFieldRadioGroupRoot,
          optionItem: styles.SubFieldRadioOptionItem,
          label: styles.SubFieldRadioOptionItemLabel,
        }}
      />

      <Flex direction={"column"} p={"24px"} className={styles.SubFields}>
        {children}
      </Flex>
    </Flex>
  );
}
