import dayjs from "dayjs";
import { DateRange } from "src/components/shared/date-range-picker/DateRangePicker";

export const groupByTypes = [
  "topics",
  "categories",
  "teams",
  "agents",
  "customers",
  "tenantUser",
] as const;
export type GroupByType = (typeof groupByTypes)[number];

export function isGroupByType(obj: any): obj is GroupByType {
  return groupByTypes.includes(obj);
}

export const groupByToLabel: Record<GroupByType, string> = {
  topics: "Topics",
  categories: "Categories",
  teams: "Teams",
  agents: "Agents",
  customers: "Customers",
  tenantUser: "Users",
};

const viewsTypes = ["tickets", "conversations"] as const;
type ViewType = (typeof viewsTypes)[number];

export function isViewType(obj: any): obj is ViewType {
  return viewsTypes.includes(obj);
}

export const numericSlicerTypes = [
  "sentiment",
  "backAndForth",
  "timeToResolution",
  "qualityAssurance",
] as const;
export const stringSlicerTypes = [
  "organization",
  "status",
  "user",
  "agent",
  "priority",
  "topic",
  "category",
  "channel",
  "tags",
  "teams",
  "keyword",
  "finite-state",
  "auto-response",
  "ticket-id",
  "tenantUser",
] as const;

export type NumericSlicerType = (typeof numericSlicerTypes)[number];
export type StringSlicerType = (typeof stringSlicerTypes)[number];

export function isNumericSlicerType(obj: any): obj is NumericSlicerType {
  return numericSlicerTypes.includes(obj);
}

export function isStringSlicerType(obj: any): obj is StringSlicerType {
  return stringSlicerTypes.includes(obj);
}

export type SlicerType = NumericSlicerType | StringSlicerType;

type NumericSlicerOperation = "gte" | "lte";
type StringSlicerOperation = "eq" | "neq";

export type NumericSlicerValue = {
  type: NumericSlicerType;
  operation: NumericSlicerOperation;
  value: number;
};

export type NumericSlicer = {
  type: NumericSlicerType;
  operation: "or" | "and";
  values: NumericSlicerValue[];
};

export type StringSlicerValue = {
  type: StringSlicerType;
  operation: StringSlicerOperation;
  value: string;
};

export type StringSlicer = {
  type: StringSlicerType;
  operation: "or" | "and";
  values: StringSlicerValue[];
};

const sentimentSlicerPresets = ["negative", "neutral", "positive"] as const;
export type SentimentSlicerPresetType = (typeof sentimentSlicerPresets)[number];

export const sentimentSlicerMutator: Record<
  SentimentSlicerPresetType,
  NumericSlicer
> = {
  negative: {
    type: "sentiment",
    operation: "or",
    values: [{ type: "sentiment", operation: "lte", value: -0.3 }],
  },
  neutral: {
    type: "sentiment",
    operation: "and",
    values: [
      { type: "sentiment", operation: "gte", value: -0.3 },
      { type: "sentiment", operation: "lte", value: 0.3 },
    ],
  },
  positive: {
    type: "sentiment",
    operation: "or",
    values: [{ type: "sentiment", operation: "gte", value: 0.3 }],
  },
};

export const sentimentToLabel: Record<SentimentSlicerPresetType, string> = {
  negative: "Negative",
  neutral: "Neutral",
  positive: "Positive",
};

const qualityAssuranceSlicerPresets = ["low", "medium", "high"] as const;
export type QualityAssuranceSlicerPresetType =
  (typeof qualityAssuranceSlicerPresets)[number];

export const qualityAssuranceSlicerMutator: Record<
  QualityAssuranceSlicerPresetType,
  NumericSlicer
> = {
  low: {
    type: "qualityAssurance",
    operation: "or",
    values: [{ type: "qualityAssurance", operation: "lte", value: 3 }],
  },
  medium: {
    type: "qualityAssurance",
    operation: "or",
    values: [
      { type: "qualityAssurance", operation: "gte", value: 3 },
      { type: "qualityAssurance", operation: "lte", value: 4.5 },
    ],
  },
  high: {
    type: "qualityAssurance",
    operation: "or",
    values: [{ type: "qualityAssurance", operation: "gte", value: 4.5 }],
  },
};

export const qualityAssuranceToLabel: Record<
  QualityAssuranceSlicerPresetType,
  string
> = {
  low: "Low Score ( Score < 3 )",
  medium: "Medium Score ( Score 3-4.5 )",
  high: "High Score ( Score > 4.5 )",
};

export const numericSlicerMutators: Record<
  NumericSlicerType,
  Record<string, NumericSlicer> | null
> = {
  sentiment: sentimentSlicerMutator,
  backAndForth: null,
  timeToResolution: null,
  qualityAssurance: qualityAssuranceSlicerMutator,
};

export const numericSlicerToLabel: Record<
  NumericSlicerType,
  Record<string, string> | null
> = {
  sentiment: sentimentToLabel,
  backAndForth: null,
  timeToResolution: null,
  qualityAssurance: qualityAssuranceToLabel,
};

export type NumericSlicerPreset =
  | `sentiment_${SentimentSlicerPresetType}`
  | `qualityAssurance_${QualityAssuranceSlicerPresetType}`;

export function isNumericSlicerPreset(
  numericSlicer: unknown
): numericSlicer is NumericSlicerPreset {
  return (
    typeof numericSlicer === "string" &&
    numericSlicer.split("_").length === 2 &&
    numericSlicerTypes.includes(
      numericSlicer.split("_")[0] as NumericSlicerType
    ) &&
    (sentimentSlicerPresets.includes(
      numericSlicer.split("_")[1] as SentimentSlicerPresetType
    ) ||
      qualityAssuranceSlicerPresets.includes(
        numericSlicer.split("_")[1] as QualityAssuranceSlicerPresetType
      ))
  );
}

export const FiniteStateSlicer: StringSlicer = {
  type: "finite-state",
  operation: "or",
  values: [
    {
      type: "finite-state",
      operation: "eq",
      value: "closed",
    },
  ],
};

export type FilterValue = {
  groupBy?: GroupByType;
  stringSliceBy?: { and: StringSlicer[] };
  numericSliceBy?: { and: (NumericSlicer | NumericSlicerPreset)[] };
  dateRange: DateRange;
  view?: ViewType;
  mainSliceBy?: StringSlicerValue;
};

export type FilterValueParsed = {
  groupBy?: GroupByType;
  stringSliceBy?: { and: StringSlicer[] };
  numericSliceBy?: { and: NumericSlicer[] };
  dateFrom: string;
  dateTo: string;
};

export function pasrseFilterValue({
  dateRange,
  numericSliceBy,
  stringSliceBy,
  mainSliceBy,
  ...filterValue
}: FilterValue): FilterValueParsed {
  const parsedNumericSlicers =
    numericSliceBy?.and
      .map((slicer) => {
        if (isNumericSlicerPreset(slicer)) {
          const presetParts = slicer.split("_");
          const type = presetParts[0] as NumericSlicerType;
          const value = presetParts[1];

          return numericSlicerMutators[type]?.[value];
        }

        return slicer;
      })
      .filter((x): x is NumericSlicer => x != null) ?? [];

  const parsedStringSlicers: StringSlicer[] = [
    ...(stringSliceBy?.and ?? []),
    ...(mainSliceBy != null
      ? [
          {
            type: mainSliceBy.type,
            operation: "and" as const,
            values: [mainSliceBy],
          },
        ]
      : []),
  ];

  return {
    ...filterValue,
    ...{
      dateFrom: dayjs(dateRange.dateFrom).startOf("day").format(),
      dateTo: dayjs(dateRange.dateTo)
        .endOf("day")
        .set("hour", 23)
        .set("minute", 59)
        .set("second", 59)
        .format(),
    },
    ...(parsedNumericSlicers.length > 0
      ? { numericSliceBy: { and: parsedNumericSlicers } }
      : {}),
    ...(parsedStringSlicers.length > 0
      ? { stringSliceBy: { and: parsedStringSlicers } }
      : {}),
  };
}

export function componseSlicers({
  stringSliceBy,
  numericSliceBy,
  mainSliceBy,
}: Pick<FilterValue, "numericSliceBy" | "stringSliceBy" | "mainSliceBy">) {
  const mainSlicer =
    mainSliceBy != null ? [[mainSliceBy.type, mainSliceBy.value]] : [];

  const stringSlicers =
    stringSliceBy?.and.map((slicer) => [
      slicer.type,
      slicer.values.map(({ value }) => value).join(","),
    ]) ?? [];

  const numericSlicer =
    numericSliceBy?.and
      .filter((slicer) => isNumericSlicerPreset(slicer))
      .map((slicer) => {
        if (typeof slicer === "string") {
          return slicer.split("_");
        }
        return [];
      }) ?? [];

  return [...mainSlicer, ...stringSlicers, ...numericSlicer];
}

export function parseSlicers(
  searchParams: URLSearchParams
): Pick<FilterValue, "numericSliceBy" | "stringSliceBy"> {
  const stringSlicers: StringSlicer[] = [];
  const numericSlicer: (NumericSlicer | NumericSlicerPreset)[] = [];

  searchParams.forEach((value, key) => {
    if (isNumericSlicerType(key)) {
      const slicerType = key;
      const slicerValue = value;

      const numericSlicerPreset = `${slicerType}_${slicerValue}`;

      if (isNumericSlicerPreset(numericSlicerPreset)) {
        numericSlicer.push(numericSlicerPreset);
      }
    } else if (isStringSlicerType(key)) {
      const slicerType = key;
      const slicerValues = value.split(",");

      stringSlicers.push({
        type: slicerType,
        operation: "or",
        values: slicerValues.map((value) => ({
          type: slicerType,
          operation: "eq",
          value,
        })),
      });
    }
  });

  return {
    ...(stringSlicers.length > 0
      ? { stringSliceBy: { and: stringSlicers } }
      : {}),
    ...(numericSlicer.length > 0
      ? { numericSliceBy: { and: numericSlicer } }
      : {}),
  };
}

export const diveHelper: Partial<Record<GroupByType, StringSlicerType>> = {
  agents: "teams",
};

export const groupByToSliceType: Record<GroupByType, StringSlicerType> = {
  teams: "teams",
  agents: "agent",
  topics: "topic",
  categories: "category",
  customers: "organization",
  tenantUser: "tenantUser",
};

export const sliceTypeToGroupBy: Partial<Record<SlicerType, GroupByType>> = {
  topic: "topics",
  category: "categories",
  teams: "teams",
  organization: "customers",
  agent: "agents",
};

export const helper: Partial<Record<SlicerType, number>> = {
  topic: 1,
  category: 1,
  teams: 1,
  organization: 1,
  agent: 2,
};

export function enhanceFilterValue(value: FilterValue): FilterValue {
  const { view, groupBy, stringSliceBy, numericSliceBy } = value;

  if ((view === "tickets" || view === "conversations") && groupBy != null) {
    const test = stringSliceBy?.and.find(
      (slicer) => slicer.type === groupByToSliceType[groupBy]
    );

    if (test != null) {
      const nextStringSlicer =
        stringSliceBy?.and.filter(
          (slicer) => slicer.type !== groupByToSliceType[groupBy]
        ) ?? [];

      return {
        dateRange: value.dateRange,
        groupBy,
        view,
        numericSliceBy,
        mainSliceBy: {
          type: test.type,
          operation: "eq",
          value: test.values[0].value,
        },
        ...(nextStringSlicer.length > 0
          ? { stringSliceBy: { and: nextStringSlicer } }
          : {}),
      };
    }

    return value;
  } else if (groupBy != null && diveHelper[groupBy] != null) {
    const test = stringSliceBy?.and.find(
      (slicer) => slicer.type === diveHelper[groupBy]
    );

    if (test != null) {
      const nextStringSlicer =
        stringSliceBy?.and.filter(
          (slicer) => slicer.type !== diveHelper[groupBy]
        ) ?? [];

      return {
        dateRange: value.dateRange,
        groupBy,
        numericSliceBy,
        mainSliceBy: {
          type: test.type,
          operation: "eq",
          value: test.values[0].value,
        },
        ...(nextStringSlicer.length > 0
          ? { stringSliceBy: { and: nextStringSlicer } }
          : {}),
      };
    }

    return value;
  }

  return value;
}

export function findDiveHierarchy(filterValue: FilterValue) {
  const res: FilterValue[] = [];

  function inner(value: FilterValue) {
    const { groupBy, view, mainSliceBy, ...restValue } = value;

    if (groupBy == null || mainSliceBy == null) {
      return;
    }

    if (view === "tickets" || view === "conversations") {
      const newFilterValue = enhanceFilterValue({
        ...restValue,
        groupBy,
      });

      inner(newFilterValue);

      res.push(newFilterValue);
    } else {
      const nextGroupBy = diveHelper[groupBy];

      if (nextGroupBy) {
        const newFilterValue = enhanceFilterValue({
          ...restValue,
          groupBy: sliceTypeToGroupBy[nextGroupBy],
        });

        inner(newFilterValue);

        res.push(newFilterValue);
      }
    }
  }

  inner(filterValue);

  return res;
}
