import { params } from "@/params";
import { useEffect, useLayoutEffect, useState } from "react";
import { useRef } from "react";

export function ChatbotSandbox() {
  const queryParams = new URLSearchParams(window.location.search);
  const tParam = queryParams.get("t");

  const [isCopied, setCopied] = useState(false);

  const isScriptInitialized = useRef(false);

  useLayoutEffect(() => {
    if (!isScriptInitialized.current) {
      setTimeout(() => {
        (function (
          q: any,
          u: any,
          a: any,
          c: any,
          k: any,
          i: any = {},
          o: any = undefined
        ) {
          q[a] =
            q[a] ||
            function () {
              (q[a].q = q[a].q || []).push(arguments);
            };
          i = u.createElement(c);
          i.async = true;
          i.id = "quack-license";
          i.src = `${params.SDK_URL}/verify.min.js`;
          i.setAttribute(a, k);
          o = u.getElementsByTagName(c)[0];
          if (o && o.parentNode) {
            o.parentNode.insertBefore(i, o);
          }
        })(window, document, "quack", "script", tParam);
      }, 1000);
    }

    isScriptInitialized.current = true;
  }, []);

  return (
    <div
      style={{
        marginTop: "10vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      Sandbox environment to test the chat
      <br />
      Check bottom right corner
      <br />
      <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
        Or{" "}
        <button
          onClick={() => {
            setCopied(true);
            navigator.clipboard.writeText(`<script>
                  (function (q, u, a, c, k, i, o) {
                    q[a] =
                      q[a] ||
                      function () {
                        (q[a].q = q[a].q || []).push(arguments);
                      };
                    i = u.createElement(c);
                    i.async = 1;
                    i.id = "quack-license";
                    i.src = "${params.SDK_URL}/verify.min.js";
                    i.setAttribute(a, k);
                    o = u.getElementsByTagName(c)[0];
                    o.parentNode.insertBefore(i, o);
                  })(window, document, "quack", "script", "${tParam}")
            </script>`);

            setTimeout(() => {
              setCopied(false);
            }, 2000);
          }}
        >
          {isCopied ? "Copied!" : "Copy"}
        </button>{" "}
        the script and try it yourself{" "}
      </div>
      <pre style={{ fontSize: "12px" }}>
        {`<script>
      (function (q, u, a, c, k, i, o) {
        q[a] =
          q[a] ||
          function () {
            (q[a].q = q[a].q || []).push(arguments);
          };
        i = u.createElement(c);
        i.async = 1;
        i.id = "quack-license";
        i.src = "${params.SDK_URL}/verify.min.js";
        i.setAttribute(a, k);
        o = u.getElementsByTagName(c)[0];
        o.parentNode.insertBefore(i, o);
      })(window, document, "quack", "script", "${tParam}")
</script>`}
      </pre>
    </div>
  );
}
