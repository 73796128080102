export enum IntegrationEvents {
  KNOWLEDGE_INTEGRATION = "integration_knowledge_integration",
  TICKETING_SYSTEM_INTEGRATION = "integration_ticketing_system_integration",
  CLICK_INTEGRATION = "integration_name_integration_click",
  CREATE_INTEGRATION = "integration_create_integration",
  COMPLETE_TICKET_SYSTEM_INTEGRATION = "integration_complete_ticket_system_integration",
  CANT_FIND_TOKEN = "integration_cant_find_token_click",
  TICKET_SYSTEM_SYNC_METHOD = "integration_ticket_system_integration_sync_method_click",
  TICKET_SYSTEM_AUTO_SYNC = "integration_ticket_system_integration_auto_sync_click",
  CREATE_BRAND_CONFIGURATION = "integration_create_brand_configuration",
}
