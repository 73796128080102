import { useCallback, useEffect, useMemo, useState, ReactNode } from "react";
import { Navbar } from "@/components/shared/navbar/Navbar";
import {
  ExploreRow,
  TicketExploreRow,
  translateColumnsToExploreRows,
  translateColumnsToTicketExploreRows,
} from "../../api/useExplore/Explore.model";
import {
  useExplore,
  useExploreInfiniteTickets,
  useExploreValues,
} from "../../api/useExplore";
import { useAuth0 } from "@auth0/auth0-react";
import { FilterProvider, useFilter } from "./context";
import { Search } from "./actions/search";
import { Keyword } from "./actions/keyword";
import {
  FiniteStateSlicer,
  groupByToLabel,
  pasrseFilterValue,
} from "./context/FilterContext.model";
import SlicedBy from "./SlicedBy";
import { DateRangePicker } from "@/components/shared/date-range-picker/DateRangePicker";
import { useExploreNavbar, UseExploreNavbarOptions } from "./ExploreNavbar";
import styles from "./styles.module.scss";
import {
  exploreLevel1Table,
  exploreTicketsLevelTable,
  globalEExploreLevelTable,
  globalETicketsLevelTable,
} from "./stractures";
import ExploreTable, { ColumnType, TableSum } from "./ExploreTable";
import {
  exploreCellGenerator,
  ticketExploreCellGenerator,
  ticketExploreCellValueGetter,
} from "./CellGenerator";
import { useGenerateReport } from "../../api/useGenerateReport";
import { Button } from "@radix-ui/themes";
import { SearchByTicketId } from "./actions/searchByTicketId";
import TicketQAScore from "../quality/ticket-qa-score/TicketQAScore";
import { MultiTagSearch } from "./actions/searchByTags";
import { GroupBy } from "./GroupBy";
import { AutoResponse } from "./slicers/auto-responsed";
import {
  mixTrackEvent,
  InteractionInformationEvents,
} from "../../assets/mixpanel";
import dayjs from "dayjs";
import { PageEmptyState } from "@/components/page-empty-state/PageEmptyState";
import { ExploreTicketsSVG } from "@/components/page-empty-state/assests/ExploreTicketsSVG";
import { useOnboarding } from "@/api/onboarding";
import { Link } from "@/components/shared/link/Link";
import { useAgentScoreFeatFlag } from "@/api/agent-score/useAgentScoreFeatFlag";

interface ExploreComponentProps {
  navbarOptions?: UseExploreNavbarOptions;
  exploreLevel1Table?: ColumnType<keyof ExploreRow>[];
  onTicketClick?: (ticket: TicketExploreRow) => void;
  finiteState?: boolean;
  hideGroupBy?: boolean;
  emptyStateOverlay?: ReactNode;
}

export const ExploreComponent = ({
  navbarOptions = {
    staticPrefix: ["Tickets"],
  },
  onTicketClick,
  finiteState = false,
  hideGroupBy = false,
  emptyStateOverlay,
  ...props
}: ExploreComponentProps) => {
  const { user, getIdTokenClaims } = useAuth0();
  const showCollaboratorsColumn = useAgentScoreFeatFlag();
  const {
    mutate: loadExplore,
    isLoading: exploreLoading,
    data: exploreData,
  } = useExplore();
  const { data: exploreValues } = useExploreValues(!!user);

  const {
    groupBy,
    stringSliceBy,
    numericSliceBy,
    dateRange,
    setDateRange,
    view,
    mainSliceBy,
    diveInto,
    diveIntoTickets,
  } = useFilter();

  const exploreNavbarItems = useExploreNavbar(navbarOptions);

  const [token, setToken] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState("");

  const ticketsQuery = useExploreInfiniteTickets(
    {
      groupBy: groupBy,
      ...(numericSliceBy ? { numericSliceBy } : {}),
      ...(finiteState
        ? {
            stringSliceBy: {
              and: [FiniteStateSlicer, ...(stringSliceBy?.and ?? [])],
            },
          }
        : {
            ...(stringSliceBy ? { stringSliceBy } : {}),
          }),
      dateRange,
      mainSliceBy,
      includeComments: true,
    },
    !!user && view === "tickets"
  );

  const data = view === "tickets" ? ticketsQuery.data?.pages[0] : exploreData;
  const isLoading =
    view === "tickets" ? ticketsQuery.isLoading : exploreLoading;

  const exploreTableProps = useMemo(() => {
    const columns = (props.exploreLevel1Table ?? exploreLevel1Table).map(
      (column) => {
        if (column.key === "name") {
          return { ...column, label: groupByToLabel[groupBy ?? "topics"] };
        }

        return column;
      }
    );

    if (exploreData == null) {
      return { columns };
    }

    const rows = translateColumnsToExploreRows(exploreData).filter(
      ({ avgVolume, name }) =>
        (avgVolume ?? 0) >= 1 && name.toLowerCase().includes(searchPhrase)
    );

    return { columns, rows };
  }, [props.exploreLevel1Table, exploreData, groupBy, searchPhrase]);

  const ticketExploreTableProps = useMemo(() => {
    const rows = ticketsQuery.data?.pages
      .map((page) => {
        return translateColumnsToTicketExploreRows(page);
      })
      .flat();

    const columns =
      user?.owner === "global-e"
        ? globalETicketsLevelTable
        : exploreTicketsLevelTable;

    if (!showCollaboratorsColumn) {
      return {
        columns: columns.filter(({ key }) => key !== "collaborators"),
        rows,
      };
    }

    return { columns, rows };
  }, [ticketsQuery, user, showCollaboratorsColumn]);

  const isEmpty = useMemo(() => {
    return data?.metadata?.ticketCount === 0;
  }, [data]);

  const agentId = useMemo(() => {
    if (mainSliceBy?.type !== "agent") {
      return;
    }

    return ticketsQuery.data?.pages[0]?.ticketsData?.tickets[0].agentAssigned
      ?.id;
  }, [mainSliceBy, ticketsQuery.data]);

  const { generateReport, isLoading: generatingReport } =
    useGenerateReport(agentId);

  const handleExloreRowClick = useCallback(
    (row: ExploreRow) => {
      if (groupBy === "teams") {
        diveInto("teams", "agents", row.name);
      } else {
        diveIntoTickets(row.name);
      }
    },
    [groupBy, diveInto, diveIntoTickets]
  );

  useEffect(() => {
    getIdTokenClaims().then((claims) => {
      setToken(true);
    });
  }, [getIdTokenClaims]);

  useEffect(() => {
    if (user && token) {
      if (view == null) {
        loadExplore(
          pasrseFilterValue({
            groupBy: groupBy,
            ...(numericSliceBy ? { numericSliceBy } : {}),
            ...(finiteState
              ? {
                  stringSliceBy: {
                    and: [FiniteStateSlicer, ...(stringSliceBy?.and ?? [])],
                  },
                }
              : {
                  ...(stringSliceBy ? { stringSliceBy } : {}),
                }),
            dateRange,
            mainSliceBy,
          })
        );
      }
    }
  }, [
    groupBy,
    view,
    user,
    token,
    loadExplore,
    dateRange,
    numericSliceBy,
    stringSliceBy,
    mainSliceBy,
    finiteState,
  ]);

  if (isEmpty && emptyStateOverlay) {
    return (
      <div className={styles.exploreContainer}>
        <div className={styles.controlRow}>
          <Navbar items={exploreNavbarItems} />
        </div>

        {emptyStateOverlay}
      </div>
    );
  }

  return (
    <div className={styles.exploreContainer}>
      <div className={styles.controlRow}>
        <Navbar items={exploreNavbarItems} />

        <div className={styles.buttonGroup}>
          {view === "tickets" && agentId != null && (
            <Button
              color={"gray"}
              variant={"solid"}
              highContrast
              size={"1"}
              loading={generatingReport}
              onClick={() => {
                const { dateFrom, dateTo } = dateRange;
                generateReport(dateFrom, dateTo);
              }}
            >
              Generate report
            </Button>
          )}

          {/* <LLM
            data={
              view === "tickets"
                ? ticketExploreTableProps.rows
                : exploreTableProps.rows
            }
          /> */}
        </div>
      </div>

      <div className={styles.controlRow}>
        <div className={styles.buttonGroup}>
          <SlicedBy
            isLoading={isLoading}
            count={data?.metadata?.ticketCount}
            groupBy={data?.metadata?.groupBy}
            exploreValues={exploreValues}
            finiteState={finiteState}
          />
        </div>

        <div className={styles.buttonGroup}>
          {!hideGroupBy && <GroupBy />}
          <DateRangePicker value={dateRange} onChange={setDateRange} />
        </div>
      </div>

      <div className={styles.controlRow}>
        <div className={styles.buttonGroup}>
          <Search {...{ searchPhrase, setSearchPhrase }} />
          <Keyword />
          <SearchByTicketId />
          <MultiTagSearch />
          <AutoResponse />
        </div>
      </div>

      <TableSum
        isLoading={isLoading}
        {...(view === "tickets"
          ? {
              label: "tickets",
              count: ticketExploreTableProps.rows?.length,
              totalCount: data?.metadata?.ticketCount,
            }
          : {
              label: groupByToLabel[groupBy ?? "topics"],
              count: exploreTableProps.rows?.length,
            })}
      />

      {view === "tickets" ? (
        <ExploreTable<TicketExploreRow>
          {...ticketExploreTableProps}
          isLoading={isLoading}
          groupBy={groupBy}
          hasNextPage={ticketsQuery.hasNextPage}
          fetchNextPage={ticketsQuery.fetchNextPage}
          isFetchingNextPage={ticketsQuery.isFetchingNextPage}
          onRowClick={onTicketClick}
          cellGenerator={ticketExploreCellGenerator}
          cellValueGetter={ticketExploreCellValueGetter}
          defaultSortState={{ key: "overview", direction: "asc" }}
        />
      ) : (
        <ExploreTable<ExploreRow>
          {...exploreTableProps}
          isLoading={isLoading}
          groupBy={groupBy}
          onRowClick={handleExloreRowClick}
          cellGenerator={exploreCellGenerator}
        />
      )}
    </div>
  );
};

function ExploreTicketsEmptyState() {
  const { hasTicketSystemIntegration } = useOnboarding();

  return (
    <PageEmptyState
      title={"No tickets to display yet"}
      description={
        "Tickets will appear here, organized by topics, categories, teams, and clients once available."
      }
      svg={"explore-tickets"}
    >
      {!hasTicketSystemIntegration && (
        <div>
          <Link to={"/getting-started"}>Connect Ticketing System</Link>
        </div>
      )}
    </PageEmptyState>
  );
}

function ExploreInner() {
  const [ticket, setTicket] = useState<TicketExploreRow>();
  const { user } = useAuth0();
  const { backfillStatus } = useOnboarding();

  const exploreNavbarItems = useExploreNavbar({});

  const toggleTicket = useCallback((ticket: TicketExploreRow) => {
    setTicket(ticket);
    mixTrackEvent({
      event: InteractionInformationEvents.OPENED_TICKET_SCORE,
      properties: {
        ticketId: ticket.id,
        source: "explore",
      },
    });
  }, []);

  const handleClose = useCallback(() => {
    setTicket(undefined);
  }, []);

  const exploreLevels = useMemo(() => {
    return user?.owner === "global-e"
      ? globalEExploreLevelTable
      : exploreLevel1Table;
  }, [user]);

  if (backfillStatus !== "completed") {
    return (
      <div className={styles.exploreContainer}>
        <div className={styles.controlRow}>
          <Navbar items={[{ title: "Explore" }]} />
        </div>

        <ExploreTicketsEmptyState />
      </div>
    );
  }

  return (
    <>
      <ExploreComponent
        onTicketClick={toggleTicket}
        exploreLevel1Table={exploreLevels}
      />

      <TicketQAScore
        ticket={ticket}
        navbarItems={exploreNavbarItems}
        onClose={handleClose}
      />
    </>
  );
}

export const Explore = () => {
  return (
    <FilterProvider
      defaultValue={{
        dateRange: {
          dateFrom: dayjs().subtract(14, "days").format("YYYY-MM-DD"),
          dateTo: dayjs().format("YYYY-MM-DD"),
        },
        groupBy: "topics",
      }}
    >
      <ExploreInner />
    </FilterProvider>
  );
};
