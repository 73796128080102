import { useContext } from "react";
import ReactDiffViewer, {
  DiffMethod,
  ReactDiffViewerStylesOverride,
} from "react-diff-viewer-continued";
import { ThemeContext } from "../../Theme";

export const compareStyles: ReactDiffViewerStylesOverride = {
  diffRemoved: {
    opacity: 0.7,
  },
  emptyLine: {
    backgroundColor: "transparent",
  },
  marker: {
    padding: "0px",
  },
  contentText: {
    padding: "6px",
    fontFamily: "Geist",
    border: "none",
  },
  wordRemoved: {
    backgroundColor: "#FF6166",
    padding: "0 2px",
    borderRadius: "4px",
  },
  wordAdded: {
    backgroundColor: "#4d945a",
    padding: "0 2px",
    borderRadius: "4px",
  },
};

interface DiffViewerProps {
  oldValue?: string | Object | undefined;
  newValue?: string | Object | undefined;
}

export function DiffViewer({ oldValue, newValue }: DiffViewerProps) {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <ReactDiffViewer
      oldValue={oldValue}
      newValue={newValue}
      compareMethod={DiffMethod.WORDS}
      splitView={true}
      hideLineNumbers={true}
      showDiffOnly={false}
      useDarkTheme={isDarkMode}
      styles={compareStyles}
    />
  );
}
