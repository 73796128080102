import { useState } from "react";
import cx from "classnames";
import { Button } from "@radix-ui/themes";
import { ViewVerticalIcon, ReaderIcon } from "@radix-ui/react-icons";
import TicketOverview from "./TicketOverview";
import styles from "./TicketQAScore.module.scss";
import { TicketExploreRow } from "../../../api/useExplore/Explore.model";
import { TicketComments } from "./TicketQAScore.model";
import TicketTranscript from "./TicketTranscript";
import {
  mixTrackEvent,
  InteractionInformationEvents,
} from "../../../assets/mixpanel";
import { useTicketTranscript } from "@/api/ticket-transcript/TicketTranscript.api";
import { useAgentScoreFeatFlag } from "@/api/agent-score/useAgentScoreFeatFlag";
import { TicketTranscript as NewTicketTranscript } from "@/features/ticket-transcript/TicketTranscript";

const sidePanelTabs = ["overview", "transcript"] as const;
export type SidePanelTab = (typeof sidePanelTabs)[number];

interface SidePanelProps {
  ticket: TicketExploreRow;
  comments: TicketComments[];
  hasRelease?: boolean;
  defaultTab?: SidePanelTab;
}

function SidePanel({
  ticket,
  comments,
  hasRelease,
  defaultTab = "overview",
}: SidePanelProps) {
  const showNewTranscript = useAgentScoreFeatFlag();

  const transcriptQuery = useTicketTranscript(
    showNewTranscript ? ticket.id : undefined
  );

  const [activeTab, setActiveTab] = useState<"overview" | "transcript">(
    defaultTab
  );

  return (
    <div className={styles.SidePanel}>
      <div className={styles.Tabs}>
        <Button
          variant={"outline"}
          color={"gray"}
          size={"1"}
          onClick={() => setActiveTab("overview")}
          className={cx(styles.TabButton, {
            [styles.active]: activeTab === "overview",
          })}
        >
          <ViewVerticalIcon /> Overview
        </Button>

        <Button
          variant={"outline"}
          color={"gray"}
          size={"1"}
          onClick={() => {
            setActiveTab("transcript");
            mixTrackEvent({
              event: InteractionInformationEvents.VIEWED_TRANSCRIPT_TAB,
              properties: {
                ticketId: ticket.id,
              },
            });
          }}
          className={cx(styles.TabButton, {
            [styles.active]: activeTab === "transcript",
          })}
        >
          <ReaderIcon /> Transcript
        </Button>
      </div>

      <div className={styles.TabContent}>
        {activeTab === "overview" && ticket.overview && (
          <TicketOverview {...ticket} hasRelease={hasRelease} />
        )}

        {activeTab === "transcript" && (
          <>
            {showNewTranscript ? (
              <NewTicketTranscript
                data={transcriptQuery?.data?.comments ?? []}
                isLoading={transcriptQuery.isLoading}
                collaborators={ticket.collaborators}
              />
            ) : (
              <TicketTranscript comments={comments} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SidePanel;
