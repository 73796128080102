import { useCallback, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Button, Flex, Box, TextArea, Card } from "@radix-ui/themes";
import { Pencil2Icon, CheckIcon, Cross1Icon } from "@radix-ui/react-icons";
import { Message } from "@/components/quack-chat/core/entities/Message";
import { FeatureAccessContext } from "@/context/featureAccess";
import {
  QuackChatSystemMessageType,
  useOverrideQuackChatSystemMessage,
} from "@/api/quackchat/configuration/useQuackChatSystemMessageOverride";
import styles from "./QuackchatSystemMessageConfiguration.module.scss";

interface EditSystemMessageProps {
  type: QuackChatSystemMessageType;
  message: Message;
  onSuccess: () => void;
}

export function EditSystemMessage({
  type,
  message,
  onSuccess,
}: EditSystemMessageProps) {
  const { selectedWorkspace } = useContext(FeatureAccessContext);
  const { mutate: overrideSystemMessage, isLoading } =
    useOverrideQuackChatSystemMessage();

  const [elem, setElem] = useState<HTMLElement | null>(null);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(message.text);

  const handleEdit = useCallback(() => {
    setEdit(true);
  }, []);

  const handleCancel = useCallback(() => {
    setEdit(false);
    setValue(message.text);
  }, [message.text]);

  const hadleSave = useCallback(async () => {
    if (value === message.text) {
      setEdit(false);
      return;
    }

    overrideSystemMessage(
      {
        tenant: selectedWorkspace,
        type,
        message: value,
      },
      {
        onSuccess: () => {
          onSuccess();
          setEdit(false);
        },
      }
    );
  }, [selectedWorkspace, type, value, overrideSystemMessage, onSuccess]);

  useEffect(() => {
    setElem(document.getElementById(message.deduplicationKey));
  }, [message.deduplicationKey]);

  if (elem == null) {
    return <></>;
  }

  if (edit) {
    return createPortal(
      <Flex direction={"column"} gap={"2"} className={styles.EditSystemMessage}>
        <Card variant="surface" style={{ padding: 4 }}>
          <TextArea
            variant={"soft"}
            color={"gray"}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={"Enter message..."}
          />
        </Card>

        <Flex justify={"end"} align={"center"} gap={"2"}>
          <Button
            size={"2"}
            color={"gray"}
            variant={"outline"}
            style={{
              boxShadow: "var(--shadow-3)",
            }}
            onClick={handleCancel}
          >
            <Cross1Icon />
            Cancel
          </Button>

          <Button
            size={"2"}
            color={"gray"}
            variant={"solid"}
            highContrast
            disabled={isLoading}
            style={{
              boxShadow: "var(--shadow-3)",
            }}
            onClick={hadleSave}
          >
            <CheckIcon />
            Save
          </Button>
        </Flex>
      </Flex>,
      elem
    );
  }

  return createPortal(
    <Box
      style={{
        position: "absolute",
        bottom: -40,
        left: 8,
      }}
    >
      <Button
        size={"2"}
        color={"gray"}
        variant={"outline"}
        style={{
          boxShadow: "var(--shadow-3)",
        }}
        onClick={handleEdit}
      >
        <Pencil2Icon />
        Edit
      </Button>
    </Box>,
    elem
  );
}
