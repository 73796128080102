import { useCallback, useMemo } from "react";
import { Flex, Text } from "@radix-ui/themes";
import { ReaderIcon } from "@radix-ui/react-icons";
import { NavbarItem } from "@/components/shared/navbar/Navbar";
import { Dialog } from "../../components/shared/dialog";
import { ConversationRow } from "../../api/useExplore/ExploreConversation.model";
import TicketTranscript from "../quality/ticket-qa-score/TicketTranscript";
import styles from "./styles.module.scss";
import { SendInteractionToTraining } from "../simulator-live-mode/sendTicketToTraining";
import { InteractionType } from "../simulator-live-mode/types";
import { useFeatureFlag } from "configcat-react";
import { useAuth0 } from "@auth0/auth0-react";

interface ConversationMessagesProps {
  conversation?: ConversationRow;
  navbarItems: NavbarItem[];
  onClose: () => void;
}

export function ConversationMessages({
  conversation,
  onClose,
}: ConversationMessagesProps) {
  const { user } = useAuth0();
  const { value: sendConversationToTraining } = useFeatureFlag(
    "sendConversationToTraining",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const handleOpenChanged = useCallback(
    (open: boolean) => {
      if (!open) {
        onClose();
      }
    },
    [onClose]
  );

  const comments = useMemo(() => {
    return conversation?.messages?.map((message) => ({
      body: message.message,
      updatedAt: message.createdAt,
      agent: message.author !== "USER",
      isInternal: false,
    }));
  }, [conversation]);

  if (conversation == null) {
    return <></>;
  }

  return (
    <Dialog open onOpenChange={handleOpenChanged}>
      <Dialog.Content size={"large"}>
        <Dialog.Title>
          <Flex pl={"15px"} gap={"10px"} align={"center"}>
            <ReaderIcon />
            <Text size={"2"}>Messages</Text>
          </Flex>
          <Flex align={"center"} gap={"10px"}>
            {sendConversationToTraining && (
              <SendInteractionToTraining
                interactionId={conversation.id}
                interactionType={InteractionType.CONVERSATION}
              />
            )}
            <Dialog.Close />
          </Flex>
        </Dialog.Title>

        <div className={styles.ConversationMessages}>
          <TicketTranscript comments={comments ?? []} />
        </div>
      </Dialog.Content>
    </Dialog>
  );
}
