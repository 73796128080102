import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { OpportunityGroupPriority, OpportunityStatus } from "./index.types";
import { DateRange } from "@/components/shared/date-range-picker/DateRangePicker";

export const useKnowledgeGapsSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialSearchPhrase = searchParams.get("searchPhrase") || "";
  const initialPriority: OpportunityGroupPriority[] = searchParams.get(
    "priority"
  )
    ? (searchParams.get("priority")!.split(",") as OpportunityGroupPriority[])
    : ["high", "critical", "medium"];
  const initialStatus: OpportunityStatus[] = searchParams.get("status")
    ? (searchParams.get("status")!.split(",") as OpportunityStatus[])
    : ["todo", "verify"];
  const initialDateFrom =
    searchParams.get("dateFrom") ||
    dayjs().subtract(2, "months").format("YYYY-MM-DD");
  const initialDateTo =
    searchParams.get("dateTo") || dayjs().format("YYYY-MM-DD");

  const [searchPhrase, setSearchPhrase] = useState<string>(initialSearchPhrase);
  const [priority, setPriority] =
    useState<OpportunityGroupPriority[]>(initialPriority);
  const [status, setStatus] = useState<OpportunityStatus[]>(initialStatus);
  const [dateRange, setDateRange] = useState<DateRange>({
    dateFrom: initialDateFrom,
    dateTo: initialDateTo,
  });

  useEffect(() => {
    setSearchParams({
      searchPhrase,
      priority: priority.join(","),
      status: status.join(","),
      dateFrom: dateRange.dateFrom,
      dateTo: dateRange.dateTo,
    });
  }, [searchPhrase, priority, status, dateRange, setSearchParams]);

  return {
    searchPhrase,
    setSearchPhrase,
    priority,
    setPriority,
    status,
    setStatus,
    dateRange,
    setDateRange,
  };
};
