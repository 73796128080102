import { Ticket, TicketCollaborator, TicketOverview } from "./Ticket.model";
import {
  FilterValueParsed,
  NumericSlicer,
  StringSlicer,
} from "../../routes/explore/context/FilterContext.model";

type KeyValueExploreValue = {
  value: string;
  label: string;
};

export interface ExploreValuesResponse {
  channels: KeyValueExploreValue[];
  statuses: KeyValueExploreValue[];
  topics: string[];
  categories: string[];
  agents: string[];
}

export type TrendValue = {
  date: string;
  count: number;
}[];

export interface ExploreValue {
  avgVolume: number;
  trendVolume: TrendValue;
  avgTimeToResolve: number;
  resolutionRate: number;
  avgBackAndForth: number;
  avgQaScore: number;
  trendQa: TrendValue;
  numberOfAgentsEvaluated: number;
  taggedRate: number;
  avgSentiment: number;
  avgMessagesCount: number;
  avgInternalNotesCount: number;
  topic: string;
  volume: number;
  topTopics: number;
  lastTicket: string;
  lastTicketDate: string;
}

export type ExploreRow = {
  id: number;
  name: string;
} & Partial<ExploreValue>;

export interface TicketExploreValue {
  ttr: number;
  backAndForth: number;
  sentimentScores: number[];
}

export type TicketExploreCollaborator = {
  isInternal: boolean;
  avgQaScore?: number;
  publicCommentsCount?: number;
  internalCommentsCount?: number;
} & TicketCollaborator;

export type TicketExploreRow = {
  id: string;
  topic?: string;
  agent?: string;
  category?: string;
  kbCoverage?: string;
  team?: string;
  overview: TicketOverview;
  status?: string;
  channel?: string;
  createdAt?: string;
  updatedAt?: string;
  ticketCsat?: number;
  avgQaScore?: number;
  tldr?: string;
  isValidated?: boolean;
  commentsCount?: number;
  tags?: string;
  messagesCount?: number;
  internalNotesCount?: number;
  avgSentiment?: number;
  organization?: string;
  collaborators?: TicketExploreCollaborator[];
} & Partial<TicketExploreValue>;

type ColumnResponseStatus = "fulfilled" | "rejected";

export interface ColumnResponse<IdType, DataType> {
  status: ColumnResponseStatus;
  value: {
    name: keyof DataType;
    data: (IdType & Partial<DataType>)[];
  };
}

export type ExploreRequest = FilterValueParsed;

interface ExploreResponseMetadata
  extends Omit<ExploreRequest, "stringSliceBy" | "numericSliceBy"> {
  tenantId: string;
  numericSliceBy: NumericSlicer[];
  stringSliceBy: StringSlicer[];
  ticketCount: number;
}

export interface ExploreResponse {
  metadata: ExploreResponseMetadata;
  res: ColumnResponse<{ id: number; name: string }, ExploreValue>[];
}

export function translateColumnsToExploreRows({
  res,
}: ExploreResponse): ExploreRow[] {
  if (!res) return [];

  const rows: Record<
    number,
    {
      id: number;
      name: string;
    } & Partial<ExploreValue>
  > = {};

  const fulfilledColumns = res.filter(({ status }) => status === "fulfilled");

  fulfilledColumns.forEach((column) => {
    const key = column.value.name;

    column.value.data.forEach((data) => {
      const { id, name } = data;

      if (id == null || data[key] == null) {
        return;
      }

      if (rows[id] == null) {
        rows[id] = { id, name, [key]: data[key] };
      } else {
        rows[id] = { ...rows[id], [key]: data[key] };
      }
    });
  });

  return Object.values(rows);
}

export type TicketExploreRequest = FilterValueParsed & {
  includeComments: boolean;
};

interface TicketExploreResponseMetadata extends ExploreResponseMetadata {
  isThereNextPage: boolean;
}

export interface TicketExploreResponse {
  metadata: TicketExploreResponseMetadata;
  res: ColumnResponse<
    { id?: string; ticketId?: string; name: string },
    TicketExploreValue
  >[];
  ticketsData?: {
    isThereNextPage: boolean;
    totalCount: number;
    tickets: Ticket[];
  };
}

export function getTicketCollaborators(
  ticket?: Ticket
): TicketExploreCollaborator[] {
  const agentCalculationsForTicketMap = new Map(
    ticket?.agentCalculationsForTicket?.map(({ agentId, avgQaScores }) => [
      agentId,
      avgQaScores,
    ]) ?? []
  );

  const { internalCollaborators, publicCollaborators } = ticket ?? {};

  const collaborators: TicketExploreCollaborator[] = [
    ...(internalCollaborators?.map((collaborator) => ({
      ...collaborator,
      isInternal: true,
    })) ?? []),
    ...(publicCollaborators?.map((collaborator) => ({
      ...collaborator,
      isInternal: false,
    })) ?? []),
  ];

  let unknownAgentIndex = 1;

  return collaborators.map((collaborator) => ({
    ...collaborator,
    avgQaScore: agentCalculationsForTicketMap.get(collaborator.id),
    publicCommentsCount: ticket?.comments?.reduce((acc, comment) => {
      if (!comment.isInternal && comment.authorId === collaborator.externalId) {
        acc += 1;
      }
      return acc;
    }, 0),
    internalCommentsCount: ticket?.comments?.reduce((acc, comment) => {
      if (comment.isInternal && comment.authorId === collaborator.externalId) {
        acc += 1;
      }
      return acc;
    }, 0),
    name:
      collaborator.name != null && collaborator.name !== ""
        ? collaborator.name
        : `Unknown Agent ${unknownAgentIndex++}`,
  }));
}

export function translateColumnsToTicketExploreRows({
  res,
  ticketsData,
}: TicketExploreResponse): TicketExploreRow[] {
  if (!res) return [];

  const rowsObj: Record<
    string,
    {
      id: string;
    } & Partial<TicketExploreValue>
  > = {};

  const fulfilledColumns = res.filter(({ status }) => status === "fulfilled");

  fulfilledColumns.forEach((column) => {
    const key = column.value.name;

    column.value.data.forEach((data) => {
      const id = data.id || data.ticketId;

      if (id == null || data[key] == null) {
        return;
      }

      if (rowsObj[id] == null) {
        rowsObj[id] = { id, [key]: data[key] };
      } else {
        rowsObj[id] = { ...rowsObj[id], [key]: data[key] };
      }
    });
  });

  const rows = Object.values(rowsObj);

  const ticketMap = new Map(
    ticketsData?.tickets.map((ticket) => [ticket.id, ticket])
  );

  return rows
    .filter(({ id }) => id)
    .map((row) => {
      const ticket = ticketMap.get(row.id);

      const sortedInsights = ticket?.insights?.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      const commentsCount = ticket?.comments?.reduce(
        (acc, comment) => {
          if (comment.isInternal) {
            acc.internal += 1;
          } else {
            acc.external += 1;
          }

          return acc;
        },
        { external: 0, internal: 0 }
      );

      const sentimentNotNull = ticket?.insights?.filter(
        (insight) => insight.sentiment != null
      );
      const sentimentSum = sentimentNotNull?.reduce(
        (acc, insight) => acc + insight.sentiment,
        0
      );

      const agentCalculationsForTicketMap = new Map(
        ticket?.agentCalculationsForTicket?.map(({ agentId, avgQaScores }) => [
          agentId,
          avgQaScores,
        ]) ?? []
      );

      const agentAssignedId = ticket?.agentAssigned?.id;

      const avgQaScore =
        agentAssignedId != null
          ? agentCalculationsForTicketMap.get(agentAssignedId)
          : ticket?.ticketCalculations?.avgQaScores;

      const collaborators = getTicketCollaborators(ticket);

      return {
        ...ticket,
        topic: ticket?.topic?.topic,
        agent: ticket?.agentAssigned?.name,
        organization: ticket?.tenantOrganization?.name,
        category: ticket?.category?.name,
        kbCoverage: ticket?.kbEvaluation?.coverageLevel,
        team: ticket?.agentAssigned?.agentGroups?.[0]?.name,
        ticketCsat: ticket?.ticketCsat?.score,
        tldr: sortedInsights?.[0]?.tldr,
        ...row,
        avgQaScore,
        overview: {
          id: ticket?.displayId ?? row.id.split("::")[1],
          subject: ticket?.subject ?? "",
        },
        messagesCount: commentsCount?.external,
        internalNotesCount: commentsCount?.internal,
        avgSentiment:
          sentimentSum && sentimentNotNull?.length
            ? sentimentSum / sentimentNotNull?.length
            : undefined,
        ttr: ticket?.ticketCalculations?.timeToResolution,
        collaborators,
      };
    });
}

export function printTicketId(ticketId: string) {
  return `#${ticketId}`;
}
