import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import styled from "styled-components";
import { IoSparkles } from "react-icons/io5";
import { ChevronDown } from "@geist-ui/icons";
import { Message } from "ai/react";

import { Input } from "./input";
import { EMessagesContainerType, TypeContext } from "./typeContext";
import { MessageComponent } from "./message";
import { fadeAndMoveUp } from "./utils";
import { Badge } from "@geist-ui/core";
import {
  useMessageFeedback,
  useAddOverviewFeedback,
} from "../../../api/copilot-chat";

import { CONVERSATIONAL_INSIGHT_MESSAGE_ID_PREFIX } from "../lib/useChat";
import { useAuth0 } from "@auth0/auth0-react";
import { ETicketsComponents } from "../lib/types";

export const MessagesContainer = ({
  onMessage,
  closeWidget,
  messages,
  question,
  setQuestion,
  handleSubmit,
  aiLoading,
  setMessages,
  isTyping,
  append,
  chatId,
  showProactive,
  setShowProactive,
  refetch,
  conversationalInsightId,
  refetchInsight,
}: {
  onMessage: () => Promise<void>;
  closeWidget: () => void;
  messages: Message[];
  question: string;
  append: (message: Message) => void;
  setQuestion: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  aiLoading: boolean;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  isTyping: boolean;
  chatId: string | null;
  showProactive: boolean;
  setShowProactive: (val: boolean) => void;
  refetch: () => Promise<any>;
  conversationalInsightId?: number;
  refetchInsight: () => void;
}) => {
  const { type } = useContext(TypeContext);
  const [isLoading, setLoading] = useState(false);

  const [isInputPresented, setInputPresented] = useState(true);
  const [messagesCountBeforeLoad, setMessagesBeforeLoad] = useState(0);

  const [proactiveMessage, setProactiveMessage] = useState("");
  const [escalation, setEscalation] = useState(false);

  useEffect(() => {
    if (!aiLoading && isLoading) {
      setLoading(false);
    }
  }, [aiLoading, isLoading]);

  useEffect(() => {
    if (type === EMessagesContainerType.PROACTIVE) {
      setInputPresented(false);
      setProactiveMessage("Hi! Let me help you choose the best plan");
      setShowProactive(true);
      setInputPresented(true);
    } else if (showProactive) {
      setShowProactive(false);
    }
  }, [type, showProactive]);

  const submitQuestion = async (e: React.FormEvent<HTMLFormElement>) => {
    if (showProactive) {
      setMessages([
        {
          id: "123",
          content: proactiveMessage,
          role: "assistant",
        },
      ]);
      setMessagesBeforeLoad(messages.length + 2);
    } else {
      setMessagesBeforeLoad(messages.length + 1);
    }
    await onMessage();
    setLoading(true);
    handleSubmit(e);
  };

  const { user } = useAuth0();
  const { mutateAsync: sendConversationInsightFeedback } =
    useAddOverviewFeedback({
      insightId: String(conversationalInsightId),
    });
  const { mutateAsync: sendMessageFeedback } = useMessageFeedback();

  const handleMessageFeedback = async (
    messageId: string,
    feedback: boolean
  ) => {
    if (messageId.startsWith(CONVERSATIONAL_INSIGHT_MESSAGE_ID_PREFIX)) {
      await sendConversationInsightFeedback({
        type: ETicketsComponents.CONVERSATIONAL_INSIGHT,
        rank: feedback ? 1 : 0,
        email: user?.email,
        author: user?.name,
      });
      refetchInsight();
    } else {
      await sendMessageFeedback({ messageId, feedback });
      void refetch();
    }
  };

  return (
    <>
      <Messages>
        {messages.map((message, index) => (
          <MessageComponent
            key={message.id}
            message={message}
            isLastMessage={index === messages.length - 1}
            isResponse={
              messages?.[index - 1]?.content ===
              "Draft me a response to the user"
            }
            sendFeedback={handleMessageFeedback}
          />
        ))}
        {isLoading && messagesCountBeforeLoad === messages.length && (
          <MessageComponent
            message={{
              role: "assistant",
              content: "Loading...",
              id: "loading",
            }}
            isLoading={isLoading}
            isLastMessage={true}
          />
        )}
      </Messages>
      {isInputPresented && (
        <div className="bottom">
          <QuickActions>
            <div className="label">suggested next steps</div>
            <div className="">
              <span
                onClick={() => {
                  // different component
                  append({
                    role: "user",
                    content: "Draft me a response to the user",
                    id: "123",
                  });
                }}
              >
                Draft response
              </span>
              <span
                onClick={() => {
                  append({
                    role: "user",
                    content: "write me a Tl;dr up to 30 words",
                    id: "123",
                  });
                }}
              >
                TL;DR
              </span>
            </div>
          </QuickActions>
          <Input
            value={question}
            handleInputChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              setQuestion(e);
            }}
            messagesLength={messages.length}
            handleSubmit={submitQuestion}
            isTyping={isTyping}
          />
        </div>
      )}
    </>
  );
};

const Messages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 200px;
  flex-grow: 1;
  overflow-y: scroll;
`;

const QuickActions = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  gap: 8px;
  div {
    gap: 10px;
    display: flex;
    span {
      border: 1px solid #d3d4db;
      cursor: pointer;
      border-radius: 999px;
      color: #000509;
      font-size: 12px;
      padding: 0 14px;
      text-align: center;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border: 1px solid #000509;
      }
    }
  }

  .label {
    color: #60646c;
    background-color: unset;
  }
`;
