export function SessionsReportSVG() {
  return (
    <svg
      width="380"
      height="161"
      viewBox="0 0 380 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4142_63971)">
        <rect
          width="380"
          height="161"
          rx="4"
          fill="url(#paint0_linear_4142_63971)"
          fill-opacity="0.05"
        />
        <rect
          x="0.5"
          y="0.5"
          width="379"
          height="160"
          rx="3.5"
          stroke="#000D37"
          stroke-opacity="0.1624"
        />
        <rect
          x="175"
          y="65.5"
          width="30"
          height="30"
          rx="15"
          fill="white"
          fill-opacity="0.5"
        />
        <rect
          width="12"
          height="12"
          transform="translate(184 74.5)"
          fill="white"
          fill-opacity="0.01"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M186.594 76.3576C186.714 76.2852 186.863 76.281 186.987 76.3464L194.187 80.1464C194.318 80.2157 194.4 80.3518 194.4 80.5002C194.4 80.6485 194.318 80.7847 194.187 80.8539L186.987 84.6539C186.863 84.7194 186.714 84.7151 186.594 84.6427C186.474 84.5703 186.4 84.4403 186.4 84.3002V76.7002C186.4 76.56 186.474 76.43 186.594 76.3576ZM187.2 77.3636V83.6367L193.143 80.5002L187.2 77.3636Z"
          fill="#1C2024"
        />
        <path
          d="M281 130C281 126.686 283.686 124 287 124H353C356.314 124 359 126.686 359 130V137C359 140.314 356.314 143 353 143H287C283.686 143 281 140.314 281 137V130Z"
          fill="white"
          fill-opacity="0.5"
        />
        <rect
          width="9"
          height="9"
          transform="translate(345 137)"
          fill="white"
          fill-opacity="0.01"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M346.976 137.029C347.083 136.981 347.208 136.999 347.297 137.076L352.716 141.77C352.809 141.851 352.843 141.979 352.803 142.095C352.763 142.212 352.655 142.291 352.533 142.297L350.532 142.385L351.691 144.931C351.76 145.082 351.693 145.26 351.542 145.328L350.251 145.917C350.1 145.985 349.922 145.919 349.854 145.768L348.693 143.222L347.318 144.671C347.234 144.76 347.104 144.789 346.99 144.744C346.876 144.698 346.801 144.588 346.801 144.465V137.302C346.801 137.185 346.869 137.078 346.976 137.029ZM347.401 137.959V143.713L348.564 142.487C348.632 142.414 348.732 142.381 348.831 142.397C348.929 142.414 349.013 142.478 349.054 142.569L350.275 145.246L351.021 144.907L349.802 142.229C349.761 142.138 349.767 142.033 349.819 141.948C349.871 141.863 349.962 141.809 350.062 141.805L351.754 141.73L347.401 137.959Z"
          fill="#1C2024"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4142_63971"
          x1="-7.81739e-07"
          y1="161"
          x2="392.076"
          y2="97.1212"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3EDDDD" />
          <stop offset="1" stop-color="#F9D40F" />
        </linearGradient>
        <clipPath id="clip0_4142_63971">
          <rect width="380" height="161" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function DarkSessionsReportSVG() {
  return (
    <svg
      width="380"
      height="161"
      viewBox="0 0 380 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5032_84333)">
        <rect
          x="0.5"
          y="0.5"
          width="379"
          height="160"
          rx="3.5"
          fill="url(#paint0_linear_5032_84333)"
          fill-opacity="0.05"
        />
        <rect
          x="0.5"
          y="0.5"
          width="379"
          height="160"
          rx="3.5"
          stroke="#464749"
        />
        <rect
          x="175"
          y="65.5"
          width="30"
          height="30"
          rx="15"
          fill="white"
          fill-opacity="0.5"
        />
        <rect
          width="12"
          height="12"
          transform="translate(184 74.5)"
          fill="white"
          fill-opacity="0.01"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M186.592 76.3576C186.712 76.2852 186.861 76.2809 186.985 76.3464L194.185 80.1464C194.316 80.2156 194.398 80.3518 194.398 80.5001C194.398 80.6485 194.316 80.7846 194.185 80.8539L186.985 84.6539C186.861 84.7193 186.712 84.7151 186.592 84.6427C186.472 84.5703 186.398 84.4403 186.398 84.3001V76.7001C186.398 76.5599 186.472 76.43 186.592 76.3576ZM187.198 77.3635V83.6367L193.141 80.5001L187.198 77.3635Z"
          fill="#EDEEF0"
        />
        <path
          d="M281 130C281 126.686 283.686 124 287 124H353C356.314 124 359 126.686 359 130V137C359 140.314 356.314 143 353 143H287C283.686 143 281 140.314 281 137V130Z"
          fill="white"
          fill-opacity="0.5"
        />
        <rect
          width="9"
          height="9"
          transform="translate(345 137)"
          fill="white"
          fill-opacity="0.01"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M346.976 137.029C347.083 136.981 347.208 136.999 347.297 137.076L352.716 141.77C352.809 141.851 352.843 141.979 352.803 142.095C352.763 142.212 352.655 142.291 352.533 142.297L350.532 142.385L351.691 144.931C351.76 145.082 351.693 145.26 351.542 145.328L350.251 145.917C350.1 145.985 349.922 145.919 349.854 145.768L348.693 143.222L347.318 144.671C347.234 144.76 347.104 144.789 346.99 144.744C346.876 144.698 346.801 144.588 346.801 144.465V137.302C346.801 137.185 346.869 137.078 346.976 137.029ZM347.401 137.959V143.713L348.564 142.487C348.632 142.414 348.732 142.381 348.831 142.397C348.929 142.414 349.013 142.478 349.054 142.569L350.275 145.246L351.021 144.907L349.802 142.229C349.761 142.138 349.767 142.033 349.819 141.948C349.871 141.863 349.962 141.809 350.062 141.805L351.754 141.73L347.401 137.959Z"
          fill="#EDEEF0"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5032_84333"
          x1="-7.81739e-07"
          y1="161"
          x2="392.076"
          y2="97.1212"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3EDDDD" />
          <stop offset="1" stop-color="#F9D40F" />
        </linearGradient>
        <clipPath id="clip0_5032_84333">
          <rect width="380" height="161" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
