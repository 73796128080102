import {
  ExploreRow,
  TicketExploreRow,
} from "../../api/useExplore/Explore.model";
import {
  ConversationRow,
  ExploreConversationRow,
} from "../../api/useExplore/ExploreConversation.model";
import { ColumnType } from "./ExploreTable";

export const exploreTicketsLevelTable: ColumnType<keyof TicketExploreRow>[] = [
  {
    label: "Tickets",
    key: "overview",
    width: "25%",
  },
  {
    label: "Avg. QA Score",
    key: "avgQaScore",
  },
  {
    label: "Status",
    key: "status",
  },
  {
    label: "Topic",
    key: "topic",
  },
  {
    label: "Agent",
    key: "agent",
    minWidth: "200px",
  },
  {
    label: "Collaborators",
    key: "collaborators",
    minWidth: "200px",
  },
  {
    label: "Category",
    key: "category",
  },
  {
    label: "Team",
    key: "team",
  },
  {
    label: "TTR",
    key: "ttr",
  },
  {
    label: "Channel",
    key: "channel",
  },
  {
    label: "Organization",
    key: "organization",
  },
  {
    label: "KB Coverage",
    key: "kbCoverage",
  },
  {
    label: "CSAT",
    key: "ticketCsat",
  },
  {
    label: "Messages Count",
    key: "messagesCount",
  },
  {
    label: "Internal Notes",
    key: "internalNotesCount",
  },
  {
    label: "Avg. Sentiment",
    key: "avgSentiment",
  },
  {
    label: "Created At",
    key: "createdAt",
  },
  {
    label: "Last Update",
    key: "updatedAt",
  },
];

export const globalETicketsLevelTable: ColumnType<keyof TicketExploreRow>[] = [
  ...exploreTicketsLevelTable,
  {
    label: "Jira Ticket",
    key: "tags",
  },
];

export const exploreLevel1Table: ColumnType<keyof ExploreRow>[] = [
  {
    label: "Topics",
    key: "name",
    width: "45%",
  },
  {
    label: "Volume",
    key: "avgVolume",
  },
  {
    label: "Trend Pattern",
    key: "trendVolume",
  },
  {
    label: "Resolution",
    key: "resolutionRate",
  },
  {
    label: "Avg. TTR",
    key: "avgTimeToResolve",
  },
  {
    label: "Avg. Messages",
    key: "avgMessagesCount",
  },
  {
    label: "Avg. Internal Notes",
    key: "avgInternalNotesCount",
  },
  {
    label: "Avg. QA Score",
    key: "avgQaScore",
  },
];

export const globalEExploreLevelTable: ColumnType<keyof ExploreRow>[] = [
  ...exploreLevel1Table,
  {
    label: "Linked to Jira",
    key: "taggedRate",
  },
];

export const exploreConversation: ColumnType<keyof ExploreConversationRow>[] = [
  {
    label: "Topics",
    key: "name",
    width: "45%",
  },
  {
    label: "Conversations Vol.",
    key: "avgVolume",
  },
  // {
  //   label: "Escalations",
  //   key: "escalationRate",
  // },
  {
    label: "Avg. Messages Count",
    key: "avgMessagesCount",
  },
  // {
  //   label: "Avg. CSAT",
  //   key: "avgCsatScore",
  // },
];

export const conversation: ColumnType<keyof ConversationRow>[] = [
  {
    label: "Conversation",
    key: "firstMessage",
    width: "40%",
  },
  {
    label: "Topic",
    key: "topic",
  },
  {
    label: "Created At",
    key: "createdAt",
  },
  // {
  //   label: "Category",
  //   key: "category",
  // },
  // {
  //   label: "Escalation",
  //   key: "escalation",
  // },
  // {
  //   label: "TTR",
  //   key: "ttr",
  // },
  {
    label: "Messages Count",
    key: "messagesCount",
  },
  {
    label: "Channel",
    key: "channel",
  },
];
