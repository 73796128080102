import * as SwitchPrimitive from "@radix-ui/react-switch";
import styles from "./Switch.module.scss";

interface SwitchProps {
  value: boolean;
  onChange: (newValue: boolean) => void;
}

function Switch({ value, onChange }: SwitchProps) {
  return (
    <SwitchPrimitive.Root
      checked={value}
      onCheckedChange={onChange}
      className={styles.Root}
    >
      <SwitchPrimitive.Thumb className={styles.Thumb} />
    </SwitchPrimitive.Root>
  );
}

export default Switch;
