import {
  forwardRef,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import cx from "classnames";
import * as SelectPrimitive from "@radix-ui/react-select";
import {
  Flex,
  Spinner,
  Text,
  IconButton,
  TextField,
  Theme,
} from "@radix-ui/themes";
import {
  ChevronDownIcon,
  Cross2Icon,
  MagnifyingGlassIcon,
} from "@radix-ui/react-icons";
import styles from "./Select.module.scss";
import { radixThemeDataAttributes } from "@/components/Theme";

export type SelectOption = {
  label: string;
  value: string;
  disabled?: boolean;
};

interface SelectItemProps {
  value: string;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
}

const SelectItem = forwardRef(function SelectItem(
  {
    children,
    value,
    selected = false,
    disabled,
    className,
  }: PropsWithChildren<SelectItemProps>,
  forwardedRef: React.Ref<HTMLDivElement>
) {
  return (
    <SelectPrimitive.Item
      ref={forwardedRef}
      value={value}
      disabled={disabled}
      className={cx(styles.Item, className)}
      aria-selected={selected}
    >
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});

interface SearchInputProps {
  value: string;
  onChange: (newValue: string) => void;
}

function SearchInput({ value, onChange }: SearchInputProps) {
  const handleInputKeyDown = useCallback((event: React.KeyboardEvent) => {
    event.stopPropagation();
  }, []);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <Flex width={"100%"} p={"1"}>
      <TextField.Root
        autoFocus
        placeholder="Search…"
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        className={styles.SearchInput}
      >
        <TextField.Slot>
          <MagnifyingGlassIcon />
        </TextField.Slot>
      </TextField.Root>
    </Flex>
  );
}

interface SelectProps {
  options?: SelectOption[];
  value?: string;
  onChange: (value: string) => void;
  size?: "small" | "medium";
  placeholder?: string;
  classes?: {
    trigger?: string;
    value?: string;
  };
  startIcon?: JSX.Element;
  loading?: boolean;
  allowClear?: boolean;
  allowSearch?: boolean;
}

function Select({
  options = [],
  value,
  onChange,
  size = "medium",
  placeholder = "Select …",
  classes = {},
  startIcon,
  loading = false,
  allowClear = false,
  allowSearch = false,
}: SelectProps) {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const selectedValue = useMemo(() => {
    return options.find((option) => option.value === value)?.label ?? "";
  }, [options, value]);

  const showClear = allowClear && value != null;

  const displayOptions = useMemo(() => {
    if (allowSearch && searchTerm === "") {
      return options;
    }

    return options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [options, allowSearch, searchTerm]);

  return (
    <div className={styles.Select}>
      <SelectPrimitive.Root
        value={selectedValue}
        onValueChange={onChange}
        disabled={loading}
      >
        <SelectPrimitive.Trigger
          className={cx(styles.Trigger, styles[size], classes.trigger)}
        >
          <Flex
            width={"100%"}
            gap={"1"}
            align={"center"}
            className={styles.TriggerContent}
          >
            {(startIcon || loading) && (
              <div className={styles.Icon}>
                {!loading && startIcon}
                {loading && <Spinner />}
              </div>
            )}

            <Flex
              pr={showClear ? "6" : undefined}
              className={cx(styles.Value, classes.value)}
            >
              <Text truncate>
                <SelectPrimitive.Value
                  placeholder={placeholder}
                  aria-label={selectedValue}
                  className={"foooo"}
                >
                  {selectedValue}
                </SelectPrimitive.Value>
              </Text>
            </Flex>

            <SelectPrimitive.Icon className={styles.Icon}>
              <ChevronDownIcon />
            </SelectPrimitive.Icon>
          </Flex>
        </SelectPrimitive.Trigger>

        {showClear && (
          <IconButton
            color="gray"
            variant="ghost"
            highContrast
            size="1"
            onClick={(e) => {
              e.stopPropagation();
              onChange("");
            }}
            className={styles.ClearButton}
          >
            <Cross2Icon />
          </IconButton>
        )}

        <SelectPrimitive.Portal>
          <SelectPrimitive.Content
            position={"popper"}
            sideOffset={5}
            className={cx(styles.Content, "radix-themes")}
            {...radixThemeDataAttributes}
          >
            {allowSearch && (
              <SearchInput value={searchTerm} onChange={setSearchTerm} />
            )}

            <SelectPrimitive.Viewport className={styles.Viewport}>
              {displayOptions.length === 0 ? (
                <SelectItem value={"empty"} disabled>
                  No results found
                </SelectItem>
              ) : (
                <>
                  {displayOptions.map((option) => (
                    <SelectItem
                      key={option.value}
                      value={option.value}
                      selected={option.value === value}
                      disabled={option.disabled}
                    >
                      {option.label}
                    </SelectItem>
                  ))}
                </>
              )}
            </SelectPrimitive.Viewport>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    </div>
  );
}

export default Select;
