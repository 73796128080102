import { Flex, Skeleton, Text } from "@radix-ui/themes";
import { ArrowDownIcon, ArrowUpIcon } from "@radix-ui/react-icons";
import { EmptyCell, getValue } from "../explore/CellGenerator";
import { ColumnType } from "../explore/ExploreTable";
import { KnowledgeGapTicketsTrend } from "../knowledge-gaps/knowledge-gaps-table.types";

export interface ArticleRow {
  articleId: string;
  title: string;
  status: string;
  ticketTrend: number;
  relatedTickets?: number | null;
  url: string;
}

export const KnowledgeGapsColumns: ColumnType<keyof ArticleRow>[] = [
  {
    key: "title",
    label: "Articles",
    minWidth: "50%",
  },
  {
    key: "ticketTrend",
    label: "Ticket Trend",
  },
];

export function articlesCellGenerator(
  values: ArticleRow,
  { key }: ColumnType<string | number | symbol>,
  groupBy?:
    | "topics"
    | "categories"
    | "teams"
    | "agents"
    | "customers"
    | "tenantUser"
): JSX.Element {
  if (key === "title") {
    return (
      <Flex direction={"column"} align={"start"} gap={"0"}>
        <Text size={"2"} weight={"regular"}>
          {getValue(values, key as keyof ArticleRow)}
        </Text>
        {values.relatedTickets === undefined ? (
          <Text size={"2"} color="gray" weight={"regular"}>
            <Skeleton>related tickets in selected period</Skeleton>
          </Text>
        ) : (
          <Text size={"2"} color="gray" weight={"regular"}>
            {values.relatedTickets} related tickets in selected period
          </Text>
        )}
      </Flex>
    );
  }

  if (key === "ticketTrend") {
    return values.relatedTickets === undefined ? (
      <Skeleton>ticket trend</Skeleton>
    ) : (
      <ArticleTicketsTrend ticketTrend={values.ticketTrend} />
    );
  }

  const value = getValue(values, key as keyof ArticleRow);
  return <>{value != null ? value : <EmptyCell />}</>;
}

export function articlesCellValueGetter(
  values: ArticleRow,
  key: keyof ArticleRow
): string | number | boolean | undefined {
  if (key === "title") {
    return getValue(values, "relatedTickets") ?? undefined;
  }

  return getValue(values, key) ?? undefined;
}

export const ArticleTicketsTrend = ({
  ticketTrend,
}: {
  ticketTrend: number;
}) => {
  return (
    <Flex align={"center"}>
      <Text size={"2"} weight={"regular"} style={{ width: "32px" }}>
        {Math.abs(ticketTrend)}
      </Text>
      {ticketTrend > 0 ? (
        <ArrowUpIcon />
      ) : ticketTrend < 0 ? (
        <ArrowDownIcon />
      ) : (
        <></>
      )}
    </Flex>
  );
};
