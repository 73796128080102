import dayjs from "dayjs";
import {
  ResponsiveContainer,
  CartesianGrid,
  YAxis,
  XAxis,
  BarChart,
  Bar,
  Tooltip,
} from "recharts";
import { SharedReportValues } from "../model";

type SharedReportTicketCountInRangeProps = Pick<
  SharedReportValues,
  "metadata" | "dailyTicketCount"
>;

const SharedReportTicketCountInRange = ({
  metadata: { dateFrom, dateTo },
  dailyTicketCount,
}: SharedReportTicketCountInRangeProps) => {
  const generateXAxisTicks = (
    dateFrom: string,
    dateTo: string,
    dailyTicketCount: { createdAtDate: string; count: number }[]
  ) => {
    const startDate = new Date(dateFrom);
    const endDate = new Date(dateTo);
    const ticks = [];
    const dailyCountMap = new Map(
      dailyTicketCount?.map((d) => [d.createdAtDate.split("T")[0], d.count])
    );

    for (
      let date = new Date(startDate);
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      const dateString = date.toISOString().split("T")[0];
      ticks.push({
        date: date.getTime(),
        count: dailyCountMap.get(dateString) || 0,
      });
    }

    return ticks;
  };

  const formattedData = generateXAxisTicks(dateFrom, dateTo, dailyTicketCount);

  const BarLabel = (props: any) => {
    const { x, y, value } = props;
    if (value > 0) {
      return (
        <text
          x={x}
          y={y}
          dx={"1.5%"}
          dy={"-1%"}
          fontSize="11"
          textAnchor="middle"
        >
          {value}
        </text>
      );
    } else return null;
  };

  return (
    <ResponsiveContainer width={"100%"} height={195}>
      <BarChart
        width={900}
        height={195}
        margin={{
          top: 15,
          right: 15,
          left: 15,
          bottom: 0,
        }}
        data={formattedData}
      >
        <CartesianGrid vertical={false} />

        <YAxis
          tick={{ fontSize: 12 }}
          type={"number"}
          tickLine={false}
          axisLine={true}
          width={30}
        />

        <XAxis
          dataKey="date"
          tick={{ fontSize: 12 }}
          name={"Date"}
          tickFormatter={(value) => dayjs(value).format("MMM DD")}
          type={"category"}
          tickLine={false}
          axisLine={true}
        />

        <Bar
          dataKey="count"
          fill={"#0091FF"}
          barSize={17}
          label={<BarLabel />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SharedReportTicketCountInRange;
