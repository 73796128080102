import React, { useState } from "react";
import { createToast } from "vercel-toast";
import { Button, Flex, Popover, Text, TextArea } from "@radix-ui/themes";

import { InteractionInTrainingStatus } from "../types";
import { useCreateInteractionInTraining } from "../../../api/training";
import { InteractionType } from "../types";
import {
  mixTrackEvent,
  InteractionInformationEvents,
} from "../../../assets/mixpanel";

export const SendInteractionToTraining = ({
  interactionId,
  onSubmit = () => {},
  disableToast = false,
  refetch = () => {},
  interactionType = InteractionType.TICKET,
}: {
  interactionId: string;
  onSubmit?: ({ good, comment }: { good: boolean; comment?: string }) => void;
  disableToast?: boolean;
  refetch?: () => void;
  interactionType?: InteractionType;
}) => {
  const [comment, setComment] = useState("");
  const { mutateAsync: createInteractionInTraining } =
    useCreateInteractionInTraining();

  const handleSubmit = async () => {
    onSubmit({
      good: false,
      comment,
    });
    await createInteractionInTraining({
      interactionId,
      interactionType,
      status: InteractionInTrainingStatus.PENDING,
      reason: comment,
    });

    mixTrackEvent({
      event: InteractionInformationEvents.SENT_TICKET_TO_TRAINING,
      properties: {
        interactionId,
      },
    });

    if (!disableToast) {
      createToast(
        `${interactionType} sent to training, you can review it in the Automation page`,
        {
          type: "dark",
          timeout: 3000,
        }
      );
    }
    refetch();
  };

  return (
    <div>
      <Popover.Root>
        <Popover.Trigger>
          <Button color="gray" variant="outline" size={"1"}>
            Send to Training
          </Button>
        </Popover.Trigger>
        <Popover.Content
          minWidth="400px"
          maxWidth="400px"
          style={{ zIndex: 10000000011 }}
        >
          <Flex direction="column" gap="3">
            <Text as="label" size="2" weight="bold">
              Why this interaction needs improvement?
            </Text>
            <TextArea
              placeholder="e.g., Missing key details, wrong tone, or inaccurate information..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Flex justify="end" gap="3">
              <Popover.Close>
                <Button
                  style={{ cursor: "pointer" }}
                  color="gray"
                  variant="solid"
                  highContrast
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSubmit();
                  }}
                >
                  Submit
                </Button>
              </Popover.Close>
            </Flex>
          </Flex>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
};
