import { useCallback, useMemo } from "react";
import { Flex, Spinner } from "@radix-ui/themes";
import { ChatSettings } from "@/components/quack-chat/QuackChatConfig.model";
import { QuackChat } from "@/components/quack-chat/QuackChat";
import { useQuackchatConfiguration } from "@/api/quackchat/configuration/useQuackchatConfiguration";
import { InlineTextField } from "@/components/shared/inputs/inilie-text-field/InlineTextField";
import { FieldGroup } from "../parts/FieldGroup";
import { QuackchatSystemMessageConfiguration } from "./system-message/QuackchatSystemMessageConfiguration";
import {
  quackChatSystemMessageTypesMap,
  useGetQuackChatSystemMessage,
} from "@/api/quackchat/configuration/useQuackChatSystemMessageOverride";
import { Message } from "@/components/quack-chat/core/entities/Message";
import { AutomationConfigurationContentHeader } from "../parts/AutomationConfigurationContentHeader";

export function QuackchatChannelGeneralConfiguration() {
  const { configuration, isLoading, updateStatus, updateConfiguration } =
    useQuackchatConfiguration();

  const { data: welcomeMessage } = useGetQuackChatSystemMessage();

  const messages = useMemo<Message[] | undefined>(() => {
    if (welcomeMessage == null) {
      return;
    }

    return [
      {
        action: "standard_message",
        author: "AUTO_AGENT",
        text: welcomeMessage.message,
        deduplicationKey: "example_message",
        createdAt: "2021-09-01T12:00:00Z",
        data: {},
      },
    ];
  }, [welcomeMessage]);

  const updateChatSettings = useCallback(
    (chatSettings: Partial<ChatSettings>) => {
      if (configuration == null) {
        return;
      }

      updateConfiguration({
        ...configuration,
        chatSettings: {
          ...configuration.chatSettings,
          ...chatSettings,
        },
      });
    },
    [configuration, updateConfiguration]
  );

  if (isLoading || configuration == null) {
    return <Spinner />;
  }

  const { chatSettings } = configuration;

  return (
    <Flex gap={"24px"}>
      <Flex flexGrow={"1"} direction={"column"} gap={"24px"}>
        <AutomationConfigurationContentHeader
          title={"General"}
          status={updateStatus}
        />

        <Flex direction={"column"}>
          <FieldGroup horizontal description={"Chat Header"}>
            <InlineTextField
              value={chatSettings.name}
              onChange={(newValue) =>
                updateChatSettings({
                  name: newValue,
                })
              }
              placeholder={"Team"}
            />
          </FieldGroup>

          <FieldGroup horizontal description={"Quack Agent Name"}>
            <InlineTextField
              value={chatSettings?.quackAgentName}
              onChange={(newValue) =>
                updateChatSettings({
                  quackAgentName: newValue,
                })
              }
              placeholder={"AI Assistant"}
            />
          </FieldGroup>

          <FieldGroup horizontal description={"Place Holder Input"}>
            <InlineTextField
              value={chatSettings.placeholder}
              onChange={(newValue) =>
                updateChatSettings({
                  placeholder: newValue === "" ? undefined : newValue,
                })
              }
              placeholder={"Ask anything..."}
            />
          </FieldGroup>

          <FieldGroup
            label={quackChatSystemMessageTypesMap["WELCOME"].title}
            description={quackChatSystemMessageTypesMap["WELCOME"].description}
          >
            <QuackchatSystemMessageConfiguration
              config={configuration}
              defaultMessageType={"WELCOME"}
            />
          </FieldGroup>
        </Flex>
      </Flex>

      <Flex flexShrink={"0"}>
        <QuackChat config={configuration} messages={messages} />
      </Flex>
    </Flex>
  );
}
