import { Card, CardContent } from "@/components/ui/card";
import { OpportunityGroupPriorityValues } from "./index.types";
import { useOpportunityGroups } from "@/api/useOpportunityGroups";
import { OpportunityGroupRow } from "@/routes/knowledge-gaps/knowledge-gaps-table.types";
import dayjs from "dayjs";
import { Flex, Text } from "@radix-ui/themes";
import { useAuth0 } from "@auth0/auth0-react";
import { useWorkspace } from "@/routes/simulator/context/wrokspace";
import { useFeatureFlag } from "configcat-react";
import { opportunityStatuses } from "@/routes/knowledge-gaps/index.types";

const statusColumns = Object.values(opportunityStatuses);
const typeColors = {
  ["escalated_question"]: {
    color: "bg-blue-500/20",
    title: "Items Quack escalated to an agent",
  },
  ["unanswered_question"]: {
    color: "bg-blue-500/50",
    title: "Items Quack didn't know the answer to",
  },
  ["knowledge"]: {
    color: "bg-green-500/40",
    title: "Knowledge gaps Quack found",
  },
};

function OGBoard() {
  const { user } = useAuth0();
  const { workspace } = useWorkspace();

  const { value: opportunitiesGroupBoard } = useFeatureFlag(
    "opportunitiesGroupBoard",
    false,
    {
      identifier: workspace ?? user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );
  const { data: opportunityGroups, isLoading } = useOpportunityGroups({
    status: ["todo", "verify"],
    priority: ["critical", "high", "medium", "low"],
    startDate: dayjs().subtract(2, "months").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
    findBy: "",
    page: 1,
    enabled: true,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!opportunitiesGroupBoard) {
    return <div>Coming soon...</div>;
  }

  return (
    <div className="relative">
      <div className="top-0 right-0 p-2">
        <div className="flex flex-col space-y-1">
          {Object.entries(typeColors).map(([type, value]) => (
            <div key={type} className="flex items-center space-x-2">
              <span className={`w-4 h-4 rounded-full ${value.color}`}></span>
              <Text size={"2"} weight={"regular"}>
                {value.title}
              </Text>
            </div>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 p-4">
        {statusColumns.map((status) => (
          <Card key={status}>
            <CardContent className="p-4">
              <h3 className="text-lg font-semibold mb-4">
                {status.toUpperCase()}
              </h3>
              <div className="space-y-2">
                {(opportunityGroups as OpportunityGroupRow[])
                  ?.filter((item) => item.status === status)
                  .sort(
                    (a, b) =>
                      OpportunityGroupPriorityValues[b.priority] -
                      OpportunityGroupPriorityValues[a.priority]
                  )
                  .map((item) => (
                    <Flex
                      direction={"column"}
                      align={"start"}
                      gap={"0"}
                      className={`p-2 rounded-lg ${
                        typeColors[item.type as keyof typeof typeColors]
                          .color || "bg-gray-500"
                      }`}
                    >
                      <Text size={"2"} weight={"regular"}>
                        {item.label}
                      </Text>
                      {item.type === "knowledge" && (
                        <Text size={"2"} color="gray" weight={"regular"}>
                          {item.allTimeSpanTickets} related tickets
                        </Text>
                      )}
                      {["unanswered_question", "escalated_question"].includes(
                        item.type
                      ) && (
                        <Text size={"2"} color="gray" weight={"regular"}>
                          Ticket #{item.externalId.split("::")[1]}
                        </Text>
                      )}
                    </Flex>
                  ))}
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default OGBoard;
