import { Home, Layers, Settings, Users } from "@geist-ui/icons";
import { BsLightningCharge } from "react-icons/bs";
import { ReactNode } from "react";
import {
  BackpackIcon,
  EyeOpenIcon,
  FileTextIcon,
  RocketIcon,
  StackIcon,
  TokensIcon,
  VideoIcon,
  IdCardIcon,
  ChatBubbleIcon,
} from "@radix-ui/react-icons";
import { LightbulbIcon } from "lucide-react";

export enum ENavigate {
  HOME = "home",
  ASSESSMENT = "topics",
  TICKETS = "tickets",
  ACTIVATION = "activation",
  GETTING_STARTED = "getting-started",
  SETTINGS = "settings",
  KNOWLEDGE = "knowledge-management",
  KNOWLEDGE_VIEW = "knowledge-management/articles",
  KNOWLEDGE_GAPS = "knowledge-management/gaps",
  SAMPLING = "sampling",
  PERFORMANCE = "teams",
  VOC = "categories",
  SIMULATOR = "automation",
  SESSIONS_REPORTS = "sessions-reports",
  CUSTOMERS = "customers",
  QUALITY = "quality",
  EVALUATIONS = "quality/evaluations",
  VALIDATIONS = "quality/validations",
  COPILOT = "automation?f=copilot",
  AUTOPILOT = "automation?f=autopilot",
  EXPLORE_INSIGHTS = "explore/insights",
  EXPLORE_TICKETS = "explore/tickets",
  EXPLORE_CONVERSATIONS = "explore/conversations",
  REPORTS_ROOT = "reports",
  REPORTS_QUACK_HIRING = "reports/quack-hiring",
  REPORTS_AGENT_PERFORMANCE = "reports/agent-performance",
  REPORTS_TICKETS = "reports/tickets",
  REPORTS_CONVERSATIONS = "reports/conversations",
  COPILOT_FEEDBACK = "copilot-feedback",
  // REPORTS_PLAYGROUND = "reports/playground",
}

export const PagesIcons: Record<ENavigate, ReactNode> = {
  [ENavigate.HOME]: <Home />,
  [ENavigate.TICKETS]: <Layers />,
  [ENavigate.ACTIVATION]: <BsLightningCharge />,
  [ENavigate.GETTING_STARTED]: <>👉</>,
  [ENavigate.SETTINGS]: <Settings />,
  [ENavigate.SESSIONS_REPORTS]: <VideoIcon />,
  [ENavigate.CUSTOMERS]: <BackpackIcon />,
  [ENavigate.PERFORMANCE]: <Users />,
  [ENavigate.VOC]: <TokensIcon />,
  [ENavigate.ASSESSMENT]: <StackIcon />,
  [ENavigate.SAMPLING]: undefined,
  [ENavigate.SIMULATOR]: <RocketIcon />,
  [ENavigate.KNOWLEDGE]: <FileTextIcon />,
  [ENavigate.QUALITY]: <EyeOpenIcon />,
  [ENavigate.EVALUATIONS]: undefined,
  [ENavigate.VALIDATIONS]: undefined,
  [ENavigate.AUTOPILOT]: undefined,
  [ENavigate.COPILOT]: undefined,
  [ENavigate.KNOWLEDGE_GAPS]: undefined,
  [ENavigate.KNOWLEDGE_VIEW]: undefined,
  [ENavigate.EXPLORE_INSIGHTS]: <LightbulbIcon />,
  [ENavigate.EXPLORE_TICKETS]: <IdCardIcon />,
  [ENavigate.EXPLORE_CONVERSATIONS]: <ChatBubbleIcon />,
  [ENavigate.REPORTS_ROOT]: undefined,
  [ENavigate.REPORTS_QUACK_HIRING]: <>🐣</>,
  [ENavigate.REPORTS_AGENT_PERFORMANCE]: <>👨‍💻</>,
  [ENavigate.REPORTS_TICKETS]: <>🎟️</>,
  [ENavigate.REPORTS_CONVERSATIONS]: <>💬</>,
  [ENavigate.COPILOT_FEEDBACK]: <>🤖</>,
  // [ENavigate.REPORTS_PLAYGROUND]: <>🛝</>,
};
