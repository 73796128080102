import { useCallback, useMemo } from "react";
import { ChartConfig } from "@/components/ui/chart";
import { DateRange } from "@/components/shared/date-range-picker/DateRangePicker";
import {
  ChannelInteraction,
  ShareOfWorkValue,
  months,
  padMonths,
  sortAvgTimeMetric,
} from "@/api/reports/reports.model";
import { useQuackShareOfWork } from "@/api/reports/reports.api";
import { RadarChartItem, RadarChartComp } from "../../parts/RadarChart";
import WidgetCard from "../../parts/WidgetCard";

const NUMBER_OF_MONTHS = 6;

export type ShareOfWorkKeys = Exclude<keyof ShareOfWorkValue, "date">;

export const chartConfig: ChartConfig = {
  quackInteractionsAmount: {
    label: "Quack-Responded Interactions",
    color: "hsl(var(--cx-chart-1))",
  },
  nonQuackInteractionsAmount: {
    label: "Other Support Interactions",
    color: "hsl(var(--cx-chart-2-alpha))",
  },
};

interface ShareOfWorkWidgetProps {
  dateRange: DateRange;
  channels: ChannelInteraction[];
}

export function ShareOfWorkWidget({
  dateRange,
  channels,
}: ShareOfWorkWidgetProps) {
  const { data, isLoading, isError, refetch } = useQuackShareOfWork({
    ...dateRange,
    channels,
  });

  const chartData = useMemo<RadarChartItem<ShareOfWorkKeys>[]>(() => {
    if (data == null || data.shareOfWork.length === 0) {
      return [];
    }

    const shareOfWork = sortAvgTimeMetric(
      data.shareOfWork,
      months[new Date(dateRange.dateFrom).getMonth()]
    );

    if (shareOfWork.length < NUMBER_OF_MONTHS) {
      const firstMonth = shareOfWork[0].date;
      const prevMonths: ShareOfWorkValue[] = padMonths(
        firstMonth,
        NUMBER_OF_MONTHS - shareOfWork.length
      ).map((month) => ({
        date: month,
        quackInteractionsAmount: 0,
        nonQuackInteractionsAmount: 0,
      }));

      return [...prevMonths, ...shareOfWork].map((item) => ({
        ...item,
        month: item.date,
      }));
    }

    return shareOfWork.map((item) => ({
      ...item,
      month: item.date,
    }));
  }, [dateRange.dateFrom, data]);

  const handleReload = useCallback(() => {
    void refetch();
  }, []);

  return (
    <WidgetCard>
      <WidgetCard.Header>
        <WidgetCard.Title value={"Quack Share of Work"} />
        <WidgetCard.Description
          value={
            "Interactions where Quack was involved in resolving user inquiries vs. other support interactions"
          }
        />
      </WidgetCard.Header>

      <WidgetCard.Content
        isLoading={isLoading}
        isError={isError}
        isEmpty={chartData.length === 0}
        onReload={handleReload}
      >
        <RadarChartComp<ShareOfWorkKeys>
          config={chartConfig}
          data={chartData}
        />
      </WidgetCard.Content>
    </WidgetCard>
  );
}
