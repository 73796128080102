export declare class User {
  name?: string;
  given_name?: string;
  family_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  email_verified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  address?: string;
  updated_at?: string;
  sub?: string;
  [key: string]: any;
}

export type TInsight = {
  id: number;
  createdAt: Date;
  relevantQuote: string | null;
  sentiment: number | null;
  sentimentExplanation: string | null;
  brief: string;
  tldr: string;
  comment: {
    externalId: string;
  };

  tenantId: string;
  ticketId: string;
  commentId: string;
  conversationalInsight?: string;
  feedbacks?: IFeedbackItem[];
};

export interface IFeedbackItem {
  id: string;
  rank: number;
  type: ETicketsComponents;
  createdAt: string;
  insightID: string;
  author?: string;
  email?: string;
}

export enum ETicketsComponents {
  OVERVIEW = "overview",
  SUGGESTED_RESPONSE = "suggestedResponse",
  CUSTOMER_BRIEF = "customerBrief",
  ASK_QUESTION = "askQuestion",
  FEEDBACK = "feedback",
  INSTRUCTIONS = "instructions",
  CUSTOMER_HEALTH = "customerHealth",
  SESSION_VIDEO = "sessionVideo",
  FINDINGS = "findings",
  SIMILAR_TICKETS = "similarTickets",
  SIMILAR_ARTICLES = "similarArticles",
  SIMILAR_ISSUES = "relatedIssues",
  CONVERSATIONAL_INSIGHT = "conversational-insight",
}
