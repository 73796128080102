import React from "react";
import cx from "classnames";
import styles from "./styles.module.scss";

export const Loader = ({
  loading,
  smaller = false,
}: {
  loading: boolean;
  smaller?: boolean;
}) => {
  return (
    <div className={cx(styles.loader_container, { [styles.smaller]: smaller })}>
      <span
        className={cx(styles.loader, { [styles.hideLoader]: !loading })}
      ></span>
    </div>
  );
};
