import cx from "classnames";
import {
  JsonView as JsonViewLite,
  allExpanded,
  defaultStyles,
} from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import styles from "./JsonView.module.scss";

const styleProps = {
  ...defaultStyles,
  container: styles.container,
  basicChildStyle: styles.basicChildStyle,
  label: styles.label,
  stringValue: styles.stringValue,
  punctuation: styles.punctuation,
  collapsedContent: styles.collapsedContent,
  collapseIcon: cx(styles.icon, defaultStyles.collapseIcon),
  expandIcon: cx(styles.icon, defaultStyles.expandIcon),
};

interface JsonViewProps {
  data: Object | any[];
}

export function JsonView({ data }: JsonViewProps) {
  return (
    <JsonViewLite
      data={data}
      shouldExpandNode={allExpanded}
      style={styleProps}
    />
  );
}
