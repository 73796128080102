import { useCallback, useState } from "react";
import { Badge, Button, IconButton, Spinner, Text } from "@radix-ui/themes";
import { Cross1Icon, PlusIcon } from "@radix-ui/react-icons";
import { UploadQuackchatAssestResponse } from "@/api/quackchat/configuration/useQuackchatConfiguration";

export function FileUploadComponent({
  defaultFileName,
  onFileUpload,
  onSuccess,
}: {
  defaultFileName?: string;
  onFileUpload: (file: File) => Promise<UploadQuackchatAssestResponse>;
  onSuccess: (filePath: string) => void;
}) {
  const [fileName, setFileName] = useState<string | undefined>(defaultFileName);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const handleFileUpload = useCallback(
    async (file: File) => {
      try {
        setLoading(true);
        const res = await onFileUpload(file);

        onSuccess(res.path);
        setFileName(res.name);
      } catch (e) {
        setError("Failed to upload file, try again");
      } finally {
        setLoading(false);
      }
    },
    [onFileUpload, onSuccess]
  );

  const handleOnClicked = useCallback(async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.onchange = (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];

      if (file) {
        void handleFileUpload(file);
      }
    };

    input.click();
  }, [handleFileUpload]);

  const handleClear = useCallback(() => {
    setFileName(undefined);
    onSuccess("");
  }, [onSuccess]);

  if (fileName != null) {
    return (
      <Badge size="2" color="gray" style={{ maxWidth: "240px", gap: "8px" }}>
        <Text truncate>{fileName}</Text>

        <IconButton size="1" color="gray" variant="ghost" onClick={handleClear}>
          <Cross1Icon width="10" height="10" />
        </IconButton>
      </Badge>
    );
  }

  return (
    <>
      <Button
        size={"1"}
        color={"gray"}
        variant={"ghost"}
        highContrast
        onClick={handleOnClicked}
        style={{ minHeight: "18px" }}
      >
        <Spinner loading={loading} size={"1"}>
          <PlusIcon />
        </Spinner>

        {loading ? "Uploading ..." : "Upload"}
      </Button>

      {error && (
        <Text size={"1"} color={"red"}>
          {error}
        </Text>
      )}
    </>
  );
}
