"use client";

import React from "react";
import { useState, useContext } from "react";
import { ChevronDown, FileText } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "@/lib/utils";
import { Skeleton } from "@radix-ui/themes";
import { ThemeContext } from "@/components/Theme";

type AccordionItem = {
  title: string;
  content: string;
  bulletIcon?: React.ReactNode;
  actions: {
    button: React.ReactNode;
  };
};

interface DataAccordionProps {
  items: AccordionItem[];
  className?: string;
  loading?: boolean;
}

export default function DataAccordion({
  items,
  className,
  loading = false,
}: DataAccordionProps) {
  const { isDarkMode } = useContext(ThemeContext);

  if (loading) {
    return (
      <div
        className={cn(
          "rounded-lg border",
          isDarkMode
            ? "bg-gray-900 border-gray-800"
            : "bg-white border-gray-200",
          className
        )}
      >
        {new Array(3).fill(0).map((_, index) => (
          <div
            key={index}
            className={cn(
              "border-b last:border-b-0 overflow-hidden",
              isDarkMode ? "border-gray-800" : "border-gray-200"
            )}
          >
            <div className="flex items-center justify-between p-4">
              <div className="flex items-center gap-3">
                <Skeleton className="h-5 w-5" />
                <Skeleton className="h-5 w-40" />
              </div>
              <Skeleton className="h-5 w-5" />
            </div>
            <div className="px-4 pb-4 pt-0">
              <Skeleton className="h-3 w-full" />
            </div>
          </div>
        ))}
      </div>
    );
  }

  const [openItems, setOpenItems] = useState<number[]>([0]);

  const toggleItem = (index: number) => {
    setOpenItems((prevOpenItems) => {
      if (prevOpenItems.includes(index)) {
        return prevOpenItems.filter((i) => i !== index);
      } else {
        return [...prevOpenItems, index];
      }
    });
  };

  return (
    <div
      className={cn(
        "rounded-lg border",
        isDarkMode ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200",
        className
      )}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className={cn(
            "border-b last:border-b-0 overflow-hidden",
            isDarkMode ? "border-gray-800" : "border-gray-200"
          )}
        >
          <div
            className={cn(
              "flex items-center justify-between p-4 cursor-pointer",
              isDarkMode ? "hover:bg-gray-800" : "hover:bg-gray-50"
            )}
            onClick={() => toggleItem(index)}
          >
            <div className="flex items-center gap-3">
              {item.bulletIcon || (
                <FileText
                  className={cn(
                    "h-5 w-5",
                    isDarkMode ? "text-gray-400" : "text-gray-500"
                  )}
                />
              )}
              <h3
                className={cn(
                  "text-sm font-medium",
                  isDarkMode ? "text-gray-100" : "text-gray-900"
                )}
              >
                {item.title}
              </h3>
            </div>
            <motion.button
              className={isDarkMode ? "text-gray-400" : "text-gray-500"}
              animate={{ rotate: openItems.includes(index) ? 180 : 0 }}
              transition={{ duration: 0.2 }}
            >
              <ChevronDown className="h-5 w-5" />
            </motion.button>
          </div>

          <AnimatePresence>
            {openItems.includes(index) && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{
                  height: "auto",
                  opacity: 1,
                  transition: {
                    height: { duration: 0.3 },
                    opacity: { duration: 0.2, delay: 0.1 },
                  },
                }}
                exit={{
                  height: 0,
                  opacity: 0,
                  transition: {
                    height: { duration: 0.3 },
                    opacity: { duration: 0.2 },
                  },
                }}
              >
                <div className="px-4 pb-4 pt-0">
                  <div className="pl-8 pr-8">
                    <p
                      className={cn(
                        "mb-2.5 text-sm font-normal",
                        isDarkMode ? "text-gray-300" : "text-gray-600"
                      )}
                    >
                      {item.content}
                    </p>
                    <motion.div
                      initial={{ opacity: 0, y: 5 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: { delay: 0.2 },
                      }}
                      exit={{ opacity: 0, y: 5 }}
                      className={cn(
                        "inline-flex items-center mt-2 gap-2 rounded-md cursor-pointer",
                        isDarkMode ? "hover:bg-gray-800" : "hover:bg-gray-50"
                      )}
                    >
                      {Object.values(item.actions).map(
                        (action, actionIndex) => (
                          <React.Fragment key={actionIndex}>
                            {action}
                          </React.Fragment>
                        )
                      )}
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
}
