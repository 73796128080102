import { params } from "@/params";
import { useAuthorizedQuery } from "..";

export const useTicket = (ticketId: string, enabled: boolean) => {
  return useAuthorizedQuery({
    url: `${params.API_URL}/platform/tickets/${ticketId}`,
    queryKey: `getTicket-${ticketId}`,
    enabled,
  });
};

export const useInsights = (ticketId: string, enabled: boolean) => {
  return useAuthorizedQuery({
    url: `${params.API_URL}/platform/extension/tickets/${ticketId}/insights`,
    queryKey: `getTicketsInsightsAndSuggResposne-${ticketId}`,
    enabled,
  });
};
