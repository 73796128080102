import {
  IntegrationName,
  IntegrationType,
  supportedTicketSystems,
  supportedKnowledgeBase,
} from "../../../api/integration/Integration.model";

export interface AddIntegrationConfig {
  header: { title: string };
  select: {
    title?: string;
    description?: string;
    options: readonly IntegrationName[];
  };
}

export const addIntegrationConfigs: Record<
  IntegrationType,
  AddIntegrationConfig | null
> = {
  TICKET_SYSTEM: {
    header: {
      title: "Connect ticketing system",
    },
    select: {
      title: "Connect ticketing system",
      description: "Select your ticketing system so I can learn from real-world data",
      options: supportedTicketSystems,
    },
  },
  KNOWLEDGE_BASE: {
    header: {
      title: "Add knowledge sources",
    },
    select: {
      title: "Add knowledge sources",
      description: "Add knowledge sources to refine your AI and enrich your knowledge base",
      options: supportedKnowledgeBase,
    },
  },
  CHAT: null,
  TASK_MANAGEMENT: null,
  WIKI: null,
};
