import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Dot, Select, Text } from "@geist-ui/core";
import styles from "./styles.module.scss";
import { useReleases } from "../context/release";
import { useInstructions } from "../context/instructions";
import {
  ManualInstruction,
  ReleaseConversation,
  ReleaseConversationFeedback,
  TTopic,
} from "../types";
import { useGetTopics } from "../../../api/useGetTopics";
import cx from "classnames";
import {
  CheckInCircleFill,
  ThumbsDown,
  ThumbsUp,
  XCircleFill,
} from "@geist-ui/icons";
import { GeistDrawer } from "../../../components/geistDrawer";
import { Collapsable } from "../../../components/collapsable";
import { EBadgeMode, GeistBadge } from "../../../components/atoms/geistBadge";
import { SimulatorChannel } from "@/api/simulator/simulator.model";

type ExtendedManualInstruction = ManualInstruction & { topicId: number };

export const ReleaseDrawer = ({
  isOpen,
  setOpen,
  updatedGroupedInstructions,
  topicsNotEngaged,
  topicsToExclude,
  handleExcludeInclude,
  formattedReleasePolicy,
  topics,
}: {
  isOpen: boolean;
  setOpen: (v: boolean) => void;
  updatedGroupedInstructions: { [x: string]: ExtendedManualInstruction[] };
  topicsNotEngaged: string[];
  topicsToExclude: string[];
  handleExcludeInclude: (topic: string) => void;
  formattedReleasePolicy: any;
  topics: TTopic[] | undefined;
  channel?: SimulatorChannel;
}) => {
  return (
    <GeistDrawer
      openDrawer={isOpen}
      onDrawerClosed={() => setOpen(false)}
      headline={"Release"}
    >
      <Release
        closeDrawer={() => setOpen(false)}
        {...{
          updatedGroupedInstructions,
          topicsNotEngaged,
          topicsToExclude,
          handleExcludeInclude,
          formattedReleasePolicy,
          topics,
        }}
      />
    </GeistDrawer>
  );
};

const Release = ({
  closeDrawer,
  updatedGroupedInstructions,
  topicsNotEngaged,
  topicsToExclude,
  handleExcludeInclude,
  formattedReleasePolicy,
  topics,
}: {
  closeDrawer: () => void;
  updatedGroupedInstructions: { [x: string]: ExtendedManualInstruction[] };
  topicsNotEngaged: string[];
  topicsToExclude: string[];
  handleExcludeInclude: (topic: string) => void;
  formattedReleasePolicy: any;
  topics: TTopic[] | undefined;
}) => {
  const { releaseConversations, handlePublish } = useReleases();

  const { instructions } = useInstructions();

  const briefInstructions = useMemo(
    () => instructions.filter((x) => x.type === "brief"),
    [instructions]
  );

  return (
    <div className={styles.container}>
      <Text h4>Create a Release</Text>
      <Text small>
        Review all topics scheduled for release. Unreviewed topics are skipped
        automatically but can be added to the release
      </Text>

      <Collapsable
        initialCollapsedValue={false}
        title={
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            Engaged
            <GeistBadge>
              {
                Object.keys(updatedGroupedInstructions).filter(
                  (topic) => !topicsToExclude.includes(topic)
                ).length
              }
            </GeistBadge>
          </div>
        }
      >
        <div className={styles.policyOverviewList}>
          {Object.keys(updatedGroupedInstructions).map((key) => {
            return (
              <InstructionSummary
                key={key}
                groupedInstructions={updatedGroupedInstructions}
                k={key}
                releaseConversations={releaseConversations}
                isIncluded={
                  !topicsToExclude.includes(
                    updatedGroupedInstructions[key]?.[0]?.topic
                  )
                }
                handleExcludeInclude={handleExcludeInclude}
                // include in previous
                isPublished={Object.keys(formattedReleasePolicy).includes(key)}
                isModified={
                  JSON.stringify(formattedReleasePolicy[key]) !==
                  JSON.stringify(updatedGroupedInstructions[key])
                }
              />
            );
          })}
        </div>
      </Collapsable>
      <Collapsable
        initialCollapsedValue={true}
        title={
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            Not Engaged
            <GeistBadge>{topicsNotEngaged!.length}</GeistBadge>
          </div>
        }
      >
        <div className={styles.policyOverviewList}>
          {topicsNotEngaged.map((key: string) => {
            return (
              <InstructionSummary
                isEmpty
                key={key}
                groupedInstructions={updatedGroupedInstructions}
                k={key}
                releaseConversations={releaseConversations}
                isIncluded={!topicsToExclude.includes(key)}
                handleExcludeInclude={handleExcludeInclude}
                isPublished={Object.keys(formattedReleasePolicy).includes(key)}
                isModified={
                  JSON.stringify(formattedReleasePolicy[key]) !==
                  JSON.stringify(updatedGroupedInstructions[key])
                }
              />
            );
          })}
        </div>
      </Collapsable>
      <div className={styles.callout}>
        Need to release to specific channel or a segment? Contact Support
      </div>
      <div className={styles.bottom}>
        <div className={styles.countStat}>
          <span>{topicsToExclude.length}</span>
          <div className={styles.skip}>
            <XCircleFill />
            Skip
          </div>
        </div>
        <div className={styles.countStat}>
          <span>{topics!.length - topicsToExclude.length}</span>
          <div className={styles.stage}>
            <CheckInCircleFill />
            Staged for release
          </div>
        </div>

        <Button
          type="secondary"
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          width={2}
          onClick={() => {
            const generalInstructions = instructions.filter(
              (x) => x.type === "general"
            );

            let engagedInstructionsToRelease: ManualInstruction[] = [];
            // eslint-disable-next-line array-callback-return
            Object.keys(updatedGroupedInstructions).map((topic) => {
              if (!topicsToExclude.includes(topic)) {
                engagedInstructionsToRelease = [
                  ...engagedInstructionsToRelease,
                  ...updatedGroupedInstructions[topic],
                ];
              }
            });

            const topicsToInclude = topics
              ?.filter(
                (t: { topic: string }) => !topicsToExclude.includes(t.topic)
              )
              .map((t: { topic: string }) => t.topic);

            let notEngagedInstructionsToRelease: ManualInstruction[] = [];
            // eslint-disable-next-line array-callback-return
            topicsNotEngaged.map((topic: string) => {
              if (topicsToInclude?.includes(topic)) {
                notEngagedInstructionsToRelease.push({
                  content: "Think carefully before answering",
                  topic: topic,
                  subTopic: null,
                  type: "manual",
                });
              }
            });

            const filteredBriefInstructions = briefInstructions.filter(
              (x) => x.topic && !topicsToExclude.includes(x.topic)
            );

            handlePublish(
              [
                ...generalInstructions,
                ...filteredBriefInstructions,
                ...engagedInstructionsToRelease,
                ...notEngagedInstructionsToRelease,
              ],
              closeDrawer
            );
          }}
        >
          Release
        </Button>
      </div>
    </div>
  );
};

const InstructionSummary = ({
  k,
  groupedInstructions,
  releaseConversations,
  isIncluded,
  handleExcludeInclude,
  isEmpty = false,
  isPublished = false,
  isModified = false,
}: {
  k: string;
  groupedInstructions: Record<string, ExtendedManualInstruction[]>;
  releaseConversations: ReleaseConversation[];
  isIncluded: boolean;
  handleExcludeInclude: (topic: string) => void;
  isEmpty?: boolean;
  isPublished?: boolean;
  isModified?: boolean;
}) => {
  const feedbackSummary = useMemo(() => {
    const summary = { good: 0, bad: 0, total: 0 };
    if (isEmpty) {
      return summary;
    }
    releaseConversations
      ?.filter(
        (conversation: ReleaseConversation) =>
          conversation.topicId === groupedInstructions[k][0].topicId
      )
      .forEach((conversation: ReleaseConversation) => {
        summary.total += 1;
        if (conversation.feedback === ReleaseConversationFeedback.POSITIVE) {
          summary.good += 1;
        } else if (
          conversation.feedback === ReleaseConversationFeedback.NEGATIVE
        ) {
          summary.bad += 1;
        }
      });
    return summary;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseConversations]);

  return (
    <Card key={k} className={cx(styles.instructionsCard)}>
      <div>
        <Text h6>
          {k}

          <Dot
            className={
              isPublished ? styles.dotPublished : styles.dotNotReleased
            }
            style={{ marginLeft: "20px", fontSize: "10px" }}
            type="success"
          >
            {isPublished ? " Live" : " Not released yet"}
          </Dot>
        </Text>
        {isModified && (
          <GeistBadge mode={isPublished ? EBadgeMode.PURPLE : EBadgeMode.BLUE}>
            {isPublished ? "Modified" : "Addedd"}
          </GeistBadge>
        )}
      </div>
      <div>
        <div className={styles.informationContainer}>
          {isEmpty ? (
            <div style={{ display: "flex", gap: "10px" }}>
              <Text small>Not engaged yet</Text>
            </div>
          ) : (
            <div className={styles.stats}>
              <div className={styles.countStat}>
                <span>{feedbackSummary.total}</span>
                <Text small>Conversatin Engaged</Text>
              </div>
              <div className={styles.countStat}>
                <span>
                  {
                    groupedInstructions[k].filter(
                      (x) => x.content !== "Think carefully before answering"
                    ).length
                  }
                </span>
                <Text small>Instructions</Text>
              </div>
              <div className={styles.countStat}>
                <span className={styles.negative}>{feedbackSummary.bad}</span>
                <Text small>
                  {" "}
                  <ThumbsDown /> bad
                </Text>
              </div>
              <div className={styles.countStat}>
                <span className={styles.positive}>{feedbackSummary.good}</span>
                <Text small>
                  <ThumbsUp /> good
                </Text>
              </div>
            </div>
          )}
        </div>
        <div className={styles.cta}>
          <Select
            value={isIncluded ? "include" : "exclude"}
            dropdownClassName={styles.dropdown}
            className={styles.select}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            onChange={(value) => {
              handleExcludeInclude(k);
            }}
          >
            <Select.Option value="include">
              <div className={styles.stage}>
                <CheckInCircleFill />
                Staged for release
              </div>
            </Select.Option>
            <Select.Option value="exclude">
              <div className={styles.skip}>
                <XCircleFill />
                Skip
              </div>
            </Select.Option>
          </Select>
        </div>
      </div>
    </Card>
  );
};
