import { useCallback, useState } from "react";
import { Flex, Text, Button, Spinner, Grid } from "@radix-ui/themes";
import RadioGroup from "../../../../components/shared/inputs/radio-group";
import Checkbox from "../../../../components/shared/inputs/checkbox";
import {
  BrandResponse,
  IntegrationName,
} from "../../../../api/integration/Integration.model";
import { StepHeader, StepMain } from "../parts";
import { integrationNameToGalleryItem } from "../../../../api/integration/integrationNameToGalleryItem";
import styles from "../AddIntegration.module.scss";

type ServerErrors = {
  autoSync?: string;
  include?: string;
  general?: string;
};

type SyncMethod = "all" | "custom";

function ZendeskConfigureBrands({
  brands,
  onSubmit,
  onBack,
}: ConfigureBrandsProps) {
  const [syncMethod, setSyncMethod] = useState<SyncMethod>("all");
  const [payload, setPayload] = useState<string[]>(brands.map(({ id }) => id));
  const [serverErrors, setServerErrors] = useState<ServerErrors>();
  const [loading, setLoading] = useState(false);

  const handleSyncMethodChanged = useCallback(
    (newValue: SyncMethod) => {
      setPayload(brands.map(({ id }) => id));
      setSyncMethod(newValue);
    },
    [brands]
  );

  const handleBrandSelectionChanged = useCallback(
    (brandId: string, include: boolean) => {
      if (include) {
        setPayload((prevState) => {
          return [...(prevState ?? []), brandId];
        });
      } else {
        setPayload((prevState) => {
          return (prevState ?? []).filter((id) => id !== brandId);
        });
      }
    },
    []
  );

  const handleSubmit = useCallback(async () => {
    try {
      setServerErrors(undefined);
      setLoading(true);

      await onSubmit(payload);
    } catch (error) {
      setServerErrors({
        general: "An unknown error occurred. Please contact support.",
      });
    } finally {
      setLoading(false);
    }
  }, [onSubmit, payload]);

  return (
    <div className={styles.Form}>
      <div className={styles.FieldList}>
        <div className={styles.FieldControl}>
          <Text size={"1"} weight={"medium"} as={"label"}>
            Sync method
          </Text>

          <RadioGroup
            value={syncMethod}
            onChange={(newValue) =>
              handleSyncMethodChanged(newValue as SyncMethod)
            }
            options={[
              { value: "all", label: "Sync all brand" },
              { value: "custom", label: "Custom" },
            ]}
          />

          {syncMethod === "custom" && (
            <Grid
              pt={"10px"}
              pl={"15px"}
              columns="2"
              gap="2"
              display="inline-grid"
              width={"100%"}
              height={"fit-content"}
            >
              <Text size={"2"} weight={"medium"}>{`${brands.length} Brand${
                brands.length > 1 ? "s" : ""
              }`}</Text>

              <Flex justify={"end"} align={"center"}>
                {payload.length === 0 ? (
                  <Button
                    color="gray"
                    variant="ghost"
                    highContrast
                    size="1"
                    onClick={() => setPayload(brands.map(({ id }) => id))}
                  >
                    Select all
                  </Button>
                ) : (
                  <Button
                    color="gray"
                    variant="ghost"
                    highContrast
                    size="1"
                    onClick={() => setPayload([])}
                  >
                    Unselect all
                  </Button>
                )}
              </Flex>

              {brands.map((brand) => (
                <Checkbox
                  label={brand.name}
                  value={payload.includes(brand.id)}
                  onChange={(checked) =>
                    handleBrandSelectionChanged(brand.id, checked)
                  }
                />
              ))}
            </Grid>
          )}
        </div>

        {serverErrors?.general != null && (
          <Text size={"1"} weight={"medium"} color={"red"}>
            {serverErrors.general}
          </Text>
        )}
      </div>

      <div className={styles.Footer}>
        <Button
          color="gray"
          variant="solid"
          highContrast
          onClick={handleSubmit}
        >
          <Spinner loading={loading} />
          Continue
        </Button>

        <Button
          color="gray"
          variant="soft"
          highContrast
          type="button"
          onClick={onBack}
        >
          Back
        </Button>
      </div>
    </div>
  );
}

interface ConfigureBrandsProps {
  name: IntegrationName;
  brands: BrandResponse[];
  onSubmit: (brandIds: string[]) => Promise<void>;
  onBack: () => void;
}

function ConfigureBrands({
  name,
  brands,
  onSubmit,
  onBack,
}: ConfigureBrandsProps) {
  const title = `Configure brands to ${integrationNameToGalleryItem[name].title}`;

  return (
    <StepMain>
      <Flex pt={"35px"} px={"45px"}>
        <StepHeader title={title} />
      </Flex>

      <Flex flexGrow={"1"} overflow={"hidden"}>
        {name === "zendesk" && (
          <ZendeskConfigureBrands
            brands={brands}
            onSubmit={onSubmit}
            onBack={onBack}
            name={"zendesk"}
          />
        )}
      </Flex>
    </StepMain>
  );
}

export default ConfigureBrands;
