import { useCallback, useState } from "react";
import { Button, Spinner } from "@radix-ui/themes";
import { TicketExploreRow } from "@/api/useExplore/Explore.model";
import { useValidateTicket, ValidateTicketRequest } from "@/api/useValidations";
import { mixTrackEvent, QualityEvents } from "@/assets/mixpanel";
import { TicketScore } from "./TicketQAScore.model";
import { QuestionList } from "./QuestionList";
import styles from "./TicketQAScore.module.scss";

interface TicketQuestionsScoreProps {
  ticket: TicketExploreRow;
  ticketScore?: TicketScore[];
  onUpdateTicketScore: () => Promise<unknown>;
  allowValidateTicket?: boolean;
  viewOnly?: boolean;
  avgQAScore?: number;
}

function TicketQuestionsScore({
  ticket,
  ticketScore = [],
  onUpdateTicketScore,
  allowValidateTicket = false,
  viewOnly = false,
  avgQAScore,
}: TicketQuestionsScoreProps) {
  const { mutateAsync } = useValidateTicket();

  const [isLoading, setIsLoading] = useState(false);

  const handleValidateTicket = useCallback(async () => {
    if (ticket == null) {
      return;
    }

    const req: ValidateTicketRequest = {
      ticketId: ticket.id,
      isValidated: true,
    };

    setIsLoading(true);
    await mutateAsync(req);
    await onUpdateTicketScore();
    setIsLoading(false);
    mixTrackEvent({
      event: QualityEvents.VALIDATED,
      properties: {
        ticket: ticket.id,
      },
    });
  }, [ticket, mutateAsync, onUpdateTicketScore]);

  return (
    <div className={styles.Questions}>
      <QuestionList
        ticket={ticket}
        ticketScore={ticketScore}
        onUpdateTicketScore={onUpdateTicketScore}
        viewOnly={viewOnly}
        avgQAScore={avgQAScore}
      />

      {allowValidateTicket && (
        <div className={styles.Footer}>
          <Button
            size={"2"}
            variant={"solid"}
            color={"gray"}
            onClick={handleValidateTicket}
            highContrast={!isLoading}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Spinner loading={isLoading} />
                Validating...
              </>
            ) : (
              <>Mark as Validated</>
            )}
          </Button>
        </div>
      )}
    </div>
  );
}

export default TicketQuestionsScore;
