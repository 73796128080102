import { useCallback, useEffect, useMemo, useState } from "react";
import { SelectOption } from "../../components/shared/select";
import {
  useGetAgentGroups,
  useGetAgentGroupAgents,
} from "../../api/agent-group";
import {
  AutoResponseFallbackAgent,
  useGetAutoResponseFallbackAgent,
  useUpdateAutoResponseConfig,
} from "../../api/auto-config";
import { UpdateStatus } from "./utils/automation-configuration.utils";

type Value = {
  method: "specific" | "unassigned";
  group?: string;
  agent?: string;
};

function buildValue(
  autoResponseFallbackAgent?: AutoResponseFallbackAgent
): Value {
  if (autoResponseFallbackAgent?.type === "group") {
    return {
      method: "specific",
      group: autoResponseFallbackAgent.externalId,
      agent: undefined,
    };
  } else if (autoResponseFallbackAgent?.type === "agent") {
    return {
      method: "specific",
      group: autoResponseFallbackAgent.groupId,
      agent: autoResponseFallbackAgent.externalId,
    };
  } else {
    return {
      method: "unassigned",
      group: undefined,
      agent: undefined,
    };
  }
}

export function useUpdateAutoResponseFallbackAgent() {
  const {
    data: autoResponseFallbackAgent,
    isLoading: isAutoResponseFallbackAgentLoading,
    isError: isAutoResponseFallbackAgentError,
    refetch: refetchAutoResponseFallbackAgent,
  } = useGetAutoResponseFallbackAgent();
  const { mutateAsync: updateAutoResponseConfig } =
    useUpdateAutoResponseConfig();

  const { data: groups, isLoading: loadinGroups } = useGetAgentGroups();

  const [localValue, setLocalValue] = useState<Value>({ method: "unassigned" });
  const [status, setStatus] = useState<UpdateStatus>("idle");

  const { data: agents, isLoading: loadingAgents } = useGetAgentGroupAgents(
    localValue.group
  );

  const groupsOptions = useMemo<SelectOption[] | undefined>(() => {
    return groups?.map(({ externalId, name }) => ({
      value: externalId,
      label: name ?? externalId,
    }));
  }, [groups]);

  const agentOptions = useMemo<SelectOption[] | undefined>(() => {
    return agents?.map(({ externalId, name }) => ({
      value: externalId,
      label: name ?? externalId,
    }));
  }, [agents]);

  useEffect(() => {
    setLocalValue(buildValue(autoResponseFallbackAgent));
  }, [autoResponseFallbackAgent]);

  const onChange = useCallback(
    async (autoResponseFallbackAgent: string | null) => {
      setStatus("saving");
      const res = await updateAutoResponseConfig({
        autoResponseFallbackAgent,
      });

      if (res != null) {
        await refetchAutoResponseFallbackAgent();
      }

      setStatus("saved");
    },
    [refetchAutoResponseFallbackAgent, updateAutoResponseConfig]
  );

  useEffect(() => {
    if (status === "saved") {
      const timer = setTimeout(() => setStatus("idle"), 2000);
      return () => clearTimeout(timer);
    }
  }, [status]);

  return useMemo(
    () => ({
      groupsOptions,
      loadinGroups,
      agentOptions,
      loadingAgents,
      value: localValue,
      onMethodChange: (method: string) => {
        if (method === "unassigned" || method === "specific") {
          setLocalValue({ method });

          if (method === "unassigned") {
            void onChange(null);
          }
        }
      },
      onGroupChange: (group: string) => {
        setLocalValue({ method: "specific", group });
        void onChange(group);
      },
      onAgentChange: (agent: string) => {
        setLocalValue((prevState) => ({ ...prevState, agent }));
        void onChange(agent);
      },
      isAutoResponseFallbackAgentError,
      isAutoResponseFallbackAgentLoading,
      refetchAutoResponseFallbackAgent,
      status,
    }),
    [
      groupsOptions,
      loadinGroups,
      agentOptions,
      loadingAgents,
      localValue,
      onChange,
      isAutoResponseFallbackAgentError,
      isAutoResponseFallbackAgentLoading,
      refetchAutoResponseFallbackAgent,
      status,
    ]
  );
}
