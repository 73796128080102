import { ReactNode } from "react";
import cx from "classnames";
import { ChevronRightIcon, DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Button } from "@radix-ui/themes";
import styles from "./Navbar.module.scss";

export interface NavbarItem {
  title: string;
  onClick?: () => void;
  svg?: ReactNode;
}

function NavbarItemLabel({ title, svg }: Pick<NavbarItem, "title" | "svg">) {
  return (
    <label className={styles.Label}>
      {svg}
      {title}
    </label>
  );
}

interface NavbarProps {
  variant?: "default" | "lite";
  items: NavbarItem[];
}

export function Navbar({ variant = "default", items }: NavbarProps) {
  if (variant === "lite") {
    if (items.length > 3) {
      return (
        <div className={cx(styles.Navbar, styles.isLite)}>
          <NavbarItemLabel title={items[0].title} svg={items[0].svg} />
          <ChevronRightIcon />

          <DotsHorizontalIcon />

          <ChevronRightIcon />
          <NavbarItemLabel
            title={items[items.length - 2].title}
            svg={items[0].svg}
          />

          <ChevronRightIcon />
          <NavbarItemLabel
            title={items[items.length - 1].title}
            svg={items[0].svg}
          />
        </div>
      );
    }

    return (
      <div className={cx(styles.Navbar, styles.isLite)}>
        {items.map(({ title, svg }, index) => (
          <>
            <NavbarItemLabel key={title} title={title} svg={svg} />

            {index < items.length - 1 && <ChevronRightIcon />}
          </>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.Navbar}>
      {items.map(({ title, svg, onClick }, index) => {
        return (
          <>
            {onClick != null ? (
              <Button
                key={title}
                color="gray"
                variant="ghost"
                highContrast
                size="1"
                onClick={onClick}
                className={styles.Button}
              >
                {title}
              </Button>
            ) : (
              <NavbarItemLabel key={title} title={title} svg={svg} />
            )}

            {index < items.length - 1 && <ChevronRightIcon />}
          </>
        );
      })}
    </div>
  );
}
