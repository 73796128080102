import { params } from "../../params";

export const fetchConversations = async (tenant: string) => {
  const response = await fetch(
    `${params.API_URL}/sessions-api/chat-session/tenants/${tenant}/sessions`,
    {
      headers: {
        "x-api-key": params.INTERNAL_TOKEN,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};
export const fetchConversationById = async (
  tenant: string,
  converationId: string
) => {
  const response = await fetch(
    `${params.API_URL}/sessions-api/chat-session/tenants/${tenant}/sessions/${converationId}`,
    {
      headers: {
        "x-api-key": params.INTERNAL_TOKEN,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};
