export enum KnowledgeEvents {
  CREATE_DRAFT_ARTICLE = "knowledge_create_draft_article",
  COPY_DRAFT_ARTICLE = "knowledge_copy_draft_article",
  ARTICLE_VIEWED = "knowledge_article_viewed",
  ARTICLE_GENERATED = "knowledge_article_generated",
  IGNORE_GAP = "knowledge_ignore_gap",
  GAP_REVIEWED = "knowledge_gap_reviewed",
  GAP_STATUS_CHANGED = "knowledge_gap_status_changed",
  GAPS_FILTER_CHANGED = "knowledge_gaps_filter_changed",
  GAP_RELATED_TICKETS_VIEWED = "knowledge_gap_related_tickets_viewed",
  FAQ_COPIED = "knowledge_faq_copied",
  ALL_FAQ_COPIED = "knowledge_all_faq_copied",
  FAQ_FEEDBACK_SUBMITTED = "knowledge_faq_feedback_submitted",
  ARTICLES_FILTER_CHANGED = "knowledge_articles_filter_changed",
  ARTICLE_REVIEWED = "knowledge_article_reviewed",
}
