import { useMemo } from "react";
import cx from "classnames";
import { Flex, Text, ScrollArea, Avatar } from "@radix-ui/themes";
import * as Tabs from "@radix-ui/react-tabs";
import { buildColorMap } from "@/components/radixColors";
import { QAScore } from "@/routes/explore/cells";
import styles from "./TicketQAScore.module.scss";

export interface QuestionListTabItem {
  value: string;
  title: string;
  publicCommentsCount?: number;
  avgQaScore?: number;
  totalAdjusted?: number;
}

interface QuestionListTabsProps {
  tabs: QuestionListTabItem[];
  value: string;
  onChange: (value: string) => void;
}

export function QuestionListTabs({
  tabs,
  value,
  onChange,
}: QuestionListTabsProps) {
  const colorMap = useMemo(() => {
    return buildColorMap(
      tabs.filter((tab) => tab.value !== "ticket").map((tab) => tab.title)
    );
  }, [tabs]);

  return (
    <Tabs.Root
      className={styles.QuestionListTabs}
      value={value}
      onValueChange={onChange}
    >
      <ScrollArea scrollbars={"horizontal"}>
        <Tabs.List className={styles.List}>
          {tabs.map((tab) => {
            const words = tab.title.split(" ").map((x) => x[0]);
            const initials =
              words.length === 1
                ? words[0]
                : [words[0], words[words.length - 1]].join("");
            const color = colorMap[tab.title] ?? "gray";

            return (
              <Tabs.Trigger
                key={tab.value}
                className={cx(styles.Trigger, {
                  [styles.hasAvatar]: tab.value !== "ticket",
                })}
                value={tab.value}
              >
                <Flex
                  width={"100%"}
                  gap={"2"}
                  align={"start"}
                  justify={"between"}
                >
                  <Flex gap={"3"} align={"center"}>
                    {tab.value !== "ticket" && (
                      <Avatar
                        size={"2"}
                        fallback={initials}
                        color={color}
                        radius={"full"}
                      />
                    )}

                    <Flex direction={"column"}>
                      <Text
                        size={"2"}
                        color={"gray"}
                        weight={"medium"}
                        highContrast
                        truncate
                      >
                        {tab.title}
                      </Text>

                      {(tab.publicCommentsCount ?? 0) > 0 && (
                        <Text size={"2"} color={"gray"}>
                          {tab.publicCommentsCount} Public Messages
                        </Text>
                      )}
                    </Flex>
                  </Flex>

                  {tab.avgQaScore != null && (
                    <Text size={"2"}>
                      <QAScore value={tab.avgQaScore} />
                    </Text>
                  )}
                </Flex>
              </Tabs.Trigger>
            );
          })}
        </Tabs.List>
      </ScrollArea>
    </Tabs.Root>
  );
}
