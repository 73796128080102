import { useCallback } from "react";
import Select, { SelectOption } from "@/components/shared/select";
import { FieldGroup } from "../parts/FieldGroup";
import styles from "../AutomationConfiguration.module.scss";

const interactionLimitOptions: SelectOption[] = [
  ...[...Array(6)].map((_, i) => ({
    label: `${i + 1} user intreaction${i > 0 ? "s" : ""}`,
    value: (i + 1).toString(),
  })),
  { label: "Unlimited", value: "unlimited" },
];

interface InteractionLimitFieldProps {
  value?: number | "unlimited";
  onChange: (newValue: number | "unlimited") => void;
}

export function InteractionLimitField({
  value,
  onChange,
}: InteractionLimitFieldProps) {
  const handleOnChange = useCallback(
    (newValue: string) => {
      onChange(newValue === "unlimited" ? "unlimited" : parseInt(newValue));
    },
    [onChange]
  );

  return (
    <FieldGroup
      label={"Interaction limit"}
      description={
        "Set the maximum number of user interactions before escalations ."
      }
    >
      <Select
        options={interactionLimitOptions}
        value={value?.toString()}
        onChange={handleOnChange}
        classes={{ trigger: styles.Select }}
      />
    </FieldGroup>
  );
}
