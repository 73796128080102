export const integrationTypes = [
  "TICKET_SYSTEM",
  "KNOWLEDGE_BASE",
  "WIKI",
  "CHAT",
  "TASK_MANAGEMENT",
] as const;

export type IntegrationType = (typeof integrationTypes)[number];

export const ticketSystemIntegrationNames = [
  "zendesk",
  "front",
  "freshdesk",
  "salesforce",
  "hubspot",
  "intercom",
  "kustomer",
  "jira",
  // "gladly",
] as const;

export type TicketSystemIntegrationName =
  (typeof ticketSystemIntegrationNames)[number];

export function isTicketSystemIntegrationName(
  obj: any
): obj is TicketSystemIntegrationName {
  return ticketSystemIntegrationNames.includes(obj);
}

export const knowledgeBaseIntegrationNames = [
  "gitbook",
  "document360",
  "notion",
  "confluence",
  "zendesk",
] as const;

type KnowledgeBaseIntegrationName =
  (typeof knowledgeBaseIntegrationNames)[number];

export function isKnowledgeBaseIntegrationName(
  obj: any
): obj is KnowledgeBaseIntegrationName {
  return knowledgeBaseIntegrationNames.includes(obj);
}

export const taskManagementIntegrationNames = [
  "jira",
] as const;

type TaskManagementIntegrationName =
  (typeof taskManagementIntegrationNames)[number];

export function isTaskManagementIntegrationName(
  obj: any
): obj is TaskManagementIntegrationName {
  return taskManagementIntegrationNames.includes(obj);
}

export const chatIntegrationNames = [
  "slack",
] as const;

type ChatIntegrationName = (typeof chatIntegrationNames)[number];

export function isChatIntegrationName(obj: any): obj is ChatIntegrationName {
  return chatIntegrationNames.includes(obj);
}

export type IntegrationName =
  | TicketSystemIntegrationName
  | KnowledgeBaseIntegrationName
  | ChatIntegrationName
  | TaskManagementIntegrationName;

export function isIntegrationName(obj: any): obj is IntegrationName {
  return (
    isTicketSystemIntegrationName(obj) ||
    isKnowledgeBaseIntegrationName(obj) ||
    isChatIntegrationName(obj) || 
    isTaskManagementIntegrationName(obj)
  );
}

export type SecretValue = {
  email: string;
  token: string;
  tokenType: "API";
};

interface BaseConnectIntegrationRequest {
  type: IntegrationType;
  name: IntegrationName;
  secretValue?: SecretValue;
  subdomain?: string;
  isNangoAuth?: boolean;
}

export interface TicketSystemConnectIntegrationRequest
  extends BaseConnectIntegrationRequest {
  type: "TICKET_SYSTEM";
  name: TicketSystemIntegrationName;
}

export interface KnowledgeBaseConnectIntegrationRequest
  extends BaseConnectIntegrationRequest {
  type: "KNOWLEDGE_BASE" | "WIKI";
  name: KnowledgeBaseIntegrationName;
  isConfigurationRequired?: boolean;
}

export interface ChatConnectIntegration extends BaseConnectIntegrationRequest {
  type: "CHAT";
  name: ChatIntegrationName;
}

export interface TaskManagementConnectIntegration extends BaseConnectIntegrationRequest {
  type: "TASK_MANAGEMENT";
  name: TaskManagementIntegrationName;
}

export type ConnectIntegrationRequest =
  | TicketSystemConnectIntegrationRequest
  | KnowledgeBaseConnectIntegrationRequest
  | ChatConnectIntegration
  | TaskManagementConnectIntegration;

export type ConnectIntegrationFormPayload = SecretValue & {
  subdomain?: string;
};

export type TenantGroup = { id: number; name: string };

export type BrandResponse = {
  name: string;
  subdomain: string;
  id: string;
}

export interface TicketSystemConnectIntegrationResponse {
  groups: TenantGroup[];
}

export function isTicketSystemConnectIntegrationResponse(
  res: any
): res is TicketSystemConnectIntegrationResponse {
  return res != null && typeof res === "object" && res.hasOwnProperty("groups");
}

export type KnowledgeBaseConnectIntegrationResponse = void;

export type ConnectIntegrationResponse =
  | TicketSystemConnectIntegrationResponse
  | KnowledgeBaseConnectIntegrationResponse;

interface BaseCreateIntegrationRequest {
  type: IntegrationType;
  name: IntegrationName;
}

export type GroupsPolicy = {
  autoSync: boolean;
  include: number[];
};

export interface TicketSystemCreateIntegrationRequest
  extends BaseCreateIntegrationRequest {
  type: "TICKET_SYSTEM";
  name: TicketSystemIntegrationName;
  groupsPolicy: GroupsPolicy;
}

export interface KnowledgeBaseCreateIntegrationRequest
  extends BaseCreateIntegrationRequest {
  type: "KNOWLEDGE_BASE";
  name: KnowledgeBaseIntegrationName;
}

export type CreateIntegrationRequest =
  | TicketSystemCreateIntegrationRequest
  | KnowledgeBaseCreateIntegrationRequest;

export const supportedTicketSystems: TicketSystemIntegrationName[] = [
  "zendesk",
  "front",
  "intercom",
];

export const existingTicketSystems: TicketSystemIntegrationName[] = [
  "zendesk",
  "front",
  "intercom",
  "freshdesk",
  "salesforce",
  "hubspot",
  "jira",
];

export const supportedKnowledgeBase: KnowledgeBaseIntegrationName[] = [
  "zendesk",
  "gitbook",
  "document360",
  "notion",
  "confluence",
];

export const supportedChat: ChatIntegrationName[] = ["slack"];

export const supportedTaskManagement: TaskManagementIntegrationName[] = [
  "jira",
];

const integrationSetupStatuses = [
  "COMPLETED",
  "IN_PROGRESS",
  "NOT_AVAILABLE",
  "UNDEFINED",
] as const;
type IntegrationSetupStatus = (typeof integrationSetupStatuses)[number];

export interface Integration {
  id?: string;
  type: IntegrationType;
  name: IntegrationName;
  status?: IntegrationSetupStatus;
  metadata?: string;
}

export interface TicketSystemIntegration extends Integration {
  type: "TICKET_SYSTEM";
  name: TicketSystemIntegrationName;
}

export function isTicketSystemIntegration(
  obj: any
): obj is TicketSystemIntegration {
  return obj != null && typeof obj === "object" && obj.type === "TICKET_SYSTEM";
}

export function isIntegrationCompleted(integration: Integration) {
  return integration.status === "COMPLETED";
}

export function isIntegrationNotAvailable(integration: Integration) {
  return integration.status === "NOT_AVAILABLE";
}

export const nangoSupportedIntegrationNames = [
  "slack",
  "notion",
  "confluence",
  "salesforce",
  "jira",
  // "intercom",
  // "kustomer",
  // "front",
] as const;

export type NangoSupportedIntegrationName =
  (typeof nangoSupportedIntegrationNames)[number];

export function isNangoSupportedIntegrationName(
  obj: any
): obj is NangoSupportedIntegrationName {
  return nangoSupportedIntegrationNames.includes(obj);
}
