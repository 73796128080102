import { Button, Flex, Text } from "@radix-ui/themes";
import { Dialog } from "../../../components/shared/dialog";
import { IntegrationName } from "../../../api/integration/Integration.model";
import { integrationNameToGalleryItem } from "../../../api/integration/integrationNameToGalleryItem";
import styles from "./AddIntegration.module.scss";
import HeadingSVG from "@/routes/onboarding/getting-started/assets/HeadingSVG";

interface CommingSoonDialogProps {
  name: IntegrationName;
  onClose: () => void;
}

function CommingSoonDialog({ name, onClose }: CommingSoonDialogProps) {
  const { title } = integrationNameToGalleryItem[name];

  return (
    <Dialog open onOpenChange={(open) => (open ? undefined : onClose())}>
      <Dialog.Content size={"small"}>
        <Flex
          p={"25px"}
          direction={"column"}
          gap={"5px"}
          className={styles.CommingSoon}
        >
          <Dialog.Close className={styles.Close} />

          <Flex justify={"center"}>
            <HeadingSVG />
          </Flex>

          <Text
            size={"4"}
            weight={"medium"}
          >{`${title} integration is coming soon!`}</Text>

          <Text size={"2"} color={"gray"}>
            {`We're working hard to bring you ${title}!  Stay ahead of the curve and be the first to know when it's ready!`}
          </Text>

          <Flex pt={"15px"} direction={"row-reverse"} gap={"5px"}>
            <Button color="gray" variant="solid" highContrast>
              Notify me when available
            </Button>

            <Button color="gray" variant="soft" highContrast onClick={onClose}>
              Close
            </Button>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog>
  );
}

export default CommingSoonDialog;
