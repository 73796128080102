import React from "react";
import {
  Opportunity,
  OpportunityStatus,
  useChangeStatusToHidden,
} from "../../api/useOpportunities";
import Tooltip from "@geist-ui/core/esm/tooltip";
import { Button } from "@radix-ui/themes";
import { useAuth0 } from "@auth0/auth0-react";

import styles from "./styles.module.scss";
import { KnowledgeEvents, mixTrackEvent } from "@/assets/mixpanel";

export const OpportunityComponent = ({
  opportunity,
  refetch,
}: {
  opportunity: Opportunity;
  refetch: () => void;
}) => {
  const { user } = useAuth0();

  const { mutateAsync: changeStatusToHidden, isLoading: loadingHidden } =
    useChangeStatusToHidden({
      opportunityId: +opportunity.id,
    });

  return (
    <tr>
      <td className={styles.number}>
        {opportunity.transaction.tickets.length}
      </td>
      <td className={styles.opportunityLabel}>
        <Tooltip
          text={
            <div>
              {opportunity.transaction.commonTransactionEval.explanation}
            </div>
          }
        >
          {opportunity.label}
        </Tooltip>
      </td>
      <td className={styles.ignoreButton}>
        <Button
          color="gray"
          variant="ghost"
          onClick={() =>
            changeStatusToHidden({
              status: OpportunityStatus.HIDDEN,
              userEmail: user?.email,
            }).then(() => {
              refetch();
              mixTrackEvent({
                event: KnowledgeEvents.IGNORE_GAP,
                properties: {
                  opportunityId: opportunity.id,
                  userEmail: user?.email,
                },
              });
            })
          }
        >
          Ignore
        </Button>
      </td>
    </tr>
  );
};
