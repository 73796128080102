import { Flex, Text } from "@radix-ui/themes";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckCircledIcon,
  CircleIcon,
  MinusCircledIcon,
  PlusCircledIcon,
  DoubleArrowUpIcon,
  DashIcon,
} from "@radix-ui/react-icons";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { EmptyCell, getValue } from "../explore/CellGenerator";
import { ColumnType } from "../explore/ExploreTable";
import { toCamelCaseWithFirstLetterCaps } from "@/assets/functions";
import React, { JSXElementConstructor, ReactElement } from "react";
import { RadixColor } from "@/components/radixColors";
import { ChevronUp } from "@geist-ui/icons";
import {
  OpportunitiesGroupType,
  OpportunityGroupPriority,
  OpportunityStatus,
} from "./index.types";
import styles from "./styles.module.scss";

export interface OpportunityGroupRow {
  id: string;
  key: string;
  label: string;
  status: OpportunityStatus;
  currentTimeSpanTickets: number;
  compareTimeSpanTickets: number;
  allTimeSpanTickets: number;
  priority: OpportunityGroupPriority;
  type: OpportunitiesGroupType;
  externalId: string;
}

export const KnowledgeGapsColumns: ColumnType<keyof OpportunityGroupRow>[] = [
  {
    key: "label",
    label: "Knowledge Gaps",
    minWidth: "50%",
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "currentTimeSpanTickets",
    label: "Ticket Trend",
  },
  {
    key: "priority",
    label: "Priority",
  },
];

export function knowledgeGapsCellGenerator(
  values: OpportunityGroupRow,
  { key }: ColumnType<keyof OpportunityGroupRow>
): JSX.Element {
  if (key === "label") {
    const allTimeSpanTickets = getValue(values, "allTimeSpanTickets");
    const currentTimeSpanTickets = getValue(values, "currentTimeSpanTickets");
    return (
      <Flex direction={"column"} align={"start"} gap={"0"}>
        <Text size={"2"} weight={"regular"}>
          {getValue(values, key)}
        </Text>
        <Text size={"2"} color="gray" weight={"regular"}>
          {allTimeSpanTickets} related tickets,{" "}
          {currentTimeSpanTickets === 0 ? "none" : currentTimeSpanTickets} in
          selected period
        </Text>
      </Flex>
    );
  }

  if (key === "status") {
    switch (getValue(values, key)) {
      case "backlog":
        return (
          <KnowledgeStatusCell
            label={toCamelCaseWithFirstLetterCaps(
              String(getValue(values, key))
            )}
            icon={<QuestionMarkCircledIcon />}
          />
        );
      case "hidden":
        return (
          <KnowledgeStatusCell
            label="Ignored"
            color="gray"
            icon={<MinusCircledIcon />}
          />
        );
      case "todo":
        return <KnowledgeStatusCell label="To Verify" icon={<CircleIcon />} />;
      case "verify":
        return (
          <KnowledgeStatusCell label="Verified" icon={<CheckCircledIcon />} />
        );
      case "solved":
        return (
          <KnowledgeStatusCell
            label="Solved"
            color="green"
            icon={<CheckCircledIcon />}
          />
        );
    }
  }

  if (key === "currentTimeSpanTickets") {
    const ticketTrend =
      getValue(values, key) - getValue(values, "compareTimeSpanTickets");

    return <KnowledgeGapTicketsTrend ticketTrend={ticketTrend} />;
  }

  if (key === "priority") {
    return <KnowledgeGapPriority priority={getValue(values, key)} />;
  }

  const value = getValue(values, key);
  return <>{value != null ? value : <EmptyCell />}</>;
}

const KnowledgeStatusCell = ({
  label,
  color,
  icon,
}: {
  label: string;
  color?: RadixColor;
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
}) => {
  return (
    <Flex align={"center"} gap={"2"}>
      {React.cloneElement(icon, { color })}
      <Text size={"2"} weight={"regular"} color={color}>
        {label}
      </Text>
    </Flex>
  );
};

export function knowledgeGapsCellValueGetter(
  values: OpportunityGroupRow,
  key: keyof OpportunityGroupRow
) {
  if (key === "priority") {
    const priorityOrder = {
      critical: 5,
      high: 4,
      medium: 3,
      low: 2,
    } as const;
    const priority = getValue(values, key) as keyof typeof priorityOrder;
    return priorityOrder[priority] || 1;
  }

  if (key === "status") {
    const statusOrder = {
      todo: 6,
      verify: 5,
      solved: 4,
      hidden: 3,
      backlog: 2,
    } as const;
    const status = getValue(values, key) as keyof typeof statusOrder;
    return statusOrder[status] || 1;
  }

  if (key === "currentTimeSpanTickets") {
    return getValue(values, key) - getValue(values, "compareTimeSpanTickets");
  }

  if (key === "label") {
    return getValue(values, "allTimeSpanTickets");
  }

  return getValue(values, key);
}

export const KnowledgeGapPriority = ({
  priority,
  label = false,
}: {
  priority: OpportunityGroupPriority;
  label?: boolean;
}) => {
  const labelComponent = (text: string) => (
    <Text size={"2"} weight={"regular"}>
      {text}
    </Text>
  );
  switch (priority) {
    case "critical":
      return (
        <Flex align={"center"} gap={"1"}>
          <DoubleArrowUpIcon
            color="purple"
            style={{ minHeight: "18px", minWidth: "18px" }}
          />
          {label && labelComponent("Critical priority")}
        </Flex>
      );
    case "high":
      return (
        <Flex align={"center"} gap={"1"}>
          <DoubleArrowUpIcon
            color="red"
            className={styles.iconPriorityHigh}
            style={{ minHeight: "18px", minWidth: "18px" }}
          />
          {label && labelComponent("High priority")}
        </Flex>
      );
    case "medium":
      return (
        <Flex align={"center"} gap={"1"}>
          <ChevronUp color="orange" />
          {label && labelComponent("Medium priority")}
        </Flex>
      );
    case "low":
      return (
        <Flex align={"center"} gap={"1"}>
          <ChevronUp color="gray" />
          {label && labelComponent("Low priority")}
        </Flex>
      );
    default:
      return (
        <Flex align={"center"} gap={"1"}>
          <DashIcon />
          {label && labelComponent("None priority")}
        </Flex>
      );
  }
};

export const KnowledgeGapTicketsTrend = ({
  ticketTrend,
}: {
  ticketTrend: number;
}) => {
  return (
    <Flex align={"center"}>
      <Text size={"2"} weight={"regular"} style={{ width: "32px" }}>
        {Math.abs(ticketTrend)}
      </Text>
      {ticketTrend > 0 ? (
        <ArrowUpIcon />
      ) : ticketTrend < 0 ? (
        <ArrowDownIcon />
      ) : (
        <></>
      )}
    </Flex>
  );
};
