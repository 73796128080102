import { useQuackResolution } from "@/api/reports/reports.api";
import WidgetCard from "../../parts/WidgetCard";
import { DateRange } from "@/components/shared/date-range-picker/DateRangePicker";
import { Flex, Grid, Spinner, Text } from "@radix-ui/themes";
import { Cell, Pie, PieChart, Sector, Tooltip } from "recharts";
import { TicketResolutionReponse } from "@/api/reports/reports.model";
import { useState } from "react";
import { ChartLegend } from "@/components/ui/chart";

const labelMapping: { [x: string]: string } = {
  solved: "Quack Resolved",
  involved: "Quack Responded & Escalated",
  escalated: "Auto-Escalated",
};

export function AvgConversationResolution({
  dateRange,
  channel,
}: {
  dateRange: DateRange;
  channel: string;
}) {
  const { data, isLoading, isError, refetch } = useQuackResolution({
    ...dateRange,
    channel,
  });
  const handleReload = () => refetch();
  const COLORS = ["#5BB98C", "#ED8A5C", "#E5484D"];

  const object = {
    solved: data?.quackSolvedAlone,
    involved: data?.quackInvolved,
    escalated: data?.quackSkippedOrEscalated,
  };

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(null);
  };

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 10}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />

        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${payload.name
          .replace("-", " ")
          .split(" ")
          .slice(0, 2)
          .join(" ")} ${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };
  return (
    <WidgetCard fullWidth>
      <WidgetCard.Header>
        <WidgetCard.Title value={"Total Conversations "} />
        <WidgetCard.Description
          value={"Total number of customer support conversations"}
        />
      </WidgetCard.Header>

      <WidgetCard.Content
        isLoading={isLoading}
        isError={isError}
        isEmpty={false}
        onReload={handleReload}
      >
        <Grid columns="2" rows={"repeat(1, auto)"} width={"100%"}>
          <Flex
            px={"7"}
            py={"5"}
            direction={"column"}
            gap={"9"}
            align={"center"}
            justify={"center"}
          >
            <Flex
              align={"center"}
              justify={"center"}
              className={isLoading ? "py-[6px]" : ""}
            >
              {isLoading ? (
                <Spinner size={"2"} />
              ) : (
                <>
                  {data?.totalInteraction ? (
                    <Text
                      size={"6"}
                      color={"gray"}
                      weight={"medium"}
                      highContrast
                    >
                      {data.totalInteraction}
                    </Text>
                  ) : (
                    <Text size={"2"} color={"gray"}>
                      No data available yet
                    </Text>
                  )}
                </>
              )}
            </Flex>
          </Flex>
          <Flex>
            {data && (
              <PieChart width={600} height={300}>
                <Pie
                  data={
                    data
                      ? Object.entries(object).map(([key, value]) => {
                          return {
                            name: labelMapping[key],
                            value: value,
                          };
                        })
                      : []
                  }
                  activeShape={renderActiveShape}
                  activeIndex={activeIndex ?? 0}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#151519"
                  onMouseEnter={onPieEnter}
                  onMouseLeave={onPieLeave}
                >
                  {Object.entries(object).map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>

                <ChartLegend iconSize={6} fontSize={6} height={20} />
              </PieChart>
            )}
          </Flex>
        </Grid>
      </WidgetCard.Content>
    </WidgetCard>
  );
}
