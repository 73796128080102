import { PropsWithChildren } from "react";
import cx from "classnames";
import { Box, Em, Flex, Text } from "@radix-ui/themes";
import Switch from "@/components/shared/inputs/switch";
import styles from "../AutomationConfiguration.module.scss";

interface FieldGroupProps {
  label?: string;
  description?: string;
  horizontal?: boolean;
  error?: string;
}

export function FieldGroup({
  label,
  description,
  horizontal = false,
  error,
  children,
}: PropsWithChildren<FieldGroupProps>) {
  return (
    <Flex gap={"2"} direction={"column"} className={styles.FieldGroupWrapper}>
      <Box
        className={cx(styles.FieldGroup, {
          [styles.horizontal]: horizontal,
        })}
      >
        {(label != null || description != null) && (
          <Flex direction={"column"} gap={"1"}>
            {label != null && (
              <Text size={"2"} as={"label"}>
                {label}
              </Text>
            )}

            {description != null && (
              <Text size={"2"} color={"gray"}>
                <Em>{description}</Em>
              </Text>
            )}
          </Flex>
        )}

        <Flex className={styles.FieldControl}>{children}</Flex>
      </Box>

      {error != null && (
        <Text size={"2"} color={"red"}>
          <Em>{error}</Em>
        </Text>
      )}
    </Flex>
  );
}

interface OptionalFieldGroupProps extends FieldGroupProps {
  enabled: boolean;
  toggleEnabled: (nextValue: boolean) => void;
}

export function OptionalFieldGroup({
  label,
  description,
  horizontal = false,
  error,
  enabled,
  toggleEnabled,
  children,
}: PropsWithChildren<OptionalFieldGroupProps>) {
  const handleToggle = () => toggleEnabled(!enabled);

  return (
    <Flex gap={"2"} direction={"column"} className={styles.FieldGroupWrapper}>
      <Box
        className={cx(styles.FieldGroup, {
          [styles.horizontal]: horizontal,
        })}
      >
        <Flex justify={"between"} gap={"3"}>
          {(label != null || description != null) && (
            <Flex direction={"column"} gap={"1"}>
              {label != null && (
                <Text size={"2"} as={"label"}>
                  {label}
                </Text>
              )}

              {description != null && (
                <Text size={"2"} color={"gray"}>
                  <Em>{description}</Em>
                </Text>
              )}
            </Flex>
          )}

          <Flex flexShrink={"0"}>
            <Switch value={enabled} onChange={handleToggle} />
          </Flex>
        </Flex>

        <Flex className={styles.FieldControl}>{children}</Flex>
      </Box>

      {error != null && (
        <Text size={"2"} color={"red"}>
          <Em>{error}</Em>
        </Text>
      )}
    </Flex>
  );
}
