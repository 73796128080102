export function ExploreConversationsSVG() {
  return (
    <svg
      width="380"
      height="170"
      viewBox="0 0 380 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="24"
        y="31"
        width="32"
        height="32"
        rx="8"
        fill="url(#paint0_linear_4590_56322)"
        fill-opacity="0.1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.8019 39.0204C37.5121 39.0204 36.3611 39.6155 35.609 40.5458C35.1232 41.1457 34.8039 41.8854 34.7195 42.6946L34.7195 42.6946H32.9336H32.9336H32.9277L32.9333 42.7008C32.9295 42.7531 32.9277 42.8057 32.9277 42.859C32.9277 44.1477 33.9701 45.1928 35.2578 45.1973C35.4221 45.4779 35.619 45.7374 35.8432 45.9706C36.5901 46.7469 37.6394 47.2302 38.8019 47.2302C39.5443 47.2302 40.2408 47.033 40.8417 46.6881C42.0754 45.9803 42.9064 44.6497 42.9064 43.1253C42.9064 40.8582 41.0687 39.0204 38.8019 39.0204ZM37.0627 41.3626C37.1447 41.3374 37.2319 41.3239 37.3222 41.3239C37.8121 41.3239 38.2089 41.7207 38.2089 42.2103C38.2089 42.6047 37.9518 42.9385 37.5962 43.0537C37.51 43.0817 37.4177 43.097 37.3222 43.097C36.8326 43.097 36.4358 42.7001 36.4358 42.2103C36.4358 41.811 36.6998 41.4733 37.0627 41.3626ZM46.3398 51.2587C44.8992 54.1264 42.1969 54.9796 39.9778 54.9796C37.7588 54.9796 33.7224 53.0245 33.7224 49.7624C33.7224 46.7514 35.8107 45.9824 35.8432 45.9706C36.5901 46.7469 37.6394 47.2303 38.8019 47.2303C39.5443 47.2303 40.2408 47.0331 40.8417 46.6881C41.6488 47.2005 42.8237 47.7198 44.0011 47.4385C46.107 46.9358 46.6204 45.5288 46.6204 45.5288C47.3223 47.7257 47.186 49.5745 46.3398 51.2587ZM46.6955 47.2949C46.4187 50.6674 43.6254 53.3168 40.2204 53.3168C37.8937 53.3168 35.8529 52.0796 34.705 50.2204C35.9819 51.688 37.8529 52.6142 39.9377 52.6142C43.1967 52.6142 45.933 50.3519 46.6955 47.2949Z"
        fill="#25262B"
      />
      <path
        d="M66 39C66 34.5817 69.5817 31 74 31H267C271.418 31 275 34.5817 275 39V47C275 51.4183 271.418 55 267 55H74C69.5817 55 66 51.4183 66 47V39Z"
        fill="url(#paint1_linear_4590_56322)"
        fill-opacity="0.1"
      />
      <rect
        x="141"
        y="81"
        width="173"
        height="24"
        rx="8"
        fill="#FFC53D"
        fill-opacity="0.1"
      />
      <rect
        x="324"
        y="73"
        width="32"
        height="32"
        rx="8"
        fill="#FFC53D"
        fill-opacity="0.1"
      />
      <path
        d="M333.573 94.2393C332.83 94.2393 332.183 94.123 331.632 93.8906C331.08 93.6536 330.645 93.3255 330.326 92.9062C330.007 92.487 329.825 92.0016 329.779 91.4502L329.772 91.3613H331.283L331.29 91.4365C331.322 91.7327 331.438 91.9902 331.639 92.209C331.844 92.4277 332.117 92.5986 332.459 92.7217C332.805 92.8447 333.2 92.9062 333.642 92.9062C334.056 92.9062 334.425 92.8402 334.749 92.708C335.073 92.5758 335.326 92.3936 335.508 92.1611C335.695 91.9287 335.788 91.6621 335.788 91.3613V91.3545C335.788 90.9762 335.645 90.6618 335.357 90.4111C335.07 90.1605 334.601 89.9645 333.949 89.8232L332.869 89.5977C331.871 89.3835 331.144 89.0439 330.688 88.5791C330.233 88.1097 330.005 87.5104 330.005 86.7812V86.7744C330.009 86.2048 330.162 85.7035 330.463 85.2705C330.768 84.8376 331.19 84.5003 331.728 84.2588C332.265 84.0173 332.883 83.8965 333.58 83.8965C334.277 83.8965 334.886 84.0173 335.405 84.2588C335.929 84.4958 336.342 84.8193 336.643 85.2295C336.948 85.6396 337.119 86.1022 337.155 86.6172L337.162 86.7061H335.665L335.651 86.6172C335.61 86.3483 335.499 86.109 335.316 85.8994C335.139 85.6898 334.899 85.5257 334.599 85.4072C334.302 85.2842 333.956 85.2249 333.56 85.2295C333.177 85.2295 332.835 85.2887 332.534 85.4072C332.233 85.5212 331.994 85.6875 331.816 85.9062C331.643 86.125 331.557 86.3893 331.557 86.6992V86.7061C331.557 87.0706 331.696 87.376 331.974 87.6221C332.256 87.8636 332.714 88.0527 333.348 88.1895L334.428 88.4287C335.116 88.5745 335.674 88.7682 336.103 89.0098C336.531 89.2513 336.843 89.5498 337.039 89.9053C337.24 90.2562 337.34 90.6755 337.34 91.1631V91.1699C337.34 91.7988 337.185 92.3434 336.875 92.8037C336.57 93.2594 336.134 93.6126 335.569 93.8633C335.004 94.1139 334.339 94.2393 333.573 94.2393ZM339.356 94V84.1357H341.127L344.265 91.9971H344.381L347.519 84.1357H349.289V94H347.888V86.7676H347.129L348.729 84.4092L344.887 94H343.752L339.917 84.4092L341.51 86.7676H340.758V94H339.356Z"
        fill="#E7B95E"
      />
      <path
        d="M24 123C24 118.582 27.5817 115 32 115H225C229.418 115 233 118.582 233 123V131C233 135.418 229.418 139 225 139H32C27.5817 139 24 135.418 24 131V123Z"
        fill="url(#paint2_linear_4590_56322)"
        fill-opacity="0.1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4590_56322"
          x1="24"
          y1="63"
          x2="57.7326"
          y2="60.6715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3EDDDD" />
          <stop offset="1" stop-color="#F9D40F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4590_56322"
          x1="66"
          y1="55"
          x2="228.608"
          y2="-42.7473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3EDDDD" />
          <stop offset="1" stop-color="#F9D40F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4590_56322"
          x1="24"
          y1="139"
          x2="186.608"
          y2="41.2527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3EDDDD" />
          <stop offset="1" stop-color="#F9D40F" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function DarkExploreConversationsSVG() {
  return (
    <svg
      width="380"
      height="170"
      viewBox="0 0 380 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="24"
        y="31"
        width="32"
        height="32"
        rx="8"
        fill="url(#paint0_linear_5032_84910)"
        fill-opacity="0.1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.8038 39.0204C37.5141 39.0204 36.363 39.6155 35.6109 40.5458C35.1252 41.1457 34.8058 41.8854 34.7214 42.6946L34.7214 42.6946H32.9356H32.9356H32.9297L32.9352 42.7008C32.9314 42.7531 32.9297 42.8057 32.9297 42.859C32.9297 44.1477 33.9721 45.1928 35.2598 45.1973C35.4241 45.4779 35.6209 45.7374 35.8451 45.9706C36.5921 46.7469 37.6414 47.2302 38.8038 47.2302C39.5463 47.2302 40.2427 47.033 40.8437 46.6881C42.0774 45.9803 42.9084 44.6497 42.9084 43.1253C42.9084 40.8582 41.0706 39.0204 38.8038 39.0204ZM37.0647 41.3626C37.1467 41.3374 37.2338 41.3239 37.3241 41.3239C37.814 41.3239 38.2108 41.7207 38.2108 42.2103C38.2108 42.6047 37.9538 42.9385 37.5981 43.0537C37.512 43.0817 37.4196 43.097 37.3241 43.097C36.8346 43.097 36.4378 42.7001 36.4378 42.2103C36.4378 41.811 36.7017 41.4733 37.0647 41.3626ZM46.3418 51.2587C44.9011 54.1264 42.1988 54.9796 39.9798 54.9796C37.7607 54.9796 33.7244 53.0245 33.7244 49.7624C33.7244 46.7514 35.8126 45.9824 35.8451 45.9706C36.5921 46.7469 37.6414 47.2303 38.8038 47.2303C39.5463 47.2303 40.2427 47.0331 40.8437 46.6881C41.6508 47.2005 42.8257 47.7198 44.003 47.4385C46.1089 46.9358 46.6223 45.5288 46.6223 45.5288C47.3243 47.7257 47.188 49.5745 46.3418 51.2587ZM46.6974 47.2949C46.4206 50.6674 43.6273 53.3168 40.2223 53.3168C37.8957 53.3168 35.8548 52.0796 34.7069 50.2204C35.9839 51.688 37.8549 52.6142 39.9397 52.6142C43.1987 52.6142 45.9349 50.3519 46.6974 47.2949Z"
        fill="#EDEEF0"
      />
      <path
        d="M66 39C66 34.5817 69.5817 31 74 31H267C271.418 31 275 34.5817 275 39V47C275 51.4183 271.418 55 267 55H74C69.5817 55 66 51.4183 66 47V39Z"
        fill="url(#paint1_linear_5032_84910)"
        fill-opacity="0.1"
      />
      <rect
        x="141"
        y="81"
        width="173"
        height="24"
        rx="8"
        fill="#FFC53D"
        fill-opacity="0.1"
      />
      <rect
        x="324"
        y="73"
        width="32"
        height="32"
        rx="8"
        fill="#FFC53D"
        fill-opacity="0.1"
      />
      <path
        d="M333.573 94.2393C332.83 94.2393 332.183 94.123 331.632 93.8906C331.08 93.6536 330.645 93.3255 330.326 92.9062C330.007 92.487 329.825 92.0016 329.779 91.4502L329.772 91.3613H331.283L331.29 91.4365C331.322 91.7327 331.438 91.9902 331.639 92.209C331.844 92.4277 332.117 92.5986 332.459 92.7217C332.805 92.8447 333.2 92.9062 333.642 92.9062C334.056 92.9062 334.425 92.8402 334.749 92.708C335.073 92.5758 335.326 92.3936 335.508 92.1611C335.695 91.9287 335.788 91.6621 335.788 91.3613V91.3545C335.788 90.9762 335.645 90.6618 335.357 90.4111C335.07 90.1605 334.601 89.9645 333.949 89.8232L332.869 89.5977C331.871 89.3835 331.144 89.0439 330.688 88.5791C330.233 88.1097 330.005 87.5104 330.005 86.7812V86.7744C330.009 86.2048 330.162 85.7035 330.463 85.2705C330.768 84.8376 331.19 84.5003 331.728 84.2588C332.265 84.0173 332.883 83.8965 333.58 83.8965C334.277 83.8965 334.886 84.0173 335.405 84.2588C335.929 84.4958 336.342 84.8193 336.643 85.2295C336.948 85.6396 337.119 86.1022 337.155 86.6172L337.162 86.7061H335.665L335.651 86.6172C335.61 86.3483 335.499 86.109 335.316 85.8994C335.139 85.6898 334.899 85.5257 334.599 85.4072C334.302 85.2842 333.956 85.2249 333.56 85.2295C333.177 85.2295 332.835 85.2887 332.534 85.4072C332.233 85.5212 331.994 85.6875 331.816 85.9062C331.643 86.125 331.557 86.3893 331.557 86.6992V86.7061C331.557 87.0706 331.696 87.376 331.974 87.6221C332.256 87.8636 332.714 88.0527 333.348 88.1895L334.428 88.4287C335.116 88.5745 335.674 88.7682 336.103 89.0098C336.531 89.2513 336.843 89.5498 337.039 89.9053C337.24 90.2562 337.34 90.6755 337.34 91.1631V91.1699C337.34 91.7988 337.185 92.3434 336.875 92.8037C336.57 93.2594 336.134 93.6126 335.569 93.8633C335.004 94.1139 334.339 94.2393 333.573 94.2393ZM339.356 94V84.1357H341.127L344.265 91.9971H344.381L347.519 84.1357H349.289V94H347.888V86.7676H347.129L348.729 84.4092L344.887 94H343.752L339.917 84.4092L341.51 86.7676H340.758V94H339.356Z"
        fill="#E7B95E"
      />
      <path
        d="M24 123C24 118.582 27.5817 115 32 115H225C229.418 115 233 118.582 233 123V131C233 135.418 229.418 139 225 139H32C27.5817 139 24 135.418 24 131V123Z"
        fill="url(#paint2_linear_5032_84910)"
        fill-opacity="0.1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5032_84910"
          x1="24"
          y1="63"
          x2="57.7326"
          y2="60.6715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3EDDDD" />
          <stop offset="1" stop-color="#F9D40F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5032_84910"
          x1="66"
          y1="55"
          x2="228.608"
          y2="-42.7473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3EDDDD" />
          <stop offset="1" stop-color="#F9D40F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5032_84910"
          x1="24"
          y1="139"
          x2="186.608"
          y2="41.2527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3EDDDD" />
          <stop offset="1" stop-color="#F9D40F" />
        </linearGradient>
      </defs>
    </svg>
  );
}
