import { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { Flex, Grid, Spinner, Text, TextProps } from "@radix-ui/themes";
import { DateRange } from "@/components/shared/date-range-picker/DateRangePicker";
import { ChannelInteraction } from "@/api/reports/reports.model";
import {
  useCountQuackInteractions,
  useCountUsersInteractedWithQuack,
  useCountQuackMessages,
  useFirstRelease,
} from "@/api/reports/reports.api";
import WidgetCard from "../parts/WidgetCard";
import styles from "../Reports.module.scss";

interface GeneralItemProps extends Pick<TextProps, "size"> {
  title: string;
  description: string;
  value?: number | string;
  isLoading?: boolean;
}

function GeneralHeaderItem({
  title,
  value,
  isLoading,
  size = "2",
}: Omit<GeneralItemProps, "description">) {
  return (
    <Flex
      px={"7"}
      py={"5"}
      direction={"column"}
      gap={"1"}
      className={styles.GeneralHeaderItem}
    >
      <Text size={size} color={"gray"} weight={"medium"} highContrast>
        {title}
      </Text>

      <Text size={"2"} color={"gray"}>
        {isLoading ? (
          <Spinner size={"2"} />
        ) : (
          <>{value ? value : "No data available yet"}</>
        )}
      </Text>
    </Flex>
  );
}

export function GeneralItem({
  title,
  description,
  value,
  isLoading,
}: GeneralItemProps) {
  return (
    <Flex
      px={"7"}
      py={"5"}
      direction={"column"}
      gap={"9"}
      className={styles.GeneralItem}
    >
      <Flex direction={"column"} gap={"1"}>
        <Text size={"2"} color={"gray"} weight={"medium"} highContrast>
          {title}
        </Text>

        <Text size={"2"} color={"gray"}>
          {description}
        </Text>
      </Flex>

      <Flex className={isLoading ? "py-[6px]" : ""}>
        {isLoading ? (
          <Spinner size={"2"} />
        ) : (
          <>
            {value ? (
              <Text size={"6"} color={"gray"} weight={"medium"} highContrast>
                {value}
              </Text>
            ) : (
              <Text size={"2"} color={"gray"}>
                No data available yet
              </Text>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
}

interface GeneralWidgetProps {
  channels: ChannelInteraction[];
}

export function GeneralWidget({ channels }: GeneralWidgetProps) {
  const ticketsFirstReleaseQuery = useFirstRelease({ channels: ["tickets"] });
  const conversationsFirstReleaseQuey = useFirstRelease({
    channels: ["conversations"],
  });

  const [ticketsFirstRelease, ticketsFirstReleaseStr] = useMemo(() => {
    if (
      ticketsFirstReleaseQuery.data == null ||
      ticketsFirstReleaseQuery.data.firstRelease == null
    ) {
      return [undefined, "Not live yet"];
    }

    const date = new Date(ticketsFirstReleaseQuery.data.firstRelease);
    const days = dayjs().diff(date, "day");
    const formatted = `🚀 Live for ${days} days since ${dayjs(date).format(
      "MMMM DD, YYYY"
    )}`;

    return [date, formatted];
  }, [ticketsFirstReleaseQuery.data]);

  const [conversationsFirstRelease, conversationsFirstReleaseStr] =
    useMemo(() => {
      if (
        conversationsFirstReleaseQuey.data == null ||
        conversationsFirstReleaseQuey.data.firstRelease == null
      ) {
        return [undefined, "Not live yet"];
      }

      const date = new Date(conversationsFirstReleaseQuey.data.firstRelease);
      const days = dayjs().diff(date, "day");
      const formatted = `🚀 Live for ${days} days since ${dayjs(date).format(
        "MMMM DD, YYYY"
      )}`;

      return [date, formatted];
    }, [conversationsFirstReleaseQuey.data]);

  const dateRange = useMemo<DateRange | undefined>(() => {
    if (
      ticketsFirstReleaseQuery.isLoading ||
      conversationsFirstReleaseQuey.isLoading
    ) {
      return;
    }

    if (ticketsFirstRelease == null && conversationsFirstRelease != null) {
      return {
        dateFrom: conversationsFirstRelease.toISOString(),
        dateTo: new Date().toISOString(),
      };
    } else if (
      ticketsFirstRelease != null &&
      conversationsFirstRelease == null
    ) {
      return {
        dateFrom: ticketsFirstRelease.toISOString(),
        dateTo: new Date().toISOString(),
      };
    } else if (
      ticketsFirstRelease != null &&
      conversationsFirstRelease != null
    ) {
      return {
        dateFrom: new Date(
          Math.min(
            ticketsFirstRelease.getTime(),
            conversationsFirstRelease.getTime()
          )
        ).toISOString(),
        dateTo: new Date().toISOString(),
      };
    } else {
      return;
    }
  }, [
    ticketsFirstReleaseQuery.isLoading,
    ticketsFirstRelease,
    conversationsFirstReleaseQuey.isLoading,
    conversationsFirstRelease,
  ]);

  const quackInteractionsQuery = useCountQuackInteractions(
    {
      ...dateRange,
      channels,
    },
    dateRange != null
  );

  const usersInteractedWithQuackQuery = useCountUsersInteractedWithQuack(
    {
      ...dateRange,
      channels,
    },
    dateRange != null
  );

  const quackMessagesQuery = useCountQuackMessages(
    {
      ...dateRange,
      channels,
    },
    dateRange != null
  );

  const handleReload = useCallback(() => {
    void quackInteractionsQuery.refetch();
    void usersInteractedWithQuackQuery.refetch();
    void quackMessagesQuery.refetch();
  }, [
    quackInteractionsQuery.refetch,
    usersInteractedWithQuackQuery.refetch,
    quackMessagesQuery.refetch,
  ]);

  const isError =
    quackInteractionsQuery.isError ||
    usersInteractedWithQuackQuery.isError ||
    quackMessagesQuery.isError;

  return (
    <WidgetCard fullWidth autoHeight>
      <WidgetCard.Content
        noPadding={!isError}
        isError={isError}
        onReload={handleReload}
      >
        <Grid columns="3" rows={"repeat(2, auto)"} width={"100%"}>
          <GeneralHeaderItem
            size={"3"}
            title="Lifetime Quack Interaction Metrics"
            value={"Tracks all Quack interactions since first launch"}
          />

          <GeneralHeaderItem
            title="Conversations"
            value={conversationsFirstReleaseStr}
            isLoading={conversationsFirstReleaseQuey.isLoading}
          />

          <GeneralHeaderItem
            title="Tickets"
            value={ticketsFirstReleaseStr}
            isLoading={ticketsFirstReleaseQuery.isLoading}
          />

          <GeneralItem
            title="Quack-Responded Interactions"
            description="Total number of support interactions where Quack provided a response"
            value={quackInteractionsQuery.data?.value}
            isLoading={quackInteractionsQuery.isLoading}
          />

          <GeneralItem
            title="Users Interacted With Quack"
            description="Unique users who engaged with Quack during a support interaction"
            value={usersInteractedWithQuackQuery.data?.value}
            isLoading={usersInteractedWithQuackQuery.isLoading}
          />

          <GeneralItem
            title="Quack Messages"
            description="Total messages sent by Quack in support interactions"
            value={quackMessagesQuery.data?.value}
            isLoading={quackMessagesQuery.isLoading}
          />
        </Grid>
      </WidgetCard.Content>
    </WidgetCard>
  );
}
