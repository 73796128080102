import React, { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Flex } from "@radix-ui/themes";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureFlag } from "configcat-react";
import {
  getOpenTicketsFormExplorerObject,
  useExploreTickets,
} from "../../../api/useExplore";
import { TTopic } from "../types";
import { ELocalStorage } from "../../../assets/storage/localStorage";
import { fetchFromLocalStorage } from "../../../assets/storage/localStorage";
import { TSimulatorLiveModeReviewTicket } from "../../simulator-live-mode/types";

import styles from "./styles.module.scss";

export const MoveToLiveModeCTA = ({
  topic,
  topics = [],
  channel,
  tenantGroup,
}: {
  topic?: string | null;
  topics?: TTopic[];
  channel?: string;
  tenantGroup?: string[];
}) => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth0();
  const { mutateAsync: fetchTickets } = useExploreTickets();
  const { simulatorId } = useParams();

  const [openTicketsCount, setOpenTicketsCount] = useState<number | null>(null);
  const [openTicketsIds, setOpenTicketsIds] = useState<string[]>([]);
  const [topicName, setTopicName] = useState<string | null>(null);

  const { value: liveModeOptionValue } = useFeatureFlag(
    "liveModeOption",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const reviewedCount = useMemo(() => {
    const tickets = fetchFromLocalStorage(
      `${ELocalStorage.LIVE_TEST_MODE}-${topic}`
    );
    return tickets
      ? JSON.parse(tickets).filter(
          (ticket: TSimulatorLiveModeReviewTicket) =>
            openTicketsIds.includes(ticket.ticketId) &&
            ticket.good !== undefined &&
            ticket.good !== null
        ).length
      : 0;
  }, [topic, openTicketsIds]);

  useEffect(() => {
    if (user && isAuthenticated && topic && topics.length) {
      const topicObj = topics.find((t) => t.id.toString() === topic);
      if (!topicObj) return;
      const topicName = topicObj?.name || topicObj?.topic;
      setTopicName(topicName);
      setTimeout(() => {
        const openTicketsObject = getOpenTicketsFormExplorerObject({
          topicName,
          channel,
          tenantGroup,
        });
        fetchTickets(openTicketsObject).then((res) => {
          setOpenTicketsCount(res?.ticketsData?.tickets?.length);
          setOpenTicketsIds(
            res?.ticketsData?.tickets?.map((ticket: any) => ticket.id) ?? []
          );
        });
      }, 100);
    }
  }, [
    user,
    topic,
    isAuthenticated,
    fetchTickets,
    topics,
    channel,
    tenantGroup,
  ]);

  return topic &&
    liveModeOptionValue &&
    openTicketsCount &&
    reviewedCount < openTicketsCount ? (
    <div className={styles.liveModeCTAContainer}>
      <h4>
        📥{"  "}
        {openTicketsCount} open tickets are waiting in the queue for {topicName}
      </h4>
      <h5>
        Run a <span>Live Test on sampled tickets</span> to refine AI responses
        and improve accuracy.
      </h5>
      <Flex width="100%" justify="start" gap="4" align="center">
        <Button
          variant="solid"
          color="gray"
          highContrast
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(
              `/automation/${simulatorId}/${topic}/live-test${
                channel ? `?channel=${channel}` : ""
              }${
                tenantGroup && tenantGroup.length > 0 && !!tenantGroup[0]
                  ? channel
                    ? `&tenantGroup=${tenantGroup}`
                    : `?tenantGroup=${tenantGroup}`
                  : ""
              }`
            );
          }}
        >
          Start Live Test <ArrowRightIcon />
        </Button>
        <p>{reviewedCount} reviewed</p>
      </Flex>
    </div>
  ) : null;
};
