import { useMemo } from "react";
import { Code, Em, Flex, Spinner, Text } from "@radix-ui/themes";
import { params } from "@/params";
import { CodeBlock } from "@/components/shared/code-block/CodeBlock";
import { useGetQuackchatConfiguration } from "@/api/quackchat/configuration/useQuackchatConfiguration";

const QUACK_WIDGET_SCRIPT = `<script>
  (function (q, u, a, c, k, i, o) {
    q[a] =
      q[a] ||
      function () {
        (q[a].q = q[a].q || []).push(arguments);
      };
    i = u.createElement(c);
    i.async = 1;
    i.id = "quack-license";
    i.src = "{{SDK_URL}}/verify.min.js";
    i.setAttribute(a, k);
    o = u.getElementsByTagName(c)[0];
    o.parentNode.insertBefore(i, o);
  })(window, document, "quack", "script", "{{TOKEN}}");
</script>`;

const QUACK_INIT_CODE = `window.quack?.identify({  userId: 'test-id', email: 'test@email.com' });

window.quack?.showChat();`;

export function QuackchatChannelInstallSimulator() {
  const { data, isLoading } = useGetQuackchatConfiguration();

  const quackWidgetScript = useMemo(() => {
    if (data == null) {
      return "";
    }

    return QUACK_WIDGET_SCRIPT.slice()
      .replace("{{SDK_URL}}", params.SDK_URL)
      .replace("{{TOKEN}}", data.token);
  }, [data]);

  if (isLoading) {
    return <Spinner size="2" />;
  }

  return (
    <Flex direction={"column"} gap={"24px"}>
      <Text size={"3"} color={"gray"} weight={"medium"} highContrast>
        Install Quack Chat on your site / platform
      </Text>

      <Flex pt={"40px"} direction={"column"} gap={"3"}>
        <Flex direction={"column"} gap={"1"}>
          <Text size={"2"} color={"gray"} weight={"medium"} highContrast>
            Copy & Past the code
          </Text>

          <Text size={"2"} color={"gray"}>
            <Em>
              {"Paste this code before the "}
              <Code color={"indigo"}>&lt;body /&gt;</Code>
              {" tag of every page you wish to embed Quack chat in."}
            </Em>
          </Text>
        </Flex>

        <CodeBlock
          code={quackWidgetScript}
          language="html"
          showLineNumbers={false}
        />

        <CodeBlock
          code={QUACK_INIT_CODE}
          language="js"
          showLineNumbers={false}
        />
      </Flex>
    </Flex>
  );
}
