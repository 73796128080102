import { useCallback } from "react";
import { Navbar, NavbarItem } from "@/components/shared/navbar/Navbar";
import { Dialog } from "../../../components/shared/dialog";
import { params } from "../../../params";
import { useAuthorizedQuery } from "../../../api";
import {
  TicketExploreRow,
  printTicketId,
} from "../../../api/useExplore/Explore.model";
import { TicketQAScoreResponse, TicketScore } from "./TicketQAScore.model";
import SidePanel, { SidePanelTab } from "./SidePanel";
import Main, { MainTab } from "./Main";
import styles from "./TicketQAScore.module.scss";
import { useQueryClient } from "react-query";
import { TicketsList } from "./TicketsList";
import cx from "classnames";
import { TicketListItem } from "@/features/tickets-info";

const useTicketData = (ticketId?: string) => {
  return useAuthorizedQuery<TicketQAScoreResponse>({
    queryKey: `useTicketData-${ticketId}`,
    url: `${params.API_URL}/platform/sampling/tickets/${ticketId}`,
    method: "GET",
    enabled: ticketId != null,
  });
};

const useTicketHasRelease = (ticketId?: string) => {
  return useAuthorizedQuery<boolean>({
    queryKey: `useTicketHasRelease-${ticketId}`,
    url: `${params.API_URL}/platform/releases/isExist/${ticketId}`,
    method: "GET",
    enabled: ticketId != null,
  });
};

interface TicketQAScoreProps {
  ticket?: TicketExploreRow & { ticketScore?: TicketScore[] };
  navbarItems: NavbarItem[];
  onClose: () => void;
  reloadTickets?: () => Promise<unknown>;
  allowValidateTicket?: boolean;
  viewOnly?: boolean;
  defaultTabs?: {
    main?: MainTab;
    sidePanel?: SidePanelTab;
  };
  ticketListPanel?: React.ReactNode;
  ticketsList?: TicketListItem[];
  setActiveTicketId?: (id: string) => void;
}

function TicketQAScore({
  ticket,
  navbarItems,
  onClose,
  reloadTickets,
  allowValidateTicket,
  viewOnly = false,
  defaultTabs,
  ticketListPanel = false,
  ticketsList,
  setActiveTicketId = () => {},
}: TicketQAScoreProps) {
  const queryClient = useQueryClient();

  const { data: ticketData, isLoading, refetch } = useTicketData(ticket?.id);
  const { data: hasRelease, isLoading: loadingHasRelease } =
    useTicketHasRelease(ticket?.id);

  const avgQaScore = ticketData?.ticketCalculations?.avgQaScores;

  const handleOpenChanged = useCallback(
    (open: boolean) => {
      if (!open) {
        onClose();
      }
    },
    [onClose]
  );

  if (ticket == null) {
    return <></>;
  }

  const { isValidated } = ticketData?.ticketValidation ?? {
    isValidated: false,
  };

  return (
    <Dialog
      open
      onOpenChange={handleOpenChanged}
      loading={!ticketListPanel && (isLoading || loadingHasRelease)}
    >
      <Dialog.Content size={"large"}>
        <Dialog.Title>
          <Navbar
            variant={"lite"}
            items={[
              ...navbarItems,
              ...(ticket != null
                ? [{ title: printTicketId(ticket?.overview?.id) }]
                : []),
            ]}
          />

          <Dialog.Close />
        </Dialog.Title>

        <div
          className={cx(styles.TicketQAScore, {
            [styles.ticketListPanel]: ticketListPanel,
          })}
        >
          {ticketListPanel && (
            <TicketsList
              activeTicketId={ticket.id}
              tickets={ticketsList || []}
              setActiveTicketId={setActiveTicketId}
            />
          )}
          <Main
            ticket={ticket}
            ticketScore={ticketData?.ticketScore || ticket?.ticketScore}
            onUpdateTicketScore={async () => {
              await queryClient.removeQueries([`useTicketData-${ticket.id}`]);
              await refetch();
              await reloadTickets?.();
            }}
            allowValidateTicket={!isValidated && allowValidateTicket}
            viewOnly={viewOnly}
            avgQAScore={avgQaScore}
            defaultTab={defaultTabs?.main}
          />

          <SidePanel
            ticket={{ ...ticket, avgQaScore }}
            comments={ticketData?.comments || []}
            hasRelease={hasRelease}
            defaultTab={defaultTabs?.sidePanel}
          />
        </div>
      </Dialog.Content>
    </Dialog>
  );
}

export default TicketQAScore;
