import { useCallback, useState } from "react";
import { Button } from "@radix-ui/themes";
import { PlusIcon } from "@radix-ui/react-icons";
import { Dialog } from "../../../components/shared/dialog";
import styles from "./Validations.module.scss";
import {
  useAddValidationSet,
  useValidationSetsOptions,
  ValidationSetsOption,
  validationSetsOptionContent,
} from "../../../api/useValidations";
import Select, { SelectOption } from "../../../components/shared/select";
import { mixTrackEvent, QualityEvents } from "@/assets/mixpanel";

export const ADD_NEW_VALIDATION_SET = "ADD_NEW_VALIDATION_SET";

const numberOfTickets = ["any", "50", "100", "200"];

const options: SelectOption[] = [
  { label: "Any ticket", value: "any" },
  { label: "50", value: "50" },
  { label: "100", value: "100" },
  { label: "200", value: "200" },
];

function AddNewValidationSet() {
  const { data } = useValidationSetsOptions();

  const { mutateAsync: addValidationSet } = useAddValidationSet();

  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<
    Partial<Record<ValidationSetsOption, (typeof numberOfTickets)[number]>>
  >({});

  const [adding, setAdding] = useState<ValidationSetsOption>();
  const [sent, setSent] = useState<ValidationSetsOption[]>([]);

  const handleAddNew = useCallback(
    async (option: ValidationSetsOption) => {
      const numberOfTickets = value[option];

      if (numberOfTickets == null) {
        return;
      }

      setAdding(option);

      await addValidationSet({
        option,
        ...(numberOfTickets == null
          ? {}
          : numberOfTickets === "any"
          ? {}
          : { numberOfTickets: parseInt(numberOfTickets) }),
      });

      setSent((prevState) => [
        ...prevState.filter((x) => x !== option),
        option,
      ]);
      setAdding(undefined);
      mixTrackEvent({
        event: QualityEvents.VALIDATION_SET_CREATED,
        properties: {
          option,
          numberOfTickets:
            numberOfTickets === "any"
              ? numberOfTickets
              : parseInt(numberOfTickets),
        },
      });
    },
    [addValidationSet, value]
  );

  return (
    <>
      <Button
        color="gray"
        variant="ghost"
        highContrast
        size="1"
        onClick={() => setOpen(true)}
      >
        <PlusIcon />
        Add Validation Set
      </Button>

      <Dialog open={open} onOpenChange={setOpen}>
        <Dialog.Content size={"small"}>
          <div className={styles.AddNewValidationSet}>
            <Dialog.Close className={styles.Close} />

            <div className={styles.Header}>
              <div className={styles.Title}>Add validation set</div>
              <div className={styles.Description}>
                Customize sampling criteria to define which tickets to include
              </div>
            </div>

            <div className={styles.Content}>
              {data
                ?.filter((option) => option !== "random")
                .map((option) => {
                  const numberOfTickets = value[option];

                  const isSent = sent.includes(option);
                  const isLoading = adding === option;
                  const isOtherLoading = adding != null && adding !== option;

                  return (
                    <div className={styles.ValidationSetOption}>
                      <div className={styles.Option}>
                        <div className={styles.Title}>
                          {validationSetsOptionContent[option]?.title ?? ""}
                        </div>
                        <div className={styles.Description}>
                          {validationSetsOptionContent[option]?.description ??
                            ""}
                        </div>
                      </div>

                      <Select
                        options={options}
                        value={numberOfTickets}
                        onChange={(newValue) =>
                          setValue((prevState) => {
                            return {
                              ...prevState,
                              [option]: newValue,
                            };
                          })
                        }
                        classes={{ trigger: styles.Select }}
                      />

                      <Button
                        {...(isSent
                          ? {
                              color: "green",
                              variant: "soft",
                            }
                          : {
                              color: "gray",
                              variant: "solid",
                              highContrast: true,
                              onClick: () => void handleAddNew(option),
                              loading: isLoading,
                              disabled:
                                numberOfTickets == null || isOtherLoading,
                            })}
                        className={styles.AddButton}
                      >
                        {sent.includes(option) ? "Requested" : "Add"}
                      </Button>
                    </div>
                  );
                })}
            </div>
          </div>
        </Dialog.Content>
      </Dialog>
    </>
  );
}

export default AddNewValidationSet;
