import React, { useEffect, useMemo, useState, useRef } from "react";
import dayjs from "dayjs";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Flex, Text } from "@radix-ui/themes";
import { StickyNote } from "lucide-react";
import { Skeleton } from "@radix-ui/themes";
import * as Toast from "@radix-ui/react-toast";
import { useAuth0 } from "@auth0/auth0-react";

import {
  useCreateOpportunityGroupFaqFeedback,
  useGetOpportunityGroup,
  useUpdateOpportunityGroup,
} from "@/api/useOpportunityGroups";
import {
  DateRange,
  DateRangePicker,
} from "@/components/shared/date-range-picker/DateRangePicker";
import { Navbar } from "@/components/shared/navbar/Navbar";
import Select from "@/components/shared/multi-select";
import DataAccordion from "@/components/shared/data-accordion";
import CopyButton from "@/components/shared/copyButton";
import {
  KnowledgeGapPriority,
  KnowledgeGapTicketsTrend,
} from "../knowledge-gaps/knowledge-gaps-table.types";
import {
  OpportunityStatus,
  opportunityStatusesToLabels,
} from "../knowledge-gaps/index.types";
import { radixColors } from "@/components/radixColors";
import { TicketsInfo } from "@/features/tickets-info";
import { Feedback } from "@/features/feedback";
import { FeedbackType } from "@/features/feedback/index.types";

import styles from "./styles.module.scss";
import { AgentActivity } from "./agent";
import {
  ChangeStatusOptions,
  OpportunitiesGroup,
  OpportunityGroupIgnoreReasons,
} from "./index.types";
import { KnowledgeEvents } from "@/assets/mixpanel/knowledge";
import { mixTrackEvent } from "@/assets/mixpanel";
import { PageEmptyState } from "@/components/page-empty-state/PageEmptyState";

export const KnowledgeGap = () => {
  const { user } = useAuth0();
  const { groupId } = useParams();
  const navigate = useNavigate();
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const timerRef = useRef(0);
  const [openTicketsInfo, setOpenTicketsInfo] = useState(false);

  const [dateRange, setDateRange] = useState<DateRange>({
    dateFrom: dayjs().subtract(2, "months").format("YYYY-MM-DD"),
    dateTo: dayjs().format("YYYY-MM-DD"),
  });
  const [status, setStatus] = useState<
    OpportunityStatus | OpportunityGroupIgnoreReasons | null
  >(null);
  const [faqFeedbackMap, setFaqFeedbackMap] = useState<
    Record<string, { comment: string; feedback: string | null }>
  >({});

  const { data: opportunityGroupData, isLoading } = useGetOpportunityGroup({
    id: groupId ?? "",
    startDate: dateRange.dateFrom,
    endDate: dateRange.dateTo,
    enabled: !!groupId,
  });
  const opportunityGroup = opportunityGroupData as OpportunitiesGroup;
  const { mutateAsync: updateOpportunityGroup } = useUpdateOpportunityGroup({
    opportunityGroupId: groupId ?? "",
  });
  const { mutateAsync: createOpportunityGroupFaqFeedback } =
    useCreateOpportunityGroupFaqFeedback();

  useEffect(() => {
    const value = opportunityGroup?.status;
    const ignoreReason = opportunityGroup?.hiddenReason;

    if (value === "hidden" && ignoreReason) {
      setStatus(ignoreReason);
    } else {
      setStatus(value);
    }
  }, [opportunityGroup]);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const handleStatusChange = async (
    value: OpportunityStatus | OpportunityGroupIgnoreReasons
  ) => {
    mixTrackEvent({
      event: KnowledgeEvents.GAP_STATUS_CHANGED,
      properties: {
        opportunityGroupId: groupId,
        status: value,
        previousStatus: status,
      },
    });
    setStatus(value);
    try {
      if (
        (OpportunityGroupIgnoreReasons as readonly string[]).includes(value)
      ) {
        await updateOpportunityGroup({
          status: "hidden",
          hiddenReason: value as OpportunityGroupIgnoreReasons,
        });
        setToastMessage(`Status updated to Ignore`);
      } else {
        await updateOpportunityGroup({
          status: value as OpportunityStatus,
          hiddenReason: null,
        });
        setToastMessage(
          `Status updated to ${
            opportunityStatusesToLabels[
              value as keyof typeof opportunityStatusesToLabels
            ]
          }`
        );
      }
      setToastOpen(false);
      window.clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(() => {
        setToastOpen(true);
      }, 100);
    } catch (error) {
      setToastMessage("Failed to update status");
      setToastOpen(false);
      window.clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(() => {
        setToastOpen(true);
      }, 100);
    }
  };

  const faqItems = useMemo(() => {
    return (
      opportunityGroup?.faqs?.map((faq) => ({
        title: faq.question,
        content: faq.answer,
        bulletIcon: <StickyNote className="h-4 w-4 text-gray-500" />,
        actions: {
          button: (
            <CopyButton
              textToCopy={`${faq.question}\n\n${faq.answer}`}
              onClick={(value) => {
                mixTrackEvent({
                  event: KnowledgeEvents.FAQ_COPIED,
                  properties: {
                    opportunityGroupId: groupId,
                    faqId: faq.id,
                    faq: value,
                  },
                });
              }}
            />
          ),
          feedback: (
            <Feedback
              value={{
                comment:
                  faqFeedbackMap[faq.id]?.comment ||
                  faq?.faqFeedback[0]?.comment ||
                  "",
                type:
                  (faqFeedbackMap[faq.id]?.feedback as FeedbackType) ||
                  (faq?.faqFeedback[0]?.feedback as FeedbackType) ||
                  null,
              }}
              onChange={async ({ comment, type }) => {
                try {
                  mixTrackEvent({
                    event: KnowledgeEvents.FAQ_FEEDBACK_SUBMITTED,
                    properties: {
                      opportunityGroupId: groupId,
                      faqId: faq.id,
                      question: faq.question,
                      answer: faq.answer,
                      feedback: type,
                      comment,
                    },
                  });
                  await createOpportunityGroupFaqFeedback({
                    opportunityGroupFaqId: faq.id,
                    comment,
                    feedback: type,
                    author: user?.email ?? "",
                  });
                  setFaqFeedbackMap((prev) => ({
                    ...prev,
                    [faq.id]: { comment, feedback: type },
                  }));
                  setToastMessage("Feedback submitted successfully");
                } catch (err) {
                  setToastMessage("Feedback submission failed");
                }
                setToastOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                  setToastOpen(true);
                }, 100);
              }}
            />
          ),
        },
      })) || []
    );
  }, [
    opportunityGroup,
    faqFeedbackMap,
    createOpportunityGroupFaqFeedback,
    user?.email,
  ]);

  const allFaqs = useMemo(() => {
    return opportunityGroup?.faqs
      ?.map((faq) => `${faq.question}\n\n${faq.answer}`)
      .join("\n\n");
  }, [opportunityGroup]);

  const [searchParams] = useSearchParams();

  if (opportunityGroupData?.type === "Error") {
    return (
      <PageEmptyState
        title={"Oops! Something went wrong"}
        description={
          "We're sorry, but we couldn't load the knowledge gap details right now. Please try again later."
        }
        svg={"knowledge-view"}
      ></PageEmptyState>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.controlRow}>
        <Navbar
          items={[
            { title: "Knowledge Management" },
            {
              title: "Knowledge Gaps",
              onClick: () =>
                navigate(
                  `/knowledge-management/gaps?${searchParams.toString()}`
                ),
            },
            { title: opportunityGroup?.label },
          ]}
        />
      </div>
      <div className={styles.main}>
        <div className={styles.content}>
          <Flex
            gap={"4"}
            justify={"between"}
            align={"center"}
            style={{ width: "100%" }}
          >
            <DateRangePicker value={dateRange} onChange={setDateRange} />
            <Flex gap={"4"} justify={"between"} align={"center"}>
              <Flex
                gap={"2"}
                justify={"between"}
                align={"center"}
                onClick={() => {
                  mixTrackEvent({
                    event: KnowledgeEvents.GAP_RELATED_TICKETS_VIEWED,
                    properties: {
                      opportunityGroupId: groupId,
                    },
                  });
                  setOpenTicketsInfo(true);
                }}
                className={styles.relatedTicketsContainer}
              >
                <Text size={"2"} weight={"light"}>
                  Related Tickets
                </Text>
                <Skeleton loading={isLoading}>
                  <Text size={"2"} className={styles.relatedTicketsNumber}>
                    {opportunityGroup?.currentTimeSpanTickets}
                  </Text>
                </Skeleton>
              </Flex>
              <Select
                options={ChangeStatusOptions}
                onChange={(value) =>
                  handleStatusChange(
                    value as OpportunityStatus | OpportunityGroupIgnoreReasons
                  )
                }
                placeholder="Select a status"
                value={
                  status === "hidden" && opportunityGroup?.hiddenReason
                    ? [opportunityGroup?.hiddenReason]
                    : status
                    ? [status as string]
                    : []
                }
              />
            </Flex>
          </Flex>
          <Flex gap={"1"} width={"100%"} direction={"column"}>
            <Skeleton loading={isLoading}>
              <Text size={"8"} weight={"bold"}>
                {isLoading ? "Loading..." : opportunityGroup?.label}
              </Text>
            </Skeleton>
            <Flex gap={"3"} width={"100%"}>
              {isLoading ? (
                <Skeleton className="h-5 w-20" />
              ) : (
                <KnowledgeGapPriority
                  priority={opportunityGroup?.priority}
                  label
                />
              )}
              {isLoading ? (
                <Skeleton className="h-5 w-10" />
              ) : (
                <KnowledgeGapTicketsTrend
                  ticketTrend={
                    opportunityGroup?.currentTimeSpanTickets -
                    opportunityGroup?.compareTimeSpanTickets
                  }
                />
              )}
            </Flex>
          </Flex>
          <Flex gap={"3"} width={"100%"} direction={"column"}>
            <Flex width={"100%"} justify={"between"}>
              <Text size={"3"} color="gray">
                Missing Content
              </Text>
              <CopyButton
                textToCopy={allFaqs}
                label="Copy All"
                onClick={(value) => {
                  mixTrackEvent({
                    event: KnowledgeEvents.ALL_FAQ_COPIED,
                    properties: {
                      opportunityGroupId: groupId,
                      allFaqs: value,
                    },
                  });
                }}
              />
            </Flex>
            <DataAccordion items={faqItems} loading={isLoading} />
          </Flex>
          {opportunityGroup?.topCollaborators?.length > 0 && (
            <Flex gap={"3"} width={"100%"} direction={"column"}>
              <Text size={"3"} color="gray">
                Agent Activity
              </Text>
              <Flex gap={"3"} width={"100%"}>
                {opportunityGroup?.topCollaborators.map((agent) => (
                  <AgentActivity agent={agent} color={radixColors[0]} />
                ))}
              </Flex>
            </Flex>
          )}
        </div>
      </div>
      <TicketsInfo
        tickets={opportunityGroup?.relatedTickets}
        open={openTicketsInfo}
        setOpen={setOpenTicketsInfo}
      />
      <Toast.Provider swipeDirection="right">
        <Toast.Root
          className={styles.toastRoot}
          open={toastOpen}
          onOpenChange={setToastOpen}
          duration={2000}
        >
          <Toast.Title className={styles.toastTitle}>
            {toastMessage.includes("Status")
              ? "Status Update"
              : "Feedback Submitted"}
          </Toast.Title>
          <Toast.Description className={styles.toastDescription}>
            {toastMessage}
          </Toast.Description>
        </Toast.Root>
        <Toast.Viewport className={styles.toastViewport} />
      </Toast.Provider>
    </div>
  );
};
