import { DropdownMenu } from "@radix-ui/themes";
import { useCallback, useMemo } from "react";
import { categoriesMapper } from "../lib";
import { useFilter } from "../context";
import { CheckIcon } from "@radix-ui/react-icons";

export const CategoriesDropDown = ({ options }: { options: string[] }) => {
  const { stringSliceBy, setStringSlicer, removeStringSlicer } = useFilter();

  const fields = useMemo(() => {
    const slicer = stringSliceBy?.and.find(({ type }) => type === "category");

    return slicer?.values.map(({ value }) => value);
  }, [stringSliceBy]);

  const handleSlice = useCallback(
    (option: string) => {
      let newFields = [];

      if (fields?.includes(option)) {
        newFields = fields.filter((o) => o !== option);
      } else {
        newFields = [...(fields ?? []), option];
      }

      if (newFields.length > 0) {
        setStringSlicer("category", {
          type: "category",
          operation: "or",
          values: newFields.map((field) => ({
            type: "category",
            operation: "eq",
            value: field,
          })),
        });
      } else {
        removeStringSlicer("category");
      }
    },
    [fields, setStringSlicer, removeStringSlicer]
  );

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
        Category
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent
        className="DropdownMenuSubContent"
        sideOffset={8}
        alignOffset={-5}
      >
        {options
          ?.filter((o) => o !== "other")
          .map((option) => {
            return (
              <DropdownMenu.Item
                className="DropdownMenuItem"
                onClick={() => handleSlice(option)}
              >
                {
                  categoriesMapper[option as keyof typeof categoriesMapper]
                    ?.icon
                }
                {categoriesMapper[option as keyof typeof categoriesMapper]
                  ?.label ?? "Unknown"}
                {fields?.includes(option) && <CheckIcon />}
              </DropdownMenu.Item>
            );
          })}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
