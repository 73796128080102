import { useContext } from "react";

import { useAuthorizedMutation, useAuthorizedQuery } from "../index";

import { params } from "@/params";

export const useCreateChatSession = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/sessions-api/copilot-chat/session`,
    method: "POST",
  });
};

export const useGetMessages = (copilotChatSessionId: string) => {
  return useAuthorizedQuery({
    url: `${params.API_URL}/sessions-api/copilot-chat/sessions/${copilotChatSessionId}/messages`,
    queryKey: `chat-${copilotChatSessionId}`,
    enabled: false,
  });
};

export const useMessageFeedback = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/sessions-api/copilot-chat/message/feedback`,
    method: "POST",
  });
};

export const useAddOverviewFeedback = ({
  insightId,
}: {
  insightId: string;
}) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/extension/insights/${insightId}/feedback`,
    method: "PUT",
  });
};
