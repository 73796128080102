import { ETicketsComponents, TInsight, User } from "./types";

export function getConversationalInsight(user: User, insights?: TInsight[]) {
  if (insights && insights.length > 0) {
    console.log({ insight: insights[insights.length - 1] });
    const { id, conversationalInsight, comment, feedbacks } =
      insights[insights.length - 1];
    const feedback = feedbacks?.find(
      (feedback: any) =>
        feedback.type === ETicketsComponents.CONVERSATIONAL_INSIGHT
    );

    return {
      id,
      content: conversationalInsight,
      commentExternalId: comment.externalId,
      feedback: feedback?.rank,
    };
  }
}
