import { useContext, useEffect, useState } from "react";
import { fetchConversationById } from "../../../../api/useConversations";
import { FeatureAccessContext } from "../../../../context/featureAccess";
import { Badge, Button } from "@radix-ui/themes";
import { Dot, Eye } from "lucide-react";
import dayjs from "dayjs";
import ExploreTable, { ColumnType } from "../../../explore/ExploreTable";
import TicketTranscript from "../../../quality/ticket-qa-score/TicketTranscript";
import { ChatSession } from "../types";

interface ExtendedChatSession {
  tenant: string;
  chatSessionId: string;
  chatMessages: {
    role: "user" | "assistant";
    content: string;
    chatMessageId: string;
    createdAt?: Date;
  }[];
}

const chatSessionsColumns: ColumnType<keyof ChatSession>[] = [
  {
    key: "chatSessionId",
    label: "ID",
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "createdAt",
    label: "Created at",
  },
  {
    key: "chatMessagesCount",
    label: "# messages",
  },
  {
    key: "view",
    label: "View",
  },
];

function cellGenerator(
  values: ChatSession,
  { key }: ColumnType<keyof ChatSession>
) {
  const cellValue = values?.[key];

  if (key === "view") {
    return (
      <Button variant="ghost" color="gray">
        <Eye style={{ width: "16px" }} />
      </Button>
    );
  }
  if (key === "status") {
    const isClosed = values.isClosed;
    return (
      <Badge
        color={`${
          cellValue === "ESCALATED" ? "red" : isClosed ? "blue" : "gray"
        }`}
      >
        {cellValue != null
          ? isClosed && cellValue !== "ESCALATED"
            ? "Finished"
            : cellValue
          : "-"}
      </Badge>
    );
  }

  if (key === "createdAt") {
    return (
      <>
        {cellValue != null
          ? dayjs(cellValue as string).format("DD/MM - hh:mm A")
          : "-"}
      </>
    );
  }

  if (key === "chatSessionId") {
    const storedConversationId =
      localStorage.getItem("quack-conversations") ?? "";

    return (
      <div style={{ display: "flex", alignItems: "center", height: "40px" }}>
        {!storedConversationId?.includes(values.chatSessionId) && (
          <Dot size={40} color="#0090FF" />
        )}
        {cellValue != null ? cellValue : "-"}
      </div>
    );
  }

  return <>{cellValue != null ? cellValue : "-"}</>;
}
export const Conversations = ({
  chatSessions,
}: {
  chatSessions: ChatSession[];
}) => {
  const [selectedChatSession, setSelectedChatSession] =
    useState<ExtendedChatSession | null>(null);
  const { selectedWorkspace } = useContext(FeatureAccessContext);
  const [selectedChatSessionId, setSelectedChatSessionId] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (selectedChatSessionId) {
      fetchConversationById(selectedWorkspace, selectedChatSessionId).then(
        (d) => {
          let quackConversations =
            localStorage.getItem("quack-conversations") ?? "";
          quackConversations += `,${selectedChatSessionId}`;

          localStorage.setItem("quack-conversations", quackConversations);
          setSelectedChatSession(d);
        }
      );
    }
  }, [selectedChatSessionId, selectedWorkspace]);

  return (
    <div
      style={{ display: "flex", height: "100%", padding: " 10px 20px 20px 0 " }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          height: "100%",
          width: "100%",
          maxWidth: "70vw",
          flexGrow: 1,
        }}
      >
        <ExploreTable<ChatSession>
          columns={chatSessionsColumns}
          rows={chatSessions.map((c: any) => {
            const twoHoursAgo = new Date(Date.now() - 2 * 60 * 60 * 1000);
            return { ...c, isClosed: new Date(c.createdAt) < twoHoursAgo };
          })}
          isLoading={false}
          cellGenerator={cellGenerator}
          onRowClick={({ chatSessionId }) => {
            setSelectedChatSessionId(chatSessionId);
          }}
        />
      </div>
      {selectedChatSession && (
        <div
          style={{
            width: "400px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ height: "200px", flexGrow: 1, overflowY: "scroll" }}>
            {selectedChatSession && selectedChatSession.chatMessages && (
              <TicketTranscript
                comments={selectedChatSession?.chatMessages.map((x) => {
                  return {
                    updatedAt: x.createdAt?.toString() ?? "",
                    body: x.content.replace(new RegExp("\\\\n", "g"), "\n"),
                    agent: x.role === "assistant",
                    isInternal: false,
                  };
                })}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
