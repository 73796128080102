import { useAuthorizedQuery } from ".";
import { params } from "../params";

export const useGetTicket = (ticketId: string, enabled: boolean) => {
  return useAuthorizedQuery({
    queryKey: `ticket-${ticketId}`,
    url: `${params.API_URL}/platform/tickets/${ticketId}`,
    method: "GET",
    enabled,
  });
};

export const useGetTicketBySource = (source: string, enabled: boolean) => {
  return useAuthorizedQuery({
    queryKey: `ticket-${source}`,
    url: `${params.API_URL}/platform/tickets/by-source/${source}`,
    method: "GET",
    enabled,
  });
};
