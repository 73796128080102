import { useState } from "react";
import { Check, Copy } from "lucide-react";
import { cn } from "@/lib/utils";

interface SyntaxHighlightProps {
  code: string;
  colorScheme?: string;
}

function SyntaxHighlight({ code, colorScheme = "sill" }: SyntaxHighlightProps) {
  // Define token patterns and their corresponding classes
  const patterns = [
    // Keywords
    {
      pattern:
        /\b(const|let|var|function|return|if|else|for|while|class|import|export|from|extends|new|this|async|await|try|catch|throw|break|continue|default|case|switch|typeof|instanceof|in|of|null|undefined|true|false)\b/g,
      className:
        colorScheme === "sill"
          ? "text-[#9b6a46] dark:text-[#d9a77e]"
          : "text-purple-11",
    },

    // Strings
    {
      pattern: /(["'`])(\\?.)*?\1/g,
      className:
        colorScheme === "sill"
          ? "text-[#5e8c61] dark:text-[#8fc292]"
          : "text-green-11",
    },

    // Numbers
    {
      pattern: /\b\d+\.?\d*\b/g,
      className:
        colorScheme === "sill"
          ? "text-[#b07d48] dark:text-[#e0a76b]"
          : "text-orange-11",
    },

    // Comments
    {
      pattern: /\/\/.*|\/\*[\s\S]*?\*\//g,
      className:
        colorScheme === "sill"
          ? "text-[#a39e99] dark:text-[#7d756f] italic"
          : "text-gray-9 italic",
    },

    // Functions
    {
      pattern: /\b([a-zA-Z_$][\w$]*)\s*\(/g,
      className:
        colorScheme === "sill"
          ? "text-[#4a6da7] dark:text-[#8ca5d9]"
          : "text-blue-11",
    },

    // Operators
    {
      pattern: /[+\-*/%=<>!&|^~?:]+/g,
      className:
        colorScheme === "sill"
          ? "text-[#a17c43] dark:text-[#d4b06e]"
          : "text-amber-11",
    },

    // HTML Tags
    {
      pattern: /<\/?([a-zA-Z][a-zA-Z0-9]*)/g,
      className:
        colorScheme === "sill"
          ? "text-[#a14957] dark:text-[#e0798a]"
          : "text-crimson-11",
    },

    // HTML Attributes
    {
      pattern: /\s([a-zA-Z][a-zA-Z0-9]*)\s*=/g,
      className:
        colorScheme === "sill"
          ? "text-[#a17c43] dark:text-[#d4b06e]"
          : "text-amber-11",
    },

    // Punctuation
    {
      pattern: /[{}[\]();,.]/g,
      className:
        colorScheme === "sill"
          ? "text-[#6f6962] dark:text-[#a39e99]"
          : "text-gray-11",
    },
  ];

  // Create an array of segments to track matched and unmatched parts
  type Segment = {
    text: string;
    className?: string;
  };

  // Start with the entire code as one unmatched segment
  let segments: Segment[] = [{ text: code }];

  // Process each pattern
  for (const { pattern, className } of patterns) {
    const newSegments: Segment[] = [];

    // Process each existing segment
    for (const segment of segments) {
      // Skip already matched segments
      if (segment.className) {
        newSegments.push(segment);
        continue;
      }

      // Reset the regex pattern
      pattern.lastIndex = 0;

      let lastIndex = 0;
      let match: RegExpExecArray | null;

      // Find all matches in this segment
      while ((match = pattern.exec(segment.text)) !== null) {
        // Add text before the match
        if (match.index > lastIndex) {
          newSegments.push({
            text: segment.text.substring(lastIndex, match.index),
          });
        }

        // Add the matched text with its class
        newSegments.push({
          text: match[0],
          className,
        });

        lastIndex = pattern.lastIndex;
      }

      // Add any remaining text
      if (lastIndex < segment.text.length) {
        newSegments.push({
          text: segment.text.substring(lastIndex),
        });
      }
    }

    segments = newSegments;
  }

  // Convert segments to React elements
  return (
    <>
      {segments.map((segment, i) =>
        segment.className ? (
          <span key={i} className={segment.className}>
            {segment.text}
          </span>
        ) : (
          segment.text
        )
      )}
    </>
  );
}

interface CodeBlockProps {
  code: string;
  language?: string;
  showLineNumbers?: boolean;
  className?: string;
}

export function CodeBlock({
  code,
  language = "js",
  showLineNumbers = true,
  className,
}: CodeBlockProps) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const lines = code.trim().split("\n");

  return (
    <div
      className={cn("rounded-lg overflow-hidden font-mono border", className)}
    >
      <div
        className={cn(
          "flex items-center justify-between px-4 py-2",
          "bg-background",
          "text-muted-foreground",
          "border-b"
        )}
      >
        <span className="text-sm font-medium">{language}</span>
        <button
          onClick={copyToClipboard}
          className="flex items-center gap-1 text-xs opacity-70 hover:opacity-100 transition-opacity"
        >
          {copied ? (
            <>
              <Check className="h-4 w-4" />
              <span>Copied!</span>
            </>
          ) : (
            <>
              <Copy className="h-4 w-4" />
              <span>Copy code</span>
            </>
          )}
        </button>
      </div>
      <div
        className={cn(
          "p-4 overflow-x-auto",
          "bg-background",
          "text-muted-foreground"
        )}
      >
        <pre className={cn("p-0 m-0 border-none", "text-sm")}>
          {showLineNumbers ? (
            <code>
              {lines.map((line, i) => (
                <div key={i} className="table-row">
                  <span className="table-cell pr-4 text-right select-none text-[#a39e99] dark:text-[#7d756f]">
                    {i + 1}
                  </span>
                  <span className="table-cell">
                    <SyntaxHighlight code={line} />
                  </span>
                </div>
              ))}
            </code>
          ) : (
            <code>
              <SyntaxHighlight code={code} />
            </code>
          )}
        </pre>
      </div>
    </div>
  );
}
