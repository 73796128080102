import { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Badge, Button, Flex } from "@radix-ui/themes";
import { createToast } from "vercel-toast";
import { useFeatureFlag } from "configcat-react";

import { useGetTicket } from "../../../api/useGetTicket";
import {
  useChangeStatusInteractionInTraining,
  useGetInteractionInTraining,
} from "../../../api/training";
import { useGetTopics } from "../../../api/useGetTopics";
import {
  InteractionInTrainingStatus,
  InteractionType,
} from "../../simulator-live-mode/types";
import { Tooltip } from "../../../components/shared/tooltip";

import styles from "./styles.module.scss";
import { ConversationInTraining, TicketInTraining } from "./types";

export const PendingReview = ({
  topicId,
  handleMessage,
  setSelectedTicket,
  setInteractionInTraining,
}: {
  topicId: string | null;
  handleMessage: (value: string) => void;
  setSelectedTicket: React.Dispatch<React.SetStateAction<string | null>>;
  setInteractionInTraining: React.Dispatch<
    React.SetStateAction<{
      interactionInTrainingId: number;
      interactionType: InteractionType;
    } | null>
  >;
}) => {
  const { user } = useAuth0();
  const { value: sendConversationToTraining } = useFeatureFlag(
    "sendConversationToTraining",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const [clickedTicketId, setClickedTicketId] = useState<string | null>(null);
  const { data } = useGetTicket(
    clickedTicketId ?? "",
    clickedTicketId !== null
  );

  const { data: topicList } = useGetTopics(!!user);
  const topic = topicList?.find(
    (topic: { id: number; topic: string }) => topic.id === Number(topicId)
  );
  const { data: interactionInTraining, refetch } = useGetInteractionInTraining(
    topic?.topic ?? "",
    !!topic?.topic
  );
  const { mutateAsync: changeStatusInteractionInTraining } =
    useChangeStatusInteractionInTraining();

  const ticketsPendingReview = useMemo(() => {
    return (
      interactionInTraining?.tickets?.filter(
        (ticket: TicketInTraining) =>
          ticket.status === InteractionInTrainingStatus.PENDING
      ) ?? []
    );
  }, [interactionInTraining]);

  const conversationsPendingReview = useMemo(() => {
    return (
      interactionInTraining?.conversations?.filter(
        (conversation: ConversationInTraining) =>
          conversation.status === InteractionInTrainingStatus.PENDING
      ) ?? []
    );
  }, [interactionInTraining]);

  useEffect(() => {
    if (data?.ticket?.description) {
      handleMessage(data.ticket.description);
    }
  }, [data, handleMessage]);

  if (
    ticketsPendingReview.length === 0 &&
    conversationsPendingReview.length === 0
  )
    return null;

  return (
    <div className={styles.pendingReview}>
      <h4>Pending Review</h4>
      {ticketsPendingReview.length > 0 && <h5>Review tickets flagged</h5>}
      {ticketsPendingReview.map((ticket: TicketInTraining) => (
        <div
          key={ticket.ticketInTrainingId}
          className={styles.pendingReviewTicket}
          onClick={() => {
            setClickedTicketId(ticket.ticketId);
            setSelectedTicket(ticket.ticketId);
            setInteractionInTraining({
              interactionInTrainingId: ticket.ticketInTrainingId,
              interactionType: InteractionType.TICKET,
            });
          }}
        >
          <Flex direction="column" gap="10px">
            <h5>
              <Badge size="2" color="purple" style={{ marginRight: "4px" }}>
                Ticket
              </Badge>{" "}
              #{ticket.displayId}
            </h5>
            <p>
              <b>Reason flagged:</b> {ticket.reason}
            </p>
          </Flex>
          <Button
            color="gray"
            variant="soft"
            style={{ cursor: "pointer" }}
            onClick={async (e) => {
              e.stopPropagation();
              await changeStatusInteractionInTraining({
                interactionInTrainingId: ticket.ticketInTrainingId,
                interactionType: InteractionType.TICKET,
                status: InteractionInTrainingStatus.IGNORED,
              });
              createToast(
                `Ticket #${ticket.displayId} removed from pending review`,
                {
                  type: "dark",
                  timeout: 3000,
                }
              );
              await refetch();
            }}
          >
            Ignore
          </Button>
        </div>
      ))}
      {sendConversationToTraining && conversationsPendingReview.length > 0 && (
        <h5>Review conversations flagged</h5>
      )}
      {sendConversationToTraining &&
        conversationsPendingReview.map(
          (conversation: ConversationInTraining) => (
            <div
              key={conversation.conversationInTrainingId}
              className={styles.pendingReviewTicket}
              onClick={() => {
                handleMessage(conversation.messages[0].content);
                setInteractionInTraining({
                  interactionInTrainingId:
                    conversation.conversationInTrainingId,
                  interactionType: InteractionType.CONVERSATION,
                });
              }}
            >
              <Flex direction="column" gap="10px">
                <h5>
                  <Badge size="2" color="blue" style={{ marginRight: "4px" }}>
                    Conversation
                  </Badge>{" "}
                  {conversation.messages[0].content.length > 100 ? (
                    <Tooltip>
                      <Tooltip.Trigger>
                        <span>
                          {conversation.messages[0].content.slice(0, 100)}...
                        </span>
                      </Tooltip.Trigger>
                      <Tooltip.Content>
                        {conversation.messages[0].content}
                      </Tooltip.Content>
                    </Tooltip>
                  ) : (
                    <span>{conversation.messages[0].content}</span>
                  )}
                </h5>
                <p>
                  <b>Reason flagged:</b> {conversation.reason}
                </p>
              </Flex>
              <Button
                color="gray"
                variant="ghost"
                style={{ cursor: "pointer" }}
                onClick={async (e) => {
                  e.stopPropagation();
                  await changeStatusInteractionInTraining({
                    interactionInTrainingId:
                      conversation.conversationInTrainingId,
                    interactionType: InteractionType.CONVERSATION,
                    status: InteractionInTrainingStatus.IGNORED,
                  });
                  createToast(`Conversation removed from pending review`, {
                    type: "dark",
                    timeout: 3000,
                  });
                  await refetch();
                }}
              >
                Ignore
              </Button>
            </div>
          )
        )}
    </div>
  );
};
