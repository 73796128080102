import { Flex, Badge, Text } from "@radix-ui/themes";
import { SlashIcon } from "@radix-ui/react-icons";
import { Dialog } from "../../../components/shared/dialog";
import { AddIntegrationConfig } from "./addIntegrationConfigs";
import styles from "./AddIntegration.module.scss";

type AddIntegrationHeaderProps = AddIntegrationConfig["header"] & {
  isGettingStarted: boolean;
};

function AddIntegrationHeader({
  title,
  isGettingStarted,
}: AddIntegrationHeaderProps) {
  return (
    <Flex
      px={"15px"}
      py={"10px"}
      justify={"between"}
      align={"center"}
      className={styles.Header}
    >
      <Flex align={"center"} gap={"15px"}>
        <Badge color="gray" className={styles.Badge} size={"3"} highContrast>
          {isGettingStarted ? "Getting Started" : "Settings"}
        </Badge>
        <SlashIcon className={styles.Icon} />

        <Text size={"2"}>{title}</Text>
      </Flex>

      <Dialog.Close />
    </Flex>
  );
}

export default AddIntegrationHeader;
