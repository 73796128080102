export enum ELocalStorage {
  LIVE_TEST_MODE = "LIVE_TEST_MODE",
}

export function saveToLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function fetchFromLocalStorage(key: string): string | null {
  return localStorage.getItem(key);
}
