import { useMemo } from "react";
import { useBrands } from "../../../api/useBrands";

export const useOptionsMapper = () => {
  const { data: brands, isLoading: brandsLoading } = useBrands();

  const optionsMapper = useMemo(
    () => ({
      maxBackAndForth: ["1", "2", "3", "4", "5", "6", "400"],
      length: ["short", "medium", "long"],
      includeLink: ["true", "false"],
      includeOpening: ["true", "false"],
      allowedLanguages: [
        "English",
        "Spanish",
        "French",
        "German",
        "Portuguese",
        "Italian",
        "Chinese",
        "Japanese",
        "Korean",
        "Hebrew",
      ],
      defaultLanguage: [
        "English",
        "Spanish",
        "French",
        "German",
        "Portuguese",
        "Italian",
        "Chinese",
        "Japanese",
        "Korean",
        "Hebrew",
      ],
      brand:
        brands?.map((b: { externalId: string; name: string }) => b.name) ?? [],
    }),
    [brands]
  );

  return { OptionsMapper: optionsMapper, loading: brandsLoading };
};

export const OptionsInfoMapper = {
  maxBackAndForth: {
    title: "Max back and forth",
    info: "After x back and forth messages Quack will transfer the ticket",
  },
  length: {
    title: "Length",
    info: "Length of the answer",
  },
  includeLink: {
    title: "Include article links",
    info: "Quack will include article links in the answers",
  },
  includeOpening: {
    title: "Include opening",
    info: "Quack will start with 'hi thanks for reaching out'",
  },
  allowedLanguages: {
    title: "Allowed languages",
    info: "AI Agent will answer in the following languages",
  },
  defaultLanguage: {
    title: "Default language",
    info: "AI Agent's default language",
  },
  brand: {
    title: "Brands",
    info: "Ticketing System Brands",
  },
};
