import { FeedbackType } from "@/features/feedback/index.types";
import {
  OpportunityGroupPriority,
  OpportunityStatus,
} from "../knowledge-gaps/index.types";
import { TicketListItem } from "@/features/tickets-info";

export type OpportunitiesGroup = {
  id: string;
  tenantId: string;
  externalId: string;
  label: string;
  createdAt: string;
  updatedAt: string;
  status: OpportunityStatus;
  priority: OpportunityGroupPriority;
  saved: boolean;
  verifiedAt: string | null;
  hiddenReason: OpportunityGroupIgnoreReasons | null;
  faqs: FAQ[];
  currentTimeSpanTickets: number;
  compareTimeSpanTickets: number;
  topCollaborators: Collaborator[];
  relatedTickets: TicketListItem[];
};

export type FAQ = {
  id: string;
  answer: string;
  question: string;
  faqFeedback: {
    comment: string;
    feedback: FeedbackType | null;
  }[];
};

export type Collaborator = {
  id: string;
  name: string;
  role: string;
  tickets: {
    id: string;
    displayId: string;
    sourceID: string;
    subject: string;
  }[];
};

export const OpportunityGroupIgnoreReasons = [
  "internal_knowledge",
  "temporary_issue",
  "outdated_information",
  "edge_case",
  "other",
] as const;
export type OpportunityGroupIgnoreReasons =
  (typeof OpportunityGroupIgnoreReasons)[number];

export const ChangeStatusOptions = [
  {
    value: "todo",
    label: "To Verify",
  },
  {
    value: "verify",
    label: "Verify",
  },
  {
    value: "backlog",
    label: "Backlog",
  },
  {
    value: "internal_knowledge",
    label: "Ignore - Internal Knowledge",
  },
  {
    value: "temporary_issue",
    label: "Ignore - Temporary Issue",
  },
  {
    value: "outdated_information",
    label: "Ignore - Outdated Information",
  },
  {
    value: "edge_case",
    label: "Ignore - Edge Case",
  },
  {
    value: "other",
    label: "Ignore - Other",
  },
];
