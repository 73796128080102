// eslint-disable-next-line import/default
import { Fragment } from "react";
import dayjs from "dayjs";
import { JsonView } from "../shared/json-view/JsonView";
import { InfoLine } from "../infoLine";
import { IRequest } from "./types";
import styles from "./styles.module.scss";

export const RequestParser = ({ data }: { data: IRequest }) => {
  let body = {};
  try {
    body = typeof data?.body == "string" ? JSON.parse(data?.body) : data.body;
  } catch (e) {
    console.log({ request: data });
  }

  if (!data) {
    return <Fragment />;
  }

  return (
    <div className={styles.container}>
      <InfoLine label="url:" value={data?.uri} />
      <InfoLine label="method:" value={data?.verb} />
      <InfoLine
        label="time:"
        value={dayjs(data?.time).format("HH:mm:ss - MM/DD/YYYY")}
      />

      <div className={styles.divider} />
      {typeof data.headers === "object"
        ? Object.keys(data.headers).map((key) => {
            return (
              <span key={key}>
                <b>{key}:</b>
                <label className={styles.customLabel}>
                  {data.headers[key]}
                </label>
              </span>
            );
          })
        : data.headers}
      {data.body && (
        <Fragment>
          <div className={styles.divider} />
          <div>
            <JsonView data={body} />
          </div>
        </Fragment>
      )}
    </div>
  );
};
