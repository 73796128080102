import { buildColorMap, RadixColorMap } from "@/components/radixColors";

export interface CommentValue {
  externalId: string;
  createAt: string;
  updateAt: string;
  authorId: string;
  authorName?: string;
  authorEmail?: string;
  isAgent: boolean;
  isInternal: boolean;
  body: string;
  rawBody?: string;
}

export function buildTranscript(
  comments: CommentValue[],
  defaultColorMap: RadixColorMap = {}
) {
  let unknownAgentIndex = 1;
  let unknownUserIndex = 1;

  const authorNames = new Map<string, string>();
  comments.forEach((comment) => {
    if (authorNames.has(comment.authorId)) {
      return;
    }

    const authorName =
      comment.authorName != null && comment.authorName !== ""
        ? comment.authorName
        : comment.authorEmail != null && comment.authorEmail !== ""
        ? comment.authorEmail
        : `Unknown ${comment.isAgent ? "Agent" : "User"} ${
            comment.isAgent ? unknownAgentIndex++ : unknownUserIndex++
          }`;

    authorNames.set(comment.authorId, authorName);
  });

  const commentsWithAuthorName = comments.map((comment) => ({
    ...comment,
    authorName:
      authorNames.get(comment.authorId) ??
      `Unknown ${comment.isAgent ? "Agent" : "User"}`,
  }));

  const authorsToColor = {
    ...defaultColorMap,
    ...buildColorMap(
      [...authorNames]
        .map(([_, authorName]) => authorName)
        .filter((authorName) => defaultColorMap[authorName] == null),
      Object.keys(defaultColorMap).length
    ),
  };

  const commentsWithColors = commentsWithAuthorName
    .sort(
      (a, b) => new Date(a.updateAt).getTime() - new Date(b.updateAt).getTime()
    )
    .map((comment) => {
      return {
        ...comment,
        color: authorsToColor[comment.authorName] ?? "gray",
      };
    });

  return commentsWithColors;
}

const messageTypes = ["public", "internal"] as const;
type MessageType = (typeof messageTypes)[number];

export function isMessageType(value: string): value is MessageType {
  return messageTypes.includes(value as MessageType);
}

export type TicketTranscriptFilterValue = {
  messageType?: MessageType[];
};
