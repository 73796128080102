import { useState, useEffect, useCallback, useMemo } from "react";
import { Navbar, NavbarItem } from "@/components/shared/navbar/Navbar";
import {
  getTicketCollaborators,
  TicketExploreRow,
} from "../../../api/useExplore/Explore.model";
import Select, { SelectOption } from "../../../components/shared/select";
import ExploreTable, { ColumnType, TableSum } from "../../explore/ExploreTable";
import { useValidationSetSample } from "../../../api/useValidations";
import exploreStyles from "../../explore/styles.module.scss";
import styles from "./Validations.module.scss";
import TicketQAScore from "../ticket-qa-score/TicketQAScore";
import {
  ticketExploreCellGenerator,
  ticketExploreCellValueGetter,
} from "../../explore/CellGenerator";
import {
  mixTrackEvent,
  QualityEvents,
  InteractionInformationEvents,
} from "@/assets/mixpanel";

const ticketColumns: ColumnType<keyof TicketExploreRow>[] = [
  {
    label: "Tickets",
    key: "overview",
    width: "45%",
  },
  {
    label: "Avg. QA Score",
    key: "avgQaScore",
  },
  {
    label: "Agent",
    key: "agent",
    minWidth: "200px",
  },
  {
    label: "Collaborators",
    key: "collaborators",
    minWidth: "200px",
  },
  {
    label: "CSAT",
    key: "ticketCsat",
  },
  {
    label: "Validation Status",
    key: "isValidated",
  },
  {
    label: "Avg. Sentiment",
    key: "avgSentiment",
  },
  {
    label: "Created At",
    key: "createdAt",
  },
  {
    label: "Last Update",
    key: "updatedAt",
  },
];

interface ValidationSetProps {
  name: string;
  samplesOptions: SelectOption[];
  backToValidationSets: () => void;
}

function ValidationSet({
  name,
  samplesOptions,
  backToValidationSets,
}: ValidationSetProps) {
  const [selectedSampleId, setSelectedSampleId] = useState<string>(
    samplesOptions?.[0]?.value
  );
  const [ticketId, setTicketId] = useState<TicketExploreRow["id"]>();

  const { data, isLoading, refetch } = useValidationSetSample(selectedSampleId);

  const rows = useMemo<TicketExploreRow[] | undefined>(() => {
    return data?.tickets.map(({ ticket }) => {
      const avgQaScore = ticket.ticketCalculations?.avgQaScores;

      const sentimentSum = ticket.comments.reduce((acc, comment) => {
        return comment.insight ? acc + comment.insight.sentiment : acc;
      }, 0);

      const notNullSentimentsCount =
        ticket.comments.filter((comment) => comment.insight !== null).length ||
        1;

      const commentsCount = ticket?.comments?.reduce(
        (acc, comment) => {
          if (comment.isInternal) {
            acc.internal += 1;
          } else {
            acc.external += 1;
          }

          return acc;
        },
        { external: 0, internal: 0 }
      );

      const collaborators = getTicketCollaborators(ticket);

      return {
        id: ticket.id,
        overview: {
          id: ticket?.displayId ?? ticket.id.split("::")[1],
          subject: ticket?.subject,
        },
        agent: ticket.agentAssigned?.name,
        avgQaScore,
        ticketCsat: ticket.ticketCsat?.score,
        isValidated: ticket.ticketValidation?.isValidated ?? false,
        messagesCount: commentsCount?.external,
        internalNotesCount: commentsCount?.internal,
        avgSentiment: sentimentSum / notNullSentimentsCount,
        createdAt: ticket.createdAt,
        updatedAt: ticket.updatedAt,
        collaborators,
      };
    });
  }, [data]);

  const selectedTicket = useMemo(() => {
    return rows?.find(({ id }) => id === ticketId);
  }, [ticketId, rows]);

  const toggleTicket = useCallback((ticket: TicketExploreRow) => {
    setTicketId(ticket.id);
    mixTrackEvent({
      event: InteractionInformationEvents.OPENED_TICKET_SCORE,
      properties: {
        ticketId: ticket.id,
        source: "validations",
      },
    });
  }, []);

  const handleClose = useCallback(() => {
    setTicketId(undefined);
  }, []);

  useEffect(() => {
    if (samplesOptions.length > 0) {
      setSelectedSampleId(samplesOptions[0].value);
    }
  }, [samplesOptions]);

  useEffect(() => {
    mixTrackEvent({
      event: QualityEvents.VALIDATION_SET_OPENED,
      properties: {
        validationSet: name.toLowerCase(),
        selectedSampleId,
      },
    });
  }, [name, selectedSampleId]);

  const navbarItems = useMemo<NavbarItem[]>(
    () => [
      { title: "quality" },
      { title: "validations", onClick: backToValidationSets },
      { title: name },
    ],
    [name, backToValidationSets]
  );

  return (
    <div className={exploreStyles.exploreContainer}>
      <div className={exploreStyles.controlRow}>
        <Navbar items={navbarItems} />
      </div>

      <div className={exploreStyles.controlRow}>
        <div className={exploreStyles.buttonGroup} />

        <Select
          options={samplesOptions}
          value={selectedSampleId}
          onChange={setSelectedSampleId}
          size={"small"}
          classes={{
            trigger: styles.SampleSelect,
          }}
        />
      </div>

      <TableSum
        label={"random"}
        isLoading={isLoading}
        count={data?.tickets.length}
      />

      <ExploreTable<TicketExploreRow>
        columns={ticketColumns}
        rows={rows}
        isLoading={isLoading}
        cellGenerator={ticketExploreCellGenerator}
        cellValueGetter={ticketExploreCellValueGetter}
        onRowClick={toggleTicket}
      />

      <TicketQAScore
        allowValidateTicket
        ticket={selectedTicket}
        navbarItems={navbarItems}
        onClose={handleClose}
        reloadTickets={refetch}
        defaultTabs={{
          main: "evaluation",
          sidePanel: "transcript",
        }}
      />
    </div>
  );
}

export default ValidationSet;
