import { useContext, useEffect, useRef, useState } from "react";
import { useAuth0, IdToken } from "@auth0/auth0-react";
import { useMutation } from "react-query";
import { params } from "../../params";
import { useAuthorizedQuery } from "..";
import { FeatureAccessContext } from "../../context/featureAccess";

export type AutoResponseAgent =
  | { type: "unassigned" }
  | { type: "agent"; id: number };

export function useGetAutoResponseAgent(enabled: boolean = true) {
  return useAuthorizedQuery<AutoResponseAgent>({
    queryKey: `useGetAutoResponseAgent`,
    url: `${params.API_URL}/platform/auto-activate/auto-response-agent`,
    method: "GET",
    enabled,
  });
}

export type AutoResponseFallbackAgent =
  | { type: "unassigned" }
  | {
      type: "group";
      externalId: string;
    }
  | { type: "agent"; externalId: string; groupId: string };

export function useGetAutoResponseFallbackAgent(enabled: boolean = true) {
  return useAuthorizedQuery<AutoResponseFallbackAgent>({
    queryKey: `useGetAutoResponseFallbackAgent`,
    url: `${params.API_URL}/platform/auto-activate/auto-response-fallback-agent`,
    method: "GET",
    enabled,
  });
}

type UpdateAutoResponseConfigRequest = {
  autoResponseAgentId?: number | null;
  autoResponseFallbackAgent?: string | null;
};

export function useUpdateAutoResponseConfig() {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const { selectedWorkspace } = useContext(FeatureAccessContext);

  const [tokenId, setToken] = useState<IdToken | undefined>(undefined);

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    (async () => {
      const token = await getIdTokenClaims();
      setToken(token);
    })();
  }, [isAuthenticated, getIdTokenClaims]);

  return useMutation<number, unknown, UpdateAutoResponseConfigRequest>({
    mutationKey: `update_auto_response_config`,
    mutationFn: async (data: UpdateAutoResponseConfigRequest) => {
      try {
        abortControllerRef.current?.abort();

        const newAbortController = new AbortController();
        abortControllerRef.current = newAbortController;

        const res = await fetch(
          `${params.API_URL}/platform/auto-activate/tenant-auto-config`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenId?.__raw ?? ""}`,
              "x-tenant-id": selectedWorkspace ?? "",
            },
            body: JSON.stringify(data),
            signal: newAbortController.signal,
          }
        );

        const contentType = res.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          return await res.json();
        } else {
          return await res.text();
        }
      } catch (error) {
        if (error instanceof Error && error.name === "AbortError") {
          return;
        } else {
          const responseError = {
            type: "Error",
            message:
              (error instanceof Error && error.message) ||
              "Something went wrong",
          };

          const customError = new Error(responseError.message);
          Object.assign(customError, responseError);

          throw customError;
        }
      }
    },
  });
}
