import React from "react";
import { useWorkspace } from "../simulator/context/wrokspace";

export const CopilotFeedback = () => {
  const { workspace } = useWorkspace();

  if (workspace !== "fiverr") {
    return <div>sorry seems like something went wrong</div>;
  }

  return (
    <div>
      <iframe
        style={{ width: "100%", height: "94.5vh" }}
        src="https://ticket-feedback-harmony.lovable.app/"
      />
    </div>
  );
};
