import { PropsWithChildren, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Flex, Button, Badge, Text, Spinner } from "@radix-ui/themes";
import { ExternalLinkIcon, ReloadIcon } from "@radix-ui/react-icons";
import Select from "@/components/shared/select";
import { useIntegrations } from "@/api/integration";
import {
  TicketSystemIntegration,
  TicketSystemIntegrationName,
  isTicketSystemIntegration,
} from "@/api/integration/Integration.model";
import { integrationNameToGalleryItem } from "@/api/integration/integrationNameToGalleryItem";
import { useGraphQLAuthorizedQuery } from "@/api";
import { useWorkspace } from "@/routes/simulator/context/wrokspace";
import { graphql } from "../../../gql/gql";
import { params } from "../../../params";
import { useUpdateAutoResponseAgent } from "../useUpdateAutoResponseAgent";
import styles from "../AutomationConfiguration.module.scss";
import { AutomationConfiguration } from "@/routes/automation-configuration/AutomationConfiguration";
import { AutomationConfigurationContentHeader } from "../parts/AutomationConfigurationContentHeader";

const listTenantsByOwnerQueryDocument = graphql(/* GraphQL */ `
  query listTenantsByOwner {
    listTenants {
      nextToken
      items {
        id
        owner
        subdomain
      }
    }
  }
`);

interface Tenant {
  id: string;
  owner: string;
  subdomain: string;
}

function useGetTenant() {
  const { user } = useAuth0();
  const { workspace } = useWorkspace();

  const query = useGraphQLAuthorizedQuery({
    queryKey: ["getTenant", workspace],
    url: `${params.GRAPHQL_URL}/graphql/`,
    document: listTenantsByOwnerQueryDocument,
    variables: { owner: workspace },
    enabled: user != null,
  });

  const data = useMemo<Tenant | undefined>(
    () => query.data?.listTenants?.items?.[0],
    [query.data]
  );

  return {
    ...query,
    data,
  };
}

interface LinkProps {
  to: string;
}

// Workaround to create a to look like radix button
function Link({ children, to }: PropsWithChildren<LinkProps>) {
  return (
    <a
      href={to}
      target="_blank"
      rel="noreferrer"
      data-accent-color={"gray"}
      className={
        "rt-reset rt-BaseButton rt-r-size-1 rt-variant-outline rt-high-contrast rt-Button"
      }
    >
      {children}
    </a>
  );
}

interface StepItemProps {
  stepNumber: number;
  label?: string;
  description?: string;
}

function StepItem({
  stepNumber,
  label,
  description,
  children,
}: PropsWithChildren<StepItemProps>) {
  return (
    <Flex py={"24px"} gap={"16px"} className={styles.StepItem}>
      <Flex direction={"column"} align={"start"}>
        <Badge
          color={"gray"}
          size={"3"}
          highContrast
          className={styles.StepNumer}
        >
          {stepNumber}
        </Badge>
      </Flex>

      <Flex direction={"column"} gap={"4px"}>
        <Text size={"3"} as={"label"} weight={"medium"}>
          {label}
        </Text>

        {description != null && (
          <Text size={"2"} color={"gray"}>
            {description}
          </Text>
        )}

        <Flex className={styles.StepContent}>{children}</Flex>
      </Flex>
    </Flex>
  );
}

const linksToCreateAgent: Record<
  TicketSystemIntegrationName,
  ((subdomain: string) => string) | null
> = {
  zendesk: (subdomain: string) =>
    `https://${subdomain}.zendesk.com/admin/people/team/members`,
  front: null,
  freshdesk: () =>
    "https://crmsupport.freshworks.com/support/solutions/articles/50000010207-onboard-users-agents-in-freshdesk-omni",
  salesforce: () =>
    "https://help.salesforce.com/s/articleView?id=platform.adding_new_users.htm&type=5",
  hubspot: () =>
    "https://knowledge.hubspot.com/account-management/add-and-remove-users",
  intercom: () =>
    "https://www.intercom.com/help/en/articles/182-how-do-i-add-new-teammates-to-intercom",
  kustomer: () =>
    "https://help.kustomer.com/en_us/categories/add-your-agents-to-kustomer-BJMmZh8JL",
  jira: null,
};

function LinkToTicketSystem({
  tenant: { subdomain },
  integration,
}: {
  tenant: Tenant;
  integration: TicketSystemIntegration;
}) {
  const link = linksToCreateAgent[integration.name]?.(subdomain);

  return (
    <Link to={link ?? "#"}>
      {`Open ${integrationNameToGalleryItem[integration.name].title}`}{" "}
      <ExternalLinkIcon />
    </Link>
  );
}

type SelectQuackAgentProps = ReturnType<typeof useUpdateAutoResponseAgent>;

function SelectQuackAgent({
  value,
  onAgentChange,
  agentOptions,
  loadingAgents,
  isAutoResponseAgentLoading,
  isAutoResponseAgentError,
  refetchAutoResponseAgent,
  reloadAgents,
}: SelectQuackAgentProps) {
  if (isAutoResponseAgentLoading) {
    return <Spinner size="2" />;
  }

  if (isAutoResponseAgentError) {
    return (
      <Flex direction={"column"} gap={"2"}>
        <Text size={"2"}>
          An error occurred while loading data. Please try again. If the error
          persists, contact Quack support.
        </Text>

        <Flex>
          <Button
            color="gray"
            variant="solid"
            highContrast
            size="1"
            onClick={() => refetchAutoResponseAgent()}
          >
            <ReloadIcon />
            Retry
          </Button>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex align={"center"} gap={"8px"}>
      <Select
        options={agentOptions}
        value={value?.agent}
        onChange={onAgentChange}
        classes={{ trigger: styles.Select, value: styles.SelectValue }}
        loading={loadingAgents}
        allowSearch
      />

      <Button
        color={"gray"}
        size={"1"}
        variant="outline"
        highContrast
        onClick={reloadAgents}
      >
        <ReloadIcon />
        Refresh list
      </Button>
    </Flex>
  );
}

export function TicketChannelInstallSimulator() {
  const { data: tenant, ...tenantQuery } = useGetTenant();
  const { data: integrations, ...integrationsQuery } = useIntegrations();

  const selectQuackAgentProps = useUpdateAutoResponseAgent();

  const ticketSystemIntegration = useMemo(() => {
    if (integrations == null) {
      return;
    }

    return integrations.find(
      (integration) =>
        integration.type === "TICKET_SYSTEM" &&
        integration.status === "COMPLETED"
    );
  }, [integrations]);

  const isLoading = tenantQuery.isLoading || integrationsQuery.isLoading;
  const isFetched = tenantQuery.isFetched && integrationsQuery.isFetched;

  if (!isFetched || isLoading) {
    return <Spinner size={"2"} />;
  }

  if (
    tenant == null ||
    ticketSystemIntegration == null ||
    !isTicketSystemIntegration(ticketSystemIntegration)
  ) {
    return <Navigate to={"/"} />;
  }

  const ticketSystemIntegrationTitle =
    integrationNameToGalleryItem[ticketSystemIntegration.name].title;

  return (
    <Flex direction={"column"} gap={"24px"}>
      <AutomationConfigurationContentHeader
        title={`Add Quack to ${ticketSystemIntegrationTitle}`}
        status={selectQuackAgentProps.status}
      />

      <Flex direction={"column"}>
        <StepItem
          stepNumber={1}
          label={"Create the Agent"}
          description={`Create a new agent in your ${ticketSystemIntegrationTitle} account to assign Quack as your AI agent.`}
        >
          <LinkToTicketSystem
            tenant={tenant}
            integration={ticketSystemIntegration}
          />
        </StepItem>

        <StepItem
          stepNumber={2}
          label={"Select the Agent"}
          description={
            "Choose the agent you've created to assign Quack for handling tickets."
          }
        >
          <SelectQuackAgent {...selectQuackAgentProps} />
        </StepItem>
      </Flex>
    </Flex>
  );
}
