import { ReactNode } from "react";
import { RadioCards, Flex, Text } from "@radix-ui/themes";
import { RadioItemValue } from "./RadioGroup";

type RadioCardItemValue = RadioItemValue & { icon?: ReactNode };

function RadioCardItem({
  value,
  label,
  description,
  icon,
}: RadioCardItemValue) {
  return (
    <RadioCards.Item value={value} style={{ padding: "8px", width: "100%" }}>
      <Flex width="100%" align={"start"} direction={"column"} gap={"2"}>
        <Flex width="100%" align={"center"} gap={"2"}>
          {icon}

          <Text size={"3"} color={"gray"} weight={"medium"} highContrast>
            {label}
          </Text>
        </Flex>

        {description && (
          <Text size={"2"} color={"gray"}>
            {description}
          </Text>
        )}
      </Flex>
    </RadioCards.Item>
  );
}

interface RadioCardGroupProps {
  value: string;
  onChange: (newValue: string) => void;
  options: (RadioItemValue & { icon?: ReactNode })[];
}

export function RadioCardGroup({
  value,
  onChange,
  options,
}: RadioCardGroupProps) {
  return (
    <RadioCards.Root
      value={value}
      onValueChange={onChange}
      size={"1"}
      color={"gray"}
      highContrast
      style={{ width: "100%", maxWidth: "240px" }}
    >
      {options.map((option, index) => (
        <RadioCardItem key={index} {...option} />
      ))}
    </RadioCards.Root>
  );
}
