import { useMemo } from "react";
import { useAgentScoreFeatFlag } from "@/api/agent-score/useAgentScoreFeatFlag";
import { TicketExploreRow } from "../../../api/useExplore/Explore.model";
import { DataList } from "../../../components/shared/data-list";
import { DataListValue } from "../../../components/shared/data-list/DataList.model";
import styles from "./TicketQAScore.module.scss";

type TicketOverviewProps = TicketExploreRow & { hasRelease?: boolean };

function TicketOverview({
  overview,
  agent,
  collaborators,
  avgQaScore,
  topic,
  status,
  kbCoverage,
  channel,
  ttr,
  category,
  ticketCsat,
  hasRelease = false,
  isValidated,
  avgSentiment,
  organization,
  createdAt,
  updatedAt,
}: TicketOverviewProps) {
  const showCollaborators = useAgentScoreFeatFlag();

  const generalDataList = useMemo<DataListValue[]>(() => {
    const list: DataListValue[] = [
      { title: "Agent", type: "agent", value: agent },
      {
        title: "Collaborators",
        type: "collaborators",
        value: collaborators,
      },
      { title: "QA Score", type: "qaScore", value: avgQaScore },
      {
        title: "Validation",
        type: "ticket-validation",
        value: isValidated,
      },
    ];

    if (!showCollaborators) {
      return list.filter((item) => item.title !== "Collaborators");
    }

    return list;
  }, [agent, collaborators, avgQaScore, isValidated, showCollaborators]);

  const ticketDataList = useMemo<DataListValue[]>(() => {
    const list: DataListValue[] = [
      { title: "Topic", type: "string", value: topic },
      { title: "Organization", type: "string", value: organization },
      { title: "Ticket Status", type: "status", value: status },
      { title: "KB Coverage", type: "kbCoverage", value: kbCoverage },
      { title: "Channel", type: "channel", value: channel },
      { title: "TTR", type: "ttr", value: ttr },
      { title: "Category", type: "category", value: category },
      { title: "CSAT", type: "qaScore", value: ticketCsat },
      { title: "Agent Autonomous", type: "release", value: hasRelease },
      { title: "Sentiment", type: "avgSentiment", value: avgSentiment },
      { title: "Created At", type: "date", value: createdAt },
      { title: "Last Update", type: "date", value: updatedAt },
    ];

    return list.filter((item) => item.value != null);
  }, [
    topic,
    status,
    kbCoverage,
    channel,
    ttr,
    category,
    ticketCsat,
    hasRelease,
    avgSentiment,
  ]);

  return (
    <div className={styles.TicketOverview}>
      <div className={styles.Header}>
        <div className={styles.Subject}>{overview.subject}</div>
        <div className={styles.TicketId}>{`#${overview.id}`}</div>
      </div>

      <div>
        <DataList className={styles.DataList} values={generalDataList} />

        <DataList className={styles.DataList} values={ticketDataList} />
      </div>
    </div>
  );
}

export default TicketOverview;
