import React, { useEffect, useRef, useState, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Keyboard, Spacer, Text, Tooltip } from "@geist-ui/core";
import Select from "../../../components/shared/select";
import MultiSelect from "../../../components/shared/multi-select";
import styles from "./styles.module.scss";
import { useInstructions } from "../context/instructions";
import { Info, Trash } from "@geist-ui/icons";
import { Instruction, ManualInstruction, TTopic } from "../types";
import { Collapsable } from "../../../components/collapsable";
import { mixTrackEvent } from "../../../assets/mixpanel";
import { SimulatorEvents } from "../../../assets/mixpanel/simulator";
import { OptionsInfoMapper, useOptionsMapper } from "./options";
import { EBadgeMode, GeistBadge } from "../../../components/atoms/geistBadge";
import { Button, Kbd, Separator } from "@radix-ui/themes";
import { useFeatureFlag } from "configcat-react";
import { MoveToLiveModeCTA } from "../moveToLiveMode";
import { BriefDialog } from "../briefDialog";
import { MdArticle } from "react-icons/md";
import { useReleases } from "../context/release";

export const Instructions = ({
  topic,
  selectedValue,
  topics,
  setBriefOpen,
  channel,
  tenantGroup,
  showGeneral = true,
  source,
}: {
  topic: string | null;
  selectedValue?: string | null;
  topics?: TTopic[] | undefined;
  setBriefOpen: (value: boolean) => void;
  channel?: string;
  tenantGroup?: string[];
  showGeneral?: boolean;
  source: string;
}) => {
  const { user } = useAuth0();
  const [isOpen, setOpen] = useState(false);

  const { OptionsMapper } = useOptionsMapper();

  const {
    manualInstructions,
    generalInstructions,
    handleGeneralInstruction,
    briefs,
  } = useInstructions();

  const { content, knowledgeEnabled, ticketsEnabled } = useMemo(() => {
    const briefsTopic = briefs.filter((b) => b.topic === topic);

    return {
      content: briefsTopic.find((b) => b.subTopic === null)?.content ?? "",
      knowledgeEnabled:
        briefsTopic.length > 0
          ? (briefsTopic.find((b) => b.subTopic === "knowledge")?.content ??
              "true") === "true"
          : true,
      ticketsEnabled:
        briefsTopic.length > 0
          ? (briefsTopic.find((b) => b.subTopic === "tickets")?.content ??
              "true") === "true"
          : true,
    };
  }, [briefs, topic]);

  const selectedTopic =
    topics?.find((x) => String(x.id) === String(selectedValue))?.topic ?? "";

  const { value: briefManagerEnabled } = useFeatureFlag("briefManager", false, {
    identifier: user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });
  const groupedInstructions = (manualInstructions as ManualInstruction[])
    .filter(
      (instruction) =>
        instruction.content !== "Think carefully before answering"
    )
    .reduce<Record<string, ManualInstruction[]>>((acc, instruction) => {
      const { topic } = instruction;
      if (!acc[topic]) {
        acc[topic] = [];
      }
      acc[topic].push(instruction);
      return acc;
    }, {});

  const onGeneralChange = (value: string, key: string) => {
    handleGeneralInstruction(
      generalInstructions.map((x) => {
        if (x.topic === key) {
          return {
            ...x,
            content: value,
          };
        }
        return x;
      })
    );
  };

  const { simulator } = useReleases();
  const trackingData = {
    source,
    channel: simulator?.channel,
    topic,
  };

  if (topic) {
    return (
      <>
        <div className={styles.container}>
          <Text h4 style={{ textTransform: "capitalize" }}>
            {topic}
          </Text>
          <div>
            <div
              style={{
                fontSize: "12px",
                whiteSpace: "break-spaces",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <div style={{ display: "flex", gap: "8px" }}>
                {knowledgeEnabled ? "Including" : "Excluding"} Knowledge
                <Separator orientation="vertical" size="1" />
                {ticketsEnabled ? "Including" : "Excluding"} Tickets
              </div>
              <Separator size="4" />
              {content}
            </div>
            <Button
              color="gray"
              variant="ghost"
              style={{ marginTop: "8px" }}
              onClick={() => {
                setOpen(true);
              }}
            >
              {content ? "Edit" : "Add"} Brief
            </Button>
          </div>
          <Spacer h={0.7} />
          <Text small>
            <div style={{ display: "flex", gap: "6px" }}>
              instructions{" "}
              <GeistBadge mode={EBadgeMode.DEFAULT}>
                {groupedInstructions[topic]?.length ?? 0}
              </GeistBadge>
            </div>
          </Text>

          {(!groupedInstructions[topic] ||
            groupedInstructions[topic]?.length === 0) && (
            <div className={styles.emptyStateContainer}>
              <Text h5>No instructions has been submitted yet</Text>
              <Text small>
                Press{" "}
                <Keyboard scale={0.7} mr="10px">
                  /
                </Keyboard>
                to add instructions
              </Text>
            </div>
          )}

          {groupedInstructions[topic]?.map((i, index) => {
            return (
              <InstructionCard
                instruction={i}
                index={index}
                key={i.content}
                source={source}
              />
            );
          })}
        </div>
        <BriefDialog
          open={isOpen}
          topicName={topic ?? ""}
          openChange={(v) => setOpen(v)}
        />
      </>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <Text h4>
          <span style={{ textTransform: "capitalize" }}>
            {simulator?.name.trim()}
          </span>
          's Brain
        </Text>

        <div className={styles.instructionsScrollWrapper}>
          {showGeneral && (
            <>
              <Text small>General</Text>
              <Collapsable
                isFullWidth
                isChildrenInSection
                initialCollapsedValue
                title={
                  <Text h6 style={{ textTransform: "capitalize", margin: "0" }}>
                    Preferences
                  </Text>
                }
              >
                <div className={styles.generalInstructionsContainer}>
                  {generalInstructions.map((g) => {
                    return (
                      <div className={styles.generalInstruction}>
                        <div className={styles.generalInstructionLabel}>
                          <Text small>
                            {
                              OptionsInfoMapper[
                                g.topic as keyof typeof OptionsInfoMapper
                              ]?.title
                            }
                          </Text>
                          <Tooltip
                            text={
                              OptionsInfoMapper[
                                g.topic as keyof typeof OptionsInfoMapper
                              ]?.info
                            }
                            type="dark"
                          >
                            <Info />
                          </Tooltip>
                        </div>
                        {g.topic === "allowedLanguages" ||
                        g.topic === "brand" ? (
                          <MultiSelect
                            options={
                              OptionsMapper[
                                g.topic as keyof typeof OptionsMapper
                              ]?.map((option: string) => ({
                                label: option === "400" ? "unlimited" : option,
                                value: option,
                              })) ?? []
                            }
                            value={g.content.split(",")}
                            onChange={(chosen: any) => {
                              if (g.content.split(",").includes(chosen)) {
                                onGeneralChange(
                                  g.content
                                    .split(",")
                                    .filter((k) => k !== chosen)
                                    .join(",") as string,
                                  g.topic!
                                );
                              } else {
                                onGeneralChange(
                                  [...g.content.split(","), chosen].join(
                                    ","
                                  ) as string,
                                  g.topic!
                                );
                              }
                            }}
                          />
                        ) : (
                          <Select
                            value={String(g.content)}
                            // className={styles.select}
                            onChange={(value) => {
                              onGeneralChange(value as string, g.topic!);
                            }}
                            options={
                              OptionsMapper[
                                g.topic as keyof typeof OptionsMapper
                              ]?.map((option: string) => ({
                                label: option === "400" ? "unlimited" : option,
                                value: option,
                              })) ?? []
                            }
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </Collapsable>
            </>
          )}

          <Text small>
            <div style={{ display: "flex", gap: "6px" }}>
              instructions{" "}
              <GeistBadge mode={EBadgeMode.DEFAULT}>
                {
                  manualInstructions.filter(
                    (i) => i.content !== "Think carefully before answering"
                  ).length
                }
              </GeistBadge>
            </div>
          </Text>

          {briefManagerEnabled && (
            <div>
              <Button
                type={"button"} // important - otherwise form will submit on click
                color={"gray"}
                variant={"ghost"}
                highContrast
                size={"1"}
                className={styles.shortcut}
                onClick={() => {
                  setBriefOpen(true);
                  mixTrackEvent({
                    event: SimulatorEvents.START_BRIEF,
                    properties: trackingData,
                  });
                }}
              >
                <Kbd size={"3"} style={{ height: "22px" }}>
                  <MdArticle />
                </Kbd>
                <Text small>Add Brief for "{selectedTopic}"</Text>
              </Button>
            </div>
          )}
          {manualInstructions.length === 0 && (
            <div className={styles.emptyStateContainer}>
              <Text h5>Your training space awaits</Text>
              <Text small>
                This panel will collect instructions and feedback as you
                interact with the simulator.
              </Text>
            </div>
          )}
          {Object.keys(groupedInstructions).map((topic) => {
            return (
              <Collapsable
                initialCollapsedValue={true}
                title={
                  <Text
                    h6
                    style={{
                      textTransform: "capitalize",
                      margin: "0",
                      display: "flex",
                      gap: "6px",
                      alignItems: "center",
                    }}
                  >
                    {topic}{" "}
                    <GeistBadge mode={EBadgeMode.DEFAULT}>
                      {groupedInstructions[topic].length}
                    </GeistBadge>
                  </Text>
                }
              >
                <div className={styles.instructionsContainer}>
                  {groupedInstructions[topic].map((i, index) => {
                    return (
                      <InstructionCard
                        instruction={i}
                        index={index}
                        key={i.content}
                        source={source}
                      />
                    );
                  })}
                </div>
              </Collapsable>
            );
          })}
        </div>
        <MoveToLiveModeCTA
          topic={selectedValue}
          topics={topics}
          channel={channel}
          tenantGroup={tenantGroup}
        />
      </div>
      <BriefDialog
        open={isOpen}
        topicName={topic ?? ""}
        openChange={(v) => setOpen(v)}
      />
    </>
  );
};

const InstructionCard = ({
  instruction,
  index,
  source,
}: {
  instruction: Instruction;
  index: number;
  source: string;
}) => {
  const [, setHover] = useState<boolean>(false);
  const { simulator } = useReleases();
  const { deleteInstruction } = useInstructions();
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    cardRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const trackingData = {
    source,
    channel: simulator?.channel,
    topic: instruction.topic,
    subTopic: instruction.subTopic,
  };

  return (
    <Card
      ref={cardRef}
      className={styles.card}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Text small>{instruction.content}</Text>
      {instruction.subTopic && (
        <div className={styles.badges}>
          <GeistBadge mode={EBadgeMode.BLUE}>{instruction.subTopic}</GeistBadge>
        </div>
      )}

      {instruction.type === "manual" && (
        <button
          className={styles.btn}
          onClick={() => {
            mixTrackEvent({
              event: SimulatorEvents.DELETE_INSTRUCTION,
              properties: {
                ...trackingData,
                instructionLength: instruction.content.length,
              },
            });
            deleteInstruction(instruction, index);
          }}
        >
          <Trash />
        </button>
      )}
    </Card>
  );
};
