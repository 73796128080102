export const opportunityStatuses = [
  "backlog",
  "todo",
  "verify",
  "solved",
  "hidden",
] as const;
export type OpportunityStatus = (typeof opportunityStatuses)[number];

export const opportunityStatusesToLabels = {
  hidden: "Ignored",
  todo: "To Verify",
  verify: "Verified",
  backlog: "Backlog",
};

export const opportunityGroupPriorities = [
  "none",
  "low",
  "medium",
  "high",
  "critical",
] as const;
export type OpportunityGroupPriority =
  (typeof opportunityGroupPriorities)[number];

export const opportunitiesGroupType = [
   'knowledge',
   'unanswered_question',
   'escalated_question',
   'user_message_feedback',
   'admin_message_feedback',
  'low_csat',
] as const;
export type OpportunitiesGroupType = (typeof opportunitiesGroupType)[number];

export type OpportunityGroups = {
  id: string;
  tenantId: string;
  externalId: string;
  label: string;
  createdAt: string;
  updatedAt: string;
  status: OpportunityStatus;
  priority: OpportunityGroupPriority;
  saved: boolean;
  verifiedAt: string | null;
  hiddenReason: string | null;
  currentTimeSpanTickets: number;
  compareTimeSpanTickets: number;
}[];
