import React, { useEffect, useState } from "react";
import { useTicketInfo } from "@/api/useTicketInfo";
import TicketQAScore from "@/routes/quality/ticket-qa-score/TicketQAScore";
import { TicketExploreRow } from "@/api/useExplore/Explore.model";
import { TicketScore } from "@/routes/quality/ticket-qa-score/TicketQAScore.model";

interface TicketsInfoProps {
  tickets: TicketListItem[];
  open: boolean;
  setOpen: (open: boolean) => void;
}

export type TicketListItem = {
  displayId: string;
  sourceId: string;
  id: string;
  isOpen: boolean;
  subject: string;
};

export const TicketsInfo = ({ tickets, open, setOpen }: TicketsInfoProps) => {
  const [ticket, setTicket] = useState<TicketListItem | null>(null);
  const [ticketInfo, setTicketInfo] = useState<
    (TicketExploreRow & { ticketScore?: TicketScore[] }) | null
  >(null);

  const { ticketData } = useTicketInfo({
    displayId: ticket?.displayId ?? "",
    sourceID: ticket?.sourceId ?? "",
    id: ticket?.id ?? "",
    isOpen: Boolean(ticket?.id && open),
  });

  useEffect(() => {
    if (ticketData?.id) {
      setTicketInfo(ticketData);
    }
  }, [ticketData]);

  useEffect(() => {
    if (tickets?.length > 0) {
      setTicket(tickets[0]);
    }
  }, [tickets]);

  return (
    <>
      {ticketInfo?.status && open && (
        <TicketQAScore
          ticket={ticketInfo}
          navbarItems={[{ title: "Related tickets" }]}
          onClose={() => setOpen(false)}
          reloadTickets={async () => {}}
          allowValidateTicket={false}
          viewOnly
          ticketListPanel={true}
          ticketsList={tickets}
          setActiveTicketId={(id) => {
            setTicket(tickets.find((ticket) => ticket.id === id) ?? null);
          }}
        />
      )}
    </>
  );
};
