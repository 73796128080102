import { Flex, Text, Badge, Spinner } from "@radix-ui/themes";
import { CheckCircledIcon } from "@radix-ui/react-icons";
import { UpdateStatus } from "../utils/automation-configuration.utils";

interface AutomationConfigurationContentHeaderProps {
  title: string;
  status: UpdateStatus;
}

export function AutomationConfigurationContentHeader({
  title,
  status = "idle",
}: AutomationConfigurationContentHeaderProps) {
  return (
    <Flex justify={"between"} align={"center"} gap={"12px"}>
      <Text size={"3"} weight={"medium"}>
        {title}
      </Text>

      {status === "saving" && (
        <Flex align={"center"} gap={"1"}>
          <Spinner size={"1"} />
          <Text size={"1"} color={"gray"}>
            Saving ...
          </Text>
        </Flex>
      )}

      {status === "saved" && (
        <Badge color={"green"} size={"1"}>
          <CheckCircledIcon />
          Saved
        </Badge>
      )}
    </Flex>
  );
}
