import { useCallback, useMemo, useState } from "react";
import cx from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { Badge, Button, IconButton, TextArea } from "@radix-ui/themes";
import {
  CircleBackslashIcon,
  CheckCircledIcon,
  PlusIcon,
  DrawingPinIcon,
  Cross2Icon,
} from "@radix-ui/react-icons";
import { params } from "../../../params";
import { SelectOption } from "@/components/shared/select";
import { DropdownMenu } from "@/components/shared/dropdown-menu";
import { Tooltip } from "@/components/shared/tooltip";
import { useAuthorizedMutation } from "@/api";
import { isYotpo } from "@/api/user";
import { mixTrackEvent, InteractionInformationEvents } from "@/assets/mixpanel";
import {
  questionToReasons,
  ReasonForNegativeScore,
  reasonsForNegativeScore,
  reasonsForNegativeScoreFormatted,
  TicketScore,
} from "./TicketQAScore.model";
import styles from "./TicketQAScore.module.scss";

function ScoreButton({
  value,
  active,
  updateTicketScore,
  variant = "number",
  label,
  hint,
  hintAlign = "center",
  viewOnly = false,
}: {
  value: number;
  active: boolean;
  updateTicketScore?: (newScore: number) => Promise<void>;
  variant?: "number" | "binary";
  label?: string;
  hint?: string;
  hintAlign?: "left" | "center" | "right";
  viewOnly?: boolean;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = useCallback(async () => {
    if (active || updateTicketScore == null) {
      return;
    }

    setIsLoading(true);
    await updateTicketScore(value);
    setIsLoading(false);
  }, [active, value, updateTicketScore]);

  return (
    <Button
      size={"1"}
      variant={"outline"}
      color={"gray"}
      className={cx(styles.ScoreButton, styles[`value-${variant}-${value}`], {
        [styles.active]: active,
        [styles.hasHint]: hint != null,
        [styles.readonly]: updateTicketScore == null,
      })}
      onClick={handleOnClick}
      loading={isLoading}
      disabled={viewOnly}
    >
      {variant === "binary" && (
        <>
          {value === 0 && <CircleBackslashIcon />}
          {value === 1 && <CheckCircledIcon />}
        </>
      )}

      {label ?? value}

      {hint != null && (
        <div className={cx(styles.ScoreButtonHint, styles[hintAlign])}>
          {hint}
        </div>
      )}
    </Button>
  );
}

type TicketScoreResultLabelProps = Pick<
  TicketScore,
  "type" | "autoScore" | "adjustedScore"
>;

function TicketScoreResultLabel({
  type,
  autoScore,
  adjustedScore,
}: TicketScoreResultLabelProps) {
  const isAdjusted = adjustedScore != null && adjustedScore !== autoScore;

  if (!isAdjusted) {
    return <div className={styles.Label}>Result</div>;
  }

  return (
    <Tooltip>
      <Tooltip.Trigger>
        <div className={styles.Label}>
          <div className={styles.Adjusted} />
          Result
        </div>
      </Tooltip.Trigger>

      <Tooltip.Content>
        <div className={styles.AdjustedTooltip}>
          <div className={styles.Label}>Adjusted result </div>
          <div className={styles.Content}>
            {type === "binary" ? (
              <>
                <ScoreButton
                  variant={"binary"}
                  value={autoScore}
                  active
                  label={"Not achieved"}
                  hint={"AI scored"}
                />

                <ScoreButton
                  variant={"binary"}
                  value={adjustedScore}
                  active
                  label={"Achieved"}
                  hint={"Adjusted score"}
                />
              </>
            ) : (
              <>
                <ScoreButton value={autoScore} active hint={"AI scored"} />

                <ScoreButton
                  value={adjustedScore}
                  active
                  hint={"Adjusted score"}
                />
              </>
            )}
          </div>
        </div>
      </Tooltip.Content>
    </Tooltip>
  );
}

function TicketScoreResultNumeric({
  value,
  updateTicketScore,
  viewOnly = false,
}: {
  value: number;
  updateTicketScore: (newScore: number) => Promise<void>;
  viewOnly?: boolean;
}) {
  return (
    <div className={styles.TicketScoreResult}>
      <ScoreButton
        value={1}
        active={value === 1}
        updateTicketScore={updateTicketScore}
        hint={"Not achieved"}
        hintAlign={"left"}
        viewOnly={viewOnly}
      />
      <ScoreButton
        value={2}
        active={value === 2}
        updateTicketScore={updateTicketScore}
        viewOnly={viewOnly}
      />
      <ScoreButton
        value={3}
        active={value === 3}
        updateTicketScore={updateTicketScore}
        hint={"Partly achieved"}
        viewOnly={viewOnly}
      />
      <ScoreButton
        value={4}
        active={value === 4}
        updateTicketScore={updateTicketScore}
        viewOnly={viewOnly}
      />
      <ScoreButton
        value={5}
        active={value === 5}
        updateTicketScore={updateTicketScore}
        hint={"Achieved"}
        hintAlign={"right"}
        viewOnly={viewOnly}
      />
    </div>
  );
}

function TicketScoreResultBinary({
  value,
  updateTicketScore,
  viewOnly = false,
}: {
  value: number;
  updateTicketScore: (newScore: number) => Promise<void>;
  viewOnly?: boolean;
}) {
  return (
    <div className={styles.TicketScoreResult}>
      <ScoreButton
        variant={"binary"}
        value={0}
        active={value === 0}
        updateTicketScore={updateTicketScore}
        label={"Not achieved"}
        viewOnly={viewOnly}
      />
      <ScoreButton
        variant={"binary"}
        value={1}
        active={value === 1}
        updateTicketScore={updateTicketScore}
        label={"Achieved"}
        viewOnly={viewOnly}
      />
    </div>
  );
}

function TicketScoreComment({
  value,
  updateTicketScore,
  viewOnly = false,
}: {
  value?: string;
  updateTicketScore: (newValue: string) => Promise<void>;
  viewOnly?: boolean;
}) {
  const [comment, setComment] = useState(value);

  const handleBlur = useCallback(() => {
    if (comment !== value) {
      updateTicketScore(comment || "");
    }
  }, [comment, value, updateTicketScore]);

  return !viewOnly || value ? (
    <TextArea
      variant={"soft"}
      color={"gray"}
      value={comment}
      onChange={(e) => setComment(e.target.value)}
      onBlur={handleBlur}
      className={styles.TicketScoreComment}
      placeholder={"Add comment"}
      disabled={viewOnly}
    />
  ) : null;
}

function TicketScoreReasons({
  reasons = [...reasonsForNegativeScore],
  value,
  updateTicketScore,
  viewOnly = false,
}: {
  reasons?: ReasonForNegativeScore[];
  value?: ReasonForNegativeScore[];
  updateTicketScore: (newValue: ReasonForNegativeScore[]) => Promise<void>;
  viewOnly?: boolean;
}) {
  const isEmpty = value == null || value.length === 0;

  const [addingReason, setAddingReason] = useState(false);
  const [reasonInRemoving, setReasonInRemoving] = useState<string>();

  const options = useMemo<SelectOption[]>(() => {
    if (isEmpty) {
      return reasons.map((value) => ({
        value,
        label: reasonsForNegativeScoreFormatted[value],
      }));
    }

    const valueAsMap = new Set(value);
    return reasons
      .filter((reason) => !valueAsMap.has(reason))
      .map((value) => ({
        value,
        label: reasonsForNegativeScoreFormatted[value],
      }));
  }, [isEmpty, value, reasons]);

  const addReason = useCallback(
    async (reason: string) => {
      setAddingReason(true);
      await updateTicketScore([
        ...(value ?? []),
        reason as ReasonForNegativeScore,
      ]);
      setAddingReason(false);
    },
    [value, updateTicketScore]
  );

  const removeReason = useCallback(
    async (reason: string) => {
      setReasonInRemoving(reason);
      await updateTicketScore(
        (value ?? []).filter((r) => r !== reason) as ReasonForNegativeScore[]
      );
      setReasonInRemoving(undefined);
    },
    [value, updateTicketScore]
  );

  return (
    <div className={styles.TicketScoreReason}>
      {value?.map((reason) => (
        <Badge
          color={"gray"}
          key={reason}
          size={"2"}
          className={styles.ReasonItem}
        >
          {reasonsForNegativeScoreFormatted[reason]}

          <IconButton
            color="gray"
            variant="ghost"
            highContrast
            size="1"
            loading={reasonInRemoving === reason}
            onClick={() => removeReason(reason)}
            disabled={viewOnly}
          >
            <Cross2Icon />
          </IconButton>
        </Badge>
      ))}

      {!viewOnly && (
        <DropdownMenu
          items={options.map((option) => ({
            label: option.label,
            onClick: () => addReason(option.value),
          }))}
        >
          <DropdownMenu.Trigger>
            {isEmpty ? (
              <Button
                color="gray"
                variant="ghost"
                highContrast
                size="1"
                loading={addingReason}
                className={styles.ReasonMenuButton}
                disabled={viewOnly}
              >
                <DrawingPinIcon />
                Add reason
              </Button>
            ) : (
              <IconButton
                color="gray"
                variant="ghost"
                highContrast
                size="1"
                loading={addingReason}
                className={styles.ReasonMenuButton}
                disabled={viewOnly}
              >
                <PlusIcon />
              </IconButton>
            )}
          </DropdownMenu.Trigger>
        </DropdownMenu>
      )}
    </div>
  );
}

function TicketScoreResult({
  type,
  id,
  content,
  autoScore,
  adjustedScore,
  reasonsForNegativeScore,
  comment,
  onUpdateTicketScore,
  viewOnly = false,
}: TicketScore & {
  onUpdateTicketScore: () => Promise<unknown>;
  viewOnly?: boolean;
}) {
  const { mutateAsync } = useAuthorizedMutation({
    url: `${params.API_URL}/platform/sampling/ticket-scores`,
    method: "PUT",
  });

  const updateTicketScore = useCallback(
    async (newScore: {
      adjustedScore?: number;
      comment?: string;
      reasonsForNegativeScore?: ReasonForNegativeScore[];
    }) => {
      const newTicketScore = {
        scoreId: id,
        adjustedScore: newScore.adjustedScore ?? adjustedScore,
        comment: newScore.comment ?? comment,
        reasonsForNegativeScore:
          newScore.reasonsForNegativeScore ?? reasonsForNegativeScore,
      };

      const nonNullableTicketScore = Object.fromEntries(
        Object.entries(newTicketScore).filter(([, value]) => value != null)
      );

      if (
        newScore.adjustedScore !== undefined &&
        adjustedScore !== newScore.adjustedScore
      ) {
        mixTrackEvent({
          event: InteractionInformationEvents.ADJUSTED_TICKET_AI_SCORE,
          properties: {
            ticketId: content,
            type: type,
            oldScore: adjustedScore ?? autoScore,
            newScore: newScore.adjustedScore,
          },
        });
      }

      await mutateAsync(nonNullableTicketScore);
      await onUpdateTicketScore();
    },
    [
      mutateAsync,
      onUpdateTicketScore,
      adjustedScore,
      comment,
      id,
      reasonsForNegativeScore,
    ]
  );

  const value = adjustedScore ?? autoScore;

  if (type === "binary") {
    return (
      <div className={styles.TicketScore}>
        <TicketScoreResultLabel
          type={type}
          autoScore={autoScore}
          adjustedScore={adjustedScore}
        />

        <TicketScoreResultBinary
          value={value}
          updateTicketScore={(adjustedScore) =>
            updateTicketScore({ adjustedScore })
          }
          viewOnly={viewOnly}
        />

        <div className={styles.Label}>Reasons</div>

        <TicketScoreReasons
          reasons={questionToReasons[content]}
          value={reasonsForNegativeScore}
          updateTicketScore={(reasonsForNegativeScore) =>
            updateTicketScore({ reasonsForNegativeScore })
          }
          viewOnly={viewOnly}
        />

        <div className={styles.Label}>Comment</div>

        <TicketScoreComment
          value={comment}
          updateTicketScore={(comment) => updateTicketScore({ comment })}
          viewOnly={viewOnly}
        />
      </div>
    );
  }

  return (
    <div className={styles.TicketScore}>
      <TicketScoreResultLabel
        type={type}
        autoScore={autoScore}
        adjustedScore={adjustedScore}
      />

      <TicketScoreResultNumeric
        value={value}
        updateTicketScore={(adjustedScore) =>
          updateTicketScore({ adjustedScore })
        }
        viewOnly={viewOnly}
      />
    </div>
  );
}

function formatQuestionTitle(title: string): string {
  return title
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/-/g, " ")
    .toLowerCase()
    .replace(/^\w/, (char) => char.toUpperCase());
}

type QuestionProps = TicketScore & {
  onUpdateTicketScore: () => Promise<unknown>;
};

export function Question(props: QuestionProps & { viewOnly?: boolean }) {
  const { id, title, content, viewOnly } = props;
  const { user } = useAuth0();

  const formattedTitle = useMemo(() => formatQuestionTitle(title), [title]);

  return (
    <div className={styles.Question} key={id}>
      {isYotpo(user) ? (
        <div className={styles.Header}>
          <div className={styles.Title}>{content}</div>
        </div>
      ) : (
        <div className={styles.Header}>
          <div className={styles.Title}>{formattedTitle}</div>
          <div className={styles.Content}>{content}</div>
        </div>
      )}

      <TicketScoreResult {...props} viewOnly={viewOnly} />
    </div>
  );
}
