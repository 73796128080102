import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";
import request, { RequestDocument } from "graphql-request";
import { useMutation, useQuery, useInfiniteQuery } from "react-query";
import { IdToken } from "@auth0/auth0-spa-js";
import { FeatureAccessContext } from "../context/featureAccess";

export const useGraphQLAuthorizedQuery = <DataType = any>({
  queryKey,
  url,
  document,
  variables,
  enabled,
  cacheTime = 5 * 60 * 1000,
}: {
  queryKey: any[];
  url: string;
  document: RequestDocument;
  variables: { [key: string]: any };
  enabled: boolean;
  cacheTime?: number;
}) => {
  const [tokenId, setToken] = useState<IdToken | undefined>(undefined);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    (async () => {
      const token = await getIdTokenClaims();
      setToken(token);
    })();
  }, [isAuthenticated, getIdTokenClaims]);

  const headers = {
    "Content-Type": "application/json",
    authorization: tokenId?.__raw ? tokenId.__raw : "",
  };

  return useQuery<DataType>({
    queryKey,
    queryFn: async () => {
      return await request(url, document, variables, headers);
    },
    enabled: enabled && isAuthenticated && !!tokenId,
    cacheTime: cacheTime,
  });
};

type TRequestOptions = {
  method: string;
  headers: Record<string, string>;
  body?: any;
};

export const useAuthorizedMutation = <
  DataType = any,
  VariablesType = Record<string, any>
>({
  url,
  method = "POST",
  onSuccess,
}: {
  url: string;
  method?: string;
  onSuccess?: (
    data: DataType,
    variables: VariablesType,
    context: unknown
  ) => Promise<unknown> | void;
}) => {
  const [tokenId, setToken] = useState<IdToken | undefined>(undefined);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const { selectedWorkspace } = useContext(FeatureAccessContext);

  useEffect(() => {
    (async () => {
      const token = await getIdTokenClaims();
      setToken(token);
    })();
  }, [isAuthenticated, getIdTokenClaims]);

  const authToken = tokenId?.__raw ? tokenId.__raw : "";
  let headers: Record<string, string> = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  if (selectedWorkspace) {
    headers["x-tenant-id"] = selectedWorkspace;
  }

  return useMutation<DataType, unknown, VariablesType>({
    mutationFn: async (data: VariablesType | null) => {
      if (!data) {
        throw new Error("useMutation: no data supplied");
      }
      const options: TRequestOptions = {
        method: method,
        headers,
      };
      if (method !== "GET") {
        options.body = JSON.stringify(data);
      }

      const res = await fetch(url, options);
      let result;
      const contentType = res.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        result = await res.json();
      } else {
        result = await res.text();
      }

      if (res.ok) {
        return result;
      }

      const responseError = {
        type: "Error",
        message: result.message || "Something went wrong",
        code: result.statusCode,
      };

      let error = new Error();
      error = { ...error, ...responseError };

      throw error;
    },
    onSuccess,
  });
};

export const useAuthorizedQuery = <
  DataType = any,
  VariablesType = Record<string, any>
>({
  url,
  queryKey,
  enabled,
  method = "GET",
  cacheTime = 5 * 60 * 1000,
  refetchInterval,
  body,
}: {
  url: string;
  queryKey: string;
  enabled: boolean;
  method?: string;
  cacheTime?: number;
  refetchInterval?: number;
  body?: VariablesType;
}) => {
  const [tokenId, setToken] = useState<IdToken | undefined>(undefined);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const { selectedWorkspace } = useContext(FeatureAccessContext);

  useEffect(() => {
    (async () => {
      const token = await getIdTokenClaims();
      setToken(token);
    })();
  }, [isAuthenticated, getIdTokenClaims]);

  const authToken = tokenId?.__raw ? tokenId.__raw : "";
  let headers: Record<string, string> = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  if (selectedWorkspace) {
    headers["x-tenant-id"] = selectedWorkspace;
  }

  return useQuery<DataType>({
    queryKey: `${queryKey}-${selectedWorkspace}`,
    queryFn: async (data: Record<string, any> | null) => {
      if (!data) {
        throw new Error("useQuery: no data supplied");
      }
      const options: TRequestOptions = {
        method: method,
        headers,
      };
      if (method !== "GET" && body != null) {
        options.body = JSON.stringify(body);
      }
      const res = await fetch(url, options);
      let result;
      const contentType = res.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        result = await res.json();
      } else {
        result = await res.text();
      }

      if (res.ok) {
        return result;
      }

      const responseError = {
        type: "Error",
        message: result.message || "Something went wrong",
        code: result.statusCode,
      };

      return responseError;
      // let error = new Error();
      // error = { ...error, ...responseError };

      // throw error;
    },
    enabled: enabled && isAuthenticated && !!tokenId,
    cacheTime: cacheTime,
    refetchInterval,
  });
};

export const useAuthorizedInfiniteQuery = <DataType = any>({
  url,
  queryKey,
  enabled,
  method = "GET",
  cacheTime = 5 * 60 * 1000,
  body = {},
  pageKey = "page",
  getNextPageParam,
}: {
  url: string;
  queryKey: string;
  enabled: boolean;
  method?: string;
  cacheTime?: number;
  body?: any;
  pageKey?: string;
  getNextPageParam?: (lastPage: DataType, allPages: DataType[]) => unknown;
}) => {
  const [tokenId, setToken] = useState<IdToken | undefined>(undefined);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const { selectedWorkspace } = useContext(FeatureAccessContext);

  useEffect(() => {
    (async () => {
      const token = await getIdTokenClaims();
      setToken(token);
    })();
  }, [isAuthenticated, getIdTokenClaims]);

  const authToken = tokenId?.__raw ? tokenId.__raw : "";
  let headers: Record<string, string> = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  if (selectedWorkspace) {
    headers["x-tenant-id"] = selectedWorkspace;
  }

  return useInfiniteQuery<DataType>({
    queryKey: `${queryKey}-${selectedWorkspace}`,
    queryFn: async ({ pageParam = 1 }) => {
      const options: TRequestOptions = {
        method: method,
        headers,
      };
      if (method !== "GET") {
        options.body = JSON.stringify({ [pageKey]: pageParam, ...body });
      }
      const res = await fetch(url, options);
      return res.json();
    },
    enabled: enabled && isAuthenticated && !!tokenId,
    cacheTime: cacheTime,
    getNextPageParam,
  });
};
