const updateStatuses = ["idle", "saving", "saved", "error"];
export type UpdateStatus = (typeof updateStatuses)[number];

export function mergeStatus(
  status1: UpdateStatus,
  status2: UpdateStatus
): UpdateStatus {
  const priorities: Record<UpdateStatus, number> = {
    idle: 0,
    saved: 1,
    saving: 2,
    error: 3,
  };

  return priorities[status1] >= priorities[status2] ? status1 : status2;
}
