import { useEffect, useMemo, useState } from "react";
import { useFeatureFlag } from "configcat-react";
import { useReleases } from "../simulator/context/release";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { Release } from "../simulator/types";
import {
  Button,
  Card,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Tooltip,
} from "@radix-ui/themes";
import styles from "./styles.module.scss";
import { Dot, Input } from "@geist-ui/core";
import { Send } from "@geist-ui/icons";
import {
  CrossCircledIcon,
  DotsVerticalIcon,
  Pencil1Icon,
  TrashIcon,
} from "@radix-ui/react-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { DropdownMenu } from "../../components/shared/dropdown-menu";
import { useDeleteSimulator } from "../../api/useRelease";
import { Dialog } from "../../components/shared/dialog";
import { mixTrackEvent, SimulatorEvents } from "@/assets/mixpanel";
import { Navbar } from "@/components/shared/navbar/Navbar";
import {
  simulatorChannelContentMap,
  SimulatorsEmptyState,
} from "./SimulatorsEmptyState";
import { SimulatorChannelIcon } from "./SimulatorChannelIcon";
import { DropdownMenuItem } from "@/components/shared/dropdown-menu/DropdownMenu";
import { useOnboarding } from "@/api/onboarding";
import { PageEmptyState } from "@/components/page-empty-state/PageEmptyState";
import { SimulatorChannel } from "@/api/simulator/simulator.model";

export const channelMapper: Record<
  SimulatorChannel,
  { label: string; internal: boolean; description: string }
> = {
  helpcenter: {
    label: "Help center chatbot",
    internal: false,
    description: "AI search experience on top of your Help center",
  },
  autonomous_ticket_event: {
    label: "",
    internal: false,
    description: "Answer your tickets in Email & Form channels",
  },
  autonomous_quackchat: {
    label: "",
    internal: false,
    description: "Answer your Chat conversations",
  },
  slackbot: {
    label: "",
    internal: true,
    description: "Provide Internal Support in your Slack Channels",
  },
  copilot: {
    label: "",
    internal: true,
    description:
      "Automate tasks and provide intelligent suggestions to enhance productivity.",
  },
};

function OnboardingSimulatorsEmptyState() {
  const [searchParams] = useSearchParams();
  const { hasTicketSystemIntegration, backfillStatus } = useOnboarding();

  const fParam = searchParams.get("f");
  const isInternal = fParam === "copilot";

  if (isInternal) {
    if (!hasTicketSystemIntegration || backfillStatus !== "completed") {
      return (
        <PageEmptyState
          title={"Co-Pilot agents will appear here"}
          description={
            "This is where you'll set up your Co-Pilot to assist agents with real-time AI-powered recommendations."
          }
          svg={"co-pilot"}
        />
      );
    } else {
      return (
        <PageEmptyState
          title={"Preparing your Co-Pilot AI agent..."}
          description={
            "Your Co-Pilot AI agent will be ready soon to enhance and streamline your agents' workflows."
          }
          svg={"co-pilot"}
        />
      );
    }
  }

  if (!hasTicketSystemIntegration || backfillStatus !== "completed") {
    return (
      <PageEmptyState
        title={"Auto-Pilot AI agents will appear here"}
        description={
          "This is where you'll set up Auto-Pilot, enabling Quack to handle inquiries automatically."
        }
        svg={"auto-pilot"}
      />
    );
  }

  return (
    <PageEmptyState
      title={"Preparing your Auto-Pilot AI agent..."}
      description={
        "We're processing your data. Once ready, you'll set up workflows and let your agent take charge. Stay tuned!"
      }
      svg={"auto-pilot"}
    />
  );
}

export const Simulators = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { simulators, loadingSimulators, handleCreateSimulator } =
    useReleases();

  const { backfillStatus } = useOnboarding();

  const fParam = searchParams.get("f");
  const isInternal = fParam === "copilot";

  const displayedSimulators = useMemo(
    () =>
      (simulators ?? []).filter((r) => {
        return channelMapper[r.channel].internal === isInternal;
      }),
    [simulators, isInternal]
  );

  const createNewItems = useMemo<DropdownMenuItem[]>(() => {
    const items: SimulatorChannel[] = isInternal
      ? ["copilot", "slackbot"]
      : ["autonomous_ticket_event", "helpcenter", "autonomous_quackchat"];

    const path = isInternal ? "/automation-copilot" : "/automation";

    return items.map((channel) => {
      return {
        icon: <SimulatorChannelIcon channel={channel} size={"1"} />,
        label: simulatorChannelContentMap[channel]?.title ?? channel,
        onClick: async () => {
          const simulator = await handleCreateSimulator(channel);
          navigate(`${path}/${simulator.id}`);
        },
      };
    });
  }, [isInternal]);

  if (backfillStatus !== "completed") {
    return (
      <div className={styles.container}>
        <div className={styles.controlRow}>
          <Navbar
            items={[
              { title: "Automation" },
              { title: isInternal ? "Co-pilot" : "Auto-pilot" },
            ]}
          />
        </div>
        <OnboardingSimulatorsEmptyState />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.controlRow}>
        <Navbar
          items={[
            { title: "Automation" },
            { title: isInternal ? "Co-pilot" : "Auto-pilot" },
          ]}
        />
      </div>

      {!loadingSimulators && displayedSimulators.length === 0 ? (
        <SimulatorsEmptyState />
      ) : (
        <div className={styles.main}>
          <div className={styles.content}>
            <div className={styles.header}>
              <Heading>
                {" "}
                {isInternal ? "Co-pilot " : "Auto-pilot "} Instances
              </Heading>

              <DropdownMenu items={createNewItems}>
                <DropdownMenu.Trigger>
                  <Button color="gray" variant="solid" highContrast>
                    Create Agent
                  </Button>
                </DropdownMenu.Trigger>
              </DropdownMenu>
            </div>

            {loadingSimulators ? (
              <Flex pt={"4"} justify={"center"}>
                <Spinner size={"3"} />
              </Flex>
            ) : (
              <Card
                variant="classic"
                style={{
                  padding: "0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {displayedSimulators.map((r) => (
                  <SimulatorItem r={r} key={r.id} />
                ))}
              </Card>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const SimulatorItem = ({
  r,
}: {
  r: {
    id: number;
    name: string;
    releases: Release[];
    channel: SimulatorChannel;
    publishedReleaseId?: number;
  };
}) => {
  const [isEdit, setEdit] = useState(false);
  const [value, setValue] = useState(r.name);
  const [requestToDeleteId, setRequestToDeleteId] = useState<number>();

  const { handleUpdateSimulator, setChosenSimulatorId } = useReleases();

  const { user } = useAuth0();
  const { value: coPilotSimulatorValue } = useFeatureFlag(
    "coPilotSimulator",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const navigate = useNavigate();
  const lastUpdateAt = r.releases?.reduce(
    (latest: Date, release: { updatedAt: Date }) => {
      const releaseDate = new Date(release.updatedAt);
      return releaseDate > latest ? releaseDate : latest;
    },
    new Date(0)
  );

  const draftRelease = r.releases.find(
    (release) => release.status === "draft" && !release.publishedAt
  );
  const publishedRelease = r.releases.find(
    (release) => release.id === r.publishedReleaseId
  );

  const calculateUpdatedInstructions = (
    draft?: Release,
    published?: Release
  ) => {
    // if (!draft || !published) return 0;
    if (!draft) return 0;

    const draftInstructions =
      typeof draft.releasePolicy === "string"
        ? JSON.parse(draft.releasePolicy)
        : draft.releasePolicy;
    if (!published)
      return draftInstructions.filter(
        (x: { type: string }) => x.type !== "general"
      ).length;
    const publishedInstructions =
      typeof published.releasePolicy == "string"
        ? JSON.parse(published.releasePolicy)
        : published.releasePolicy;
    const updatedInstructions = draftInstructions.filter(
      (draftInst: any) =>
        !publishedInstructions.some(
          (pubInst: any) =>
            pubInst.content === draftInst.content &&
            pubInst.subTopic === draftInst.subTopic &&
            pubInst.topic === draftInst.topic &&
            pubInst.type === draftInst.type
        )
    );
    const deletedInstructions = publishedInstructions.filter(
      (draftInst: any) =>
        !draftInstructions.some(
          (pubInst: any) =>
            pubInst.content === draftInst.content &&
            pubInst.subTopic === draftInst.subTopic &&
            pubInst.topic === draftInst.topic &&
            pubInst.type === draftInst.type
        )
    );

    return updatedInstructions.length + deletedInstructions.length;
  };

  const updatedInstructionsCount = calculateUpdatedInstructions(
    draftRelease,
    publishedRelease
  );

  return (
    <>
      <div
        className={styles.simulator}
        onClick={() => {
          if (!isEdit) {
            const path =
              !coPilotSimulatorValue || r.channel !== "copilot"
                ? `/automation/${r.id}`
                : `/automation-copilot/${r.id}`;
            navigate(path);
          }
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <span className={styles.editableTitle}>
            <Tooltip content={channelMapper[r.channel].description}>
              <SimulatorChannelIcon channel={r.channel} size={"3"} />
            </Tooltip>

            {isEdit ? (
              <Input
                value={value}
                onChange={(e) => setValue(e.target.value)}
                crossOrigin={undefined}
                autoFocus
                onBlur={(e) => {
                  handleUpdateSimulator(r.id, value);
                  e.stopPropagation();
                  setTimeout(() => {
                    setEdit(false);
                  }, 300);
                }}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                className={styles.input}
              />
            ) : (
              <Heading as="h6" size="3">
                {r.name}
              </Heading>
            )}
          </span>

          <Heading as="h6" size="1" weight="light">
            Last release {dayjs(lastUpdateAt).format("MMM DD,YYYY")}
          </Heading>
        </div>

        <label>
          <Send />
          {r.releases.length - 1} Releases
        </label>

        <label>
          <Dot type={updatedInstructionsCount === 0 ? "default" : "warning"} />
          {updatedInstructionsCount} Updates{" "}
        </label>

        <label
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <DropdownMenu
            items={[
              {
                label: "Rename",
                onClick: () => {
                  console.log("Rename");

                  setEdit(true);
                  setChosenSimulatorId(r.id);
                },
                icon: <Pencil1Icon />,
              },
              {
                label: "Delete",
                onClick: () => {
                  console.log("Delete");

                  setRequestToDeleteId(r.id);
                },
                icon: <TrashIcon />,
              },
            ]}
          >
            <DropdownMenu.Trigger>
              <IconButton
                color={"gray"}
                variant={"ghost"}
                highContrast
                size={"1"}
              >
                <DotsVerticalIcon />
              </IconButton>
            </DropdownMenu.Trigger>
          </DropdownMenu>
        </label>
      </div>

      {requestToDeleteId != null && (
        <DeleteSimulatorDialog
          simulatorId={requestToDeleteId}
          onClose={() => setRequestToDeleteId(undefined)}
        />
      )}
    </>
  );
};

function isError(data: any) {
  return data.error != null && data.statusCode != null && data.message != null;
}

interface DeleteSimulatorDialogProps {
  simulatorId: number;
  onClose: () => void;
}

function DeleteSimulatorDialog({
  simulatorId,
  onClose,
}: DeleteSimulatorDialogProps) {
  const { refetchSimulators } = useReleases();
  const { mutate, isLoading, data } = useDeleteSimulator({
    simulatorId,
  });

  const erroMessage = useMemo<string>(() => {
    if (data != null && isError(data)) {
      return data.message;
    }
  }, [data]);

  useEffect(() => {
    if (data != null && !isError(data)) {
      refetchSimulators().then(() => {
        onClose();
      });
    }
  }, [data, onClose, refetchSimulators]);

  return (
    <Dialog
      open
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Dialog.Content size={"small"}>
        <Dialog.Title>
          Delete Simulator
          <Dialog.Close />
        </Dialog.Title>

        <div className={styles.DeleteSimulatorDialog}>
          <div className={styles.Content}>
            Are you sure you want to delete this simulator?
          </div>

          {erroMessage != null && (
            <div className={styles.ErroMessage}>
              <CrossCircledIcon />
              {erroMessage}
            </div>
          )}

          <div className={styles.Footer}>
            <Button
              color={"red"}
              variant={"solid"}
              size={"1"}
              loading={isLoading}
              disabled={erroMessage != null}
              onClick={() => {
                mutate({});
                mixTrackEvent({
                  event: SimulatorEvents.DELETED,
                  properties: {
                    id: simulatorId,
                  },
                });
              }}
            >
              Delete
            </Button>

            <Button
              color={"gray"}
              variant={"ghost"}
              size={"1"}
              disabled={isLoading}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog>
  );
}
