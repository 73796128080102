export enum SimulatorEvents {
  TRAINING_INQUIRY_OPENED = "simulator_training_inquiry_opened",
  TRAINING_INQUIRY_SELF_CREATED = "simulator_training_inquiry_self_created",
  CREATED = "simulator_created",
  DELETED = "simulator_deleted",
  GOOD_FEEDBACK = "simulator_good_feedback",
  BAD_FEEDBACK = "simulator_bad_feedback",
  START_INSTRUCTION = "simulator_start_instruction",
  SUBMIT_INSTRUCTION = "simulator_submit_instruction",
  DELETE_INSTRUCTION = "simulator_delete_instruction",
  CHECK_SOURCES = "simulator_check_sources",
  ENTER_EDIT_MODE = "simulator_enter_edit_mode",
  SAVE_EDIT_MODE = "simulator_save_edit_mode",
  RELEASED = "simulator_released",
  START_BRIEF = "simulator_start_brief",
  SUBMIT_BRIEF = "simulator_submit_brief",
  CANCEL_BRIEF = "simulator_cancel_brief",
  MESSAGE_TRANSLATED = "simulator_message_translated",
}
